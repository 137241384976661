<template>
  <CModal
    @mtdclosemodal="mtdClose"
    :show.sync="boo_modal"
    :close-on-backdrop="false"
    :centered="true"
    :title="title"
    :size="'xl'"
    :btn_cancel="true"
    :btn_ok="true"
    :booTitile="true"
    :scrollable="true"
    :fullScreen="false"
  >
    <div class="row mx-0 mt-2">
      <div
        v-for="(item, index) in days"
        :key="index"
        class="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-xs-1 text-center"
      >
        <button :class="mtdReturnClass(index)" @click="mtdSelectDay(item.day,index)">{{ item.day }}</button>
      </div>
    </div>
    <template #footer>
      <button type="button" class="btn btn-register text-white" @click="mtdSelectDates">
        <i class="far fa-calendar-check"></i> SELECCIONAR FECHAS
      </button>
    </template>
  </CModal>
</template>
  

  <script>
import CModal from "../../shared/modals/CModal.vue";
import { mapActions } from "vuex";
import moment from "moment";
import SweetAlert from "@/components/shared/SweetAlert/SweetAlert.vue";
export default {
  name: "c-pharmacy-cModalDetalle",
  props: {
    title: String,
    boo_modal: Boolean,
    days: Array,
    arDay: Array,
    // click:Number
  },
  components: {
    SweetAlert,
    CModal,
  },
  data() {
    return {
      click:0
    };
  },
  created() {
  },
  computed: {
  },
  methods: {
    ...mapActions(["get", "post"]),
    mtdClose() {
      this.arDay=[0,0];
      this.click = 0;
      this.$emit("closeModal");
    },
    mtdSelectDay: function(day,index){
      if (this.click == 0) {
        this.arDay[0]=day;
      } else {
          if (day < this.arDay[0]) {
            this.arDay[0]=day
          }else if( day >= this.arDay[0] && day <= this.arDay[1] ){
            if (day - this.arDay[0] <=3) {
              this.arDay[0]=day  
            }else{
              this.arDay[1]=day
            }
          }else{
            this.arDay[1]=day
          }
      }
      this.mtdGetClass();
      this.click ++;
    },
    mtdGetClass: function(){
      this.days.forEach(element => {
        if (this.arDay[0] <= element.day && element.day<=this.arDay[1]) {
          element.class = 'btn btn-sm btn-info w-100 mt-2';
        } else {
          element.class = 'btn btn-sm btn-secondary w-100 mt-2';
        }
      });
    },
    mtdSelectDates: function(){
      this.$emit('mtdSelectDates',this.arDay);
    },
    mtdReturnClass:function(index){
      return this.days[index].class;
    }
  },
  filters: {
    formatDate(date) {
      return date != null ? moment(date).format("DD/MM/YYYY") : "-";
    },
    formatDateTime(dateTime) {
      return dateTime != null
        ? moment(dateTime).format("DD/MM/YYYY HH:mm")
        : "-";
    },
  },
};
</script>
  <style scoped>
.align-middle {
  vertical-align: middle;
}
</style>
  