<template>
  <div>
    <div class="px-5 pt-2">
      <div id="btn-open-caja" class="d-flex justify-content-end pt-1">
        <div class="btn-group" role="group">
          <button type="button" class="btn w-100 btn-bm-noradius" @click="mtdBackCampus">
            <i class="fas fa-recycle"></i> Cambiar de Sede
          </button>
        </div>
      </div>
    </div>
    <div class="c-app align-items-center">
      <div id="contenedor">
        <div class="d-grid gap-2 mx-auto text-center col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <h2>HOME MARKETING</h2>
        </div>
      </div>
    </div>
  </div>
  </template>

<script>
export default {
  data() {
    return {
      page: null,
    };
  },
  methods: {
    changePage(page) {
      this.$emit("changePage", page);
    },
    mtdBackCampus: function () {
      this.$emit("mtdBackCampus");
    },
  },
};
</script>