<template>
    <CModal
      @mtdclosemodal="mtdClose"
      :show="boo_modal"
      :close-on-backdrop="false"
      :centered="true"
      :title="title"
      :size="'xl'"
      :btn_cancel="true"
      :btn_ok="true"
      :booTitile="true"
      :fullScreen="true"
    >
    <div class="container mt-2">
  
      <div class="card mb-3" style="border-color: #900052;">
        <div class="card-body p-2">
          <div class="table-responsive">
            <table class="table table-bordered mb-0" style="font-size: 10px;">
                <tbody>
                    <tr>
                        <th style="padding: 0.25rem;">PRODUCTO</th>
                        <td style="padding: 0.25rem;">{{ namePro }}</td>
                    </tr>
                    <tr>
                        <th style="padding: 0.25rem;">LOTE</th>
                        <td style="padding: 0.25rem;">{{ lotePro }}</td>
                    </tr>
                </tbody>
            </table>
          </div>
        </div>
      </div>
  
      <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-xs-12">
          <div class="accordion " id="accordionExample">
            <!--OC-->
            <div class="accordion-item">
              <h2 class="accordion-header">
                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                  <i :class="iconClass"></i>&nbsp; ORDEN DE COMPRA
                </button>
              </h2>
              <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <div v-if="cpFactura.length > 0">
                      <div class="row"  v-for="(item, index) in cpFactura" :key="index">
                        <div class="col-md-6">
                          <p><strong>Fecha de compra:</strong> {{ item.pur_date | formatDate }}</p>
                          <p><strong>Proveedor:</strong> {{ item.pur_provider ? item.pur_provider : '-'}}</p>
                          <p><strong>Costo de envío:</strong> {{ item.pur_shipping_cost ? item.pur_shipping_cost : '-' }}</p>
                        </div>
                        <div class="col-md-6">
                          <p><strong>Estado:</strong> {{ stateOc(item.pur_state) }}</p>
                          <p><strong>Total:</strong> {{ item.pur_total ? item.pur_total : '-' }}</p>
                          <p><strong>Pdf de OC: </strong>
                            <a v-if="item.pur_id" class="acciones-Detkar text-center px-2 py-2" href="# " @click="viewfactura(item.pur_id)" style="margin-left: 2px">
                                    <i class="fas fa-file-pdf"></i>
                                </a>
                            <span v-else>-</span>
                          </p>
                        </div>
                      </div>
                  </div>
                  <div v-else style="font-size: 12px">
                    <h6>Registrado por Nota de Ingreso</h6>
                  </div>
                </div>
              </div>
            </div>
            <!--Fin OC-->
            <!--Factura-->
            <div class="accordion-item acord" style="margin-left: 50px">
              <h2 class="accordion-header">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                  <i :class="iconClassFa"></i>&nbsp; FACTURA DE COMPRA
                </button>
              </h2>
              <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <div v-if="cpFactura.length > 0">
                    <div class="row"  v-for="(item, index) in cpFactura" :key="index">
                      <div class="col-md-6">
                        <p><strong>N° Factura:</strong> {{ item.bill_number ? item.bill_number : '-'}}</p>
                        <p><strong>Tipo:</strong> {{ stateType(item.bill_type)}}</p>
                        <p><strong>Fecha de Factura:</strong> {{ item.bill_date | formatDate}}</p>
                        <p><strong>Fecha de Venc:</strong> {{ item.bill_due_date | formatDate}}</p>
                      </div>
                      <div class="col-md-6">
                        <p><strong>Total:</strong> {{ item.bill_total ? item.bill_total : '-' }}</p>
                        <p><strong>Estado de Pago:</strong> {{ stateFa(item.bill_state)}}</p>
                        <p><strong>Estado de Despacho:</strong> {{ stateFaDis(item.bill_dispatchState) }}</p>
                        <p>
                          <strong>Pdf de Factura: </strong>
                          <a v-if="item.bill_file" @click="openBillFile(item.bill_file)" class="acciones-Detkar text-center px-2 py-2" style="margin-left: 2px; cursor: pointer;">
                            <i class="fas fa-file-pdf"></i>
                          </a>
                          <span v-else>-</span>
                        </p>
                      </div>
                      <div class="col-md-12 table-responsive mt-2" id="div-tabla-anular">
                        <table id="tb-anular" class="table table-bordered">
                          <caption class="mx-0 px-0"></caption>
                          <thead class="bg-main" style="font-size: 12px">
                            <tr class="text-white">
                              <th scope="col" class="align-middle">N°</th>
                              <th scope="col" class="align-middle">MONTO PAGADO</th>
                              <th scope="col" class="align-middle">FECHA</th>
                              <th scope="col" class="align-middle">USUARIO</th>
                              <th scope="col" class="align-middle">METODO DE PAGO</th>
                              <th scope="col" class="align-middle">VOUCHER</th>
                            </tr>
                          </thead>
                          <tbody class="border-main" style="font-size: 11px">
                            <tr v-if="item.billPago.length == 0" class="text-center">
                                <th scope="col" colspan="6">No hay Pagos Registrados</th>
                            </tr>
                            <tr v-for="(pago, i) in item.billPago" :key="i" class="tbody-table">
                              <td class="align-middle">{{ i + 1 }}</td>
                              <td class="align-middle">{{ pago.amount }}</td>
                              <td class="align-middle">{{ pago.date | formatDate }}</td>
                              <td class="align-middle">{{ pago.user.name }} {{ pago.user.last_name }}</td>
                              <td class="align-middle" v-if="pago.method_pay == 'BANCO'">
                                  {{ pago.method_pay }} - {{ pago.bank.name }}
                              </td>
                              <td class="align-middle" v-else>
                                  {{ pago.method_pay }}
                              </td>
                              <td class="align-middle">
                                <a v-if="pago.file" @click="openBillFile(pago.file)" class="acciones-Detkar text-center px-2 py-2" style="cursor: pointer;">
                                  <i class="fas fa-file-pdf"></i>
                                </a>
                                <span v-else>-</span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div v-else style="font-size: 12px">
                    <h6>Registrado por Nota de Ingreso</h6>
                  </div>
                </div>
              </div>
            </div>
            <!-- Fin Factura-->
            <!--Guia-->
            <div class="accordion-item acord" style="margin-left: 100px">
              <h2 class="accordion-header">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                  GUIA DE REMISIÓN
                </button>
              </h2>
              <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <div class="row"  v-for="(item, index) in cpData" :key="index">
                    <div class="col-md-12">
                      <p><strong>Fecha de Ingreso:</strong> {{ item.dis_date | formatDate}}</p>
  
                      <template v-if="item.dis_guide !== 'No'">
                        <p><strong>Guia de Remission:</strong> Aplica - {{ item.dis_guide }}</p>
                        <p>
                          <strong>Url de Guia: </strong>
                          <a v-if="item.dis_url" @click="openUrl(item.dis_url)" class="acciones-Detkar text-center px-2 py-2" style="margin-left: 2px; cursor: pointer;">
                            <i class="fas fa-file-pdf"></i>
                          </a>
                          <span v-else>-</span>
                        </p>
                      </template>
  
                      <template v-else>
                        <p><strong>Guia de Remission:</strong> No aplica</p>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--Fin Guia-->
            <!--Venta-->
            <div class="accordion-item acord" style="margin-left: 150px" v-if="!cpVentaIsEmpty">
              <h2 class="accordion-header">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                  VENTA
                </button>
              </h2>
              <div id="collapseFour"  class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <div v-if="cpControlIsEmpty">
                      <div class="row" v-for="(item, index) in cpVenta" :key="index">
                            <div class="col-md-6">
                              <p><strong>Fecha de Venta:</strong> {{ item.sale_date | formatDate }}</p>
                              <p><strong>Método:</strong> <span class="uppercase">{{ item.sale_method }}</span></p>
                              <p><strong>Tipo:</strong> <span class="uppercase">{{ item.sale_type }}</span></p>
                              </div>
                              <div class="col-md-6">
                              <p><strong>Paciente:</strong> {{ item.det_patient_name }}</p>
                              <p><strong>Tipo Paciente:</strong> {{ item.sale_type_patient}}</p>
                              <p><strong>TOTAL:</strong> {{ item.sale_total }}</p>
                            </div>
                      </div>
                  </div>
                  <div v-else>
                      <div class="row" v-for="(item, index) in cpControl" :key="index">
                        <div class="col-md-6">
                          <p><strong>Fecha: </strong> {{ item.control_date | formatDate}}</p>
                          <p><strong>Documento:</strong> {{ item.control_document }}</p>
                          <p><strong>Serie y Numero:</strong> {{ item.control_serie}}-{{ item.control_number}}</p>
                          <p><strong>Destino:</strong> {{ item.control_destination}}</p>
                        </div>
                        <div class="col-md-6">
                          <p><strong>Paciente:</strong> {{ item.control_patient}}</p>
                          <p><strong>Tipo Paciente:</strong> {{ item.control_typePa}}</p>
                          <p><strong>Cantidad:</strong> {{ item.control_quantity}}</p>
                          <p><strong>Pdf de Control Interno: </strong>
                            <a v-if="item.control_id" class="acciones-Detkar text-center px-2 py-2" href="# " @click="mtdPdf(item.control_id)" style="margin-left: 2px">
                                    <i class="fas fa-file-pdf"></i>
                                </a>
                            <span v-else>-</span>
                          </p>
                        </div>
                      </div>
                      <div v-if="!cpNoteIsEmpty">
                        <div class="row">
                          <hr>
                          <h6><strong>DEVOLUCIONES</strong></h6>
                        </div>
                        <div class="row" v-for="(item) in cpNote" :key="item.note_id">
                          <hr>
                          <div class="col-md-6">
                            <p><strong>Fecha: </strong> {{ item.note_date | formatDate }}</p>
                            <p><strong>Documento:</strong> {{ item.note_document }}</p>
                            <p><strong>Serie y Numero:</strong> {{ item.note_serie }}-{{ item.note_number }}</p>
                            <p><strong>Cantidad Devuelta:</strong> {{ item.note_devolution }}</p>
                          </div>
                          <div class="col-md-6">
                            <p><strong>Motivo:</strong> {{ item.note_devolution_motives }}</p>
                            <p><strong>Tipo:</strong> {{ item.note_devolution_types }}</p>
                            <p><strong>Pdf de Nota de Credito: </strong>
                              <a v-if="item.note_PharId" class="acciones-Detkar text-center px-2 py-2" href="# " @click="mtdPdfNote(item.note_PharId)" style="margin-left: 2px">
                                      <i class="fas fa-file-pdf"></i>
                                  </a>
                              <span v-else>-</span>
                            </p>
                          </div>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
            <!--Fin Venta-->
            <!--Deposito-->
            <!-- <div class="accordion-item acord" style="margin-left: 200px" v-if="!cpVentaIsEmpty">
              <h2 class="accordion-header">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                  DEPOSITO DE LA VENTA
                </button>
              </h2>
              <div id="collapseFive"  class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  <div class="row"  v-for="(item, index) in cpVenta" :key="index">
                    <div class="col-md-6">
                      <p v-if="item.vou_date">
                        <strong>Fecha:</strong> {{item.vou_date | formatDate}}
                      </p>
                      <p v-else>
                        <strong>Fecha:</strong> {{'-'}}
                      </p>
                      <p><strong>Documento:</strong> {{ item.vou_document ? item.vou_document : '-'}}</p>
                    </div>
                    <div class="col-md-6">
                      <p><strong>Serie y Numero:</strong> {{ item.vou_serie }}-{{ item.vou_numberVou}}</p>
                      <p><strong>Url de Voucher: </strong>
                          <a v-if="item.vou_link" :href="item.vou_link" target="_blank" class="acciones-Detkar text-center px-2 py-2" style="margin-left: 2px">
                                  <i class="fas fa-file-pdf"></i>
                              </a>
                          <span v-else>-</span>
                        </p>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
            <!--Fin Deposito-->
          </div>
        </div>
      </div>
    </div>
  
    </CModal>
</template>
  
<script>
  import CModal from "../../../shared/modals/CModal.vue";
  import moment from "moment";
  import { mapActions } from "vuex";
  
  export default {
    name: "c-oftalmologia-modal-detalle",
    components: {
      CModal,
    },
    data() {
      return {
      };
    },
    props: {
      title: String,
      boo_modal: Boolean,
      dataFactura: Array,
      dataDis: Array,
      dataSal: Array,
      dataDePhar: Array,
      dataNoteDet: Array,
      namePro: String,
      lotePro: String,
    },
    created() {
    },
    computed: {
      cpData() {
          return this.dataDis.map(element => element);
      },
      cpFactura() {
          return this.dataFactura.map(element => element);
      },
      cpVenta() {
          return this.dataSal.map(element => element);
      },
  
      cpControl() {
          return this.dataDePhar.map(element => element);
      },
  
      cpNote() {
          return this.dataNoteDet.map(element => element);
      },
  
      cpVentaIsEmpty() {
        return !this.cpVenta || this.cpVenta.length === 0;
      },
  
      cpControlIsEmpty() {
        return !this.cpControl || this.cpControl.length === 0;
      },
  
      cpNoteIsEmpty() {
        return !this.cpNote || this.cpNote.length === 0;
      },
  
      iconClass() {
        return (this.cpData.length > 0 && this.cpData[0].pur_state === 0) ? 'fas fa-lock' : 'fas fa-lock-open';
      },
  
      iconClassFa() {
        return (this.cpData.length > 0 && this.cpData[0].bill_state === 3) ? 'fas fa-lock' : 'fas fa-lock-open';
      }
    },
    methods: {
      mtdClose() {
        this.$emit("closeModalDetKar");
      },

      openUrl(url) {
        window.open('assets/' + url, '_blank');
      },

      openBillFile(url){
        window.open('assets/' + url, '_blank');
      },
  
      viewfactura: function (id) {
          window.open(
              this.$store.getters.get__url + "/Kardex/viewpdfOc/" + id,
              "_blank"
          );
      },
  
      mtdPdf: function(id) {
        window.open(
            this.$store.getters.get__url + "/viewpdfDocInterno/" + id,
            "_blank"
        );
      },
  
      mtdPdfNote: function(id) {
        window.open(
            this.$store.getters.get__url + "/Pharmacy/returns/noteCrePdf/" + id,
            "_blank"
        );
      },
  
      stateOc(state) {
        switch (state) {
          case 0:
            return "Cerrada";
          case 1:
            return "Pendiente Factura";
          case 2:
            return "Pendiente Productos";
          default:
            return "-";
        }
      },
  
      stateFa(state) {
        switch (state) {
          case 0:
            return "Sin Pagar";
          case 1:
            return "Pagado";
          case 2:
            return "Pagado Parcialmente";
          case 3:
            return "Pagado";
          default:
            return "-";
        }
      },
  
  
      stateFaDis(state) {
        switch (state) {
          case 1:
            return "Sin Despachar";
          case 2:
            return "Parcialmente Despachado";
          case 3:
            return "Completo";
          default:
            return "-";
        }
      },
  
      stateType(state) {
        switch (state) {
          case 1:
            return "Factura";
          case 2:
            return "Boleta";
          default:
            return "-";
        }
      },

      openBillFile: function (url) {
          let nuevaUrl = "/assets/" + url;
          window.open(nuevaUrl, '_blank');
      },
    },
  
    filters: {
        formatDate(date) {
            return date != null ? moment(date).format("DD/MM/YYYY") : "-";
        },
        formatDateTime(dateTime) {
            return dateTime != null ? moment(dateTime).format("DD/MM/YYYY HH:mm") : "-";
        },
    },
  
  };
</script>
<style scoped>
    @media (max-width: 768px) {
      .acord {
        margin-left: 0 !important;
      }
      .ancho {
        width: 100% !important;
      }
    }
  
    .ancho {
      width: 75%;
    }
  
  
  
    .accordion-item {
        border: none;
        margin-bottom: 10px;
        border-radius: 20px;
      }
      .accordion-button {
        background-color: #95336b;
        color: white;
        font-weight: bold;
      }
      .accordion-button:not(.collapsed) {
        background-color: #900052;
      }
      .accordion-button:focus {
        box-shadow: none;
      }
      .accordion-header {
        background-color: #900052;
      }
      .accordion-body {
        background-color: #900052;
      }
      .accordion-item.acord {
        margin-left: 50px;
      }
      #collapseOne .accordion-body {
        background-color: #f1e7ed;
      }
      #collapseTwo .accordion-body {
        background-color: #f1e7ed;
      }
      #collapseThree .accordion-body {
        background-color: #f1e7ed;
      }
      #collapseFour .accordion-body {
        background-color: #f1e7ed;
      }
      #collapseFive .accordion-body {
        background-color: #f1e7ed;
      }
  
    a {
      color: #900052;
    }
  
    .close-button {
      border: none;
      background-color: transparent;
    }
  
    .uppercase {
        text-transform: uppercase;
    }

    .acciones-Detkar {
        background-color: #D2E0F2;
        border-radius: 10px;
        width: 15px;
        font-size: 12px;
        border: 1.5px solid #ffe2f4;
    }
</style>
  
  