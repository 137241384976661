import { render, staticRenderFns } from "./MediSinStock.vue?vue&type=template&id=47b6b7e2&scoped=true"
import script from "./MediSinStock.vue?vue&type=script&lang=js"
export * from "./MediSinStock.vue?vue&type=script&lang=js"
import style0 from "./MediSinStock.vue?vue&type=style&index=0&id=47b6b7e2&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47b6b7e2",
  null
  
)

export default component.exports