<template>
    <div>
        <div class="px-3">
            <div class="row row-home my-5" id="div_conte_2">
                <div id="div_conte-home" class="col-md-10 mx-auto mt-0 border border-2 rounded rounded-2 border-main">
                    <div class="bg-opacity-10">
                        <div class="text-center py-4 px-5" >
                            <div class=" row row-home">
                                <div class=" border-main card-book border border-2 card div-pointer col m-2 p-2 " @click="changePage('administrativo')">
                                    <div class="card-body">
                                        <h5 class="card-title color-main">ADMINISTRATIVOS</h5>
                                        <div class="icon-book">
                                            <img src="../../../../../assets/users/administrador.png" alt="" class="img-fluid-bed-sop rounded-start">
                                        </div>
                                     
                                    </div>
                           
                                </div>
                                <div class=" border-main card-book border border-2 card div-pointer col m-2 p-2  " @click="changePage('medico')">
                                    <div class="card-body">
                                        <h5 class="card-title color-main">MÉDICOS</h5>
                                        <div class="icon-book">
                                            <img src="../../../../../assets/users/medico.png" alt="" class="img-fluid-bed-sop rounded-start">
                                        </div>
                                    </div>
                               
                                </div>
                                <div class=" border-main card-book  border border-2 card div-pointer col m-2 p-2  " @click="changePage('enfermera')">
                                    <div class="card-body">
                                        <h5 class="card-title color-main">LIC. ENFERMERÍA</h5>
                                        <div class="icon-book">
                                            <img src="../../../../../assets/users/enfermera.png" alt="" class="img-fluid-bed-sop rounded-start">
                                        </div>
                                    </div>
                               
                                </div>
                                <div class=" border-main card-book border border-2 card div-pointer col m-2 p-2  " @click="changePage('tec_enfermero')">
                                    <div class="card-body">
                                        <h5 class="card-title color-main">TEC. ENFERMERÍA</h5>
                                        <div class="icon-book">
                                            <img src="../../../../../assets/users/tec_enfermero.png" alt="" class="img-fluid-bed-sop rounded-start">
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                            <div class=" row row-home">
                                <div class=" border-main  card-book border border-2 card div-pointer col m-2 p-2  " @click="changePage('tec_laboratorio')">
                                    <div class="card-body">
                                        <h5 class="card-title color-main">TEC. LABORATORIO</h5>
                                        <div class="icon-book">
                                            <img src="../../../../../assets/users/tec_laboratorio.png" alt="" class="img-fluid-bed-sop rounded-start">
                                        </div>
                                    </div>
                                  
                                </div>
                                <div class=" border-main card-book border border-2 card div-pointer col m-2 p-2  " @click="changePage('tec_farmacia')">
                                    <div class="card-body">
                                        <h5 class="card-title color-main">TEC. FARMACIA</h5> 
                                        <div class="icon-book">
                                            <img src="../../../../../assets/users/farmaceutico.png" alt="" class="img-fluid-bed-sop rounded-start">
                                        </div>
                                    </div>
                                  
                                </div>
                                <div class=" border-main card-book border border-2 card div-pointer col m-2 p-2  " @click="changePage('biologo')">
                                    <div class="card-body">
                                        <h5 class="card-title color-main">BIÓLOGO</h5>
                                        <div class="icon-book">
                                            <img src="../../../../../assets/users/biologo.png" alt="" class="img-fluid-bed-sop rounded-start">
                                        </div>
                                    </div>
                                    
                                </div>
                                <div class=" border-main card-book border border-2 card div-pointer col m-2 p-2  " @click="changePage('quimico')">
                                    <div class="card-body">
                                        <h5 class="card-title color-main">QUÍMICO FARMACÉUTICO</h5>
                                        <div class="icon-book">
                                            <img src="../../../../../assets/users/quimico.png" alt="" class="img-fluid-bed-sop rounded-start">
                                        </div>
                                    </div>
                                 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </template>
    
        
    <script>
    export default {
        name: 'homeUsers',
        data() {
            return {
                page: null
            }
        },
        methods: {
            changePage(page) {
                this.$emit('changePage', page)
            },
            mtdBackCampus: function () {
                this.$emit('mtdBackCampus')
            },
            returnHome:function(){
                this.$emit('returnHome')
            }
        }
    }
    </script>
    