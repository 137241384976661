var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"px-3"},[_c('div',{staticClass:"row row-home my-5",attrs:{"id":"div_conte_2"}},[_c('div',{staticClass:"col-md-10 mx-auto mt-0 border border-2 rounded rounded-2 border-main",attrs:{"id":"div_conte-home"}},[_c('div',{staticClass:"bg-opacity-10"},[_c('div',{staticClass:"text-center py-4 px-5"},[_c('div',{staticClass:"row row-home"},[_c('div',{staticClass:"border-main card-book border border-2 card div-pointer col m-2 p-2",on:{"click":function($event){return _vm.changePage('administrativo')}}},[_vm._m(0)]),_c('div',{staticClass:"border-main card-book border border-2 card div-pointer col m-2 p-2",on:{"click":function($event){return _vm.changePage('medico')}}},[_vm._m(1)]),_c('div',{staticClass:"border-main card-book border border-2 card div-pointer col m-2 p-2",on:{"click":function($event){return _vm.changePage('enfermera')}}},[_vm._m(2)]),_c('div',{staticClass:"border-main card-book border border-2 card div-pointer col m-2 p-2",on:{"click":function($event){return _vm.changePage('tec_enfermero')}}},[_vm._m(3)])]),_c('div',{staticClass:"row row-home"},[_c('div',{staticClass:"border-main card-book border border-2 card div-pointer col m-2 p-2",on:{"click":function($event){return _vm.changePage('tec_laboratorio')}}},[_vm._m(4)]),_c('div',{staticClass:"border-main card-book border border-2 card div-pointer col m-2 p-2",on:{"click":function($event){return _vm.changePage('tec_farmacia')}}},[_vm._m(5)]),_c('div',{staticClass:"border-main card-book border border-2 card div-pointer col m-2 p-2",on:{"click":function($event){return _vm.changePage('biologo')}}},[_vm._m(6)]),_c('div',{staticClass:"border-main card-book border border-2 card div-pointer col m-2 p-2",on:{"click":function($event){return _vm.changePage('quimico')}}},[_vm._m(7)])])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-body"},[_c('h5',{staticClass:"card-title color-main"},[_vm._v("ADMINISTRATIVOS")]),_c('div',{staticClass:"icon-book"},[_c('img',{staticClass:"img-fluid-bed-sop rounded-start",attrs:{"src":require("../../../../../assets/users/administrador.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-body"},[_c('h5',{staticClass:"card-title color-main"},[_vm._v("MÉDICOS")]),_c('div',{staticClass:"icon-book"},[_c('img',{staticClass:"img-fluid-bed-sop rounded-start",attrs:{"src":require("../../../../../assets/users/medico.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-body"},[_c('h5',{staticClass:"card-title color-main"},[_vm._v("LIC. ENFERMERÍA")]),_c('div',{staticClass:"icon-book"},[_c('img',{staticClass:"img-fluid-bed-sop rounded-start",attrs:{"src":require("../../../../../assets/users/enfermera.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-body"},[_c('h5',{staticClass:"card-title color-main"},[_vm._v("TEC. ENFERMERÍA")]),_c('div',{staticClass:"icon-book"},[_c('img',{staticClass:"img-fluid-bed-sop rounded-start",attrs:{"src":require("../../../../../assets/users/tec_enfermero.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-body"},[_c('h5',{staticClass:"card-title color-main"},[_vm._v("TEC. LABORATORIO")]),_c('div',{staticClass:"icon-book"},[_c('img',{staticClass:"img-fluid-bed-sop rounded-start",attrs:{"src":require("../../../../../assets/users/tec_laboratorio.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-body"},[_c('h5',{staticClass:"card-title color-main"},[_vm._v("TEC. FARMACIA")]),_c('div',{staticClass:"icon-book"},[_c('img',{staticClass:"img-fluid-bed-sop rounded-start",attrs:{"src":require("../../../../../assets/users/farmaceutico.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-body"},[_c('h5',{staticClass:"card-title color-main"},[_vm._v("BIÓLOGO")]),_c('div',{staticClass:"icon-book"},[_c('img',{staticClass:"img-fluid-bed-sop rounded-start",attrs:{"src":require("../../../../../assets/users/biologo.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-body"},[_c('h5',{staticClass:"card-title color-main"},[_vm._v("QUÍMICO FARMACÉUTICO")]),_c('div',{staticClass:"icon-book"},[_c('img',{staticClass:"img-fluid-bed-sop rounded-start",attrs:{"src":require("../../../../../assets/users/quimico.png"),"alt":""}})])])
}]

export { render, staticRenderFns }