<template>
<CModal @mtdclosemodal="mtdClose" :show="boo_modal" :close-on-backdrop="false" :centered="false" :title="title" :size="'md'" :btn_cancel="true" :btn_ok="true" :booTitile="true">
    <div class="px-3">
        <div class="row mx-0 px-0">
            <div class="mb-2 col-xl-12 px-1">
                <label for="name" class="form-label">Nombre</label>
                <input type="text" class="form-control form-control-sm" id="name" name="name" v-model="data_detail.name" placeholder=" Nombre">
            </div>
            <div class="mb-2 col-xl-12 px-1">
                <label for="code" class="form-label">Codigo Ipress</label>
                <input type="text" class="form-control form-control-sm" id="code" name="code" v-model="data_detail.code_ipress" @keyup="mtdUniqueCode" @keydown="filterKey" placeholder=" Codigo Ipress" @input="">
                <span class="text-danger" style="font-size: 10px;" v-if="!codeUnique">
                    Ya existe un registro con ese codigo
                </span>
            </div>
            <div class="mb-2 col-xl-12 px-1">
                <label for="address" class="form-label">Direccion</label>
                <input type="text" class="form-control form-control-sm" id="address" name="address" v-model="data_detail.address" placeholder=" Direccion">
            </div>
        </div>
        <div class="col-md-12 mt-3 pt-1" v-if="num === 1">
            <div class="form-check form-switch">
                <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" v-model="data_detail.state" :checked="data_detail.state == 1 ? true : false" aria-checked="false" />
                <label class="form-check-label" for="flexSwitchCheckChecked">
                    {{ data_detail.state == 1 ? 'Activo' : 'Inactivo' }}
                </label>
            </div>
        </div>
    </div>
    <template #footer>
        <button class="btn btn-success btn-sm" @click="DataDetail" :disabled="cpButton">
            {{ (num == 0 ) ? 'Registrar':'Editar' }}
        </button>
        &nbsp;
    </template>
</CModal>
</template>

<script>
import CModal from "../../shared/modals/CModal.vue";
import {
    mapActions
} from "vuex";

export default {
    name: "c-maintenance-modal-documento",
    components: {
        CModal,
    },
    data() {
        return {
            data_detail: {
                name: '',
                code_ipress: "",
                address: "",
                state: '',
            },
            codeUnique: true,
        };
    },
    watch: {
        payload: {
            handler(newVal) {
                if (this.num == 1) {
                    if (newVal) {
                        this.data_detail = {
                            ...newVal
                        };
                    }
                }
            },
            immediate: true,
        },
    },
    props: {
        title: String,
        boo_modal: Boolean,
        num: Number,
        payload: {
            type: Object,
            default: null
        },
        data: Array,
    },
    computed: {
        cpButton() {
            return !(this.data_detail.name != '' && this.data_detail.code_ipress !== "" && this.codeUnique);

        }
    },
    methods: {
        ...mapActions(["get", "post"]),
        mtdClose() {
            this.data_detail = {
                name: '',
                code_ipress: "",
                address: "",
                state: '',
            };
            this.codeUnique =  true,
            this.$emit("closeModal");
        },
        DataDetail() {
            this.$emit('Datadetail', this.data_detail, this.num)
            this.mtdClose();
        },
        filterKey(event) {
            if (!/[0-9]/.test(event.key) && event.key !== 'Backspace') {
                event.preventDefault();
            }
        },
        mtdUniqueCode(){
            let dataFilter = this.data.filter(i => i.id !== this.data_detail.id);
            let data = dataFilter.filter(item => item.code_ipress === this.data_detail.code_ipress);
            this.codeUnique = (data.length == 0);
        }
    },

};
</script>
