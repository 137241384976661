<template>
    <div>
      <div class="w-100 h-100">
        <Nav :rolname="rolname"/>
      </div>
      <div class="content h-100 " style="background:white">
        <HomeVue v-if="page=='home'" @changePage="changePage" @mtdBackCampus="mtdBackCampus" />
      </div>
      <div class="footer">
        <FooterVue @mtdChangePage="mtdChangePage" :pageView='page' />
      </div>
    </div>
  </template>
  
  <script>
  import HomeVue from '@/components/Marketing/Home.vue';
  import Nav from "@/components/Marketing/Nav.vue";
  import FooterVue from '@/components/Marketing/Footer.vue';
  export default {
    name: "MarketingView",
    components: {
      Nav,
      HomeVue,
      FooterVue
    },
    props: {
      rolname: String,
    },
    data() {
      return {
        page: 'home',
      };
    },
    methods: {
      changePage: function (page) {
        this.page = page;
      },
      returnHome: function () {
        this.page = "home";
      },
      mtdBackCampus: function () {
        this.$emit("mtdBackCampus");
      },
      mtdChangePage: function(page){
        this.page = page;
      },
    },
  };
  </script>