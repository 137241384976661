<template>
    <CModal @mtdclosemodal="mtdclosemodal" :show="boo_modal" :close-on-backdrop="false" :centered="false" :title="title" :size="'xl'" :btn_cancel="true" :btn_ok="true" :booTitile="true">
        <div class="row d-flex">
            <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-xs-12">
                <label class="mb-3 mt-2">Solución</label>
                <div class="input-group input-group-sm">
                    <div class="input-group-text">
                        <span style="font-size: 20px; border: none" class="input-group-text">
                            <i class="fas fa-box"></i>
                        </span>
                    </div>
                    <input type="text" class="form-control" placeholder="Solución" v-model="Subsubcategory.name" />
                </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-xs-12">
                <label class="mb-3 mt-2">Contrato EPS</label>
                <div class="input-group input-group-sm">
                    <div class="input-group-text">
                        <span style="font-size: 20px; border: none" class="input-group-text">
                            <i class="fa fa-medkit"></i>
                        </span>
                    </div>
                    <select class="form-control form-control-sm" aria-label="Default select example" v-model="Subsubcategory.insurance_contrat_id">
                        <option disabled selected value="">seleccione...</option>
                        <option v-for="(item, index) in contracts" :key="index" :value="item.id">
                            {{ item.name }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-xs-12">
                <label class="mb-3 mt-2">Prec. Kairos</label>
                <div class="input-group input-group-sm">
                    <div class="input-group-text">
                        <span style="font-size: 20px; border: none" class="input-group-text">
                            <i class="fa fa-barcode"></i>
                        </span>
                    </div>
                    <input type="number" class="form-control" placeholder="Kairos " v-model="Subsubcategory.kairos" step="0.1" @input="validateInput('kairos')" />
                </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-xs-12">
                <label class="mb-3 mt-2">Unidad</label>
                <div class="input-group input-group-sm">
                    <div class="input-group-text">
                        <span style="font-size: 20px; border: none" class="input-group-text">
                            <i class="fa fa-barcode"></i>
                        </span>
                    </div>
                    <input type="number" class="form-control" placeholder="Unidad" v-model="Subsubcategory.unidad" @input="validateInput('unidad')" />
                </div>
            </div>
        </div>

        <div class="row d-flex">
            <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-xs-12">
                <label class="mb-3 mt-2">Prec.Particular</label>
                <div class="input-group input-group-sm">
                    <div class="input-group-text">
                        <span style="font-size: 20px; border: none" class="input-group-text">
                            <i class="fa fa-barcode"></i>
                        </span>
                    </div>
                    <input type="number" class="form-control" placeholder="Particular" v-model="Subsubcategory.particular" step="0.1" @input="validateInput('particular')" />
                </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-xs-12">
                <label class="mb-3 mt-2">Concentración</label>
                <div class="input-group input-group-sm">
                    <div class="input-group-text">
                        <span style="font-size: 20px; border: none">
                            <i class="fa fa-percent"></i>
                        </span>
                    </div>
                    <input type="text" class="form-control" placeholder="Concentración" v-model="Subsubcategory.concentration" />
                </div>
            </div>
           
            <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-xs-12">
                <label class="mb-3 mt-2">Comercial</label>
                <div class="input-group input-group-sm">
                    <div class="input-group-text">
                        <span style="font-size: 20px; border: none">
                            <i class="fa fa-tags"></i>
                        </span>
                    </div>
                    <input type="text" class="form-control" placeholder="Comercial" v-model="Subsubcategory.commercial" />
                </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-xs-12">
                <label class="mb-3 mt-2">Marca</label>
                <div class="input-group input-group-sm">
                    <div class="input-group-text">
                        <span style="font-size: 20px; border: none">
                            <i class="fa fa-building"></i>
                        </span>
                    </div>
                    <select class="form-control form-control-sm" aria-label="Default select example" v-model="Subsubcategory.brand_id">
                        <option disabled selected value="">seleccione...</option>
                        <option v-for="(item, index) in dataMarca" :key="index" :value="item.id">
                            {{ item.name }}
                        </option>
                    </select>
                </div>
            </div>
        </div>

        <div class="row d-flex">
          
            <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-xs-12">
                <label class="mb-3 mt-2">Presentation</label>
                <div class="input-group input-group-sm">
                    <div class="input-group-text">
                        <span style="font-size: 20px; border: none">
                            <i class="fa fa-file-alt"></i>
                        </span>
                    </div>
                    <input type="text" class="form-control" placeholder="Presentation" v-model="Subsubcategory.presentation" />
                </div>
            </div>

            <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-xs-12">
                <label class="mb-3 mt-2">Forma</label>
                <div class="input-group input-group-sm">
                    <div class="input-group-text">
                        <span style="font-size: 20px; border: none">
                            <i class="fa fa-file"></i>
                        </span>
                    </div>
                    <input type="text" class="form-control" placeholder="Forma" v-model="Subsubcategory.format" />
                </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-xs-12">
                <label class="mb-3 mt-2">Stock-Mínimo</label>
                <div class="input-group input-group-sm">
                    <div class="input-group-text">
                        <span style="font-size: 20px; border: none">
                            <i class="fa fa-box"></i>
                        </span>
                    </div>
                    <input type="number" class="form-control" placeholder="Stock-Minimo" v-model="Subsubcategory.minimum_stock" @input="validateInput('minimum_stock')" />
                </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-xs-12">
                <label class="mb-3 mt-2">Sector</label>
                <div class="input-group input-group-sm">
                    <div class="input-group-text">
                        <span style="font-size: 20px; border: none">
                            <i class="fa fa-building"></i>
                        </span>
                    </div>
                    <select class="form-control form-control-sm" aria-label="Default select example" v-model="Subsubcategory.sector_id">
                        <option disabled selected value="">seleccione...</option>
                        <option v-for="(item, index) in dataSector" :key="index" :value="item.id">
                            {{ item.name }}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        
        <div class="row d-flex">
           
            <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-xs-12">
                <label class="mb-3 mt-2">Sub Sector</label>
                <div class="input-group input-group-sm">
                    <div class="input-group-text">
                        <span style="font-size: 20px; border: none">
                            <i class="fa fa-building"></i>
                        </span>
                    </div>
                    <select class="form-control form-control-sm" aria-label="Default select example" v-model="Subsubcategory.sub_sectors_id">
                        <option disabled selected value="">seleccione...</option>
                        <option v-for="(item, index) in filteredSubSector" :key="index" :value="item.id">
                            {{ item.name }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-xs-12">
                <label class="mb-3 mt-2">Ubicacion</label>
                <div class="input-group input-group-sm">
                    <div class="input-group-text">
                        <span style="font-size: 20px; border: none">
                            <i class="fas fa-map-marker"></i>
                        </span>
                    </div>
                    <input type="text" class="form-control" placeholder="Ubicacion Fisica" v-model="Subsubcategory.location" />
                </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-xs-12 d-flex justify-content-center align-items-center">
                <div class='me-lg-3' style="margin-right: 20px">
                    <label class="mt-2">&nbsp;</label>
                    <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckEps" :checked="Subsubcategory.eps == 1 ? true : false" @change="mtdChangeStateEps" />
                        <label class="form-check-label" for="flexSwitchCheckEps">EPS</label>
                    </div>
                </div>
                <div >
                    <label class="mt-2">&nbsp;</label>
                    <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckParti" :checked="Subsubcategory.parti == 1 ? true : false" @change="mtdChangeStateParti" />
                        <label class="form-check-label" for="flexSwitchCheckParti">PARTICULAR</label>
                    </div>
                </div>
            </div>
        </div>

        <template #footer>
            <button class="btn btn-success btn-sm" @click="mtdInsertData" :disabled="cpButtonForm">
                <i class="fas fa-save" />&nbsp; {{ action == "store" ? "Registrar" : "Editar" }}
            </button>
            &nbsp;
        </template>
        <SweetAlert :swal="swal" ref="SweetAlert" />
    </CModal>
</template>

<script>
import CModal from "../../shared/modals/CModal.vue";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import {
    mapActions
} from "vuex";

export default {
    name: "c-maintenance-modal-documento",
    components: {
        CModal,
        SweetAlert
    },
    data() {
        return {
            swal: null,
            dataProvider: [],
            filter_name: "",
            Subsubcategory: {
                name: "",
                Id: "",
                state: "",
                categorie_id: "",
                subcategorie_id: "",
                specialty_id: "",
                insurance_contrat_id: "",
                kairos: 0.00,
                unidad: 0,
                particular: 0.00,
                eps: false,
                parti: false,
                molecule: "",
                commercial: "",
                brand: "",
                brand_id: "",
                presentation: "",
                concentration: "",
                format: "",
                stock: 0,
                minimum_stock: 0,
                state: "",
                sub_sub_category_id: null,
                sector: "",
                sector_id: "",
                location: "",
                sub_sectors_id: ""
            },
            contracts: [],
            dataMarca:[],
            dataSector:[],
        };
    },
    props: {
        title: String,
        boo_modal: Boolean,
        action: String,
        subsubcategoryObject: Object,
    },
    watch: {
        action: {
            handler(newVal) {
                this.mtdGetData();
                this.mtdGetBrand();
                this.mtdGetSector();
            },
            immediate: true 
        },
        subsubcategoryObject: {
            handler(newVal) {
                if (newVal) {
                    if (this.action == 'edit') {
                        this.Subsubcategory = {
                            ...newVal
                        };
                    }
                }
            },
            immediate: true,
        },
    },
    computed: {
        cpButtonForm: function () {
            return false;
        },
        filteredSubSector() {
            if (this.Subsubcategory.sector_id) {
                const selectedSector = this.dataSector.find(
                    (sector) => sector.id === this.Subsubcategory.sector_id
                );
                if (selectedSector) {
                    return selectedSector.sub_sector;
                }
            }
            return [];
        },
    },
    methods: {
        ...mapActions(["get", "post"]),

        mtdGetBrand: function () {
            this.get({
                url: this.$store.getters.get__url + "/Brand",
                token: this.$store.getters.get__token,
            })
            .then((response) => {
                this.dataMarca = response.data;
            })
            .catch((errors) => {
                this.$refs.SweetAlert.showError(errors);
            });
        },
        mtdGetData: function () {
            this.get({
                url: this.$store.getters.get__url + "/Medicament",
                token: this.$store.getters.get__token,
            })
            .then((response) => {
                this.contracts = response.data;
            })
            .catch((errors) => {
                this.$refs.SweetAlert.showError(errors);
            });
        },
        mtdGetSector: function () {
            this.get({
                url: this.$store.getters.get__url + "/Sector",
                token: this.$store.getters.get__token,
            })
            .then((response) => {
                this.dataSector = response.data.filter(sector => sector.state === 1);
            })
            .catch((errors) => {
                this.$refs.SweetAlert.showError(errors);
            });
        },
        filterKey: function (e) {
            let b = false;
            b = /^[0-9]+$/.test(e.key);
            if (b == false && e.key != "Backspace") {
                e.preventDefault();
            }
        },
        mtdChangeStateEps: function () {
            this.Subsubcategory.eps = this.Subsubcategory.eps == 1 ? 0 : 1;
        },
        mtdChangeStateParti: function () {
            this.Subsubcategory.parti = this.Subsubcategory.parti == 1 ? 0 : 1;
        },
        mtdclosemodal() {
            this.Subsubcategory = {
                    name: "",
                    Id: "",
                    state: "",
                    categorie_id: "",
                    subcategorie_id: "",
                    specialty_id: "",
                    insurance_contrat_id: "",
                    kairos: 0.00,
                    unidad: 0,
                    particular: 0.00,
                    eps: false,
                    parti: false,
                    molecule: "",
                    commercial: "",
                    brand: "",
                    brand_id: "",
                    presentation: "",
                    concentration: "",
                    format: "",
                    stock: 0,
                    minimum_stock: 0,
                    state: "",
                    sub_sub_category_id: null,
                    sector: "",
                    sector_id: "",
                    location: "",
                },
                this.$emit('closeModal', this.filter_name);
        },
        mtdInsertData: function () {
            if (this.isFormValid()) {
            if (this.Subsubcategory.sector_id === "" || this.Subsubcategory.sector_id === null) {
                this.Subsubcategory.sector_id = "";
            }
            if (this.Subsubcategory.sector_id === "" || this.Subsubcategory.sector_id === null) {
                this.Subsubcategory.sector_id = "";
            }
            if (this.action == "store") {
                this.Subsubcategory.molecule =  this.Subsubcategory.name
                    this.post({
                        url: this.$store.getters.get__url + "/Medicament/storeSoluciones",
                        token: this.$store.getters.get__token,
                        params: this.Subsubcategory,
                    })
                    .then((response) => {
                        if (response.data.state == 0) {
                            this.$refs.SweetAlert.showSuccess(response.message);
                            this.filter_name = response.data.data.name;
                            this.mtdclosemodal();
                        } 
                    })
                    .catch((errors) => {
                        this.$refs.SweetAlert.showError(errors);
                    });
            } else {
                this.mtdModifyData();
            }
            }
        },
        //metodo modificar
        mtdModifyData: function () {
            if (this.isFormValid()) {
            if (this.Subsubcategory.sector_id === "" || this.Subsubcategory.sector_id === null) {
                this.Subsubcategory.sector_id = "";
            }
            if (this.Subsubcategory.sub_sectors_id === "" || this.Subsubcategory.sub_sectors_id === null) {
                this.Subsubcategory.sub_sectors_id = "";
            }
            this.post({
                url: this.$store.getters.get__url + "/Medicament/update",
                token: this.$store.getters.get__token,
                params: this.Subsubcategory,
            })
            .then((response) => {
                if (response.data.state == 0) {
                    this.$refs.SweetAlert.showSuccess("Modificación Exitosa");
                    this.filter_name = response.data.data.name;
                    this.mtdclosemodal();
                } 
            })
            .catch((errors) => {
                this.$refs.SweetAlert.showError(errors);
            });
            }
        },

        validateInput(field) {
            if (this.Subsubcategory[field] < 0) {
                this.Subsubcategory[field] = 0;
            }
        },

        isFormValid() {
            if (this.Subsubcategory.name.trim() === "" ||
                this.Subsubcategory.brand_id === "" || this.Subsubcategory.brand_id === null 
                //|| this.Subsubcategory.sector_id === "" || this.Subsubcategory.sector_id === null
                ) {
                    this.$refs.SweetAlert.showWarning("Por favor completa el Medicamento y Marca");
                    return false;
                }
            return true;
        },

    },
    

};
</script>