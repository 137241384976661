<template>
    <CModal @mtdclosemodal="mtdClose" :show="boo_modal" :close-on-backdrop="false" :centered="false" :title="title" :size="'md'" :btn_cancel="true" :btn_ok="true" :booTitile="true">
        <div class="px-3">
            <div class="row mx-0 px-0">
                <div class="mb-2 col-xl-12 px-1">
                    <textarea class="form-control form-control-sm" v-model="item" rows="5" disabled></textarea>
                </div>
            </div>
        </div>
    </CModal>
</template>

<script>
import CModal from "../../shared/modals/CModal.vue";
import {
    mapActions
} from "vuex";

export default {
    name: "c-maintenance-modal-documento",
    components: {
        CModal,
    },
    data() {
        return {
            data_detail: {
            },
        };
    },
    watch: {
    },
    props: {
        title: String,
        boo_modal: Boolean,
        item: String,
    },
    computed: {
    },
    methods: {
        ...mapActions(["get", "post"]),
        mtdClose() {
            this.$emit("mtdCloseModalRe");
        },
    },
    

};
</script>