<template>
    <CModal @mtdclosemodal="mtdclosemodal" :show="boo_modal" :close-on-backdrop="false" :centered="false" :title="title" :size="'xl'" :btn_cancel="true" :btn_ok="true" :booTitile="true">
        
        <div class="row px-5 mb-2">
            <table class="table table-bordered border-main mb-0 px-0 text-xs">
                <caption class=" my-0 py-0"></caption>
                <thead class="">
                    <tr class="text-white border-white text-center bg-main">
                        <th scope="col">N°</th>
                        <th scope="col">N.ANTIGUO</th>
                        <th scope="col">N.NUEVA</th>
                        <th scope="col">MOTIVO</th>
                        <th scope="col">FECHA CAMBIO</th>
                    </tr>
                </thead>
                <tbody v-if="this.notesedit.length > 0">
                    <tr v-for="(note, index) in this.notesedit" :key="index" class="px-1 border-bottom">
                        <td class="text-center"><span>{{ index + 1 }}</span></td>
                        <td class="text-start"><span v-html="note.comment_old.replace(/\n/g, '<br>')"> </span></td>
                        <td class="text-start"><span v-html="note.comment_new.replace(/\n/g, '<br>')"> </span></td>
                        <td class="text-start"><span v-html="note.motive.replace(/\n/g, '<br>')"> </span></td>
                        <td class="text-center"><span>{{ note.datenew }} | {{ note.hour }}</span></td>
                    </tr>
                </tbody>
                <tbody class=" text-center" v-else>
                <tr>
                    <td colspan="19" class=" text-center">
                    <strong> No hay resultados</strong>
                    </td>
                </tr>
                </tbody>
            </table>
            </div>
        
        <SweetAlert :swal="swal" ref="SweetAlert" />
    </CModal>
</template>

<script>
import CModal from "@/components/shared/modals/CModal.vue";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import { mapActions } from "vuex";
import moment from "moment";

export default {
    name: "c-medico-modal-proc-invasivo-view",

    data() {
        return {
            swal: null,
            
        }
    },
    components: {
        CModal,
        SweetAlert
    },
    props: {
        title: String,
        boo_modal: Boolean,
        notesedit:{
            type: Array,
            default: () => []
        }
    },
   
    created() {
    },
    mounted() {
    },
    methods: {
        ...mapActions(['get', 'post']),
       
        mtdclosemodal() {
            this.$emit('mtdclosemodalview');
        },
       
    },
}
</script>