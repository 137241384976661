<template>
    <div>
        <div class="px-3 py-2">
            <div class="d-flex"> 
                <div  class="btn-home mb-4 ms-auto">
                    <button type="button" class="btn btn-outline-main" @click="returnHome">
                        <i class="fas fa-home-lg-alt"></i> Inicio
                    </button>
                </div>
            </div>
            <div id="div-modal-cxc" class="d-flex justify-content-center">
                <div id="div-cxc" class="card w-100">
                    <div class="card-header text-white h4 text-center" style="background: #900052">
                        <strong>INVENTARIO</strong>
                    </div>
                    <div class="card-body w-100">
                        <div class="row">
                            <!--Filtro-->
                            <div class="col-md-12 py-2">
                                <div class="row d-flex">
                                <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-xs-12 mt-1 mb-2">
                                    <div class="input-group input-group-sm">
                                        <span class="input-group-text col-md-4">ALMACÉN</span>
                                        <v-select
                                        class="text-dark flex-grow-1"
                                        label="name"
                                        :options="dataAlmacen"
                                        placeholder="- Seleccionar -"
                                        v-model="selectedAlma"
                                        :reduce="(name) => name.id"
                                        @input="mtdGetData"
                                        >
                                        <template slot="no-options">
                                            No hay Almacen para la busqueda.
                                        </template>
                                        </v-select>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-2">
                                    <div class="input-group">
                                          <span class="input-group-text ">Hasta</span>
                                          <input type="date" aria-label="shift_date" class="form-control form-control-sm" id="end" v-model="filter.end" @change="mtdGetData"
                                          :max="currentDate" />
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-2">
                                    <div class="card" style="padding: 5px;">
                                        <div class="d-flex align-items-center">
                                            <span class="me-3 ms-3">Tipo</span>
                                            <div class="form-check form-check-inline me-3">
                                                <input class="form-check-input" type="radio" name="filterType" id="inlineRadio1" value="1" v-model="filterType" @change="mtdGetData">
                                                <label class="form-check-label" for="inlineRadio1">Vencido</label>
                                            </div>
                                            <div class="form-check form-check-inline me-3">
                                                <input class="form-check-input" type="radio" name="filterType" id="inlineRadio2" value="3" v-model="filterType" @change="mtdGetData">
                                                <label class="form-check-label" for="inlineRadio2">Por vencer</label>
                                            </div>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="radio" name="filterType" id="inlineRadio3" value="2" v-model="filterType" @change="mtdGetData">
                                                <label class="form-check-label" for="inlineRadio3">Todos</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-xl-1 col-lg-3 col-md-3 col-sm-12 col-xs-12 mb-2">
                                        <button
                                        class="btn btn-sm w-100 text-white w-100 py-2"
                                        style="background: #900052"
                                        @click="viewpdf()"
                                        :disabled="mtddisable"
                                        >
                                        <i class="fas fa-file-pdf"  style="font-size: 18px;"></i>&nbsp; Pdf
                                        </button>
                                </div>
<!--                                 <div class="col-xl-1 col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                        <button
                                        class="btn btn-sm w-100 text-white w-100 py-2"
                                        style="background: #900052"
                                        @click="viewExcel()"
                                        :disabled="mtddisable"
                                        >
                                        <i class="fas fa-file-excel"  style="font-size: 18px;"></i>&nbsp; Excel
                                        </button>
                                </div> -->
                                <div :class="{'col-xl-10 col-lg-9 col-md-8 col-sm-4 col-xs-4 mb-2': showTable, 'col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-2 mb-2': !showTable}">
                                    <div class="input-group input-group">
                                        <span class="input-group-text text-white bg-main"
                                        ><i class="fas fa-search"></i
                                        ></span>
                                        <input
                                        @keyup="calculateData(10)"
                                        v-model="search"
                                        type="text"
                                        class="form-control form-control"
                                        placeholder="Molécula / Comercial / Marca"
                                        />
                                    </div>
                                </div>
                                <div class="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-xs-4 mb-2" v-if="showTable">
                                    <div class="input-group input-group">
                                        <span class="input-group-text text-white bg-main"
                                        ><strong>S/ TOTAL</strong></span>
                                        <input
                                        v-model="totalValorizado"
                                        type="text"
                                        class="form-control form-control text-center"
                                        readonly
                                        />
                                    </div>
                                </div>
                                </div>
                            </div>
                            <!--Fin Filtro-->

                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-3 mb-2" id="content-timelinetable"  v-if="showTable">
                                <table  id="tb-anular" class="table table-bordered border-main align-middle">
                                    <thead>
                                        <tr class="text-white border-white text-center font-size-ca" style="background: #900052; vertical-align: middle">
                                            <th scope="col">COMERCIAL - MOLECULA - MARCA</th>
                                            <th scope="col">LOTE</th>
                                            <th scope="col">FECHA VCTO
                                                <i class="fa fa-arrow-up flecha" @click="sortData('date_vcto', 'asc')"></i>
                                                <i class="fa fa-arrow-down flecha" @click="sortData('date_vcto', 'desc')"></i>
                                            </th>

                                            <th scope="col" class="table-th-acciones">CANTIDAD ACTUAL</th>
                                            <th scope="col">PRECIO DE COMPRA c/ IGV
                                                <i class="fa fa-arrow-up flecha" @click="sortData('price_unit', 'asc')"></i>
                                                <i class="fa fa-arrow-down flecha" @click="sortData('price_unit', 'desc')"></i>
                                            </th>
                                            <th scope="col" class="nowrap">S/ VALORIZADO 
                                                <i class="fa fa-arrow-up flecha" @click="sortData('valorizado', 'asc')"></i>
                                                <i class="fa fa-arrow-down flecha" @click="sortData('valorizado', 'desc')"></i>
                                            </th>
                                        </tr>
                                    </thead>

                                    <tbody class="custom-table">
                                            <tr v-if="cpData.length === 0">
                                                <td class="text-center align-middle" colspan="6">Sin inventario</td>
                                            </tr>
                                            <tr v-for="(movement) in cpData" :key="movement.id">
                                                <td class="text-center align-middle">{{  movement.product}}</td>
                                                <td class="text-center align-middle">{{ movement.lote ? movement.lote.toUpperCase() : '-' }}</td>
                                                <td class="text-center align-middle">{{ movement.date_vcto | formatDate }}</td>
                                                <td class="text-center align-middle">{{ movement.quantity }}</td>
                                                <td class="text-center align-middle"> S/ {{ movement.price_unit }}</td>
                                                <td class="text-center align-middle"> S/ {{ movement.valorizado }}</td>
                                            </tr>
                                            <tr>
                                                <th colspan="5" scope="col" class="text-center align-middle color-main" style="font-size: 12px">TOTAL</th>
                                                <th colspan="1" scope="col" class="text-center align-middle color-main" style="font-size: 12px">S/ {{ pageTotalValorizado.toFixed(2) }}</th>
                                            </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 text-center">
                                <!-- paginado -->
                                <div v-if="cpData.length > 0 && showTable" class="d-flex justify-content-center mb-2">
                                    <nav aria-label="Page navigation example">
                                        <ul class="pagination mb-1">
                                            <li class="page-item">
                                                <a class="page-link page" href="javascript:void(0)" @click="backPage">Anterior</a>
                                            </li>
                                            <li v-if="limitedStepPagination[0] !== 1" class="page-item">
                                                <a :class="selectPage === 1 ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(1)">1</a>
                                            </li>
                                            <li v-if="limitedStepPagination[0] > 2" class="page-item disabled">
                                                <span class="page-link">...</span>
                                            </li>
    
                                            <li v-for="(item, index) in limitedStepPagination" :key="index" class="page-item">
                                                <a :class="selectPage === item ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(item)">{{ item }}
                                                </a>
                                            </li>
                                            <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] <stepPagination.length - 1" class="page-item disabled">
                                                <span class="page-link">...</span>
                                            </li>
                                            <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] !== stepPagination.length" class="page-item">
                                                <a :class="selectPage === limitedStepPagination.length + 2 ? 'page-link active':'page-link'" href="javascript:void(0)" @click="selectedPage(stepPagination.length)">{{ stepPagination.length }}
                                                </a>
                                            </li>
                                            <li class="page-item">
                                                <a class="page-link page" href="javascript:void(0)" @click="nextPage">Siguiente</a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <SweetAlert :swal="swal" ref="SweetAlert" />
    </div>
</template>

<script>
  import {mapActions} from "vuex";
  import moment from "moment";
  import exportFromJson from "export-from-json";
  import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';

export default {
    name: "Inventary",
    components: {
        SweetAlert,
    },
    data() {
        return {
            swal: null,
            search: "",
            dataShow: [],
            showTable: false,
            dataIn: [],
            dataAlmacen: [],
            selectedAlma: null,
            filter: {
                init: null,
                end: null,
            },
            filterType: 2,
            sortDirection: null, 
            sortKey: null, 
            excelData: [],
            //Pag
            page: 0,
            selectPage: 1,
            stepPagination: [],
            totalPagesToShow: 4,
            action: "",
            // Estado de orden
            sortKey: '',
            sortOrder: '',
            currentDate: moment().format('YYYY-MM-DD'),
            totalValorizado: 0,
            pageTotalValorizado: 0,
        };
    },
    watch: {
        selectedAlma(newVal) {
            if (newVal === null) {
                this.dataIn = []; 
                this.showTable = false;
            }
        },
        selectPage() {
            this.calculateData(10);
        },
    },
    computed: {
        mtddisable() {
            return !(this.selectedAlma !== null &&  this.filter.end !== null);
        },
        cpData() {
            return this.dataShow;
        },
        limitedStepPagination() {
            const totalPages = this.stepPagination.length;
            const currentPage = this.selectPage;

            let startPage = 1;
            let endPage = totalPages;
            if (totalPages > this.totalPagesToShow) {
                const halfPagesToShow = Math.floor(this.totalPagesToShow / 2);

                if (currentPage > halfPagesToShow) {
                    startPage = currentPage - halfPagesToShow;
                    endPage = currentPage + halfPagesToShow - 1;

                    if (endPage > totalPages) {
                        endPage = totalPages;
                        startPage = totalPages - this.totalPagesToShow + 1;
                    }
                } else {
                    endPage = this.totalPagesToShow;
                }
            }

            return Array.from({
                    length: endPage - startPage + 1,
                },
                (_, i) => startPage + i
            );
        },
        totalValorizadoPorPagina() {
            return this.cpData.reduce((total, item) => total + item.valorizado, 0);
        }
    },
    created() {
        moment.locale("es");
        this.filter.end =
            moment().format("L").substr(6, 4) +
            "-" +
            moment().format("L").substr(3, 2) +
            "-" +
            moment().format("L").substr(0, 2);
        this.filter.init = this.filter.end;

        this.vercampus();
    },
    methods: {
        ...mapActions(["get", "post"]),

        mtdGetData: function () {
            if(this.selectedAlma == null){
                this.$refs.SweetAlert.showWarning("Seleccione un Almacén");
            }else{
               /*  let filterType = 0; 
                if (this.filterType.includes("1") && this.filterType.includes("2")) {
                    filterType = 0;
                } else if (this.filterType.includes("1")) {
                    filterType = 1;
                } else if (this.filterType.includes("2")) {
                    filterType = 2;
                } */
                this.post({
                    url: this.$store.getters.get__url + "/Inventario/index",
                    token: this.$store.getters.get__token,
                    params: {
                        alma: this.selectedAlma,
                        end: this.filter.end,
                        filterType: this.filterType,
                    },
                })
                .then((response) => {
                    this.dataIn = response.data.data;
                    this.totalValorizado = response.data.total;
                    this.excelData= [];
                    if (this.dataIn.length === 0) {
                        this.showTable = false;
                        this.$refs.SweetAlert.showWarning("Sin Datos");
                    } else {
                        this.showTable = true;
                        this.calculateData(10);
                        this.dataIn.forEach((element, index) => {
                            this.excelData.push({
                                "N°": index + 1,
                                "COMERCIAL - MOLECULA - MARCA": element.product,
                                "LOTE": element.lote ? element.lote : '-',
                                "FECHA DE VENCIMIENTO": element.date_vcto,
                                "CANTIDAD ACTUAL": element.quantity,
                                "PRECIO": element.price_unit,
                                "VALORIZADO S/": element.valorizado
                            });
                        });
                    }
                })
                .catch((errors) => {
                    this.$refs.SweetAlert.showError(errors);
                });
            }
        },
        calculateData(items) {
            let filteredData = Object.values(this.dataIn);
            let indexInitial = this.selectPage;
            if (this.search.length >= 3 && this.search != "") {
                let query = this.search.toString().toUpperCase();
                filteredData = this.dataIn.filter((element) => {
                    let commercial = element.commercial ? element.commercial.toString().toUpperCase() : "";
                    let molecule = element.molecule ? element.molecule.toString().toUpperCase() : "";
                    let marca = element.marca ? element.marca.toString().toUpperCase() : "";
                    return commercial.includes(query) || molecule.includes(query) || marca.includes(query);
                });
                indexInitial = 1;
            }
            let totalPages = Math.ceil(filteredData.length / items);
            this.stepPagination = Array.from({
                    length: totalPages,
                },
                (_, index) => index + 1
            );

            let startIndex = (indexInitial - 1) * items;
            let endIndex = startIndex + (items - 1);
            this.dataShow = filteredData.slice(startIndex, endIndex + 1);
            this.pageTotalValorizado = this.dataShow.reduce((total, item) => total + parseFloat(item.valorizado || 0), 0);
            this.count = this.count + 1;
        },
        sortData(key, order) {
            if (this.sortKey && this.sortKey !== key) {
                this.sortOrder = ''; 
            }
            this.sortKey = key;
            this.sortOrder = order;

            this.dataIn.sort((a, b) => {
                let result = 0;

                // Convertir valores a números si la clave es 'price_unit' o 'valorizado'
                const valueA = key === 'price_unit' || key === 'valorizado' ? parseFloat(a[key]) : a[key];
                const valueB = key === 'price_unit' || key === 'valorizado' ? parseFloat(b[key]) : b[key];

                if (valueA < valueB) result = -1;
                if (valueA > valueB) result = 1;

                return order === 'asc' ? result : -result;
            });
            this.calculateData(10); 
        },

        clearData() {
            this.dataIn = [];   
            this.showTable = false;
        },

        viewpdf: function () {
            let filterType = 'todos'; 
/*             if (this.filterType.includes("1") && this.filterType.includes("2")) {
                filterType = 'todos';
            } else if (this.filterType.includes("1")) {
                filterType = 'vencidos';
            } else if (this.filterType.includes("2")) {
                filterType = 'por_vencer';
            } */
            if (this.filterType == 2) {
                filterType = 'todos';
            } else if (this.filterType == 1) {
                filterType = 'vencidos';
            } else if (this.filterType == 3) {
                filterType = 'por_vencer';
            }
            window.open(
                this.$store.getters.get__url +
                "/viewpdfInventario/" +
                this.selectedAlma + "/" + this.filter.end + "/" + filterType,
                "_blank"
            );
        },

        viewExcel: function () {
            const datos = this.excelData;
            const fileName = 'INVENTARIO';
            const typeExport = exportFromJson.types.xls;
            exportFromJson({
                data: datos,
                fileName: fileName,
                exportType: typeExport,
            });
        },

        vercampus: function () {
            this.filter.campus_id = this.$store.getters.get__campus;
            this.get({
                    url: this.$store.getters.get__url + "/Kardex/filterAlmacen/" +this.filter.campus_id,
                    token: this.$store.getters.get__token,

                })
                .then((response) => {
                    this.dataAlmacen = response.data;
                })
                .catch((errors) => {
                    this.$refs.SweetAlert.showError(errors);
                });
        },


        //Paginacion
        backPage() {
            this.selectPage = this.selectPage > 1 ? this.selectPage - 1 : 1;
        },
        nextPage() {
            this.selectPage =
                this.selectPage < this.stepPagination.length ?
                this.selectPage + 1 :
                this.stepPagination.length;
        },
        selectedPage(page) {
            this.selectPage = page;
        },
        returnHome: function () {
            this.$emit("returnHome");
        },
    },
    filters: {
        formatDate(date) {
            return date != null ? moment(date).format("DD/MM/YYYY") : "-";
        },
        formatDateTime(dateTime) {
            return dateTime != null ? moment(dateTime).format("DD/MM/YYYY HH:mm") : "-";
        },
    },
};
</script>

<style scoped>
.custom-table th,
.custom-table td {
    font-size: 11px;
}

.large-text {
    font-size: 9px;
}
.nowrap {
  white-space: nowrap;
}
.flecha {
    font-size: 12px; 
    cursor: pointer; 
    margin-left: 4px; 
    margin-right: 1px;
    color: #EDEAEC;
}
.font-size-ca {
    font-size: 14px !important;
}
</style>
