<template>
<div>
    <div class="pt-2 pb-5">
        <div class="d-flex justify-content-center mt-4">
            <div class="card w-100 text-center">
                <div class="card-header text-white bg-main h4">
                    <div class="d-flex flex-column flex-sm-row" style="position: relative;">
                        <div class="btn-home-jefatura ml-sm-auto mt-2 mt-sm-0" style="position: relative;">
                            <button type="button" class="btn btn-sm" @click="returnHome">
                                <i class="fas fa-arrow-left"></i> Volver
                            </button>
                        </div>
                        <div class="flex-sm-grow-1 text-center text-sm-left">
                            <strong>CONTROL DIARIO DE CONSULTAS</strong>
                        </div>
                    </div>
                </div>
                <div class="card-body w-100">
                    <div class="row d-flex mt-2">
                        <div class="col-xl-12 col-lg-12 col-md-12 py-2">
                            <div class="row d-flex">
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-2">
                                    <div class="input-group input-group-sm">
                                        <span class="input-group-text col-md-3">Desde</span>
                                        <input type="date" aria-label="shift_date" class="form-control form-control-sm" id="startdate" v-model="filter.date_start" @change="mtdGetData" />
                                    </div>
                                </div>
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                    <div class="input-group input-group-sm">
                                        <span class="input-group-text col-md-3">Hasta</span>
                                        <input type="date" aria-label="shift_date" class="form-control form-control-sm" id="enddate" v-model="filter.date_end" @change="mtdGetData" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div v-if="cpData.length > 0" class="d-flex justify-content-end mb-2">
                            <nav aria-label="Page navigation example ">
                                <ul class="pagination mb-0 ">
                                    <li class="page-item">
                                        <a class="page-link page" href="javascript:void(0)" @click="backPage">Anterior</a>
                                    </li>
                                    <li v-if="limitedStepPagination[0] !== 1" class="page-item">
                                        <a :class="selectPage === 1 ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(1)">1</a>
                                    </li>
                                    <li v-if="limitedStepPagination[0] > 2" class="page-item disabled">
                                        <span class="page-link">...</span>
                                    </li>

                                    <li v-for="(item, index) in limitedStepPagination" :key="index" class="page-item">
                                        <a :class="selectPage === item ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(item)">{{ item }}
                                        </a>
                                    </li>
                                    <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] <stepPagination.length - 1" class="page-item disabled">
                                        <span class="page-link">...</span>
                                    </li>
                                    <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] !== stepPagination.length" class="page-item">
                                        <a :class="selectPage === limitedStepPagination.length + 2 ? 'page-link active':'page-link'" href="javascript:void(0)" @click="selectedPage(stepPagination.length)">{{ stepPagination.length }}
                                        </a>
                                    </li>
                                    <li class="page-item">
                                        <a class="page-link page" href="javascript:void(0)" @click="nextPage">Siguiente</a>
                                    </li>
                                </ul>
                            </nav>
                        </div>

                        <div class="col-md-12 mb-4" id="content-timelinetable">
                            <table id="dtb-cxc" class="table table-bordered border-main text-center align-middle">
                                <caption class="py-0 my-0"></caption>
                                <thead>
                                    <tr class="text-white border-white text-center" style="background: #900052; vertical-align: middle">
                                        <th scope="col">N°</th>
                                        <th scope="col">FECHA</th>
                                        <th scope="col">DNI</th>
                                        <th scope="col">PACIENTE</th>
                                        <th scope="col">EDAD</th>
                                        <th scope="col">MEDICO ESPECIALISTA</th>
                                        <th scope="col">ESPECIALIDAD</th>
                                        <th scope="col">DIAGNOSTICO MÉDICO/CIE10</th>
                                        <th scope="col">SIGNOS VITALES</th>
                                        <th scope="col">ACCIONES</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-if="cpData.length === 0">
                                        <td colspan="10" class="text-center">No se encontraron resultados</td>
                                    </tr>
                                    <tr v-for="(item, index) in cpData" :key="index" class="border border-main text-xs">
                                        <td>{{ mtdNumber(index) }}</td>
                                        <td>{{ item.date | formatDate }} </td>
                                        <td>{{ item.document }}</td>
                                        <td>{{ item.patient }}</td>
                                        <td>{{ item.yearOld }}</td>
                                        <td>{{ item.doctor_name }}</td>
                                        <td>{{ item.specialty }}</td>
                                        <td>{{ item.cie }}</td>
                                        <td class="text-vital_signs">
                                            <div v-if="item.vital_signs">
                                                <div v-for="(item2, index1) in JSON.parse(item.vital_signs)" :key="index1">
                                                    <span><b>{{ item2.label }}: </b>&nbsp; {{ item2.value }}</span>
                                                </div>
                                            </div>
                                            <div v-else>
                                                <div v-for="(item2, index1) in vital_signs" :key="index1">
                                                    <span><b>{{ item2.label }}: </b>&nbsp; {{ item2.value }}</span>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="text-center d-flex justify-content-center">
                                                <button class="btn btn-sm a-success text-white" @click="mtdModalShow(item)" :disabled="item.cie !== ''">
                                                    <i class="fa fa-edit"></i>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>

                            </table>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <CModalEditBook :title="modal.title" :boo_modal="modal.boo_modal" :payload="modal.payload" @mtdclosemodal="mtdclosemodal"/>

</div>
</template>

<script>
import {
    mapActions
} from "vuex";
import moment from "moment";
import CModalEditBook from './modal/cModalEditConsultingBook.vue'
export default {
    name: "c-asistencial-control-controlConsultas",
    components:{
        CModalEditBook
    },
    data() {
        return {
            filter: {
                date_start: "",
                date_end: "",
            },
            data: [],
            dataShow: [],
            modal: {
                title: "",
                boo_modal: false,
                payload: {},
            },
            vital_signs: [{
                    id: 1,
                    label: 'PESO',
                    value: ''
                }, {
                    id: 2,
                    label: 'TALLA',
                    value: ''
                },
                {
                    id: 2,
                    label: 'PA',
                    value: ''
                },

                {
                    id: 4,
                    label: 'SAT O2',
                    value: ''
                },
                {
                    id: 5,
                    label: 'FC',
                    value: ''
                },
                {
                    id: 6,
                    label: 'T(°C)',
                    value: ''
                }
            ],
            //paginado
            totalPagesToShow: 4,
            selectPage: 1,
            stepPagination: [],
        }
    },
    watch: {
        selectPage() {
            this.calculateData(10);
        },

    },
    created() {
        this.getFirstDayOfMonth();
    },
    computed: {
        //cpData() {}
        cpData() {
            return this.dataShow;
        },
        limitedStepPagination() {
            const totalPages = this.stepPagination.length;
            const currentPage = this.selectPage;

            let startPage = 1;
            let endPage = totalPages;
            if (totalPages > this.totalPagesToShow) {
                const halfPagesToShow = Math.floor(this.totalPagesToShow / 2);

                if (currentPage > halfPagesToShow) {
                    startPage = currentPage - halfPagesToShow;
                    endPage = currentPage + halfPagesToShow - 1;

                    if (endPage > totalPages) {
                        endPage = totalPages;
                        startPage = totalPages - this.totalPagesToShow + 1;
                    }
                } else {
                    endPage = this.totalPagesToShow;
                }
            }

            return Array.from({
                    length: endPage - startPage + 1,
                },
                (_, i) => startPage + i
            );
        },
    },
    methods: {
        ...mapActions(["get", "post"]),
        getFirstDayOfMonth: function () {
            const currentDate = new Date();
            const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
            const today = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
            this.filter.date_start = firstDayOfMonth.toISOString().split('T')[0];
            this.filter.date_end = today.toISOString().split('T')[0];
            this.mtdGetData();
        },
        mtdGetData: function () {
            this.post({
                    url: this.$store.getters.get__url +
                        "/control/consultingBook/filter",
                    token: this.$store.getters.get__token,
                    params: this.filter,
                })
                .then((response) => {
                    this.data = response.data;
                    this.calculateData(10);
                })
                .catch((errors) => {});
        },
        //paginado
        mtdNumber: function (index) {
            return (index + 1) + ((this.selectPage - 1) * 10);
        },
        calculateData(items) {
            if (!this.data || !this.data.length) {
                return (this.dataShow = []);
            }
            let filteredData = this.data;
            let totalPages = Math.ceil(filteredData.length / items);
            this.stepPagination = Array.from({
                    length: totalPages,
                },
                (_, index) => index + 1
            );
            let startIndex = (this.selectPage - 1) * items;
            let endIndex = startIndex + (items - 1);
            this.dataShow = filteredData.slice(startIndex, endIndex + 1);
        },

        backPage() {
            this.selectPage = this.selectPage > 1 ? this.selectPage - 1 : 1;
        },
        nextPage() {
            this.selectPage =
                this.selectPage < this.stepPagination.length ?
                this.selectPage + 1 :
                this.stepPagination.length;
        },
        selectedPage(page) {
            this.selectPage = page;
        },
        returnHome: function () {
            this.$emit("mtdShowBook", 'buttons');
        },
        mtdModalShow: function (item) {
            
            this.modal = {
                boo_modal: true,
                payload: item,
                title: 'CONSULTA - ' + item.patient
            }
        },
        mtdclosemodal: function (opt){
            this.modal = {
                boo_modal: false,
                payload: {},
                title: ''
            };
            if(opt == 0){
                this.mtdGetData();
            }
        }
    },
    filters: {
        formatDate(date) {
            return date != null ? moment(date).format("DD/MM/YYYY") : "-";
        },
    },

}
</script>
