<template>
    <CModal @mtdclosemodal="mtdclosemodal" :show="boo_modal" :close-on-backdrop="false" :centered="true" :title="title" :size="'lg'" :btn_cancel="true" :btn_ok="true" :booTitile="true">
        <div class="row mx-0  px-2">
            <div class=" col-xl-4 text-center  btn-home my-2" v-if="destination === '' || destination === 'ALTA'">
                <button @click="mtdstoredata(0,'ALTA')" type="button" class="btn w-100 fw-bold">
                     ALTA 
                </button>
            </div>
            <div class=" col-xl-4 text-center  btn-home my-2 " v-if="subsubcategorie_id !=2 && type_history != 1 && (destination === '' || destination === 'FUGA')">
                <button  @click="mtdstoredata(0,'FUGA')" type="button" class="btn w-100 fw-bold">
                     FUGA
                </button>
            </div>
            <div class=" col-xl-4 text-center  btn-home my-2 " v-if="subsubcategorie_id !=2 && type_history != 1 && (destination === '' || destination === 'FALLECIMIENTO')">
                <button   @click="mtdstoredata(0,'FALLECIMIENTO')" type="button" class="btn w-100 fw-bold">
                     FALLECIMIENTO
                </button>
            </div>
            <div v-if="type_history != 3 && type_history != 4 && type_history != 1 && type_history != 7 && subsubcategorie_id !=2 && rolname == 'DOCTOR(A)' " class=" col-xl-4 text-center  btn-home my-2 ">
                <button @click="mtdstoredata()" type="button" class="btn w-100 fw-bold">
                    CONSULTA
                </button>
            </div>
            <div v-if=" type_history == 1  && subsubcategorie_id !=2  && rolname == 'DOCTOR(A)'  " class=" col-xl-4 text-center  btn-home my-2 ">
                <button @click="mtdstoredata(4,'LECTURA DE RESULTADOS')" type="button" class="btn w-100 fw-bold">
                   LECTURA DE RESULTADOS 
                </button>
            </div>
            <div v-if="type_history != 7 && type_history != 3 && type_history != 4 && type_history != 1  && subsubcategorie_id !=2 && rolname == 'DOCTOR(A)' " class=" col-xl-4 text-center  btn-home my-2 ">
                <button @click="mtdstoredata(3,'EMERGENCIA')" type="button" class="btn w-100 fw-bold">
                    EMERGENCIA
                </button>
            </div>
            <div v-if="type_history != 3 && type_history != 1   && subsubcategorie_id !=2 && rolname == 'DOCTOR(A)' " class=" col-xl-4 text-center  btn-home my-2 ">
                <button @click="mtdstoredata(1,'HOSPITALIZACION')" type="button" class="btn w-100 fw-bold">
                     HOSPITALIZACION
                </button>
            </div>
            <div v-if="type_history != 4  && subsubcategorie_id !=2 && type_history != 1 && rolname == 'DOCTOR(A)' " class=" col-xl-4 text-center  btn-home my-2 ">
                <button @click="mtdstoredata(2,'UCI')" type="button" class="btn w-100 fw-bold">
                    UCI
                </button>
            </div>
            <div v-if="subsubcategorie_id !=2 && type_history != 1 &&  (destination === '' || destination === 'RETIRO VOLUNTARIO')" class=" col-xl-4 text-center  btn-home my-2 ">
                <button @click="mtdstoredata(0,'RETIRO VOLUNTARIO')" type="button" class="btn w-100 fw-bold">
                    RETIRO VOLUNTARIO
                </button>
            </div>
            <div class=" col-xl-4 text-center  btn-home my-2 " v-if=" subsubcategorie_id !=2 && type_history != 1 && rolname == 'DOCTOR(A)' ">
                <button  @click="mtdstoredata()" type="button" class="btn w-100 fw-bold">
                     SOP
                </button>
            </div>
        </div>
    
    </CModal>
    </template>
    
    <script>
    import CModal from "@/components/shared/modals/CModal.vue";
    
    import {
        mapActions
    } from "vuex";
    
    export default {
        name: "c-asistencial-hospitalizacion-modals-cModaldestino",
        components: {
            CModal,
        },
        data() {
            return {
                destination: "",
            };
        },
        props: {
            title: String,
            boo_modal: Boolean,
            type_history:{
                type:Number,
                default:0
            },
            subsubcategorie_id:{
                type:Number,
                default:0
            },
            rolname:String,
            medicalAct: Number,

        },
        watch: {
            boo_modal: {
                handler: function (newValue) {
                    if(newValue){

                        this.mtdGetDestination();
                    }
                },
                immediate: true,
            }
        },
        methods: {
            ...mapActions(["get", "post"]),
            mtdclosemodal() {
                this.$emit('mtdclosemodal')
            },
            mtdstoredata( payload,destination){
                    this.$emit('mtdstoredata', payload,destination); 
            },
            mtdGetDestination: function () {
                if(this.medicalAct){
                    //Cargar data de acuerdo a lo buscado
                    this.get({
                        url: this.$store.getters.get__url + "/admision/destinationActMedical/"+this.medicalAct,
                        token: this.$store.getters.get__token,
                    }).then((response) => {
                        if (response.statusCode === 200) {
                            this.destination=response.data ? response.data.destination : "";
                        }
                    });
                }
            },
        },
    };
    </script>
    