<template>
<div class="dropdown mb-2" :class="[dropdown && 'activeItem']">
    <div class="dropdown-header" @click="toggleDropdown()">
        {{ title }}
        <img src="@/assets/helpers/circle-chevron-down-solid.svg" alt="" class="dropdown-icon" :class="[dropdown && 'active']">
    </div>
    <transition @before-enter="beforeEnter" @enter="enter" @before-leave="beforeLeave" @leave="leave">
        <div class="dropdown-wrapper" v-if="dropdown">
            <div id="myTabContentHijo" v-if="component=='Hchospitalizacion'">
                <Hchospitalizacion :responsive="true" :metodoadd="metodoadd" @mtdaddexit="mtdaddexit"  :viewMedico="viewMedico" :Clinicahistory="Clinicahistory" :datapatients="datapatients" :showHistory="showHistory"></Hchospitalizacion>
            </div>
        </div>
    </transition>

</div>
</template>

<script>
import Hchospitalizacion from '@/components/Asistencial/Consulta/Historia/HcconsultaDesktop.vue';
export default {
    name: 'dropdown-component-consulta',
    props: {
        title: String,
        component: String,
        datapatients: {
            type: Object,
            default: () => ({})
        },
        Clinicahistory: {
            type: Object,
            default: () => ({})
        },
        viewMedico: Number,
        metodoadd: {
            type: Number,
            default: 0
        },
        showHistory: {
            type: Boolean,
            default: false
        }
    },
    components: {
        Hchospitalizacion,
    },
    data: () => ({
        dropdown: false
    }),
    methods: {
        toggleDropdown() {
            this.dropdown = !this.dropdown;
        },
        beforeEnter(el) {
            el.style.height = 0
        },
        enter(el) {
            el.style.height = el.scrollHeight + 'px'
        },
        beforeLeave(el) {
            el.style.height = el.scrollHeight + 'px'
        },
        leave(el) {
            el.style.height = 0
        },

        mtdaddexit:function(ind,Historia){
            this.$emit("mtdaddexit", ind, Historia);
        }
    }
}
</script>
