import { render, staticRenderFns } from "./HcconsultaDesktop.vue?vue&type=template&id=2a57b52d&scoped=true"
import script from "./HcconsultaDesktop.vue?vue&type=script&lang=js"
export * from "./HcconsultaDesktop.vue?vue&type=script&lang=js"
import style0 from "./HcconsultaDesktop.vue?vue&type=style&index=0&id=2a57b52d&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a57b52d",
  null
  
)

export default component.exports