<template>
<div>
    <div class="px-4">
        <div class="row">
            <div class=" btn-home  col-md-12 mt-3 mb-4 d-flex justify-content-end">
                <button type="button" class="btn btn-outline-main" @click="changePage('home')">
                    <i class="fas fa-recycle"></i> Atras
                </button>
            </div>
        </div>
        <div id="div-modal-cxc" class="d-flex justify-content-center">
            <div id="div-cxc" class=" card  w-100">
                <div class="text-center card-header text-white h4 bg-main">
                    <strong>SOLUCIONES</strong>
                </div>
                <div class="card-body w-100">
                    <div class="row">
                        <div class="col-md-12 py-2">
                            <div class="row d-flex mx-0">
                                <div class="col-xl-10 col-lg-10 col-md-9 col-sm-12 col-xs-12 px-1 mb-2">
                                    <div class="input-group input-group">
                                        <span class="input-group-text text-white bg-main"><i class="fas fa-search"></i></span>
                                        <input @keyup.enter="mtdSearchData" @input="mtdSearchData" v-model="filter.name" type="text" class="form-control form-control" placeholder="Ingrese un nombre para mostrar soluciones" />
                                    </div>
                                </div>
                                <div class="col-xl-2 col-lg-2 col-md-3 col-sm-12 col-xs-12 btn-home px-1 py-xl-0 mb-2">
                                    <button type="button" class="btn btn-bm-noradius w-100" @click="mtdModalShow('store', 0)">
                                        <i class="fas fa-plus"></i> Nueva
                                    </button>
                                </div>
                                <!-- <div class="col-xl-2 col-lg-2 col-md-3 col-sm-6 col-xs-12 btn-home px-1 py-xl-0">
                                        <button
                                            type="button"
                                            class="btn btn-bm-noradius w-100"
                                            @click="mtdGetData()"
                                        >
                                            <i class="fa fa-prescription-bottle"></i> Ver Todo
                                        </button>
                                    </div> -->
                            </div>
                        </div>
                        <div v-if="dataSubsubcategory.length > 0 && cpData.length > 0" class="d-flex justify-content-end  mb-3 ml-5">
                            <nav aria-label="Page navigation example ">
                                <ul class="pagination mb-0 ">
                                    <li class="page-item">
                                        <a class="page-link page" href="javascript:void(0)" @click="backPage">Anterior</a>
                                    </li>
                                    <li v-if="limitedStepPagination[0] !== 1" class="page-item">
                                        <a :class="selectPage === 1 ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(1)">1</a>
                                    </li>
                                    <li v-if="limitedStepPagination[0] > 2" class="page-item disabled">
                                        <span class="page-link">...</span>
                                    </li>

                                    <li v-for="(item, index) in limitedStepPagination" :key="index" class="page-item">
                                        <a :class="selectPage === item ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(item)">{{ item }}
                                        </a>
                                    </li>
                                    <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] <stepPagination.length - 1" class="page-item disabled">
                                        <span class="page-link">...</span>
                                    </li>
                                    <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] !== stepPagination.length" class="page-item">
                                        <a :class="selectPage === limitedStepPagination.length + 2 ? 'page-link active':'page-link'" href="javascript:void(0)" @click="selectedPage(stepPagination.length)">{{ stepPagination.length }}
                                        </a>
                                    </li>
                                    <li class="page-item">
                                        <a class="page-link page" href="javascript:void(0)" @click="nextPage">Siguiente</a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                        <div class="col-md-12 mt-2" id="content-timelinetable">
                            <table id="tb-cxc" class="table table-bordered border-main">
                                <caption class="py-0 my-0"></caption>
                                <thead class="bg-main">
                                    <tr class=" table-th text-white text-center text-xxs">
                                        <th>N°</th>
                                        <th>REGISTRO SANITARIO</th>
                                        <th>CODIGO UNICO</th>
                                        <th class="table-th-name">NOMBRE</th>
                                        <th class="table-th-cxc-report-2">NOMBRE COMERCIAL</th>
                                        <th>PRESENTACION</th>
                                        <th>MARCA</th>
                                        <th>CONCENTRACION</th>
                                        <th class="table-th-price">P. KAIROS C/IGV</th>
                                        <th class="table-th-price">P. PART. KAIROS C/IGV</th>
                                        <th>SECTOR</th>
                                        <th>SUB SECTOR</th>
                                        <th>UBICACION</th>
                                        <th>ESTADO</th>
                                        <th class="table-th-number">ACCIONES</th>
                                    </tr>
                                </thead>
                                <tbody class="border-main">
                                    <tr class="table-body text-center" v-if="cpData.length === 0">
                                        <td colspan="20" class="text-center">No hay registros</td>
                                    </tr>
                                    <tr class="table-body text-center text-xxs" v-for="(item, index) in cpData" :key="index">
                                        <td>
                                            {{mtdNumber(index) }}
                                        </td>
                                        <td class="">
                                            {{ item.reg_sanitario }}
                                        </td>
                                        <td class="">
                                            {{ item.cod_unico }}
                                        </td>
                                        <td class="table-th-name">
                                            {{ item.name }}
                                        </td>
                                        <td class="">
                                            {{ item.commercial }}
                                        </td>
                                        <td class="">
                                            {{ item.presentation }}
                                        </td>
                                        <td class="">
                                            {{ item.brand ? item.brand: ' - '  }}
                                        </td>
                                        <td class="">
                                            {{ item.concentration ? item.concentration : '-' }}
                                        </td>
                                        <td class="">
                                            {{item.kairos}}
                                        </td>
                                        <td class="">
                                            {{item.particular}}
                                        </td>
                                        <td class="">
                                            {{item.sector_name ? item.sector_name : '-'}}
                                        </td>
                                        <td class="">
                                                {{item.sub_sectors_name ? item.sub_sectors_name : '-'}}
                                        </td>
                                        <td class="">
                                            {{item.location ? item.location : '-'}}
                                        </td>
                                        <td>
                                            <div id="state_speciality" v-if="item.state == 1">
                                                <div class="bg-success text-white">Activo</div>
                                            </div>
                                            <div id="state_speciality" v-else>
                                                <div class="bg-secondary text-white">Inactivo</div>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="text-center d-flex justify-content-center">
                                                
                                                <button class="btn btn-sm a-success text-white" @click="mtdGetDataID(item.id, index)">
                                                    <i class="fa fa-edit"></i>
                                                </button>
                                                <button class="btn btn-sm a-danger text-white" @click="mtdBeforeDelete(item.id)">
                                                    <i class="fas fa-trash-alt"></i>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <cModalAddSoluciones @closeModal="mtdHideModal" :contracts="contracts" :subsubcategoryObject="Subsubcategory" :title="modal.title" :boo_modal="modal.modal_form" :action="modal.action" />
    <SweetAlert :swal="swal" ref="SweetAlert" />
</div>
</template>

<script>
import {
    mapActions
} from "vuex";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import cModalAddSoluciones from "@/components/Maintenance/Modals/cModalAddSoluciones.vue";
export default {
    name: "c-mantenedores-pages-medicine",
    components: {
        SweetAlert,
        cModalAddSoluciones,
    },
    data() {
        return {
            swal: null,
            all: [],
            option: "",
            pageExcel: 1,
            categorie_control: "",
            subcategorie_control: "",
            especialidadesModalVisible: false,
            category: {
                id: "",
            },
            Subcategorie: {
                name: "",
                categorie_id: "",
            },
            totalPagesToShow: 5,
            search: "",
            dataShow: [],
            page: 0,
            selectPage: 1,
            stepPagination: [],
            estado: "activo",
            categories: [],
            specialtys: [],
            filter: {
                name: null,
                type : 3
            },
            subcategories: [],
            contracts: [],
            dataSubsubcategory: [],
            dataSubcategory: [],
            estado: "activo",
            modal: {
                title: "",
                action: "",
                modal_form: false,
            },
            Subsubcategory: {
                name: "",
                Id: "",
                state: "",
                categorie_id: "",
                subcategorie_id: "",
                specialty_id: "",
                insurance_contrat_id: "",
                kairos: "",
                unidad: 0,
                particular: 0,
                eps: false,
                parti: false,
                molecule: "",
                commercial: "",
                brand: "",
                brand_id: "",
                presentation: "",
                concentration: "",
                format: "",
                stock: 0,
                minimum_stock: 0,
                state: "",
                sub_sub_category_id: null,
                provider_id: null,
                provider_id_2: null,
                sector_id: "",
                sector_name: "",
                location: "",
            },
            posEdit: null,
            message: false,
            noRecordsMessage: "",
            file: "",
            fileLoaded: false,
            dataProvider: [],
            modalunit: {
                title: "",
                modal_form: false,
                productId: "",
                unitId: [],
            },

            dataunits: [],
        };
    },
    computed: {
        classTh() {
            return 'align-middle border border-5 bg-main border-white';
        },
        cpData() {
            return this.dataShow;
        },
        limitedStepPagination() {
            const totalPages = this.stepPagination.length;
            const currentPage = this.selectPage;

            let startPage = 1;
            let endPage = totalPages;
            if (totalPages > this.totalPagesToShow) {
                const halfPagesToShow = Math.floor(this.totalPagesToShow / 2);

                if (currentPage > halfPagesToShow) {
                startPage = currentPage - halfPagesToShow;
                endPage = currentPage + halfPagesToShow - 1;

                if (endPage > totalPages) {
                    endPage = totalPages;
                    startPage = totalPages - this.totalPagesToShow + 1;
                }
                } else {
                endPage = this.totalPagesToShow;
                }
            }

            return Array.from(
                {
                length: endPage - startPage + 1,
                },
                (_, i) => startPage + i
            );
        },

    },
    watch: {
        selectPage() {
            this.calculateData(10);
        },
    },
    created() {
    },
    methods: {
        ...mapActions(["get", "post"]),

        mtdSearchFilter() {
            this.calculateData(10);
        },

        openModal: function (id) {
            this.get({
                url: this.$store.getters.get__url + "/Medicament/unitmedicine/" + id,
                token: this.$store.getters.get__token,
            })
            .then((response) => {
                if (response.data == 1) {
                    this.$refs.SweetAlert.showWarning("Agregue Detalle");
                } else {
                    this.dataunits = response.data.data;

                    this.modalunit = {
                        title: "Agregar Unidades ",
                        modal_form: true,
                        productId: response.data.product.id,
                        unitId: [],
                    };
                    response.data.product.productunits.forEach((element) => {
                        this.modalunit.unitId.push(element.unit_id);
                    });
                }
            })
            .catch((errors) => {
                this.$refs.SweetAlert.showError(errors);
            });
        },

        closeModal: function () {
            this.modalunit = {
                title: "",
                modal_form: false,
            };
        },

        mtdChangeState: function () {
            this.Subsubcategory.state = this.Subsubcategory.state == 1 ? 0 : 1;
        },

        mtdChangeEps: function () {
            this.Subsubcategory.eps = this.Subsubcategory.eps == 1 ? 0 : 1;
        },

        mtdGetData: function () {
            this.get({
                url: this.$store.getters.get__url + "/Medicament/allData",
                token: this.$store.getters.get__token,
            })
            .then((response) => {
                this.dataSubsubcategory = response.data;
                this.option = "all";
                this.calculateData(10);
            })
            .catch((errors) => {
                this.$refs.SweetAlert.showError(errors);
            });
        },

        mtdNumber: function (index) {
            return index + 1 + (this.selectPage - 1) * 10;
        },

        mtdSearchData: function () {
            if (this.filter.name != null) {
                if (this.filter.name.length > 4) {
                    this.post({
                        url: this.$store.getters.get__url + "/Medicament/filter",
                        token: this.$store.getters.get__token,
                        params: this.filter,
                    })
                    .then((response) => {
                        this.dataSubsubcategory = response.data;
                        this.option = "";
                        this.selectPage = 1;
                        this.calculateData(10);
                    })
                    .catch((errors) => {
                        this.$refs.SweetAlert.showError(errors);
                    });
                }
            } else {
                this.$refs.SweetAlert.showWarning("Debe digitar  un nombre de soluciones");
            }
        },

        mtdModalShow: function (action, pos) {
            this.modal.title = action == "store" ? "Nuevo Solución" : "Editar Solución";
            this.modal.action = action;
            this.modal.modal_form = true;
            this.posEdit = pos;
            console.log(this.modal);
        },

        mtdHideModal: function (name) {
            this.modal = {
                title: "",
                action: "",
                modal_form: false,
            };
            this.Subsubcategory = {
                name: "",
                Id: "",
                state: "",
                subcategorie_id: "",
                categorie_id: "",
                cod_ipress: "",
                cod_cpt: "",
                cod_nomenclador: "",
                cod_segus: "",
                particular: 0,
                price_unit: 0,
                ayudantiva: 0,
                instrumentista: 0,
                anatomia_patologica: "",
                laparoscopia: "",
                laser: "",
                luxo_fracturas: "",
                injerto_oseo: "",
                honorarios_cirujano_principal: 0,
                honorarios_primer_ayudante: 0,
                honorarios_segundo_ayudante: 0,
                honorarios_tercer_ayudante: 0,
                specialty_id: "",
                anestesiologo: 0,
                obstetra: 0,
                eps: "",
                unidad: 0,
                molecule: "",
                commercial: "",
                brand: "",
                brand_id: "",
                presentation: "",
                concentration: "",
                format: "",
                stock: 0,
                minimum_stock: 0,
                state: "",
                sub_sub_category_id: null,
                provider_id: null,
                provider_id_2: null,
                sector_id: "",
                sector_name: "",
                location: "",
            };
            this.posEdit = null;
            /*
            const fileInput = this.$refs.fileInput;
            if (fileInput.files.length > 0) {
                fileInput.value = null;
            }
            */
            this.fileLoaded = false;
            this.categorie_control = "";
            this.subcategorie_control = "";
            this.filter.name = name;
            this.mtdSearchData();
        },

        mtdGetDataID: function (id, pos) {
            this.get({
                url: this.$store.getters.get__url + "/Medicament/" + id + "/show",
                token: this.$store.getters.get__token,
            })
            .then((response) => {
                this.Subsubcategory = response.data;
                this.subcategorie_control = response.data.subcategorie_name;
                this.mtdModalShow("edit", pos);
            })
            .catch((errors) => {
                this.$refs.SweetAlert.showError(errors);
            });
        },

        mtdBeforeDelete: function (id) {
            this.Subsubcategory.Id = id;
            this.$refs.SweetAlert.showConfirmSimple2("¿Estás seguro?","No podrás revertir esto","warning","Eliminar") 
            .then((result) => {
                if (result.value) {
                    this.mtdDeleteData();
                }
            });
        },

        mtdDeleteData: function () {
            this.post({
                url: this.$store.getters.get__url + "/Medicament/destroy",
                token: this.$store.getters.get__token,
                params: this.Subsubcategory,
            })
            .then((response) => {
                this.$refs.SweetAlert.showSuccess(response.message);
                this.mtdSearchData();
            })
            .catch((errors) => {
                this.$refs.SweetAlert.showError(errors);
            });
        },

        /** pagination */
        calculateData(items) {
            if (!this.dataSubsubcategory) {
                this.dataShow = [];
            }
            let filteredData = this.dataSubsubcategory;
            let selectPage = this.selectPage;
            let totalPages = Math.ceil(filteredData.length / items);
            this.stepPagination = Array.from(
                {
                length: totalPages,
                },
                (_, index) => index + 1
            );
            let startIndex = (selectPage - 1) * items;
            let endIndex = startIndex + (items - 1);
            this.dataShow = filteredData.slice(startIndex, endIndex + 1);
        },

        backPage() {
            this.selectPage = this.selectPage > 1 ? this.selectPage - 1 : 1;
        },

        nextPage() {
            this.selectPage =
            this.selectPage < this.stepPagination.length
            ? this.selectPage + 1
            : this.stepPagination.length;
        },

        selectedPage(page) {
            this.selectPage = page;
        },

        mtdSearch: function () {
            if (this.search.length >= 4 && this.search != "") {
                let q = this.search.toString().toUpperCase();
                this.dataShow = [];
                let i = 0;
                this.dataSubsubcategory.forEach((element) => {
                let name = element.name.toString().toUpperCase();
                if (name.includes(q)) {
                    this.dataShow.push(element);
                }
                });
                this.selectPage = 1;
            }
        },

        returnHome: function () {
            this.$emit("returnHome");
        },
        changePage(page) {
            this.$emit('changePage', page)
        },
    }
}
</script>
