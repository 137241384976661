<template>
    <CModal @mtdclosemodal="mtdClose" :show="boo_modal" :close-on-backdrop="false" :centered="false" :title="title" :size="'lg'" :btn_cancel="true" :btn_ok="true" :booTitile="true">
        <div class="px-3">
            <div class="row mx-0 px-0 d-flex">
                <div class="mb-2 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 px-1">
                    <label for="name" class="form-label">Peso</label>
                    <input type="number" class="form-control form-control-sm" v-model="data_detail.promPeso" placeholder="Peso" step="0.1" @input="validateInput('promPeso')">
                </div>
                <div class="mb-2 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 px-1">
                    <label for="name" class="form-label">Balance Hidrico</label>
                    <input type="number" class="form-control form-control-sm" v-model="data_detail.totalBalance" placeholder="Balance Hidrico" step="0.1"  
                    >
                </div>
                <div class="mb-2 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 px-1">
                    <label for="name" class="form-label">Deposición</label>
                    <input type="text" class="form-control form-control-sm" v-model="data_detail.deposition" placeholder="Deposición">
                </div>
                <div class="mb-2 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 px-1">
                    <label for="name" class="form-label">Orina</label>
                    <input type="text" class="form-control form-control-sm" v-model="data_detail.urine" placeholder="Orina">
                </div>
            </div>
        </div>
        <template #footer>
            <button class="btn btn-success btn-sm" :disabled="cpButton" @click="DataDetail">
                Guardar
            </button>
            &nbsp;
        </template>
        <SweetAlert :swal="swal" ref="SweetAlert" />
    </CModal>
</template>

<script>
import CModal from "@/components/shared/modals/CModal.vue";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import {
    mapActions
} from "vuex";

export default {
    name: "c-maintenance-modal-documento",
    components: {
        CModal,
        SweetAlert,
    },
    data() {
        return {
            swal: null,
            data_detail: {
                totalBalance: '',
                promPeso: '',
                deposition: '',
                urine: '',
                state: ''
            },
        };
    },
    watch: {
        payload: {
            handler(newVal) {
                if (newVal) {
                    this.data_detail = {
                        ...newVal
                    };
                }
                
            },
            immediate: true,
        },
    },
    props: {
        title: String,
        boo_modal: Boolean,
        payload: {
            type: Object,
            default: null
        },
        dataId: {
            type: Array,
            default: null
        },
    },
    computed: {
        cpButton() {
            return !(this.data_detail.totalBalance !== '' && this.data_detail.promPeso !== '');
        }
    },
    methods: {
        ...mapActions(["get", "post"]),

        validateInputt(field) {
            this.data_detail[field] = this.data_detail[field].replace(/[^0-9]/g, '');
        },

        validateInput(field) {
            if (this.data_detail[field] < 0) {
                this.data_detail[field] = 0;
            }
        },

        mtdClose() {
            this.data_detail = {
                totalBalance: '',
                promPeso: '',
                deposition: '',
                urine: '',
                state: ''
            },
            this.$emit("closeModal");
        },

        DataDetail() {
            this.$refs.SweetAlert.showConfirmSimple2("","¿Estás seguro de guardar estos registros?","warning","Confirmar") 
                .then((result) => {
                    if (result.value) {
                        this.$emit('Datadetail', this.data_detail, this.dataId)
                        this.mtdClose();
                    }
                });
        },
    },
    

};
</script>