<template>
  <CModal @mtdclosemodal="mtdclosemodal" :show="boo_modal" :close-on-backdrop="false" :centered="true" :title="title"
    :size="'xl'" :btn_cancel="true" :btn_ok="true" :booTitile="true">
    <div class="row mx-0 px-2">
      <b>{{ cuerpo[tab - 1].title }}</b>
    </div>
    <div class="row mx-0 px-2 text-center">
      <template v-if="cuerpo[tab - 1].value === 1 || cuerpo[tab - 1].value === 2">
        <table class="table table-bordered">
          <tbody>
            <tr v-for="index in Math.ceil(cuerpo[tab - 1].text.length / 2)" :key="index">
              <td v-html="formatText(cuerpo[tab - 1].text[(index - 1) * 2])"></td>
              <td v-if="(index - 1) * 2 + 1 < cuerpo[tab - 1].text.length" v-html="formatText(cuerpo[tab - 1].text[(index - 1) * 2 + 1])"></td>
            </tr>
          </tbody>
        </table>
      </template>
      <template v-else>
        <ul>
          <li class="list-group" v-for="(item, index) in cuerpo[tab - 1].text" :key="index" v-html="formatText(item)"></li>
        </ul>
      </template>
    </div>
    <template #footer>
      <button @click="mtdclosemodal" class="btn btn-danger btn-sm px-4">
        <i class="fas fa-times"></i>
        &nbsp;Cerrar
      </button>
      &nbsp;
      <button @click="mtdInitVoice" class="btn btn-info btn-sm px-4">
        <i class="fas fa-headset"></i>
        &nbsp;Iniciar
      </button>
    </template>
  </CModal>
</template>

<script>
import CModal from "@/components/shared/modals/CModal.vue";
import { mapActions } from "vuex";

export default {
  name: "c-asistencial-hospi-modals-cModalVoice",
  components: {
    CModal,
  },
  data() {
    return {
      cuerpo: [
        {
          value: 1,
          title: "SE ENCUENTRA EN LA PRIMERA HOJA, PARA PODER USAR EL DICTADO CORRECTAMENTE DEBE HACERLO POR PALABRAS CLAVE",
          text: [
            //sección 1 de la hoja 1
            'PARA COLOCAR UN DATO EN GRUPO SANGUINEO DECIR "GRUPO"',
            'PARA COLOCAR UN DATO EN FACTOR SANGUÍNEO DECIR "FACTOR"',
            //sección 2 de la hoja 1
            'PARA COLOCAR UN DATO EN DM DECIR "DIABETES"',
            'PARA COLOCAR UN DATO EN AR DECIR "ARTRITIS"',
            'PARA COLOCAR UN DATO EN HTA DECIR "HTA"',
            'PARA COLOCAR UN DATO EN OTRO DECIR "OTRO"',
            'PARA COLOCAR UN DATO EN ALERGÍAS DECIR "ALERGÍAS"',
            'PARA COLOCAR UN DATO EN CIRUGÍAS DECIR "CIRUGÍAS"',
            'PARA COLOCAR UN DATO EN MEDICAMENTOS FRECUENTES DECIR "MEDICAMENTOS"',
            'PARA COLOCAR UN DATO EN TIEMPO DE ENFERMEDAD(T.E) DECIR "ENFERMEDAD"',
            'PARA COLOCAR UN DATO EN FORMA DE INICIO(F.I) DECIR "FORMA"',
            'PARA COLOCAR UN DATO EN CURSO DECIR "CURSO"',
            //sección 3 de la hoja 1
            'PARA COLOCAR UN DATO EN FRECUENCIA CARDÍACA DECIR "FRECUENCIA"',
            'PARA COLOCAR UN DATO EN TEMPERATURA(T(°C)) DECIR "TEMPERATURA"',
            'PARA COLOCAR UN DATO EN FRECUENCIA DECIR "RESPIRATORIA"',
            'PARA COLOCAR UN DATO EN SATURACIÓN(SATO2) DECIR "SATURACIÓN"',
            'PARA COLOCAR UN DATO EN HEMOGLUCOTEST DECIR "EMO"',
            'PARA COLOCAR UN DATO EN FIO 2 DECIR "FIO"',
            'PARA COLOCAR UN DATO EN PRESIÓN SISTÓLICA(PAS) DECIR "SISTÓLICA"',
            'PARA COLOCAR UN DATO EN PRESIÓN DISTÓLICA(PAD) DECIR "DIASTÓLICA"',
            //Sección 4 de la hoja 1
            'PARA COLOCAR UN DATO EN APETITO DECIR "APETITO"',
            'PARA COLOCAR UN DATO EN SED DECIR "AGUA"',
            'PARA COLOCAR UN DATO EN ORINA DECIR "ORINA"',
            'PARA COLOCAR UN DATO EN ORINA HECES DECIR "HECES"',
            'PARA COLOCAR UN DATO EN SUEÑO DECIR "SUEÑO"',
          ],
        },
        {
          value: 2,
          title: "SE ENCUENTRA EN LA SEGUNDA HOJA, PARA PODER USAR EL DICTADO CORRECTAMENTE DEBE HACERLO POR PALABRAS CLAVE",
          text: [
            'PARA COLOCAR UN DATO EN RELATO DECIR "RELATO"',
            'PARA COLOCAR UN DATO EN OTROS EXÁMENES DECIR "EXÁMENES"',
            //PARTE 2
            'PARA COLOCAR UN DATO EN ESTADO GENERAL DECIR "ESTADO"',
            'PARA COLOCAR UN DATO EN SCN DECIR "SNC"',
            'PARA COLOCAR UN DATO EN RESPIRATORIO DECIR "RESPIRATORIO"',
            'PARA COLOCAR UN DATO EN TORÁX CV HEMO DECIR "TÓRAX"',
            'PARA COLOCAR UN DATO EN ABDOMINAL DECIR "ABDOMINAL"',
            'PARA COLOCAR UN DATO EN RENAL DECIR "RENAL"',
            'PARA COLOCAR UN DATO EN OTROS DECIR "OTROS"',
          ],
        },
        {
          value: 3,
          title: "SE ENCUENTRA EN LA TERCERA HOJA, PARA PODER USAR EL DICTADO CORRECTAMENTE DEBE HACERLO POR PALABRAS CLAVE",
          text: [
            'PARA COLOCAR UN DATO EN INDICACIONES Y/O RECOMENDACIONES DECIR "INDICACIONES"',
            'PARA COLOCAR UN DATO EN DIETA DECIR "DIETA"',
          ],
        },
      ],
    };
  },
  props: {
    title: String,
    boo_modal: Boolean,
    tab: Number,
  },
  methods: {
    ...mapActions(["get", "post"]),
    mtdclosemodal() {
      this.$emit("mtdclosemodalVoice");
    },
    mtdInitVoice(payload) {
      this.$emit("mtdInitVoice", payload);
    },
    formatText(text) {
      return text.replace(/(".*?")/g, '<strong>$1</strong>');
    },
  },
};
</script>
