<template>
<div>
    <div class="container">
        <div id="btn-open-caja" class="d-flex justify-content-end pt-2 mt-2">
            <div class="btn-group btn-home" role="group">
                <button type="button" class="btn btn-outline-main bg-main text-white" @click="mtdBackCampus">
                    <i class="fas fa-recycle"></i> Cambiar de Sede
                </button>
            </div>
        </div>
        <div class="row row-home" id="div_conte_2">
            <div id="div_conte-home" class="col-md-10 mx-auto">
                <div class="bg-secondary text-white bg-opacity-10">
                    <div class="text-center py-4 px-5" id="scroll-div-home" style="height: 215px;">
                        <div class=" row row-home">
                            <button class="div-campus col m-2 p-2 text-white" @click="changePage('oc')">
                                ORDEN DE COMPRA
                            </button>
                            <button class="div-campus col m-2 p-2 text-white" @click="changePage('servicios')">
                                ORDEN DE SERVICIOS
                            </button>
                            <button class="div-campus col m-2 p-2 text-white" @click="changePage('factura')">
                                REPORTE DE FACTURA
                            </button>
                            <button class="div-campus col m-2 p-2 text-white" @click="changePage('compra')">
                                COMPRAS
                            </button>
                           <!-- <button class="div-campus col m-2 p-2 text-white" @click="changePage('notaIngreso')">
                                NOTA DE INGRESO
                            </button>  -->
                        </div>
                        <div class=" row row-home">
                            <button class="div-campus col m-2 p-2 text-white" @click="changePage('inventario')">
                                INVENTARIO
                            </button>
                            <button class="div-campus col m-2 p-2 text-white" @click="changePage('mantenimiento')">
                                MANTENIMIENTO
                            </button>
                            <button class="div-campus col m-2 p-2 text-white" @click="changePage('cxp')">
                                CXP
                            </button>
                            <button class="div-campus col m-2 p-2 text-white" @click="changePage('sire')">
                                SIRE
                            </button>
                            <!-- <button class="div-campus col m-2 p-2 text-white" @click="changePage('verificacion')">
                               VERIFICACION STOCK
                            </button> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

    
<script>
export default {
    name: 'homeLogistics',
    data() {
        return {
            page: null
        }
    },
    methods: {
        changePage(page) {
            this.$emit('mtdChangePage', page)
        },
        mtdBackCampus: function () {
            this.$emit('mtdBackCampus')
        },
    }
}
</script>
