<template>
<div>
    <div class="container pb-5">
        <div class="d-flex title-option">
            <div class="btn-home mt-2 ms-auto" v-if="subpage == 'buttons'">
                <button type="button" class="btn btn-outline-main" @click="returnHome()">
                    <i class="fas fa-home-lg-alt"></i> Inicio
                </button>
            </div>
            
            <!-- <div class="mt-3" v-if="subpage !== 'buttons'">
                <span class="btn border border-main text-main" @click="mtdShowBook('buttons')" style="align-self: flex-start;">
                    <i class="fas fa-arrow-left"></i> Volver
              </span>
            </div> -->

        </div>
        <div class="mt-4" v-if="subpage == 'buttons'">
            <div class="row d-flex justify-content-center mt-2">
                <div class="col-md-6 col-lg-4 col-sm-6 col-xs-12 mb-4">
                    <div class="card card-book shadow" @click="mtdShowBook('consulta')">
                        <h4>CONSULTAS</h4>
                        <div class="icon-book">
                            <img class="img-fluid-bed-sop rounded-start" src="../../assets/consulta.png" alt=""/>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-4 col-sm-6 col-xs-12 mb-4">
                    <div class="card card-book shadow" @click="mtdShowBook('emergencia')">
                        <h4>EMERGENCIA</h4>
                        <div class="icon-book">
                            <img class="img-fluid-bed rounded-start" src="../../assets/ambulancia.png" alt=""/>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row d-flex justify-content-center mt-2">
                <div class="col-md-6 col-lg-4 col-sm-6 col-xs-12 mb-4">
                    <div class="card card-book shadow" @click="mtdShowBook('uci')">
                        <h4>UCI</h4>
                        <div class="icon-book">
                            <img class="img-fluid-bed-sop rounded-start" src="../../assets/uci.png" alt=""/>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-4 col-sm-6 col-xs-12">
                    <div class="card card-book shadow" @click="mtdShowBook('hosp')">
                        <h4>HOSPITALIZACION</h4>
                        <div class="icon-book">
                            <img class="img-fluid-bed rounded-start" src="../../assets/cama_sin_fondo.png" alt=""/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- subpoginas -->
         <ConsultingBook v-if="subpage == 'consulta'" @mtdShowBook="mtdShowBook"></ConsultingBook>
         <EmergencyBook v-if="subpage == 'emergencia'" @mtdShowBook="mtdShowBook"></EmergencyBook>
         <UciBook v-if="subpage == 'uci'" @mtdShowBook="mtdShowBook"></UciBook>
         <HospitalizationBook v-if="subpage == 'hosp'" @mtdShowBook="mtdShowBook"></HospitalizationBook>

    </div>
</div>
</template>

<script>
import {
    mapActions
} from 'vuex';
import ConsultingBook from './Book/ConsultingBook.vue';
import EmergencyBook from './Book/EmergencyBook.vue';
import UciBook from './Book/UciBook.vue';
import HospitalizationBook from './Book/HospBook.vue';

export default {
    name: 'c-asistencial-controlDiario',
    components:{
        ConsultingBook,
        EmergencyBook,
        UciBook,
        HospitalizationBook
    },
    data() {
        return {
            subpage: "buttons",

        }
    },
    methods: {
        ...mapActions(['get', 'post']),
        mtdShowBook(subpage){
            this.subpage = subpage;
        },
        returnHome() {
            //window.location.reload();
            this.$emit('mtdChangePage', 'home');
        },

    }

}
</script>
