<template>
    <div>
      <div class="px-5 py-3">
        <div class=" btn-home  col-md-12 mt-3 mb-4 d-flex justify-content-end">
            <button type="button" class="btn btn-outline-main" @click="returnHome">
                <i class="fas fa-home-lg-alt"></i> Inicio
            </button>
        </div>
        <div id="div-modal-cxc" class="d-flex justify-content-center">
            <div id="div-cxc" class="card w-100">
                <div class="text-center card-header text-white h4 bg-main">
                    <strong>FACTURACIÓN</strong>
                </div>
                <div class="card-body w-100">
                    <div class="row d-flex px-2">
                        <!--Busqueda por Paciente-->
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-sm-12 mt-1 mb-3">
                            <div class="input-group input-group">
                                <span class="input-group-text text-white bg-main"><i class="fas fa-search"></i></span>
                                <v-select
                                    class="text-dark form-control"
                                    label="name"
                                    :options="servicesPa"
                                    placeholder="-- Buscar Paciente --"
                                    @search="searchPa"
                                    v-model="selectedPa"
                                    :reduce="(name) => name.id"
                                    required
                                    :clearable="false"
                                    @input="mtdGetData"
                                >
                                    <template slot="no-options">
                                    No hay Pacientes para la busqueda.
                                    </template>
                                </v-select>
                            </div>
                        </div>
                        <!--Medicamentos e Insumos-->
                        <div class="dropdown mt-1" :class="[dropdown && 'activeItem']" v-if="showTable && cpData.length !== 0">
                            <div class="dropdown-headerFac" @click="toggleDropdown()">
                                MEDICAMENTOS E INSUMOS
                                <img src="@/assets/helpers/circle-chevron-down-solid.svg" alt="" class="dropdown-icon" :class="[dropdown && 'active']">
                            </div>

                            <transition @before-enter="beforeEnter" @enter="enter" @before-leave="beforeLeave" @leave="leave">
                                <div class="dropdown-wrapper bordered" v-if="dropdown">
                                    <div class="col-md-12 mt-3" id="content-timelinetable">
                                        <div class="d-flex justify-content-start mb-2">
                                            <button class="btn text-bg-custom btn-sm" @click="registerSale" v-if="!allItemsPaid">
                                                <i class="fas fa-save"/>&nbsp; <strong>Guardar</strong>
                                            </button>
                                        </div>
                                        
                                    </div>
                                    <div class="col-md-12" id="content-timelinetable">
                                        <table id="tb-cxc" class="table table-bordered border-main" style="font-size: 11.5px">
                                            <caption class="py-0 my-0"></caption>
                                            <thead>
                                                <tr class="text-center text-white border-white bg-main align-middle">
                                                    <th scope="col">FECHA</th>
                                                    <th scope="col">TIPO DE PACIENTE</th>
                                                    <th scope="col">% PAGA PACIENTE</th>
                                                    <th scope="col">SERVICIO</th> 
                                                    <th scope="col">CANTIDAD</th> 
                                                    <th scope="col">PRECIO</th>
                                                    <th scope="col">TOTAL</th> 
                                                    <th scope="col">ULT. EDICIÓN</th> 
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <template>
                                                    <tr  v-for="(item, index) in cpData" :key="index" class="text-center align-middle">
                                                        <td><strong>{{item.created_at | formatDateTime}}</strong></td>
                                                        <td>{{ item.sale.insurance.name}}</td>
                                                        <td style="white-space: nowrap;">% {{ item.sale.payment_patient}}</td>
                                                        <td>{{ item.subsub_categorie.name}}</td>
                                                        <td>{{ item.quantity}}</td>
                                                        <td v-if="posEditPrice !== index" @dblclick="mtdEditPrecies(item, index)">
                                                            <strong>{{ item.price_fac }}</strong>
                                                        </td>
                                                        <td v-else>
                                                            <input
                                                            type="text"
                                                            class="form-control-sm w-50 text-center custom-input"
                                                            v-model="newPrice"
                                                            @keyup.enter="mtdOutFocusEditPrices"
                                                            @blur="mtdOutFocusEditPrices"
                                                            @keypress="validateNumberInput"
                                                            />
                                                        </td>
                                                        <td>{{ (item.quantity * item.price_fac).toFixed(2) }}</td> 
                                                        <td>
                                                            <span v-if="item.date_update_price">{{ item.date_update_price | formatDateTime }}</span>
                                                            <span v-else class="badge rounded-pill text-bg-danger">No Registrado</span>
                                                        </td>
                                                    </tr>
                                                </template>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </transition>
                        </div>
                        <!--Planes-->
                        <div class="dropdown mt-1" :class="[dropdownPlans && 'activeItem']" v-if="showTablePlan && cpDataPlan.length !== 0">
                            <div class="dropdown-headerFac" @click="toggleDropdownPlans()">
                                PLANES
                                <img src="@/assets/helpers/circle-chevron-down-solid.svg" alt="" class="dropdown-icon" :class="[dropdownPlans && 'active']">
                            </div>

                            <transition @before-enter="beforeEnter" @enter="enter" @before-leave="beforeLeave" @leave="leave">
                                <div class="dropdown-wrapper bordered" v-if="dropdownPlans">
                                    <div class="col-md-12 mt-3" id="content-timelinetable">
                                        <div class="d-flex justify-content-start mb-2">
                                            <button class="btn text-bg-custom btn-sm" @click="registerSalePlan" v-if="!allItemsPaidPlan" >
                                                <i class="fas fa-save"/>&nbsp; <strong>Guardar</strong>
                                            </button>
                                        </div>
                                        <table id="tb-cxc" class="table table-bordered border-main" style="font-size: 11.5px">
                                            <caption class="py-0 my-0"></caption>
                                            <thead>
                                                <tr class="text-center text-white border-white bg-main align-middle">
                                                    <th rowspan="2" scope="col">FECHA</th>
                                                    <th scope="col">LUGAR</th>
                                                    <th scope="col">TIPO DE PACIENTE</th>
                                                    <th scope="col">% PAGA PACIENTE</th>
                                                    <th scope="col">TIPO</th>
                                                    <th scope="col">SERVICIO</th>
                                                    <th scope="col">S/ PRECIO</th>
                                                    <th scope="col">ULT. EDICIÓN</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            <template>
                                                <tr  v-for="(item, index) in cpDataPlan" :key="index" class="text-center align-middle">
                                                    <td><strong>{{item.date | formatDateTime}}</strong></td>
                                                    <td>
                                                        <span>{{ destiPlan(item.destination) }}</span>
                                                    </td>
                                                    <td>{{ item.type_patient}}</td>
                                                    <td style="white-space: nowrap;">% {{ item.payment_patient}}</td>
                                                    <td>{{ typePlan(item.type) }}</td>
                                                    <td>{{ item.product}}</td>
                                                    <td v-if="posEditPricePlan !== index" @dblclick="mtdEditPreciesPlan(item, index)">
                                                        <strong>{{ item.price }}</strong>
                                                    </td>
                                                    <td v-else>
                                                        <input
                                                        type="text"
                                                        class="form-control-sm w-50 text-center custom-input"
                                                        v-model="newPricePlan"
                                                        @keyup.enter="mtdOutFocusEditPricesPlan"
                                                        @blur="mtdOutFocusEditPricesPlan"
                                                        @keypress="validateNumberInput"
                                                        />
                                                    </td>
                                                    <td>
                                                        <span v-if="item.date_update_price">{{ item.date_update_price | formatDateTime }}</span>
                                                        <span v-else class="badge rounded-pill text-bg-danger">No Registrado</span>
                                                    </td>
                                                </tr>
                                            </template>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </transition>
                        </div>
                        <!--Oxigeno-->
                        <div class="dropdown mt-1" :class="[dropdownOxi && 'activeItem']" v-if="showTableOxi && cpDataOxi.length !== 0">
                            <div class="dropdown-headerFac" @click="toggleDropdownOxi()">
                                OXIGENO
                                <img src="@/assets/helpers/circle-chevron-down-solid.svg" alt="" class="dropdown-icon" :class="[dropdownOxi && 'active']">
                            </div>

                            <transition @before-enter="beforeEnter" @enter="enter" @before-leave="beforeLeave" @leave="leave">
                                <div class="dropdown-wrapper bordered" v-if="dropdownOxi">
                                    <div class="col-md-12 mt-3" id="content-timelinetable">
                                        <div class="d-flex justify-content-start mb-2">
                                            <button class="btn text-bg-custom btn-sm" @click="registerSaleOxi" v-if="!allItemsPaidOxi" >
                                                <i class="fas fa-save"/>&nbsp; <strong>Guardar</strong>
                                            </button>
                                        </div>
                                        <table id="tb-cxc" class="table table-bordered border-main" style="font-size: 11px">
                                            <caption class="py-0 my-0"></caption>
                                            <thead>
                                                <tr class="text-center text-white border-white bg-main align-middle">
                                                    <th rowspan="2" scope="col">FECHA</th>
                                                    <th rowspan="2" scope="col">DISPOSITIVO</th>
                                                    <th colspan="6" scope="col">M</th>
                                                    <th colspan="6" scope="col">T</th>
                                                    <th colspan="6" scope="col">N</th>
                                                    <th rowspan="2" scope="col">S/ TOTAL</th>
                                                </tr>
                                                <tr class="text-center text-white border-white bg-main align-middle">
                                                    <th scope="col">FIO2 %</th>
                                                    <th scope="col">MINUTOS</th>
                                                    <th scope="col">LITROS</th>
                                                    <th scope="col">COSTO</th>
                                                    <th scope="col">ULT. EDICIÓN</th>
                                                    <th scope="col">S/ SUBTOTAL</th>

                                                    <th scope="col">FIO2 %</th>
                                                    <th scope="col">MINUTOS</th>
                                                    <th scope="col">LITROS</th>
                                                    <th scope="col">COSTO</th>
                                                    <th scope="col">ULT. EDICIÓN</th>
                                                    <th scope="col">S/ SUBTOTAL</th>

                                                    <th scope="col">FIO2 %</th>
                                                    <th scope="col">MINUTOS</th>
                                                    <th scope="col">LITROS</th>
                                                    <th scope="col">COSTO</th>
                                                    <th scope="col">ULT. EDICIÓN</th>
                                                    <th scope="col">S/ SUBTOTAL</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            <tr v-for="(oxygen, index) in cpDataOxi" :key="index" class="text-center align-middle">
                                                <td class="text-center"><span><strong>{{ oxygen.date | formatDate}}</strong></span></td>
                                                <td class="text-center"><span>{{ oxygen.device }}</span></td>

                                                <td class="text-center"><span>{{ parseFloat(oxygen.fio2_m) > 0 ? parseFloat(oxygen.fio2_m)+'%' : '-' }}</span></td>
                                                <td class="text-center" v-if="oxygen.type_time=1"><span>{{ parseInt(oxygen.minute_m) > 0 ? parseInt(oxygen.minute_m)+' mins' : '-' }}</span></td>
                                                <td class="text-center" v-else><span>{{ parseInt(oxygen.minute_m) > 0 ? parseInt(oxygen.minute_m)+' hrs' : '-' }}</span></td>
                                                <td class="text-center"><span>{{ parseFloat(oxygen.liter_m) > 0 ? parseFloat(oxygen.liter_m)+' lts' : '-' }}</span></td>
                                                <td class="text-center"><span>{{ parseFloat(oxygen.coste_m) > 0 ? parseFloat(oxygen.coste_m) : '-' }}</span></td>
                                                <td class="text-center">
                                                    <span v-if="oxygen.date_update_price_m">{{ oxygen.date_update_price_m | formatDateTime }}</span>
                                                    <span v-else class="badge rounded-pill text-bg-danger">No Registrado</span>
                                                </td>
                                                <td v-if="!oxygen.id_m || posEditPriceOxi !== oxygen.id_m" @dblclick="mtdEditPreciesOxi(oxygen, 1)" class="fw-bold">
                                                    {{ oxygen.subTotal_m ? oxygen.subTotal_m : '-' }}
                                                </td>
                                                <td v-else>
                                                    <input
                                                    type="text"
                                                    class="form-control-sm w-50 text-center custom-input"
                                                    v-model="newPriceOxi"
                                                    @keyup.enter="mtdOutFocusEditPricesOxi(1)"
                                                    @blur="mtdOutFocusEditPricesOxi(1)"
                                                    @keypress="validateNumberInput"
                                                    />
                                                </td>

                                                <td class="text-center"><span>{{ parseFloat(oxygen.fio2_t) > 0 ? parseFloat(oxygen.fio2_t)+'%' : '-' }}</span></td>
                                                <td class="text-center" v-if="oxygen.type_time=1"><span>{{ parseInt(oxygen.minute_t) > 0 ? parseInt(oxygen.minute_t)+' mins' : '-' }}</span></td>
                                                <td class="text-center" v-else><span>{{ parseInt(oxygen.minute_t) > 0 ? parseInt(oxygen.minute_t)+' hrs' : '-' }}</span></td>
                                                <td class="text-center"><span>{{ parseFloat(oxygen.liter_t) > 0 ? parseFloat(oxygen.liter_t)+' lts' : '-' }}</span></td>
                                                <td class="text-center"><span>{{ parseFloat(oxygen.coste_t) > 0 ? parseFloat(oxygen.coste_t) : '-' }}</span></td>
                                                <td class="text-center">
                                                    <span v-if="oxygen.date_update_price_t">{{ oxygen.date_update_price_t | formatDateTime }}</span>
                                                    <span v-else class="badge rounded-pill text-bg-danger">No Registrado</span>
                                                </td>
                                                <td v-if="!oxygen.id_t || posEditPriceOxi !== oxygen.id_t" @dblclick="mtdEditPreciesOxi(oxygen, 2)" class="fw-bold">
                                                    {{ oxygen.subTotal_t ? oxygen.subTotal_t : '-' }}
                                                </td>
                                                <td v-else>
                                                    <input
                                                    type="text"
                                                    class="form-control-sm w-50 text-center custom-input"
                                                    v-model="newPriceOxi"
                                                    @keyup.enter="mtdOutFocusEditPricesOxi(2)"
                                                    @blur="mtdOutFocusEditPricesOxi(2)"
                                                    @keypress="validateNumberInput"
                                                    />
                                                </td>

                                                <td class="text-center"><span>{{ parseFloat(oxygen.fio2_n) > 0 ? parseFloat(oxygen.fio2_n)+'%' : '-' }}</span></td>
                                                <td class="text-center" v-if="oxygen.type_nime=1"><span>{{ parseInt(oxygen.minute_n) > 0 ? parseInt(oxygen.minute_n)+' mins' : '-' }}</span></td>
                                                <td class="text-center" v-else><span>{{ parseInt(oxygen.minute_n) > 0 ? parseInt(oxygen.minute_n)+' hrs' : '-' }}</span></td>
                                                <td class="text-center"><span>{{ parseFloat(oxygen.liter_n) > 0 ? parseFloat(oxygen.liter_n)+' lts' : '-' }}</span></td>
                                                <td class="text-center"><span>{{ parseFloat(oxygen.coste_n) > 0 ? parseFloat(oxygen.coste_n) : '-' }}</span></td>
                                                <td class="text-center">
                                                    <span v-if="oxygen.date_update_price_n">{{ oxygen.date_update_price_n | formatDateTime }}</span>
                                                    <span v-else class="badge rounded-pill text-bg-danger">No Registrado</span>
                                                </td>
                                                <td v-if="!oxygen.id_n || posEditPriceOxi !== oxygen.id_n" @dblclick="mtdEditPreciesOxi(oxygen, 3)" class="fw-bold">
                                                    {{ oxygen.subTotal_n ? oxygen.subTotal_n : '-' }}
                                                </td>
                                                <td v-else>
                                                    <input
                                                    type="text"
                                                    class="form-control-sm w-50 text-center custom-input"
                                                    v-model="newPriceOxi"
                                                    @keyup.enter="mtdOutFocusEditPricesOxi(3)"
                                                    @blur="mtdOutFocusEditPricesOxi(3)"
                                                    @keypress="validateNumberInput"
                                                    />
                                                </td>
                                                
                                                <td class="text-center" :id="'total_'+index"><span class="fw-bold" style="color: #900052;">{{ oxygen.total }}</span></td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </transition>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    <cModalAddPayVue
        :title="modalPay.title"
        :boo_modal="modalPay.boo_modal"
        :item="modalPay.item"
        :saleId="modalPay.sale_id"
        :patientId="modalPay.patient_id"
        :idDis="modalPay.id"
        @mtdCommitEditPrice="mtdCommitEditPrice"
        @mtdClose="mtdClose"
        @mtdUpdate="mtdGetDataUp"
    />
      <SweetAlert :swal="swal" ref="SweetAlert" />
    </div>
</template>
<script>
import { mapActions } from "vuex";
import moment from "moment";
import cModalAddPayVue from "../Modals/cModalAddPay.vue";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';

export default {
    name: "c-pages-billing",
    components:{
        SweetAlert,
        cModalAddPayVue
    },
    data() {
        return {
            swal: null,
            search: "",
            servicesPa: [],
            selectedPa: "",

            data:[],
            dataPlan:[],
            dataOxi:[],

            dataShow: [],
            dataShowPlan: [],
            dataShowOxi: [],

            showTable: false,
            showTablePlan: false,
            showTableOxi: false,

            modalPay: {
                title: "Editar Liquidación",
                item: [],
                boo_modal: false,
                pos: null,
                sale_id: null,
                patient_id: null,
                id: null
            },

            dropdown: false,
            dropdownPlans: false,
            dropdownOxi: false,
            //Pag Recipe
            page: 0,
            selectPage: 1,
            stepPagination: [],
            totalPagesToShow: 4,
            //Pag Plan
            pagePlan: 0,
            selectPagePlan: 1,
            stepPaginationPlan: [],
            totalPagesToShowPlan: 4,
            //Pag Oxi
            pageOxi: 0,
            selectPageOxi: 1,
            stepPaginationOxi: [],
            totalPagesToShowOxi: 4,

            posEditPrice: null,
            newPrice: null,
            oldPrice: null,
            editingItem: null,

            posEditPricePlan: null,
            newPricePlan: null,
            oldPricePlan: null,

            posEditPriceOxi: null,
            newPriceOxi: null,
            oldPriceOxi: null,

            detalle: [],
            detallePlan: [],
            detalleOxi: [],
        }
    },
    created() {
    },
    watch: {
        selectPage() {
            this.calculateData(5);
        },
        selectPagePlan() {
            this.calculateDataPlan(5);
        },
        selectPageOxi() {
            this.calculateDataOxi(5);
        },
        selectedPa(newValue) {
            this.showTable = false;
            this.showTablePlan = false;
            this.showTableOxi = false;
            this.dropdown = false;
            this.dropdownPlans = false;
            this.dropdownOxi = false;
        }
    },
    computed: {
        cpData() {
            let show = [];
            this.data.forEach((element) => {
                show.push(element);
            });
            this.dataShow = show;
            return show;
        },

        cpDataPlan() {
            let show = [];
            this.dataPlan.forEach((element) => {
                show.push(element);
            });
            this.dataShowPlan = show;
            return show;
        },

        cpDataOxi() {
            let show = [];
            this.dataOxi.forEach((element) => {
                show.push(element);
            });
            this.dataShowOxi = show;
            return show;
        },

        limitedStepPagination() {
            const totalPages = this.stepPagination.length;
            const currentPage = this.selectPage;

            let startPage = 1;
            let endPage = totalPages;
            if (totalPages > this.totalPagesToShow) {
                const halfPagesToShow = Math.floor(this.totalPagesToShow / 2);

                if (currentPage > halfPagesToShow) {
                    startPage = currentPage - halfPagesToShow;
                    endPage = currentPage + halfPagesToShow - 1;

                    if (endPage > totalPages) {
                        endPage = totalPages;
                        startPage = totalPages - this.totalPagesToShow + 1;
                    }
                } else {
                    endPage = this.totalPagesToShow;
                }
            }

            return Array.from({
                    length: endPage - startPage + 1,
                },
                (_, i) => startPage + i
            );
        },

        limitedStepPaginationPlan() {
            const totalPages = this.stepPaginationPlan.length;
            const currentPage = this.selectPagePlan;

            let startPage = 1;
            let endPage = totalPages;
            if (totalPages > this.totalPagesToShowPlan) {
                const halfPagesToShow = Math.floor(this.totalPagesToShowPlan / 2);

                if (currentPage > halfPagesToShow) {
                    startPage = currentPage - halfPagesToShow;
                    endPage = currentPage + halfPagesToShow - 1;

                    if (endPage > totalPages) {
                        endPage = totalPages;
                        startPage = totalPages - this.totalPagesToShowPlan + 1;
                    }
                } else {
                    endPage = this.totalPagesToShowPlan;
                }
            }

            return Array.from({
                    length: endPage - startPage + 1,
                },
                (_, i) => startPage + i
            );
        },

        limitedStepPaginationOxi() {
            const totalPages = this.stepPaginationOxi.length;
            const currentPage = this.selectPageOxi;

            let startPage = 1;
            let endPage = totalPages;
            if (totalPages > this.totalPagesToShowOxi) {
                const halfPagesToShow = Math.floor(this.totalPagesToShowOxi / 2);

                if (currentPage > halfPagesToShow) {
                    startPage = currentPage - halfPagesToShow;
                    endPage = currentPage + halfPagesToShow - 1;

                    if (endPage > totalPages) {
                        endPage = totalPages;
                        startPage = totalPages - this.totalPagesToShowOxi + 1;
                    }
                } else {
                    endPage = this.totalPagesToShowOxi;
                }
            }

            return Array.from({
                    length: endPage - startPage + 1,
                },
                (_, i) => startPage + i
            );
        },

        allItemsPaid() {
            return this.data.every(item => item.state == 1);
        },

        allItemsPaidPlan() {
            return this.dataPlan.every(item => item.sale_detail && item.sale_detail.state == 1);
        },

        allItemsPaidOxi() {
            return this.dataOxi.every(item => {
            // Verificamos las tres condiciones: m, n, t
            const check_m = item.id_m !== null ? item.stateDet_m === 1 : true;
            const check_n = item.id_n !== null ? item.stateDet_n === 1 : true;
            const check_t = item.id_t !== null ? item.stateDet_t === 1 : true;
            return check_m && check_n && check_t;
        });
        },
    },
    methods: {
        ...mapActions(["get", "post"]),
        returnHome: function () {
            this.$emit("returnHome");
        },

        searchPa: function (search, loading) {
            if (search.length > 3) {
                this.showTable = false;
                this.servicesPa = [];
                this.selectedPa =  "",
                loading(true);

                this.get({
                    url: this.$store.getters.get__url + "/Billing/patient/" + search,
                    token: this.$store.getters.get__token,
                })
                .then((response) => {
                    this.servicesPa = response.data;
                    loading(false);
                })
                .catch((errors) => {
                    this.$refs.SweetAlert.showError(errors);
                });
            }
        },

        mtdGetData: function() {
            this.post({
                url: this.$store.getters.get__url + "/Billing/mtdGetDispath",
                token: this.$store.getters.get__token,
                params: {
                    selectedPa: this.selectedPa,
                },
            })
            .then((response) => {
                //Recipe
                if (response.data.data.length > 0) {
                    this.data = response.data.data;
                    this.showTable = true;
                }else{
                    this.showTable = false;
                }
                //Planes
                if(response.data.dataHis.length > 0){
                    this.dataPlan = response.data.dataHis;
                    this.showTablePlan = true;
                }else{
                    this.showTablePlan  = false;
                }
                //Oxigeno
                if(response.data.dataOxi.length > 0){
                    this.dataOxi = response.data.dataOxi;
                    this.showTableOxi = true;
                }else{
                    this.showTableOxi  = false;
                }
                //Alerta
                if (!this.showTable && !this.showTablePlan && !this.showTableOxi) {
                    this.$refs.SweetAlert.showWarning("Sin Registros");
                }
            })
            .catch((errors) => {
                this.$refs.SweetAlert.showError(errors);
            });
        },

        mtdGetDataUp: function(idPa) {
            this.post({
                url: this.$store.getters.get__url + "/Billing/mtdGetDispath",
                token: this.$store.getters.get__token,
                params: {
                    selectedPa: idPa,
                },
            })
            .then((response) => {
                //Recipe
                if(response.data.data.length > 0){
                    this.data = response.data.data;
                    this.showTable = true;
                }else{
                    this.showTable = false;
                }
                //Planes
                if(response.data.dataHis.length > 0){
                    this.dataPlan = response.data.dataHis;
                    this.showTablePlan = true;
                }else{
                    this.showTablePlan  = false;
                }
                 //Oxigeno
                if(response.data.dataOxi.length > 0){
                    this.dataOxi = response.data.dataOxi;
                    this.showTableOxi = true;
                }else{
                    this.showTableOxi  = false;
                }
                //Alerta
                if (!this.showTable && !this.showTablePlan && !this.showTableOxi) {
                    this.$refs.SweetAlert.showWarning("Sin Registros");
                }
            })
            .catch((errors) => {
                this.$refs.SweetAlert.showError(errors);
            });
        },

        calculateData(items) {
            if (!this.data || !this.data.length) {
                return (this.dataShow = []);
            }
            let filteredData = this.data;
            let indexInitial = this.selectPage;
            let totalPages = Math.ceil(filteredData.length / items);
            this.stepPagination = Array.from({
                    length: totalPages,
                },
                (_, index) => index + 1
            );
            let startIndex = (indexInitial - 1) * items;
            let endIndex = startIndex + (items - 1);
            this.dataShow = filteredData.slice(startIndex, endIndex + 1);
            this.count = this.count + 1;
        },

        calculateDataPlan(items) {
            if (!this.dataPlan || !this.dataPlan.length) {
                return (this.dataShowPlan = []);
            }
            let filteredData = this.dataPlan;
            let indexInitial = this.selectPagePlan;
            let totalPages = Math.ceil(filteredData.length / items);
            this.stepPaginationPlan = Array.from({
                    length: totalPages,
                },
                (_, index) => index + 1
            );
            let startIndex = (indexInitial - 1) * items;
            let endIndex = startIndex + (items - 1);
            this.dataShowPlan = filteredData.slice(startIndex, endIndex + 1);
            this.countPlan = this.countPlan + 1;
        },

        calculateDataOxi(items) {
            if (!this.dataOxi || !this.dataOxi.length) {
                return (this.dataShowOxi = []);
            }
            let filteredData = this.dataOxi;
            let indexInitial = this.selectPageOxi;
            let totalPages = Math.ceil(filteredData.length / items);
            this.stepPaginationOxi = Array.from({
                    length: totalPages,
                },
                (_, index) => index + 1
            );
            let startIndex = (indexInitial - 1) * items;
            let endIndex = startIndex + (items - 1);
            this.dataShowOxi = filteredData.slice(startIndex, endIndex + 1);
            this.countOxi = this.countOxi + 1;
        },

        filterKey: function (e) {
            let b = false;
            b = /^[0-9]+$/.test(e.key);
            if (b == false && e.key != "Backspace") {
            e.preventDefault();
            }
        },

        mtdPdf: function(id) {
            window.open(
                this.$store.getters.get__url + "/viewpdfDocInterno/" + id,
                "_blank"
            );
        },

        mtdAddPrice: function(item, pos) {
            this.modalPay.title = `Editar Liquidación: ${item.serie} - ${item.number}`;
            this.modalPay.item = item.detail;
            this.modalPay.pos = pos;
            this.modalPay.sale_id = item.sale_id;
            this.modalPay.patient_id = item.patient_id;
            this.modalPay.id = item.id;
            this.modalPay.boo_modal = true;
        },

        mtdClose: function () {
            this.modalPay = {
            title: "Editar Liquidación",
            item: [],
            boo_modal: false,
            pos: null,
            sale_id: null,
            patient_id: null,
            };
        },

        mtdCommitEditPrice: function (pos, newPrice) {
            this.get({
            url:
                this.$store.getters.get__url +
                "/Billing/editPrice/" +
                this.modalPay.item[pos].id +
                "/" +
                newPrice,
            token: this.$store.getters.get__token,
            })
            .then((response) => {
                if (response.data.state == 0) {
                    this.modalPay.item[pos].price = newPrice;
                    this.$refs.SweetAlert.showSuccess("Editado Correctamente");
                } else {
                    this.$refs.SweetAlert.showError("Error");
                }
            })
            .catch((errors) => {
                this.$refs.SweetAlert.showError(errors);
            });
        },

        typePlan(type) {
            switch (type) {
            case 1:
                return "LABORATORIO";
            case 2:
                return "IMAGENES";
            case 3:
                return "PROCEDIMIENTOS";
            case 4:
                return "PROCEDIMIENTOS";
            default:
                return "-";
            }
        },

        destiPlan(item) {
            switch (item) {
            case 1:
                return "CONSULTAS";
            case 3:
                return "HOSPITALIZACIÓN";
            case 4:
                return "UCI ADULTO";
            case 6:
                return "SOP";
            case 7:
                return "EMERGENCIA";
            default:
                return "-";
            }
        },

        destiPlanClass(item) {
            switch (item) {
                case 1:
                    return "badge rounded-pill text-bg-info";   
                case 3:
                    return "badge rounded-pill text-bg-primary";   
                case 4:
                    return "badge rounded-pill text-bg-success";        
                case 6:
                    return "badge rounded-pill text-bg-warning";  
                case 7:
                    return "badge rounded-pill text-bg-dark";     
                default:
                    return "badge rounded-pill text-bg-secondary";      
            }
        },

        //PARA RECIPES EDITAR PRECIO
        mtdEditPrecies(item, pos) {
            if (item.state == 1) {
                this.$refs.SweetAlert.showWarning("No puedes editar el Precio de algo ya Pagado");
                return;
            }
            
            this.posEditPrice = pos;
            this.oldPrice = this.data[pos].price_fac;
            this.newPrice = this.data[pos].price_fac;
        },

        mtdOutFocusEditPrices() {
            if (this.oldPrice !== this.newPrice && this.newPrice > 0) {
                Swal.fire({
                    icon: "warning",
                    title: "¿Desea cambiar este Precio?",
                    width: "400px",
                    showDenyButton: true,
                    denyButtonText: `Rechazar`,
                    denyButtonColor: "#bb8a17",
                    confirmButtonText: "Permitir",
                    confirmButtonColor: "#3085d6",
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.mtdCommitEditPrice(this.posEditPrice, this.newPrice); 
                        this.resetPriceEditing();
                    } else if (result.isDenied) {
                        this.resetPriceEditing();
                    }
                });
            } else {
                this.resetPriceEditing();
            }
        },

        mtdCommitEditPrice(pos, newPrice) {
            this.get({
                url:
                this.$store.getters.get__url +
                "/Billing/editPrice/" +
                this.data[pos].id +
                "/" +
                newPrice,
                token: this.$store.getters.get__token,
            })
            .then((response) => {
                if (response.data.state == 0) {
                    this.data[pos].price_fac = newPrice;
                    this.$refs.SweetAlert.showSuccess("Editado Correctamente");
                } else {
                    this.$refs.SweetAlert.showError("Error");
                }
            })
            .catch((errors) => {
                this.$refs.SweetAlert.showError(errors);
            });
        },

        resetPriceEditing() {
            this.posEditPrice = null;
            this.oldPrice = null;
            this.newPrice = null;
        },

        registerSale(){
            this.detalle = this.data.map(item => ({
            id: item.id,
            quantity: item.quantity,
            price: item.price_fac,
            state: item.state,
            })).filter(item => item.quantity > 0);
            
            const allQuantityZero = this.detalle.every(item => item.quantity === 0);

            if (allQuantityZero) {
                this.$refs.SweetAlert.showWarning("No se puede Registrar. Todos los Medicamentos tienen cantidad igual a 0");
                return; 
            }
        
            this.$refs.SweetAlert.showConfirmSimple2("","¿Estas seguro de agregar estos Medicamentos?","warning","Aceptar") 
            .then((result) => {
                if (result.value) {
                    this.post({
                        url: this.$store.getters.get__url + "/Billing/registerSale",
                        token: this.$store.getters.get__token,
                        params: {
                            saleId: this.data[0].sale_id,
                            patientId: this.selectedPa,
                            detalle: this.detalle
                        },
                    })
                    .then((response) => {
                        if(response.data.state == 0){
                            this.$refs.SweetAlert.showSuccess("Agregado Correctamente");
                            this.mtdGetDataUp(this.selectedPa);
                        }else{
                            this.$refs.SweetAlert.showError("Error");
                        }
                    })
                    .catch((errors) => {
                        this.$refs.SweetAlert.showError(errors);
                    });
                } 
            });
        },

        //PARA PLANES EDITAR PRECIO
        validateNumberInput(event) {
            const regex = /^[0-9]*\.?[0-9]*$/;
            if (!regex.test(event.key)) {
            event.preventDefault();
            }
        },
    
        mtdEditPreciesPlan: function (item, pos) {
            if (item.sale_detail && item.sale_detail.state == 1) {
            this.$refs.SweetAlert.showWarning("No puedes editar el Precio de algo ya Pagado");
            return;
            }
            this.posEditPricePlan = pos;
            this.oldPricePlan = this.dataPlan[pos].price;
            this.newPricePlan = this.dataPlan[pos].price;
        },
  
        mtdOutFocusEditPricesPlan: function () {
            if (
                this.oldPricePlan !== this.newPricePlan &&
                this.newPricePlan > 0
            ) {
                Swal.fire({
                icon: "warning",
                title: "¿Desea cambiar este Precio?",
                width: "400px",
                showDenyButton: true,
                denyButtonText: `Rechazar`,
                denyButtonColor: "#bb8a17",
                confirmButtonText: "Permitir",
                confirmButtonColor: "#3085d6",
                }).then((result) => {
                if (result.isConfirmed) {
                    this.mtdCommitEditPricePlan(this.posEditPricePlan, this.newPricePlan);
                    this.posEditPricePlan = null;
                    this.oldPricePlan = null;
                    this.newPricePlan = null;
                } else if (result.isDenied) {
                    this.posEditPricePlan = null;
                    this.oldPricePlan = null;
                    this.newPricePlan = null;
                }
                });
            } else {
                this.posEditPricePlan = null;
                this.oldPricePlan = null;
                this.newPricePlan = null;
            }
        },

        mtdCommitEditPricePlan: function (pos, newPrice) {
            this.get({
            url:
                this.$store.getters.get__url +
                "/Billing/editPricePlan/" +
                this.dataPlan[pos].id +
                "/" +
                newPrice,
            token: this.$store.getters.get__token,
            })
            .then((response) => {
                if (response.data.state == 0) {
                    this.dataPlan[pos].price = newPrice;
                    this.$refs.SweetAlert.showSuccess("Editado Correctamente");
                } else {
                    this.$refs.SweetAlert.showError("Error");
                }
            })
            .catch((errors) => {
                this.$refs.SweetAlert.showError(errors);
            });
        },

        registerSalePlan(){
            this.detallePlan = this.dataPlan.map(item => ({
            id: item.id,
            category_id: item.category_id,
            pdId: item.subsub_categorie_id,
            quantity: 1,
            price: item.price,
            state: item.stateDet,
            sale_details_id: item.sale_detail_id,
            })).filter(item => item.quantity > 0);

            const allQuantityZero = this.detallePlan.every(item => item.quantity === 0);

            if (allQuantityZero) {
                this.$refs.SweetAlert.showWarning("No se puede Registrar. Todos los Medicamentos tienen cantidad igual a 0");
                return; 
            }
        
            this.$refs.SweetAlert.showConfirmSimple2("","¿Estas seguro de agregar estos Medicamentos?","warning","Aceptar") 
            .then((result) => {
                if (result.value) {
                    this.post({
                        url: this.$store.getters.get__url + "/Billing/registerSalePlan",
                        token: this.$store.getters.get__token,
                        params: {
                            campus: this.$store.getters.get__campus,
                            saleId: this.dataPlan[0].sale_id,
                            patientId: this.selectedPa,
                            detalle: this.detallePlan
                        },
                    })
                    .then((response) => {
                        if(response.data.state == 0){
                            this.$refs.SweetAlert.showSuccess("Agregado Correctamente");
                            this.mtdGetDataUp(this.selectedPa);
                        }else{
                            this.$refs.SweetAlert.showError("Error");
                        }
                    })
                    .catch((errors) => {
                        this.$refs.SweetAlert.showError(errors);
                    });
                } 
            });
        },

        //PARA OXIGENO EDITAR PRECIO
        mtdEditPreciesOxi: function (item, num) {
            if(num == 1){
                if (item.stateDet_m == 1) {
                    this.$refs.SweetAlert.showWarning("No puedes editar el Precio de algo ya Pagado");
                    return;
                }
                this.posEditPriceOxi = item.id_m;
                this.oldPriceOxi = item.subTotal_m;
                this.newPriceOxi = item.subTotal_m;
            }else if(num == 2){
                if (item.stateDet_t == 1) {
                    this.$refs.SweetAlert.showWarning("No puedes editar el Precio de algo ya Pagado");
                    return;
                }
                this.posEditPriceOxi = item.id_t;
                this.oldPriceOxi = item.subTotal_t;
                this.newPriceOxi = item.subTotal_t;
            }else{
                if (item.stateDet_n == 1) {
                    this.$refs.SweetAlert.showWarning("No puedes editar el Precio de algo ya Pagado");
                    return;
                }
                this.posEditPriceOxi = item.id_n;
                this.oldPriceOxi = item.subTotal_n;
                this.newPriceOxi = item.subTotal_n;
            }
        },
  
        mtdOutFocusEditPricesOxi: function (num) {
            if (
                this.oldPriceOxi !== this.newPriceOxi &&
                this.newPriceOxi > 0
            ) {
                Swal.fire({
                icon: "warning",
                title: "¿Desea cambiar este Precio?",
                width: "400px",
                showDenyButton: true,
                denyButtonText: `Rechazar`,
                denyButtonColor: "#bb8a17",
                confirmButtonText: "Permitir",
                confirmButtonColor: "#3085d6",
                }).then((result) => {
                if (result.isConfirmed) {
                    this.mtdCommitEditPriceOxi(this.posEditPriceOxi, this.newPriceOxi, num);
                    this.posEditPriceOxi = null;
                    this.oldPriceOxi = null;
                    this.newPriceOxi = null;
                } else if (result.isDenied) {
                    this.posEditPriceOxi = null;
                    this.oldPriceOxi = null;
                    this.newPriceOxi = null;
                }
                });
            } else {
                this.posEditPriceOxi = null;
                this.oldPriceOxi = null;
                this.newPriceOxi = null;
            }
        },

        mtdCommitEditPriceOxi: function (id, newPrice, num) {
            this.get({
            url: `${this.$store.getters.get__url}/Billing/editPriceOxi/${id}/${newPrice}`,
            token: this.$store.getters.get__token,
            })
            .then((response) => {
                if (response.data.state == 0) {
                    this.mtdGetData(this.selectedPa);
                    /*
                    if(num == 1){
                        let item = this.dataOxi.find((item) => item.id_m === id); 
                        if (item) {
                            item.subTotal_m = newPrice;
                        }
                    }else if(num == 2){
                        let item = this.dataOxi.find((item) => item.id_t === id); 
                        if (item) {
                            item.subTotal_t = newPrice;
                        }
                    }else{
                        let item = this.dataOxi.find((item) => item.id_n === id); 
                        if (item) {
                            item.subTotal_n = newPrice;
                        }
                    }
                    */
                    
                } else {
                    this.$refs.SweetAlert.showError("Error");
                }
            })
            .catch((errors) => {
                this.$refs.SweetAlert.showError(errors);
            });
        },

        registerSaleOxi(){
            this.detalleOxi = this.dataOxi.flatMap(item => {
            const detalles = [];

            if (item.id_m !== null) {
                detalles.push({
                id: item.id_m,
                price: item.subTotal_m,
                state: item.stateDet_m,
                sale_details_id: item.sale_detail_id_m,
                });
            }

            if (item.id_n !== null) {
                detalles.push({
                id: item.id_n,
                price: item.subTotal_n,
                state: item.stateDet_n,
                sale_details_id: item.sale_detail_id_n,
                });
            }

            if (item.id_t !== null) {
                detalles.push({
                id: item.id_t,
                price: item.subTotal_t,
                state: item.stateDet_t,
                sale_details_id: item.sale_detail_id_t,
                });
            }

            return detalles;
            });

            this.$refs.SweetAlert.showConfirmSimple2("","¿Estas seguro de agregar estos Medicamentos?","warning","Aceptar") 
            .then((result) => {
                if (result.value) {
                    this.post({
                        url: this.$store.getters.get__url + "/Billing/registerSaleOxi",
                        token: this.$store.getters.get__token,
                        params: {
                            campus: this.$store.getters.get__campus,
                            saleId: this.dataOxi[0].sale_id,
                            patientId: this.selectedPa,
                            detalle: this.detalleOxi
                        },
                    })
                    .then((response) => {
                        if(response.data.state == 0){
                            this.$refs.SweetAlert.showSuccess("Agregado Correctamente");
                            this.mtdGetDataUp(this.selectedPa);
                        }else{
                            this.$refs.SweetAlert.showError("Error");
                        }
                    })
                    .catch((errors) => {
                        this.$refs.SweetAlert.showError(errors);
                    });
                } 
            });
        },

        //Pag Recipe
        backPage() {
            this.selectPage = this.selectPage > 1 ? this.selectPage - 1 : 1;
        },
        nextPage() {
            this.selectPage =
                this.selectPage < this.stepPagination.length ?
                this.selectPage + 1 :
                this.stepPagination.length;
        },
        selectedPage(page) {
            this.selectPage = page;
        },
        //Pag Plan
        backPagePlan() {
            this.selectPagePlan = this.selectPagePlan > 1 ? this.selectPagePlan - 1 : 1;
        },
        nextPagePlan() {
            this.selectPagePlan =
                this.selectPagePlan < this.stepPaginationPlan.length ?
                this.selectPagePlan + 1 :
                this.stepPaginationPlan.length;
        },
        selectedPagePlan(page) {
            this.selectPagePlan = page;
        },
        //Pag Oxi
        backPageOxi() {
            this.selectPageOxi = this.selectPageOxi > 1 ? this.selectPageOxi - 1 : 1;
        },
        nextPageOxi() {
            this.selectPageOxi =
                this.selectPageOxi < this.stepPaginationOxi.length ?
                this.selectPageOxi + 1 :
                this.stepPaginationOxi.length;
        },
        selectedPageOxi(page) {
            this.selectPageOxi = page;
        },
        
        //Dropdown
        toggleDropdown() {
            this.dropdown = !this.dropdown;
            if (this.dropdown) {
                this.dropdownPlans = false; 
                this.dropdownOxi = false; 
            }
        },
        toggleDropdownPlans() {
            this.dropdownPlans = !this.dropdownPlans;
            if (this.dropdownPlans) {
                this.dropdown = false; 
                this.dropdownOxi = false; 
            }
        },
        toggleDropdownOxi() {
            this.dropdownOxi = !this.dropdownOxi;
            if (this.dropdownOxi) {
                this.dropdown = false; 
                this.dropdownPlans = false; 
            }
        },

        beforeEnter(el) {
            el.style.height = 0
        },
        enter(el) {
            el.style.height = el.scrollHeight + 'px'
        },
        beforeLeave(el) {
            el.style.height = el.scrollHeight + 'px'
        },
        leave(el) {
            el.style.height = 0
        },

    },
    filters: {
        formatDate(date) {
            return date != null ? moment(date).format("DD/MM/YYYY") : "-";
        },
        formatDateTime(dateTime) {
            return dateTime != null ? moment(dateTime).format("DD/MM/YYYY HH:mm") : "-";
        },
    },
};
</script>
<style scoped>
    .table-container {
        overflow-x: auto;
        white-space: nowrap;
        scrollbar-width: thin;
        scrollbar-color: darkgray lightgray;
    }
    .bootom {
        background-color: #900052 !important;
        border: 0.5px solid #900052 !important;
    }
    .bordered {
        border: 1.5px solid #900052;
        border-radius: 5px; 
        padding: 15px; 
        margin-top: 10px; 
    }
    .custom-input {
        border: 1px solid #900052;
        border-radius: 4px;
        transition: border-color 0.3s;
    }
  
    .custom-input:focus {
        border-color: #b3006b;
        outline: none;
    }

    .text-bg-custom {
        background-color: #EE7D8B !important;
        color: white; 
        padding: 0.25rem 0.5rem;
        font-size: 0.75rem; 
    }

    @media (max-width: 1199px) {
        .bordered {
            height: 0.75rem;
        }
    }

</style>
