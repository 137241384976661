<template>
  <div class="">
    <div class="w-100 h-100">
      <Nav :rolname="rolname" />
    </div>
    <div class="content">
      <Home
        v-if="page == 'home'"
        @mtdChangePage="mtdChangePage"
        @mtdBackCampus="mtdBackCampus"
      />
    </div>
  </div>
</template>
  
  <script>
import { mapActions } from "vuex";
import Home from "@/components/GerenciaGeneral/Home.vue";
import Nav from "../../components/GerenciaGeneral/Nav.vue";

export default {
  name: "GerenciaView",
  components: {
    Nav,
    Home,
  },

  data() {
    return {
      prefix: "gerencia",
      page: "home",
      rol_name: null,
      stateRolAdmin: false,
    };
  },
  props: {
    rolname: String,
    dataRoles: Array,
    campu: Number,
  },
  created() {
    this.rol_name = this.rolname;
    this.mtdGetRolAdmin();
  },
  methods: {
    ...mapActions(["get", "post"]),
    mtdChangePage: function (page) {
      this.page = page;
    },
    returnHome: function () {
      this.page = "home";
    },
    mtdGetRolAdmin: function () {
      this.dataRoles.forEach((element) => {
        if (element.role_id == 1) {
          this.stateRolAdmin = true;
        }
      });
    },
    mtdBackCampus: function () {
      this.$emit("mtdBackCampus");
    },
  },
};
</script>

<style>
.body{
  background: rgb(219, 216, 216);
  height:100% ;
}
</style>
