<template>
  <CModal
    @mtdclosemodal="mtdclosemodal"
    :show="boo_modal"
    :close-on-backdrop="false"
    :centered="true"
    :title="title"
    :size="'xl'"
    :btn_cancel="true"
    :btn_ok="true"
    :booTitile="true"
  >
    <div class="row mx-0 px-2">
        <b>{{ cuerpo[tab-1].title }}</b>
    </div>
    <div class="row mx-0 px-2 text-center">
        <p>{{ cuerpo[tab-1].text }}</p>
    </div>
    <template #footer>
        <button @click="mtdclosemodal" class="btn btn-danger btn-sm px-4">
            <i class="fas fa-times"></i>
            &nbsp;Cerrar
        </button>
        &nbsp;
        <button @click="mtdInitVoice" class="btn btn-info btn-sm px-4">
            <i class="fas fa-headset"></i>
            &nbsp;Iniciar
        </button>
    </template>
  </CModal>
</template>
    
    <script>
import CModal from "@/components/shared/modals/CModal.vue";

import { mapActions } from "vuex";

export default {
  name: "c-asistencial-consulta-modals-cModalVoice",
  components: {
    CModal,
  },
  data() {
    return {
        cuerpo: [
            {
            value: 1,
            title:
                "SE ENCUENTRA EN LA PRIMERA HOJA, PARA PODER USAR EL DICTADO CORRECTAMENTE DEBE HACERLO POR PALABRAS CLAVE",
            text: 'PARA COLOCAR UN DATO EN GRUPO SANGUINEO DECIR "GRUPO SANGUINEO"; PARA COLOCAR UN DATO EN SIGNOS VITALES EN T.E. DECIR "EXPLORACIÓN"',
            },
            {
            value: 2,
            title:
                "SE ENCUENTRA EN LA SEGUNDA HOJA, PARA PODER USAR EL DICTADO CORRECTAMENTE DEBE HACERLO POR PALABRAS CLAVE",
            text: 'PARA COLOCAR UN DATO EN GRUPO SANGUINEO DECIR "GRUPO SANGUINEO"; PARA COLOCAR UN DATO EN SIGNOS VITALES EN T.E. DECIR "EXPLORACIÓN"',
            },
            {
            value: 3,
            title:
                "SE ENCUENTRA EN LA TERCERA HOJA, PARA PODER USAR EL DICTADO CORRECTAMENTE DEBE HACERLO POR PALABRAS CLAVE",
            text: 'PARA COLOCAR UN DATO EN GRUPO SANGUINEO DECIR "GRUPO SANGUINEO"; PARA COLOCAR UN DATO EN SIGNOS VITALES EN T.E. DECIR "EXPLORACIÓN"',
            },
        ],
    };
  },
  props: {
    title: String,
    boo_modal: Boolean,
    tab: Number,
  },
  methods: {
    ...mapActions(["get", "post"]),
    mtdclosemodal() {
      this.$emit("mtdclosemodalVoice");
    },
    mtdInitVoice(payload) {
      this.$emit("mtdInitVoice", payload);
    },
  },
};
</script>
    