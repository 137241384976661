<template>
<div class="pt-2 pb-3">
    <div class="container-line">
        <div class="row" v-if="rolname !== 'DOCTOR(A)'">
            <div class="col-md-12">
                <div class="row d-flex">
                    <div class="col-md-8 col-lg-6 col-xl-6 px-4" v-if="action !== 'edit'">
                        <div class="row d-flex border border-main text-xs">
                            <div class="col-xl-3 col-lg-5 py-1 px-2 border border-main">
                                <span class="color-main fw-bold">TIPO DE PACIENTE</span>
                            </div>
                            <div class="col-xl-3 col-lg-3 py-1 px-2 text-center border border-main">
                                {{type_patient}}
                            </div>
                            <div class="col-xl-3 col-lg-2 py-1 px-2 border border-main">
                                <span class="color-main fw-bold">PESO</span>
                            </div>
                            <div class="col-xl-3 col-lg-2 py-1 px-2 text-center border border-main">
                                {{graphic_sheets.weight}} Kg.
                            </div>
                        </div>
                    </div>
                    <div class="col-md-2 d-flex justify-content-start align-bottom" v-if="action == 'edit'">
                        <button type="button" class="btn btn-success btn-sm text-white" :disabled="cpTotalGlasgow < 3 || validate == true" @click="mtdadd()"><i class="fas fa-save"></i> Guardar</button>
                    </div>
                    <div class="d-flex justify-content-end" :class="action == 'edit'?'col-md-10':'col-lg-3 col-xl-1 col-md-4'" style="align-items: flex-start">
                        <div class="form-check form-switch">
                            <input class="form-check-input" type="checkbox" id="flexSwitchCheckChecked" :checked="action !== 'edit'" @change="mtdChangeState" />
                            <label class="form-check-label" for="flexSwitchCheckChecked">
                                {{ action == 'edit' ? "AGREGAR" : "VER" }}
                            </label>
                        </div>
                    </div>
                    <div class="col-lg-3 col-xl-5" v-if="action !== 'edit' && data.length > 0">
                        <!-- PAGINADO -->
                        <div class="row d-flex justify-content-end">
                            <div class="col-12 px-2">
                                <nav aria-label="Page navigation example" class="pagination-container">
                                    <ul class="pagination pagination-xs justify-content-end">
                                        <li class="page-item">
                                            <a style="cursor: pointer" href="javascript:void(0)" class="page-link" @click="backPage">«</a>
                                        </li>
                                        <template>
                                            <li class="page-item">
                                                <a class="page-link active" href="javascript:void(0)">PAG. {{ pos + 1  }} - {{ cpName }}</a>
                                            </li>
                                        </template>
                                        <li class="page-item">
                                            <a style="cursor: pointer" class="page-link" @click="nextPage">»</a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div id="show" class="col-md-12 mt-2" v-if="action == 'show'">
                <cTableSheet :sendSheet="sendSheet" :pos="pos" :medical_act_id="medical_act_id" @mtgetsheet="mtgetsheet"></cTableSheet>
            </div>
            <div id="edit" class="col-md-12 my-2" v-if="action == 'edit'">
                <!-- PANEL PARA AGREGAR DATOS -->
                <div class="card shadow p-3" style="max-height: 450px; overflow-y: auto;">
                    <div class="row d-flex">
                        <div class="col-md-6 col-lg-4">
                            <!-- despregable -->
                            <div class="panel-border mt-1 mb-0 px-3 pt-1 d-inline-block" @click="toggleDropdown(0)">
                                <h6 class="fw-bold color-main d-inline-block">{{titleDropdown[0].title}}</h6>
                                <img src="@/assets/helpers/circle-chevron-down-solid.svg" alt="" class="dropdown-icon-content d-inline-block float-end" :class="[titleDropdown[0].state && 'active']">
                            </div>
                            <div class="panel-border mb-1 pb-2" v-if="titleDropdown[0].state">
                                <div class="row px-3">
                                    <div class="col-md-12 col-lg-6 mt-1 align-left">
                                        <div id="div-weight" class="form-group row">
                                            <label for="peso" class="col-sm-5 color-main fw-bold">Peso (Kg)</label>
                                            <div class="col-xl-7 col-sm-12">
                                                <input type="text" v-model="graphic_sheets.weight" v-on:keypress="validateDecimal($event)" class="form-control form-control-input form-control-sm" id="peso">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12 col-lg-6 mt-1 align-left" v-for="(item2, index) in graphic_sheets.values" :key="index">
                                        <div id="div-estado" class="form-group row">
                                            <label :for="'input0' + index" class="col-sm-5 color-main fw-bold">{{ item2.label }}</label>
                                            <div class="col-xl-7 col-sm-12">
                                                <input type="text" v-model="item2.value" v-on:keypress="validateDecimal($event)" class="form-control form-control-input form-control-sm" :id="'input0' + index">
                                                <div v-if="index == 4">
                                                    <span class="text-danger text-xxs" v-if="mtdValiteT(item2) ">
                                                        No permitido</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="panel-border mt-1 mb-0 px-3 pt-1 d-inline-block" @click="toggleDropdown(1)">
                                <h6 class="fw-bold color-main d-inline-block">{{titleDropdown[1].title}}</h6>
                                <img src="@/assets/helpers/circle-chevron-down-solid.svg" alt="" class="dropdown-icon-content d-inline-block float-end" :class="[titleDropdown[1].state && 'active']">
                            </div>
                            <div class="panel-border mb-1 pb-2" v-if="titleDropdown[1].state">
                                <div class="row px-3 pt-1">
                                    <div class="col-md-12 mt-1 align-left" v-for="(itemv, indexv) in valuesOthers" :key="indexv">
                                        <div id="div-estado1" class="form-group row">
                                            <label :for="'inputv' + indexv" class="col-sm-5 color-main fw-bold">{{ itemv.label }}</label>
                                            <div class="col-xl-7 col-sm-12">
                                                <input type="text" v-model="itemv.value" v-on:keypress="validateDecimal($event)" class="form-control form-control-input form-control-sm" :id="'inputv' + indexv">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-4">
                            <!-- despregable -->
                            <div class="panel-border mt-1 mb-0 px-3 pt-1 d-inline-block" @click="toggleDropdown(2)">
                                <h6 class="fw-bold color-main d-inline-block">{{titleDropdown[2].title}}</h6>
                                <img src="@/assets/helpers/circle-chevron-down-solid.svg" alt="" class="dropdown-icon-content d-inline-block float-end" :class="[titleDropdown[2].state && 'active']">
                            </div>
                            <div class="panel-border mb-1 pb-2" v-if="titleDropdown[2].state">
                                <div class="row px-3">
                                    <div class="col-md-12 mt-1 align-left" v-for="(item2, index) in valuesGlasgow" :key="index">
                                        <div id="div-Glasgow" class="form-group row d-flex">
                                            <template>
                                                <label v-if="item2.new == false" :for="'inputvg' + index" class="col-sm-5 col-xl-5 col-lg-12 color-main fw-bold">{{ item2.label }}</label>
                                                <editable-cell v-else :value="item2.label" :index="index" :array="'valuesGlasgow'" @update-label="updateLabel" />

                                            </template>
                                            <div class="col-xl-7  col-lg-7 col-sm-12">
                                                <input type="text" v-model="item2.value" @input="validateAmount($event,index)" class="form-control form-control-input form-control-sm" :id="'inputvg' + index">
                                            </div>
                                            <!-- <div class="col-auto">
                                                <button v-if="index == (valuesGlasgow.length-1)" class="btn btn-success btn-sm text-xxs" title="Agregar fila" @click="mtdAddItem(item2.id,'valuesGlasgow')">
                                                    <i class="fas fa-plus text-white"></i>
                                                </button>
                                                <button v-if="index !== (valuesGlasgow.length-1) && item2.new == true" class="btn btn-danger btn-sm text-xxs" @click="removeItem(index,'valuesGlasgow')">
                                                    <i class="fas fa-minus text-white"></i>
                                                </button>
                                            </div> -->
                                        </div>
                                    </div>
                                    <div class="col-md-12 mt-1 align-left">
                                        <div id="div-total" class="form-group row">
                                            <label for="totalG" class="col-sm-5 color-main fw-bold">TOTAL</label>
                                            <div class="col-xl-7 col-sm-12">
                                                <input type="text" v-model="cpTotalGlasgow" readonly class="form-control form-control-input form-control-sm" id="totalG">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Vasoactivos -->
                            <div class="panel-border mt-1 mb-0 px-3 pt-1 d-inline-block" @click="toggleDropdown(3)">
                                <h6 class="fw-bold color-main d-inline-block">{{titleDropdown[3].title}}</h6>
                                <img src="@/assets/helpers/circle-chevron-down-solid.svg" alt="" class="dropdown-icon-content d-inline-block float-end" :class="[titleDropdown[3].state && 'active']">
                            </div>
                            <div class="panel-border mb-1 pb-2" v-if="titleDropdown[3].state">
                                <div class="row px-3 pt-1">
                                    <div class="col-md-12 mt-1 align-left" v-for="(itemv, indexv) in valuesVasoActivo" :key="indexv">
                                        <div id="div-vaso" class="form-group row">
                                            <template>
                                                <label v-if="itemv.new == false && indexv < 3" :for="'input2' + indexv" class="col-sm-5 col-xl-5 col-lg-12 color-main fw-bold">{{ itemv.label }}</label>
                                                <editable-cell v-else :value="itemv.label" :index="indexv" :array="'valuesVasoActivo'" @update-label="updateLabel" />

                                            </template>
                                            <div :class="mtdClassButton(indexv,valuesVasoActivo,'vaso')">
                                                <input type="text" v-model="itemv.value" v-on:keypress="validateDecimal($event)" class="form-control form-control-input form-control-sm" :id="'input2' + indexv">
                                            </div>
                                            <div class="col-auto">
                                                <button v-if="indexv == (valuesVasoActivo.length-1)" class="btn btn-success btn-sm text-xxs me-1" title="Agregar fila" @click="mtdAddItem(itemv.id,'valuesVasoActivo')">
                                                    <i class="fas fa-plus text-white"></i>
                                                </button>
                                                <button v-if="mtdShowButton(valuesVasoActivo,indexv,'vaso')" class="btn btn-danger btn-sm text-xxs" @click="removeItem(indexv,'valuesVasoActivo')">
                                                    <i class="fas fa-minus text-white"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <!-- 3era columna -->
                        <div class="col-md-6 col-lg-4">
                            <!-- SEDACION -->
                            <div class="panel-border mt-1 mb-0 px-3 pt-1 d-inline-block" @click="toggleDropdown(4)">
                                <h6 class="fw-bold color-main d-inline-block">{{titleDropdown[4].title}}</h6>
                                <img src="@/assets/helpers/circle-chevron-down-solid.svg" alt="" class="dropdown-icon-content d-inline-block float-end" :class="[titleDropdown[4].state && 'active']">
                            </div>
                            <div class="panel-border mb-1 pb-2" v-if="titleDropdown[4].state">
                                <div class="row px-3 pt-1">
                                    <div class="col-md-12 mt-1 align-left" v-for="(itemv, indexv) in valuesSedacion" :key="indexv">
                                        <div id="div-vaso" class="form-group row">
                                            <template>
                                                <label v-if="itemv.new == false" :for="'input3' + indexv" class="col-sm-5 col-xl-5 col-lg-12 color-main fw-bold">{{ itemv.label }}</label>
                                                <editable-cell v-else :value="itemv.label" :index="indexv" :array="'valuesSedacion'" @update-label="updateLabel" />

                                            </template>
                                            <div :class="mtdClassButton(indexv,valuesSedacion,'sedacion')">
                                                <input type="text" v-model="itemv.value" v-on:keypress="validateDecimal($event)" class="form-control form-control-input form-control-sm" :id="'input3' + indexv">
                                            </div>
                                            <div class="col-auto">
                                                <button v-if="indexv == (valuesSedacion.length-1)" class="btn btn-success btn-sm text-xxs me-1" title="Agregar fila" @click="mtdAddItem(itemv.id,'valuesSedacion')">
                                                    <i class="fas fa-plus text-white"></i>
                                                </button>
                                                <button v-if="mtdShowButton(valuesSedacion,indexv,'sedacion')" class="btn btn-danger btn-sm text-xxs" @click="removeItem(indexv,'valuesSedacion')">
                                                    <i class="fas fa-minus text-white"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- calculable -->
                            <div class="panel-border mt-1 mb-0 px-3 pt-1 d-inline-block" @click="toggleDropdown(5)">
                                <h6 class="fw-bold color-main d-inline-block">{{titleDropdown[5].title}}</h6>
                                <img src="@/assets/helpers/circle-chevron-down-solid.svg" alt="" class="dropdown-icon-content d-inline-block float-end" :class="[titleDropdown[5].state && 'active']">
                            </div>
                            <div class="panel-border mb-1 pb-2" v-if="titleDropdown[5].state">
                                <div class="row d-flex px-3">
                                    <div class="col-xl-12 col-md-6 py-1">
                                        <div id="div-device" class="form-group row align-left">
                                            <label for="device" class="col-xl-5 col-md-12 color-main fw-bold">Dispositivo</label>
                                            <div class="col-xl-7 col-md-12">
                                                <select class="form-control form-control-sm" v-model="device" id="device" placeholder="- Seleccionar -">
                                                    <option value="">- Seleccionar -</option>
                                                    <option value="mecanica">VENTILADOR MECÁNICO</option>
                                                    <option value="canula">CÁNULAS NASALES</option>
                                                    <option value="simple">MASCARILLA SIMPLE</option>
                                                    <option value="reservorio">MASCARILLA RESERVORIO</option>
                                                    <option value="venturi">MASCARILLA VÉNTURI</option>
                                                    <option value="gafas">GAFAS NASALES</option>
                                                    <option value="nebulizadora">MÁSCARA NEBULIZADORA</option>
                                                    <option value="caf">CAF</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-12 col-md-6 py-1">
                                        <div id="div-minutes" class="form-group row align-left">
                                            <label for="minutes" class="col-xl-5 color-main fw-bold">MINUTOS/HORAS</label>
                                            <div class="form-group col-xl-6">
                                                <div class="input-group">
                                                    <select v-model="time" class="form-control form-control-sm" id="time">
                                                        <option value="minute">MINUTOS</option>
                                                        <option value="hour">HORAS</option>
                                                    </select>
                                                    <input type="text" v-model="minutes" v-on:keypress="validateDecimal($event)" class="form-control form-control-sm" id="minutes">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-12 py-1">
                                        <div id="div-fio2" class="form-group from-group-sm row align-left">
                                            <label for="fio2" class="col-xl-5 color-main fw-bold">FIO2 %</label>
                                            <div class="col-xl-7">
                                                <input @keyup="obtenerLitraje" v-model="fio2" v-on:keypress="validateDecimal($event)" type="text" class="form-control form-control-sm" id="fio2">
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 col-md-12 py-1">
                                        <div id="div-liter" class="form-group from-group-sm row align-left">
                                            <label for="liter" class="col-xl-5 color-main fw-bold">LITROS</label>
                                            <div class="col-xl-7">
                                                <input v-model="liter" v-on:keypress="validateDecimal($event)" readonly type="text" class="form-control form-control-sm" id="liter">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- monitoreo respiratorio -->
                            <div class="panel-border mt-1 mb-0 px-3 pt-1 d-inline-block" @click="toggleDropdown(6)">
                                <h6 class="fw-bold color-main d-inline-block">{{titleDropdown[6].title}}</h6>
                                <img src="@/assets/helpers/circle-chevron-down-solid.svg" alt="" class="dropdown-icon-content d-inline-block float-end" :class="[titleDropdown[6].state && 'active']">
                            </div>
                            <div class="panel-border pb-3" v-if="titleDropdown[6].state">
                                <div class="row px-3 pt-1">
                                    <div class="col-md-12 mt-1 align-left" v-for="(itemmr, indexmr) in valuesmonitoreo" :key="indexmr">
                                        <div id="div-estado" class="form-group row">
                                            <label :for="'input6' + indexmr" class="col-sm-5 color-main fw-bold text-xs">{{ itemmr.label }}</label>
                                            <div class="col-xl-7 col-sm-12" v-if="itemmr.id !== 1 && itemmr.id !== 15">
                                                <input type="text" v-model="itemmr.value" v-on:keypress="validateDecimal($event)" class="form-control form-control-input form-control-sm" :id="'input6' + indexmr">
                                            </div>
                                            <div class="col-xl-7 col-sm-12" v-else>
                                                <input type="text" v-model="itemmr.value" class="form-control form-control-input form-control-sm" :id="'input6' + indexmr">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <SweetAlert :swal="swal" ref="SweetAlert" @mtdhorario="mtdhorario"/>

</div>
</template>

<script>
import {
    mapActions
} from 'vuex';
import moment from "moment";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import EditableCell from '@/components/shared/column/EditableLabel.vue';
import cTableSheet from '../modals/cTableSheet.vue';

export default {
    name: 'c-asistencial-uciadulto-historia-monitoreohemodinamico',
    props: {
        medical_act_id: {
            type: Number,
            default: 0
        },
        dete_out: {
            type: Boolean
        },
        dete_out_doctor: {
            type: Boolean
        },
        rolname: String
    },
    components: {
        SweetAlert,
        EditableCell,
        cTableSheet,
    },
    watch: {
        medical_act_id: {
            handler: function (newValue) {
                if (newValue != 0) {
                    this.mtdtypepatient();
                    this.mtgetsheet()
                }
            },
            deep: true,
            immediate: true,
        },
    },
    data() {
        return {
            //page: null,
            action: 'show',
            data: [],
            pos: 0,
            dropdown: false,
            validate: false,
            sendSheet: [],
            titleDropdown: [{
                    title: 'Funciones Vitales',
                    state: true,
                },
                {
                    title: 'Otros',
                    state: true,
                },
                {
                    title: 'Glasgow',
                    state: true,
                },
                {
                    title: 'Vasoactivos',
                    state: true,
                },
                {
                    title: 'Sedacion',
                    state: true,
                },
                {
                    title: 'Oxigenoterapia',
                    state: true,
                },
                {
                    title: 'Monitoreo respiratorio',
                    state: false,
                },
            ],
            valuesOthers: [{
                    id: 1,
                    label: 'Hemoglucotex',
                    value: '',
                    value2: '',
                },
                {
                    id: 2,
                    label: 'Insulina',
                    value: '',
                    value2: '',
                },
                {
                    id: 3,
                    label: 'Rass',
                    value: '',
                    value2: '',
                },
            ],
            valuesGlasgow: [{
                    id: 1,
                    label: 'Apertura Ocular',
                    value: '',
                    value2: '',
                    new: false,

                },
                {
                    id: 2,
                    label: 'Respuesta verbal',
                    value: '',
                    value2: '',
                    new: false,
                },
                {
                    id: 3,
                    label: 'Respuesta',
                    value: '',
                    value2: '',
                    new: false,
                },
            ],
            valuesVasoActivo: [{
                    id: 1,
                    label: 'Item 1',
                    value: '',
                    value2: '',
                    new: true,
                }, {
                    id: 2,
                    label: 'Item 2',
                    value: '',
                    value2: '',
                    new: true,
                },
                {
                    id: 3,
                    label: 'Item 3',
                    value: '',
                    value2: '',
                    new: true,
                }
            ],
            valuesSedacion: [{
                id: 1,
                label: 'Item 1',
                value: '',
                value2: '',
                new: true,
            }, {
                id: 2,
                label: 'Item 2',
                value: '',
                value2: '',
                new: true,
            }, {
                id: 3,
                label: 'Item 3',
                value: '',
                value2: '',
                new: true,
            }],
            liter: '',
            device: '',
            fio2: '',
            time: 'minute',
            minutes: '',
            //json para guardarlo
            valuesOx: [],
            valuesmonitoreo: [{
                    id: 1,
                    label: 'VM/Modo',
                    value: '',
                    value2: ''
                },
                {
                    id: 2,
                    label: 'Pro2',
                    value: '',
                    value2: ''
                },
                {
                    id: 3,
                    label: 'VT Programada',
                    value: '',
                    value2: ''
                },
                {
                    id: 4,
                    label: 'VT Espontaneo',
                    value: '',
                    value2: ''
                },
                {
                    id: 5,
                    label: 'FR Programada',
                    value: '',
                    value2: ''
                },
                {
                    id: 6,
                    label: 'FR Espontaneo',
                    value: '',
                    value2: ''
                },
                {
                    id: 7,
                    label: 'PEEP',
                    value: '',
                    value2: ''
                },
                {
                    id: 8,
                    label: 'Presion Pico',
                    value: '',
                    value2: ''
                },
                {
                    id: 9,
                    label: 'Presion Insp.',
                    value: '',
                    value2: ''
                },
                {
                    id: 10,
                    label: 'Presion Sop',
                    value: '',
                    value2: ''
                },
                {
                    id: 11,
                    label: 'Sensibilidad',
                    value: '',
                    value2: ''
                },
                {
                    id: 12,
                    label: 'Rotacion I.E.',
                    value: '',
                    value2: ''
                },
                {
                    id: 13,
                    label: 'Tiempo Insp.',
                    value: '',
                    value2: ''
                },
                {
                    id: 14,
                    label: 'Flujo',
                    value: '',
                    value2: ''
                },
                {
                    id: 15,
                    label: 'Dispositivo O2',
                    value: '',
                    value2: ''
                },
                {
                    id: 16,
                    label: 'N° TOT',
                    value: '',
                    value2: ''
                },
                {
                    id: 17,
                    label: 'N° Fijacion',
                    value: '',
                    value2: ''
                },
                {
                    id: 18,
                    label: 'CUFF',
                    value: '',
                    value2: ''
                },

            ],
            totalVaso: 3,
            totalSedacion: 3,
            /* de hosp */
            swal: null,
            patient_name: '',
            insurance_id: '',
            insurance_name: '',
            type_patient: null,
            peso: '',
            datatime: [],
            datasheets: [],
            graphic_sheets: {
                date_name: 'GRÁFICA ACTUAL',
                id: null,
                medical_act_id: null,
                date: null,
                hour: null,
                day: 0,
                doctor_name: '',
                user_id: null,
                day_po: '',
                weight: '',
                deposition: '',
                urine: '',
                hydric_balance: '',
                values: [{
                        id: 1,
                        label: 'PAS',
                        value: '',
                        value2: '',
                        inputs: 2
                    },
                    {
                        id: 2,
                        label: 'PAD',
                        value: '',
                        inputs: 1
                    },
                    {
                        id: 3,
                        label: 'Pulso',
                        value: '',
                        inputs: 1
                    },
                    {
                        id: 4,
                        label: 'FR',
                        value: '',
                        inputs: 1
                    },
                    {
                        id: 5,
                        label: 'Temp',
                        value: '',
                        inputs: 1
                    },
                    {
                        id: 6,
                        label: 'S02',
                        value: '',
                        inputs: 1
                    },
                    {
                        id: 7,
                        label: 'C02',
                        value: '',
                        inputs: 1
                    }
                ],
                type: 1
            },
            selectedItems: [],

            modal: {
                title: '',
                modal_form: false,
                payload: {},
                dataId: []
            },
        }
    },
    created() {

    },
    computed: {
        cpTotalGlasgow() {
            let sum = 0;
            this.valuesGlasgow.forEach(element => {
                let value = element.value == "" ? 0 : parseInt(element.value);
                sum += value;
            });
            return isNaN(sum) ? 0 : sum;
        },
        cpName() {
            if (this.datatime.length > 0) {

                let date = this.datatime[this.pos].day_page;
                return this.$options.filters.formatDate(date);
            }
        }

    },
    methods: {
        ...mapActions(['get', 'post']),
        mtdValiteT(item) {
            let num = item.value == "" ? 21 : item.value;
            let validate = num < 20 || num > 50;
            this.validate = validate;
            return validate;
        },
        mtdClassButton(index, array,title) {
            let numMax = title == 'sedacion' ? this.totalSedacion : this.totalVaso;
            if (index < 2) {
                return 'col-xl-7 col-sm-12';
            }

            if (array.length === 3 && index === 2) {
                return 'col-xl-5 col-sm-12';
            }

            if (array.length > 3) {
                if (index < numMax - 1) {
                    return 'col-xl-7 col-sm-12';
                } else if (index < array.length - 1) {
                    return 'col-xl-5 col-sm-10';
                } else {
                    return 'col-xl-4 col-sm-12';
                }
            }

            return '';
        },
        mtdShowButton: function (array, index, title) {
            let numMax = title == 'sedacion' ? this.totalSedacion : this.totalVaso;
            if (array.length === 3 && index === 2) {
                return false;
            }

            if (array.length > 3) {
                return index >= numMax && index < array.length;
            }

        },
        mtgetsheet: function () {
            this.get({
                    url: this.$store.getters.get__url + "/Uciadulto/indexGraphicSheets/" + this.medical_act_id,
                    token: this.$store.getters.get__token,
                })
                .then((response) => {
                    this.datatime = response.data.dates;
                    this.graphic_sheets.day = response.data.day;
                    this.data = response.data.sheets; //todos lo graficos agrupado por dia
                    if (this.data.length > 0) {
                        this.pos = this.data.length - 1;
                        this.sendSheet = this.data[this.pos];
                        if (this.sendSheet.length > 0) {
                            //completar los inputs con el ultimo registro
                            this.completed(this.sendSheet[this.sendSheet.length - 1]);
                        }
                    }
                })
                .catch((errors) => {
                    this.$refs.SweetAlert.showError(errors);
                });
        },
        mtdChangeState: function () {
            this.action = this.action == 'edit' ? 'show' : 'edit';
        },
        mtdAddItem(id, array) {
            let num = id - 2;
            let newItem = {
                id: id + 1,
                label: 'Nuevo ' + num,
                value: '',
                value2: '',
                new: true
            };
            switch (array) {
                case 'valuesGlasgow':
                    //this.valuesGlasgow.push(newItem);
                    this.valuesGlasgow.splice(id, 0, newItem);
                    break;
                case 'valuesVasoActivo':
                    this.valuesVasoActivo.push(newItem);
                    break;
                case 'valuesSedacion':
                    this.valuesSedacion.push(newItem);
                    break;
                default:
                    break;
            }
        },
        removeItem(index, array) {
            switch (array) {
                case 'valuesGlasgow':
                    this.valuesGlasgow.splice(index, 1);
                    break;
                case 'valuesVasoActivo':
                    this.valuesVasoActivo.splice(index, 1);
                    break;
                case 'valuesSedacion':
                    this.valuesSedacion.splice(index, 1);
                    break;
                default:
                    break;
            }

        },
        updateLabel(label, index, array) {
            switch (array) {
                case 'valuesGlasgow':
                    this.valuesGlasgow[index].label = label;
                    break;
                case 'valuesVasoActivo':
                    this.valuesVasoActivo[index].label = label;
                    break;
                case 'valuesSedacion':
                    this.valuesSedacion[index].label = label;
                    break;
                default:
                    break;
            }
        },
        completed(item) {
            //const long = response.data.sheets.length - 1;
            const dataSheets = JSON.parse(item.values);
            const dataGlasgow = JSON.parse(item.valuesGlasgow);
            dataGlasgow.pop();
            const pesoSheets = item.weight;
            //completar
            this.graphic_sheets.values = dataSheets;
            this.graphic_sheets.weight = pesoSheets;
            this.valuesOthers = JSON.parse(item.valuesOthers);
            this.valuesmonitoreo = JSON.parse(item.valuesmonitoreo);
            this.valuesGlasgow = dataGlasgow;
            this.valuesVasoActivo = JSON.parse(item.valuesVasoActivo);
            this.totalVaso = this.valuesVasoActivo.length;
            this.valuesSedacion = JSON.parse(item.valuesSedacion);
            this.totalSedacion = this.valuesSedacion.length;

        },
        obtenerLitraje: function () {
            if (this.device === '' || this.device === null) {
                this.$refs.SweetAlert.showInfo('Seleccione un Dispositivo.');
            } else {
                this.post({
                        url: this.$store.getters.get__url + "/Uciadulto/literValue",
                        token: this.$store.getters.get__token,
                        params: {
                            device: this.device,
                            fio2: this.fio2,
                        },
                    })
                    .then((response) => {
                        this.liter = response.data;
                        //crear el json para guardarlo

                    })
                    .catch((errors) => {
                        console.log(errors);
                    });
            }
        },

        validateDecimal: function (evt) {
            evt = evt ? evt : window.event;
            var charCode = evt.which ? evt.which : evt.keyCode;
            if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        validateAmount(event, index) {
            let sanitizedValue = event.target.value.replace(/[^0-6]/g, '');

            const allowedRanges = {
                0: {
                    min: 1,
                    max: 4,
                    message: 'Los números permitidos son del 1 al 4'
                },
                1: {
                    min: 1,
                    max: 5,
                    message: 'Los números permitidos son del 1 al 5'
                },
                2: {
                    min: 1,
                    max: 6,
                    message: 'Los números permitidos son del 1 al 6'
                }
            };

            // Obtener el rango correspondiente al valor o usar el valor por defecto
            const {
                min,
                max,
                message
            } = allowedRanges[index] || allowedRanges['default'];

            if (sanitizedValue && (sanitizedValue < min || sanitizedValue > max)) {
                event.preventDefault();
                this.$refs.SweetAlert.showInfoNumber(message);
                sanitizedValue = ''; // Borrar el valor si está fuera de rango
            }

            this.valuesGlasgow[index].value = sanitizedValue;
        },

        nextPage: function () {
            this.pos = (this.pos == this.data.length - 1) ? this.pos : this.pos + 1
            this.sendSheet = this.data[this.pos];
        },

        backPage: function () {
            this.pos = (this.pos == 0) ? this.pos : this.pos - 1
            this.sendSheet = this.data[this.pos];

        },
        //Dropdown
        toggleDropdown(index) {
            this.titleDropdown[index].state = !this.titleDropdown[index].state;
        },
        beforeEnter(el) {
            el.style.height = 0
        },
        enter(el) {
            el.style.height = el.scrollHeight + 'px'
        },
        beforeLeave(el) {
            el.style.height = el.scrollHeight + 'px'
        },
        leave(el) {
            el.style.height = 0
        },
        mtdtypepatient: function () {
            this.post({
                    url: this.$store.getters.get__url + "/Uciadulto/salePatient",
                    token: this.$store.getters.get__token,
                    params: {
                        medical_act_id: this.medical_act_id
                    },
                })
                .then((response) => {
                    //this.sale = response.data;
                    this.type_patient = response.data.type_patient;
                    this.date_in = response.data.date_ind;
                    this.date_out = response.data.date_out;
                    this.insurance_id = response.data.insurance ? response.data.insurance.id : null;
                    this.insurance_name = response.data.insurance ? response.data.insurance.name : '';
                    this.patient_name = response.data.patient.name;
                })
                .catch((errors) => {
                    this.$refs.SweetAlert.showError(errors);
                });
        },
        mtdaddsheet: function () {
            this.graphic_sheets.medical_act_id = this.medical_act_id;
            this.graphic_sheets.values[0].value2 = this.graphic_sheets.values[1].value;
            this.mtdCompletedOx();
            this.valuesGlasgow.push({
                id: this.valuesGlasgow.length + 1,
                label: 'Total',
                value: this.cpTotalGlasgow,
                value2: '',
                new: false,

            });
            let oxigenoRegister = {
                type_patient: this.type_patient,
                insurance_id: this.insurance_id,
                medical_act_id: this.medical_act_id,
                liter: this.liter,
                device: this.device,
                fio2: this.fio2,
                minutes: this.minutes,
                time: this.time,
            };
            this.post({
                    url: this.$store.getters.get__url + "/Uciadulto/storeSheet",
                    token: this.$store.getters.get__token,
                    params: {
                        ...this.graphic_sheets,
                        valuesOthers: this.valuesOthers,
                        valuesGlasgow: this.valuesGlasgow,
                        valuesVasoActivo: this.valuesVasoActivo,
                        valuesSedacion: this.valuesSedacion,
                        valuesOx: this.valuesOx,
                        valuesmonitoreo: this.valuesmonitoreo,
                        oxigeno: oxigenoRegister,
                    },
                })
                .then((response) => {
                    this.$refs.SweetAlert.showSuccess(response.message);
                    this.action = 'show';
                    this.mtdvaciar();
                    this.mtgetsheet();

                })
                .catch((errors) => {
                    this.$refs.SweetAlert.showError(errors);
                });
        },
        mtdCompletedOx() {
            this.valuesOx = [{
                    id: 1,
                    label: 'Dispositivos',
                    value: this.device,
                },
                {
                    id: 2,
                    label: 'Litraje',
                    value: this.liter,
                },
                {
                    id: 3,
                    label: 'FIO2',
                    value: this.fio2,
                },
                {
                    id: 4,
                    label: 'TIPO',
                    value: this.time,
                },
                {
                    id: 5,
                    label: 'TIEMPO',
                    value: this.minutes,
                }
            ];
        },
        mtdadd: function () {
            this.$refs.SweetAlert.horario();
        },
        mtdhorario: function (hour, payload) {
            this.graphic_sheets.hour = hour
            this.mtdaddsheet()
        },
        mtdvaciar: function () {
            this.liter = '',
                this.device = '',
                this.fio2 = '',
                this.time = 'minute',
                this.minutes = '',
                this.graphic_sheets = {
                    date_name: 'GRÁFICA ACTUAL',
                    id: null,
                    medical_act_id: null,
                    date: null,
                    hour: null,
                    day: 0,
                    doctor_name: '',
                    user_id: null,
                    day_po: '',
                    weight: '',
                    deposition: '',
                    urine: '',
                    hydric_balance: '',
                    values: [{
                            id: 1,
                            label: 'PAS',
                            value: '',
                            value2: '',
                            inputs: 2
                        },
                        {
                            id: 2,
                            label: 'PAD',
                            value: '',
                            inputs: 1
                        },
                        {
                            id: 3,
                            label: 'Pulso',
                            value: '',
                            inputs: 1
                        },
                        {
                            id: 4,
                            label: 'FR',
                            value: '',
                            inputs: 1
                        },
                        {
                            id: 5,
                            label: 'Temp',
                            value: '',
                            inputs: 1
                        },
                        {
                            id: 6,
                            label: 'S02',
                            value: '',
                            inputs: 1
                        },
                        {
                            id: 7,
                            label: 'C02',
                            value: '',
                            inputs: 1
                        }
                    ],
                }
        },
    },
    filters: {
        formatDate(date) {
            return date != null ? moment(date).format("DD/MM/YYYY") : "-";
        },
        formatTime(time) {
            let arTime = time.split(":");
            if (arTime[0] <= 12) {
                return time + " am";
            } else {
                return arTime[0] - 12 + ":" + arTime[1] + " pm";
            }
        },
    },
}
</script>
