<template>
<CModal @mtdclosemodal="mtdclosemodal" :show="boo_modal" :close-on-backdrop="false" :centered="true" :title="title" :size="'xl'" :btn_cancel="true" :btn_ok="true" :booTitile="true">
    <div class="row d-flex mx-0 px-2">
        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
            <div class="input-group input-group-sm mb-3">
                <span class="input-group-text col-md-4">PACIENTE</span>
                <input type="text" aria-label="date" class="form-control form-control-sm" required disabled v-model="data_detail.patient" />
            </div>
        </div>
        <div class="col-lg-2 col-md-4 col-sm-12 col-xs-12">
            <div class="input-group input-group-sm mb-3">
                <span class="input-group-text col-md-4">DNI</span>
                <input type="text" aria-label="date" class="form-control form-control-sm" required disabled v-model="data_detail.document" />
            </div>
        </div>
        <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12">
            <div class="input-group input-group-sm mb-3">
                <span class="input-group-text col-xl-4 col-md-5">EDAD</span>
                <input type="text" aria-label="date" class="form-control form-control-sm" v-model="data_detail.year" />
            </div>
        </div>
        <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12">
            <div class="input-group input-group-sm mb-3">
                <span class="input-group-text col-4" id="basic-addon1">Sexo</span>
                <select selected class="form-control form-control-sm pb-0 pt-0" v-model="data_detail.sex_type">
                    <option selected disabled :value=null>Seleccione..</option>
                    <option value="1">Femenino</option>
                    <option value="2">Masculino</option>
                </select>
            </div>
        </div>
    </div>
    <div class="row d-flex mx-0 px-2">
        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
            <div class="row p-2">
                <div class="col-12 px-3">
                    <div class="row d-flex card-filter">
                        <div class="col-lg-3 col-md-3 col-sm-12 bg-secondary-subtle">
                            <span>PRIORIDAD</span>
                        </div>
                        <div class="col-lg-9 col-md-9 col-sm-12 d-flex align-items-center">
                            <div class="form-check form-check-inline" v-for="(item, index) in priority">
                                <input class="form-check-input" type="radio" name="inlineRadioOptions" :id="'inlineCheckbox'+index" :value="item" v-model="data_detail.priority" />
                                <label class="form-check-label" :for="'inlineCheckbox'+index">{{ item }}</label>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
            <div class="input-group mb-3">
                <span class="input-group-text col-md-4">MOTIVO</span>
                <textarea class="form-control" aria-label="With textarea" rows="2" v-model="data_detail.motive"></textarea>
            </div>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="input-group input-group-sm mb-3">
                <span class="input-group-text col-lg-2 col-md-4">DIAGNOSTICO</span>
                <v-select class="form-control form-control-sm" :options="dataCies" v-model="data_detail.cie" :reduce="(cie) => cie" label="name" placeholder="- Buscar Cie -" required :clearable="false" @search="mtdgetCies" @input="mtdaddDiagnosticoUnit">
                    <template slot="no-options">
                        No hay diagnosticos para la busqueda.
                    </template>
                </v-select>
                <input type="text" disabled aria-label="date" class="form-control form-control-sm col-md-2" v-model="data_detail.cie_code" />
            </div>
        </div>
        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
            <div class="input-group input-group-sm mb-3">
                <span class="input-group-text col-md-4">DOCTOR</span>
                <v-select class="flex-grow-1" :options="dataDoctors" v-model="data_detail.doctor_name" :reduce="(doctor) => doctor" label="name" placeholder="- Buscar Doctor -" required :clearable="false" @search="mtdgetDoctors" @input="mtdaddDoctor">
                    <template slot="no-options">
                        No hay doctor para la busqueda
                    </template>
                </v-select>
            </div>
        </div>
        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
            <div class="input-group input-group-sm mb-3">
                <span class="input-group-text col-md-4">SALIDA</span>
                <input type="date" aria-label="date" class="form-control form-control-sm" v-model="data_detail.date_end" />
                <input type="time" aria-label="date" class="form-control form-control-sm" v-model="data_detail.hour_end" />
            </div>
        </div>
    </div>
    <div class="row mx-0 px-2">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <label>DESTINO</label>
            <div class="row d-flex justify-content-center my-2 px-2">
                <div class="col-md-4 col-lg-2 col-sm-6 mt-2" v-for="(item, index) in destino" :key="index">
                    <button class="w-100 h-100" :class="index !== pos ? 'button-destino' : 'button-destino-inverse'" @click="activarTipo(index,item)">
                        <div class="py-1">{{ item }}</div>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <template #footer>
        <button @click="mtdclosemodal" color="danger" class="btn btn-danger btn-sm">
            <i class="fa fa-times" />&nbsp;Cerrar
        </button>
        &nbsp;
        <button @click="mtdEditData" color="danger" class="btn btn-success btn-sm">
            <i class="fas fa-save" />&nbsp;Guardar
        </button>
        &nbsp;
    </template>
    <SweetAlert :swal="swal" ref="SweetAlert" />
</CModal>
</template>

<script>
import CModal from "@/components/shared/modals/CModal.vue";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import {
    mapActions
} from "vuex";

export default {
    name: "c-asistencial-hospitalizacion-modals-cModaldestino",
    components: {
        CModal,
        SweetAlert
    },
    data() {
        return {
            data_detail: {
                id: "",
                patient: "",
                year: "",
                priority: "",
                motive: "",
                cie_id: "",
                cie_code: "",
                cie: "",
                date_end: "",
                hour_end: "",
                destination: "",
                doctor_name: "",
                doctor_id: "",
                sex_type: "",
            },
            swal: null,
            priority: ['I', 'II', 'III', 'IV'],
            destino: ['UCI', 'Hospitalizacion', 'Traslado', 'Alta', 'Fallecimiento'],
            pos: -1,
            dataCies: [],
            dataDoctors: [],

        };
    },
    props: {
        title: String,
        boo_modal: Boolean,
        payload: Object,
    },
    watch: {
        payload: {
            handler(newVal) {
                if (newVal) {
                    this.data_detail = {
                        ...newVal
                    };
                }
            },
            immediate: true,
        },
    },
    methods: {
        ...mapActions(["get", "post"]),
        mtdclosemodal(opt, payload) {
            this.pos = -1;
            this.dataCies = [];
            this.dataDoctors = [];
            this.$emit('mtdclosemodal', opt);
            
            if (opt == 1) {
                this.$emit('openModelTraslate', payload);
            }
        },
        mtdgetCies: function (search, loading) {
            if (search.length > 4) {
                this.dataCies = [];
                loading(true);
                this.get({
                        url: this.$store.getters.get__url + "/Consultation/cies/" + search,
                        token: this.$store.getters.get__token,
                    })
                    .then((response) => {
                        if (response.statusCode === 200) {
                            this.dataCies = response.data;

                        }
                        loading(false);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        },
        mtdgetDoctors: function (search, loading) {
            if (search.length > 4) {
                this.dataDoctors = [];
                loading(true);
                this.get({
                        url: this.$store.getters.get__url + "/Doctor/search/" + search,
                        token: this.$store.getters.get__token,
                    })
                    .then((response) => {
                        if (response.statusCode === 200) {
                            this.dataDoctors = response.data;

                        }
                        loading(false);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        },
        mtdaddDiagnosticoUnit: function (diagnostico) {
            this.data_detail.name = diagnostico.name;
            this.data_detail.cie_id = diagnostico.id;
            this.data_detail.cie_code = diagnostico.code;
        },
        activarTipo(index, item) {
            this.pos = index;
            this.data_detail.destination = item;
        },
        mtdaddDoctor(doctor) {
            this.data_detail.doctor_id = doctor.id;
        },
        mtdEditData() {
            //guardar los nuevos registros 
            this.post({
                    url: this.$store.getters.get__url + "/control/emergencyBook/update",
                    token: this.$store.getters.get__token,
                    params: this.data_detail,
                })
                .then((response) => {
                    if (response.statusCode === 200) {
                        this.$refs.SweetAlert.showSuccess(response.message);
                        let openModel = this.data_detail.destination == 'Traslado' ? 1 : 0;
                        let payload = {
                            ...this.data_detail,
                            ...response.data,
                            service_destination : "",
                            centro_destination: "",
                            summary_hc: [],
                        };
                        this.mtdclosemodal(openModel, payload);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    },
};
</script>
