<template>
<CModal @mtdclosemodal="mtdclosemodal" :show="boo_modal" :close-on-backdrop="false" :centered="true" :title="title" :size="'xl'" :btn_cancel="true" :btn_ok="true" :booTitile="true">
    <div class="row d-flex mx-0 px-2">
        <div class=" col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="input-group input-group-sm mb-3">
                <span class="input-group-text col-md-4">PACIENTE</span>
                <input type="text" aria-label="date" class="form-control form-control-sm" required disabled v-model="data_detail.patient" />
            </div>
        </div>
        <div class="col-xl-6 col-lg-3 col-md-8 col-sm-8 col-xs-12">
            <div class="input-group input-group-sm mb-3">
                <span class="input-group-text col-md-4">DNI</span>
                <input type="text" aria-label="date" class="form-control form-control-sm" required disabled v-model="data_detail.document" />
            </div>
        </div>
        <div class="col-xl-2 col-lg-3 col-md-4 col-sm-4 col-xs-6">
            <div class="input-group input-group-sm mb-3">
                <span class="input-group-text col-xl-4 col-md-5">EDAD</span>
                <input type="text" aria-label="date" class="form-control form-control-sm" v-model="data_detail.year" />
            </div>
        </div>
        <div class="col-xl-2 col-lg-3 col-md-8 col-sm-6 col-xs-6">
            <div class="input-group input-group-sm mb-3">
                <span class="input-group-text col-4" id="basic-addon1">Sexo</span>
                <select selected class="form-control form-control-sm pb-0 pt-0" v-model="data_detail.sex_type">
                    <option selected disabled :value=null>Seleccione..</option>
                    <option value="1">Femenino</option>
                    <option value="2">Masculino</option>
                </select>
            </div>
        </div>
        <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-xs-6">
            <div class="input-group input-group-sm mb-3">
                <span class="input-group-text col-md-5 col-xl-3" id="basic-addon1">Cama</span>
                <select selected class="form-control form-control-sm pb-0 pt-0" v-model="data_detail.bed_id">
                    <option selected disabled :value=null>Seleccione..</option>
                    <option v-for="(bed, index) in dataBeds" :key="index" :value="bed.id">{{ bed.name }}</option>
                </select>
            </div>
        </div>

    </div>
    <div class="row d-flex mx-0 px-2">
        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
            <div class="input-group input-group-sm mb-3">
                <span class="input-group-text col-md-4">DOCTOR</span>
                <v-select class="flex-grow-1" :options="dataDoctors" v-model="data_detail.doctor_name" :reduce="(cie) => cie" label="name" placeholder="- Buscar Doctor -" required :clearable="false" @search="mtdgetDoctors" @input="mtdaddDoctor">
                    <template slot="no-options">
                        No hay doctor para la busqueda
                    </template>
                </v-select>
            </div>
        </div>
        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
            <div class="input-group input-group-sm mb-3">
                <span class="input-group-text col-md-4">DIRECCION</span>
                <input type="text" aria-label="date" class="form-control form-control-sm" v-model="data_detail.direccion" />
            </div>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="input-group input-group-sm mb-3">
                <span class="input-group-text">DIAGNOSTICO</span>
                <v-select class="form-control form-control-sm" :options="dataCies" v-model="data_detail.cie" :reduce="(cie) => cie" label="name" placeholder="- Buscar Cie -" required :clearable="false" @search="mtdgetCies" @input="mtdaddDiagnosticoUnit">
                    <template slot="no-options">
                        No hay diagnosticos para la busqueda.
                    </template>
                </v-select>
                <input type="text" disabled aria-label="date" class="form-control form-control-sm" v-model="data_detail.cie_code" />
            </div>
        </div>

        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
            <div class="input-group input-group-sm mb-3">
                <span class="input-group-text col-md-4">SALIDA</span>
                <input type="date" aria-label="date" class="form-control form-control-sm" v-model="data_detail.date_end" @change="mtdCalculateDays" />
                <input type="time" aria-label="date" class="form-control form-control-sm" v-model="data_detail.hour_end" />
            </div>
        </div>
        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
            <div class="input-group input-group-sm mb-3">
                <span class="input-group-text col-md-6">DIAS HOSPITALIZADOS</span>
                <input type="text" aria-label="date" class="form-control form-control-sm" disabled v-model="data_detail.days_hospitalized" />
            </div>
        </div>
    </div>
    <div class="row mx-0 px-2">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <label>DESTINO</label>
            <div class="row d-flex justify-content-center my-2 px-2">
                <div class="col-md-6 col-lg-3 col-sm-6 mt-2" v-for="(item, index) in destino" :key="index">
                    <button class="w-100 h-100" :class="index !== pos ? 'button-destino' : 'button-destino-inverse'" @click="activarTipo(index,item)">
                        <div class="py-1">{{ item }}</div>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <template #footer>
        <button @click="mtdclosemodal" color="danger" class="btn btn-danger btn-sm">
            <i class="fa fa-times" />&nbsp;Cerrar
        </button>
        &nbsp;
        <button @click="mtdEditData" color="danger" class="btn btn-success btn-sm">
            <i class="fas fa-save" />&nbsp;Guardar
        </button>
        &nbsp;
    </template>
    <SweetAlert :swal="swal" ref="SweetAlert" />
</CModal>
</template>

<script>
import CModal from "@/components/shared/modals/CModal.vue";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import {
    mapActions
} from "vuex";

export default {
    name: "c-asistencial-hospitalizacion-modals-cModaldestino",
    components: {
        CModal,
        SweetAlert
    },
    data() {
        return {
            data_detail: {
                id: "",
                patient: "",
                year: "",
                bead_id: "",
                clinical_history: "",
                direccion: '',
                days_hospitalized: "",
                cie_id: "",
                cie_code: "",
                cie: "",
                date_end: "",
                hour_end: "",
                date_entry: "",
                hour_entry: "",
                destination: "",
                doctor_name: "",
                doctor_id: "",
                sex_type: "",
            },
            swal: null,
            priority: ['I', 'II', 'III', 'IV'],
            destino: ['Alta', 'Traslado', 'Fallecimiento'],
            pos: -1,
            dataCies: [],
            dataDoctors: [],
            dataBeds: [],

        };
    },
    props: {
        title: String,
        boo_modal: Boolean,
        payload: Object,
        type: String,
    },
    watch: {
        payload: {
            handler(newVal) {
                if (newVal) {
                    this.data_detail = {
                        ...newVal
                    };
                }
                this.addDestination();
                this.getDataBed();
            },
            immediate: true,
        },
    },
    methods: {
        ...mapActions(["get", "post"]),
        addDestination() {
            if (this.type) {
                let destinoNew = this.type == 'hospitalizationBook' ? 'UCI' : 'Hospitalizacion';
                this.destino.unshift(destinoNew, );
            }
        },
        mtdclosemodal(opt,payload) {
            this.pos = -1;
            this.destino = ['Alta', 'Traslado', 'Fallecimiento'];
            this.dataCies = [];
            this.$emit('mtdclosemodal', opt);

            if (opt == 1) {
                this.$emit('openModelTraslate', payload);
            }
        },
        mtdgetCies: function (search, loading) {
            if (search.length > 4) {
                this.dataCies = [];
                loading(true);
                this.get({
                        url: this.$store.getters.get__url + "/Consultation/cies/" + search,
                        token: this.$store.getters.get__token,
                    })
                    .then((response) => {
                        if (response.statusCode === 200) {
                            this.dataCies = response.data;
                        }
                        loading(false);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        },
        mtdgetDoctors: function (search, loading) {
            if (search.length > 4) {
                this.dataDoctors = [];
                loading(true);
                this.get({
                        url: this.$store.getters.get__url + "/Doctor/search/" + search,
                        token: this.$store.getters.get__token,
                    })
                    .then((response) => {
                        if (response.statusCode === 200) {
                            this.dataDoctors = response.data;

                        }
                        loading(false);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        },
        mtdaddDiagnosticoUnit: function (diagnostico) {
            this.data_detail.name = diagnostico.name;
            this.data_detail.cie_id = diagnostico.id;
            this.data_detail.cie_code = diagnostico.code;
        },
        activarTipo(index, item) {
            this.pos = index;
            this.data_detail.destination = item;
        },
        mtdaddDoctor(doctor) {
            this.data_detail.doctor_id = doctor.id;
        },
        getDataBed() {
            let search = this.$store.getters.get__campus;
            this.get({
                    url: this.$store.getters.get__url + "/Bed/filterbyCampu/" + search,
                    token: this.$store.getters.get__token,
                })
                .then((response) => {
                    if (response.statusCode === 200) {
                        this.dataBeds = response.data;

                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        mtdCalculateDays() {
            let calcule = 0;
            if (!this.data_detail.date_entry || !this.data_detail.date_end) calcule = 0; // Verifica que ambas fechas existan

            // Convierte las fechas a objetos de tipo Date
            const startDate = new Date(this.data_detail.date_entry);
            const endDate = new Date(this.data_detail.date_end);

            // Calcula la diferencia en milisegundos
            const timeDiff = endDate - startDate;

            // Convierte la diferencia de milisegundos a días
            const daysDiff = timeDiff / (1000 * 60 * 60 * 24);

            calcule = Math.ceil(daysDiff);

            if (calcule < 0) {
                this.$refs.SweetAlert.showWarning('Fecha no permitida');
                this.data_detail.date_end = "";
            } else {
                this.data_detail.days_hospitalized = calcule + 1;
            }
        },
        mtdEditData() {
            //guardar los nuevos registros 
            this.post({
                    url: this.$store.getters.get__url + "/control/" + this.type + "/update",
                    token: this.$store.getters.get__token,
                    params: this.data_detail,
                })
                .then((response) => {
                    if (response.statusCode === 200) {
                        this.$refs.SweetAlert.showSuccess(response.message);
                        let openModel = this.data_detail.destination == 'Traslado' ? 1 : 0;
                        let payload = {
                            ...this.data_detail,
                            ...response.data,
                            service_destination : "",
                            centro_destination: "",
                            summary_hc: [],
                        };
                        this.mtdclosemodal(openModel, payload);
                    } else {
                        this.$refs.SweetAlert.showError(response.message);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    },
};
</script>
