<template>
<div>
    <div class="container">
        <!-- <div class="row">
            <div class=" btn-home  col-md-12 mt-3 mb-4 d-flex justify-content-end">
                <button type="button" class="btn btn-outline-main" @click="returnHome">
                    <i class="fas fa-home-lg-alt"></i> Inicio
                </button>
            </div>
        </div> -->
        <div class="w-100 mt-4">
            <div class="card w-100 text-center">
                <div class="card-header text-white bg-main h4">
                    <div style="position: relative;">
                        <div class="">
                            <strong>HISTORIAL DE VERIFICACIÓN DE STOCK</strong>
                        </div>
                        <div class="btn-home-jefatura" style="left: 0;top: 0;position: absolute;">
                            <button type="button" class="btn btn-sm"  @click="viewBack">
                                <i class="fas fa-arrow-left"></i> Atrás
                            </button>
                        </div>
                    </div>
                </div>
                <div class="card-body w-100">
                    <div class="row">
                        <div class="col-md-12 py-2">
                            <div class="row d-flex mx-0">
                                <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-6 mt-2">
                                    <div class="input-group input-group-sm">
                                        <span class="input-group-text col-lg-4 col-md-3">Desde</span>
                                        <input type="date" aria-label="shift_date" class="form-control form-control-sm" id="begindate" v-model="filter.date_start" @change="mtdGetData" />
                                    </div>
                                </div>
                                <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-6 mt-2">
                                    <div class="input-group input-group-sm">
                                        <span class="input-group-text col-lg-4 col-md-3">Hasta</span>
                                        <input type="date" aria-label="shift_date" class="form-control form-control-sm" id="enddate" v-model="filter.date_end" @change="mtdGetData" />
                                    </div>
                                </div>
                                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 mt-2">
                                    <div class="row d-flex">
                                        <div class="col-lg-12 col-md-12">
                                            <div class="input-group input-group-sm mb-2">
                                                <span class="input-group-text text-white bg-main"><i class="fas fa-search"></i></span>
                                                <input v-model="search" type="text" class="form-control form-control" placeholder="Escriba un almacen" @input="calculateData(10)"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div v-if="data.length > 0 && cpData.length > 0" class="d-flex justify-content-end  mb-2 ml-5">
                                <nav aria-label="Page navigation example ">
                                    <ul class="pagination mb-0">
                                        <li class="page-item">
                                            <a class="page-link page" href="javascript:void(0)" @click="backPage">Anterior</a>
                                        </li>
                                        <li v-if="limitedStepPagination[0] !== 1" class="page-item">
                                            <a :class="selectPage === 1 ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(1)">1</a>
                                        </li>
                                        <li v-if="limitedStepPagination[0] > 2" class="page-item disabled">
                                            <span class="page-link">...</span>
                                        </li>

                                        <li v-for="(item, index) in limitedStepPagination" :key="index" class="page-item">
                                            <a :class="selectPage === item ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(item)">{{ item }}
                                            </a>
                                        </li>
                                        <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] <stepPagination.length - 1" class="page-item disabled">
                                            <span class="page-link">...</span>
                                        </li>
                                        <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] !== stepPagination.length" class="page-item">
                                            <a :class="selectPage === limitedStepPagination.length + 2 ? 'page-link active':'page-link'" href="javascript:void(0)" @click="selectedPage(stepPagination.length)">{{ stepPagination.length }}
                                            </a>
                                        </li>
                                        <li class="page-item">
                                            <a class="page-link page" href="javascript:void(0)" @click="nextPage">Siguiente</a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                        <!--tabla-->
                        <div class="col-md-12 py-2 table-container table-responsive" id="content-timelinetable">
                            <table class="table table-borderless " style="font-size: 12px;">
                                <caption class="py-0 my-0"></caption>
                                <thead class="bg-main ">
                                    <tr class="text-white text-center tr-table text-xs">
                                        <th scope="col" class="border border-white">
                                            <div>N°</div>
                                        </th>
                                        <th scope="col" class="border border-white">
                                            <div>FECHA DE REGISTRO</div>
                                        </th>
                                        <th scope="col" class="border border-white">
                                            <div>USUARIO</div>
                                        </th>
                                        <th scope="col" class="border border-white">
                                            <div>ALMACEN</div>
                                        </th>
                                        <th scope="col" class="border border-white">
                                            <div>PDF DE EVIDENCIA</div>
                                        </th>
                                        <th scope="col" class="border border-white">
                                            <div>REPORTE</div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, index) in cpData" :key="index" class="text-center">
                                        <td class="border border-main">{{ mtdNumber(index) }}</td>
                                        <td class="border border-main">{{ item.date | formatDateTime }}</td>
                                        <td class="border border-main">{{ item.user.name }} {{ item.user.last_name }}</td>
                                        <td class="border border-main">{{ item.almacen.name }}</td>
                                        <td class="border border-main">
                                            <button class="btn btn-sm a-warning text-white" v-if="!item.file" @click="mtduploadPdf(item)">
                                                <i class="fas fa-upload"></i>
                                            </button>
                                            <button class="btn btn-sm a-warning text-white" v-if="item.file" @click="viewPdf(item.file)">
                                                <i class="fas fa-file-pdf"></i>
                                            </button>
                                        </td>
                                        <td class="border border-main">
                                            <button class="btn btn-sm a-primary text-white" @click="openPdfVeri(item.id, item.almacen_id, 'todos')">
                                                <i class="fas fa-eye"></i>
                                            </button>
                                        </td>
                                    </tr>
                                    <tr class="border border-main text-center" v-if="cpData.length == 0">
                                        <td colspan="10">No se encontraron resultados</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <cModalUpload :boo_modal="modal_upload.boo_modal" :title="modal_upload.title"  :id="modal_upload.id" @mtdcloseModal="mtdCloseUpload"/>

</div>

</template>

<script>
import {
    mapActions
} from "vuex";
import moment from "moment";
import cModalUpload from "../modals/cModalUpload.vue";

export default {
    name: "c-logistica-reportVerificacion",
    components:{
        cModalUpload
    },
    data() {
        return {
            filter: {
                date_start: "",
                date_end: "",
            },
            search: "",
            data: [],
            dataShow: [],
            /* paginacion */
            page: 0,
            selectPage: 1,
            stepPagination: [],
            totalPagesToShow: 3,
            modal_upload: {
                boo_modal: false,
                title: '',
                id: 0,
                page: "",
            }
        }
    },
    created() {
        this.getFirstDayOfMonth();
    },
    watch: {
        selectPage() {
            this.calculateData(10);
        },

    },
    computed: {
        cpData() {
            return this.dataShow;
        },
        limitedStepPagination() {
            const totalPages = this.stepPagination.length;
            const currentPage = this.selectPage;

            let startPage = 1;
            let endPage = totalPages;
            if (totalPages > this.totalPagesToShow) {
                const halfPagesToShow = Math.floor(this.totalPagesToShow / 2);

                if (currentPage > halfPagesToShow) {
                    startPage = currentPage - halfPagesToShow;
                    endPage = currentPage + halfPagesToShow - 1;

                    if (endPage > totalPages) {
                        endPage = totalPages;
                        startPage = totalPages - this.totalPagesToShow + 1;
                    }
                } else {
                    endPage = this.totalPagesToShow;
                }
            }

            return Array.from({
                    length: endPage - startPage + 1,
                },
                (_, i) => startPage + i
            );
        },
    },
    methods: {
        ...mapActions(["get", "post"]),
        getFirstDayOfMonth: function () {
            const currentDate = new Date();
            const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
            const today = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
            this.filter.date_start = firstDayOfMonth.toISOString().split('T')[0];
            this.filter.date_end = today.toISOString().split('T')[0];
            this.mtdGetData();
        },
        mtdGetData() {
            this.post({
                    url: this.$store.getters.get__url + "/Inventario/listVerificacion",
                    token: this.$store.getters.get__token,
                    params: this.filter

                })
                .then((response) => {
                    this.data = response.data;
                    this.calculateData(10);
                })
                .catch((errors) => {});
        },
        mtduploadPdf: function (item) {
            this.modal_upload = {
                boo_modal: true,
                title: 'Subir PDF de evidencia de verificacion',
                id: item.id,
            };
        },
        mtdCloseUpload: function () {
            this.modal_upload = {
                boo_modal: false,
                title: "",
            };
            this.mtdGetData();
        },
        /** view pdf*/
        viewPdf: function (ruta) {
            window.open(
                "https://clinicabm.v2.howertpi.com/assets/" + ruta,
                "_blank"
            );
        },

        openPdfVeri: function(id, alma, type){
            window.open(
                this.$store.getters.get__url +
                "/viewpdfVerStock/" + id + "/" + alma + "/" + type,
                "_blank"
            );
        },

        /** pagination */
        calculateData(items) {
            if (!this.data) {
                this.dataShow = [];
            }
            let filteredData = this.data;
            let selectPage = this.selectPage;
            if (this.search.length >= 2 && this.search != "") {
                let query = this.search.toString().toUpperCase();
                filteredData = this.data.filter((element) => {
                    let name = element.almacen.name.toString().toUpperCase();
                    //let ruc = element.provider.ruc.toString().toUpperCase();
                    //let razon_social = element.provider.name.toString().toUpperCase();
                    return name.includes(query) ;
                });
                selectPage = 1;
            }
            let totalPages = Math.ceil(filteredData.length / items);
            this.stepPagination = Array.from({
                    length: totalPages,
                },
                (_, index) => index + 1
            );
            let startIndex = (selectPage - 1) * items;
            let endIndex = startIndex + (items - 1);
            this.dataShow = filteredData.slice(startIndex, endIndex + 1);
        },
        backPage() {
            this.selectPage = this.selectPage > 1 ? this.selectPage - 1 : 1;
        },
        nextPage() {
            this.selectPage =
                this.selectPage < this.stepPagination.length ?
                this.selectPage + 1 :
                this.stepPagination.length;
        },
        selectedPage(page) {
            this.selectPage = page;
        },
        mtdNumber: function (index) {
            return (index + 1) + ((this.selectPage - 1) * 10);
        },
        returnHome: function () {
            this.$emit("returnHome");
        },
        viewBack: function () {
            this.$emit("mtdChangePage",'verificacion');
        },
    },
    filters: {
        formatDate(date) {
            return date != null ? moment(date).format("DD/MM/YYYY") : "-";
        },
        formatDateTime(dateTime) {
            return dateTime != null ? moment(dateTime).format("DD/MM/YYYY HH:mm") : "-";
        },
    },
}
</script>
