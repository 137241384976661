<template>
<CModal @mtdclosemodal="mtdClose" :show.sync="boo_modal" :close-on-backdrop="false" :centered="true" :title="title" :size="'xl'" :btn_cancel="true" :btn_ok="true" :booTitile="true">
    <div class="row d-flex">
        <div class="col-lg-12 mt-3">
            <dl class="row d-flex text-xs px-5" v-if="item.patient">
                <dt class="col-sm-3">N° HCL:</dt>
                <dd class="col-sm-3">HC-{{item.patient ? item.patient.document : ''}}</dd>
                <dd class="col-sm-3">
                    <dl class="row">
                        <dt class="col-sm-4">HORA:</dt>
                        <dd class="col-sm-8">{{item.hora }}</dd>
                    </dl>
                </dd>
                <dd class="col-sm-3 mt-0">
                    <dl class="row">
                        <dt class="col-sm-4">FECHA:</dt>
                        <dd class="col-sm-8">{{ day }}</dd>
                    </dl>
                </dd>
            </dl>
        </div>
        <div class="col-lg-12 mt-0" v-if="item.patient">
            <!-- completar los datos que faltan -->

            <div class="row d-flex px-2">
                <div class="col-lg-6 col-md-12">
                    <div class="input-group input-group-sm mb-3">
                        <span class="input-group-text col-4" id="basic-addon2">Nombre y Apellido</span>
                        <input type="text" class="form-control" disabled aria-label="Username" aria-describedby="basic-addon" v-model="item.patient.name">
                    </div>
                    <div class="input-group input-group-sm mb-3">
                        <span class="input-group-text col-4" id="basic-addon3">Fecha de Nacimiento</span>
                        <input type="date" class="form-control" aria-label="Username" aria-describedby="basic-addon" v-model="patient.birthdate" :max="fechaMinima" @change="calculateAge()">
                    </div>

                </div>
                <div class="col-md-6 col-lg-3">
                    <div class="input-group input-group-sm mb-3">
                        <span class="input-group-text col-4" id="basic-addon1">DNI</span>
                        <input type="text" class="form-control" disabled placeholder="Username" aria-label="Username" aria-describedby="basic-addon1" v-model="item.patient.document">
                    </div>
                    <div class="input-group input-group-sm mb-3">
                        <span class="input-group-text col-4" id="basic-addon1">Sexo</span>
                        <select selected class="form-control form-control-sm pb-0 pt-0" v-model="patient.sex_type">
                            <option selected disabled :value=null>Seleccione..</option>
                            <option value="1">Femenino</option>
                            <option value="2">Masculino</option>
                        </select>
                    </div>

                </div>
                <div class="col-md-6 col-lg-3">
                    <div class="input-group input-group-sm mb-3">
                        <span class="input-group-text col-4" id="basic-addon1">Edad</span>
                        <input type="text" class="form-control" placeholder="Edad" aria-label="Username" aria-describedby="basic-addon1" disabled v-model="patient.year" maxlength="3">
                    </div>
                    <div class="input-group input-group-sm mb-3">
                        <span class="input-group-text col-4" id="basic-addon1">Telefono</span>
                        <input type="text" class="form-control" placeholder="Celular" aria-label="Username" aria-describedby="basic-addon1" v-model="patient.phone" maxlength="9">
                    </div>
                </div>
            </div>
            <div class="row d-flex px-2">
                <div class="col-md-12">
                    <div class="row d-flex">
                        <div class="col-lg-6">
                            <div class="input-group input-group-sm mb-3">
                                <span class="input-group-text col-4" id="basic-addo4">Lugar de Nacimiento</span>
                                <input type="text" class="form-control" aria-label="Username" aria-describedby="basic-addon" v-model="patient.place_of_birth">
                            </div>
                            <div class="input-group input-group-sm mb-3">
                                <span class="input-group-text col-5" id="basic-addon1">DNI Familiar Responsable</span>
                                <input type="text" class="form-control" placeholder="dni" aria-label="Username" aria-describedby="basic-addon1" v-model="patient.document_tutor" @keyup="mtdSearchDocument">
                            </div>

                            <div class="input-group input-group-sm mb-3">
                                <span class="input-group-text col-5" id="basic-addon1">Parentesco</span>
                                <select selected class="form-control form-control-sm pb-0 pt-0" v-model="patient.relationship">
                                    <option selected disabled :value=null>Seleccione..</option>
                                    <option v-for="(item, index) in options" :key="index" :value="item">{{item}}</option>
                                </select>
                            </div>

                        </div>
                        <div class="col-lg-6">
                            <div class="input-group input-group-sm mb-3">
                                <span class="input-group-text col-4" id="basic-addon1">Estado Civil</span>
                                <select selected class="form-control form-control-sm pb-0 pt-0" v-model="patient.marital_status">
                                    <option selected disabled :value=null>Seleccione..</option>
                                    <option v-for="(item, index) in optionsMaritalStatus" :key="index" :value="item.value">{{item.label}}</option>
                                </select>
                            </div>
                            <div class="input-group input-group-sm mb-3">
                                <span class="input-group-text col-4" id="basic-addo4">Familiar responsable</span>
                                <input type="text" class="form-control" disabled aria-label="Username" aria-describedby="basic-addon" v-model="patient.name_tutor">
                            </div>
                            <div class="input-group mb-3">
                                <span class="input-group-text col-md-4">Antecentes</span>
                                <textarea class="form-control" aria-label="With textarea" v-model="patient.background"></textarea>
                            </div>
                        </div>
                        <!-- <div class="col-lg-6">
                            <div class="input-group">
                                <span class="input-group-text col-md-4">Enfermedades</span>
                                <textarea class="form-control" aria-label="With textarea" v-model="enfermedades"></textarea>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <template #footer>
        <!-- <button @click="mtdClose" color="danger" class="btn btn-danger btn-sm">
            <i class="fa fa-times" />&nbsp;Cerrar
        </button>
        &nbsp; -->
        <button class="btn btn-success btn-sm" @click="mtdSave" :disabled="cpDisabled">
            <i class="fas fa-save" />&nbsp; Guardar
        </button>
        &nbsp;
    </template>
    <SweetAlert :swal="swal" ref="SweetAlert" />

</CModal>
</template>

<script>
import CModal from "../../shared/modals/CModal.vue";
import CButtonClose from "../../shared/modals/cButtonClose.vue";
import {
    mapActions
} from "vuex";
import SweetAlert from "@/components/shared/SweetAlert/SweetAlert.vue";
export default {
    name: "c-cola-datosafiliacion",
    components: {
        CModal,
        CButtonClose,
        SweetAlert,
    },
    data() {
        return {
            day: new Date().toISOString().split('T')[0],
            swal: "",
            patient: {
                birthdate: "",
                marital_status: "",
                sex_type: "",
                year: "",
                phone: "",
                place_of_birth: "Chiclayo",
                document_tutor: "",
                name_tutor: "",
                tutor: "",
                relationship: "",
                background: "",
            },
            antecendes: "",
            enfermedades: "",
            options: ['MADRE', 'PADRE', 'HERMANO(A)', 'HIJO(A)', 'OTRO'],
            optionsMaritalStatus: [{
                    value: "S",
                    label: 'Soltero(a)'
                },
                {
                    value: "C",
                    label: 'Casado(a)'
                },
                {
                    value: "V",
                    label: 'viudo(a)'
                }, {
                    value: "D",
                    label: 'divorciado(a)'
                }
            ],
        };
    },
    props: {
        title: String,
        boo_modal: Boolean,
        item: Object,
    },
    watch: {
        item: {
            handler(newVal) {
                if (newVal) {
                    this.patient = {
                        ...newVal.patient
                    };
                }
            },
            immediate: true,
        },
    },
    computed: {
        fechaMinima() {
            // Obtener la fecha actual en el formato YYYY-MM-DD
            const fechaActual = new Date().toISOString().split('T')[0];
            return fechaActual;
        },
        cpDisabled() {
            return this.patient.phone == '' || !this.patient.phone || this.patient.phone.length !== 9 || this.patient.sex_type == '';
        }
    },
    methods: {
        ...mapActions(["get", "post"]),
        calculateAge() {
            const hoy = new Date();
            const nacimiento = new Date(this.patient.birthdate);
            let edad = hoy.getFullYear() - nacimiento.getFullYear();
            const mes = hoy.getMonth() - nacimiento.getMonth();

            // Ajustar si el cumpleaños no ha ocurrido aún este año
            if (mes < 0 || (mes === 0 && hoy.getDate() < nacimiento.getDate())) {
                edad--;
            }

            this.patient.year = edad;
        },
        mtdClose(pos) {
            this.$emit("mtdCloseModal", pos,this.item);
        },
        mtdSearchDocument: function () {
            if (this.patient.document_tutor.length == 8) {
                this.get({
                        url: this.$store.getters.get__url + "/reniec/" + this.patient.document_tutor,
                        token: this.$store.getters.get__token,
                    })
                    .then((response) => {

                        if (response.data.boo == 3) {
                            // this.client.document = "";
                            this.$refs.SweetAlert.showWarning("DNI no encontrado");
                            this.patient.name_tutor = "";
                        } else {
                            this.patient.name_tutor = response.data.name + " " + response.data.last_name;
                        }
                    })
                    .catch((errors) => {
                        console.log(errors);
                    });
            } else {
                this.patient.name_tutor;
            }
        },
        mtdSave() {
            this.post({
                    url: this.$store.getters.get__url + "/Admision/cola/storeDataAfiliacion",
                    token: this.$store.getters.get__token,
                    params: this.patient,
                })
                .then((response) => {
                    if (response.statusCode == 200) {
                        this.$refs.SweetAlert.showSuccess(response.message);
                        this.mtdClose(0);
                    }
                })
                .catch((errors) => {
                    this.$refs.SweetAlert.showError(errors);
                });
        },

    },
};
</script>
