<template>
  <div>    
    <nav id="nav-menu" class="fixed-bottom py-0 d-flex justify-content-around mt-2">
      <div :style="mtdGetStyle('permiso_caja')" class="navbar-brand pl-2 text-white text-center pr-2 nav-footer w-100 border-end border-white border" @click="mtdSendOption('permiso_caja')"><span :style="mtdGetStyleSpan('permiso_caja')" class="btn w-100"><strong>PERMISOS DE CAJA</strong></span></div>
      <div :style="mtdGetStyle('permiso_anulacion')" class="navbar-brand pl-2 text-white text-center pr-2 nav-footer w-100 border-end border-white border" @click="mtdSendOption('permiso_anulacion')"><span :style="mtdGetStyleSpan('permiso_anulacion')" class="btn w-100"><strong>PERMISOS DE ANULACIÓN</strong></span></div>
      <div :style="mtdGetStyle('per_caja_fuerte')" class="navbar-brand pl-2 text-white text-center pr-2 nav-footer w-100 border-end border-white border" @click="mtdSendOption('per_caja_fuerte')"><span :style="mtdGetStyleSpan('per_caja_fuerte')" class="btn w-100"><strong>AUT. CAJA FUERTE</strong></span></div>
      <div :style="mtdGetStyle('permiso_logistica')" class="navbar-brand pl-2 text-white text-center pr-2 nav-footer w-100 border-end border-white border" @click="mtdSendOption('permiso_logistica')"><span :style="mtdGetStyleSpan('permiso_logistica')" class="btn w-100"><strong>NOTAS DE INGRESO</strong></span></div>
      <div :style="mtdGetStyle('permiso_os')" class="navbar-brand pl-2 text-white text-center pr-2 nav-footer w-100 border-end border-white border" @click="mtdSendOption('permiso_os')"><span :style="mtdGetStyleSpan('permiso_os')" class="btn w-100"><strong>PERMISO OS</strong></span></div>
      <div :style="mtdGetStyle('reporte_caja')" class="navbar-brand pl-2 text-white text-center pr-2 nav-footer w-100 border-end border-white border" @click="mtdSendOption('reporte_caja')"><span :style="mtdGetStyleSpan('reporte_caja')" class="btn w-100"><strong>REPORTES DE CAJA</strong></span></div>
      <div :style="mtdGetStyle('inreso_egreso')" class="navbar-brand pl-2 text-white text-center pr-2 nav-footer w-100 border-end border-white border" @click="mtdSendOption('inreso_egreso')"><span :style="mtdGetStyleSpan('inreso_egreso')" class="btn w-100"><strong>REPORTES DE INGRESOS/EGRESOS</strong></span></div>
      <div :style="mtdGetStyle('otro_permiso')" class="navbar-brand pl-2 text-white text-center pr-2 nav-footer w-100 border-end border-white border" @click="mtdSendOption('otro_permiso')"><span :style="mtdGetStyleSpan('otro_permiso')" class="btn w-100"><strong>OTROS PERMISOS</strong></span></div>
    </nav>
  </div>
</template>

<script>
export default {
  name: 'FooterGerenciaComponent',
  props: {
    pageView: String
  },
  data () {
    return {
      page: null
    }
  },
  created () {
   
  },
  methods: {
    mtdGetStyle:function(option){
      if (option == this.page && this.pageView != "home") return 'background: #900052';
      return '';
    },
    mtdGetStyleSpan:function(option){
      if (option == this.page && this.pageView != "home") return 'color:white';
      return '';
    },
    mtdSendOption: function(page){
      this.page=page;
      this.$emit('mtdChangePage',this.page)
    }
  },
}
</script>