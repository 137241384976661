<template>
<div>
    <nav id="nav-menu" class="fixed-bottom py-0 d-flex justify-content-around mt-2">
        <div v-if="rolname != 'ENFERMERA TECNICA' && consultasCount && type == 1" :style="mtdGetStyle('consulta')" class="navbar-brand pl-2 text-white text-center pr-2 nav-footer w-100 border-end border-white border" @click="mtdSendOption('consulta')"><span :style="mtdGetStyleSpan('consulta')" class="btn w-100"><strong>CONSULTAS</strong></span></div>
        <div v-if="hospitalizacionCount" :style="mtdGetStyle('hospitalizacion')" class="navbar-brand pl-2 text-white text-center pr-2 nav-footer w-100 border-end border-white border" @click="mtdSendOption('hospitalizacion')"><span :style="mtdGetStyleSpan('hospitalizacion')" class="btn w-100"><strong>HOSPITALIZACIÓN</strong></span></div>
        <div v-if="uciadultoCount" :style="mtdGetStyle('uciadulto')" class="navbar-brand pl-2 text-white text-center pr-2 nav-footer w-100 border-end border-white border" @click="mtdSendOption('uciadulto')"><span :style="mtdGetStyleSpan('uciadulto')" class="btn w-100"><strong>UCI ADULTO</strong></span></div>
        <!-- <div v-if="ucineoCount" :style="mtdGetStyle('ucineo')" class="navbar-brand pl-2 text-white text-center pr-2 nav-footer w-100 border-end border-white border" @click="mtdSendOption('ucineo')"><span :style="mtdGetStyleSpan('ucineo')" class="btn w-100"><strong>UCI NEO</strong></span></div> -->
        <div v-if="sopCount" :style="mtdGetStyle('sop')" class="navbar-brand pl-2 text-white text-center pr-2 nav-footer w-100 border-end border-white border" @click="mtdSendOption('sop')"><span :style="mtdGetStyleSpan('sop')" class="btn w-100"><strong>SOP Y URPA</strong></span></div>
        <div v-if="emergenciaCount" :style="mtdGetStyle('emergencia')" class="navbar-brand pl-2 text-white text-center pr-2 nav-footer w-100 border-end border-white border" @click="mtdSendOption('emergencia')"><span :style="mtdGetStyleSpan('emergencia')" class="btn w-100"><strong>EMERGENCIA TRAUMASHOCK</strong></span></div>
        <div v-if="rolname != 'ENFERMERA TECNICA' && interconsultaCount" :style="mtdGetStyle('interconsulta')" class="navbar-brand pl-2 text-white text-center pr-2 nav-footer w-100 border-end border-white border" @click="mtdSendOption('interconsulta')"><span :style="mtdGetStyleSpan('interconsulta')" class="btn w-100"><strong>INTERCONSULTA</strong></span></div>
        <div v-if="rolname != 'ENFERMERA TECNICA' && laboratorioCount" :style="mtdGetStyle('laboratorio')" class="navbar-brand pl-2 text-white text-center pr-2 nav-footer w-100 border-end border-white border" @click="mtdSendOption('laboratorio')"><span :style="mtdGetStyleSpan('laboratorio')" class="btn w-100"><strong>LABORATORIO</strong></span></div>
        <div v-if="rolname != 'ENFERMERA TECNICA' && imagenCount" :style="mtdGetStyle('imagen')" class="navbar-brand pl-2 text-white text-center pr-2 nav-footer w-100 border-end border-white border" @click="mtdSendOption('imagen')"><span :style="mtdGetStyleSpan('imagen')" class="btn w-100"><strong>IMAGENES</strong></span></div>
        <div v-if="rolname != 'ENFERMERA TECNICA' && procedimientoCount"  :style="mtdGetStyle('procedimiento')" class="navbar-brand pl-2 text-white text-center pr-2 nav-footer w-100 border-end border-white border" @click="mtdSendOption('procedimiento')"><span :style="mtdGetStyleSpan('procedimiento')" class="btn w-100"><strong>PROCEDIMIENTO</strong></span></div>
        <div v-if="rolname != 'ENFERMERA TECNICA' && type == 1" :style="mtdGetStyle('historia')" class="navbar-brand pl-2 text-white text-center pr-2 nav-footer w-100 border-end border-white border" @click="mtdSendOption('historia')"><span :style="mtdGetStyleSpan('historia')" class="btn w-100"><strong>HISTORIAS CLINICAS</strong></span></div>
        <div  v-if="DevolucionesCount && (rolname == 'ENFERMERA(O)' || rolname == 'ENFERMERA TECNICA')" :style="mtdGetStyle('devoluciones')" class="navbar-brand pl-2 text-white text-center pr-2 nav-footer w-100 border-end border-white border" @click="mtdSendOption('devoluciones')"><span :style="mtdGetStyleSpan('devoluciones')" class="btn w-100"><strong>DEVOLUCIONES</strong></span></div>
        <div  v-if="rolname == 'ENFERMERA TECNICA'" :style="mtdGetStyle('requerimientos')" class="navbar-brand pl-2 text-white text-center pr-2 nav-footer w-100 border-end border-white border" @click="mtdSendOption('requerimientos')"><span :style="mtdGetStyleSpan('requerimientos')" class="btn w-100"><strong>REQUERIMIENTOS</strong></span></div>
        <div v-if="rolname == 'ENFERMERA(O)'" :style="mtdGetStyle('control')" class="navbar-brand pl-2 text-white text-center pr-2 nav-footer w-100 border-end border-white border" @click="mtdSendOption('control')"><span :style="mtdGetStyleSpan('control')" class="btn w-100"><strong>CONTROL DIARIO</strong></span></div>
    </nav>
</div>
</template>

<script>
import {
    mapActions
} from 'vuex'
import Interconsulta from './Consulta/Historia/Interconsulta.vue';
import Devoluciones from '@/components/Asistencial/Devoluciones.vue';
export default {
    name: 'FooterComponent',
    props: {
        pageView: String,
        patient: Object,
        pagefinish:{
            type:String,
            default:''
        },
        rolname: String,
        type : Number,
    },

    data() {
        return {
            page: null,
            consultasCount: true,
            hospitalizacionCount: true,
            uciadultoCount: true,
            /* ucineoCount: true, */
            sopCount: true,
            emergenciaCount: true,
            interconsultaCount :false,
            laboratorioCount: false,
            imagenCount: false,
            procedimientoCount: false,
            DevolucionesCount: false,

        }
    },
    watch: {
        patient: {
            handler: function (newValue) {
                if (newValue && Object.keys(newValue).length > 0) {
                    this.mtdfooter(newValue)
                } else{
                    this.mtdooterOriginal()
                }
            },
            deep: true,
            immediate: true,
        },

        pagefinish:{
            handler: function (newValue) {
                if( newValue !=''){
                    this.page = newValue
                    this.mtdGetStyle(newValue)
                    this.mtdGetStyleSpan(newValue)
                    this.mtdback();
                }
            },
            deep: true,
            immediate: true,
        },
        
    },
    methods: {
        ...mapActions(['get', 'post']),
        mtdGetStyle: function (option) {
            if (option == this.page && this.pageView != "home") return 'background: #900052';
            return '';
        },
        mtdGetStyleSpan: function (option) {
            if (option == this.page && this.pageView != "home") return 'color:white';
            return '';
        },
        mtdSendOption: function (page) {
            this.page = page;
            if (page == 'laboratorio' || page == 'imagen' || page == 'procedimiento' || page == 'interconsulta') {
                if (Object.keys(this.patient).length !== 0) {
                    this.$emit('mtdChangePage', this.page)
                }
            } else {
                this.$emit('mtdChangePage', this.page)
            }
        },

        mtdfooter: function (payload) {
            this.post({
                    url: this.$store.getters.get__url + "/Hospitalization/footer",
                    token: this.$store.getters.get__token,
                    params: {
                        id: payload.medical_act_id
                    },
                })
                .then((response) => {

                    this.consultasCount = (response.data.consultasCount == 0) ? false : true,
                    this.hospitalizacionCount = (response.data.hospitalizacionCount == 0) ? false : true,
                    this.uciadultoCount = (response.data.UciAdulCount == 0) ? false : true,
                    /* this.ucineoCount = (response.data.UciNeoCount == 0) ? false : true, */
                    this.sopCount = (response.data.SopCount == 0) ? false : true,
                    this.emergenciaCount = (response.data.EmegenciaCount == 0) ? false : true,
                    this.interconsultaCount  =  true,
                    this.laboratorioCount =  true,
                    this.imagenCount =  true,
                    this.procedimientoCount =  true
                    /* this.DevolucionesCount = (response.data.hospitalizacionCount == 0 && response.data.UciAdulCount == 0 && response.data.UciNeoCount == 0 && response.data.SopCount == 0 && response.data.EmegenciaCount ==0) ?  false : true; */
                    this.DevolucionesCount = (response.data.hospitalizacionCount == 0 && response.data.UciAdulCount == 0 && response.data.SopCount == 0 && response.data.EmegenciaCount ==0) ?  false : true;

                })
                .catch((errors) => {
                    console.log(errors);
                });
        },
        
        mtdooterOriginal : function (){
          this.consultasCount= true,
            this.hospitalizacionCount= true,
            this.uciadultoCount= true,
            /* this.ucineoCount= true, */
            this.sopCount= true,
            this.emergenciaCount= true,
            this.interconsultaCount =false,
            this.laboratorioCount= false,
            this.imagenCount= false,
            this.procedimientoCount= false,
            this.DevolucionesCount = false
        },
        mtdback:function(){
            this.$emit('mtdback')
        }
    },
}
</script>
