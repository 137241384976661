<template>
<CModal @mtdclosemodal="mtdclosemodal" :show="boo_modal" :close-on-backdrop="false" :centered="false" :title="title" :size="'lg'" :btn_cancel="true" :btn_ok="true" :booTitile="true">
    <div class="row px-4">
        <div class="col-xl-6 col-md-4 col-xs-12 py-1">
            <div id="div-fio2" class="form-group row align-left">
                <label for="fio2" class="col-xl-5 color-main fw-bold">FECHA INICIO</label>
                <div class="col-xl-7">
                    <input type="date" v-model="date_start" class="form-control form-control-sm" :readonly="isDateStartReadOnly">
                </div>
            </div>
        </div>
        <div class="col-xl-4 col-md-4 col-xs-12 py-1 ">
            <div v-if="type_kardex =='transfusiones_sanguineas'" class="d-flex">
                <button @click="triggerFileInput(0)" class="btn btn-sm btn-warning text-white me-1 py-0 ">
                    <i class="fas fa-upload text-dark"></i>
                </button>
                <input v-model="filename" type="text" class="form-control form-control-sm  " placeholder="Nombre del pdf" autocomplete="off" readonly>
                <input type="file" @change="handleFileUpload($event,0)" :ref="'fileInput'+ 0" style="display : none">
            </div>
            <div v-if="type_kardex =='hemodialisis'" class="d-flex">
                <button @click="triggerFileInput(1)" class="btn btn-sm btn-warning text-white me-1 py-0 ">
                    <i class="fas fa-upload text-dark"></i>
                </button>
                <input v-model="filename" type="text" class="form-control form-control-sm  " placeholder="Nombre del pdf" autocomplete="off" readonly>
                <input type="file" @change="handleFileUpload($event,1)" :ref="'fileInput'+ 1" style="display : none">
            </div>

        </div>
        <div class="col-xl-2 col-md-4 col-xs-12 pt-xl-1 pt-md-3 align-center">
            <button type="button" class="btn btn-success btn-sm text-white" @click="mtdprocinvasivonew" :disabled="disabled"><i class="fas fa-save"></i> Guardar</button>
        </div>
        <div class="col-xl-12 col-md-4 col-xs-12 py-1">
            <label for="fio2" class=" color-main fw-bold mb-2">DETALLE:</label>
            <textarea v-model="detalle" class="form-control text-content" rows="5"></textarea>
        </div>
    </div>

    <SweetAlert :swal="swal" ref="SweetAlert" />
</CModal>
</template>

<script>
import CModal from "@/components/shared/modals/CModal.vue";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import {
    mapActions
} from "vuex";

export default {
    name: "c-medico-modal-proc-invasivo",

    data() {
        return {
            swal: null,
            validation: false,
            sale: [],
            last_kardex: [],
            date_start: '',
            isDateStartReadOnly: false,
            createNew: false,
            detalle: '',
            filename: '',
        }
    },
    components: {
        CModal,
        SweetAlert
    },
    props: {
        title: String,
        boo_modal: Boolean,
        id_medical_new: {
            type: Number,
            default: 0
        },
        patient_id: {
            type: Number,
            default: 0
        },
        type_kardex: {
            type: String,
            default: ''
        }
    },
    computed: {
        disabled: function () {
            return !this.date_start
        }
    },
    created() {
        this.date_start = null;

    },
    methods: {
        ...mapActions(["get", "post"]),
        mtdclosemodal() {
            this.date_start = null; // Restablecer date_start a null al cerrar el modal
            this.date_end = null;
            this.detalle = ''
            this.filelaboratorio = ''
            this.filename = '' // Restablecer date_start a null al cerrar el modal
            this.$emit('mtdclosemodalprocinvasivo2');
        },
        mtdprocinvasivonew: function () {
            this.post({
                url: this.$store.getters.get__url + "/Uciadulto/storeProcInvasivo",
                token: this.$store.getters.get__token,
                params: {
                    medical_act_id: this.id_medical_new,
                    date_start: this.date_start,
                    date_end: this.date_end,
                    type_kardex: this.type_kardex,
                    detalle: this.detalle
                }
            }).then((response) => {
                if (response.statusCode === 200) {
                    if (response.data != []) {
                        let data = response.data
                        if (this.filelaboratorio != '') {
                            this.mtdaddfile(data.id)
                        } else {
                            this.$refs.SweetAlert.showSuccess(response.message);
                            this.mtdclosemodal();
                        }
                        
                    } else {
                        this.$refs.SweetAlert.showWarning(response.message);
                    }
                }
            });
        },

        formatDate(date) {
            return date != null ? moment(date).format("DD/MM/YYYY") : "-";
        },
        triggerFileInput(index) {
            this.$refs['fileInput' + index].click();
        },
        handleFileUpload(event, index) {
            let file = event.target.files[0];
            let allowedExtensions = /\.(png|jpg|jpeg|pdf)$/i;

            if (!allowedExtensions.test(file.name)) {
                this.filelaboratorio = ''
                this.filename = ''
                let fileType = "imagen o PDF";
                Swal.fire({
                    text: `Seleccione un archivo formato ${fileType}`,
                    icon: "info",
                    confirmButtonColor: "#900052",
                }).then((result) => {
                    if (result.isConfirmed) {
                        const input = this.$refs['fileInput' + index];
                        input.type = "text";
                        input.type = "file";

                    }
                });
            } else {
                this.filelaboratorio = file;
                this.filename = this.filelaboratorio.name
            }

        },
        mtdaddfile: function (id) {
            let formData = new FormData();
            formData.append("id", id);
            formData.append("file", this.filelaboratorio);

            this.post({
                url: this.$store.getters.get__url + "/Uciadulto/addfileProInvasivos",
                token: this.$store.getters.get__token,
                params: formData,
            }).then((response) => {
                if (response.statusCode === 200) {
                    this.$refs.SweetAlert.showSuccess(response.message);
                    this.mtdclosemodal();
                }
            });
        },
    },
}
</script>

<style scoped>

</style>
