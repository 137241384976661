<template>
    <CModal @mtdclosemodal="mtdClose" :show="boo_modal" :close-on-backdrop="false" :centered="false" :title="title"
        :size="'xl'" :btn_cancel="true" :btn_ok="true" :booTitile="true">
        <!-- <div class="modal-body">
            <pre>{{ data_detail }}</pre> 
        </div> -->
        <div class="px-3">
            <div class="row mx-0 px-0 d-flex">
                
                <div class="col-lg-4 col-md-6">
                    <label for="codPersonal" class="mb-1 mt-3">COD. UNIC. PERSONAL</label>
                    <div class="input-group input-group-sm">
                        <div class="input-group-text">
                            <span style="font-size: 20px; border: none" class="input-group-text">
                                <i class="fa fa-address-book"></i>
                            </span>
                        </div>
                        <input type="text" class="form-control" id="codPersonal" placeholder="Digite el código único"
                            v-model="data_detail.cod_uni_employee" />
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <label for="documentType" class="mb-1 mt-3">TIPO DE DOCUMENTO</label>
                    <div class="input-group input-group-sm">
                        <div class="input-group-text">
                            <span style="font-size: 20px; border: none" class="input-group-text">
                                <i class="fa fa-id-card"></i>
                            </span>
                        </div>
                        <select class="form-control" id="documentType" v-model="data_detail.document_type"
                            @change="onDocumentTypeChange">
                            <option value="" disabled>Selecciona un documento...</option>
                            <option v-for="documento in documentos" :key="documento.value" :value="documento.value">
                                {{ documento.label }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <label for="documentNumber" class="mb-1 mt-3">NUM. DE DOCUMENTO</label>
                    <div class="input-group input-group-sm">
                        <div class="input-group-text">
                            <span style="font-size: 20px; border: none" class="input-group-text">
                                <i class="fa fa-id-card"></i>
                            </span>
                        </div>
                        <input type="text" class="form-control" id="documentNumber" placeholder="Número de documento"
                            v-model="data_detail.num_document" :disabled="!isDocumentTypeSelected"
                            @input="validateDocumentNumber" />
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <label for="name" class="mb-1 mt-3">NOMBRES</label>
                    <div class="input-group input-group-sm">
                        <div class="input-group-text">
                            <span style="font-size: 20px; border: none" class="input-group-text">
                                <i class="fa fa-user"></i>
                            </span>
                        </div>
                        <input type="text" class="form-control" id="name" placeholder="Nombres"
                            v-model="data_detail.name" @keydown="filterKeyLetter" />
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <label for="lastName" class="mb-1 mt-3">APELLIDOS</label>
                    <div class="input-group input-group-sm">
                        <div class="input-group-text">
                            <span style="font-size: 20px; border: none" class="input-group-text">
                                <i class="fa fa-user"></i>
                            </span>
                        </div>
                        <input type="text" class="form-control" id="lastName" @keydown="filterKeyLetter"
                            placeholder="Apellidos" v-model="data_detail.last_name" />
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <label for="position" class="mb-1 mt-3">CARGO</label>
                    <div class="input-group input-group-sm">
                        <div class="input-group-text">
                            <span style="font-size: 20px; border: none" class="input-group-text">
                                <i class="fa fa-address-book"></i>
                            </span>
                        </div>
                        <select class="form-control" id="position" v-model="data_detail.position_id">
                            <option value="" disabled>Seleccione un turno...</option>
                            <option v-for="(item, index) in positionList" :key="index" :value="item.id">
                                {{ item.name }}
                            </option>
                        </select>
                    </div>
                </div>
                <!-- <div class="col-lg-4 col-md-6">
                    <label for="dependencia" class="mb-1 mt-3">FEC. NACIMIENTO</label>
                    <div class="input-group input-group-sm">
                        <div class="input-group-text">
                            <span style="font-size: 20px; border: none" class="input-group-text">
                                <i class="fa fa-money-check"></i>
                            </span>
                        </div>
                        <input type="text" class="form-control" id="dependencia" placeholder="--/--/----"
                            v-model="data_detail.birthday" />
                    </div>
                </div> -->
                <div class="col-lg-4 col-md-6">
                    <label for="birthday" class="mb-1 mt-3">FEC. NACIMIENTO</label>
                    <div class="input-group">
                        <div class="input-group-text">
                            <span style="font-size: 20px; border: none" class="input-group-text"><i class="fas fa-calendar"></i></span>
                        </div>
                        <input v-model="data_detail.birthday" type="date" class="form-control" id="birthday" />
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <label for="dependencia" class="mb-1 mt-3">DOMICILIO</label>
                    <div class="input-group input-group-sm">
                        <div class="input-group-text">
                            <span style="font-size: 20px; border: none" class="input-group-text">
                                <i class="fas fa-map-marker"></i>
                            </span>
                        </div>
                        <input type="text" class="form-control" id="dependencia" placeholder="Domicilio"
                            v-model="data_detail.address" />
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <label for="dependencia" class="mb-1 mt-3">TELÉFONO 1</label>
                    <div class="input-group input-group-sm">
                        <div class="input-group-text">
                            <span style="font-size: 20px; border: none" class="input-group-text">
                                <i class="fa fa-phone"></i>
                            </span>
                        </div>
                        <input type="text" class="form-control" id="dependencia" placeholder="Número Principal"
                            v-model="data_detail.phone" @keydown="filterKeyDni" maxlength="9" />
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <label for="dependencia" class="mb-1 mt-3">TELÉFONO 2</label>
                    <div class="input-group input-group-sm">
                        <div class="input-group-text">
                            <span style="font-size: 20px; border: none" class="input-group-text">
                                <i class="fa fa-phone"></i>
                            </span>
                        </div>
                        <input type="text" class="form-control" id="dependencia" placeholder="Núnmero Opcional"
                            v-model="data_detail.other_phone" @keydown="filterKeyDni" maxlength="9" />
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <label for="dependencia" class="mb-1 mt-3">DEPENDENCIA</label>
                    <div class="input-group input-group-sm">
                        <div class="input-group-text">
                            <span style="font-size: 20px; border: none" class="input-group-text">
                                <i class="fa fa-address-book"></i>
                            </span>
                        </div>
                        <input type="text" class="form-control" id="dependencia" placeholder="Dependencia"
                            v-model="data_detail.dependency" @keydown="filterKeyLetter" />
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <label for="centro_costo" class="mb-1 mt-3">CENTRO DE COSTO</label>
                    <div class="input-group input-group-sm">
                        <div class="input-group-text">
                            <span style="font-size: 20px; border: none" class="input-group-text">
                                <i class="fa fa-address-book"></i>
                            </span>
                        </div>
                        <input type="text" class="form-control" id="centro_costo" placeholder="Centro de costo"
                            v-model="data_detail.cost_center" />
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <label for="clasificacion" class="mb-1 mt-3">CLASIFICACION</label>
                    <div class="input-group input-group-sm">
                        <div class="input-group-text">
                            <span style="font-size: 20px; border: none" class="input-group-text">
                                <i class="fa fa-address-book"></i>
                            </span>
                        </div>
                        <input type="text" class="form-control" id="clasificacion" placeholder="Centro de costo"
                            v-model="data_detail.classification" @keydown="filterKeyLetter" />
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <label for="base_salary" class="mb-1 mt-3">SUELDO BASE</label>
                    <div class="input-group input-group-sm">
                        <div class="input-group-text">
                            <span style="font-size: 20px; border: none" class="input-group-text">
                                <i class="fa fa-dollar-sign"></i>
                            </span>
                        </div>
                        <input type="text" class="form-control" placeholder="0.00" id="base_salary"
                            v-model="data_detail.base_salary" />
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <label for="base_salary" class="mb-1 mt-3">HORAS TRABAJADAS</label>
                    <div class="input-group input-group-sm">
                        <div class="input-group-text">
                            <span style="font-size: 20px; border: none" class="input-group-text">
                                <i class="fa fa-clock"></i>
                            </span>
                        </div>
                        <input type="text" class="form-control" placeholder="0.00" id="base_salary"
                            v-model="data_detail.hours_worked" />
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <label for="turno" class="mb-1 mt-3">TURNO</label>
                    <div class="input-group input-group-sm">
                        <div class="input-group-text">
                            <span style="font-size: 20px; border: none" class="input-group-text">
                                <i class="fa fa-calendar"></i>
                            </span>
                        </div>
                        <select class="form-control" id="turno" v-model="data_detail.turn_id">
                            <option value="" disabled>Seleccione un turno...</option>
                            <option v-for="(item, index) in TurnsList" :key="index" :value="item.id">
                                {{ item.turn }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <label for="banco" class="mb-1 mt-3">BANCO</label>
                    <div class="input-group input-group-sm">
                        <div class="input-group-text">
                            <span style="font-size: 20px; border: none" class="input-group-text">
                                <i class="fa fa-user-md"></i>
                            </span>
                        </div>
                        <select class="form-control" id="turno" v-model="data_detail.bank_id">
                            <option value="" disabled>Seleccione un turno...</option>
                            <option v-for="(item, index) in bankList" :key="index" :value="item.id">{{ item.abbreviation }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <label for="base_salary" class="mb-1 mt-3">NÚMERO DE CUENTA</label>
                    <div class="input-group input-group-sm">
                        <div class="input-group-text">
                            <span style="font-size: 20px; border: none" class="input-group-text">
                                <i class="fa fa-money-check"></i>
                            </span>
                        </div>
                        <input type="text" class="form-control" placeholder="XXX-XXXXXXXXXX-XXX" id="base_salary"
                            v-model="data_detail.account_number" />
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <label for="banco" class="mb-1 mt-3">TIPO DE CONTRATO</label>
                    <div class="input-group input-group-sm">
                        <div class="input-group-text">
                            <span style="font-size: 20px; border: none" class="input-group-text">
                                <i class="fa fa-user-md"></i>
                            </span>
                        </div>
                        <select class="form-control" id="tipo de contrato" v-model="data_detail.type_contract_id">
                            <option value="" disabled>Seleccione tipo de contrato...</option>
                            <option v-for="(item, index) in contractTypes" :key="index" :value="item.id">{{ item.name }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <label for="familyAssignment" class="mb-1 mt-2">ASIGNACIÓN FAMILIAR</label>
                    <div class="form-check form-switch mt-2">
                        <input class="form-check-input" type="checkbox" id="familyAssignment"
                            v-model="data_detail.family_allowance" />
                        <label class="form-check-label" for="familyAssignment">
                            {{ data_detail.family_allowance ? "Sí" : "No" }}
                        </label>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <label class="mb-1 mt-2">FONDO DE PENSIONES</label>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" :value="1" v-model="data_detail.pension_fund">
                        <label class="form-check-label" for="inlineRadio1">ONP</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" :value="2" v-model="data_detail.pension_fund">
                        <label class="form-check-label" for="inlineRadio2">AFP</label>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 mt-5" v-if="num === 1">
                    <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked"
                            v-model="data_detail.condition" :checked="data_detail.condition == 1 ? true : false"
                            aria-checked="false" />
                        <label class="form-check-label" for="flexSwitchCheckChecked">
                            {{ data_detail.condition == 1 ? "Activo" : "Inactivo" }}
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <template #footer>
            <button class="btn btn-success btn-sm" @click="DataDetail" :disabled="cpButton">
                {{ num == 0 ? "Registrar" : "Editar" }}
            </button>
            &nbsp;
        </template>
    </CModal>
</template>

<script>
import CModal from "../../shared/modals/CModal.vue";
import { mapActions } from "vuex";
import Turn from "../Pages/Maintenance/Turn.vue";

export default {
    name: "c-maintenance-modal-personal",
    components: {
        CModal,
    },
    data() {
        return {
            data_detail: {
                cod_uni_employee: "",
                document_type: "",
                num_document: "",
                name: "",
                last_name: "",
                position_id: "",
                dependency: "",
                cost_center: "",
                classification: "",
                condition: "",
                base_salary: "",
                hours_worked: "",
                turn_id: "",
                family_allowance: "",
                pension_fund: 1,
                bank_id: "",
                account_number: "",
                type_contract_id: "",
                turn_name: "",
                position_name: "",
                bank_name: "",
                type_contract_name: ""
            },
            documentos: [
                { value: "DNI", label: "DNI" },
                { value: "CARNET DE EXTRANJERIA", label: "CARNET EXT." },
                { value: "RUC", label: "RUC" },
            ],
            isDocumentTypeSelected: false,
        };
    },
    watch: {
        payload: {
            handler(newVal) {
                if (this.num == 1) {
                    if (newVal) {
                        this.data_detail = {
                            ...newVal,
                        };
                    }
                }
            },
            immediate: true,
        },
    },
    props: {
        title: String,
        boo_modal: Boolean,
        num: Number,
        payload: {
            type: Object,
            default: null,
        },
        positionList: {
            type: Array,
            default: null,
        },
        bankList: {
            type: Array,
            default: null,
        },
        contractTypes: {
            type: Array,
            default: null,
        },
        TurnsList: {
            type: Array,
            default: null,
        },
    },
    computed: {
        cpButton() {
            return !(this.data_detail.name != "");
        },
    },
    methods: {
        ...mapActions(["get", "post"]),
        mtdClose() {
            this.data_detail = {
                cod_uni_employee: "",
                document_type: "",
                num_document: "",
                name: "",
                last_name: "",
                position_id: "",
                dependency: "",
                cost_center: "",
                classification: "",
                condition: "",
                base_salary: "",
                hours_worked: "",
                turn_id: "",
                family_allowance: "",
                pension_fund: "",
                bank_id: "",
                account_number: "",
                type_contract_id: "",
                turn_name: "",
                position_name: "",
                bank_name: "",
                type_contract_name: ""
            };
            this.$emit("closeModal");
        },
        onDocumentTypeChange() {
            this.isDocumentTypeSelected = true;
            this.data_detail.num_document = "";
        },
        validateDocumentNumber() {
            const { document_type, num_document } = this.data_detail;
            const maxLength = document_type === "DNI" ? 8 : document_type === "RUC" ? 11 : 22;
            if (num_document.length > maxLength) {
                this.data_detail.num_document = num_document.slice(0, maxLength);
            }
        },
        filterKeyDni: function (e) {
            let b = false;
            b = /^\d$/.test(e.key);
            if (b == false && e.key != "Backspace") {
                e.preventDefault();
            }
        },
        filterKeyLetter: function (e) {
            let b = false;
            b = /^[a-zA-ZÀ-ÿ\s]$/.test(e.key);
            if (b == false && e.key != "Backspace") {
                e.preventDefault();
            }
        },
        DataDetail() {
            this.$emit("Datadetail", this.data_detail, this.num);
            this.mtdClose();
        },
    },
};
</script>