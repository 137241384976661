<template>
<CModal @mtdclosemodal="mtdclosemodal" :show="boo_modal" :close-on-backdrop="false" :centered="false" :title="title" :size="'xl'" :btn_cancel="true" :btn_ok="true" :booTitile="true">
    <div class="row mx-0  px-2">
        <div class="mb-3 text-start">
            <!-- <div class="row mx-0 mb-2 px-0">
                <table class="w-100">
                    <caption class="div-text my-0 py-0"></caption>
                    <thead class=" txt-xs ">

                        <tr>
                            <th class=" text-start text-xs  " scope="col" colspan="7"> Seleccione {{ type_product ==2 ? 'Insumos':'Soluciones' }}:</th>
                            <th class=" text-start text-xs  " scope="col">

                                <button @click="mtdaddrecipesdetails" type="button" class="btn  btn-info btn-sm px-4 py-0 text-xs text-white" :disabled="validationadd">Guardar</button>

                            </th>
                        </tr>
                    </thead>
                    <tbody class="div-text  ">
                        <tr class="px-1">
                            <td colspan="2" class="w-25 z-index-select">
                                <input type="text" v-model="receta" :placeholder=" type_product ==2 ? 'Insumos':'Soluciones'" class="form-control form-control-input form-control-sm" :disabled="validation" @keyup.enter="mtdopenmodal" />
                            </td>
                            <td v-if="recipe_unit.presentation != '-'">
                                <input type="text" v-model="recipe_unit.presentation" placeholder="Presentacion" class="form-control form-control-input form-control-sm" disabled />
                            </td>
                            <td v-if="!type">
                                <input type="text" v-model="recipe_unit.dosis" placeholder="Dosis" class="form-control form-control-input form-control-sm" disabled />
                            </td>
                            <td v-if="!type || type_product ==3">
                                <input type="text" v-model="recipe_unit.via" placeholder="Via" class="form-control form-control-input form-control-sm" :disabled="validation" />
                            </td>
                            <td v-if="!type">
                                <input v-on:keypress="isNumber($event)" type="text" v-model="recipe_unit.frequency" placeholder="Frecuencia" class="form-control form-control-input form-control-sm" :disabled="validation" />
                            </td>
                            <td v-if="!type">
                                <input v-on:keypress="isNumber($event)" type="text" v-model="recipe_unit.duration" placeholder="Duración" class="form-control form-control-input form-control-sm" :disabled="validation" />
                            </td>
                            <td v-if="type">
                                <input v-on:keypress="isNumber($event)" type="text" v-model="recipe_unit.total" placeholder="Total" class="form-control form-control-input form-control-sm" :disabled="validation" />
                            </td>
                            <td v-if="!type">
                                <button type="button" :disabled="validationInputrecipe || validation" @click="mtdaddrecipe()" class=" btn btn-success btn-sm px-1 py-0"> <i class="fas fa-plus text-white"></i> </button>
                            </td>
                            <td v-else style=" width: 50px;">
                                <button type="button" :disabled="validationtotal" @click="mtdaddrecipe()" class=" btn btn-success btn-sm px-1 py-0"> <i class="fas fa-plus text-white"></i> </button>
                            </td>
                        </tr>

                    </tbody>
                </table>
            </div>
            <div class="row div-text mx-0 px-0 mb-2 text-center">
                <table class="w-100">
                    <caption class="div-text my-0 py-0"></caption>
                    <thead class=" txt-xs ">
                        <tr>
                            <th class="encabezado-rounded" scope="col">Rp.</th>
                            <th class="encabezado-rounded w-25">{{ type_product ==2 ? 'Insumos':'Soluciones' }}</th>
                            <th class="encabezado-rounded" >Presentacion</th>
                            <th class="encabezado-rounded" v-if="type_product ==3">Via</th>
                            <th class="encabezado-rounded">Total</th>
                            <th class="encabezado-rounded">Acciones</th>
                        </tr>

                    </thead>
                    <tbody class="div-text" v-if="recipes.length >0">

                        <tr v-for="(recipe, index) in recipes" :key="index" class="px-1">
                            <td>
                                <span>{{ index + 1 }}</span>
                            </td>
                            <td>
                                <span>{{ recipe.molecule }}</span>
                            </td>
                            <td>
                                <span>{{ recipe.presentation }}</span>
                            </td>
                            <td  v-if="type_product ==3">
                                <span>{{ recipe.via }}</span>
                            </td>
                            <td>
                                <span>{{ recipe.total }}</span>
                            </td>

                            <td>
                                <button type="button" @click="mtddelete(index, 'mtddeleterecipe')" class=" btn btn-danger btn-sm px-1 py-0 " :disabled="validation"> <i class="fas fa-trash-alt text-white"></i> </button>
                            </td>
                        </tr>
                    </tbody>
                    <tbody class=" text-center" v-else>
                        <tr>
                            <td colspan="8" class=" text-center">
                                <strong> No hay resultados</strong>
                            </td>
                        </tr>

                    </tbody>
                </table>
            </div> -->
            <div>
                <div class="text-xs">
                    <strong>{{ type_product ==2 ? 'Insumos':'Soluciones' }} Seleccionados</strong>
                </div>
            </div>
            <div class="row div-text mx-0 px-0 mb-2 text-center mt-2" id="content-timelinetable">
                <table class="w-100 table table-bordered">
                    <caption class="div-text my-0 py-0"></caption>
                    <thead class=" div-text div-title text-center">
                        <tr>
                            <th class="" scope="col">N°</th>
                            <th class=" ">Fecha</th>
                            <th class=" w-25">{{ type_product ==2 ? 'Insumos':'Soluciones' }}</th>
                            <th class="">Presentacion</th>
                            <th class="" v-if="type_product ==3">via</th>
                            <th class="">Cant.Pedida</th>
                            <th class="">Cant.Utilizada</th>
                            <th class="">Cant.Pendiente</th>
                            <th class="">Cant. a utilizar</th>
                            <th class="">Acciones</th>
                        </tr>

                    </thead>
                    <tbody class="div-text" v-if="insumos.length >0">

                        <tr v-for="(insumo, index) in insumos" :key="index" class="px-1">
                            <td>
                                <span>{{ index + 1 }} </span>
                            </td>
                            <td>
                                <span>{{ insumo.date}}</span>
                            </td>
                            <td>
                                <span>{{ insumo.molecule }}</span>
                            </td>
                            <td>
                                <span>{{ insumo.presentation }}</span>
                            </td>
                            <td v-if="type_product == 3">
                                <span>{{ insumo.via }}</span>
                            </td>
                            <td>
                                <span>{{ insumo.total }}</span>
                            </td>
                            <td>
                                <span>{{ insumo.utilizado }}</span>
                            </td>
                            <td>
                                <span>{{ insumo.pendiente }}</span>
                            </td>
                            <td style="width: 150px;">
                                <input :disabled="insumo.total == insumo.utilizado" v-on:keypress="isNumber($event)" @input="mtdvalue(insumo)" type="text"  v-model="insumo.value" placeholder="Cantidad Entrante" class="form-control form-control-input form-control-sm" />
                            </td>
                            <td>
                                <button  type="button" @click="mtddaddquantity(insumo)" class=" btn btn-warning btn-sm px-1 py-0 text-white" :disabled="insumo.validation"> <i class="fas fa-plus-circle"></i></button>
                            </td>
                        </tr>
                    </tbody>
                    <tbody class=" text-center" v-else>
                        <tr>
                            <td colspan="9" class=" text-center">
                                <strong> No hay resultados</strong>
                            </td>
                        </tr>

                    </tbody>
                </table>
            </div>
        </div>

    </div>
    
    <SweetAlert :swal="swal" ref="SweetAlert" @mtddeleterecipe="mtddeleterecipe" />
</CModal>
</template>

<script>
import CModal from "@/components/shared/modals/CModal.vue";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import {
    mapActions
} from "vuex";

export default {
    name: "c-medico-modal-diagnostico",

    data() {
        return {

            swal: null,
            receta: '',
            validation: false,
            recipe_unit: {
                order: null,
                product_id: null,
                molecule: '',
                presentation: '',
                dosis: '',
                via: '',
                frequency: '',
                duration: '',
                type: null,
                total: null
            },
            type: true,
            recipes: [],
            insumos:[]

        }
    },
    components: {
        CModal,
        SweetAlert
    },

    watch: {
        number: {
            handler: function (newValue) {
                if (newValue == 1) {
                    this.mtdvaciar();

                }
            },
            deep: true,
            immediate: true,
        },
        receta_details: {
            handler: function (newValue) {
                if (Object.keys(newValue).length > 0) {
                    this.addreceta(newValue);
                }
            },
            deep: true,
            immediate: true,
        },
        id_medical: {
            handler: function (newValue) {
                if (newValue !=null && newValue !=0) {
                    this.mtdGetInsumos();
                }
            },
            deep: true,
            immediate: true,
        },
    },
    computed: {
        validationInputrecipe: function () {
            let value = true
            if (this.receta && this.recipe_unit.frequency && this.recipe_unit.via && this.recipe_unit.duration) {
                value = false
            }
            return value
        },
        validationtotal: function () {
            let value = true
            if (this.recipe_unit.total) {
                value = false
            }
            return value
        },
        validationadd: function () {
            let value = true
            if (this.recipes.length > 0) {
                value = false
            }
            return value
        }
    },
    props: {
        title: String,
        boo_modal: Boolean,
        number: {
            type: Number,
            default: 2
        },
        receta_details: {
            type: Object,
            default: {}
        },
        id_medical: {
            type: Number,
            default: 0
        },
        type_product:{
            type: Number,
            default: 0
        }

    },
    methods: {
        ...mapActions(["get", "post"]),
        mtdclosemodal() {
            this.$emit('mtdclosemodalinsumo')
        },
        mtdopenmodal: function () {
            if (this.receta) {
                this.$emit('mtdopenmodal', this.receta)
            } else {
                this.$refs.SweetAlert.showWarning('Digite Insumo');
            }
        },
        mtdvaciar: function () {
            this.receta = '',
                this.recipe_unit = {
                    order: null,
                    product_id: null,
                    molecule: '',
                    presentation: '',
                    dosis: '',
                    via: '',
                    frequency: '',
                    duration: ''
                }
        },
        addreceta: function (payload) {
            this.type = (payload.type == 2|| payload.type == 3) ? true : false
            this.receta = payload.molecule
            this.recipe_unit.order = this.recipes.length + 1;
            this.recipe_unit.molecule = payload.molecule ? payload.molecule : '-';
            this.recipe_unit.type = payload.type
            if (this.type) {
                this.recipe_unit.via = this.type_product ==3 ?'': '-'
                this.recipe_unit.frequency = '-'
                this.recipe_unit.duration = '-'
                this.recipe_unit.dosis = payload.dosis ? payload.dosis : '-';
                this.recipe_unit.presentation = payload.presentation ? payload.presentation : payload.commercial;
            } else {
                this.recipe_unit.dosis = payload.dosis;
                this.recipe_unit.presentation = payload.presentation;
            }
        },
        isNumber: function (evt) {
            evt = evt ? evt : window.event;
            var charCode = evt.which ? evt.which : evt.keyCode;
            if (
                charCode > 31 &&
                (charCode < 48 || charCode > 57) &&
                charCode !== 46
            ) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        mtdaddrecipe: function () {
            const exists = this.recipes.some(item => item.molecule + ' ' + item.presentation + ' ' + item.dosis === this.recipe_unit.molecule + ' ' + this.recipe_unit.presentation + ' ' + this.recipe_unit.dosis);
            if (exists) {
                this.$refs.SweetAlert.showWarning("Insumo ya agregado");
            } else {
                this.recipes.push(this.recipe_unit);
            }
            this.type = true
            this.receta = null
            this.recipe_unit = {
                order: null,
                product_id: null,
                molecule: '',
                presentation: '',
                dosis: '',
                via: '',
                frequency: '',
                duration: '',
                type: null,
                total: null
            }
        },
        mtddelete: function (index, msg) {
            this.$refs.SweetAlert.showDelete(index, msg);

        },
        mtddeleterecipe: function (index) {
            this.recipes.splice(index, 1);
            for (let i = 0; i < this.recipes.length; i++) {
                this.recipes[i].order = i + 1;
            }
        },

        mtdaddrecipesdetails: function () {
            let data = {
                medical_act_id: this.id_medical,
                recipe: this.recipes
            }
            this.post({
                url: this.$store.getters.get__url + "/Emergency/addInsumo",
                token: this.$store.getters.get__token,
                params: data
            }).then((response) => {
                if (response.statusCode === 200) {
                    this.$refs.SweetAlert.showSuccess(response.message);
                    this.recipes = []
                    this.mtdGetInsumos()   
                }
            });
        },
        mtdGetInsumos: function(){
            this.post({
                url: this.$store.getters.get__url + "/Emergency/indexInsumo",
                token: this.$store.getters.get__token,
                params: {
                    id: this.id_medical,
                    type:this.type_product
                }
            }).then((response) => {
                if (response.statusCode === 200) {
                    response.data.forEach(element => {
                        element.value = ''
                        element.validation = true
                    });
                    this.insumos = response.data
                }
            });
        },
        mtddaddquantity : function (payload){
            payload.medical_act_id = this.id_medical
            // if(payload.state == 3 || payload.state == 2 ){
               
                this.post({
                    url: this.$store.getters.get__url + "/Hospitalization/addQuantityInsumo",
                    token: this.$store.getters.get__token,
                    params:payload
                }).then((response) => {
                    if (response.statusCode === 200) {
                        if(response.data != null){
                            this.$refs.SweetAlert.showSuccess(response.message); 
                        }else{
                            let msg = this.type_product == 2 ? 'El insumo aún no ha sido despachado' : 'La solución aún no ha sido despachada';
                            this.$refs.SweetAlert.showWarning(msg); 
                        }
                        this.mtdGetInsumos() 
                    }
                });
            },
            // else{
            //    
            // }

          
            
            mtdvalue: function(payload){
                if(payload.value){
                if(payload.value <= payload.pendiente){
                    payload.validation = false
                }else{
                    payload.validation = true
                    payload.value=''
                    this.$refs.SweetAlert.showInfo('Cantida mayor a la cantidad pendiente');
                }
                }else{
                    payload.validation = true 
                }

            
            }
        },

    
}
</script>
