<template>
<CModal @mtdclosemodal="mtdclosemodal" :show="boo_modal" :close-on-backdrop="false" :centered="false" :title="title" :size="'xl'" :btn_cancel="true" :btn_ok="true" :booTitile="true">
    <div class="row mx-0  px-2">
        <div class="mb-3 text-start">
            <div class="row div-text mx-0 px-0 mb-2 text-center">
                <table id="tb-anular" class="table table-bordered">
                    <caption class="mx-0 px-0"></caption>
                    <thead class="bg-main" style="font-size: 12px">
                        <tr class="text-white">
                            <th scope="col" class="align-middle " style="width: 10%;">Seleccionar</th>
                            <th scope="col" class="align-middle  " style="width: 70%;">Nombre de Producto</th>
                            <th scope="col" class="align-middle " style="width: 20%;">Cantidad</th>    
                        </tr>
                    </thead>
                    <tbody class="border-main" style="font-size: 11 px" v-if="devoluciones.length >0">
                        <tr class="tbody-table" v-for="(item, index) in devoluciones " :key="index" >
                            <td class="align-middle">
                                <input class="form-check-input" v-model="item.status" type="checkbox" value="" id="flexCheckDefault">
                            </td>
                            <td class="align-middle">
                                {{item.molecule }} {{ item.presentation }} {{ item.dosis }}
                            </td>
                            
                            <td  class="text-center">
                                <input :disabled="!item.status"  v-on:keypress="isNumber($event)"  type="text"  v-model="item.quantity" placeholder="Cantidad Entrante" class="form-control form-control-input form-control-sm" />
                            </td>
                          
                        </tr>
                       
                    </tbody>
                    <tbody class=" text-center" v-else>
                        <tr>
                            <td colspan="3" class=" text-center">
                                <strong> No hay resultados</strong>
                            </td>
                        </tr>

                    </tbody>
                </table>
            </div>
        </div>

    </div>
    <template #footer>
        <button @click="mtdclosemodal" class="btn btn-danger btn-sm">
            <i class="fa fa-times" />&nbsp;Cerrar
        </button>
        <button class="btn btn-success btn-sm"  @click="addReturns" :disabled="mtddisable">
            Guardar
        </button>
        &nbsp;
    </template>

    <SweetAlert :swal="swal" ref="SweetAlert" />
</CModal>
</template>

<script>

import CModal from "@/components/shared/modals/CModal.vue";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import {
    mapActions
} from "vuex";

export default {
    name: "c-medico-modal-diagnostico",

    data() {
        return {
            swal: null,
            devoluciones:[]
        }
    },
    components: {
        CModal,
        SweetAlert
    },

    watch: {

        id_medical: {
            handler: function (newValue) {
                if (newValue != null && newValue != 0) {
                    this.mtdDevoluciones();
                }
            },
            deep: true,
            immediate: true,
        },
    },
    computed: {
        mtddisable:function(){
            let value = true 
            this.devoluciones.forEach(item=>{
                if(item.quantity){
                    value = false
                }
            })
            return value
        }
    },
    props: {
        title: String,
        boo_modal: Boolean,
        id_medical: {
            type: Number,
            default: 0
        },
    },
    methods: {
        ...mapActions(["get", "post"]),
        mtdclosemodal() {
            this.$emit('mtdclosemodaldevoluciones')
        },

        mtdDevoluciones:function(){
            this.post({
                url: this.$store.getters.get__url + "/Emergency/indexDevoluciones",
                token: this.$store.getters.get__token,
                params:{
                    id: this.id_medical
                }
            }).then((response) => {
                if (response.statusCode === 200) {
                     this.devoluciones=  response.data;
                }
            });
        },

        addReturns:function(){
            this.post({
                url: this.$store.getters.get__url + "/Emergency/addReturns",
                token: this.$store.getters.get__token,
                params:{
                    id: this.id_medical,
                    returns: this.devoluciones
                }
            }).then((response) => {
                if (response.statusCode === 200) {
                    this.$refs.SweetAlert.showSuccess(response.message);
                    this.devoluciones = [];
                    this.mtdclosemodal();
                }
            });
        },

        isNumber: function (evt) {
            evt = evt ? evt : window.event;
            var charCode = evt.which ? evt.which : evt.keyCode;
            if (
                charCode > 31 &&
                (charCode < 48 || charCode > 57) &&
                charCode !== 46
            ) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
    },
}
</script>
