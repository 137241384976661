<template>
    <div class="px-5">
        <div class="px-4 pb-5 pt-2">
            <div class="row">
                <div class=" btn-home  col-md-12 mt-3 mb-4 d-flex justify-content-end">
                    <button type="button" class="btn btn-outline-main" @click="returnHome">
                        <i class="fas fa-home-lg-alt"></i> Inicio
                    </button>
                </div>
            </div>
            <ul class="nav" id="myTab" role="tablist">
                <li class="nav-item  " role="presentation">
                    <button @click="changePage('afp')" :class="{ 'item-button':true,'nav-link': true, 'active': page === 'afp' }" id="profile-tab" type="button">
                        AFP
                    </button>
                </li>
                <li class="nav-item  " role="presentation">
                    <button @click="changePage('banco')" :class="{ 'item-button':true,'nav-link': true, 'active': page === 'banco' }" id="profile-tab" type="button">
                        Bancos
                    </button>
                </li>
                <li class="nav-item  " role="presentation">
                    <button @click="changePage('cargo')" :class="{'item-button':true, 'nav-link': true, 'active': page === 'cargo' }" id="home-tab" type="button">
                        Cargo
                    </button>
                </li>
                <li class="nav-item " role="presentation">
                    <button @click="changePage('tipo_contrato')" :class="{ 'item-button':true,'nav-link': true, 'active': page === 'tipo_contrato' }" id="home-tab" type="button">
                        Tipo de Contrato
                    </button>
                </li>
                <li class="nav-item " role="presentation">
                    <button @click="changePage('especialidad')" :class="{ 'item-button':true,'nav-link': true, 'active': page === 'especialidad' }" id="home-tab" type="button">
                        Especialidad
                    </button>
                </li>
                <li class="nav-item " role="presentation">
                    <button @click="changePage('turno')" :class="{ 'item-button':true,'nav-link': true, 'active': page === 'turno' }" id="profile-tab" type="button">
                        Turno
                    </button>
                </li>
                <li class="nav-item " role="presentation">
                    <button @click="changePage('usuario')" :class="{ 'item-button':true,'nav-link': true, 'active': page === 'usuario' }" id="profile-tab" type="button">
                        Usuarios
                    </button>
                </li>
            </ul>
            <div class="tab-content" id="myTabContent">
                <Afp v-if="page=='afp'" />
                <Banco v-if="page=='banco'" />
                <Cargo v-if="page=='cargo'" />
                <TipoContrato v-if="page=='tipo_contrato'" />
                <Especialidad v-if="page=='especialidad'" />
                <Turno v-if="page=='turno'" />
                <Usuario v-if="page=='usuario'" />
            </div>
        </div>
    </div>
    </template>
    
    <script>
    import {
        mapActions
    } from "vuex";
    import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
    import Afp from '@/components/RecursosHumanos/Pages/Maintenance/Afp.vue';
    import Banco from '@/components/RecursosHumanos/Pages/Maintenance/Bank.vue';
    import Cargo from '@/components/RecursosHumanos/Pages/Maintenance/Position.vue';
    import TipoContrato from '@/components/RecursosHumanos/Pages/Maintenance/TypeContract.vue';
    import Turno from '@/components/RecursosHumanos/Pages/Maintenance/Turn.vue';
    import Usuario from '@/components/RecursosHumanos/Pages/Maintenance/User.vue';
    import Especialidad from '@/components/RecursosHumanos/Pages/Maintenance/Especialidad.vue';

    export default {
        name: "c-mantenedores-pages-personal",
        components: {
            SweetAlert,
            Afp,
            Banco,
            Cargo,
            TipoContrato,
            Turno,
            Usuario,
            Especialidad
        },
        data() {
            return {
                swal: null,
                page: 'afp',
            };
        },
        computed: {
    
        },
        created() {
            //this.mtdGetCampus();
        },
        methods: {
            ...mapActions(["get", "post"]),
    
            changePage: function (page) {
                this.page = page;
    
            },
            returnHome: function () {
            this.$emit("mtdChangePage", 'home');
        },
        }
    }
    </script>
    