<template>
<div class="py-3" v-if="graficData.length > 0">
    <div class="order-md-1 order-lg-2 py-1 py-xl-0 px-0 d-flex justify-content-between">
        <div class="row d-flex px-1 mx-1">
            <div class="ol-md-12 d-inline-flex px-0 w-100 ms-1">
                <button type="button" class="btn btn-sm text-white btn-sm bg-danger text-xs py-0" 
                @click="pdfGrafica">
                    <i class="fa fa-file-pdf"></i> Exportar PDF
                </button>
            </div>
        </div>
        <div class="row d-flex justify-content-end px-2">
            <!-- <div class="col-12 px-0">
                <nav aria-label="Page navigation example" class="pagination-container">
                    <ul class="pagination pagination-xs justify-content-end mb-0">
                        <li class="page-item">
                            <a style="cursor: pointer" href="javascript:void(0)" class="page-link" @click="backPage">«</a>
                        </li>
                        <template>
                            <li class="page-item">
                                <a class="page-link active" href="javascript:void(0)">{{ graphic_sheets.date_name }}</a>
                            </li>
                        </template>
                        <li class="page-item">
                            <a style="cursor: pointer" class="page-link" @click="nextPage">»</a>
                        </li> 
                    </ul>
                </nav>
            </div> -->
            <div class="col-12 px-0">
                <nav aria-label="Page navigation example" class="pagination-container">
                    <ul class="pagination pagination-xs justify-content-end mb-0">
                        <li class="page-item" :class="{ disabled: currentPage === 0 }">
                            <a style="cursor: pointer" class="page-link" @click="backPage">«</a>
                        </li>
                        <li class="page-item">
                            <span class="page-link active">{{ currentPageLabel }}</span>
                        </li>
                        <li class="page-item" :class="{ disabled: currentPage === grafigPages.length - 1 }">
                            <a style="cursor: pointer" class="page-link" @click="nextPage">»</a>
                        </li>
                    </ul>
                </nav>
            </div> 
        </div>
    </div>

   <!--  <div class="row mx-0 p-2 mb-2">
        <div class="col-md-12 d-flex justify-content-end " style="color: #900052; text-align: center; position: relative;">
            <div class="superior-derecha">
                <span class="leyenda-graf-1 mt-2"></span> PAS &nbsp;
                <span class="leyenda-graf-2 mt-2"></span> PAD &nbsp;
                <span class="leyenda-graf-3 mt-2"></span> FC &nbsp;
                <span class="leyenda-graf-4 mt-2"></span> FR &nbsp;
                <span class="leyenda-graf-5 mt-2"></span> T* &nbsp;
                <span class="leyenda-graf-6 mt-2"></span> SatO2
            </div>
        </div>
    </div> -->

    <div class="row p-2">
        <!-- <div class="row d-flex">
            <div class="col-xl-10 col-lg-9 col-md-8 col-sm-12 py-3">
                <div class="row">
                    <div class="col-xl-2 col-md-6 py-1">
                        <div id="div-d-hospitalizado" class="form-group row align-left">
                            <label for="d-hospitalizado" class="col-xl-8 color-main fw-bold" style="font-size: 14px">Días Hospitalizado</label>
                            <div class="col-xl-4">
                                <input v-model="graphic_sheets.day" v-on:keypress="validateDecimal($event)" type="text" class="form-control form-control-sm" id="d-hospitalizado" readonly>
                            </div>
                        </div>
                    </div>

                    <div class="col-xl-2 col-md-6 py-1">
                        <div id="div-d-po" class="form-group row align-left">
                            <label for="d-po" class="col-xl-5 color-main fw-bold" style="font-size: 14px">Días P.O.</label>
                            <div class="col-xl-7">
                                <input v-model="graphic_sheets.day_po" v-on:keypress="validateDecimal($event)" type="text" class="form-control form-control-sm" id="d-po" :disabled="validation">
                            </div>
                        </div>
                    </div>

                    <div class="col-xl-2 col-md-6 py-1">
                        <div id="div-orina" class="form-group row align-left">
                            <label for="orina" class="col-xl-5 color-main fw-bold" style="font-size: 14px">Peso (Kg)</label>
                            <div class="col-xl-7">
                                <input v-model="graphic_sheets.weight" v-on:keypress="validateDecimal($event)" type="text" class="form-control form-control-sm" id="orina" :disabled="validation">
                            </div>
                        </div>
                    </div>

                    <div class="col-xl-2 col-md-6 py-1">
                        <div id="div-deposicion" class="form-group row align-left">
                            <label for="deposicion" class="col-xl-6 color-main fw-bold" style="font-size: 14px">Deposiciones</label>
                            <div class="col-xl-6">
                                <input v-model="graphic_sheets.deposition" v-on:keypress="validateDecimal($event)" type="text" class="form-control form-control-sm" id="deposicion" :disabled="validation">
                            </div>
                        </div>
                    </div>

                    <div class="col-xl-2 col-md-6 py-1">
                        <div id="div-orina" class="form-group row align-left">
                            <label for="orina" class="col-xl-5 color-main fw-bold" style="font-size: 14px">Orina</label>
                            <div class="col-xl-7">
                                <input v-model="graphic_sheets.urine" v-on:keypress="validateDecimal($event)" type="text" class="form-control form-control-sm" id="orina" :disabled="validation">
                            </div>
                        </div>
                    </div>

                    <div class="col-xl-2 col-md-6 py-1">
                        <div id="div-orina" class="form-group row align-left">
                            <label for="balance_hidrico" class="col-xl-8 color-main fw-bold" style="font-size: 14px">Balance Hídrico</label>
                            <div class="col-xl-4">
                                <input v-model="graphic_sheets.hydric_balance" v-on:keypress="validateDecimal($event)" type="text" class="form-control form-control-sm" id="balance_hidrico" :disabled="validation">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-2 col-lg-3 col-md-4 col-sm-12 py-3">
                <div class="row">
                    <table class="w-100">
                        <caption class="div-text"></caption>
                        <tbody class="div-text">
                            <tr class="px-1 mb-1" v-for="(item, index) in graphic_sheets.values" :key="index" v-if="item.id!==2">
                                <td v-if="item.id===1" class="text-center">
                                    <div><span class="encabezado-rounded-1 fw-bold px-2 py-1">PAS</span><span class="encabezado-rounded-2 fw-bold px-2 py-1">PAD</span></div>
                                </td>
                                <td v-else class="text-center">
                                    <div :class="['encabezado-rounded-'+item.id, 'px-2', 'fw-bold']">{{item.label}}</div>
                                </td>
                                <td class="px-2">
                                    <div class="input-group">
                                        <input v-model="item.value" type="text" v-on:keypress="validateDecimal($event)" class="form-control form-control-input form-control-sm" :placeholder="item.id==1 ? 'PAS' : ''" aria-label="Valor" :disabled="validation">
                                        <input v-if="item.inputs==2" v-model="item.value2" type="text" v-on:keypress="validateDecimal($event)" class="form-control form-control-input form-control-sm" placeholder="PAD" aria-label="Valor 2" :disabled="validation">
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div> -->

        <!---Tabla 2 GRAFICAS -->
        <!--
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 p-3">

            <div class="row">

                <div class="p-2 col-xl-12 col-md-12 col-sm-12">
                    <span class="encabezado-rounded-1 px-2 fw-bold">PAS</span> <span class="encabezado-rounded-2 px-2 fw-bold">PAD</span>
                    <div class="table-responsive-graph scroll-graph px-0">
                        <div id="chart" class="content-graph ancho">
                            <apexchart type="rangeBar" height="250" :options="chartOptionsPas" :series="series_pas"></apexchart>
                        </div>
                    </div>
                </div>
                <div class="p-2 col-xl-12 col-md-12 col-sm-12">
                    <span class="encabezado-rounded-3 px-2 fw-bold">FC</span>
                    <div class="table-responsive-graph scroll-graph px-0">
                        <div id="chart" class="content-graph ancho">
                            <apexchart type="line" height="240" :options="chartOptionsFc" :series="series_fc"></apexchart>
                        </div>
                    </div>
                </div>

                <div class="p-2 col-xl-12 col-md-12 col-sm-12">
                    <span class="encabezado-rounded-4 px-2 fw-bold">FR</span>
                    <div class="table-responsive-graph scroll-graph px-0">
                        <div id="chart" class="content-graph ancho">
                            <apexchart type="line" height="240" :options="chartOptionsFr" :series="series_fr"></apexchart>
                        </div>
                    </div>
                </div>

                <div class="p-2 col-xl-12 col-md-12 col-sm-12">
                    <span class="encabezado-rounded-5 px-2 fw-bold">T°</span>
                    <div class="table-responsive-graph scroll-graph px-0">
                        <div id="chart" class="content-graph ancho">
                            <apexchart type="line" height="240" :options="chartOptionsT" :series="series_t"></apexchart>
                        </div>
                    </div>
                </div>

                <div class="p-2 col-xl-12 col-md-12 col-sm-12">
                    <span class="encabezado-rounded-6 px-2 fw-bold">SatO2</span>
                    <div class="table-responsive-graph scroll-graph px-0">
                        <div id="chart" class="content-graph ancho">
                            <apexchart type="line" height="240" :options="chartOptionsSat" :series="series_sat"></apexchart>
                        </div>
                    </div>
                </div>

            </div>


        </div>
        -->

        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 p-3" v-if="grafigPages[currentPage]">
            <div class="row">
                <!-- PAS -->
                <div class="p-2 col-xl-12 col-lg-12 col-md-12 col-sm-12"  id="content-timelinetable">
                    <span class="encabezado-rounded-1 px-2 fw-bold">PAS</span> <span class="encabezado-rounded-2 px-2 fw-bold">PAD</span>
                    <div class="d-flex flex-column align-items-center justify-content-center">
                        <div id="chart" class="content-graph ancho">
                            <img :src="grafigPages[currentPage].chartPas" alt="ChartPas" class="img-chart" />
                        </div>
                    </div>
                </div> 

                <!-- FC -->
                <div class="p-2 col-xl-12 col-lg-12 col-md-12 col-sm-12" id="content-timelinetable">
                    <span class="encabezado-rounded-3 px-2 fw-bold">FC</span>
                    <div class="d-flex flex-column align-items-center justify-content-center">
                        <div id="chart" class="content-graph ancho">
                            <img :src="grafigPages[currentPage].chartFc" alt="ChartFc" class="img-chart" />
                        </div>
                    </div>
                </div>

                <!-- FR -->
                <div class="p-2 col-xl-12 col-lg-12 col-md-12 col-sm-12" id="content-timelinetable">
                    <span class="encabezado-rounded-4 px-2 fw-bold">FR</span>
                    <div class="d-flex flex-column align-items-center justify-content-center">
                        <div id="chart" class="content-graph ancho">
                            <img :src="grafigPages[currentPage].chartFr" alt="ChartFr" class="img-chart" />
                        </div>
                    </div>
                </div>

                <!-- T° -->
                <div class="p-2 col-xl-12 col-lg-12 col-md-12 col-sm-12" id="content-timelinetable">
                    <span class="encabezado-rounded-5 px-2 fw-bold">T°</span>
                    <div class="d-flex flex-column align-items-center justify-content-center">
                        <div id="chart" class="content-graph ancho">
                            <img :src="grafigPages[currentPage].chartT" alt="ChartT" class="img-chart" />
                        </div>
                    </div>
                </div>

                <!-- SatO2 -->
                <div class="p-2 col-xl-12 col-lg-12 col-md-12 col-sm-12" id="content-timelinetable">
                    <span class="encabezado-rounded-6 px-2 fw-bold">SatO2</span>
                    <div class="d-flex flex-column align-items-center justify-content-center">
                        <div id="chart" class="content-graph ancho">
                            <img :src="grafigPages[currentPage].chartSat" alt="ChartSat" class="img-chart" />
                        </div>
                    </div>
                </div>


                <div class="p-0 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-2">
                    <div class="col-md-12 table-responsive px-0" id="content-timelinetable">
                        <table id="tb-anular" class="table table-bordered">
                            <caption class="mx-0 px-0"></caption>
                            <thead class="bg-main" style="font-size: 13px">
                                <tr  class="text-white text-center align-middle">
                                    <th scope="col">FECHA</th>
                                    <th scope="col" v-for="(data, fecha) in grafigPages[currentPage].tGra" :key="fecha">
                                        {{ fecha }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody class="border-main" style="font-size: 12px">
                                <tr class="text-center align-middle">
                                    <th scope="row" class="wide-nameCabe-gra">PESO</th>
                                    <td v-for="(data, index) in grafigPages[currentPage].tGra" :key="'weight-' + index" class="wide-name-gra text-center">
                                        {{ data.weightGraphic || '-' }}
                                    </td>
                                </tr>
                                <tr class="text-center align-middle">
                                    <th scope="row" class="wide-nameCabe-gra">DEPOSICIONES</th>
                                    <td v-for="(data, index) in grafigPages[currentPage].tGra" :key="'deposition-' + index" class="wide-name-gra text-center">
                                        {{ data.deposition || '-' }}
                                    </td>
                                </tr>
                                <tr class="text-center align-middle">
                                    <th scope="row" class="wide-nameCabe-gra">ORINA</th>
                                    <td v-for="(data, index) in grafigPages[currentPage].tGra" :key="'urine-' + index" class="wide-name-gra text-center">
                                        {{ data.urine || '-' }}
                                    </td>
                                </tr>
                                <tr class="text-center align-middle">
                                    <th scope="row" class="wide-nameCabe-gra">BALANCE HIDRICO</th>
                                    <td v-for="(data, index) in grafigPages[currentPage].tGra" :key="'hydric_balance-' + index" class="wide-name-gra text-center">
                                        {{ data.hydric_balance || '-' }}
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div>


            </div>
        </div>

    </div>
</div>
<div v-else>
    <div class="text-center mt-5">
        <h2>No se encontraron resultados</h2>
    </div>
</div>
</template>

<script>
import {
    mapActions
} from 'vuex';
import VueApexCharts from 'vue-apexcharts'

export default {
    name: 'c-asistencial-hospitalizacion-historia-hojagrafica',
    components: {
        apexchart: VueApexCharts,
    },
    data() {
        return {
            validation: false,
            swal: null,
            sheets: [],
            graphic_sheets: {
                date_name: 'GRÁFICA ACTUAL',
                id: null,
                medical_act_id: null,
                date: null,
                hour: null,
                day: 0,
                doctor_name: '',
                user_id: null,
                day_po: '',
                weight: '',
                deposition: '',
                urine: '',
                hydric_balance: '',
                values: [{
                        id: 1,
                        label: 'PAS',
                        value: '',
                        value2: '',
                        inputs: 2
                    },
                    {
                        id: 2,
                        label: 'PAD',
                        value: '',
                        inputs: 1
                    },
                    {
                        id: 3,
                        label: 'FC',
                        value: '',
                        inputs: 1
                    },
                    {
                        id: 4,
                        label: 'FR',
                        value: '',
                        inputs: 1
                    },
                    {
                        id: 5,
                        label: 'T*',
                        value: '',
                        inputs: 1
                    },
                    {
                        id: 6,
                        label: 'SAT02',
                        value: '',
                        inputs: 1
                    }
                ],
            },
            pos: null,

            // Arrays
            datatime: [],
            series_pas: [],
            series_pad: [],
            categories_pad: [],
            annotations_pad: [],
            series_fc: [],
            categories_fc: [],
            annotations_fc: [],
            series_fr: [],
            categories_fr: [],
            annotations_fr: [],
            series_t: [],
            categories_t: [],
            annotations_t: [],
            series_sat: [],
            categories_sat: [],
            annotations_sat: [],
            // Parámetros
            data_pas: [350, 300, 250, 200, 150, 100, 50, 0],
            data_pad: [70, 60, 50, 40, 30, 20, 10, 0],
            data_fc: [160, 150, 140, 130, 120, 110, 100, 90, 80, 70, 60, 50, 40],
            data_fr: [60, 50, 40, 30, 20, 10, 0],
            data_temp: ['42°', '41°', '40°', '39°', '38°', '37°', '36°', '35°'],
            data_sat: ['100%', '80%', '60%', '40%', '20%', '0%'],

            // PAS
            /* series_pas: [{
                name: 'Mañana',
                data: [{
                  x: '11-07-2024',
                  y: [90, 200]
                }, {
                  x: '12-07-2024',
                  y: [98, 180]
                }, {
                  x: '13-07-2024',
                  y: [78, 195]
                }, {
                  x: '14-07-2024',
                  y: [70, 185]
                }, {
                  x: '15-07-2024',
                  y: [75, 189]
                }, {
                  x: '16-07-2024',
                  y: [84, 200]
                }, {
                  x: '17-07-2024',
                  y: [100, 210]
                }]
              }, {
                name: 'Tarde',
                data: [{
                  x: '11-07-2024',
                  y: [69, 198]
                }, {
                  x: '12-07-2024',
                  y: [85, 203]
                }, {
                  x: '13-07-2024',
                  y: [90, 210]
                }, {
                  x: '14-07-2024',
                  y: [97, 185]
                }, {
                  x: '15-07-2024',
                  y: [85, 199]
                }, {
                  x: '16-07-2024',
                  y: [67, 197]
                }, {
                  x: '17-07-2024',
                  y: [94, 195]
                }]
              }, {
                name: 'Noche',
                data: [{
                  x: '11-07-2024',
                  y: [89, 195]
                }, {
                  x: '12-07-2024',
                  y: [85, 200]
                }, {
                  x: '13-07-2024',
                  y: [70, 170]
                }, {
                  x: '14-07-2024',
                  y: [97, 187]
                }, {
                  x: '15-07-2024',
                  y: [95, 190]
                }, {
                  x: '16-07-2024',
                  y: [100, 210]
                }, {
                  x: '17-07-2024',
                  y: [105, 205]
                }]
            }], */
            chartOptionsPas: {
                chart: {
                    type: 'rangeBar',
                    height: 350
                },
                plotOptions: {
                    bar: {
                        horizontal: false
                    }
                },
                dataLabels: {
                    enabled: true
                },
                colors: ['#FFC107', '#008FFB', '#FF4560'], // Colores de las barras
                dataLabels: {
                    enabled: true
                },
                legend: {
                    show: true,
                    position: 'bottom', // Posición del pie de página
                    horizontalAlign: 'left',
                    labels: {
                        colors: ['#000'], // Color del texto de la leyenda
                        useSeriesColors: false
                    }
                },
            },
            // FIN PAS

            // PAD
            /* series_pad: [{
              name: 'PAD',
              data: [
                  21, 0, 25,  // Valores del 11-07-2024
                  30, 20, 22,  // Valores del 12-07-2024
                  25, 24, 23,  // Valores del 13-07-2024
                  20, 22, 23,  // Valores del 14-07-2024
                  18, 19, 20,  // Valores del 15-07-2024
                  26, 27, 28   // Valores del 16-07-2024
              ]
            }], */
            chartOptionsPad: {
                chart: {
                    height: 350,
                    type: 'line',
                    zoom: {
                        enabled: false
                    }
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'straight'
                },
                title: {
                    align: 'left'
                },
                colors: ['#62CDFF'],
                grid: {
                    row: {
                        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                        opacity: 0.5
                    },
                },
                xaxis: {
                    categories: [],
                    labels: {
                        rotate: -45
                    }
                },
                annotations: {
                    xaxis: []
                }
            },
            // FIN PAD

            // FC
            /* series_fc: [{
              name: 'FC',
              data: [
                  40, 30, 60,  // Valores del 11-07-2024
                  80, 50, 60,  // Valores del 12-07-2024
                  120, 100, 80,  // Valores del 13-07-2024
                  75, 50, 80,  // Valores del 14-07-2024
                  80, 110, 90,  // Valores del 15-07-2024
                  80, 90, 100   // Valores del 16-07-2024
              ]
            }], */
            chartOptionsFc: {
                chart: {
                    height: 350,
                    type: 'line',
                    zoom: {
                        enabled: false
                    }
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'straight'
                },
                title: {
                    align: 'left'
                },
                colors: ['#81FFBA'],
                grid: {
                    row: {
                        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                        opacity: 0.5
                    },
                },
                xaxis: {
                    categories: [],
                    labels: {
                        rotate: -45
                    }
                },
                annotations: {
                    xaxis: []
                }
            },
            // FIN FC

            // FR
            /* series_fr: [{
              name: 'FR',
              data: [
                  40, 30, 60,  // Valores del 11-07-2024
                  55, 50, 60,  // Valores del 12-07-2024
                  55, 40, 30,  // Valores del 13-07-2024
                  25, 30, 28,  // Valores del 14-07-2024
                  30, 40, 50,  // Valores del 15-07-2024
                  50, 55, 60   // Valores del 16-07-2024
              ]
            }], */
            chartOptionsFr: {
                chart: {
                    height: 350,
                    type: 'line',
                    zoom: {
                        enabled: false
                    }
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'straight'
                },
                title: {
                    align: 'left'
                },
                colors: ['#FFBC81'],
                grid: {
                    row: {
                        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                        opacity: 0.5
                    },
                },
                xaxis: {
                    categories: [],
                    labels: {
                        rotate: -45
                    }
                },
                annotations: {
                    xaxis: []
                }
            },
            // FIN FR

            // T°
            /* series_t: [{
              name: 'T°',
              data: [
                  36.50, 35, 38,  // Valores del 11-07-2024
                  38, 36, 36,  // Valores del 12-07-2024
                  39, 37.4, 35.8,  // Valores del 13-07-2024
                  40, 37, 35.5,  // Valores del 14-07-2024
                  38, 37, 36.8,  // Valores del 15-07-2024
                  35.8, 33, 35   // Valores del 16-07-2024
              ]
            }], */
            chartOptionsT: {
                chart: {
                    height: 350,
                    type: 'line',
                    zoom: {
                        enabled: false
                    }
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'straight'
                },
                title: {
                    align: 'left'
                },
                colors: ['#FF818B'],
                grid: {
                    row: {
                        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                        opacity: 0.5
                    },
                },
                xaxis: {
                    categories: [],
                    labels: {
                        rotate: -45
                    }
                },
                yaxis: {
                    labels: {
                        formatter: function (value) {
                            return value + '°'; // Para grados
                        }
                    }
                },
                annotations: {
                    xaxis: []
                }
            },
            // FIN T°

            // Sat
            /* series_sat: [{
              name: 'Sat',
              data: [
                  36.50, 55, 68,  // Valores del 11-07-2024
                  98, 96, 96,  // Valores del 12-07-2024
                  99, 97.4, 95.8,  // Valores del 13-07-2024
                  40, 97, 95.5,  // Valores del 14-07-2024
                  98, 97, 36.8,  // Valores del 15-07-2024
                  35.8, 13, 35   // Valores del 16-07-2024
              ]
            }], */
            chartOptionsSat: {
                chart: {
                    height: 350,
                    type: 'line',
                    zoom: {
                        enabled: false
                    }
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    curve: 'straight'
                },
                title: {
                    align: 'left'
                },
                colors: ['#DB81FF'],
                grid: {
                    row: {
                        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                        opacity: 0.5
                    },
                },
                xaxis: {
                    categories: [],
                    labels: {
                        rotate: -45
                    }
                },
                yaxis: {
                    labels: {
                        formatter: function (value) {
                            return value + '%'; // Para porcentaje
                        }
                    }
                },
                annotations: {
                    xaxis: []
                }
            },
            // FIN Sat
            activePasIndex: 0,
            activeSatIndex: 0,
            activeFcIndex: 0,
            activeFrIndex: 0,
            activeTIndex: 0,
            chartsPas: [],
            chartsSat: [],
            chartsFc: [],
            chartsFr: [],
            chartsT: [],
            currentPage: 0, 
            grafigPages: [],
            graficData: []  
        }
    },
    props: {
        medical_act_id: {
            type: Number,
            default: 0
        },
        addSheet: {
            type: Number,
            default: 0
        },
        dete_out: {
            type: Boolean
        },
        rolname: String,
        dete_out_doctor: {
            type: Boolean
        },
    },
    watch: {
        medical_act_id: {
            handler: function (newValue) {
                if (newValue != 0) {
                    this.mtdday();
                    this.mtdGetSheets();
                    this.mtdsheetday();
                }
            },
            deep: true,
            immediate: true,
        },
        addSheet: {
            handler: function (newValue) {
                if (newValue == 1) {
                    //this.mtdday();
                    this.mtdstoredata()
                }
            },
            deep: true,
            immediate: true,
        },
    },
    created() {
        this.setCurrentDateTime();
        //this.mtdsheetday();
        //this.mtdsheetdata();
    },
    mounted() {
        //this.mtdsheetdata();
    },
    computed: {
        currentPageLabel() {
            return `Página ${this.currentPage + 1}`;
        }
    },
    methods: {
        ...mapActions(['get', 'post']),
        mtdhidde: function () {
            this.graphic_sheets = {
                    date_name: 'GRÁFICA ACTUAL',
                    id: null,
                    medical_act_id: null,
                    date: null,
                    hour: null,
                    day: 0,
                    doctor_name: '',
                    user_id: null,
                    day_po: '',
                    weight: '',
                    deposition: '',
                    urine: '',
                    hydric_balance: '',
                    values: [{
                            id: 1,
                            label: 'PAS',
                            value: '',
                            value2: '',
                            inputs: 2
                        },
                        {
                            id: 2,
                            label: 'PAD',
                            value: '',
                            inputs: 1
                        },
                        {
                            id: 3,
                            label: 'FC',
                            value: '',
                            inputs: 1
                        },
                        {
                            id: 4,
                            label: 'FR',
                            value: '',
                            inputs: 1
                        },
                        {
                            id: 5,
                            label: 'T*',
                            value: '',
                            inputs: 1
                        },
                        {
                            id: 6,
                            label: 'SAT02',
                            value: '',
                            inputs: 1
                        }
                    ],
                },
                this.$miVariableGlobalSheet.day = this.graphic_sheets.day
            this.$miVariableGlobalSheet.day_po = this.graphic_sheets.day_po
            this.$miVariableGlobalSheet.weight = this.graphic_sheets.weight
            this.$miVariableGlobalSheet.deposition = this.graphic_sheets.deposition
            this.$miVariableGlobalSheet.urine = this.graphic_sheets.urine
            this.$miVariableGlobalSheet.hydric_balance = this.graphic_sheets.hydric_balance
            this.$miVariableGlobalSheet.values = this.graphic_sheets.values
            this.$miVariableGlobalSheet.medical_act_id = null
        },
        setCurrentDateTime() {
            const now = new Date();
            const timezoneOffset = now.getTimezoneOffset() * 60000;
            const localDate = new Date(now.getTime() - timezoneOffset);
            this.$miVariableGlobalSheet.date = localDate.toISOString().split('T')[0];
            this.$miVariableGlobalSheet.hour = now.toTimeString().split(' ')[0];
            this.$miVariableGlobalSheet.doctor_name = this.$store.getters.get__user.name + ' ' + this.$store.getters.get__user.last_name;
            this.$miVariableGlobalSheet.user_id = this.$store.getters.get__user.id;
            this.$miVariableGlobalSheet.medical_act_id = this.medical_act_id;

            this.$miVariableGlobalSheet_plantilla.date = localDate.toISOString().split('T')[0];
            this.$miVariableGlobalSheet_plantilla.hour = now.toTimeString().split(' ')[0];
            this.$miVariableGlobalSheet_plantilla.doctor_name = this.$store.getters.get__user.name + ' ' + this.$store.getters.get__user.last_name;
            this.$miVariableGlobalSheet_plantilla.user_id = this.$store.getters.get__user.id;
            this.$miVariableGlobalSheet_plantilla.medical_act_id = this.medical_act_id;
        },
        mtdstoredata: function () {
            this.mtdhidde()
            this.mtdday()
            this.setCurrentDateTime();
            this.mtdGetSheets();
            this.mtdsheetday();
            //this.mtdsheetdata();
            this.$emit('mtdaddsheetexit')
        },
        mtdGetSheets: function () {
            this.get({
                url: this.$store.getters.get__url + "/Hospitalization/indexSheet/" + this.medical_act_id,
                token: this.$store.getters.get__token,
            }).then((response) => {
                if (response.statusCode === 200) {
                    this.graficData = response.data;
                    let dataSheets = response.data;
                    this.sheets = []
                    dataSheets.forEach(element => {
                        let sheetdetails = {
                            doctor_name: element.nurse.name,
                            date_name: element.datename,
                            id: element.id,
                            medical_act_id: element.medical_act_id,
                            date: element.date,
                            hour: element.hour,
                            day: element.day,
                            day_po: element.day_po,
                            weight: element.weight,
                            deposition: element.deposition,
                            urine: element.urine,
                            hydric_balance: element.hydric_balance,
                            values: JSON.parse(element.values)
                        }
                        this.sheets.push(sheetdetails)
                    });
                    this.graphic_sheets = this.$miVariableGlobalSheet
                    this.sheets.push(this.graphic_sheets);
                    if (this.graficData.length == 0) {
                        }else{
                            this.mtdsheetdata();
                        }
                   /*  this.pos = this.sheets.length - 1
                    if (this.dete_out ||this.rolname !='ENFERMERA(O)' || this.rolname !='DOCTOR(A)' || this.dete_out_doctor) {
                        this.sheets.pop();
                        if (this.sheets.length > 0) {
                            this.backPage()
                        }
                    } */
                }
            });
        },
        mtdday: function () {
            this.get({
                url: this.$store.getters.get__url + "/Hospitalization/day/" + this.medical_act_id,
                token: this.$store.getters.get__token,
            }).then((response) => {
                if (response.statusCode === 200) {
                    let day = response.data.hospitalizacion.date_entry;
                    let currentDate = new Date();
                    let entryDate = new Date(day);
                    currentDate.setHours(0, 0, 0, 0);
                    entryDate.setHours(0, 0, 0, 0);
                    let differenceInMilliseconds = currentDate - entryDate;
                    let differenceInDays = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));
                    this.graphic_sheets.day = differenceInDays + 1;
                    this.$miVariableGlobalSheet.day = this.graphic_sheets.day
                    this.$miVariableGlobalSheet_plantilla.day = this.graphic_sheets.day
                }
            });
        },
        mtdsheetday: function () {
            this.get({
                url: this.$store.getters.get__url + "/Hospitalization/sheetday/" + this.medical_act_id,
                token: this.$store.getters.get__token,
            }).then((response) => {
                if (response.statusCode === 200) {
                    this.datatime = response.data.dates;
                }
            });
        },
        mtdsheetdata: function () {
            this.get({
                url: this.$store.getters.get__url + "/Hospitalization/sheetdata/" + this.medical_act_id,
                token: this.$store.getters.get__token,
            }).then((response) => {
                if (response.statusCode === 200) {
                   /*  //PAS
                    this.series_pas = response.data.data_pas.original;
                    //PAD
                    this.series_pad = response.data.data_pad.original;
                    this.annotations_pad = response.data.annotations_pad.original;
                    response.data.categories_pad.forEach(item => {
                        this.categories_pad.push(item);
                    });
                    //FC
                    this.series_fc = response.data.data_fc.original;
                    this.annotations_fc = response.data.annotations_fc.original;
                    response.data.categories_fc.forEach(item => {
                        this.categories_fc.push(item);
                    });
                    //FR
                    this.series_fr = response.data.data_fr.original;
                    this.annotations_fr = response.data.annotations_fr.original;
                    response.data.categories_fr.forEach(item => {
                        this.categories_fr.push(item);
                    });
                    //T°
                    this.series_t = response.data.data_t.original;
                    this.annotations_t = response.data.annotations_t.original;
                    response.data.categories_t.forEach(item => {
                        this.categories_t.push(item);
                    });
                    //SAT
                    this.series_sat = response.data.data_sat.original;
                    this.annotations_sat = response.data.annotations_sat.original;
                    response.data.categories_sat.forEach(item => {
                        this.categories_sat.push(item);
                    });
                    // */
                    //this.updateChartOptions();
                    const grafig = response.data.grafig;
                    this.grafigPages = Object.values(grafig);

                }
            });
        },

        nextChart(type) {
            if (type === 'pas' && this.activePasIndex < this.chartsPas.length - 1) {
                this.activePasIndex++;
            } else if (type === 'sat' && this.activeSatIndex < this.chartsSat.length - 1) {
                this.activeSatIndex++;
            } else if (type === 'fc' && this.activeFcIndex < this.chartsFc.length - 1) {
                this.activeFcIndex++;
            } else if (type === 'fr' && this.activeFrIndex < this.chartsFr.length - 1) {
                this.activeFrIndex++;
            } else if (type === 't' && this.activeTIndex < this.chartsT.length - 1) {
                this.activeTIndex++;
            }
        },
        prevChart(type) {
            if (type === 'pas' && this.activePasIndex > 0) {
                this.activePasIndex--;
            } else if (type === 'sat' && this.activeSatIndex > 0) {
                this.activeSatIndex--;
            } else if (type === 'fc' && this.activeFcIndex > 0) {
                this.activeFcIndex--;
            } else if (type === 'fr' && this.activeFrIndex > 0) {
                this.activeFrIndex--;
            } else if (type === 't' && this.activeTIndex > 0) {
                this.activeTIndex--;
            }
        },

        updateChartOptions() {
            this.chartOptionsPad = {
                ...this.chartOptionsPad,
                xaxis: {
                    ...this.chartOptionsPad.xaxis,
                    categories: this.categories_pad
                },
                annotations: {
                    xaxis: this.annotations_pad
                }
            };
            this.chartOptionsFc = {
                ...this.chartOptionsFc,
                xaxis: {
                    ...this.chartOptionsFc.xaxis,
                    categories: this.categories_fc
                },
                annotations: {
                    xaxis: this.annotations_fc
                }
            };
            this.chartOptionsFr = {
                ...this.chartOptionsFr,
                xaxis: {
                    ...this.chartOptionsFr.xaxis,
                    categories: this.categories_fr
                },
                annotations: {
                    xaxis: this.annotations_fr
                }
            };
            this.chartOptionsT = {
                ...this.chartOptionsT,
                xaxis: {
                    ...this.chartOptionsT.xaxis,
                    categories: this.categories_t
                },
                annotations: {
                    xaxis: this.annotations_t
                }
            };
            this.chartOptionsSat = {
                ...this.chartOptionsSat,
                xaxis: {
                    ...this.chartOptionsSat.xaxis,
                    categories: this.categories_sat
                },
                annotations: {
                    xaxis: this.annotations_sat
                }
            };
        },
        validateDecimal: function (evt) {
            evt = evt ? evt : window.event;
            var charCode = evt.which ? evt.which : evt.keyCode;
            if (
                charCode > 31 &&
                (charCode < 48 || charCode > 57) &&
                charCode !== 46
            ) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
       /*  nextPage: function () {
            this.pos = (this.pos == this.sheets.length - 1) ? this.pos : this.pos + 1
            this.graphic_sheets = this.sheets[this.pos]
            this.validation = this.graphic_sheets.date_name != 'GRÁFICA ACTUAL'
        },
        backPage: function () {
            this.pos = (this.pos == 0) ? this.pos : this.pos - 1
            this.graphic_sheets = this.sheets[this.pos]
            this.validation = this.graphic_sheets.date_name != 'GRÁFICA ACTUAL'
        }, */
        
        backPage() {
            if (this.currentPage > 0) {
                this.currentPage--;
            }
        },
        nextPage() {
            if (this.currentPage < this.grafigPages.length - 1) {
                this.currentPage++;
            }
        },

        //Pdf
        pdfGrafica(){
            window.open(
                this.$store.getters.get__url + "/Hospitalization/viewpdfGrafica/" + this.medical_act_id,
                "_blank"
            );
        }

    }
}
</script>

<style>
.table-responsive-graph {
    position: relative;
    overflow-x: auto;
    overflow-y: hidden;
}

#pas thead tr th {
    background-color: #A3B8F5;
}

#pad thead tr th {
    background-color: #62CDFF;
}

#t thead tr th {
    background-color: #FF818B;
}

#sat thead tr th {
    background-color: #DB81FF;
}

#fr thead tr th {
    background-color: #FFBC81;
}

#fc thead tr th {
    background-color: #81FFBA;
}

.scroll-graph {
    width: 100%;
    overflow-x: auto;
    white-space: nowrap;
}

.content-graph {
    display: inline-block;
    min-width: 1000px;
}

.ancho {
    width: 100% !important;
    height: auto;
    overflow-x: auto; 
}

.img-chart {
    width: 1220px;
    height: auto;     
    margin: 0 auto;  
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
