<template>
    <CModal @mtdclosemodal="mtdclosemodal" :show="boo_modal" :close-on-backdrop="false" :centered="false" :title="title" :size="'xl'" :btn_cancel="true" :btn_ok="true" :booTitile="true">
        <div class="row mx-0  px-2">
            <div class="mb-3 col-xl-6">
                <label for="exampleFormControlInput1" class="form-label">Nombre</label>
                <input v-model="data.nombre" type="text" class="form-control" id="exampleFormControlInput1" placeholder="Digite Nombre">
            </div>
            <div class="mb-3 col-xl-6">
                <label for="exampleFormControlInput2" class="form-label">Almacen</label>
                <v-select v-model="data.almacen"  :options="almacenes"  class="form-control" :reduce="(name) => name.id" label="name" placeholder="-- Seleccione uno --" required />
            </div>
            <div class="mb-3 col-xl-4">
                <label for="exampleFormControlInput3" class="form-label">Lote</label>
                <input v-model="data.lote" type="text" class="form-control" id="exampleFormControlInput3" placeholder="Digite Lote">
            </div>
            <div class="mb-3 col-xl-4">
                <label for="exampleFormControlInput4" class="form-label">Fecha de Vencimiento</label>
                <input v-model="data.fecha_vencimiento" type="date" class="form-control" id="exampleFormControlInput4" >
            </div>
            <div class="mb-3 col-xl-4">
                <label for="exampleFormControlInput5" class="form-label">Cantidad</label>
                <input v-model="data.cantidad" type="text" class="form-control" id="exampleFormControlInput5" placeholder="Digite Cantidad">
            </div>
        </div>
        <template #footer>
            <button @click="mtdclosemodal" class="btn btn-danger btn-sm">
                <i class="fa fa-times" />&nbsp;Cerrar
            </button>
            <button :disabled="mtddisable" @click="addProduct" class="btn btn-success btn-sm"   >
               Guardar
            </button>
            &nbsp;
        </template>
        <SweetAlert :swal="swal" ref="SweetAlert" />
    </CModal>
    </template>
    
    <script>
    import CModal from "@/components/shared/modals/CModal.vue";
    import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
    import {
        mapActions
    } from "vuex";
    
    export default {
        name: "c-medico-modal-diagnostico",
    
        data() {
            return {  
                swal: null,  
                data:{
                    subsubcategoria_id:null,
                    product_id:null,
                    nombre:'',
                    almacen: null,
                    lote:'',
                    fecha_vencimiento:'',
                    cantidad:'',
                }
            }
        },
        components: {
            CModal,
            SweetAlert
        },
    
        
        computed: {
            mtddisable: function () {
                return ! (this.data.nombre && this.data.almacen && this.data.lote && this.data.fecha_vencimiento && this.data.cantidad)
            }
        },
        props: {
            title: String,
            boo_modal: Boolean,
            almacenes:{
                type: Array,
                default: () => []
            },
            ProductDetails:{
                type: Object,
                default: () => ({})
            }
        },
        methods: {
            ...mapActions(["get", "post"]),
            mtdclosemodal() {
                this.$emit('mtdclosemodalproduct')
                this.data ={
                    product_id:null,
                    nombre:'',
                    almacen: null,
                    lote:'',
                    fecha_vencimiento:'',
                    cantidad:'',
                    subsubcategoria_id:null,
                }
            },
            addProduct(){
                this.data.subsubcategoria_id = this.ProductDetails.sub_sub_category_id
                this.data.product_id = this.ProductDetails.id
                this.post({
                    url: this.$store.getters.get__url + "/Pharmacy/recipe/DispatchProduct",
                    token: this.$store.getters.get__token,
                    params: this.data
                })
                .then((response) => {
                    if (response.statusCode === 200) {
                        this.$refs.SweetAlert.showSuccess(response.message);
                        this.mtdclosemodal()
                    }
                    
                })
                .catch((errors) => {
                    this.$refs.SweetAlert.showError(errors);
                });
            }
            
           
        },
    }
    </script>
    <style lang="css" scoped>
    .v-select {
        padding: 0px
    }
    </style>