<template>
    <CModal @mtdclosemodal="mtdClose" :show="boo_modal" :close-on-backdrop="false" :centered="false" :title="title"
        :size="'xl'" :btn_cancel="true" :btn_ok="true" :booTitile="true">
        <div class="px-3">
            <div class="row mx-0 px-0 d-flex">
                <div class="col-lg-4 col-md-6">
                    <label for="start_time" class="mb-3 mt-2">HORA DE INICIO</label>
                    <div class="input-group input-group-sm">
                        <div class="input-group-text">
                            <span style="font-size: 20px; border: none" class="input-group-text">
                                <i class="fa fa-user-md"></i>
                            </span>
                        </div>
                        <input type="time" class="form-control" id="start_time" v-model="data_detail.start_time" />
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <label for="end_time" class="mb-3 mt-2">HORA FINAL</label>
                    <div class="input-group input-group-sm">
                        <div class="input-group-text">
                            <span style="font-size: 20px; border: none" class="input-group-text">
                                <i class="fa fa-user-md"></i>
                            </span>
                        </div>
                        <input type="time" class="form-control" id="end_time" v-model="data_detail.end_time" />
                    </div>
                </div>
                <!-- <div class="col-lg-4 col-md-6">
                    <label for="turn" class="mb-3 mt-2">TURNO</label>
                    <div class="input-group input-group-sm">
                        <div class="input-group-text">
                            <span style="font-size: 20px; border: none" class="input-group-text">
                                <i class="fa fa-user-md"></i>
                            </span>
                        </div>
                        <select class="form-control" id="turn" v-model="data_detail.turn">
                            <option value="" disabled>Selecciona un turno...</option>
                            <option value="MAÑANA">MAÑANA</option>
                            <option value="TARDE">TARDE</option>
                            <option value="NOCHE">NOCHE</option>
                        </select>
                    </div>
                </div> -->
                <div class="col-lg-4 col-md-6">
                    <label for="turno" class="mb-3 mt-2">TURNO</label>
                    <div class="input-group input-group-sm">
                        <div class="input-group-text">
                            <span style="font-size: 20px; border: none" class="input-group-text">
                                <i class="fa fa-user-md"></i>
                            </span>
                        </div>
                        <input type="text" class="form-control" id="turno" placeholder="Turno"
                            v-model="data_detail.turn" />
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 mt-5" v-if="num === 1">
                    <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked"
                            v-model="data_detail.state" :checked="data_detail.state == 1 ? true : false"
                            aria-checked="false" />
                        <label class="form-check-label" for="flexSwitchCheckChecked">
                            {{ data_detail.state == 1 ? 'Activo' : 'Inactivo' }}
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <template #footer>
            <button class="btn btn-success btn-sm" @click="validateData" :disabled="cpButton">
                {{ (num == 0) ? 'Registrar' : 'Editar' }}
            </button>
            &nbsp;
        </template>
        <SweetAlert :swal="swal" ref="SweetAlert" />
    </CModal>
</template>

<script>
import CModal from "../../shared/modals/CModal.vue";
import SweetAlert from "@/components/shared/SweetAlert/SweetAlert.vue";
import {
    mapActions
} from "vuex";

export default {
    name: "c-maintenance-modal-position",
    components: {
        CModal,
        SweetAlert
    },
    data() {
        return {
            data_detail: {
                start_time: "",
                end_time: "",
                turn: "",
                state: "",
            },
            swal: null,
        };
    },
    watch: {
        payload: {
            handler(newVal) {
                if (this.num == 1) {
                    if (newVal) {
                        this.data_detail = {
                            ...newVal
                        };
                    }
                }
            },
            immediate: true,
        },
    },
    props: {
        title: String,
        boo_modal: Boolean,
        num: Number,
        payload: {
            type: Object,
            default: null
        },
    },
    computed: {
        cpButton() {
            return !(this.data_detail.start_time != '' && this.data_detail.end_time != '' && this.data_detail.turn != '');
        }
    },
    methods: {
        ...mapActions(["get", "post"]),
        mtdClose() {
            this.data_detail = {
                start_time: "",
                end_time: "",
                turn: "",
                state: "",
            };
            this.$emit("closeModal");
        },
        validateData() {
            const startTime = this.data_detail.start_time;
            const endTime = this.data_detail.end_time;

            //if (startTime && endTime && startTime > endTime) {
                //this.$refs.SweetAlert.showError("La hora de inicio no puede ser mayor a la de fin.");
            //} else {
                this.DataDetail();
            //}
        },
        DataDetail() {
            this.$emit('Datadetail', this.data_detail, this.num)
            this.mtdClose();
        },
        filterKeyLetter: function (e) {
            let b = false;
            b = /^[a-zA-ZÀ-ÿ\s]$/.test(e.key);
            if (b == false && e.key != "Backspace") {
                e.preventDefault();
            }
        },
    },

};
</script>