<template>
    <CModal @mtdclosemodal="mtdClose" :show="boo_modal" :close-on-backdrop="false" :centered="false" :title="title" :size="'xl'" :btn_cancel="true" :btn_ok="true" :booTitile="true">
       
        <div class="row d-flex mt-2 mb-3" >
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <button
                  type="button"
                  class="bg-main text-white btn me-2 custom-btn" @click="pdfRecipeSinStock()"
                >
                  <i class="fas fa-file-pdf"></i> Exportar a PDF
                </button>
            </div>
        </div>
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="col-md-12 table-responsive" id="div-tabla-anular">
                    <table id="tb-anular" class="table table-bordered">
                        <caption class="mx-0 px-0"></caption>
                        <thead class="bg-main" style="font-size: 13px">
                            <tr class="text-white text-center align-middle">
                                <th scope="col">#</th>
                                <th scope="col">VIA</th>
                                <th scope="col">DOSIS</th>
                                <th scope="col">MOLECULA</th>
                                <th scope="col">PRESENTACIÓN</th>
                                <th scope="col" style="white-space: nowrap;">CANTIDAD</th>
                            </tr>
                        </thead>
                        <tbody class="border-main" style="font-size: 12px">
                            <tr v-for="(det, index) in cpData" :key="index" class="text-center align-middle">
                            <template>
                                <td>{{ index + 1 }}</td>
                                <td>{{ det.via}}</td>
                                <td>{{ det.dosis}}</td>
                                <td>{{ det.molecule}}</td>
                                <td>{{ det.presentation}}</td>
                                <td>{{ det.cantidadDet}}</td>
                            </template>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </CModal>
</template>

<script>
import CModal from "../../shared/modals/CModal.vue";
import {
    mapActions
} from "vuex";

export default {
    name: "c--modal-recetasSinStock",
    components: {
        CModal,
    },
    data() {
        return {
            tabla: [],
        };
    },
    watch: {
    },
    props: {
        title: String,
        boo_modal: Boolean,
        idReci: Number,
        payload: {
            type: Array,
            default: () => []
        },
    },
    computed: {
        cpData() {
        let show = [];
            this.payload.forEach((element) => {
                show.push(element);
            });
            this.tabla = show;
            return show;
        },
    },
    methods: {
        ...mapActions(["get", "post"]),
        mtdClose() {
            this.$emit("closeModal");
        },

        pdfRecipeSinStock() {
            window.open(
                  this.$store.getters.get__url +
                  "/viewpdfMedicalRecipes/" +
                  this.idReci,
                  "_blank"
              );
        }
    },
    

};
</script>
<style>
.custom-btn {
    padding: 4px 10px;
    font-size: 14px;
  }
</style>