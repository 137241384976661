<template lang="es">
<CModal class="bg-white" @mtdclosemodal="mtdclosemodal" :show="boo_modal" :close-on-backdrop="false" :centered="true" :title="title" :size="'xl'" :btn_cancel="true" :btn_ok="true" :booTitile="true">
    <div class="row d-flex">
        <!--BOTONES-->
        <div class="col-md-12 col-lg-12 col-xs-12 my-3">
            <div class="row d-flex justify-content-center">
                <div class="col-md-4 col-lg-4 col-sm-4">
                    <button class="w-100 h-100 rounded" :class="type == 'doctor' ? 'bg-main text-white border-main' : 'bg-white color-main border-light'" @click="activarTipo('doctor')">
                        <div>RH DOCTOR</div>
                    </button>
                </div>
                <div class="col-md-4 col-lg-4 col-sm-4">
                    <button class="w-100 h-100 rounded" :class="type == 'servicio' ? 'bg-main text-white border-main' : 'bg-white color-main border-light'" @click="activarTipo('servicio')">
                        <div>RH SERVICIO</div>
                    </button>
                </div>
            </div>
        </div>
        <!--FIN BOTONES-->

        <!--PARA DOCTOR-->
        <div v-show="type == 'doctor'">
            <div class="row d-flex">
                <div class="col-md-12 col-lg-6 col-sm-12 col-xs-12">
                    <div class="input-group input-group-sm mb-3">
                        <span class="input-group-text col-md-3" id="serie">N° Comprobante</span>
                        <input type="text" class="form-control" placeholder="N° Recibo" aria-describedby="num_document" v-model="egreso_edit.serie" />
                    </div>
                </div>

                <div class="col-md-12 col-lg-6 col-sm-12 col-xs-12">
                    <div class="input-group input-group-sm mb-3">
                        <div class="input-group-prepend">
                            <div class="input-group-text">
                                <input type="radio" aria-label="Checkbox for selecting RUC" v-model="egreso_edit.type_document" value="RUC" />
                                <label for="rucCheckbox">RUC</label>
                            </div>
                        </div>
                        <div class="input-group-prepend">
                            <div class="input-group-text">
                                <input type="radio" aria-label="Checkbox for selecting DNI" v-model="egreso_edit.type_document" value="DNI" />
                                <label for="dniCheckbox">DNI</label>
                            </div>
                        </div>
                        <input type="text" class="form-control" placeholder="RUC/DNI" aria-describedby="document" required v-model="egreso_edit.document" @keyup="mtdSearchDocument" />
                    </div>
                </div>

                <div class="col-md-12 col-lg-6 col-sm-12 col-xs-12">
                    <div class="input-group input-group-sm mb-3">
                        <span class="input-group-text col-md-3" id="company">Nombre Personal</span>
                        <input disabled type="text" class="form-control" placeholder="Nombres y Apellidos" aria-describedby="name" required v-model="egreso_edit.company" />
                    </div>
                </div>

                <div class="col-md-12 col-lg-6 col-sm-12 col-xs-12">
                    <div class="input-group input-group-sm mb-3">
                        <span class="input-group-text col-md-3" id="patient_id">Paciente</span>
                        <v-select :options="patients" class="form-control" :reduce="(name) => name.id" label="name" placeholder="-- Seleccione uno --" v-model="egreso_edit.patient_id" />
                    </div>
                </div>

                <div class="col-md-12 col-lg-6 col-sm-12 col-xs-12">
                    <div class="input-group input-group-sm mb-3">
                        <span class="input-group-text col-md-3">Fecha Turno</span>
                        <input type="date" aria-label="date" class="form-control form-control-sm" required v-model="egreso_edit.shift_date" />
                    </div>
                </div>

                <div class="col-md-12 col-lg-6 col-sm-12 col-xs-12">
                    <div class="input-group input-group-sm mb-3">
                        <span class="input-group-text col-md-3" id="shift">Turno</span>
                        <v-select :options="shifts" class="form-control" :reduce="(name) => name.id" label="name" placeholder="-- Seleccione uno --" v-model="egreso_edit.shift" />
                    </div>
                </div>

                <div class="col-md-12 col-lg-6 col-sm-12 col-xs-12">
                    <div class="input-group input-group-sm mb-3">
                        <span class="input-group-text col-md-3" id="category">Categoría</span>
                        <v-select :options="categories" class="form-control" :reduce="(name) => name.id" label="name" placeholder="-- Seleccione uno --" v-model="egreso_edit.category" />
                    </div>
                </div>

                <div class="col-md-12 col-lg-6 col-sm-12 col-xs-12">
                    <div class="input-group input-group-sm mb-3">
                        <span class="input-group-text col-md-3">Monto</span>
                        <input type="text" aria-label="total" placeholder="0.00" class="form-control form-control-sm" required v-on:keypress="isNumber($event)" v-model="egreso_edit.total" disabled />
                    </div>
                </div>

                <div class="col-md-12 col-lg-6 col-sm-12 col-xs-12">
                    <div class="input-group input-group-sm mb-3">
                        <span class="input-group-text col-md-3">Monto Pagado</span>
                        <input type="text" aria-label="amount" placeholder="0.00" class="form-control form-control-sm" v-on:keypress="isNumber($event)" required @blur="mtdCalculate" v-model="egreso_edit.amount" disabled />
                    </div>
                </div>

                <div class="col-md-12 col-lg-6 col-sm-12 col-xs-12">
                    <div class="input-group input-group-sm mb-3">
                        <span class="input-group-text col-md-3">Fecha Pagada</span>
                        <input type="date" aria-label="date" class="form-control form-control-sm" required disabled v-model="egreso_edit.date" />
                    </div>
                </div>

                <div class="col-md-12 col-lg-6 col-sm-12 col-xs-12">
                    <div class="input-group input-group-sm mb-3">
                        <input @change="getFileCo" type="file" class="form-control" id="fileCo" lang="es" name="fileCo" ref="fileInputCo" />
                    </div>
                </div>

                <div class="col-md-12 col-lg-6 col-sm-12 col-xs-12">
                    <div class="input-group input-group-sm mb-3">
                        <span class="input-group-text col-md-3" id="description">Concepto</span>
                        <textarea type="text" class="form-control" aria-label="Sizing example input" placeholder="Motivo, descripción o detalle" aria-describedby="description" v-model="egreso_edit.description"></textarea>
                    </div>
                </div>
            </div>
        </div>

        <!--PARA SERVICIO-->
        <div v-show="type == 'servicio'">
            <div class="row">
                <div class="col-md-12 col-lg-6 col-sm-12 col-xs-12">
                    <div class="input-group input-group-sm mb-3">
                        <span class="input-group-text col-md-3" id="serie">N° Comprobante</span>
                        <input type="text" class="form-control" placeholder="N° Recibo" aria-describedby="num_document" v-model="egreso_edit.serie" />
                    </div>
                </div>

                <div class="col-md-12 col-lg-6 col-sm-12 col-xs-12">
                    <div class="input-group input-group-sm mb-3">
                        <div class="input-group-prepend">
                            <div class="input-group-text">
                                <input type="radio" aria-label="Checkbox for selecting RUC" v-model="egreso_edit.type_document" value="RUC" />
                                <label for="rucCheckbox">RUC</label>
                            </div>
                        </div>
                        <div class="input-group-prepend">
                            <div class="input-group-text">
                                <input type="radio" aria-label="Checkbox for selecting DNI" v-model="egreso_edit.type_document" value="DNI" />
                                <label for="dniCheckbox">DNI</label>
                            </div>
                        </div>
                        <input type="text" class="form-control" placeholder="RUC/DNI" aria-describedby="document" required v-model="egreso_edit.document" @keyup="mtdSearchDocument" />
                    </div>
                </div>

                <div class="col-md-12 col-lg-6 col-sm-12 col-xs-12">
                    <div class="input-group input-group-sm mb-3">
                        <span class="input-group-text col-md-3" id="company">Nombre Personal</span>
                        <input disabled type="text" class="form-control" placeholder="Nombres y Apellidos" aria-describedby="name" required v-model="egreso_edit.company" />
                    </div>
                </div>

                <div class="col-md-12 col-lg-6 col-sm-12 col-xs-12">
                    <div class="input-group input-group-sm mb-3">
                        <span class="input-group-text col-md-3">Monto</span>
                        <input type="text" aria-label="total" placeholder="0.00" class="form-control form-control-sm" required v-on:keypress="isNumber($event)" v-model="egreso_edit.total" disabled />
                    </div>
                </div>

                <div class="col-md-12 col-lg-6 col-sm-12 col-xs-12">
                    <div class="input-group input-group-sm mb-3">
                        <span class="input-group-text col-md-3">Monto Pagado</span>
                        <input type="text" aria-label="amount" placeholder="0.00" class="form-control form-control-sm" v-on:keypress="isNumber($event)" required @blur="mtdCalculate" v-model="egreso_edit.amount" disabled />
                    </div>
                </div>

                <div class="col-md-12 col-lg-6 col-sm-12 col-xs-12">
                    <div class="input-group input-group-sm mb-3">
                        <span class="input-group-text col-md-3">Fecha Pagada</span>
                        <input type="date" aria-label="date" class="form-control form-control-sm" required disabled v-model="egreso_edit.date" />
                    </div>
                </div>

                <div class="col-md-12 col-lg-6 col-sm-12 col-xs-12">
                    <div class="input-group input-group-sm mb-3">
                        <input @change="getFileCo" type="file" class="form-control" id="fileCo" lang="es" name="fileCo" ref="fileInputCo" />
                    </div>
                </div>

                <div class="col-md-12 col-lg-6 col-sm-12 col-xs-12">
                    <div class="input-group input-group-sm mb-3">
                        <span class="input-group-text col-md-3" id="description">Concepto</span>
                        <textarea type="text" class="form-control" aria-label="Sizing example input" placeholder="Motivo, descripción o detalle" aria-describedby="description" v-model="egreso_edit.description"></textarea>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <template #footer>
        <button @click="mtdclosemodal" color="danger" class="btn btn-danger btn-sm">
            <i class="fa fa-times" />&nbsp;Cerrar
        </button>
        &nbsp;
        <button class="btn btn-success btn-sm" @click="mtdCommitReceiptSer">
            <i class="fas fa-save" />&nbsp; Registrar
        </button>
        &nbsp;
    </template>
    <SweetAlert :swal="swal" ref="SweetAlert" />
</CModal>
</template>

<script>
import CModal from "@/components/shared/modals/CModal.vue";
import SweetAlert from "@/components/shared/SweetAlert/SweetAlert.vue";
import {
    mapActions
} from "vuex";

export default {
    name: "c-jefatura-convertirRH",
    components: {
        CModal,
        SweetAlert
    },
    data() {
        return {
            swal: null,
            type: "",
            shifts: [{
                    id: 1,
                    name: "6 Horas"
                },
                {
                    id: 2,
                    name: "12 Horas"
                },
                {
                    id: 3,
                    name: "24 Horas"
                },
            ],
            egreso_edit: {
                idRe: "",
                id: "",
                campu_id: null,
                type_document: "",
                document_type_id: null,
                date: "",
                date_doc: "",
                serie: "",
                number: "",
                document: "",
                company: "",
                total: 0.0,
                amount: 0.0,
                pending: 0.0,
                method: "",
                description: "",
                state: "",
                file: "",
                user: "",
                //RECEIPT
                patient_id: null,
                shift_date: null,
                shift: null,
                category: null,
                //FIN RECEIPT
                user_autorize: "",
            },
        };
    },
    props: {
        title: String,
        boo_modal: Boolean,
        egreso: {},
        patients: [],
        categories: []

    },
    computed: {

    },
    watch: {
        egreso: {
            handler(newVal) {
                if (newVal) {
                    
                        this.egreso_edit = {
                            ...newVal
                        };
                    

                }
            },
            immediate: true,
        },
    },
    methods: {
        ...mapActions(["get", "post"]),
        activarTipo: function(type){
            this.type = type;
        },
        /** helpers */
        isNumber: function (evt) {
            evt = evt ? evt : window.event;
            var charCode = evt.which ? evt.which : evt.keyCode;
            if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        getFileRe(e) {
            let fileInputRe = this.$refs.fileInputRe;
            let file = e.target.files[0];
            if (
                !/\.(pdf)$/i.test(file.name) &&
                !/\.(png)$/i.test(file.name) &&
                !/\.(jpg)$/i.test(file.name) && !/\.(jpeg)$/i.test(file.name)
            ) {
                this.$refs.SweetAlert.showInfo('Seleccione un archivo formato PDF, PNG O JPG');
                fileInputRe.value = "";
            } else {
                this.egreso_edit.file = file;
            }
        },
        mtdSearchDocument: function () {
            if (!this.egreso_edit.type_document) {
                this.$refs.SweetAlert.showWarning("Seleccione RUC  o DNI");
            } else {
                if (this.egreso_edit.document.length === 8 && this.egreso_edit.type_document == "DNI") {
                    this.get({
                            url: this.$store.getters.get__url + "/reniec/" + this.egreso_edit.document,
                            token: this.$store.getters.get__token,
                        })
                        .then((response) => {

                            if (response.data.boo == 3) {
                                this.egreso_edit.document = "";
                                this.$refs.SweetAlert.showWarning("DNI no encontrado");
                            } else {
                                this.egreso_edit.company = response.data.name;
                            }
                        })
                        .catch((errors) => {
                            console.log(errors)
                        });
                } else {
                    if (this.egreso_edit.document.length === 11 && this.egreso_edit.type_document == "RUC") {
                        this.get({
                                url: this.$store.getters.get__url + "/reniec/" + this.egreso_edit.document,
                                token: this.$store.getters.get__token,
                            })
                            .then((response) => {

                                if (response.data.boo == 3) {
                                    this.client.document = "";
                                    this.$refs.SweetAlert.showWarning("RUC no encontrado");
                                } else {
                                    this.egreso_edit.company = response.data.razon_social;
                                }
                            })
                            .catch((errors) => {
                                console.log(errors)
                            });
                    }
                }
            }
        },
        mtdCalculate: function () {
            this.egreso_edit.pending =
                parseFloat(this.egreso_edit.total).toFixed(2) -
                parseFloat(this.egreso_edit.amount).toFixed(2);
        },
        mtdCommitReceiptSer: function () {
            let formData = new FormData();
            formData.append("campu_id", this.$store.getters.get__campus);
            formData.append("id", this.egreso_edit.id);
            formData.append("bead_id", this.egreso_edit.bead_id);
            formData.append("document_type_id", this.egreso_edit.document_type_id);
            formData.append("date", this.egreso_edit.date);
            formData.append("date_doc", this.egreso_edit.date_doc);
            formData.append("serie", this.egreso_edit.serie);
            formData.append("number", this.egreso_edit.number);
            formData.append("document", this.egreso_edit.document);
            formData.append("company", this.egreso_edit.company);
            formData.append("total", this.egreso_edit.total);
            formData.append("amount", this.egreso_edit.amount);
            formData.append("pending", this.egreso_edit.pending);
            formData.append("method", this.egreso_edit.method);
            formData.append("description", this.egreso_edit.description);
            formData.append("state", 2);
            formData.append("file", this.egreso_edit.file);
            formData.append("user", this.egreso_edit.user);
            //Lo de Recipt
            formData.append("user_autorize", this.egreso_edit.user_autorize);

            this.post({
                    url: this.$store.getters.get__url + "/Jefatura/receipt/store",
                    token: this.$store.getters.get__token,
                    params: formData,
                })
                .then((response) => {
                    if (response.statusCode == 200) {
                        this.type = "";
                        this.$refs.SweetAlert.showSuccess('Se cambió con éxito a RH');
                        this.mtdclosemodal(0);
                    }
                })
                .catch((errors) => {
                    console.log(errors);
                });
        },
        getFileCo(e) {
            let fileInputCo = this.$refs.fileInputCo;
            let file = e.target.files[0];
            if (
                !/\.(pdf)$/i.test(file.name) &&
                !/\.(png)$/i.test(file.name) &&
                !/\.(jpg)$/i.test(file.name) && !/\.(jpeg)$/i.test(file.name)
            ) {
                this.$refs.SweetAlert.showInfo('Seleccione un archivo formato PDF, PNG O JPG');
                fileInputCo.value = "";
            } else {
                this.egreso_edit.file = file;
            }
        },
        mtdclosemodal(opt) {
            this.type = "";
            this.$emit('mtdclosemodal',opt);
        },
        mtdstoredata() {
            this.$emit('mtdstoredata')
        }
    },
};
</script>

<style scoped>
.inpt_color {
    text-align: center !important;
    background-color: cadetblue !important;
    border-radius: 20px;
    color: white;
}

.inpt_color::placeholder {
    color: white;
}
</style>
