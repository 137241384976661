<template>
<div>
    <div class="w-100 h-100">
        <Nav :rolname="rolname" />
    </div>
    <div class="content m-auto">
        <Home v-if="page == 'home'" @mtdChangePage="mtdChangePage" @mtdBackCampus="mtdBackCampus" />
        <OC v-if="page == 'oc'" @returnHome="returnHome" @mtdChangePage="mtdChangePage" @mtdChangeFilter="mtdChangeFilter" />
        <Mantenimiento v-if="page == 'mantenimiento'" @returnHome="returnHome"/>
        <OS v-if="page == 'servicios'" @returnHome="returnHome" />
        <Factura v-if="page == 'factura'" @returnHome="returnHome" />
        <Compra v-if="page=='compra'" @returnHome="returnHome" />
        <Cxp v-if="page=='cxp'" @returnHome="returnHome" />
        <Inventario v-if="page == 'inventario'" @returnHome="returnHome" />
        <StockMin v-if="page=='stockmin'" @returnHome="returnHome" />
        <Side v-if="page == 'sire'" @returnHome="returnHome" />
        <Requerimiento v-if="page=='requerimiento'"  @returnHome="returnHome" />
        <HisMovimiento v-if="page == 'hisMovimiento'" @returnHome="returnHome" />
        <Movimiento v-if="page=='movimiento'" @returnHome="returnHome"/>
        <Kardex v-if="page == 'kardex'" @returnHome="returnHome" />
        <KardexInt v-if="page == 'kardexInterno'" @returnHome="returnHome" />
        <Verificacion v-if="page == 'verificacion'" @returnHome="returnHome" @mtdChangePage="mtdChangePage"/>
        <NotaIngreso v-if="page == 'notaIngreso'" @returnHome="returnHome" />
        <reportVerificacion v-if="page == 'repVerificacion'" @returnHome="returnHome" @mtdChangePage="mtdChangePage" />
    </div>
    <div class="footer">
        <Footer @mtdChangePage="mtdChangePage" :pageView="page" />
    </div>
</div>
</template>

<script>
import {
    mapActions
} from 'vuex'
import Nav from '../../components/Asistencial/Nav.vue'
import Home from "../../components/Logistica/Home.vue";
import Footer from "../../components/Logistica/Footer.vue";
import OC from "../../components/Logistica/pages/Ordendecompra.vue";
import Mantenimiento from "../../components/Logistica/pages/Mantenimiento.vue";
import OS from "../../components/Logistica/pages/Ordendeservicios.vue";
import Factura from "../../components/Logistica/pages/ReporteFactura.vue";
import Cxp from '@/components/Logistica/pages/Cxp.vue';
import Compra from '@/components/Logistica/pages/Compra.vue';
import HisMovimiento from "../../components/Logistica/pages/HisMovimiento.vue";
import Movimiento from '@/components/Logistica/pages/Movimiento.vue';
import Requerimiento from "@/components/Logistica/pages/Requerimiento.vue";
import Inventario from "../../components/Logistica/pages/Inventory.vue";
import StockMin from "@/components/Logistica/pages/Stockminimo.vue";
import Side from "../../components/Logistica/pages/Side.vue";
/* verificacion de stocks */
import Verificacion from "../../components/Logistica/pages/VerificacionStock.vue";
import reportVerificacion from "../../components/Logistica/pages/ReportVerificacionStock.vue";
import NotaIngreso from "../../components/Logistica/pages/NotaIngreso.vue";
//Kardex
import Kardex from "../../components/Logistica/pages/Kardex.vue";
import KardexInt from "../../components/Logistica/pages/KarInterno.vue";
export default {
    name: "LogisticaView",
    components: {
        Nav,
        Home,
        OS,
        OC,
        Mantenimiento,
        Inventario,
        Factura,
        Cxp,
        Compra,
        Requerimiento,
        HisMovimiento,
        Movimiento,
        Footer,
        StockMin,
        Side,
        Kardex,
        KardexInt,
        Verificacion,
        reportVerificacion,
        NotaIngreso
    },
    data() {
        return {
            prefix: 'logistica',
            page: 'home',
            filter: {},
        }
    },
    props: {
        rolname: String,
    },
    created() {
        this.rol_name = this.rolname;
    },
    methods: {
        ...mapActions(['get', 'post']),
        mtdChangePage: function (page) {
            this.page = page;
        },
        returnHome: function () {
            this.page = "home";
        },
        mtdBackCampus: function () {
            this.$emit("mtdBackCampus");
        },
        mtdChangeFilter: function (data) {
            this.filter = data;
        },
    }

}
</script>
