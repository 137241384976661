<template>
<div class="py-2">
    <div class="row mx-0">

        <div class="col-xl-8 col-lg-12  px-0 row mx-0  ">
            <div class="col-lg-3 col-md-12 border py-2 ">
                <div class=" div-text   fw-bold py-1 justify-content-center">
                    <span>REGISTRO DE EVALUACION ANESTECIA</span>
                </div>
                <div class="row mx-0 pt-1">
                    <div class="col-sm-3 div-text text-start px-0">Fecha:</div>
                    <div class="col-sm-9 px-0">
                        <input type="date" class="form-control form-control-input" autocomplete="off" />
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12 border py-2 px-1">
                <div class="row mx-0 pt-1">
                    <div class="col-sm-2 text-xxs text-start px-0"> Anastesiólogo:</div>
                    <div class="col-sm-6  ">
                        <input type="input" class="form-control form-control-input" autocomplete="off" />
                    </div>
                    <div class="col-sm-1 div-text text-start px-0">Cama:</div>
                    <div class="col-sm-3 px-0">
                        <input type="input" class="form-control form-control-input" autocomplete="off" />
                    </div>
                    <div class="col-sm-2 div-text text-start px-0 mt-1">Cirujanos:</div>
                    <div class="col-sm-10 pe-0 mt-1">
                        <input type="input" class="form-control form-control-input" autocomplete="off" />
                    </div>
                    <div class="col-sm-2 div-text text-start px-0 mt-1">Diagnóstico:</div>
                    <div class="col-sm-10 pe-0 mt-1">
                        <input type="input" class="form-control form-control-input" autocomplete="off" />
                    </div>
                    <div class="col-sm-2 div-text text-start px-0 mt-1">Cirugia:</div>
                    <div class="col-sm-10 pe-0 mt-1">
                        <input type="input" class="form-control form-control-input" autocomplete="off" />
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-12 border">
                <div class="row div-title div-text fw-bold py-1 justify-content-center">
                    <span>Premedicación</span>
                </div>

            </div>
            <div class=" row mx-0 px-1 pt-2 col-lg-7 col-md-12 border fw-bold">
                <div class="form-check col-sm-2 text-xxxxs  text-start  px-0">
                    <label class="form-check-label px-1  " for="flexCheckDefault">
                        Monitoreo
                    </label>
                </div>
                <div class="form-check col-sm-2 text-xxxxs  text-start  px-0">
                    <label class="form-check-label px-1" for="flexCheckDefault">
                        Sp02
                    </label>
                    <input class="form-check-input mx-0" type="checkbox" value="" id="flexCheckDefault">
                </div>
                <div class="form-check col-sm-3 text-xxxxs  text-start  px-0">
                    <label class="form-check-label px-1" for="flexCheckDefault1">
                        Capnografia
                    </label>
                    <input class="form-check-input mx-0" type="checkbox" value="" id="flexCheckDefault1">
                </div>
                <div class="form-check col-sm-4 text-xxxxs  text-start  px-0">
                    <label class="form-check-label px-1" for="flexCheckDefault2">
                        Analisis ST derivaciones
                    </label>
                    <input class="form-check-input mx-0" type="checkbox" value="" id="flexCheckDefault2">
                </div>
                <div class="form-check col-sm-1 text-xxxxs  text-start  px-0">
                    <label class="form-check-label px-1" for="flexCheckDefault3">
                        EKG
                    </label>
                    <input class="form-check-input mx-0" type="checkbox" value="" id="flexCheckDefault3">
                </div>

                <div class="form-check col-sm-2 text-xxxxs  text-start  px-0">
                    <label class="form-check-label px-1 px-1  " for="flexCheckDefault4">
                        PANI
                    </label>
                    <input class="form-check-input mx-0" type="checkbox" value="" id="flexCheckDefault4">
                </div>
                <div class="form-check col-sm-2 text-xxxxs  text-start px-0">
                    <label class="form-check-label px-1 text-start" for="flexCheckDefault5">
                        Respirometro
                    </label>
                    <input class="form-check-input mx-0" type="checkbox" value="" id="flexCheckDefault5">
                </div>
                <div class="form-check col-sm-3 text-xxxxs  text-start  px-0">
                    <label class="form-check-label px-1" for="flexCheckDefault6">
                        1° central/piel
                    </label>
                    <input class="form-check-input mx-0" type="checkbox" value="" id="flexCheckDefault6">
                </div>
                <div class="form-check col-sm-4 text-xxxxs  text-start  px-0">
                    <label class="form-check-label px-1" for="flexCheckDefault7">
                        Estimulador Nervio Periferico
                    </label>
                    <input class="form-check-input mx-0" type="checkbox" value="" id="flexCheckDefault7">
                </div>
                <div class="form-check col-sm-1 text-xxxxs  text-start  px-0">
                    <label class="form-check-label px-1" for="flexCheckDefault8">
                        BIS
                    </label>
                    <input class="form-check-input mx-0" type="checkbox" value="" id="flexCheckDefault8">
                </div>
                <div class="form-check col-sm-2 text-xxxxs  text-start  px-0">
                    <label class="form-check-label px-1 px-1  " for="flexCheckDefault9">
                        Dir. Arteria
                    </label>
                    <input class="form-check-input mx-0" type="checkbox" value="" id="flexCheckDefault9">
                </div>
                <div class="form-check col-sm-2 text-xxxxs  text-start px-0">
                    <label class="form-check-label px-1 text-start" for="flexCheckDefault10">
                        Diuresis
                    </label>
                    <input class="form-check-input mx-0" type="checkbox" value="" id="flexCheckDefault10">
                </div>
                <div class="form-check col-sm-3 text-xxxxs  text-start  px-0">
                    <label class="form-check-label px-1" for="flexCheckDefault11">
                        Gases Aspirados
                    </label>
                    <input class="form-check-input mx-0" type="checkbox" value="" id="flexCheckDefault11">
                </div>
                <div class="form-check col-sm-4 text-xxxxs  text-start  px-0">
                    <label class="form-check-label px-1" for="flexCheckDefault12">
                        PVC/Preciones Pulmonares
                    </label>
                    <input class="form-check-input mx-0" type="checkbox" value="" id="flexCheckDefault12">
                </div>
                <div class="form-check col-sm-1 text-xxxxs  text-start  px-0">
                    <label class="form-check-label px-1" for="flexCheckDefault13">
                        PIC
                    </label>
                    <input class="form-check-input mx-0" type="checkbox" value="" id="flexCheckDefault13">
                </div>

            </div>
            <div class=" row mx-0 px-1 fw-bold col-lg-5 col-md-12 pt-2 border">
                <div class="form-check col-sm-2 text-xxxxs  text-start  px-0">
                    <label class="form-check-label " for="flexCheckDefault">
                        Otros
                    </label>

                </div>
                <div class="form-check col-sm-2 text-xxxxs  text-start  px-0">
                    <label class="form-check-label px-1 px-1  " for="flexCheckDefault">
                        TEE
                    </label>
                    <input class="form-check-input mx-0" type="checkbox" value="" id="flexCheckDefault">
                </div>
                <div class="form-check col-sm-3 text-xxxxs  text-start px-0">
                    <label class="form-check-label px-1 text-start" for="flexCheckDefault14">
                        Calentador
                    </label>
                    <input class="form-check-input mx-0" type="checkbox" value="" id="flexCheckDefault14">
                </div>
                <div class="form-check col-sm-3 text-xxxxs  text-start  px-0">
                    <label class="form-check-label px-1" for="flexCheckDefault15">
                        GONI
                    </label>
                    <input class="form-check-input mx-0" type="checkbox" value="" id="flexCheckDefault15">
                </div>
                <div class="form-check col-sm-2 text-xxxxs  text-start  px-0">
                    <label class="form-check-label px-1" for="flexCheckDefault16">
                        DNI
                    </label>
                    <input class="form-check-input mx-0" type="checkbox" value="" id="flexCheckDefault16">
                </div>
                <div class="form-check col-sm-4 text-xxxxs  text-start  px-0">
                    <label class="form-check-label px-1 px-1  " for="flexCheckDefault17">
                        Manta conectiva
                    </label>
                    <input class="form-check-input mx-0" type="checkbox" value="" id="flexCheckDefault17">
                </div>
                <div class="form-check col-sm-3 text-xxxxs  text-start  px-0">
                    <label class="form-check-label px-1 px-1  " for="flexCheckDefault18">
                        Antiembolicas
                    </label>
                    <input class="form-check-input mx-0" type="checkbox" value="" id="flexCheckDefault18">
                </div>
                <div class="form-check col-sm-5 text-xxxxs  text-start  px-0">
                    <label class="form-check-label px-1 px-1  " for="flexCheckDefault19">
                        Potenciales Evocados
                    </label>
                    <input class="form-check-input mx-0" type="checkbox" value="" id="flexCheckDefault19">
                </div>
                <div class="form-check col-sm-7 text-xxxxs  text-start  px-0">
                    <label class="form-check-label px-1 px-1  " for="flexCheckDefault20">
                        Verificacion Puntod Presión/ Apoyo
                    </label>
                    <input class="form-check-input mx-0" type="checkbox" value="" id="flexCheckDefault20">
                </div>
                <div class="form-check col-sm-5 text-xxxxs  text-start  px-0">
                    <label class="form-check-label px-1 px-1  " for="flexCheckDefault21">
                        Protección ocular
                    </label>
                    <input class="form-check-input mx-0" type="checkbox" value="" id="flexCheckDefault21">
                </div>

            </div>
            <div class="row mx-0 px-0 div-title">
                <div class="col-sm-3 border fw-bold text-xxxxs"> EVENTO IDP </div>
                <div class="col-sm-1 border"></div>
                <div class="col-sm-8 border"></div>
            </div>
            <div class="row mx-0 px-0 ">
                <div class=" col-sm-3 px-0 border fw-bold ">
                    <div class=" text-xxxxs   px-2 text-start border px-0 mx-0  fw-bold py-1 justify-content-center">
                        <span>Ayunos Horas</span>
                    </div>
                    <div class=" text-xxxxs  px-2 text-start border px-0 mx-0  fw-bold py-1 justify-content-center">
                        <span>HGB Hcto</span>
                    </div>
                    <div class=" text-xxxxs px-2 text-start  border px-0 mx-0  fw-bold py-1 justify-content-center">
                        <span>VOLEMIA</span>
                    </div>
                    <div class=" text-xxxxs  px-2 text-start border px-0 mx-0  fw-bold py-1 justify-content-center">
                        <span>PPS Hto</span>
                    </div>
                    <div class=" div-text  px-2  px-0 mx-0  text-start fw-bold py-1 justify-content-center">
                        <span>TECNICA ANESTESICA</span>
                    </div>

                    <div class="form-check px-2 div-text text-start   px-0">
                        <label class="form-check-label px-1 px-1  " for="flexCheckDefault22">
                            General Inh.
                        </label>
                        <input class="form-check-input mx-0" type="checkbox" value="" id="flexCheckDefault22">
                    </div>
                    <div class="form-check px-2 div-text text-start   px-0">
                        <label class="form-check-label px-1 px-1  " for="flexCheckDefault23">
                            Intravenosa
                        </label>
                        <input class="form-check-input mx-0" type="checkbox" value="" id="flexCheckDefault23">
                    </div>
                    <div class="form-check px-2 div-text text-start   px-0">
                        <label class="form-check-label px-1 px-1  " for="flexCheckDefault24">
                            Peridural
                        </label>
                        <input class="form-check-input mx-0" type="checkbox" value="" id="flexCheckDefault24">
                    </div>
                    <div class="form-check px-2 div-text text-start   px-0">
                        <label class="form-check-label px-1 px-1  " for="flexCheckDefault25">
                            Espinal
                        </label>
                        <input class="form-check-input mx-0" type="checkbox" value="" id="flexCheckDefault25">
                    </div>
                    <div class="form-check px-2 div-text text-start   px-0">
                        <label class="form-check-label px-1 px-1  " for="flexCheckDefault26">
                            Aguja N°
                        </label>
                        <input class="form-check-input mx-0" type="checkbox" value="" id="flexCheckDefault26">
                    </div>
                    <div class="form-check px-2 div-text text-start   px-0">
                        <label class="form-check-label px-1 px-1  " for="flexCheckDefault27">
                            Catatar
                        </label>
                        <input class="form-check-input mx-0" type="checkbox" value="" id="flexCheckDefault27">
                    </div>
                    <div class="form-check px-2 div-text text-start   px-0">
                        <label class="form-check-label px-1 px-1  " for="flexCheckDefault28">
                            Nivel Puncion
                        </label>
                        <input class="form-check-input mx-0" type="checkbox" value="" id="flexCheckDefault28">
                    </div>
                    <div class="form-check px-2 div-text text-start   px-0">
                        <label class="form-check-label px-1 px-1  " for="flexCheckDefault29">
                            Nivel Anestecia
                        </label>
                        <input class="form-check-input mx-0" type="checkbox" value="" id="flexCheckDefault29">
                    </div>
                    <div class="form-check px-2 div-text text-start   px-0">
                        <label class="form-check-label px-1 px-1  " for="flexCheckDefault30">
                            Bloqueo
                        </label>
                        <input class="form-check-input mx-0" type="checkbox" value="" id="flexCheckDefault30">
                    </div>
                    <div class="form-check px-2 div-text text-start   px-0">
                        <label class="form-check-label px-1 px-1  " for="flexCheckDefault">
                            L. Controlada
                        </label>
                        <input class="form-check-input mx-0" type="checkbox" value="" id="flexCheckDefault">
                    </div>

                </div>
                <div class="col-sm-1  px-0">
                    <div class=" text-xxxxs  border px-0 mx-0  fw-bold py-1 justify-content-center">
                        <span>HORA</span>
                    </div>
                    <div v-for="(hora,index4) in horas" :key="'hour'+index4" class=" text-xxxxs border  fw-bold py-1 justify-content-center">
                        <span>{{ hora }}</span>
                    </div>

                </div>
                <div class=" row px-0 mx-0 col-sm-8 ">
                    <div v-for="int4 in 12" :key="int4" class="row col-sm-1 mx-0 px-0 ">
                        <div class=" col-sm-12 mx-0 px-0 border">

                        </div>
                        <div v-for="(hora,index) in horas" :key="int4+index" class=" col-sm-4 mx-0 px-0 border">

                        </div>
                        <div v-for="(hora1,index1) in horas" :key="'h1'+int4+index1" class=" col-sm-4 mx-0 px-0 border">

                        </div>
                        <div v-for="(hora2,index2) in horas" :key="'h2'+int4+index2" class=" col-sm-4 mx-0 px-0 border">

                        </div>

                    </div>
                </div>
            </div>

        </div>
        <div class="col-xl-4 col-lg-12 px-0 border">
            <div class=" div-text div-title   fw-bold py-1 justify-content-center">
                <span>Eventos/Datos Intraoperatorios</span>
            </div>
            <div class="text-start  div-text px-2"> 1. </div>
            <div class="text-start div-text px-2"> 2. </div>
            <div class="text-start div-text px-2"> 2. </div>

        </div>
    </div>
    <div class="row mx-0 px-0 border">
        <div class="row col-xl-8  mx-0 px-0">
            <div class=" row mx-0  col-lg-12 px-0  ">
                <div class="col-sm-3 border px-0">
                    <div class=" div-text    fw-bold py-1 justify-content-center">
                        <span>VIA AREA</span>
                    </div>
                    <div class="row px-0 mx-0 ">
                        <div class="form-check col-sm-6 px-2 div-text text-start   px-0">
                            <label class="form-check-label px-1 px-1  " for="canule">
                                Canule
                            </label>
                            <input class="form-check-input mx-0" type="checkbox" value="" id="canule">
                        </div>
                        <div class="form-check col-sm-6 px-2 div-text text-start   px-0">
                            <label class="form-check-label px-1 px-1  " for="mascara">
                                Mascara
                            </label>
                            <input class="form-check-input mx-0" type="checkbox" value="" id="mascara">
                        </div>
                        <div class="form-check col-sm-6 px-2 div-text text-start   px-0">
                            <label class="form-check-label px-1 px-1  " for="lima">
                                Lima
                            </label>
                            <input class="form-check-input mx-0" type="checkbox" value="" id="lima">
                        </div>
                        <div class="form-check col-sm-6 px-2 div-text text-start   px-0">
                            <label class="form-check-label px-1 px-1  " for="fastrach">
                                Fastrach
                            </label>
                            <input class="form-check-input mx-0" type="checkbox" value="" id="fastrach">
                        </div>
                        <div class="form-check col-sm-6 px-2 div-text text-start   px-0">
                            <label class="form-check-label px-1 px-1  " for="flexCheckDefaul">
                                Proseal
                            </label>
                            <input class="form-check-input mx-0" type="checkbox" value="" id="flexCheckDefaul">
                        </div>
                        <div class="form-check col-sm-6 px-2 div-text text-start   px-0">
                            <label class="form-check-label px-1 px-1  " for="flexible">
                                Flexible
                            </label>
                            <input class="form-check-input mx-0" type="checkbox" value="" id="flexible">
                        </div>
                        <div class="form-check col-sm-6 px-2 div-text text-start   px-0">
                            <label class="form-check-label px-1 px-1  " for="iot">
                                IOT
                            </label>
                            <input class="form-check-input mx-0" type="checkbox" value="" id="iot">
                        </div>
                        <div class="form-check col-sm-6 px-2 div-text text-start   px-0">
                            <label class="form-check-label px-1 px-1  " for="int">
                                INT
                            </label>
                            <input class="form-check-input mx-0" type="checkbox" value="" id="int">
                        </div>
                        <div class="form-check col-sm-6 px-2 div-text text-start   px-0">
                            <label class="form-check-label px-1 px-1  " for="dier">
                                Dier
                            </label>
                            <input class="form-check-input mx-0" type="checkbox" value="" id="dier">
                        </div>
                        <div class="form-check col-sm-6 px-2 div-text text-start   px-0">
                            <label class="form-check-label px-1 px-1  " for="izq">
                                Izq
                            </label>
                            <input class="form-check-input mx-0" type="checkbox" value="" id="izq">
                        </div>
                        <div class="form-check col-sm-12 px-2 mb-0 div-text text-start   px-0">
                            <label class="form-check-label px-1 px-1  " for="flexCheckDefault">
                                Fibroscopia Tubo doble luz
                            </label>
                            <input class="form-check-input mx-0" type="checkbox" value="" id="flexCheckDefault">
                        </div>
                        <div class="row pb-1 mx-0 px-2 py-0">
                            <div class="col-md-1 div-text text-start  px-0 ">N°</div>
                            <div class="col-md-8 px-0">
                                <input type="text" class="form-control form-control-input" autocomplete="off" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-1 px-0 py-2 fw-bold ">
                    <div class="text-start  div-text px-2"> Sp02 </div>
                    <div class="text-start div-text px-2"> EIC02</div>
                    <div class="text-start div-text px-2"> Dluresis </div>
                    <div class="text-start div-text px-2"> FI02 </div>
                </div>
                <div class="row col-sm-8  px-0 mx-0  ">
                    <div v-for="int in 13" :key="int+1" class=" border ">

                    </div>
                </div>
            </div>
            <div class=" row mx-0  div-text col-lg-12 px-0  ">
                <div class="row col-sm-4  px-0 mx-0">
                    <div v-for="int1 in 11" :key="int1+2" class=" border ">
                        .
                    </div>
                </div>

                <div class="row col-sm-8  px-0 mx-0  ">
                    <div v-for="int2 in 11" :key="int2+3" class=" border ">
                    </div>
                </div>
            </div>

        </div>
        <div class=" col-xl-4 col-lg-12 px-0 mx-0 div-text fw-bold ">
            <div class="row mx-0 px-0">
                <div class="col-sm-3  border">Manejo PQ</div>
                <div class="col-sm-3 border">Aides</div>
                <div class="col-sm-3 border">Oploide Ev</div>
                <div class="col-sm-3 border">Inflitracion</div>

            </div>
            <div class="row mx-0 px-0">
                <div class="col-sm-2  border">PCA</div>
                <div class="col-sm-4 border">Opiode espinal</div>
                <div class="col-sm-2 border">Epid</div>
                <div class="col-sm-4 border">Bloqueo </div>

            </div>
            <div class=" mx-0 px-0">
                <div class=" ">Gases Sanguineos</div>
            </div>
            <div class="table-responsive ">
                <table class="w-100">
                    <caption class="div-text py-0"></caption>
                    <thead class="text-xxxs ">
                        <tr>
                            <th class="border">Hora</th>
                            <th class="border">PH</th>
                            <th class="border">PCO2</th>
                            <th class="border">PA2</th>
                            <th class="border">HCO3</th>
                            <th class="border">SAT</th>
                            <th class="border">DE</th>
                            <th class="border">LAB</th>
                        </tr>
                    </thead>
                    <tbody class="text-xxxs">
                        <tr v-for="index in 4" :key="index" class="px-1">
                            <td class="border rounded-0">.</td>
                            <td class="border rounded-0">.</td>
                            <td class="border rounded-0">.</td>
                            <td class="border rounded-0">.</td>
                            <td class="border rounded-0">.</td>
                            <td class="border rounded-0">.</td>
                            <td class="border rounded-0">.</td>
                            <td class="border rounded-0">.</td>

                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="table-responsive ">
                <table class="w-100">
                    <caption class="div-text py-0"></caption>
                    <thead class="text-xxxs ">
                        <tr>
                            <th colspan="4" class="border">Balance de Liquidos</th>

                        </tr>
                    </thead>
                    <tbody class="text-xxxs">
                        <tr class="px-1">
                            <td colspan="2" class="border rounded-0">Eliminados</td>
                            <td colspan="2" class="border rounded-0">Administrado</td>

                        </tr>
                        <tr class="px-1">
                            <td class="border rounded-0 px-1 text-start">Deficit</td>
                            <td class="border rounded-0 px-1  text-start">-------</td>
                            <td class="border rounded-0 px-1  text-start">Leotato Ringer</td>
                            <td class="border rounded-0 px-1  text-start">---------</td>
                        </tr>
                        <tr class="px-1">
                            <td class="border rounded-0 px-1 text-start">Perdida</td>
                            <td class="border rounded-0 px-1  text-start">-------</td>
                            <td class="border rounded-0 px-1  text-start">S.salina</td>
                            <td class="border rounded-0 px-1  text-start">---------</td>
                        </tr>
                        <tr class="px-1">
                            <td class="border rounded-0 px-1 text-start">Diuresis</td>
                            <td class="border rounded-0 px-1  text-start">-------</td>
                            <td class="border rounded-0 px-1  text-start">Cotoides</td>
                            <td class="border rounded-0 px-1  text-start">---------</td>
                        </tr>
                        <tr class="px-1">
                            <td class="border rounded-0 px-1 text-start">Sangrado</td>
                            <td class="border rounded-0 px-1  text-start">-------</td>
                            <td class="border rounded-0 px-1  text-start">Sangre</td>
                            <td class="border rounded-0 px-1  text-start">---------</td>
                        </tr>
                        <tr class="px-1">
                            <td class="border rounded-0 px-1 text-start">Otros</td>
                            <td class="border rounded-0 px-1  text-start">-------</td>
                            <td class="border rounded-0 px-1  text-start">Globulos Rojos</td>
                            <td class="border rounded-0 px-1  text-start">---------</td>
                        </tr>
                        <tr class="px-1">
                            <td class="border rounded-0 px-1 text-start">------- </td>
                            <td class="border rounded-0 px-1  text-start">-------</td>
                            <td class="border rounded-0 px-1  text-start">Otros</td>
                            <td class="border rounded-0 px-1  text-start">---------</td>
                        </tr>
                        <tr class="px-1">
                            <td class="border rounded-0 px-1 text-start">Total</td>
                            <td class="border rounded-0 px-1  text-start">-------</td>
                            <td class="border rounded-0 px-1  text-start">Total</td>
                            <td class="border rounded-0 px-1  text-start">---------</td>
                        </tr>

                    </tbody>
                </table>
            </div>
            <div class=" row  mx-0 px-0">
                <div class=" my-2 text-start px-2 ">Traslado A:</div>
                <div class="form-check col-sm-12 px-5 mb-0 div-text text-start   px-0">
                    <label class="form-check-label px-1 px-1  " for="flexCheckDefault">
                        Unidad de cuidado post-Anestesico
                    </label>
                    <input class="form-check-input mx-0" type="checkbox" value="" id="flexCheckDefault">
                </div>
                <div class="form-check col-sm-6 px-5 div-text text-start   px-0">
                    <label class="form-check-label px-1 px-1  " for="flexCheckDefault">
                        UCI
                    </label>
                    <input class="form-check-input mx-0" type="checkbox" value="" id="flexCheckDefault">
                </div>
                <div class="form-check col-sm-6 px-5 div-text text-start   px-0">
                    <label class="form-check-label px-1 px-1  " for="flexCheckDefault">
                        UGIM
                    </label>
                    <input class="form-check-input mx-0" type="checkbox" value="" id="flexCheckDefault">
                </div>
                <div class="form-check col-sm-12 px-5 mb-0 div-text text-start   px-0">
                    <label class="form-check-label px-1 px-1  " for="flexCheckDefault">
                        Traslado de fase II a recuperacion
                    </label>
                    <input class="form-check-input mx-0" type="checkbox" value="" id="flexCheckDefault">
                </div>
            </div>

        </div>
    </div>
</div>
</template>

<script>
import {
    mapActions
} from 'vuex'
export default {
    name: 'c-asistencial-sop-historia-hojaanestecia',
    data() {
        return {
            horas: [200, 190, 180, 170, 160, 150, 140, 130, 120, 110, 100, 90, 80, 70, 60, 50, 40, 30, 20, 10, 5, '.']
        };
    },
    methods: {
        ...mapActions(['get', 'post']),
    },
}
</script>
