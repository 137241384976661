<template>
    <div class="col-12">
        <div class="row d-flex">

            <div class="panel-border my-1">
                <div class="row d-flex text-xs">
                    <div class="col-md-4 bg-main text-white py-2">
                        <span class="fw-bold">HISTORIA CLINICA - {{ typeService }}</span>
                    </div>
                    <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12 py-2">
                        <span class="fw-bold">DOCTOR: </span>
                        {{ cpDoctor }}
                    </div>
                </div>
            </div>
        </div>
        <div class="row d-flex">
            <div class="panel-border my-1">
                <div class="row px-3 d-sm-flex ">
                    <div class="row my-2">
                        <div class="col-lg-4 col-md-12" v-for="(item2, index) in measures" :key="index">
                            <label class="col-sm-4 color-main fw-bold text-uppercase" :for="'input2' + index">{{ item2.label }}</label>
                            <div class="">
                                <input type="text" v-model="item2.value" disabled
                                    class="form-control form-control-input form-control-sm" :id="'input2' + index">
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-4 my-1">
                        <div id="div-te" class="form-group row align-left">
                            <label for="te" class="col-sm-4 color-main fw-bold ">T.E.</label>
                            <div class="col-xl-8 col-sm-12">
                                <input type="text" v-model="clinica_history.fe" placeholder="-"
                                    class="form-control form-control-input form-control-sm" id="te" disabled>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-4 my-1">
                        <div id="div-fi" class="form-group row align-left">
                            <label for="fi" class="col-sm-4 color-main fw-bold">F.I.</label>
                            <div class="col-xl-8 col-sm-12">
                                <input type="text" v-model="clinica_history.fi" placeholder="-"
                                    class="form-control form-control-input form-control-sm" id="fi" disabled>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-4 my-1">
                        <div id="div-curso" class="form-group row align-left">
                            <label for="curso" class="col-sm-5 color-main fw-bold">CURSO</label>
                            <div class="col-xl-7 col-sm-12">
                                <input type="text" v-model="clinica_history.course" placeholder="-"
                                    class="form-control form-control-input form-control-sm" id="curso" disabled>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 my-1">
                        <div id="div-story" class="form-group row align-left">
                            <label for="story" class="col-sm-12 col-md-3 color-main fw-bold">RELATO CRONOLOGICO</label>
                            <div class="col-xl-9 col-sm-12 col-md-9">
                                <textarea rows="5" v-model="clinica_history.story" placeholder="-"
                                    class="form-control text-xs" aria-label="With textarea" id="story"
                                    disabled></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row d-flex">
            <div class="panel-border my-1">
                <div class="row px-3 d-sm-flex ">
                    <div class="col-sm-12 col-md-6 col-xl-4 my-1">
                        <div id="div-signo" class="form-group row align-left">
                            <label for="story" class="col-sm-12 color-main fw-bold">SIGNOS Y SÍNTOMAS
                                PRINCIPALES</label>
                            <div class="col-xl-12 col-sm-12">
                                <ul class="list-group text-xs">
                                    <li class="list-group-item" v-for="(item, index) in symploms" :key="index">
                                        <span>{{ item.label == 'Otros' ? item.value : item.label }}</span>
                                    </li>
                                    <li class="list-group-item mb-2" v-if="symploms.length == 0">
                                        No hay signos y sintomas
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-6 col-xl-4 my-1">
                        <div id="div-antecente" class="form-group row align-left">
                            <label for="background" class="col-sm-6 color-main fw-bold">ANTECEDENTES</label>
                            <div class="col-xl-12 col-sm-12">
                                <ul class="list-group text-xs">
                                    <li class="list-group-item" v-for="(item, index) in background" :key="index">
                                        <span><b>{{ item.label }}:</b> {{ item.value }}</span>
                                    </li>
                                    <li class="list-group-item mb-2" v-if="background.length == 0">
                                        No hay Antecedentes
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-12 col-xl-4 col-lg-12 col-md-12 mb-2">
                        <div class="row d-flex">
                            <label class="col-sm-12 color-main fw-bold">SIGNOS VITALES</label>
                            <div class="col-md-4 col-xl-4 col-xs-6 mt-1 align-left"
                                v-for="(item2, index) in signosVitales" :key="index">
                                <div class="form-group row d-flex text-xs">
                                    <label :for="'inputsig' + index" class="col-sm-5 color-main">{{ item2.label
                                        }}</label>
                                    <div class="col-xl-7 col-lg-4 col-xs-6">
                                        <input type="text" v-model="item2.value" disabled
                                            class="form-control form-control-input form-control-sm"
                                            :id="'inputsig' + index">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row d-flex" v-if="typeService == 'HOSPITALIZACION'">
            <div class="panel-border my-1">
                <div class="row px-3 d-sm-flex ">
                    <div class="col-sm-12 col-xl-6 my-1">
                        <div id="div-other" class="form-group row align-left">
                            <label for="other" class="col-sm-12 color-main fw-bold">ANTECEDENTES FAMILIARES</label>
                            <div class="col-xl-12 col-sm-12">
                                <textarea rows="4" v-model="clinica_history.background_parent"
                                    class="form-control text-xs" aria-label="With textarea" id="other"
                                    disabled></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-xl-6 my-1">
                        <div id="div-physical" class="form-group row d-flex align-left">
                            <label class="col-sm-12 color-main fw-bold ">EXAMEN REGIONAL</label>
                            <div class="col-md-6 col-xs-4 mt-1 align-left" v-for="(item2, index) in regionals"
                                :key="index">
                                <div class="form-group row text-xs">
                                    <label :for="'regional' + index" class="col-sm-5 color-main">{{ item2.label
                                        }}</label>
                                    <div class="col-xl-7 col-sm-12">
                                        <textarea :rows="item2.row" v-model="item2.value" class="form-control text-xs"
                                            aria-label="With textarea" :id="'regional' + index" disabled></textarea>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-xs-4 mt-1 align-left text-xs" v-if="regionals.length == 0">
                                <span>No se registraron examenes</span>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="row d-flex">
            <div class="panel-border mb-1">
                <div class="row px-3">
                    <div class="col-sm-12 col-xl-6 my-1">
                        <div id="div-physical" class="form-group row d-flex align-left">
                            <label class="col-sm-12 color-main fw-bold ">EXAMEN FÍSICO</label>
                            <div class="col-md-6 col-xs-4 mt-1 align-left" v-for="(item2, index) in cpPhysicalExam"
                                :key="index">
                                <div class="form-group row text-xs">
                                    <label :for="'input2' + index" class="col-sm-5 color-main">{{ item2.label }}</label>
                                    <div class="col-xl-7 col-sm-12">
                                        <input type="text" v-model="item2.value" disabled
                                            class="form-control form-control-input form-control-sm"
                                            :id="'input2' + index">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-xl-6 my-1">
                        <div id="div-other" class="form-group row align-left">
                            <label for="other" class="col-sm-12 color-main fw-bold">OTROS EXAMENES</label>
                            <div class="col-xl-12 col-sm-12">
                                <textarea rows="4" v-model="clinica_history.another_file" class="form-control text-xs"
                                    aria-label="With textarea" id="other" disabled></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row d-flex">
            <div class="panel-border mb-1">
                <div class="row px-3">
                    <div class="col-sm-12 col-xl-6 my-1">
                        <div id="div-cie" class="form-group row d-flex align-left">
                            <label class="col-sm-12 color-main fw-bold ">DIAGNÓSTICOS</label>
                            <div class="col-xl-12 col-sm-12">
                                <ul class="list-group text-xs">
                                    <li class="list-group-item" v-for="(item, index) in cpCies" :key="index">
                                        <span><b>{{ index + 1 }}</b>. {{ item.name }} - {{ item.codigo }}</span>
                                    </li>
                                    <li class="list-group-item mb-2" v-if="cpCies.length == 0">
                                        No se han registrado diagnosticos
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-xl-6 my-1">
                        <div id="div-plan" class="form-group row d-flex align-left">
                            <label class="col-sm-12 color-main fw-bold ">PLAN DE TRABAJO</label>
                            <div class="col-xl-12 col-sm-12">
                                <ul class="list-group text-xs">
                                    <li class="list-group-item" v-for="(item, index) in cpPlan" :key="index">
                                        <span>{{ item.name }}</span>
                                    </li>
                                    <li class="list-group-item mb-2" v-if="cpPlan.length == 0">
                                        No se han registrado Planes
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row d-flex">
            <div class="panel-border mb-1">
                <div class="row px-3">
                    <div class="col-sm-12 col-xl-8 my-1">
                        <div id="div-recipe" class="form-group row d-flex align-left">
                            <label class="col-sm-12 color-main fw-bold ">RECETAS</label>
                            <div class="col-xl-12 col-sm-12">
                                <ul class="list-group text-xs">
                                    <li class="list-group-item" v-for="(item, index) in cpRecipe" :key="index">
                                        <span>{{ index + 1 }}. {{ item.molecule }} - {{ item.dosis }} - {{
                                            item.presentation }} - {{ mtdIndicacion(item) }}</span>
                                    </li>
                                    <li class="list-group-item mb-2" v-if="cpRecipe.length == 0">
                                        No se han registrado receta
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-sm-12 my-1">
                        <div id="div-rec" class="form-group row align-left">
                            <label for="recomendations"
                                class="col-sm-12 col-md-12 color-main fw-bold">RECOMENDACIONES</label>
                            <div class="col-xl-12 col-sm-12 col-md-12">
                                <textarea :rows="cpRow" v-model="clinica_history.recomendations" placeholder="-"
                                    class="form-control text-xs" aria-label="With textarea" id="recomendations"
                                    disabled></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import {
    mapActions
} from 'vuex';
export default {
    name: 'c-consultingshow',
    data() {
        return {
            subpage: "buttons",

        }
    },
    props: {
        clinica_history: Object,
        doctor: Object,
        typeService: String,
    },
    watch: {

    },
    computed: {
        background() {
            let hc = JSON.parse(this.clinica_history.background);
            let content = [];
            hc.forEach(element => {
                if (element.state == true) {
                    content.push(element);
                }
            });
            return content;
        },
        symploms() {
            let hc = JSON.parse(this.clinica_history.symploms);
            let content = [];
            hc.forEach(element => {
                if (element.state == true) {
                    content.push(element);
                }
            });
            return content;
        },
        measures() {
            let hc = JSON.parse(this.clinica_history.measures);
            let content = [];
            hc.forEach(element => {
                content.push(element);
            });
            return content;
        },
        regionals() {
            let content = [];
            if (this.typeService == 'HOSPITALIZACION') {
                let hc = JSON.parse(this.clinica_history.regional_exam);
                hc.forEach(element => {
                    if (element.value) {
                        content.push(element);
                    }
                });
            }
            return content;
        },
        cpRow() {
            let size = this.cpRecipe.length;
            return size < 2 ? 2 : size;
        },
        cpCies() {
            return JSON.parse(this.clinica_history.diagnostics);
        },
        signosVitales() {
            return JSON.parse(this.clinica_history.vital_sings);
        },
        cpPhysicalExam() {
            let exam = JSON.parse(this.clinica_history.physical_exam);
            return exam;
        },
        cpPlan() {
            return JSON.parse(this.clinica_history.plan);
        },
        cpRecipe() {
            return JSON.parse(this.clinica_history.recipe);
        },
        cpDoctor() {
            let name = "";
            let last_name = "";
            if (this.doctor) {
                name = this.doctor.name ?? "";
                last_name = this.doctor.last_name ?? "";
            }
            return name + " " + last_name;
        }

    },
    methods: {
        mtdIndicacion(item) {
            let frequency = item.frequency;
            let duration = item.duration;
            let msg = frequency == 1 ? ' hora  x' : ' horas x';
            let msgduration = duration == 1 ? ' dia' : ' días';
            let msgfinal = "( cada " + frequency + msg + " " + duration + " " + msgduration + " )";
            return msgfinal.toUpperCase();
        },
        returnHome() {
            //window.location.reload();
            this.$emit('mtdChangePage', 'home');
        },
        mtdback(subpage) {
            this.$emit('mtdChangeSubPage', subpage);

        },
    }

}
</script>
