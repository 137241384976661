<template>
<div class="col-md-12">
    <div class="row d-flex">
        <div class="panel-border my-1">
            <div class="row d-flex text-xs">
                <div class="col-md-12 bg-main text-white text-center py-2">
                    <span class="fw-bold">KARDEX </span>
                </div>
                <!-- <div class="col-xl-9 col-lg-9 col-md-9 col-sm-12 col-xs-12 py-2">
                    <span class="fw-bold">DOCTOR: </span>
                    DOCTOR
                </div> -->
            </div>
        </div>
        <div class="border border-main">
            <div class="table-responsive-edit mt-3 px-0" id="content-timelinetable">
                <table class="table table-bordered text-xxs mb-0">
                    <thead class="bg-main text-white text-center">
                        <tr id="cabecera-fija">
                            <th class="align-middle" scope="col" rowspan="2">N°</th>
                            <th class="align-middle" scope="col" rowspan="2">INDICACIONES MÉDICAS</th>
                            <th class="align-middle" scope="col" rowspan="2">CONCENTRACIÓN</th>
                            <th class="align-middle" scope="col" rowspan="2">VIA</th>
                            <th class="align-middle" scope="col" rowspan="2">HORA</th>
                            <th class="align-middle" scope="col" rowspan="2">INDICACIONES</th>
                            <th v-for="(item, index) in data.datatime" :key="index" scope="col" colspan="3">{{ item }}</th>
                        </tr>
                        <tr id="cabecera">
                            <template v-for="n in data.datatime.length">
                                <th>M</th>
                                <th>T</th>
                                <th>N</th>
                            </template>
                        </tr>
                    </thead>
                    <tbody v-if="data.recipes.length > 0">
                        <template v-for="(item1) in data.recipes">
                            <tr>
                                <td>{{item1.medicamentos[0].count }}</td>
                                <td>
                                    <span v-if="item1.medicamentos[0].group !=0 " class=" px-2 rounded bg-info"> {{ 'Grupo '+ item1.medicamentos[0].group  }}</span> {{ item1.medicamentos[0].name }}
                                </td>
                                <td>{{ item1.medicamentos[0].dosis  }}</td>
                                <td>{{ item1.medicamentos[0].via }}</td>

                                <td style="width: 120px;">{{ item1.medicamentos[0].hora }}
                                    <!-- <button @click="mtdaddhorario(item1.medicamentos[0])" v-if="item1.medicamentos[0].prn && !dete_out && !dete_out_doctor && !item1.medicamentos[0].cancelation && rol_id == 6" type="button" class="btn btn-warning ms-4 py-0 px-1"><i class="fa fa-clock"></i></button>
                                    <button @click="mtdaddhorario(item1.medicamentos[0])" v-if="!item1.medicamentos[0].prn && !item1.medicamentos[0].start  && !dete_out && !dete_out_doctor   && !item1.medicamentos[0].cancelation && rol_id == 6" type="button" class="btn btn-info ms-4 py-0 px-1"><i class="fa fa-clock"></i></button> -->
                                    <!-- <span>
                                        <a @click="mtdinsule(item1.medicamentos[0].insuline)" v-if="item1.medicamentos[0].insuline" type="button" :class="item1.medicamentos[0].start && !item1.medicamentos[0].prn ? 'ms-4': 'ms-2'" class="btn btn-success  py-0 px-1"><i class="fas fa-solid fa-eye"></i></a>
                                    </span> -->
                                </td>
                                <td class=" text-start h-100" :rowspan="item1.medicamentos.length" v-html="item1.medicamentos[0].indicaciones.replace(/\n/g, '<br>')">
                                </td>
                                <template v-for="(horario) in item1.medicamentos[0].horario">
                                    <td>
                                        <div class="d-flex justify-content-center">
                                            <div v-for="(mañana, index) in horario.mañana" :key="index" style="margin: 0px 2px 0px 2px;">
                                                <!-- <button > -->
                                                <span :class="mañana.status == 0 ? 'bg-danger' : mañana.status == 1 ? 'bg-success text-decoration-line-through' : mañana.status == 3 ? 'bg-secondary': mañana.status == 2 && mañana.comment ? 'bg-success text-decoration-line-through': 'bg-warning text-decoration-line-through'"> <strong class="fw-bold text-xs text-warning"> {{mañana.aplicacion}} </strong>{{ formatTime(mañana.time_kardex)  }} </span>
                                                <!-- </button> -->
                                                <div style="font-size: 7px;">F.R:{{ formatTime(mañana.time_register) }}</div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="d-flex justify-content-center">
                                            <div v-for="(tarde, index) in horario.tarde" :key="index" style="margin: 0px 2px 0px 2px;">
                                                <!-- <button > -->
                                                <span :class="tarde.status == 0 ? 'bg-danger' : tarde.status == 1 ? 'bg-success text-decoration-line-through' : tarde.status == 3 ? 'bg-secondary': tarde.status == 2 && tarde.comment ? 'bg-success text-decoration-line-through': 'bg-warning text-decoration-line-through'"> <strong class="fw-bold text-xs text-warning"> {{tarde.aplicacion}} </strong>{{ formatTime(tarde.time_kardex)}} </span>
                                                <!-- </button> -->
                                                <div style="font-size: 7px;">F.R:{{formatTime(tarde.time_register)}}</div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="d-flex justify-content-center">
                                            <div v-for="(noche, index) in horario.noche" :key="index" style="margin: 0px 2px 0px 2px;">
                                                <!-- <button > -->
                                                <span :class="noche.status == 0 ? 'bg-danger' : noche.status == 1 ? 'bg-success text-decoration-line-through' : noche.status == 3 ? 'bg-secondary': noche.status == 2 && noche.comment ? 'bg-success text-decoration-line-through': 'bg-warning text-decoration-line-through'"> <strong class="fw-bold text-xs text-warning"> {{noche.aplicacion}} </strong>{{formatTime(noche.time_kardex) }} </span>
                                                <!-- </button> -->
                                                <div style="font-size: 7px;">F.R:{{ formatTime(noche.time_register) }}</div>
                                            </div>
                                        </div>
                                    </td>
                                </template>
                            </tr>
                            <template v-if="item1.medicamentos.length > 1">
                                <tr v-for="(medicine, index) in item1.medicamentos.slice(1)">
                                    <td>{{ medicine.count  }}</td>
                                    <td>
                                        <span v-if="medicine.group !=0 " class=" px-2 rounded bg-info"> {{ 'Grupo '+ medicine.group  }}</span> {{ medicine.name }}

                                    </td>
                                    <td>{{ medicine.dosis  }}</td>
                                    <td>{{ medicine.via }}</td>
                                    <td style="width: 120px;">{{ medicine.hora }}
                                        <!-- <button @click="mtdaddhorario(medicine)" v-if="medicine.prn && !dete_out && !dete_out_doctor && !medicine.cancelation && rol_id == 6" type="button" class="btn btn-warning ms-4 py-0 px-1"><i class="fa fa-clock"></i></button>
                                        <button @click="mtdaddhorario(medicine)" v-if="!medicine.prn && !medicine.start  && !dete_out && !dete_out_doctor   && !medicine.cancelation && rol_id == 6" type="button" class="btn btn-info ms-4 py-0 px-1"><i class="fa fa-clock"></i></button> -->
                                        <!-- <span>
                                            <a @click="mtdinsule(medicine.insuline)" v-if="medicine.insuline" type="button" :class="medicine.start && !medicine.prn ? 'ms-4': 'ms-2'" class="btn btn-success  py-0 px-1"><i class="fas fa-solid fa-eye"></i></a>
                                        </span> -->
                                    </td>
                                    <template v-for="(horario) in medicine.horario">
                                        <td>
                                            <div class="d-flex justify-content-center">
                                                <div v-for="(mañana, index) in horario.mañana" :key="index" style="margin: 0px 2px 0px 2px;">
                                                    <!-- <button > -->
                                                    <span :class="mañana.status == 0 ? 'bg-danger' : mañana.status == 1 ? 'bg-success text-decoration-line-through' : mañana.status == 3 ? 'bg-secondary': mañana.status == 2 && mañana.comment ? 'bg-success text-decoration-line-through': 'bg-warning text-decoration-line-through'"> <strong class="fw-bold text-xs text-warning"> {{mañana.aplicacion}} </strong>{{ formatTime(mañana.time_kardex)  }} </span>
                                                    <!-- </button> -->
                                                    <div style="font-size: 7px;">F.R:{{ formatTime(mañana.time_register) }}</div>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="d-flex justify-content-center">
                                                <div v-for="(tarde, index) in horario.tarde" :key="index" style="margin: 0px 2px 0px 2px;">
                                                    <!-- <button > -->
                                                    <span :class="tarde.status == 0 ? 'bg-danger' : tarde.status == 1 ? 'bg-success text-decoration-line-through' : tarde.status == 3 ? 'bg-secondary': tarde.status == 2 && tarde.comment ? 'bg-success text-decoration-line-through': 'bg-warning text-decoration-line-through'"> <strong class="fw-bold text-xs text-warning"> {{tarde.aplicacion}} </strong>{{ formatTime(tarde.time_kardex)}} </span>
                                                    <!-- </button> -->
                                                    <div style="font-size: 7px;">F.R:{{formatTime(tarde.time_register)}}</div>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="d-flex justify-content-center">
                                                <div v-for="(noche, index) in horario.noche" :key="index" style="margin: 0px 2px 0px 2px;">
                                                    <!-- <button > -->
                                                    <span :class="noche.status == 0 ? 'bg-danger' : noche.status == 1 ? 'bg-success text-decoration-line-through' : noche.status == 3 ? 'bg-secondary': noche.status == 2 && noche.comment ? 'bg-success text-decoration-line-through': 'bg-warning text-decoration-line-through'"> <strong class="fw-bold text-xs text-warning"> {{noche.aplicacion}} </strong>{{formatTime(noche.time_kardex) }} </span>
                                                    <!-- </button> -->
                                                    <div style="font-size: 7px;">F.R:{{ formatTime(noche.time_register) }}</div>
                                                </div>
                                            </div>
                                        </td>
                                    </template>
                                </tr>

                            </template>

                        </template>

                    </tbody>
                    <tbody v-else>
                        <tr>
                            <td colspan="6" class="text-center">
                                <span class="fw-bold"> No hay medicamentos </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="table-responsive-edit mt-3 px-0" id="content-timelinetable">
                <table class=" table table-2 table-bordered text-xxs mb-0 w-100">
                    <thead class="bg-main text-white text-center">
                        <tr id="cabecera-fija">
                            <th class="align-middle" colspan="5" scope="col" rowspan="2"></th>
                            <th v-for="(item, index) in data.datatime2" :key="index" scope="col" colspan="3">{{ item }}</th>
                        </tr>
                        <tr id="cabecera">
                            <template v-for="n in data.datatime2.length">
                                <th>M</th>
                                <th>T</th>
                                <th>N</th>
                            </template>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td colspan="5" class="fw-bold">CURACIONES </td>
                            <template v-for="(history) in data.historys">
                                <td class="text-xxxs text-center ">
                                    <div class="d-flex justify-content-center">
                                        <span v-for="(mañana, index) in history.mañana " :key="index">
                                            <span class="py-2 px-2 rounded rounded-circle " style="font-size: 6px; margin: 0px 4px 0px 4px;">
                                                <i class="fas fa-check text-dark" style="font-size: 8px;"></i>
                                            </span>
                                            <div class="mt-2 fw-bold" style="font-size: 7px;">{{ mañana.hour}}</div>
                                        </span>
                                    </div>
                                </td>
                                <td class="text-xxxs text-center">
                                    <div class="d-flex justify-content-center">
                                        <span v-for="(tarde, index) in history.tarde " :key="index">
                                            <span class="py-2 px-2 rounded rounded-circle " style="font-size: 6px ; margin: 0px 4px 0px 4px;">
                                                <i class="fas fa-check text-dark" style="font-size: 8px;"></i>
                                            </span>
                                            <div class="mt-2 fw-bold" style="font-size: 7px;">{{ tarde.hour}}</div>
                                        </span>
                                    </div>
                                </td>
                                <td class="text-xxxs text-center ">
                                    <div class="d-flex justify-content-center">
                                        <span v-for="(noche, index) in history.noche " :key="index" style=" margin: 0px 4px 0px 4px;">
                                            <span class="py-2 px-2 rounded rounded-circle " style="font-size: 6px;">
                                                <i class="fas fa-check text-dark" style="font-size: 8px;"></i>
                                            </span>
                                            <div class="mt-2 fw-bold" style="font-size: 7px;">{{ noche.hour}}</div>
                                        </span>
                                    </div>
                                </td>
                            </template>

                        </tr>
                        <tr>
                            <td colspan="5" class="fw-bold">ESCARAS </td>
                            <template v-for="(note) in data.notes">
                                <td class="text-xxxs text-center">
                                    <span v-if="note.mañana " @click="mtdviewdetalle(note.mañana)" class="py-2 px-2 rounded rounded-circle  " style="font-size: 6px;">
                                        <i class="fas fa-check text-dark" style="font-size: 8px;"></i>
                                    </span>
                                </td>
                                <td class="text-xxxs text-center">
                                    <span v-if="note.tarde " @click="mtdviewdetalle(note.tarde)" class="py-2 px-2 rounded rounded-circle  " style="font-size: 6px;">
                                        <i class="fas fa-check text-dark" style="font-size: 8px;"></i>
                                    </span>
                                </td>
                                <td class="text-xxxs text-center">
                                    <span v-if="note.noche " @click="mtdviewdetalle(note.noche)" class="py-2 px-2 rounded rounded-circle  " style="font-size: 6px;">
                                        <i class="fas fa-check text-dark" style="font-size: 8px;"></i>
                                    </span>
                                </td>
                            </template>

                        </tr>
                        <tr>
                            <td rowspan='2' colspan="2" class="fw-bold">CONTROL DE GLICEMIAS </td>
                            <td colspan="3" class="fw-bold column-second1">VALOR</td>
                            <template v-for="(kardex) in data.nursing_kardexes_1">
                                <td class="text-xxxs text-center cell-with-icon">
                                    <span v-if="hasElements(kardex.mañana)">
                                        <span v-for="(mañana, index) in kardex.mañana" :key="index">
                                            <li>
                                                <span>{{ mañana.description }}</span>
                                            </li>
                                        </span>
                                    </span>
                                </td>
                                <td class="text-xxxs text-center cell-with-icon">
                                    <span v-if="hasElements(kardex.tarde)">
                                        <span v-for="(tarde, index) in kardex.tarde" :key="index">
                                            <li>
                                                <span>{{ tarde.description }}</span>
                                            </li>
                                        </span>
                                    </span>
                                </td>
                                <td class="text-xxxs text-center cell-with-icon">
                                    <span v-if="hasElements(kardex.noche)">
                                        <span v-for="(noche, index) in kardex.noche" :key="index">
                                            <li>
                                                <span>{{ noche.description }}</span>
                                            </li>
                                        </span>
                                    </span>
                                </td>
                            </template>
                        </tr>
                        <tr>
                            <td colspan="3" class="fw-bold column-second2">C/H</td>
                            <template v-for="(kardex) in data.nursing_kardexes_2">
                                <td class="text-xxxs text-center cell-with-icon">
                                    <span v-if="hasElements(kardex.mañana)">
                                        <span v-for="(mañana, index) in kardex.mañana" :key="index">
                                            <li>
                                                <span><strong>Por: </strong>{{ mañana.description }}</span><br>
                                                <span v-if="mañana.description2 != null || mañana.description2 != ''"><strong>Cada: </strong>{{ mañana.description2 }} horas</span>
                                                <span v-else></span>
                                            </li>
                                        </span>
                                    </span>

                                </td>
                                <td class="text-xxxs text-center cell-with-icon">
                                    <span v-if="hasElements(kardex.tarde)">
                                        <span v-for="(tarde, index) in kardex.tarde" :key="index">
                                            <li>
                                                <span><strong>Por: </strong>{{ tarde.description }}</span><br>
                                                <span v-if="tarde.description2 != null || tarde.description2 != ''"><strong>Cada: </strong>{{ tarde.description2 }} horas</span>
                                                <span v-else></span>
                                            </li>
                                        </span>
                                    </span>

                                </td>
                                <td class="text-xxxs text-center cell-with-icon">
                                    <span v-if="hasElements(kardex.noche)">
                                        <span v-for="(noche, index) in kardex.noche" :key="index">
                                            <li>
                                                <span><strong>Por: </strong>{{ noche.description }}</span><br>
                                                <span v-if="noche.description2 != null || noche.description2 != ''"><strong>Cada: </strong>{{ noche.description2 }} horas</span>
                                                <span v-else></span>
                                            </li>
                                        </span>
                                    </span>

                                </td>
                            </template>
                        </tr>
                        <tr>
                            <td colspan="5" class="fw-bold">OXIGENOTERAPIA</td>
                            <template v-for="(kardex) in data.nursing_kardexes_6">
                                <td class="text-xxxs text-center cell-with-icon">
                                    <span v-if="hasElements(kardex.mañana)">
                                        <span v-for="(mañana, index) in kardex.mañana" :key="index">
                                            <li>
                                                <span><strong>FIO2:</strong> {{ mañana.fio2 }} <strong>| LITROS:</strong> {{ mañana.liter }}</span>
                                                <br>
                                                <strong>
                                                    <span v-if="mañana.device == 'mecanica'"> (VENTILADOR MECÁNICO)</span>
                                                    <span v-else-if="mañana.device == 'canula'"> (CÁNULA)</span>
                                                    <span v-else-if="mañana.device == 'simple'"> (MASCARILLA SIMPLE)</span>
                                                    <span v-else-if="mañana.device == 'reservorio'"> (MASCARILLA RESERVORIO)</span>
                                                    <span v-else-if="mañana.device == 'venturi'"> (MASCARILLA VÉNTURI)</span>
                                                    <span v-else-if="mañana.device == 'gafas'"> (GAFAS NASALES)</span>
                                                    <span v-else-if="mañana.device == 'nebulizadora'"> (MÁSCARA NEBULIZADORA)</span>
                                                    <span v-else-if="mañana.device == 'caf'"> (CAF)</span>
                                                    <span v-else></span>
                                                </strong>
                                            </li>
                                        </span>
                                    </span>

                                </td>
                                <td class="text-xxxs text-center cell-with-icon">
                                    <span v-if="hasElements(kardex.tarde)">
                                        <span v-for="(tarde, index) in kardex.tarde" :key="index">
                                            <li>
                                                <span><strong>FIO2:</strong> {{ tarde.fio2 }} <strong>| LITROS:</strong> {{ tarde.liter }}</span>
                                                <br>
                                                <strong>
                                                    <span v-if="tarde.device == 'mecanica'"> (VENTILADOR MECÁNICO)</span>
                                                    <span v-else-if="tarde.device == 'canula'"> (CÁNULA)</span>
                                                    <span v-else-if="tarde.device == 'simple'"> (MASCARILLA SIMPLE)</span>
                                                    <span v-else-if="tarde.device == 'reservorio'"> (MASCARILLA RESERVORIO)</span>
                                                    <span v-else-if="tarde.device == 'venturi'"> (MASCARILLA VÉNTURI)</span>
                                                    <span v-else-if="tarde.device == 'gafas'"> (GAFAS NASALES)</span>
                                                    <span v-else-if="tarde.device == 'nebulizadora'"> (MÁSCARA NEBULIZADORA)</span>
                                                    <span v-else-if="tarde.device == 'caf'"> (CAF)</span>
                                                    <span v-else></span>
                                                </strong>
                                            </li>
                                        </span>
                                    </span>
                                </td>
                                <td class="text-xxxs text-center cell-with-icon">
                                    <span v-if="hasElements(kardex.noche)">
                                        <span v-for="(noche, index) in kardex.noche" :key="index">
                                            <li>
                                                <span><strong>FIO2:</strong> {{ noche.fio2 }} <strong>| LITROS:</strong> {{ noche.liter }}</span>
                                                <br>
                                                <strong>
                                                    <span v-if="noche.device == 'mecanica'"> (VENTILADOR MECÁNICO)</span>
                                                    <span v-else-if="noche.device == 'canula'"> (CÁNULA)</span>
                                                    <span v-else-if="noche.device == 'simple'"> (MASCARILLA SIMPLE)</span>
                                                    <span v-else-if="noche.device == 'reservorio'"> (MASCARILLA RESERVORIO)</span>
                                                    <span v-else-if="noche.device == 'venturi'"> (MASCARILLA VÉNTURI)</span>
                                                    <span v-else-if="noche.device == 'gafas'"> (GAFAS NASALES)</span>
                                                    <span v-else-if="noche.device == 'nebulizadora'"> (MÁSCARA NEBULIZADORA)</span>
                                                    <span v-else-if="noche.device == 'caf'"> (CAF)</span>
                                                    <span v-else></span>
                                                </strong>
                                            </li>
                                        </span>
                                    </span>
                                </td>
                            </template>
                        </tr>
                        <tr>
                            <td colspan="5" class="fw-bold">TERAPIA FISICA Y RESPIRATORIA</td>
                            <template v-for="(kardex) in data.nursing_kardexes_5">
                                <td class="text-xxxs text-center cell-with-icon px-2">
                                    <span v-if="hasElements(kardex.mañana)">
                                        <span v-for="(mañana, index) in kardex.mañana" :key="index">
                                            <li>
                                                <span><strong>Por: </strong>{{ mañana.description }}</span><br>
                                                <span v-if="mañana.description2 != null || mañana.description2 != ''"><strong>Cada: </strong>{{ mañana.description2 }} horas</span>
                                                <span v-else></span>
                                            </li>
                                        </span>
                                    </span>
                                </td>
                                <td class="text-xxxs text-center cell-with-icon px-2">
                                    <span v-if="hasElements(kardex.tarde)">
                                        <span v-for="(tarde, index) in kardex.tarde" :key="index">
                                            <li>
                                                <span><strong>Por: </strong>{{ tarde.description }}</span><br>
                                                <span v-if="tarde.description2 != null || tarde.description2 != ''"><strong>Cada: </strong>{{ tarde.description2 }} horas</span>
                                                <span v-else></span>
                                            </li>
                                        </span>
                                    </span>
                                </td>
                                <td class="text-xxxs text-center cell-with-icon px-2">
                                    <span v-if="hasElements(kardex.noche)">
                                        <span v-for="(noche, index) in kardex.noche" :key="index">
                                            <li>
                                                <span><strong>Por: </strong>{{ noche.description }}</span><br>
                                                <span v-if="noche.description2 != null || noche.description2 != ''"><strong>Cada: </strong>{{ noche.description2 }} horas</span>
                                                <span v-else></span>
                                            </li>
                                        </span>
                                    </span>
                                </td>
                            </template>
                        </tr>

                        <tr>
                            <td colspan="5" class="fw-bold">DIETA</td>
                            <template v-for="(dieta) in data.dietas">
                                <td class="text-xxxs text-center">
                                    <span v-for="(mañana, index) in dieta.mañana " :key="index">
                                        <li class=" text-start">
                                            <span>{{ mañana.name }}</span>
                                        </li>
                                    </span>

                                </td>
                                <td class="text-xxxs text-center">
                                    <span v-for="(tarde, index) in dieta.tarde " :key="index">
                                        <li class=" text-start">
                                            <span>{{ tarde.name }}</span>
                                        </li>
                                    </span>
                                </td>
                                <td class="text-xxxs text-center">
                                    <div v-for="(noche, index) in dieta.noche " :key="index">
                                        <li class=" text-start">
                                            <span>{{ noche.name }}</span>
                                        </li>
                                    </div>
                                </td>
                            </template>

                        </tr>

                    </tbody>
                </table>
            </div>
            <div class="row">
                <div class="mt-3 mb-3 ">
                    <div class="row mx-0 px-0">
                        <div class=" responsive-container-div col-xl-4 px-0 pe-xl-1 mb-3">
                            <div class="row mx-0 px-0">
                                <div class=" responsive-container-div col-xl-12 px-0  pe-xl-1   mb-3">
                                    <div>
                                        <table class=" plan w-100 table-bordered border-white  text-center ">
                                            <caption class=" py-0 my-0"></caption>
                                            <thead class=" text-white text-xxs bg-main  ">
                                                <tr>
                                                    <th class="py-1" colspan="3">LABORATORIOS</th>
                                                </tr>
                                                <tr>
                                                    <th class="py-1">CONCEPTO DE SOLICITUD</th>
                                                    <th class="py-1">FECHA DE SOLICITUD</th>
                                                    <th class="py-1">FECHA DE RESULTADO</th>
                                                </tr>
                                            </thead>
                                            <tbody class="div-text" v-if="data.plan_laboratorio.length > 0">
                                                <tr class="px-1 mb-1" v-for="(laboratorio , index) in data.plan_laboratorio" :key="index">

                                                    <td class="border">
                                                        {{ laboratorio.name }}
                                                    </td>
                                                    <td class="border">
                                                        {{ laboratorio.aplicacion }}
                                                    </td>
                                                    <td class="border">
                                                        {{ laboratorio.resultado ? laboratorio.resultado : '-' }}
                                                    </td>
                                                </tr>

                                            </tbody>
                                            <tbody class=" text-center" v-else>
                                                <tr>
                                                    <td colspan="4" class=" text-center text-xs">
                                                        <strong> No hay resultados</strong>
                                                    </td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class=" responsive-container-div col-xl-12 px-0   mb-3">
                                    <div>
                                        <table class=" plan w-100 table-bordered border-white  text-center ">
                                            <caption class=" py-0 my-0"></caption>
                                            <thead class=" text-white text-xxs bg-main  ">
                                                <tr>
                                                    <th class="py-1" colspan="3">IMAGENES</th>
                                                </tr>
                                                <tr>
                                                    <th class="py-1">CONCEPTO DE SOLICITUD</th>
                                                    <th class="py-1">FECHA DE SOLICITUD</th>
                                                    <th class="py-1">FECHA DE RESULTADO</th>
                                                </tr>
                                            </thead>
                                            <tbody class="div-text" v-if="data.plan_imagenes.length > 0">
                                                <tr class="px-1 mb-1" v-for="(imagen , index) in data.plan_imagenes" :key="index">

                                                    <td class="border">
                                                        {{ imagen.name }}
                                                    </td>
                                                    <td class="border">
                                                        {{ imagen.aplicacion }}
                                                    </td>
                                                    <td class="border">
                                                        {{ imagen.resultado ? imagen.resultado : '-' }}
                                                    </td>
                                                </tr>

                                            </tbody>
                                            <tbody class=" text-center" v-else>
                                                <tr>
                                                    <td colspan="4" class=" text-center text-xs">
                                                        <strong> No hay resultados</strong>
                                                    </td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class=" responsive-container-div col-xl-12 px-0  pe-xl-1   mb-3">
                                    <div>
                                        <table class=" plan w-100 table-bordered border-white  text-center ">
                                            <caption class=" py-0 my-0"></caption>
                                            <thead class=" text-white text-xxs bg-main  ">
                                                <tr>
                                                    <th class="py-1" colspan="3">PROCEDIMIENTOS</th>
                                                </tr>
                                                <tr>
                                                    <th class="py-1">CONCEPTO DE SOLICITUD</th>
                                                    <th class="py-1">FECHA DE SOLICITUD</th>
                                                    <th class="py-1">FECHA DE RESULTADO</th>
                                                </tr>
                                            </thead>
                                            <tbody class="div-text" v-if="data.plan_procedimientos.length > 0">
                                                <tr class="px-1 mb-1" v-for="(Procedimiento , index) in data.plan_procedimientos" :key="index">

                                                    <td class="border">
                                                        {{ Procedimiento.name }}
                                                    </td>
                                                    <td class="border">
                                                        {{ Procedimiento.aplicacion }}
                                                    </td>
                                                    <td class="border">
                                                        {{ Procedimiento.resultado ? Procedimiento.resultado : '-' }}
                                                    </td>
                                                </tr>

                                            </tbody>
                                            <tbody class=" text-center" v-else>
                                                <tr>
                                                    <td colspan="4" class=" text-center text-xs">
                                                        <strong> No hay resultados</strong>
                                                    </td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class=" responsive-container-div col-xl-12 px-0   mb-3">
                                    <div>
                                        <table class=" plan w-100 table-bordered border-white  text-center ">
                                            <caption class=" py-0 my-0"></caption>
                                            <thead class=" text-white text-xxs bg-main  ">
                                                <tr>
                                                    <th class="py-1" colspan="4">INTERCONSULTAS</th>
                                                </tr>
                                                <tr>
                                                    <th class="py-1">CONCEPTO DE SOLICITUD</th>
                                                    <th class="py-1">FECHA DE SOLICITUD</th>
                                                    <th class="py-1">FECHA DE RESULTADO</th>
                                                </tr>
                                            </thead>
                                            <tbody class="div-text" v-if="data.plan_interconsultas.length > 0">
                                                <tr class="px-1 mb-1" v-for="(interconsulta , index) in data.plan_interconsultas" :key="index">

                                                    <td class="border">
                                                        {{ interconsulta.name }}
                                                    </td>
                                                    <td class="border">
                                                        {{ interconsulta.aplicacion }}
                                                    </td>
                                                    <td class="border">
                                                        {{ interconsulta.resultado ? interconsulta.resultado : '-' }}
                                                    </td>

                                                </tr>

                                            </tbody>
                                            <tbody class=" text-center" v-else>
                                                <tr>
                                                    <td colspan="4" class=" text-center text-xs">
                                                        <strong> No hay resultados</strong>
                                                    </td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class=" px-0">
                                    <table class=" plan w-100 table-bordered border-white   text-center ">
                                        <caption class=" py-0 my-0"></caption>
                                        <thead class=" text-white text-xxs bg-main ">
                                            <tr>
                                                <th class="py-1 text-left" colspan="4">ESCALA CORRECTIVA</th>
                                            </tr>
                                        </thead>
                                        <tbody class="div-text" v-if="data.insulina.length > 0">
                                            <tr class="px-1 mb-1" v-for="(insulin , index) in data.insulina" :key="index">

                                                <td class="border">
                                                    {{ insulin.created_at }}
                                                </td>
                                                <td class="border text-start px-2" v-html="insulin.insulin.replace(/\n/g, '<br>')">

                                                </td>
                                            </tr>

                                        </tbody>
                                        <tbody class=" text-center" v-else>
                                            <tr>
                                                <td colspan="4" class=" text-center text-xs">
                                                    <strong> No hay resultados</strong>
                                                </td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </div>
                        <div class=" responsive-container-div col-xl-3 px-0 pe-xl-1 mb-3">
                            <div>
                                <table class=" plan w-100 table-bordered border-white   text-center ">
                                    <caption class=" py-0 my-0"></caption>
                                    <thead class=" text-white text-xxs bg-main ">
                                        <tr>
                                            <th class="py-1 text-left" colspan="4">PROCEDIMIENTOS INVASIVOS</th>
                                        </tr>
                                        <tr>
                                            <th class="py-1 cabecera-kardex">PROCEDIMIENTO</th>
                                            <th class="py-1 cabecera-kardex">FECHA DE INICIO</th>
                                            <th class="py-1 cabecera-kardex">FECHA DE CAMBIO</th>
                                        </tr>
                                    </thead>
                                    <tbody class="div-text-md">
                                        <template v-for="(proc) in this.data.procedimiento_invasivo">
                                            <tr class="px-1 mb-1">
                                                <td class="border text-start px-1 fw-bold cell-with-icon" :rowspan="proc.date.length ==0 ? 1 : proc.date.length  ">{{ proc.name }}</td>
                                                <td class="border" v-if="proc.date.length > 0 && proc.date[0].date_start !== ''">{{ formatDateString(proc.date[0].date_start) }}</td>
                                                <td class="border" v-else></td>
                                                <td class="border" v-if="proc.date.length > 0 && proc.date[0].date_end !== ''">{{ formatDateString(proc.date[0].date_end) }}</td>
                                                <td class="border" v-else></td>
                                            </tr>
                                            <template v-if="proc.date.length > 1">
                                                <tr v-for="(item1, index) in proc.date.slice(1)">
                                                    <td class="border" v-if="proc.date.length > 0 && item1.date_start !== ''">{{ formatDateString(item1.date_start) }}</td>
                                                    <td class="border" v-else></td>
                                                    <td class="border" v-if="proc.date.length > 0 && item1.date_end !== ''">{{ formatDateString(item1.date_end) }}</td>
                                                    <td class="border" v-else></td>
                                                </tr>
                                            </template>

                                        </template>

                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class=" responsive-container-div col-xl-5 px-0 pe-xl-1 mb-3">
                            <div>
                                <table class=" plan w-100 table-bordered border-white   text-center ">
                                    <caption class=" py-0 my-0"></caption>
                                    <thead class=" text-white text-xxs bg-main ">
                                        <tr>
                                            <th class="py-1 text-left" colspan="4">PROCEDIMIENTOS INVASIVOS</th>
                                        </tr>
                                        <tr>
                                            <th class="py-1 cabecera-kardex" style="width: 180px;">PROCEDIMIENTO</th>
                                            <th class="py-1 cabecera-kardex" style="width: 70px;">FECHA </th>
                                            <th class="py-1 cabecera-kardex">FECHA DETALLE</th>
                                        </tr>
                                    </thead>
                                    <tbody class="div-text-md">
                                        <template v-for="(proc) in this.data.procedimiento_invasivo2">
                                            <tr class="px-1 mb-1">
                                                <td class="border text-start px-1 fw-bold cell-with-icon" :rowspan="proc.date.length ==0 ? 1 : proc.date.length  ">{{ proc.name }}</td>
                                                <td class="border" v-if="proc.date.length > 0 && proc.date[0].date_start !== ''">{{ formatDateString(proc.date[0].date_start) }}</td>
                                                <td class="border" v-else></td>
                                                <td class="border text-start px-2" v-if="proc.date.length > 0 && proc.date[0].detalle !== ''" v-html="proc.date[0].detalle.replace(/\n/g, '<br>')"></td>
                                                <td class="border" v-else></td>
                                            </tr>
                                            <template v-if="proc.date.length > 1">
                                                <tr v-for="(item1, index) in proc.date.slice(1)">
                                                    <td class="border" v-if="proc.date.length > 0 && item1.date_start !== ''">{{ formatDateString(item1.date_start) }}</td>
                                                    <td class="border" v-else></td>
                                                    <td class="border text-start px-2" v-if="proc.date.length > 0 && item1.detalle !== ''" v-html="item1.detalle .replace(/\n/g, '<br>')"></td>
                                                    <td class="border" v-else></td>
                                                </tr>
                                            </template>

                                        </template>

                                    </tbody>
                                </table>
                            </div>
                            <div class=" px-0 mt-1">
                                <table class=" plan w-100 table-bordered border-white   text-center ">
                                    <caption class=" py-0 my-0"></caption>
                                    <thead class=" text-white text-xxs bg-main ">
                                        <tr>
                                            <th class="py-1 text-left" colspan="4">LOCALIZACIÓN DE CATÉTERES Y UPP</th>
                                        </tr>
                                    </thead>
                                    <tbody class="div-text" v-if="data.upp.length > 0">
                                        <tr class="px-1 mb-1" v-for="(upp , index) in data.upp" :key="index">

                                            <td class="border">
                                                {{ upp.date }}
                                            </td>
                                            
                                            <td class="border">
                                                {{ upp.zona }}
                                            </td>
                                            <td class="border">
                                                {{ upp.level }}
                                            </td>
                                            <!-- <td class="border text-start px-2" v-html="upp.insulin.replace(/\n/g, '<br>')">

                                            </td> -->
                                        </tr>

                                    </tbody>
                                    <tbody class=" text-center" v-else>
                                        <tr>
                                            <td colspan="4" class=" text-center text-xs">
                                                <strong> No hay resultados</strong>
                                            </td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
</template>

<script>
import moment from "moment";
export default {
    name: 'c-medicalAct-reportEvMedical',
    data() {
        return {
            subpage: "buttons",

        }
    },
    props: {
        data: Object,
        doctor: Object,
        typeService: String
    },
    watch: {

    },
    computed: {
        // cpDoctor() {
        //     let last_name = this.doctor.last_name ? ? "";
        //     let name = this.doctor.name + " " + last_name;
        //     return name;
        // }

    },
    methods: {
        returnHome() {
            //window.location.reload();
            this.$emit('mtdChangePage', 'home');
        },
        mtdback(subpage) {
            this.$emit('mtdChangeSubPage', subpage);

        },
        formatTime(timeString) {
            const [hours, minutes] = timeString.split(':');
            return `${hours}:${minutes}`;
        },
        hasElements(variable) {
            return Array.isArray(variable) && variable.length > 0;
        },
        formatDateString(date) {
            return date != null ? moment(date).format("DD/MM/YYYY") : "";
        },

    }

}
</script>
