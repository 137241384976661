<template>
<div>
    <div class="px-2 pt-3 pb-3" :style="{ display: evolutions.length > 0 ? 'block' : 'none' }">
        <div class="row d-flex justify-content-end">
            <!-- <div class="col-xl-12 col-lg-12 mb-2">
                    <button type="button" class="btn btn-outline-main btn-sm" @click="initCallVoice">
                        <i class="fas fa-headset"></i>{{ isListe ? " Parar Dictado" : " Iniciar dictado" }}
                    </button>
                </div> -->
            <div class="col-12 px-0">
                <nav aria-label="Page navigation example" class="pagination-container">
                    <ul class="pagination pagination-xs justify-content-end">
                        <li class="page-item">
                            <a style="cursor: pointer" href="javascript:void(0)" class="page-link" @click="backPage">«</a>
                        </li>
                        <template>
                            <li class="page-item">
                                <a class="page-link active" href="javascript:void(0)">{{
                                        medical_evolutions.date_name }}</a>
                            </li>
                        </template>
                        <li class="page-item">
                            <a style="cursor: pointer" class="page-link" @click="nextPage">»</a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>

        <div class=" row mx-0 px-0 d-xl-flex justify-content-between col-12 mb-3">
            <div class="row mx-0 d-sm-flex px-0 col-xl-9  text-xs ">
                <div class="col-xl-1 px-0 col-sm-3    d-flex   ">
                    <strong class="color-main">Fecha Actual </strong>
                </div>
                <div class="col-xl-2 col-sm-9 px-0   ">
                    <input v-model="medical_evolutions.date" type="date" class="form-control form-control-input form-control-sm " placeholder="" readonly>
                </div>
                <div class="col-xl-1  col-sm-3 px-0 ms-xl-4   d-flex   ">
                    <strong class="color-main">Hora</strong>
                </div>
                <div class="col-xl-2 col-sm-9 px-0">
                    <input v-model="medical_evolutions.hour" type="time" class="form-control form-control-input form-control-sm" readonly>
                </div>
                <div class="col-xl-2 col-sm-3  px-0 ms-xl-4  d-flex   ">
                    <strong class="color-main">Dias Hospitalizados</strong>
                </div>
                <div class="col-xl-2 col-sm-9 px-0  ">
                    <input v-model="medical_evolutions.day" type="text" class="form-control form-control-input form-control-sm " placeholder="" readonly>
                </div>
            </div>
            <div v-if="medical_evolutions.date_name != 'EV ACTUAL'" class="col-xl-3 text-xs   px-0 mt-xl-0 mt-2 ">
                <div class=" row mx-0 px-0">
                    <div class="col-xl-3 px-0 col-sm-3    ">
                        <strong class="color-main">Medico</strong>
                    </div>
                    <div class="col-xl-9 col-sm-9 px-0   ">
                        <input v-model="medical_evolutions.doctor_name" type="text" class="form-control form-control-input form-control-sm " placeholder="" readonly>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mx-0 px-0 ">
            <div class="col-xl-2  px-0 ">
                    <div class="text-center py-1 border border-main border-2 ">
                        <strong class="color-main">Signos Vitales</strong>
                        <span class="info-icon color-main px-2" @click.stop="showInfo('PALABRAS CLAVES', 'signos')">
                            <i class="fas fa-info-circle fs-6"></i>
                        </span>
                    </div>
                    <div class=" border border-2 border-main py-2 px-1">
                        <div class="row mx-0 px-0 d-sm-flex color-main fw-bold"
                            v-for="(signoVital, index1) in medical_evolutions.vital_sings" :key="index1">
                            <div class="col-xl-7 col-sm-3 text-xs text-start ">
                                <span>{{ signoVital.label }}</span>
                            </div>
                            <div class="col-xl-5 col-sm-9 pb-1  px-0" >
                                <input v-if="signoVital.label !== 'PAM'" v-model="signoVital.value" type="text"
                                    class="form-control form-control-input form-control-sm  " placeholder=""
                                    :disabled="validation">
                                <input v-else v-model="cpValuePam" type="text"
                                    class="form-control form-control-input form-control-sm  " placeholder=""
                                    disabled>
                            </div>
                        </div>
                    </div>

                </div>
            <div class="col-xl-10 px-0  h-100 ">
                <div class="px-xl-1">
                    <div class="text-center py-1 border border-3 border-main  ">
                        <strong class="color-main">(S) Subjetivo</strong>
                        <span class="info-icon color-main px-2" @click.stop="showInfo('PALABRAS CLAVES', 'subjetivo')">
                            <i class="fas fa-info-circle fs-6"></i>
                        </span>
                    </div>
                    <div class="border border-3 border-main">
                        <div class=" px-1 py-1">
                            <textarea v-model="medical_evolutions.subjective" class="form-control " rows="4" :disabled="validation"></textarea>
                        </div>
                    </div>
                </div>
                <!-- PLANES -->
                <div class="px-0 mt-2 px-xl-1">
                        <div class="text-center py-1 border border-main border-3">
                            <strong class="color-main">Plan</strong>
                        </div>
                        <div class="border border-main border-3">
                            <div class="row mx-0 px-2">

                                <div class="col-xl-12 col-sm-12 py-2 ">
                                    <div class=" flex-input  d-sm-flex row input-group input-group mx-0 mb-2">
                                        <span
                                            class="text-center col-xl-1 col-md-1 col-sm-1 input-group-text text-white bg-main"><i
                                                class="fas fa-search"></i></span>
                                        <v-select class="col-xl-11 col-md-11 col-sm-11 px-0" @input="mtdaddPlan"
                                            :options="dataLaboratorio" :reduce="(laboratorio) => laboratorio"
                                            label="name" placeholder="Laboratorio" v-model="plan_unit" required
                                            :disabled="validation" />
                                    </div>
                                </div>
                                <div class="col-xl-12 col-sm-12 py-2  ">
                                    <div class="flex-input  d-sm-flex row input-group input-group mx-0 mb-2">
                                        <span
                                            class=" text-center col-xl-1 col-md-1 col-sm-1 input-group-text text-white bg-main"><i
                                                class="fas fa-search"></i></span>
                                        <v-select class="col-xl-11 col-md-11 col-sm-11 px-0" @input="mtdaddPlan"
                                            :options="dataImagenes" :reduce="(Imagenes) => Imagenes" label="name"
                                            placeholder="Imagenes" v-model="plan_unit" required
                                            :disabled="validation" />
                                    </div>
                                </div>
                                <div class="col-xl-12 col-sm-12 py-2  ">
                                    <div class="flex-input d-sm-flex row input-group input-group mx-0 mb-2">
                                        <span
                                            class=" text-center col-xl-1 col-md-1 col-sm-1 input-group-text text-white bg-main"><i
                                                class="fas fa-search"></i></span>
                                        <v-select class="col-xl-11 col-md-11 col-sm-11 px-0" @input="mtdaddPlan"
                                            :options="dataProcedimiento" :reduce="(Procedimiento) => Procedimiento"
                                            label="name" placeholder="Procedimiento" v-model="plan_unit" required
                                            :disabled="validation" />
                                    </div>
                                </div>
                                <div class="col-xl-12 col-sm-12 py-2  ">
                                    <div class="flex-input d-sm-flex row input-group input-group mx-0 mb-2">
                                        <span
                                            class=" text-center col-xl-1 col-md-1 col-sm-1 input-group-text text-white bg-main"><i
                                                class="fas fa-search"></i></span>
                                        <v-select class="col-xl-11 col-md-11 col-sm-11 px-0"
                                            :options="dataInterconsultas" :reduce="(Interconsulta) => Interconsulta"
                                            label="name" placeholder="Interconsulta" required @input="mtdaddPlan"
                                            v-model="plan_unit" :disabled="validation" />
                                    </div>
                                </div>
                            </div>
                            <div class="row mx-0 px-3">
                                <div v-for="(planunit, index) in medical_evolutions.plan " :key="index"
                                    class="col-md-12 py-1  ">
                                    <div
                                        class="input-group input-group btn-home px-0 div-pointer text-white fw-bold border bg-main h-100 border-2 align-middle  ">
                                        <button @click="mtddelete(index, 'mtddeleteplan')" type=" button"
                                            class="w-100 text-start" :disabled="validation">{{ planunit.name }}
                                        </button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
            </div>
        </div>

        <div v-if="medical_evolutions.date_name == 'EV ACTUAL'" class="row mx-0 px-0 d-sm-flex mt-3">
            <div class="col-xl-5 col-sm-3 border-main border border-2 d-flex align-items-center justify-content-center">
                <strong class="color-main">
                    Médico Especialista
                </strong>
            </div>
            <div class="col-xl-7 col-sm-9 border-main border border-2  px-0">
                <span class="px-2"> {{ medical_evolutions.doctor_name }} </span>

            </div>
        </div>

        <SweetAlert :swal="swal" ref="SweetAlert" @mtddeleteplan="mtddeleteplan" />
        <cModalCie @mtdaddDiagnosticoUnit="mtdaddDiagnosticoUnit" :dataCies="dataCies" :title="modalCie.title" :boo_modal="modalCie.modal_form" @mtdclosemodalCie="mtdclosemodalCie" />
        <Listen v-show="isListe" />
    </div>
    <div id="floating-button" v-if="typeDoctor == 1">
        <button @click="initCallVoice" class="button_comando">
            <i class="fas fa-microphone i_comando"></i>
        </button>
    </div>
    <div :style="{ display: evolutions.length > 0 ? 'none' : 'block' }">
        <div class="text-center mt-5">
            <h2>
                No se encontraron resultados
            </h2>
        </div>
    </div>
</div>
</template>

<script>
import {
    mapActions
} from 'vuex';
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import cModalCie from '@/components/Asistencial/Emergency/modals/cModalCie.vue';
import Listen from '@/components/loading/Listen.vue';
import validator from 'validator';

const Recognition = window.SpeechRecognition || window.webkitSpeechRecognition;
const sr = new Recognition();

export default {
    name: 'c-asistencial-sop-historia-evolucionmedica',
    data() {
        return {
            dataCies: [],
            diagnostico: null,
            inputchecked: false,
            swal: null,
            dataLaboratorio: [],
            dataImagenes: [],
            dataProcedimiento: [],
            dataInterconsultas: [],
            evolutions: [],
            medical_evolutions: {
                date_name: 'EV ACTUAL',
                id: null,
                medical_act_id: null,
                date: null,
                hour: null,
                day: 0,
                doctor_name: '',
                user_id: null,
                vital_sings: [{
                        id: 1,
                        label: 'FC',
                        value: ''
                    },
                    {
                        id: 2,
                        label: 'T*',
                        value: ''
                    },
                    {
                        id: 3,
                        label: 'FR',
                        value: ''
                    },
                    {
                        id: 4,
                        label: 'SO2',
                        value: ''
                    },
                    {
                        id: 5,
                        label: 'HGT',
                        value: ''
                    },
                    {
                        id: 6,
                        label: 'FIO2',
                        value: ''
                    },
                    {
                        id: 7,
                        label: 'PAS',
                        value: ''
                    },
                    {
                        id: 8,
                        label: 'PAD',
                        value: ''
                    },
                    {
                        id: 9,
                        label: 'PAM',
                        value: ''
                    },
                    {
                        id: 10,
                        label: 'DIURESIS',
                        value: ''
                    },
                    {
                        id: 11,
                        label: 'BH',
                        value: ''
                    },
                ],
                subjective: '',
                plan: [],
            },
            medical_evolutions_plantilla: {
                date_name: 'EV ACTUAL',
                id: null,
                medical_act_id: null,
                date: null,
                hour: null,
                day: 0,
                doctor_name: '',
                user_id: null,
                vital_sings: [{
                        id: 1,
                        label: 'FC',
                        value: ''
                    },
                    {
                        id: 2,
                        label: 'T*',
                        value: ''
                    },
                    {
                        id: 3,
                        label: 'FR',
                        value: ''
                    },
                    {
                        id: 4,
                        label: 'SO2',
                        value: ''
                    },
                    {
                        id: 5,
                        label: 'HGT',
                        value: ''
                    },
                    {
                        id: 6,
                        label: 'FIO2',
                        value: ''
                    },
                    {
                        id: 7,
                        label: 'PAS',
                        value: ''
                    },
                    {
                        id: 8,
                        label: 'PAD',
                        value: ''
                    },
                    {
                        id: 9,
                        label: 'PAM',
                        value: ''
                    },
                    {
                        id: 10,
                        label: 'DIURESIS',
                        value: ''
                    },
                    {
                        id: 11,
                        label: 'BH',
                        value: ''
                    },

                ],
                subjective: '',
                plan: [],
            },
            plan_unit: null,
            plans: {},
            pos: null,
            posdate: null,
            analitica: [],
            plandate: {},
            curva: [],
            chartInstance: null,
            chartCanvas: null,
            validation: false,
            diagnostics_unit: {
                id: "",
                codigo: "",
                name: "",
                cie: "",
                type: "",
            },
            modaldx: {
                title: "",
                modal_form: false,
            },
            modalcomment: {
                title: "",
                modal_form: false,
            },
            modalCie: {
                title: "",
                modal_form: false,
            },
            /* voice */
            tab: 1,
            isListe: false,
            dictado: "",
            input: "",
            modalVoice: {
                title: "GUIA DE DICTADO",
                modal_form: false,
            },
        };
    },
    components: {
        SweetAlert,
        cModalCie,
        Listen
    },
    computed: {
        disabledx: function () {
            return !this.diagnostics_unit.name;
        },
        cpValuePam() {
            let div = null;
            //obtener el promedio 
            let pas = this.medical_evolutions.vital_sings[6].value;
            let pas1 = isNaN(pas) || pas == "" || pas == null ? 0 : pas;
            let pad = this.medical_evolutions.vital_sings[7].value;
            let pad1 = isNaN(pad) || pad == "" || pad == null ? 0 : pad;
            div = (parseFloat(pas1) + parseFloat(pad1)) / 2;
            this.medical_evolutions.vital_sings[8].value = div;
            return isNaN(div) ? null : div;
        }
    },
    props: {
        medical_act_id: {
            type: Number,
            default: 0
        },
        addEv: {
            type: Number,
            default: 0
        },
        dete_out: {
            type: Boolean
        },
        dete_out_doctor: {
            type: Boolean
        },
        rolname: String,
        typeDoctor: {
            type: Number,
            default: 1,
        }
    },
    watch: {
        medical_act_id: {
            handler: function (newValue) {
                if (newValue != 0) {
                    this.mtdgetSubsubcategories();
                    this.mtdday();
                    this.mtdGetEvolutions();
                }
            },
            deep: true,
            immediate: true,
        },
        addEv: {
            handler: function (newValue) {
                if (newValue == 1) {
                    this.mtdstoredata()
                }
            },
            deep: true,
            immediate: true,
        },

    },
    created() {

        //this.setCurrentDateTime();
        this.getPlanes();
    },

    methods: {
        ...mapActions(['get', 'post']),
        createList(items) {
            return `
            
<style>
.custom-list {
    font-size: 15px;
    padding-left: 0;
    text-align: left;
}

.custom-list li {
    margin-bottom: 0.5em;
}
</style>
            <ul class="custom-list">
                ${items.map(item => `<li>${item}</li>`).join('')}
            </ul>`;
        },
        showInfo(title, key) {
            const content = {
                signos: this.createList([
                    'PARA COLOCAR UN DATO EN FRECUENCIA CARDÍACA DECIR <strong>"CARDÍACA"</strong>',
                    'PARA COLOCAR UN DATO EN TEMPERATURA(T(°C)) DECIR <strong>"TEMPERATURA"</strong>',
                    'PARA COLOCAR UN DATO EN FRECUENCIA RESPIRATORIA (F.R) DECIR <strong>"RESPIRATORIA"</strong>',
                    'PARA COLOCAR UN DATO EN SATURACIÓN(SATO2) DECIR <strong>"SATURACIÓN"</strong>',
                    'PARA COLOCAR UN DATO EN HEMOGLUCOTEST DECIR <strong>"GLUCOSA"</strong>',
                    'PARA COLOCAR UN DATO EN FIO 2 DECIR <strong>"FIO"</strong>',
                    'PARA COLOCAR UN DATO EN PRESIÓN SISTÓLICA(PAS) DECIR <strong>"SISTÓLICA"</strong>',
                    'PARA COLOCAR UN DATO EN PRESIÓN DISTÓLICA(PAD) DECIR <strong>"DIASTÓLICA"</strong>',
                    'PARA COLOCAR UN DATO EN DIURESIS DECIR <strong>"DIURESIS"</strong>',
                    'PARA COLOCAR UN DATO EN BALANCE HÍDRICO(BH) DECIR <strong>"BALANCE"</strong>',
                ]),
                subjetivo: this.createList([
                    'PARA COLOCAR UN DATO EN SUBJETIVO DECIR <strong>"SUBJETIVO"</strong>',
                ]),
                objetivo: this.createList([
                    'PARA COLOCAR UN DATO EN ESTADO GENERAL DECIR <strong>"ESTADO"</strong>',
                    'PARA COLOCAR UN DATO EN NEUROLÓGICO DECIR <strong>"NEUROLÓGICO"</strong>',
                    'PARA COLOCAR UN DATO EN RESPIRATORIO DECIR <strong>"RESPIRATORIO"</strong>',
                    'PARA COLOCAR UN DATO EN HEMODINÁMICO DECIR <strong>"HEMODINÁMICO"</strong>',
                    'PARA COLOCAR UN DATO EN ANEXOS DECIR <strong>"ANEXOS"</strong>',
                    'PARA COLOCAR UN DATO EN GASTROINTESTINAL DECIR <strong>"GASTRO"</strong>',
                    'PARA COLOCAR UN DATO EN OTROS DECIR <strong>"OTROS"</strong>',
                ]),
                apreciacion: this.createList([
                    'PARA COLOCAR UN DATO EN APRECIACIÓN DECIR <strong>"APRECIACIÓN"</strong>',
                ]),
            };
            Swal.fire({
                title: title,
                html: content[key],
                icon: 'info',
                confirmButtonText: 'Cerrar'
            });
        },
        mtdhidde: function () {
            this.medical_evolutions = {
                date_name: 'EV ACTUAL',
                id: null,
                date: null,
                hour: null,
                day: 0,
                doctor_name: '',
                user_id: null,
                vital_sings: [{
                        id: 1,
                        label: 'FC',
                        value: ''
                    },
                    {
                        id: 2,
                        label: 'T*',
                        value: ''
                    },
                    {
                        id: 3,
                        label: 'FR',
                        value: ''
                    },
                    {
                        id: 4,
                        label: 'SO2',
                        value: ''
                    },
                    {
                        id: 5,
                        label: 'HGT',
                        value: ''
                    },
                    {
                        id: 6,
                        label: 'FIO2',
                        value: ''
                    },
                    {
                        id: 7,
                        label: 'PAS',
                        value: ''
                    },
                    {
                        id: 8,
                        label: 'PAD',
                        value: ''
                    },
                    {
                        id: 9,
                        label: 'PAM',
                        value: ''
                    },
                    {
                        id: 10,
                        label: 'DIURESIS',
                        value: ''
                    },
                    {
                        id: 11,
                        label: 'BH',
                        value: ''
                    },

                ],
                subjective: '',
                plan: [],
            }
            this.$miVariableGlobalEv.subjective = this.medical_evolutions.subjective
            this.$miVariableGlobalEv.vital_sings = this.medical_evolutions.vital_sings
            this.$miVariableGlobalEv.physical_exam = this.medical_evolutions.physical_exam
            this.$miVariableGlobalEv.current_analytics = this.medical_evolutions.current_analytics
            this.$miVariableGlobalEv.appreciation = this.medical_evolutions.appreciation
            this.$miVariableGlobalEv.diagnostics = this.medical_evolutions.diagnostics
            this.$miVariableGlobalEv.plan = this.medical_evolutions.plan
            this.$miVariableGlobalEv.medical_act_id = null
            this.$miVariableGlobalEv.day = this.medical_evolutions.day
        },
        setCurrentDateTime() {
            const now = new Date();
            const timezoneOffset = now.getTimezoneOffset() * 60000;
            const localDate = new Date(now.getTime() - timezoneOffset);
            this.$miVariableGlobalEv.date = localDate.toISOString().split('T')[0];
            this.$miVariableGlobalEv.hour = now.toTimeString().split(' ')[0];
            this.$miVariableGlobalEv.doctor_name = this.$store.getters.get__user.name + ' ' + this.$store.getters.get__user.last_name;
            this.$miVariableGlobalEv.user_id = this.$store.getters.get__user.id;
            this.$miVariableGlobalEv.medical_act_id = this.medical_act_id;

            this.$miVariableGlobalEv_plantilla.date = localDate.toISOString().split('T')[0];
            this.$miVariableGlobalEv_plantilla.hour = now.toTimeString().split(' ')[0];
            this.$miVariableGlobalEv_plantilla.doctor_name = this.$store.getters.get__user.name + ' ' + this.$store.getters.get__user.last_name;
            this.$miVariableGlobalEv_plantilla.user_id = this.$store.getters.get__user.id;
            this.$miVariableGlobalEv_plantilla.medical_act_id = this.medical_act_id;

        },
        mtdstoredata: function () {
            this.mtdstorecurva(this.curva)
            // this.curva = [];
            // this.mtdhidde()
            // this.setCurrentDateTime();
            // this.mtdday()
            // this.mtdGetEvolutions()
        },

        mtdstorecurva: function (payload) {
            this.post({
                    url: this.$store.getters.get__url + "/Hospitalization/CurvaEv",
                    token: this.$store.getters.get__token,
                    params: payload,
                })
                .then((response) => {
                    this.curva = [];
                    this.mtdhidde()
                    this.setCurrentDateTime();
                    this.mtdday()
                    this.mtdGetEvolutions()
                    this.$emit('mtdaddevexit')
                })
                .catch((errors) => {
                    console.log(errors);
                });
        },

        mtdgetSubsubcategories: function () {
            this.post({
                url: this.$store.getters.get__url + "/Consulting/subsubcategorie",
                token: this.$store.getters.get__token,
            }).then((response) => {
                if (response.statusCode === 200) {
                    this.dataLaboratorio = response.data.dataLaboratorio;
                    this.dataImagenes = response.data.dataImagenes;
                    this.dataProcedimiento = response.data.dataProcedimiento;
                    this.dataInterconsultas = response.data.dataInterconsulta;
                }
            });
        },
        formatDate(date) {
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Los meses comienzan desde 0
            const year = date.getFullYear();
            return `${day}/${month}/${year}`;
        },
        mtdaddPlan: function () {
            const exist = this.medical_evolutions.plan.some(item => item.subsubcategory_id === this.plan_unit.subsubcategory_id);
            if (exist) {
                this.$refs.SweetAlert.showWarning("El item seleccionado a sido agregado");
            } else {
                if (this.plan_unit.category_id == 1) {
                    this.modalcomment = {
                        title: this.plan_unit.name,
                        modal_form: true,
                    }
                    this.plan_unit.medical_act_id = this.medical_act_id
                    this.plans = this.plan_unit
                } else {
                    this.mtdaddComment(this.plan_unit)
                }
            }
            this.plan_unit = null
        },
        mtdaddComment: function (payload) {
            this.mtdclosemodalcomment()
            this.medical_evolutions.plan.push(payload);
            let plananalitica = {
                subsubcategorie_id: payload.subsubcategory_id,
                subsubcategorie_name: payload.name,
                aplication_date: this.formatDate(new Date())
            }
            this.analitica[0].subsubcategories.push(plananalitica)
        },
        mtddelete: function (index, msg) {
            this.$refs.SweetAlert.showDelete(index, msg);
        },
        mtddeleteplan: function (index) {
            this.medical_evolutions.plan.splice(index, 1);
            let newindex = this.posdate == 0 ? index : this.posdate + index
            this.analitica[0].subsubcategories.splice(newindex, 1);
        },
        mtdGetEvolutions: function () {
            this.get({
                url: this.$store.getters.get__url + "/Hospitalization/indexEv/" + this.medical_act_id,
                token: this.$store.getters.get__token,
            }).then((response) => {
                if (response.statusCode === 200) {
                    let dataEvolutions = response.data;
                    this.evolutions = []
                    dataEvolutions.forEach(element => {
                        let evolutiondetails = {
                            doctor_name: element.doctor.name,
                            date_name: element.datename,
                            id: element.id,
                            medical_act_id: element.medical_act_id,
                            date: element.date,
                            hour: element.hour,
                            day: element.day,
                            vital_sings: JSON.parse(element.vital_sings),
                            subjective: element.subjective,
                            physical_exam: JSON.parse(element.physical_exam),
                            current_analytics: JSON.parse(element.current_analytics),
                            appreciation: element.appreciation,
                            diagnostics: JSON.parse(element.diagnostics),
                            plan: JSON.parse(element.plan)
                        }
                        this.evolutions.push(evolutiondetails)
                    });
                    this.medical_evolutions = this.$miVariableGlobalEv
                    this.evolutions.push(this.medical_evolutions);
                    this.pos = this.evolutions.length - 1
                    if (this.dete_out || this.rolname != 'DOCTOR(A)' || this.dete_out_doctor) {
                        this.evolutions.pop();
                        if (this.evolutions.length > 0) {
                            this.backPage()
                        }

                    }

                }
            });
        },
        nextPage: function () {
            this.pos = (this.pos == this.evolutions.length - 1) ? this.pos : this.pos + 1
            this.medical_evolutions = this.evolutions[this.pos]
            this.validation = this.medical_evolutions.date_name != 'EV ACTUAL'
        },

        backPage: function () {
            this.pos = (this.pos == 0) ? this.pos : this.pos - 1
            this.medical_evolutions = this.evolutions[this.pos]
            this.validation = this.medical_evolutions.date_name != 'EV ACTUAL'

        },
        mtdday: function () {
            this.get({
                url: this.$store.getters.get__url + "/Hospitalization/day/" + this.medical_act_id,
                token: this.$store.getters.get__token,
            }).then((response) => {
                if (response.statusCode === 200) {
                    let day = response.data.hospitalizacion.date_entry;
                    let currentDate = new Date();
                    let entryDate = new Date(day);
                    currentDate.setHours(0, 0, 0, 0);
                    entryDate.setHours(0, 0, 0, 0);
                    let differenceInMilliseconds = currentDate - entryDate;
                    let differenceInDays = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));
                    this.medical_evolutions.day = differenceInDays + 1;
                    this.$miVariableGlobalEv.day = this.medical_evolutions.day
                    this.$miVariableGlobalEv_plantilla.day = this.medical_evolutions.day
                    let curvas = response.data.planes
                    curvas.forEach(itemdetails => {
                        let curvadetails = {
                            id: itemdetails.id,
                            date: itemdetails.date,
                            name: itemdetails.subsubcategorie.name,
                            value: itemdetails.result ? itemdetails.result : 0,
                            state: itemdetails.result ? 0 : 1,
                            file: itemdetails.file
                        }
                        this.curva.push(curvadetails);
                    })
                    //this.renderChart();

                }
            });
        },
        getPlanes: function () {
            this.get({
                url: this.$store.getters.get__url + "/Hospitalization/indexplan/" + this.medical_act_id,
                token: this.$store.getters.get__token,
            }).then((response) => {
                if (response.statusCode === 200) {
                    this.analitica = response.data;
                    this.plandate = this.analitica[0]
                    this.posdate = this.analitica[0].subsubcategories.length
                }
            });
        },
        mtdplanedate: function (index) {
            this.plandate = this.analitica[index]
        },
        mtdgetCies: function () {
            if (this.diagnostics_unit.name.length > 4) {
                this.dataCies = [];

                this.get({
                        url: this.$store.getters.get__url + "/Hospitalization/cies/" + this.diagnostics_unit.name,
                        token: this.$store.getters.get__token,
                    })
                    .then((response) => {
                        if (response.statusCode === 200) {
                            this.dataCies = response.data;
                            this.modalCie = {
                                title: "Lista de Diagnostico",
                                modal_form: true,
                            }

                        }

                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        },
        mtdaddDiagnosticoUnit: function (diagnostico) {
            this.diagnostics_unit = {
                id: diagnostico.id,
                codigo: diagnostico.code,
                name: diagnostico.name,
                type: diagnostico.type,
                cie: diagnostico.cie,
            };
            this.modalCie = {
                title: "",
                modal_form: false,
            }
            this.dataCies = [];
        },
        mtdaddDiagnostico: function () {
            const exists = this.medical_evolutions.diagnostics.some(
                (item) => item.id === this.diagnostics_unit.id
            );
            if (exists) {
                this.$refs.SweetAlert.showWarning("Diagnostico ya agregado");
                this.mtdhidediagnosticounit();
                return;
            }
            this.medical_evolutions.diagnostics.push(this.diagnostics_unit);
            this.mtdhidediagnosticounit();
        },
        mtdhidediagnosticounit: function () {
            this.diagnostics_unit = {
                id: "",
                codigo: "",
                name: "",
                cie: "",
                type: "",
            };
            this.diagnostico = null;
        },
        openmodaldx: function () {
            if (this.inputchecked == false) {
                this.modaldx = {
                    title: "Nuevo Diagnostico",
                    modal_form: true,
                };
                this.mtdhidediagnosticounit();
            }
        },
        mtdclosemodaldx: function () {
            this.modaldx = {
                title: "",
                modal_form: false,
            };
            this.inputchecked = false;
        },
        mtdcie: function (itemdetalle) {
            this.diagnostics_unit = {
                id: itemdetalle.id,
                codigo: itemdetalle.code,
                name: itemdetalle.name,
                type: "",
            };
            this.diagnostico = itemdetalle.name;
        },

        mtdclosemodalcomment: function () {
            this.modalcomment = {
                title: "",
                modal_form: false,
            }
        },
        openPdf: function (url) {
            let nuevaUrl = "/assets/" + url;
            window.open(nuevaUrl, '_blank');
        },
        mtdclosemodalCie: function () {
            this.modalCie = {
                title: "",
                modal_form: false,
            }
            this.diagnostics_unit = {
                id: "",
                codigo: "",
                name: "",
                cie: "",
                type: "",
            }
            this.dataCies = [];

        },

        /** voice */
        initCallVoice: function () {
            // this.mtdclosemodalVoice();
            if (
                "webkitSpeechRecognition" in window ||
                "SpeechRecognition" in window
            ) {
                if (this.isListe) {
                    sr.stop();
                    this.isListe = !this.isListe;
                } else {
                    sr.start();
                    this.isListe = !this.isListe;
                }
            } else {
                alert("no compatible");
            }
        },
        /** CONTROL DE COMANDOS */
        CheckForCommand: function (result) {
            const t = result[0].transcript.toUpperCase();
            if (t.includes("TERMINAR")) {
                this.isListe = !this.isListe;
                sr.stop();
            }
        },
        ProcessVocie: function (text) {
            switch (this.tab) {
                case 1:
                    this.ProcessVocie_hoja_uno(text);
                    break;
                default:
                    console.error("Página no reconocida");
                    break;
            }
        },
        ProcessVocie_hoja_uno: function (text) {
            //                   0                1         2                3            4       5        6           7            8            9
            let arHeader = ["CARDÍACA", "TEMPERATURA", "RESPIRATORIA", "SATURACIÓN", "GLUCOSA", "FIO", "SISTÓLICA", "DIASTÓLICA", "DIURESIS", "BALANCE",
                //  10            11            12             13          14        15         16    
                "ESTADO", "NEUROLÓGICO", "RESPIRATORIO", "HEMODINÁMICO", "ANEXOS", "GASTRO", "OTROS",
                // 17              18   
                "SUBJETIVO", "APRECIACIÓN"
            ];

            let arHeaderValues = {};
            console.log(text);

            text = text.toUpperCase().replace("TERMINAR", "").replace("EMPEZAR", "").trim();
            let arText = text.split(" ");

            arHeader.forEach(header => {
                arHeaderValues[header] = "";
            });

            arHeader.forEach(header => {
                const index = arText.indexOf(header);
                if (index !== -1) {
                    const start = index + 1;
                    let end = arText.length;

                    for (let nextHeader of arHeader) {
                        if (nextHeader !== header) {
                            const nextIndex = arText.indexOf(nextHeader);
                            if (nextIndex !== -1 && nextIndex > index) {
                                end = nextIndex;
                                break;
                            }
                        }
                    }

                    const concatenacion = arText.slice(start, end).join(" ").trim();
                    if (concatenacion.length > 0) {
                        arHeaderValues[header] = concatenacion;
                    }
                }
            });

            const updateField = (field, value) => {
                if (value.length > 0) {
                    if (!this.medical_evolutions[field] || this.medical_evolutions[field] === "") {
                        this.medical_evolutions[field] = value;
                    } else {
                        this.medical_evolutions[field] = value;
                    }
                }
            };

            updateField('subjective', arHeaderValues["SUBJETIVO"]);
            updateField('appreciation', arHeaderValues["APRECIACIÓN"]);

            const updateSignosVitales = (index, value) => {

                if (value.length > 0) {
                    if (["CARDÍACA", "TEMPERATURA", "RESPIRATORIA", "SATURACIÓN", "GLUCOSA", "FIO", "SISTÓLICA", "DIASTÓLICA", "DIURESIS", "BALANCE"].includes(arHeader[index]) && !validator.isNumeric(value)) {
                        return;
                    }
                    this.medical_evolutions.vital_sings[index].value = value;
                }
            };

            updateSignosVitales(0, arHeaderValues["CARDÍACA"]);
            updateSignosVitales(1, arHeaderValues["TEMPERATURA"]);
            updateSignosVitales(2, arHeaderValues["RESPIRATORIA"]);
            updateSignosVitales(3, arHeaderValues["SATURACIÓN"]);
            updateSignosVitales(4, arHeaderValues["GLUCOSA"]);
            updateSignosVitales(5, arHeaderValues["FIO"]);
            updateSignosVitales(6, arHeaderValues["SISTÓLICA"]);
            updateSignosVitales(7, arHeaderValues["DIASTÓLICA"]);
            updateSignosVitales(9, arHeaderValues["DIURESIS"]);
            updateSignosVitales(10, arHeaderValues["BALANCE"]);

            const updatePhysicalExam = (index, value) => {
                if (value.length > 0) {
                    this.medical_evolutions.physical_exam[index].state = true;
                    this.medical_evolutions.physical_exam[index].value = value;
                }
            };

            updatePhysicalExam(0, arHeaderValues["ESTADO"]);
            updatePhysicalExam(1, arHeaderValues["NEUROLÓGICO"]);
            updatePhysicalExam(2, arHeaderValues["RESPIRATORIO"]);
            updatePhysicalExam(3, arHeaderValues["HEMODINÁMICO"]);
            updatePhysicalExam(4, arHeaderValues["ANEXOS"]);
            updatePhysicalExam(5, arHeaderValues["GASTRO"]);
            updatePhysicalExam(6, arHeaderValues["OTROS"]);
        },

    }
}
</script>

<style scoped>

    #floating-button {
        position: fixed;
        bottom: 60px;
        right: 20px;
        z-index: 1000;
    }
    .text-start {
        text-align: left;
    }

    .button_comando {
        background-color: #900052;
        color: white;
        border: none;
        border-radius: 50%;
        width: 55px;
        height: 55px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
        cursor: pointer;
        transition: background-color 0.3s;
    }

    .button_comando:hover {
        background-color: #50002d;
        color: #fff;
    }

    .i_comando {
        font-size: 20px;
    }

</style>
