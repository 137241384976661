<template>
<div class="col-md-12">
    <div class="row d-flex">
        <div class="panel-border my-1">
            <div class="row d-flex text-xs">
                <div class="col-md-12 bg-main text-white text-center py-2">
                    <span class="fw-bold">NOTAS DE ENFERMERIA - {{ typeService }}</span>
                </div>
                <!-- <div class="col-xl-9 col-lg-9 col-md-9 col-sm-12 col-xs-12 py-2">
                    <span class="fw-bold">DOCTOR: </span>
                    DOCTOR
                </div> -->
            </div>
        </div>
        <div class="px-0 mt-2" v-if="data.length > 0">

            <!-- Notas registradas -->
            <div class="col-md-12 px-0" id="content-timelinetable">
                <table class="table table-bordered border-main text-xs">
                    <caption class="py-0 my-0"></caption>
                    <thead class="thead-notes">
                        <tr class="text-center color-main">
                            <th class="table-th-number">FECHA</th>
                            <th class="table-th-acciones">HORA</th>
                            <th class="table-th-cxc-report">NOTA</th>
                            <th class="table-th-status">ENFERMERA</th>
                        </tr>
                    </thead>
                    <tbody class="border-main">
                        <tr class="" v-for="(item, index) in cpData" :key="index">
                            <td>
                                {{ item.date | formatDate }}
                            </td>
                            <td class="text-center">
                                {{ item.hour | formatTime }}
                            </td>
                            <td>
                                {{ item.name }}
                            </td>
                            <td>
                                {{ item.doctor_name }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!-- PAGINADO -->
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 text-center px-4">
                <!-- paginado -->
                <div v-if="cpData.length > 0" class="d-flex justify-content-center">
                    <nav aria-label="Page navigation example">
                        <ul class="pagination mb-1">
                            <li class="page-item">
                                <a class="page-link page" href="javascript:void(0)" @click="backPage">Anterior</a>
                            </li>
                            <li v-if="limitedStepPagination[0] !== 1" class="page-item">
                                <a :class="selectPage === 1 ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(1)">1</a>
                            </li>
                            <li v-if="limitedStepPagination[0] > 2" class="page-item disabled">
                                <span class="page-link">...</span>
                            </li>

                            <li v-for="(item, index) in limitedStepPagination" :key="index" class="page-item">
                                <a :class="selectPage === item ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(item)">{{ item }}
                                </a>
                            </li>
                            <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] <stepPagination.length - 1" class="page-item disabled">
                                <span class="page-link">...</span>
                            </li>
                            <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] !== stepPagination.length" class="page-item">
                                <a :class="selectPage === limitedStepPagination.length + 2 ? 'page-link active':'page-link'" href="javascript:void(0)" @click="selectedPage(stepPagination.length)">{{ stepPagination.length }}
                                </a>
                            </li>
                            <li class="page-item">
                                <a class="page-link page" href="javascript:void(0)" @click="nextPage">Siguiente</a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
        <div class=" border border-main p-5" v-else>
            <div class=" text-center">
                <h4>No hay notas de enfermería registradas</h4>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import moment from "moment";
export default {
    name: 'c-medicalAct-reportNotes',
    data() {
        return {
            subpage: "buttons",
            dataShow: [],
            dataNotes: [],
            //Pag
            page: 0,
            selectPage: 1,
            stepPagination: [],
            totalPagesToShow: 4,

        }
    },
    props: {
        data: Array,
        doctor: Object,
        typeService: String,
    },
    watch: {
        data: {
            handler: function (newValue) {
                this.dataNotes = newValue;
                this.calculateData(10);

            },
            immediate: true,
        },
        selectPage() {
            this.calculateData(10);
        },
    },
    computed: {

        cpData() {
            return this.dataShow;
        },
        limitedStepPagination() {
            const totalPages = this.stepPagination.length;
            const currentPage = this.selectPage;

            let startPage = 1;
            let endPage = totalPages;
            if (totalPages > this.totalPagesToShow) {
                const halfPagesToShow = Math.floor(this.totalPagesToShow / 2);

                if (currentPage > halfPagesToShow) {
                    startPage = currentPage - halfPagesToShow;
                    endPage = currentPage + halfPagesToShow - 1;

                    if (endPage > totalPages) {
                        endPage = totalPages;
                        startPage = totalPages - this.totalPagesToShow + 1;
                    }
                } else {
                    endPage = this.totalPagesToShow;
                }
            }

            return Array.from({
                    length: endPage - startPage + 1,
                },
                (_, i) => startPage + i
            );
        },

    },
    methods: {
        returnHome() {
            //window.location.reload();
            this.$emit('mtdChangePage', 'home');
        },
        mtdback(subpage) {
            this.$emit('mtdChangeSubPage', subpage);
        },
        calculateData(items) {

            if (!this.dataNotes || !this.dataNotes.length) {
                return (this.dataShow = []);
            }
            let filteredData = this.dataNotes;
            let indexInitial = this.selectPage;
            let totalPages = Math.ceil(filteredData.length / items);
            this.stepPagination = Array.from({
                    length: totalPages,
                },
                (_, index) => index + 1
            );

            let startIndex = (indexInitial - 1) * items;
            let endIndex = startIndex + (items - 1);
            this.dataShow = filteredData.slice(startIndex, endIndex + 1);
        },

        //Paginacion
        backPage() {
            this.selectPage = this.selectPage > 1 ? this.selectPage - 1 : 1;
        },
        nextPage() {
            this.selectPage =
                this.selectPage < this.stepPagination.length ?
                this.selectPage + 1 :
                this.stepPagination.length;
        },
        selectedPage(page) {
            this.selectPage = page;
        },
    },
    filters: {
        formatDate(date) {
            return date != null ? moment(date).format("DD/MM/YYYY") : "-";
        },
        formatTime(time) {
            let arTime = time.split(":");
            if (arTime[0] <= 12) {
                return time + " am";
            } else {
                return arTime[0] - 12 + ":" + arTime[1] + " pm";
            }
        },
    },

}
</script>
