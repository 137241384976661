<template>
<div>
    <div class="px-5 pt-2 pb-5">
        <div class="row">
            <div class=" btn-home  col-md-12 mt-3 mb-4 d-flex justify-content-end">
                <button type="button" class="btn btn-outline-main" @click="returnHome">
                    <i class="fas fa-home-lg-alt"></i> Inicio
                </button>
            </div>
        </div>
        <div id="div-modal-anular" class="w-100">
            <div id="div-anular" class="card w-100">
                <div class="text-center card-header text-white h4 bg-main">
                    <strong>ORDEN DE COMPRA</strong>
                </div>
                <div class="d-flex justify-content-center">
                    <div class="w-100 border-box-main pt-4" style="background-color: #f2f4f4">
                        <div class="d-flex responsive-search px-4">
                            <div class="input-group input-group mb-3">
                                <span class="input-group-text text-white bg-main"><i class="fas fa-search"></i></span>
                                <input v-model="filter.molecula" @keyup.enter="mtdChangeFilter()" type="text" class="form-control form-control" style="background-color: #f9e79f" placeholder="Nombre / molécula / comercial / marca" />
                            </div>
                            <div class="input-group input-group mb-3 mx-lg-1 w-25">
                                <span class="input-group-text text-white bg-main"><i class="fas fa-search"></i></span>
                                <input v-model="filter.proveedor" @keyup.enter="mtdChangeFilter()" type="text" class="form-control form-control" style="background-color: #aed6f1" placeholder="PROVEEDOR" />
                            </div>
                            <div class="input-group input-group mb-3 me-lg-1 w-25">
                                <span class="input-group-text text-white bg-main"><i class="fas fa-search"></i></span>
                                <input v-model="filter.ruc" @keyup.enter="mtdChangeFilter()" type="text" class="form-control form-control" style="background-color: #abebc6" placeholder="RUC" />
                            </div>
                            <div class="input-group input-group mb-3 w-25">
                                <span class="input-group-text text-white bg-main" @click="mtdChangeFilterOC()"><i class="fas fa-search"></i></span>
                                <input v-model="filter_oc_id" type="text" class="form-control form-control" style="background-color: #f5b7b1" placeholder="O.C." />
                            </div>
                        </div>
                        <div class="cuerpo">
                            <OC v-if="page == 'oc'" :dataordersPendientes="DataordersPendientes" :filter_oc="filter_oc_id" />
                            <Medicamento v-if="page == 'medicamento'" :providerOc="providerOc" :medicaments="Medicaments" @mtdAddItemCarrito="mtdAddItemCarrito" :carrito="carritoOc" @registro-exitoso="manejarRegistroExitoso" @mtdviewcarrito="mtdviewcarrito" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- modal carrito OC -->
    <cModalViewCarritoOrdenCompraVue :numcarrito="numcarrito" :title="modalCarrito.title" :boo_modal="modalCarrito.modal_form" :provider="providerOc" @closeModalCarrito="closeModalCarrito" :carrito="carritoOc" :valorType="modalCarrito.valorType" :valorTypeVen="modalCarrito.valorTypeVen" @registro-exitoso="manejarRegistroExitoso" />
    <cModalPreview :title="modalPreview.title" :boo_modal="modalPreview.modal_form" :orden="carritoOc" @mtdclosemodal="closeModalCarrito" @mtdOpenModalCarrito="mtdOpenModalCarrito" @mtdstoredata="mtdInsertData"></cModalPreview>
    
</div>
</template>

<script>
import OC from "@/components/Logistica/pages/Ordendecompra/Ocpendiente.vue";
import Medicamento from "@/components/Logistica/pages/Ordendecompra/Medicamento.vue";
import {
    mapActions
} from "vuex";
import cModalViewCarritoOrdenCompraVue from "../modals/Oc/cModalViewCarritoOrdenCompra.vue";
import cModalPreview from '../modals/Oc/cModalPreviewOc.vue';

export default {
    name: "c-Logistica-ordendecompra",
    data() {
        return {
            filter: {
                molecula: "",
                proveedor: "",
                ruc: "",
            },
            money : {},
            fiter_oc: '',
            filter_oc_id: "",
            Medicaments: [],
            page: "oc",
            DataordersPendientes: [],
            /** carrito */
            modalCarrito: {
                title: "",
                modal_form: false,
                valorType: null,
                valorTypeVen: null,
            },
            ordenCompras: [],
            itemOrdemCompra: {
                cabecera: {},
                detalle: [],
            },

            providerOc: {
                name: "",
                id: null,
            },
            carritoOc: [],
            itemCarrito: [],
            numcarrito: null,
            /* PREVIEW */
            modalPreview: {
                title: '',
                modal_form: false
            },
        };
    },
    components: {
        OC,
        Medicamento,
        cModalViewCarritoOrdenCompraVue,
        cModalPreview
    },

    created() {
        this.mtdChangeFilterOC();
    },
    methods: {
        ...mapActions(["get", "post"]),
        //metodo retornar a Home
        returnHome: function () {
            if (this.page == "medicamento") {
                Swal.fire({
                    icon: "warning",
                    title: "SI SALE SE PERDERA LOS ITEMS, SI A AGREGADO EN EL CARRITO DE COMPRAS",
                    width: "400px",
                    showDenyButton: true,
                    denyButtonText: `Rechazar`,
                    denyButtonColor: "#bb8a17",
                    confirmButtonText: "Permitir",
                    confirmButtonColor: "#3085d6",
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.$emit("returnHome");
                    }
                });
            } else {
                this.$emit("returnHome");
            }
        },
        changePage(page) {
            this.$emit("changePage", page);
        },
        mtdHide() {
            (this.filter = {
                molecula: "",
                proveedor: "",
                ruc: "",
            }),
            (this.filter_id = "");
        },
        mtdChangeFilter() {
            this.page = "medicamento";
            this.post({
                    url: this.$store.getters.get__url + "/ordendecompra/detalles/filter",
                    token: this.$store.getters.get__token,
                    params: this.filter,
                })
                .then((response) => {
                    this.reformatData(response.data);
                    this.mtdHide();

                })
                .catch((errors) => {});
        },
        reformatData(data) {
            const reformattedData = [];
            data.forEach((product) => {
                if (product.proveedor) {
                    const newProduct = {
                        id: product.id,
                        molecule: product.molecule,
                        dosis: product.dosis,
                        commercial: product.commercial,
                        cargado: product.cargado,
                        brand: product.brand,
                        presentation: product.presentation,
                        concentration: product.concentration,
                        format: product.format,
                        sub_sub_category_id: product.sub_sub_category_id,
                        precio_farmacia_kairos: product.precio_farmacia_kairos,
                        proveedor: {
                            id: product.proveedor.id,
                            name: product.proveedor.name,
                            ruc: product.proveedor.ruc,
                            email: product.proveedor.email ?? '',
                            address: product.proveedor.address ?? '',
                            phone: product.proveedor.phone ?? '',
                        },
                        precio_purchase: product.proveedor.price_purchase,
                        units: product.units,
                        precio_unitario: 0,
                        utilidad: parseFloat(product.precio_farmacia_kairos - product.proveedor.price_purchase).toFixed(2),
                    };
                    reformattedData.push(newProduct);
                }
                // if (product.proveedor_2) {
                //     const newProduct = {
                //         id: product.id,
                //         molecule: product.molecule,
                //         commercial: product.commercial,
                //         cargado: product.cargado,
                //         brand: product.brand,
                //         presentation: product.presentation,
                //         concentration: product.concentration,
                //         format: product.format,
                //         sub_sub_category_id: product.sub_sub_category_id,
                //         precio_farmacia_kairos: product.precio_farmacia_kairos,
                //         proveedor: {
                //             id: product.proveedor_2.id,
                //             name: product.proveedor_2.name,
                //             ruc: product.proveedor_2.ruc,
                //         },
                //         precio_purchase: product.proveedor_2.price_purchase,
                //         precio_unitario: 0,
                //         units: product.units,
                //         utilidad: parseFloat(product.precio_farmacia_kairos - product.proveedor.price_purchase).toFixed(2),
                //     };
                //     reformattedData.push(newProduct);
                // }
            });
            this.Medicaments = reformattedData;

        },
        mtdChangeFilterOC() {
            this.page = "oc";
        },
        /** metodos carrito OC */
        mtdOpenModalCarrito: function () {
            this.mtdTypeChanges();
            this.modalCarrito.modal_form = true;
            this.modalCarrito.title = "ORDENES DE COMPRA ";
            this.modalPreview.modal_form = false;

        },
        closeModalCarrito: function (option,money) {
            this.modalPreview.modal_form = false;
            this.modalCarrito = {
                title: "",
                modal_form: false,
                valorType: null,
                valorTypeVen: null,
            };
            if (this.carritoOc.length == 0) {
                this.numcarrito = null
            }
            if (option == 'preview') {
                this.modalPreview = {
                    title: "ORDEN DE COMPRA",
                    modal_form: true,
                };
                this.money = money;
            }
        },

        mtdAddItemCarrito: function (payload) {
            let presenteProvider = false;
            let indexPresenteProvider = null;

            if (this.ordenCompras.length > 0) {
                this.ordenCompras.forEach((element, index) => {
                    if (element.cabecera.id == payload.proveedor.id) {
                        presenteProvider = true;
                        indexPresenteProvider = index;
                    }
                });
            }

            if (presenteProvider) {
                const isItemPresent = this.ordenCompras[
                    indexPresenteProvider
                ].detalle.some((detail) => detail.id === payload.id);
                if (!isItemPresent) {
                    payload.quantity = 1;
                    payload.price_purchase = payload.precio_unitario;
                    payload.precio_purchase = payload.precio_purchase;
                    payload.precio_unitario = payload.precio_purchase;
                    payload.dosisedit = '';
                    payload.formatedit = '';
                    payload.grabado = true;
                    payload.cargado_state = payload.cargado;
                    payload.discount = null;
                    payload.price_discount = null;
                    payload.unit = payload.units[0].id;
                    if (payload.cargado === null) {
                        payload.cargado = 1
                    };
                    let ultimoDetalle = payload;
                    this.ordenCompras[indexPresenteProvider].detalle.push(ultimoDetalle);
                } else {
                    Swal.fire({
                        title: "El producto ya está en el carrito",
                        text: "error!",
                        icon: "warning",
                        //showConfirmButton: true,
                        width: "400px",
                        //padding: '50px 0',
                        //timer: 2000
                        confirmButtonColor: "rgb(170, 2, 95)",
                    });
                }
            } else {
                let cabecera = {
                    id: payload.proveedor.id,
                    name: payload.proveedor.name,
                    ruc: payload.proveedor.ruc,
                    address: payload.proveedor.address,
                    phone: payload.proveedor.phone,
                    email: payload.proveedor.email,
                    expiration_days: "",
                    total: 0,
                    send: 0,
                    observation: "",
                    payment_condition: "",
                    comment: "",
                    type: 1,
                };
                payload.quantity = 1;
                payload.price_purchase = payload.precio_unitario;
                payload.precio_purchase = payload.precio_purchase
                payload.precio_unitario = payload.precio_purchase;
                payload.dosisedit = '';
                payload.formatedit = '';
                payload.grabado = true;
                payload.cargado_state = payload.cargado;
                payload.discount = null;
                payload.price_discount = null;
                if (payload.cargado === null) {
                    payload.cargado = 1
                };
                payload.unit = payload.units[0].id;
                let detalle = [payload];
                let nuevaOrdenCompra = {
                    cabecera: cabecera,
                    detalle: detalle,
                };
                this.ordenCompras.push(nuevaOrdenCompra);
            }

            this.carritoOc = this.ordenCompras;
            this.numcarrito = this.ordenCompras.length;
            this.mtdOpenModalCarrito();
        },
        mtdviewcarrito: function () {
            this.mtdOpenModalCarrito();
        },
        manejarRegistroExitoso() {
            // Reiniciar los datos de carrito y provider
            this.carritoOc = [];
            this.modalCarrito = {
                title: "",
                modal_form: false,
            };
            this.ordenCompras = [];
            this.itemOrdemCompra = {
                cabecera: {},
                detalle: [],
            };
            this.closeModalCarrito();
            this.mtdChangeFilterOC()
        },
        mtdTypeChanges() {
            this.post({
                    url: this.$store.getters.get__url + "/logistics/typeChanges",
                    token: this.$store.getters.get__token,
                })
                .then((response) => {
                    this.modalCarrito.valorType = response.compra;
                    this.modalCarrito.valorTypeVen = response.venta;
                })
                .catch((errors) => {});
        },
        mtdInsertData: function () {
            this.carritoOc.forEach((carro) => {
                carro.detalle.forEach((detalleItem, ind) => {
                    detalleItem.formatedit = !detalleItem.format ?
                        detalleItem.formatedit :
                        detalleItem.format;
                    detalleItem.dosisedit = !detalleItem.dosis ?
                        detalleItem.dosisedit :
                        detalleItem.dosis;
                });
            });

            this.post({
                    url: this.$store.getters.get__url + "/ordendecompra/store",
                    token: this.$store.getters.get__token,
                    params: {
                        carrito: this.carritoOc,
                        type: this.money.type,
                        name: this.money.name,
                    },
                })
                .then((response) => {
                    if (response.statusCode == 200) {
                        for (let index = 0; index < response.data.length; index++) {
                            const element = response.data[index];
                            window.open(
                                this.$store.getters.get__url +
                                "/ordendecompra/viewpdf/" +
                                element,
                                "_blank"
                            );
                        }
                        Swal.fire({
                            title: "Registro Exitoso",
                            text: "Perfecto!",
                            icon: "success",
                            width: "400px",
                            confirmButtonColor: "rgb(170, 2, 95)",
                        });
                        this.manejarRegistroExitoso();
                    } else {
                        Swal.fire({
                            title: "Registro Incorrecto!",
                            text: "Error!",
                            icon: "error",
                            confirmButtonColor: "rgb(170, 2, 95)",
                        });
                    }
                })
                .catch((errors) => {});
        },
    },
};
</script>
