<template>
<CModal @mtdclosemodal="mtdclosemodal" :show.sync="boo_modal" :close-on-backdrop="false" :centered="false" :title="title" :size="'xl'" :btn_cancel="true" :btn_ok="true" :booTitile="true">
    <!-- <div style="border: none" class="close  mx-2 d-flex justify-content-end" data-bs-dismiss="modal" aria-label="Close" @click="mtdClose()">
        &times;
    </div> -->

    <div class="modal-body mr-2 mb-2 ml-2">
        <div class="row d-flex">
            <div class=" col-lg-4  col-md-12 mb-2 col-xs-12">
                <label for="inputtext" class="mb-3">Campus</label>
                <div class="input-group">
                    <div class="input-group-text">
                        <span style="font-size: 20px; border: none" class="input-group-text"><i class="fa fa-hospital"></i></span>
                    </div>
                    <select class="form-control form-control" v-model="stockinitial.campu_id" @change="mtdChooseAlmacen">
                        <option selected disabled value="">Seleccione ...</option>
                        <option v-for="(item, index) in dataCampus" :key="index" :value="item.id">{{ item.name }}
                        </option>
                    </select>

                </div>
            </div>
            <div class=" col-lg-4 col-md-12 mb-2 col-xs-12">
                <label for="inputtext" class="mb-3">Almacenes </label>
                <div class="input-group">
                    <div class="input-group-text">
                        <span style="font-size: 20px; border: none" class="input-group-text"> <i class="fas fa-warehouse"></i></span>
                    </div>
                    <select class="form-control form-control" v-model="stockinitial.almacen_id">
                        <option selected disabled value="">Seleccione ...</option>
                        <option v-for="(item1, index) in dataAlmacen" :key="index" :value="item1.id">{{ item1.name }}
                        </option>
                    </select>

                </div>
            </div>
            <div class=" col-lg-4 col-md-12 col-xs-12 mb-2">
                <label for="inputtext" class="mb-3">Molecula</label>
                <div class="input-group">
                    <div class="input-group-text">
                        <span style="font-size: 20px; border: none" class="input-group-text"> <i class="fas fa-atom"></i></span>
                    </div>
                    <!-- <select class="form-control form-control" v-model="stockinitial.product_details_id">
                        <option selected disabled value="">Seleccione ...</option>
                        <option v-for="(item2, index) in dataProductDetail" :key="index" :value="item2.id">{{ item2.molecule }}
                        </option>
                    </select> -->
                    <v-select :options="dataProductDetail" class="form-control form-control-sm" :reduce="(item) => item.id" label="molecule" placeholder="-- Seleccione una molecula --" required v-model="stockinitial.product_details_id" />

                </div>
            </div>

            <div class="col-md-12 col-lg-4 col-xs-12 mb-2">
                <label for="date" class="mb-3">Fecha de Inventario</label>
                <div class="input-group">
                    <div class="input-group-text">
                        <span style="font-size: 20px; border: none" class="input-group-text"><i class="fas fa-calendar fa-xs "></i></span>
                    </div>
                    <input v-model="stockinitial.date_inventary" type="date" class="form-control" name="date" />
                </div>
            </div>
            <div class="col-md-12 col-lg-4 col-xs-12 mb-2">
                <label for="date" class="mb-3">Fecha de Expiracion</label>
                <div class="input-group">
                    <div class="input-group-text">
                        <span style="font-size: 20px; border: none" class="input-group-text"><i class="fas fa-calendar fa-xs "></i></span>
                    </div>
                    <input v-model="stockinitial.expiration" type="date" class="form-control" name="date" />
                </div>
            </div>
            <div class="col-md-12 col-lg-4 col-xs-12 mb-2">
                <label for="lote" class="mb-3">Lote</label>
                <div class="input-group">
                    <div class="input-group-text">
                        <span style="font-size: 20px; border: none" class="input-group-text"><i class="fas fa-cubes fa-xs "></i></span>
                    </div>
                    <input v-model="stockinitial.lote" type="text" class="form-control" name="lote" placeholder="Lote" />
                </div>
            </div>
            <div class="col-md-12 col-lg-4 col-xs-12 mb-2">
                <label for="register" class="mb-3">Registro Sanitario</label>
                <div class="input-group">
                    <div class="input-group-text">
                        <span style="font-size: 20px; border: none" class="input-group-text"><i class="fas fa-file-medical fa-xs "></i></span>
                    </div>
                    <input v-model="stockinitial.register_sanitary" type="text" class="form-control" name="register" placeholder="Registro Sanitario" />
                </div>
            </div>
            <div class="col-md-12 col-lg-4 col-xs-12 mb-2">
                <label for="barcode" class="mb-3">Codigo de Barras</label>
                <div class="input-group">
                    <div class="input-group-text">
                        <span style="font-size: 20px; border: none" class="input-group-text"><i class="fas fa-barcode fa-xs "></i></span>
                    </div>
                    <input v-model="stockinitial.barcode" type="text" class="form-control" name="barcode" placeholder="Codigo de Barras" />
                </div>
            </div>

            <div class="col-md-12 col-lg-2 col-xs-12 mb-2">
                <label for="correo" class="mb-3"> Cantidad en Fisico</label>
                <div class="input-group">
                    <div class="input-group-text">
                        <span style="font-size: 20px; border: none" class="input-group-text"><i class="fas fa-database fa-xs "></i></span>
                    </div>
                    <input v-model="stockinitial.quantity" type="number" class="form-control" name="correo" min="0" value="0" />
                </div>
            </div>
            <div class="col-md-12 col-lg-2 col-xs-12 mb-2">
                <label for="cantidad" class="mb-3">Cantidad del Sistema</label>
                <div class="input-group">
                    <div class="input-group-text">
                        <span style="font-size: 20px; border: none" class="input-group-text"><i class="fas fa-database fa-xs "></i></span>
                    </div>
                    <input v-model="stockinitial.quantity_system" type="number" class="form-control" name="cantidad" min="0" value="0" />
                </div>
            </div>

        </div>
        <div class="d-flex">
            <button id="btn-close" type="button" class="btn btn-secondary w-50 me-1 mt-5 mb-4" @click="mtdclosemodal()">
                Cerrar
            </button>
            <button type="button" class="btn mt-5 mb-4 w-50 btn-register text-white" :disabled="cpButtonForm" @click="mtdInsertData">
                {{ action == "store" ? "Registrar" : "Editar" }}
            </button>
        </div>
    </div>

</CModal>
</template>

<script>
import CModal from "@/components/shared/modals/CModal.vue";
import CButtonClose from "@/components/shared/modals/cButtonClose.vue";
import {
    mapActions
} from "vuex";

export default {
    name: "c-addStockInicial",
    components: {
        CModal,
        CButtonClose,
    },
    data() {
        return {
            ind: null,
            end: null,
            stockinitial: {
                id: "",
                almacen_id: "",
                campu_id: "",
                product_details_id: "",
                year: "",
                date_inventary: "",
                lote: "",
                expiration: "",
                register_sanitary: "",
                barcode: "",
                quantity: 0,
                quantity_system: 0,
                temperature: "",
            },
            dataAlmacen: [],
            dataProductDetail: [],
        };
    },
    props: {
        title: String,
        boo_modal: Boolean,
        dataCampus: Array,
        payload: Object,
        action: String,

    },
    watch: {
        payload: {
            handler(newVal) {
                if (this.action == 'edit') {
                    if (newVal) {
                        this.stockinitial = newVal;
                    }
                }
                this.mtdChooseAlmacen();
                this.mtdGetProductDetail();
            },
            immediate: true,
        },
    },
    computed: {
        cpButtonForm: function () {
            if (this.stockinitial.almacen_id == '' || this.stockinitial.campu_id == '' ||
                this.stockinitial.product_details_id == '' ||
                this.stockinitial.date_inventary == '') {
                return true;
            }

            return false;
        },
    },

    methods: {
        ...mapActions(["get", "post"]),
        mtdclosemodal() {
            this.mtdHideModalStockInitial();
            this.$emit('mtdCloseModalStockInitial');
        },
        mtdChooseAlmacen: function () {
            this.dataAlmacen = [];
            this.dataCampus.forEach(element => {
                if (element.id === this.stockinitial.campu_id) {
                    this.dataAlmacen = element.almacenes;
                }
            });
        },
        mtdGetProductDetail: function () {
            this.get({
                    url: this.$store.getters.get__url + "/logistics/maintenance/stockinitial",
                    token: this.$store.getters.get__token,
                })
                .then((response) => {
                    this.dataProductDetail = response.data;
                })
                .catch((errors) => {});
        },
        mtdHideModalStockInitial: function () {

            this.stockinitial = {
                id: "",
                almacen_id: "",
                campu_id: "",
                product_details_id: "",
                year: "",
                date_inventary: "",
                lote: "",
                expiration: "",
                register_sanitary: "",
                barcode: "",
                quantity: 0,
                quantity_system: 0,
                temperature: "",
            }
        },

        mtdInsertData: function () {
            this.$emit("mtdInsertData", this.stockinitial);
            this.mtdHideModalStockInitial();
        }

    },
};
</script>
