<template>
    <div>
        <div class="card-body" v-if="view == 'data'">
            <div class="row">
                <div class="col-md-12 py-3" style="color: #900052; text-align: center; position: relative;">
                    <div id="floorSelect" class="d-flex justify-content-evenly">
                        <ul class="nav nav-pills">
                            <li v-for="(item, index) in dataFloor" :key="index" class="nav-item"
                                @click="mtdSelectGroup(item.id)">
                                <a :class="tab == item.id ? 'nav-link active' : 'nav-link'" aria-current="page"
                                    href="javascript:void(0)">{{ item.name }}</a>
                            </li>
                        </ul>
                    </div>
                    <div class="superior-derecha">
                        <span class="libre mt-2"></span> Libre &nbsp;
                        <span class="reservado mt-2"></span> Reservado &nbsp;
                        <span class="ocupado mt-2"></span> Ocupado &nbsp;
                        <span class="limpieza mt-2"></span> En Limpieza
                    </div>
                </div>
            </div>

            <div class="row tab-content pb-3" id="camas" v-if="tab != 0">
                <div class="tab-pane fade show active" v-bind:id="tab + '-tab-pane'" role="tabpanel"
                    v-bind:arialabeledby="tab + '-tab'" tabindex="0">
                    <div align="center" class="row m-2 p-2 d-flex justify-content-center">
                        <div class="card m-3 p-2" v-for="(itemg, index) in dataBedGroupsD" :key="index" :style="'border: 2px solid #900052; max-width: 400px; max-height: ' +
                            180 * itemg.beds.length + 'px'" v-if="itemg.beds.length > 0">
                            <div v-for="(itemd, indexb) in itemg.beds" :key="indexb">
                                <div class="row g-0 align-items-center">
                                    <div class="col-md-7 d-flex flex-column justify-content-center align-items-center">
                                        <div>
                                            <svg
                                                class="img-fluid rounded-start"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 448 512"
                                                width="100px"
                                            >
                                                <path
                                                    fill="#900052"
                                                    d="M32 32c17.7 0 32 14.3 32 32l0 256 224 0 0-160c0-17.7 14.3-32 32-32l224 0c53 0 96 43 96 96l0 224c0 17.7-14.3 32-32 32s-32-14.3-32-32l0-32-224 0-32 0L64 416l0 32c0 17.7-14.3 32-32 32s-32-14.3-32-32L0 64C0 46.3 14.3 32 32 32zm144 96a80 80 0 1 1 0 160 80 80 0 1 1 0-160z"
                                                />
                                            </svg>
                                        </div>
                                    </div>
                                    <div class="col-md-5">
                                        <div class="card-body">
                                            <div v-if="itemd.status == 4">
                                                <span class="room card-text h5 circle p-2 text-white"
                                                    style="background: orange; font-size: 1.25rem">{{ itemd.name }}</span>
                                            </div>
                                            <div v-else-if="itemd.status == 2">
                                                <span class="room card-text h5 circle p-2 text-white"
                                                    style="background: red; font-size: 1.25rem"
                                                    v-if="itemd.medical_act_id"
                                                    @click="mtdGetDataMedicalAct(itemd.patient_id, itemd.medical_act_id)">
                                                    {{ itemd.name }}
                                                </span>
                                                <span class="card-text h5 circle p-2 text-white"
                                                    style="background: red; font-size: 1.25rem" v-else>
                                                    {{ itemd.name }}
                                                </span>
                                            </div>
                                            <div v-else-if="itemd.status == 3">
                                                <span class="card-text h5 circle p-2 text-white"
                                                    style="background: blue; font-size: 1.25rem">{{ itemd.name }}</span>
                                            </div>
                                            <div v-else>
                                                <span class="card-text h5 circle p-2 text-white"
                                                    style="background: green; font-size: 1.25rem">{{ itemd.name }}</span>
                                            </div>
                                        </div>
                                        <div class="col-md-12" v-if="itemd.status == 4 || itemd.status == 2">
                                            <span class="text-paciente" style="font-size: 13.5px">{{ itemd.patient }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container" v-else-if="view == 'devolucion'">
            <cDevolucion
                :patient_id="patient_id"
                :id_medical="medical_act_id"
                :type="type"
                @mtdBack="mtdBack"
            />
        </div>
        <SweetAlert :swal="swal" ref="SweetAlert" />
    </div>
</template>

<script>
import {
    mapActions
} from 'vuex'
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import cDevolucion from "@/components/Farmacia/pages/DevAsist.vue";
export default {
    name: 'c-hospitalizacion-cola',
    data() {
        return {
            rol: null,
            swal: null,
            tab: 0,
            dataFloor: [],
            dataBedGroupsD: [],
            verifSelectBed: false,
            oldPosSelectBed: [],
            mostrar: false,
            reservation_id: null,
            hospitalization_id: null,
            view: "data",
            patient_id: null,
            medical_act_id: null
        }
    },
    mounted() {
        const tooltips = document.querySelectorAll('[data-toggle="tooltip"]');
        if (tooltips) {
            tooltips.forEach(tooltip => {
                tooltip.addEventListener('mouseenter', () => {
                });
                tooltip.addEventListener('mouseleave', () => {
                });
            });
        }
    },
    created() {
        this.mtdGetData();
    },
    props: {
        rolname: String,
        type: Number
    },
    components: {
        SweetAlert,
        cDevolucion
    },
    methods: {
        ...mapActions(['get', 'post']),

        mtdGetData: function () {
            this.get({
                url: this.$store.getters.get__url + "/admision/floorUci/" + this.$store.getters.get__campus,
                token: this.$store.getters.get__token,
            })
            .then((response) => {
                this.dataFloor = response.floors;
            })
            .catch((errors) => {
                this.$refs.SweetAlert.showError(errors);
            });
        },
        mtdGetDataMedicalAct: function (patient_id, medical_act_id) {
            this.$emit("openDev", patient_id, medical_act_id);
        },

        mtdSelectGroup: function (id) {
            this.post({
                url: this.$store.getters.get__url + "/admision/bedgroup/filterUci",
                token: this.$store.getters.get__token,
                params: {
                    floor_id: id,
                },
            })
            .then((response) => {
                this.dataBedGroupsD = response.bedgroups_details;
                this.tab = id;
            })
            .catch((errors) => {
                this.$refs.SweetAlert.showError(errors);
            });
        },

        
        mtdBack() {
            this.view = "data";
        },
    }
}
</script>

<style scoped>
.nav-link:hover {
    color: #ffffff !important;
}

.nav-link {
    color: #900052 !important;
    font-weight: bold;
}

.nav-link.active {
    background: #900052 !important;
    color: #ffffff !important;
}

.circle {
    background: lightblue;
    border-radius: 50%;
    width: 100px;
    height: 100px;
}

.circle_button {
    border-radius: 50%;
    width: 35px;
    height: 35px;
}

.superior-derecha {
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px;
}

.libre {
    display: inline-block;
    width: 20px;
    height: 20px;
    background-color: green;
    border-radius: 50%;
}

.reservado {
    display: inline-block;
    width: 20px;
    height: 20px;
    background-color: orange;
    border-radius: 50%;
}

.ocupado {
    display: inline-block;
    width: 20px;
    height: 20px;
    background-color: red;
    border-radius: 50%;
}

.limpieza {
    display: inline-block;
    width: 20px;
    height: 20px;
    background-color: blue;
    border-radius: 50%;
}
.containerSvg {
    width: 90px;
    height: 90px;
}
</style>
