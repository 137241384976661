<template>
    <div>
      <nav id="nav-menu" class="fixed-bottom py-0 d-flex justify-content-around mt-2">
        <div :style="mtdGetStyle('descuento')" class="navbar-brand pl-2 text-white text-center pr-2 nav-footer w-100 border-end border-white border" @click="mtdSendOption('descuento')"><span :style="mtdGetStyleSpan('descuento')" class="btn w-100"><strong>DESCUENTOS</strong></span></div>
        <div :style="mtdGetStyle('personal')" class="navbar-brand pl-2 text-white text-center pr-2 nav-footer w-100 border-end border-white border" @click="mtdSendOption('personal')"><span :style="mtdGetStyleSpan('personal')" class="btn w-100"><strong>PERSONAL</strong></span></div>
        <!-- <div :style="mtdGetStyle('planilla')" class="navbar-brand pl-2 text-white text-center pr-2 nav-footer w-100 border-end border-white border" @click="mtdSendOption('planilla')"><span :style="mtdGetStyleSpan('planilla')" class="btn w-100"><strong>PLANILLA</strong></span></div>
        <div :style="mtdGetStyle('horario')" class="navbar-brand pl-2 text-white text-center pr-2 nav-footer w-100 border-end border-white border" @click="mtdSendOption('horario')"><span :style="mtdGetStyleSpan('horario')" class="btn w-100"><strong>HORARIO</strong></span></div> -->
        <div :style="mtdGetStyle('contrato')" class="navbar-brand pl-2 text-white text-center pr-2 nav-footer w-100 border-end border-white border" @click="mtdSendOption('contrato')"><span :style="mtdGetStyleSpan('contrato')" class="btn w-100"><strong>CONTRATO</strong></span></div>
        <!-- <div :style="mtdGetStyle('vacaciones')" class="navbar-brand pl-2 text-white text-center pr-2 nav-footer w-100 border-end border-white border" @click="mtdSendOption('vacaciones')"><span :style="mtdGetStyleSpan('vacaciones')" class="btn w-100"><strong>VACACIONES</strong></span></div> -->
        <div :style="mtdGetStyle('mantenimiento')" class="navbar-brand pl-2 text-white text-center pr-2 nav-footer w-100 border-end border-white border" @click="mtdSendOption('mantenimiento')"><span :style="mtdGetStyleSpan('mantenimiento')" class="btn w-100"><strong>MANTENIMIENTO</strong></span></div>
      </nav>
    </div>
</template>
<script>
export default {
  name: 'FooterRecursosHumanosComponent',
  props: {
    pageView: String
  },
  data () {
    return {
      page: null
    }
  },
  created () {
   
  },
  methods: {
    mtdGetStyle:function(option){
      if (option == this.page && this.pageView != "home") return 'background: #900052';
      return '';
    },
    mtdGetStyleSpan:function(option){
      if (option == this.page && this.pageView != "home") return 'color:white';
      return '';
    },
    mtdSendOption: function(page){
      this.page=page;
      this.$emit('mtdChangePage',this.page)
    }
  },
}
</script>