<template lang="es">
<CModal @mtdclosemodal="mtdClose" :show="boo_modal" :close-on-backdrop="false" :centered="false" :title="title" :size="'xl'" :btn_cancel="true" :btn_ok="true" :booTitile="true">

    <div class="row d-flex">

        <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12 mt-1">
            <label class="mb-3 mt-2">TIPO DE DOCUMENTO</label>
            <div class="input-group input-group-sm">
                <div class="input-group-text">
                    <span style="font-size: 20px; border: none" class="input-group-text">
                        <i class="fa fa-id-card"></i>
                    </span>
                </div>
                <input type="text" class="form-control" placeholder="Tipo de Documento" v-model="data_detail.documento" disabled />
            </div>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12 mt-1">
            <label class="mb-3 mt-2">DNI</label>
            <div class="input-group input-group-sm">
                <div class="input-group-text">
                    <span style="font-size: 20px; border: none" class="input-group-text">
                        <i class="fa fa-id-card"></i>
                    </span>
                </div>
                <input type="text" class="form-control" name="documento" placeholder="Ingrese documento y presione enter" v-model="data_detail.dni" @keypress.enter="mtdSearchDocEmployee" maxlength="12" />
            </div>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12 mt-1">
            <label class="mb-3 mt-2">ROL PRINCIPAL</label>
            <div class="input-group input-group-sm">
                <div class="input-group-text">
                    <span style="font-size: 20px; border: none" class="input-group-text">
                        <i class="fa fa-address-book"></i>
                    </span>
                </div>
                <select class="form-control form-control-sm" aria-label="Default select example" v-model="data_detail.role_id">
                    <option disabled selected value="">Seleccione...</option>
                    <option v-for="(item, index) in dataRoles" :key="index" :value="item.id">
                        {{ item.name }}
                    </option>
                </select>
            </div>
        </div>
    </div>

    <div class="row d-flex">
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 mt-1">
            <label class="mb-3 mt-2">NOMBRES</label>
            <div class="input-group input-group-sm">
                <div class="input-group-text">
                    <span style="font-size: 20px; border: none" class="input-group-text">
                        <i class="fa fa-user"></i>
                    </span>
                </div>
                <input type="text" class="form-control" name="nombre" placeholder="Nombre" v-model="data_detail.name" :disabled="disabledEmployee" />
            </div>
        </div>

        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 mt-1">
            <label class="mb-3 mt-2">APELLIDOS</label>
            <div class="input-group input-group-sm">
                <div class="input-group-text">
                    <span style="font-size: 20px; border: none" class="input-group-text">
                        <i class="fa fa-user"></i>
                    </span>
                </div>
                <input type="text" class="form-control" name="last_name" placeholder="Apellido" v-model="data_detail.last_name" :disabled="disabledEmployee" />
            </div>
        </div>

        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 mt-1">
            <label class="mb-3 mt-2">EMAIL</label>
            <div class="input-group input-group-sm">
                <div class="input-group-text">
                    <span style="font-size: 20px; border: none" class="input-group-text">
                        <i class="fa fa-user"></i>
                    </span>
                </div>
                <input type="email" class="form-control" name="email" placeholder="Email" v-model="data_detail.email" />
            </div>
        </div>
        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 mt-1">
            <label class="mb-3 mt-2 form-label" for="formFile">FIRMA</label>
            <div class="input-group input-group-sm">
                <div class="input-group-text">
                    <span style="font-size: 20px; border: none" class="input-group-text">
                        <i class="fa fa-signature"></i>
                    </span>
                </div>
                <div class="input-group-text">
                    <input class="form-control form-control-sm" type="file" id="formFile" ref="fileInput" lang="es" name="file" @change="getFile">
                </div>
            </div>
        </div>
    </div>

    <template #footer>
        <button class="btn btn-success btn-sm" @click="DataDetail" :disabled="cpButton">
            {{ (num == 0 ) ? 'Registrar':'Editar' }}
        </button>
        &nbsp;
    </template>
    <SweetAlert :swal="swal" ref="SweetAlert" />
</CModal>
</template>

<script>
import CModal from "../../shared/modals/CModal.vue";
import SweetAlert from "@/components/shared/SweetAlert/SweetAlert.vue";
import {
    mapActions
} from "vuex";

export default {
    name: "c-maintenance-modal-documento",
    components: {
        CModal,
        SweetAlert
    },
    data() {
        return {
            disabledEmployee: true,
            data_detail: {
                employee_id: null,
                role_id: '',
                documento: "DNI",
                dni: '',
                name: '',
                last_name: '',
                email: '',
                file: "",
            },
            swal: "",
        };
    },
    watch: {
        payload: {
            handler(newVal) {
                if (this.num == 1) {
                    if (newVal) {
                        this.data_detail = {
                            ...newVal
                        };
                    }
                }
            },
            immediate: true,
        },
    },
    props: {
        title: String,
        boo_modal: Boolean,
        num: Number,
        payload: {
            type: Object,
            default: null
        },
        dataRoles: {
            type: Array,
            default: null
        },
    },
    computed: {
        cpButton() {
            return !(this.data_detail.role_id != '' && this.data_detail.documento != '' && this.data_detail.dni.length == 8 && this.data_detail.name != '' &&
                this.data_detail.last_name != '' &&
                this.data_detail.email != ''
            );
        }
    },
    methods: {
        ...mapActions(["get", "post"]),
        getFile(e) {
            let fileInput = this.$refs.fileInput;
            let file = e.target.files[0];
            if (!/\.(jpeg)$/i.test(file.name) && !/\.(png)$/i.test(file.name) && !/\.(jpg)$/i.test(file.name) && !/\.(jpeg)$/i.test(file.name)) {
                this.$refs.SweetAlert.showInfo('Seleccione un archivo formato JPEG, PNG O JPG');
                fileInput.value = '';
            } else {
                this.data_detail.file = file;
            }
        },
        mtdClose() {
            this.data_detail.employee_id = null,
            this.data_detail.role_id = '';
            this.data_detail.documento = '';
            this.data_detail.dni = '';
            this.data_detail.name = '';
            this.data_detail.last_name = '';
            this.data_detail.email = '';
            this.data_detail.file ='';
            this.$emit("closeModal");
        },
        DataDetail() {
            this.$emit('Datadetail', this.data_detail, this.num);
            this.mtdClose();
        },
        filterKey: function (e) {
            let b = false;
            b = /^[0-9]+$/.test(e.key);
            if (b == false && e.key != "Backspace") {
                e.preventDefault();
            }
        },
        mtdSearchDocEmployee: function () {
            this.get({
                url: this.$store.getters.get__url + "/RecursosHumanos/getDocEmployee/" + this.data_detail.dni,
                token: this.$store.getters.get__token,
            })
            .then((response) => {
                if (response.data.boo == 0) {
                    this.$refs.SweetAlert.showWarning("El colaborador: "+response.data.name+" "+response.data.last_name+" se encuentra inhabilitado.");
                    this.disabledEmployee = true;
                    this.data_detail.name = "";
                    this.data_detail.last_name = "";
                    this.data_detail.employee_id = null;
                }else
                if (response.data.boo == 1) {
                    if (response.data.hasOwnProperty("employee")) {
                        this.data_detail.name = response.data.name;
                        this.data_detail.last_name = response.data.last_name;
                        this.data_detail.employee_id = response.data.id;
                    } else {
                        this.data_detail.name = "";
                        this.data_detail.last_name = "";
                        this.data_detail.employee_id = null;
                        this.disabledEmployee = true;
                    }
                }else
                if (response.data.boo == 2) {
                    this.$refs.SweetAlert.showWarning("El colaborador: "+response.data.name+" "+response.data.last_name+" ya tiene usuario creado.");
                    this.disabledEmployee = true;
                    this.data_detail.name = "";
                    this.data_detail.last_name = "";
                    this.data_detail.employee_id = null;
                }else
                if (response.data.boo == 3) {
                    this.$refs.SweetAlert.showWarning("Documento no encontrado.");
                    this.disabledEmployee = true;
                    this.data_detail.name = "";
                    this.data_detail.last_name = "";
                    this.data_detail.employee_id = null;
                } else {
                    this.$refs.SweetAlert.showWarning("Error al buscar empleado.");
                    this.disabledEmployee = true;
                    this.data_detail.name = "";
                    this.data_detail.last_name = "";
                    this.data_detail.employee_id = null;
                }
            })
            .catch((errors) => {
                console.log(errors);
            });
        },
    },

};
</script>
