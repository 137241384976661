<template>
<div>
    <div class="w-100 h-100">
        <Nav :rolname="rolname" />
    </div>
    <!-- <div class="content">
        <Home v-if="page=='home'" @mtdChangePage="mtdChangePage" @mtdBackCampus="mtdBackCampus"/>
        <Planilla v-if="page=='planilla'" @mtdChangePage="mtdChangePage" />
    </div> -->
    <div class="content">
      <Home v-if="page == 'home'" @mtdChangePage="mtdChangePage" @mtdBackCampus="mtdBackCampus" />
      <StaffView v-if="page == 'personal'" @mtdChangePage="mtdChangePage" />
      <PayrollView v-if="page == 'planilla'" @mtdChangePage="mtdChangePage" />
      <Descuento v-if="page=='descuento'" @mtdChangePage="mtdChangePage" />
      <ScheduleView v-if="page == 'horario'" @mtdChangePage="mtdChangePage" />
      <ContractView v-if="page == 'contrato'" @mtdChangePage="mtdChangePage" />
      <VacationsView v-if="page == 'vacaciones'" @mtdChangePage="mtdChangePage" />
      <MaintenanceView v-if="page == 'mantenimiento'" @mtdChangePage="mtdChangePage" />
    </div>
    <div class="footer">
        <Footer @mtdChangePage="mtdChangePage" :pageView="page" />
    </div>
</div>
</template>

<script>
import Nav from '../../components/Asistencial/Nav.vue';
import Descuento from '../../components/RecursosHumanos/Pages/Discount.vue'
import StaffView from '@/components/RecursosHumanos/Pages/Staff.vue';
import PayrollView from '@/components/RecursosHumanos/Pages/Payroll.vue';
import ScheduleView from '@/components/RecursosHumanos/Pages/Schedule.vue';
import ContractView from '@/components/RecursosHumanos/Pages/Contract.vue';
import VacationsView from '@/components/RecursosHumanos/Pages/Vacations.vue';
import MaintenanceView from '@/components/RecursosHumanos/Pages/Maintenance.vue';
import Home from '../../components/RecursosHumanos/Home.vue'
import Footer from '../../components/RecursosHumanos/Footer.vue'

export default {
    name: "v-recursosHumanos",
    components: {
        Nav,
        Home,
        Descuento,
        Footer,
        StaffView,
        PayrollView,
        ScheduleView,
        ContractView,
        VacationsView,
        MaintenanceView
    },
    data() {
            return {
                page: "home",
                rol_name: null,
            };
        },
        props: {
            rolname: String,
            dataRoles: Array,
        },
        methods: {
    changePage: function (page) {
      this.page = page;
    },
    returnHome: function () {
      this.page = "home";
    },
    mtdBackCampus: function () {
      this.$emit("mtdBackCampus");
    },
    mtdChangePage: function (page) {
      this.page = page;
    },
  },
}
</script>
