<template>
  <div class="container px-4">
    <div class="container mt-5" v-if="page == 'venta'">
      <div id="div-modal-venta" style="margin-top: -15px" class="d-flex justify-content-center">
        <div id="div-venta" class="card w-100">
          <div align="center" class="card-header text-white h5" style="background: #900052">
            <strong>VENTA</strong>
          </div>
          <div class="card-body" style="background-color: #e6e6e6">
            <div class="row" v-if="page == 'venta'">
              <div class="col-md-6" id="div-venta-body">
                <div class="col-md-12">
                  <div class="input-group input-group-sm mb-1">
                    <span class="input-group-text" style="background: #900052; color: #ffffff"><i
                        class="fas fa-search"></i></span>
                    <input type="search" id="search_product" class="form-control" placeholder="Digitar Venta"
                      aria-describedby="search_product" v-model="search_product" @keyup="mtd_filter_product" />
                  </div>
                </div>

                <div class="row py-1" id="list-product">
                  <div v-for="(item, index) in data_product_filter" :key="index"
                    class="col-md-12 py-1 d-flex align-items-center" id="item-product"
                    style="background-color: #e6e6e6; height: 80px">
                    <div class="p-1 d-flex flex-column w-100 justify-content-center"
                      style="background-color: #ffffff; height: 100%" @click="mtdSelectSubsubcatergorie(item)">
                      <div class="align-items-center" id="name-product">
                        <p class="d-flex align-items-center mb-0 m-1">
                          <strong class="mb-0  text-white"
                            style="text-align: CENTER; width: 12%; border-radius: 10px; background-color: #900052">
                            S/{{ item.precio_farmacia_particular }}
                          </strong>
                          <span style="width: 5%">&nbsp;</span>
                          <!-- Espacio entre precio y nombre -->
                          <strong
                            class="mb-0 item-name custom-tooltip d-flex justify-content-between align-items-center"
                            data-toggle="tooltip" :data-placement="'top'" :title="item.name" :class="{
                              'text-success': item.total_quantity > 5,
                              'text-danger': item.total_quantity === 0,
                              'text-custom-light-green': item.total_quantity <= 5 && item.total_quantity > 0
                            }" style="
                                  width: 88%;
                                  margin-left: 10px;
                                  font-size: 11.5px;
                                  margin-top: 10px;
                                ">
                            {{ item.name }}
                            &nbsp;
                            <span style="font-weight: bold; font-size: 14px; text-align: right;">
                              ({{ item.total_quantity }})
                            </span>
                          </strong>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- carrito -->
              <div class="col-md-6" id="div-venta-body">
                <div class="col-md-12">
                  <div class="input-group input-group-sm mb-3">
                    <span class="input-group-text rounded-pill w-100"
                      style="background: #900052; color: #ffffff; padding-left: 25px"><strong>MEDICAMENTOS ESCOGIDOS</strong></span>
                  </div>
                </div>
                <div class="col-md-12 list-monto">
                  <div class="container-list">
                    <div class="" v-if="carrito.length > 0">
                      <div class="box item-monto" v-for="(item, index) in carrito" :key="index">
                        <p class="text-monto">({{ item.quantity }}) {{ item.producto }}</p>
                        <div class="input-group" style="">
                          <span class="input-group-text" :class="posEditPrice != index && posobs != index &&
                            itemDiscount != index ? '' : 'col-md-12'" style="color: #8a8a97">
                            <strong v-if="posEditPrice != index && posobs != index &&
                              itemDiscount != index">
                              <template v-if="item.location">
                                <div
                                  style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; max-width: 250px;"
                                  :title="item.location">
                                  Ubicación: {{ item.location }} <br />
                                </div>
                              </template>
                              Precio S/ {{ parseFloat(item.price).toFixed(2) }} <br />
                              Desc. S/ {{ parseFloat(item.montodescuento).toFixed(2) }}<br />
                              SubTotal S/
                              {{
                                parseFloat(item.subtotal).toFixed(2)
                              }}</strong>
                            <input v-if="posEditPrice == index" type="text" style="background: white"
                              class="form-control-sm" @keyup.enter="mtdOutFocusEditPrice(index)" v-model="item.price"
                              @keypress="validateNumberInput" />
                            <input v-if="posobs == index" type="text" style="background: white; width: 200%;"
                              class="form-control-sm" @keyup.enter="mtdOutFocusEditObs(index)"
                              @blur="mtdOutFocusEditObs(index)" v-model="item.obs" placeholder="Motivo de Descuento." />
                            <div v-if="itemDiscount == index" style="display: flex; align-items: center;">
                              <input type="text" style="background: white; width:90px;  margin-right:5px;"
                                class="form-control-sm" @keyup.enter="AplicarDescuento(index)" v-model="item.descuento"
                                placeholder="Descuento" />
                              <label style="margin:0 5px;">
                                <input type="radio" v-model="item.tipodescuento" value="porcentaje" /> %
                              </label>
                              <label style="margin:0 5px;">
                                <input type="radio" v-model="item.tipodescuento" value="monto" /> Monto
                              </label>
                            </div>
                          </span>
                          &nbsp;
                          <div class="form-control text-right" v-if="posEditPrice != index && posobs != index &&
                            itemDiscount != index">
                            <!--
                              <button
                                type="button"
                                class="rounded-circle m-1"
                                @click="mtdAddQuantity(index)"
                              >
                                <i class="fas fa-plus text-white"></i>
                              </button>
                              -->
                            <button type="button" class="rounded-circle m-1" @click="mtdDelQuantity(index)">
                              <i class="fas fa-minus text-white"></i>
                            </button>
                            <button type="button" class="rounded-circle m-1" @click="mtdChangePrice(index)">
                              <i class="fas fa-triangle text-white"></i>
                            </button>
                            <button type="button" class="rounded-circle m-1" @click="mtdChangeDiscount(index)">
                              <i class="fa fa-percent text-white" aria-hidden="true"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12"></div>
            </div>
            <div class="row" v-else-if="page == 'programar'">escoger camas y citas</div>
          </div>
          <div class="col"
            style="background: #900052; font-size: 20px; display: flex; justify-content: space-between; align-items: center;">
            <div class="card-footer text-white">
              <span class="btn border border-white text-white" @click="retroceder" style="align-self: flex-start;">
                <strong>ATRAS</strong>
              </span>
            </div>
            <div style="flex-grow: 1; text-align: right;" class="card-footer text-white">
              <span v-if="!posDesc && !posMotivo" class="btn border border-white text-white" style="margin-left: 20px"
                @click="addDsc()">
                <strong>DESC.</strong>
              </span>
              &nbsp;
              <strong v-if="!posDesc && !posMotivo">SUBTOTAL: S/ {{ cpTotal }}</strong> &nbsp;
              <strong v-if="!posDesc && !posMotivo">DESC: - S/ {{ parseFloat(montoDescuento).toFixed(2) }}</strong>
              &nbsp;
              <strong v-if="!posDesc && !posMotivo">TOTAL: S/ {{ cpTotalDesc }}</strong>
              <strong v-if="posDesc">
                DESC:&nbsp;
                <input type="text" style="background: white" class="form-control-sm" placeholder="Descuento"
                  @keyup.enter="mtdOutFocusDesc()" v-model="dsc" @keypress="validateNumberInput" />
                <label style="margin:0 5px;">
                  <input type="radio" v-model="TypeDiscount" value="porcentaje" />%
                </label>
                <label style="margin:0 5px;">
                  <input type="radio" v-model="TypeDiscount" value="monto" /> Monto
                </label>
              </strong>
              <strong v-if="posMotivo">
                <textarea class="form-control-xl" style="background: white" placeholder="Motivo" v-model="motivo"
                  @keyup.enter="mtdOutFocusMotivo()" @blur="mtdOutFocusMotivo()"></textarea>
              </strong>
              <span v-if="!posDesc && !posMotivo" class="btn border border-white text-white" style="margin-left: 20px"
                @click="verifyZero()">
                <strong>OK</strong>
              </span>

            </div>
          </div>
        </div>
      </div>
      <br />
    </div>
    <PagoVue v-if="page == 'pago'" :castCarrito="castCarrito" :key="componentKey" :dsc="dsc"
      :montodescuento="montoDescuento" :permissionValue="permissionValue" @mtdBehindProcess="mtdBehindProcess"
      @mdtCommitPayment="mdtCommitPayment" :client="client" :users="users" />

    <cModalCarroVentaVue :title="modalReci.title" :boo_modal="modalReci.boo_modal" :item="modalReci.item"
      @registrarReci="mtdSelectSubCar" @mtdCloseModalDeta="mtdCloseModalReci" />
    <SweetAlert :swal="swal" ref="SweetAlert" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import PagoVue from "./ventas/Pago.vue";
import cModalCarroVentaVue from "../Modals/cModalCarroVenta.vue";

export default {
  name: "c-admision-venta",
  components: {
    PagoVue,
    cModalCarroVentaVue,
    SweetAlert,
  },
  data() {
    return {
      swal: null,
      TypeDiscount: "porcentaje",
      validaDescuento: 0,
      validaDescuentoGeneral: 0,
      itemDiscount: null,
      dsc: "0",
      montoDescuento: "0",
      motivo: "",
      componentKey: 0,
      posDesc: false,
      posMotivo: false,
      page: "venta",
      view: "table",
      dataSubSubCategories: [],
      data_product_filter: [],
      search_product: "",
      setTimeoutSearch: "",
      posobs: null,
      doctors: [],
      carrito: [],
      item_carrito: {},
      castCarrito: [],
      elementProcess: {},
      users: [],
      medicos: [],
      posEditPrice: null,
      permissionValue: null,
      swal: null,
      isconfirmed: null,

      modalReci: {
        title: "Medicamento",
        item: [],
        boo_modal: false,
        pos: null,
      },
    };
  },
  props: {
    boxes: [],
    bead: {},
    client: {},
  },
  computed: {
    cpTotal() {
      this.validaDescuento = 0;
      if (this.carrito.length == 0) {
        this.dsc = 0;
      }
      let total = 0;
      this.carrito.forEach((element) => {
        total += element.subtotal;
        if ((parseFloat(element.montodescuento)) > 0) {
          this.validaDescuento = 1;
        }
      });
      if (parseFloat(this.montoDescuento) > 0) {
        this.validaDescuentoGeneral = 1;
      } else {
        this.validaDescuentoGeneral = 0;
      }
      // return parseFloat((total/1.18)).toFixed(2);
      return parseFloat((total)).toFixed(2);
    },
    cpTotalDesc() {
      this.validaDescuento = 0;
      if (this.carrito.length == 0) {
        this.dsc = '0';
      }
      let total = 0;
      this.carrito.forEach((element) => {
        total += element.subtotal;
        if ((parseFloat(element.montodescuento)) > 0) {
          this.validaDescuento = 1;
        }
      });
      let totalsig = total / 1.18;
      let final = parseFloat(totalsig - this.montoDescuento);
      let TotalFinal = final + (final * 0.18);
      return parseFloat(TotalFinal).toFixed(2);
    },
    igv() {
      if (this.carrito.length == 0) {
        this.dsc = '0';
      }
      let total = 0;
      this.carrito.forEach((element) => {
        total += element.subtotal;
        if ((parseFloat(element.montodescuento)) > 0) {
          this.validaDescuento = 1;
        } else {
          this.validaDescuento = 0;
        }
      });
      let totalsig = total / 1.18;
      let final = parseFloat(totalsig - this.montoDescuento);
      return parseFloat(final * 0.18).toFixed(2);
    }
  },
  created() {
    // this.mtdGetDataSubSubCategories();
    this.mtdGetDataPerAndData();
  },
  methods: {
    ...mapActions(["get", "post"]),

    confirmed(data) {
      if (data == "1") {
        this.page = "venta";
        this.search_product = "";
      }
    },

    mtdGetDataSubSubCategories: function () {
      this.get({
        url:
          this.$store.getters.get__url +
          "/Pharmacy/" +
          this.client.type +
          "/" +
          this.client.insurance +
          "/subsubcategoriesCli?search_product=" +
          this.search_product,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          let data = [];
          if (this.client.type == "eps") {
            if (this.client.copago.length > 0) {
              response.data.forEach((element) => {
                let tmpPrice = element.precio_farmacia_particular;
                let newPrice = (tmpPrice * (100 - this.client.copago)) / 100;
                element.precio_farmacia_particular = parseFloat(newPrice).toFixed(2);
                data.push(element);
              });
            } else {
              response.data.forEach((element) => {
                if (element.catId == 1 || element.catId == 2 || element.catId == 8) {
                  element.precio_farmacia_particular =
                    this.client.fijo.length == 0
                      ? element.precio_farmacia_particular
                      : parseFloat(this.client.fijo).toFixed(2);
                }
                let tmpPrice = parseFloat(element.precio_farmacia_particular); // Obtener el precio de venta actual
                let ivaAmount = tmpPrice * 0.18; // Calcular el 18% de IVA
                let newPrice = tmpPrice + ivaAmount; // Calcular el nuevo precio con el IVA
                element.precio_farmacia_particular = newPrice.toFixed(2); // Redondear el nuevo precio a 2 decimales
                data.push(element);
              });
            }
          } else {
            data = response.data;
          }
          this.dataSubSubCategories = data;
          this.data_product_filter = data;
        })
        .catch((errors) => { });
    },
    retroceder() {
      this.$emit("changePageVen", "directa");
    },
    mtd_filter_product: function () {
      if (search_product.value.length >= 5 && search_product.value != "") {
        let q = search_product.value.toString().toUpperCase();
        this.mtdGetDataSubSubCategories(q);
        this.data_product_filter = [];
        let i = 0;
        this.dataSubSubCategories.forEach((element) => {
          let name = element.name.toString().toUpperCase();
          if (name.includes(q)) {
            this.data_product_filter.push(element);
          }
        });
      } else {
        this.data_product_filter = this.dataSubSubCategories;
      }
    },
    search() {
      clearTimeout(this.setTimeoutSearch);
      this.setTimeoutSearch = setTimeout(this.mtdGetDataSubSubCategories, 360);
    },
    addDsc() {
      if (this.carrito.length > 0) {
        if (this.validaDescuento == 1) {
          Swal.fire({
            text: "Ya Realizo Descuentos por Item",
            icon: "warning",
            confirmButtonColor: "#900052",
          });
          return false;
        }
        Swal.fire({
          text: "Va Registrar Un Descuento Global",
          icon: "warning",
          width: "400px",
          showDenyButton: true,
          confirmButtonText: "Si, Confirmar!",
          confirmButtonColor: "#3085d6",
          showCancelButton: false,
          cancelButtonColor: "#d33",
          cancelButtonText: "Cerrar",
        })
          .then((result) => {
            if (result.isConfirmed) {
              const todosMayoresQueCero = this.carrito.every(
                (item) => parseFloat(item.price) > 0
              );
              if (todosMayoresQueCero) {
                this.posDesc = true;
                return true;
              } else {
                Swal.fire({
                  text: "Hay Productos con precio 0.00",
                  icon: "warning",
                  confirmButtonColor: "#900052",
                });
                return false;
              }
            }
          });
      }
      else {
        Swal.fire({
          text: "No hay productos en el carrito",
          icon: "warning",
          confirmButtonColor: "#900052",
        });
        return false;
      }
    },
    /** carrito */
    mtdSelectSubsubcatergoriee: function (item) {

      let bo = false;
      if (this.carrito.length == 0) {
        this.item_carrito = {
          id: item.id,
          name: item.name,
          price: item.precio_farmacia_particular,
          price_v: item.precio_farmacia_particular,
          obs: "",
          quantity: 1,
          subategorie: {
            id: item.subCatId,
            name: item.subCatName,
          },
          categorie: {
            id: item.catId,
            name: item.catName,
            type: item.catType,
          },
          doctor: {
            id: null,
            name: null,
          },
          reservadespues: false
        };
        if (item.catId == 1 || item.catId == 8 || item.catId == 10) {
          if (this.doctors.length == 0) {
            this.doctors =
              item.catId == 1 ||
                item.catId == 8 ||
                item.catId == 10
                ? item.doctors
                : [];
            //  this.doctors = item.catId==1?item.doctors: [];
            // this.carrito.push(this.item_carrito);
          }
        } else {
          this.doctors = [];
          this.carrito.push(this.item_carrito);
        }
        bo = true;
      } else {
        this.carrito.forEach((element) => {
          if (element.id == item.id) {
            element.quantity = element.quantity + 1;
            bo = true;
          }
        });
      }
      if (!bo) {
        // this.doctors = item.catId==1?[]: item.doctors;
        this.doctors =
          item.catId == 1 || item.catId == 8 || item.catId == 10 ? item.doctors : [];
        this.item_carrito = {
          id: item.id,
          name: item.name,
          price: item.precio_farmacia_particular,
          price_v: item.precio_farmacia_particular,
          obs: "",
          quantity: 1,
          subategorie: {
            id: item.subCatId,
            name: item.subCatName,
          },
          categorie: {
            id: item.catId,
            name: item.catName,
            type: item.catType,
          },
          doctor: {
            id: null,
            name: null,
          },
          reservadespues: false
        };
        if (this.doctors.length == 0) {
          this.carrito.push(this.item_carrito);
        }
        bo = false;
      }
      this.search_product = "";
      this.data_product_filter = [];

    },

    mtdSelectSubsubcatergorie: function (item) {
      if (item.total_quantity === 0) {
        this.$refs.SweetAlert.showWarning("No puede agregar al carrito Medicamentos sin Stock");
        return;
      }
      this.modalReci.boo_modal = true;
      let bo = false;
      if (this.carrito.length == 0) {
        this.item_carrito = {
          id: item.id,
          name: item.name,
          location: item.location,
          price: item.precio_farmacia_particular,
          price_v: item.price_sale,
          obs: "",
          descuento: "0",
          subtotal: item.price_sale * 1,
          tipodescuento: "porcentaje",
          montodescuento: "0",
          quantity: 1,
          subategorie: {
            id: item.subCatId,
            name: item.subCatName,
          },
          categorie: {
            id: item.catId,
            name: item.catName,
            type: item.catType,
          },
          dataRe: {
            frecuencia: 0,
          },
        };

        this.modalReci.item = item;
        if (this.modalReci.boo_modal !== true) {
          this.carrito.push(this.item_carrito);
          this.actulizarDscGlobal();
        }

        bo = true;
      } else {
        this.carrito.forEach((element) => {
          if (element.id == item.id) {
            this.modalReci.boo_modal = false;
            Swal.fire({
              text: "Ya está agregado el Medicamento en el Carrito",
              icon: "warning",
              confirmButtonColor: "#900052",
            });
            //element.quantity = element.quantity + 1;
            bo = true;
          }
        });
      }
      if (!bo) {
        this.item_carrito = {
          id: item.id,
          name: item.name,
          location: item.location,
          price: item.precio_farmacia_particular,
          obs: "",
          descuento: "0",
          tipodescuento: "porcentaje",
          montodescuento: "0",
          subtotal: item.price_sale * 1,
          quantity: 1,
          subategorie: {
            id: item.subCatId,
            name: item.subCatName,
          },
          categorie: {
            id: item.catId,
            name: item.catName,
            type: item.catType,
          },
          dataRe: {
            frecuencia: 0,
          },
        };
        this.modalReci.item = item;
        if (this.modalReci.boo_modal !== true) {
          this.carrito.push(this.item_carrito);
          this.actulizarDscGlobal();
        }
        bo = false;
      }
      this.search_product = "";

      //this.data_product_filter = [];
    },

    mtdSelectSubCar: function (item) {
      this.carrito.push(item);
      this.actulizarDscGlobal();
    },

    mtdCloseModalReci: function () {
      this.modalReci = {
        title: "Medicamento",
        item: [],
        boo_modal: false,
        pos: null,
      };
    },

    mtdSelectDoctor: function (item) {
      this.item_carrito.doctor.id = item.doctor.id;
      this.item_carrito.doctor.name = item.doctor.name + " " + item.doctor.last_name;
      this.doctors = [];
      this.carrito.push(this.item_carrito);
    },
    mtdAddQuantity: function (pos) {
      this.carrito[pos].quantity = this.carrito[pos].quantity + 1;
      this.carrito[pos].subtotal = this.carrito[pos].quantity * this.carrito[pos].price;
      this.AplicarDescuento1(pos);
    },
    mtdDelQuantity: function (pos) {
      let cantidad = this.carrito[pos].quantity;

      let temp = [];
      this.carrito.forEach((element, index) => {
        if (index != pos) {
          temp.push(element);
        }
      });
      this.carrito = temp;
    },
    /** programas citas o reservas */
    mtdNextStepp: function (step) {
      if (this.carrito.length > 0) {
        if (step == "2") {
          this.castCarrito = [];
          this.carrito.forEach((element) => {
            element.data = [];
            element.programado = false; // false cuando funcione reserva
            element.actualPage = false;
            if (element.categorie.id == 1) {
              /** consultorio */
              element.component = "consultas";
            } else if (
              element.categorie.id == 2 ||
              element.categorie.id == 4 ||
              element.categorie.id == 5 ||
              element.categorie.id == 6 ||
              element.categorie.id == 7
            ) {
              /** Hospitalización */
              element.component = "camas";
            } else if (element.categorie.id == 8 || element.categorie.id == 10) {
              /** sop */
              element.component = "sop";
            } else if (element.categorie.id == 11) {
              /** laboratorio */
              element.component = "laboratorio";
            } else if (element.categorie.id == 13) {
              /** imagenes */
              element.component = "imagenes";
            } else {
              element.programado = true;
              element.component = "pago";
            }
            this.castCarrito.push(element);
          });
          //poner  los de pago al ultimo
          this.castCarrito.sort((a, b) => {
            if (a.component === "pago" && b.component !== "pago") {
              return 1;
            } else if (a.component !== "pago" && b.component === "pago") {
              return -1;
            } else {
              return 0;
            }
          });

          this.mtdProcessCastCarrito();
        }
      } else {
        Swal.fire({
          text: "Seleccione un Producto o Servicio",
          icon: "warning",
          confirmButtonColor: "#900052",
        });
      }
    },

    mtdNextStep: function (step) {
      if (this.carrito.length > 0) {
        if (step == "2") {
          this.castCarrito = [];
          this.carrito.forEach((element) => {
            element.data = [];
            //element.programado = false;
            element.actualPage = false;

            element.programado = true;
            element.component = "pago";

            this.castCarrito.push(element);
          });
          //poner  los de pago al ultimo
          this.castCarrito.sort((a, b) => {
            if (a.component === "pago" && b.component !== "pago") {
              return 1;
            } else if (a.component !== "pago" && b.component === "pago") {
              return -1;
            } else {
              return 0;
            }
          });

          this.mtdProcessCastCarrito();
        }
      } else {
        Swal.fire({
          text: "Seleccione un Medicamento",
          icon: "warning",
          confirmButtonColor: "#900052",
        });
      }
    },

    verifyZero: function () {
      const todosMayoresQueCero = this.carrito.every(
        (item) => parseFloat(item.price) > 0
      );
      if (todosMayoresQueCero) {
        this.mtdNextStep("2");
        return true;
      } else {
        Swal.fire({
          text: "Hay valores iguales o menores que 0.00",
          icon: "warning",
          confirmButtonColor: "#900052",
        });

        return false;
      }
    },
    mtdProcessCastCarrito: function () {

      let boo = false;
      for (let index = 0; index < this.castCarrito.length; index++) {
        const element = this.castCarrito[index];
        if (element.actualPage == true) {
          boo = true;
          element.actualPage = false;
          if (index == this.castCarrito.length - 1) {
            //ir a la página de pagos
            this.page = "pago";
          } else {
            this.elementProcess = {};
            this.elementProcess = this.castCarrito[index + 1];
            this.castCarrito[index + 1].actualPage = true;
            this.page = this.castCarrito[index + 1].component;
            index = this.castCarrito.length;
          }
        }
      }
      if (boo == false) {
        this.castCarrito[0].actualPage = true;
        this.elementProcess = {};
        this.elementProcess = this.castCarrito[0];
        // this.page = "pago";  sin sreserva
        this.page = this.castCarrito[0].component;
      }
    },
    mtdNextProcess: function (process) {
      this.page = "";
      for (let index = 0; index < this.castCarrito.length; index++) {
        let element = this.castCarrito[index];
        if (element.id == process.id) {
          this.castCarrito[index] = process;
          for (let i = 0; i < this.castCarrito.length; i++) {
            if (this.castCarrito[index].categorie.id == this.castCarrito[i].categorie.id) {
              this.castCarrito[i].data = this.castCarrito[index].data;
              this.castCarrito[i].reservadespues = this.castCarrito[index].reservadespues;
            }
          }
        }
      }
      this.mtdProcessCastCarrito();
    },
    mtdBehindProcess: function () {
      let boo = false;
      for (let index = 0; index < this.castCarrito.length; index++) {
        const element = this.castCarrito[index];
        if (element.actualPage == true) {
          if (index != 0) {
            boo = true;
            this.elementProcess = this.castCarrito[index - 1];
            element.actualPage = false;
            this.castCarrito[index - 1].actualPage = true;
            this.page = this.castCarrito[index - 1].component;
            break;
          } else {
            break;
          }
        }
      }
      if (this.page == "pago") {
        let cat = 14;
        //let cat = this.castCarrito[this.castCarrito.length - 1].categorie.id;
        if (cat == 17 || cat == 12 || cat == 3 || cat == 14) {
          this.page = "venta";
          this.search_product = "";
        } else {
          this.castCarrito[this.castCarrito.length - 1].actualPage = true;
          this.elementProcess = this.castCarrito[this.castCarrito.length - 1];
          this.page = this.castCarrito[this.castCarrito.length - 1].component;
        }
      } else if (boo == false) {
        //alert
        Swal.fire({
          title: "¿Desea volver al menú?",
          text: "¡Se perderá todo los datos seleccionados!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si",
          width: "400px",
        }).then((result) => {
          if (result.isConfirmed) {
            this.page = "venta";
            this.search_product = "";
          }
        });
      }
    },
    // mdtCommitPayment: function (payment, chkPatiente, planilla, garantia, impuestos, selectedVoucherCancel) {
    //   this.post({
    //     url: this.$store.getters.get__url + "/Pharmacy/storeHome",
    //     token: this.$store.getters.get__token,
    //     params: {
    //       data: this.castCarrito,
    //       payment: payment,
    //       patient: this.client,
    //       bead: this.bead,
    //       campu_id: this.$store.getters.get__campus,
    //       chkPatiente: chkPatiente,
    //       planilla: planilla,
    //       garantia: garantia,
    //       impuestos: impuestos,
    //       motivoDesc:this.motivo,
    //       descuento:this.dsc,
    //       tipodescuento:this.TypeDiscount,
    //       montodescuento:this.montoDescuento,
    //       validardescuento:this.validaDescuentoGeneral,
    //       selectedVoucherCancel: selectedVoucherCancel,
    //     },
    //   })
    //     .then((response) => {
    //       if (response.data.state == 0) {
    //         if (response.data.data != null) {
    //           let res = response.data.data.sunat;
    //           window.open(res.json.enlace_del_pdf_ticket, "_blank");
    //         }
    //         Swal.fire({
    //           text: "Venta Finalizada",
    //           icon: "success",
    //           confirmButtonColor: "#900052",
    //         });
    //         this.$emit("mtdCommitPayment", this.bead);
    //       }
    //     })
    //     .catch((errors) => {
    //       console.log(errors);
    //     });
    // },
    mdtCommitPayment: function (payment, chkPatiente, planilla, garantia, impuestos, selectedVoucherCancel) {
      this.post({
        url: this.$store.getters.get__url + "/Pharmacy/storeHome",
        token: this.$store.getters.get__token,
        params: {
          data: this.castCarrito,
          payment: payment,
          patient: this.client,
          bead: this.bead,
          campu_id: this.$store.getters.get__campus,
          chkPatiente: chkPatiente,
          planilla: planilla,
          garantia: garantia,
          impuestos: impuestos,
          motivoDesc: this.motivo,
          descuento: this.dsc,
          tipodescuento: this.TypeDiscount,
          montodescuento: this.montoDescuento,
          validardescuento: this.validaDescuentoGeneral,
          selectedVoucherCancel: selectedVoucherCancel
        },
      })
        .then((response) => {
          if (response.data.state == 0) {
            if (response.data.data != null) {
              Swal.fire({
                title: 'Venta Finalizada',
                text: 'Seleccione una opción',
                icon: 'success',
                showCancelButton: true,
                showDenyButton: true,
                confirmButtonText: '<i class="fas fa-file-pdf"></i> Imprimir PDF',
                cancelButtonText: '<i class="fas fa-envelope"></i> Enviar a Correo',
                denyButtonText: '<i class="fab fa-whatsapp"></i> Enviar a WhatsApp',
                customClass: {
                  confirmButton: 'btn btn-primary',
                  cancelButton: 'btn btn-secondary',
                  denyButton: 'btn btn-success',
                  actions: 'd-flex justify-content-between gap-2'
                },
                buttonsStyling: false,
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: true,
                showCloseButton: true
              }).then((result) => {
                if (result.isConfirmed) {
                  // Acción PDF
                  let res = response.data.data.sunat;
                  window.open(res.json.enlace_del_pdf_ticket, "_blank");
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                  //Correo
                  let res = response.data.data.sunat;
                  this.post({
                    url: this.$store.getters.get__url + "/send/email",
                    token: this.$store.getters.get__token,
                    params: {
                      email: "backend@howertpi.com",
                      destinatario: [this.client.email + ","],
                      asunto: "Envio de comprobante electronico",
                      body: "Hola " + this.client.fullName + "clinica BM desea que estés teniendo un buen día, el motivo de este correo es el envío de tu comprobante de venta electrónica.",
                      imagen_path: "",
                      archivo_path: res.json.enlace_del_pdf_ticket,
                      html_path: "",
                      xml_path: ""
                    },
                  })
                    .then((response) => {

                    })
                    .catch((errors) => {
                      console.log(errors);
                    });
                  // Acción para correo
                  // window.location.href = 'mailto:correo@example.com';
                } else if (result.dismiss === Swal.DismissReason.deny) {
                }
              });

            }
            this.$emit("mtdCommitPayment", this.bead);
          } else {
            alert(response.exception);
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    truncateText(text, maxLength) {
      if (text.length > maxLength) {
        return text.substring(0, maxLength) + "...";
      }
      return text;
    },
    /** temporal cambiar precio */
    mtdChangePrice: function (pos) {

      this.posEditPrice = pos;
    },
    mtdOutFocusEditPrice: function (index) {
      let carrito = this.carrito[index];
      this.carrito[index].subtotal = carrito.price * carrito.quantity;
      this.AplicarDescuento1(index);
      this.posEditPrice = null;
    },

    AplicarDescuento: function (index) {
      let item = null;
      item = this.carrito[index];
      item.subtotal = item.quantity * item.price;
      if (item.tipodescuento === "monto" && item.descuento != '0') {
        let subtotalSING = item.subtotal / 1.18;
        subtotalSING = subtotalSING - parseFloat(item.descuento);
        item.montodescuento = parseFloat(item.descuento);
        item.subtotal = subtotalSING + (subtotalSING * 0.18);
      } else if (item.tipodescuento === "porcentaje" && item.descuento != '0') {
        let subtotalSING = item.subtotal / 1.18;
        const porcentajeDescuento = parseFloat(item.descuento) / 100;
        subtotalSING = subtotalSING - (subtotalSING * porcentajeDescuento);
        item.montodescuento = parseFloat(item.subtotal) * porcentajeDescuento;
        item.subtotal = subtotalSING + (subtotalSING * 0.18);
      } else if (item.descuento == '0') {
        item.montodescuento = '0';
        item.subtotal = item.quantity * parseFloat(item.price);
      }
      if (item.price < 0) {
        item.price = 0;
      }
      this.carrito[index] = item;
      this.itemDiscount = null;
      this.actulizarDscGlobal();
      if (parseFloat(item.montodescuento) > 0) {
        this.posobs = index;
      }
    },

    mtdChangeDiscount: function (index) {
      if (this.validaDescuentoGeneral == 1) {
        Swal.fire({
          text: "Ya Registro Un descuento Global",
          icon: "warning",
          confirmButtonColor: "#900052",
        });
        return false;
      }
      this.itemDiscount = index;
    },
    mtdOutFocusEditObs(index) {
      if (this.carrito[index].obs != "") {
        this.posobs = null;
      } else {
        Swal.fire({
          text: "Registre un motivo",
          icon: "warning",
          confirmButtonColor: "#900052",
        });
        return;
      }
    },
    mtdOutFocusDesc() {
      if (parseFloat(this.dsc) > 0) {
        this.posDesc = false;
        let total = 0;
        this.carrito.forEach((element) => {
          total += element.subtotal;
        });
        let TotalSIG = total / 1.18;
        if (this.TypeDiscount == 'monto' && this.dsc != '0') {
          this.montoDescuento = parseFloat(this.dsc);
        }
        else if (this.TypeDiscount == 'porcentaje' && this.dsc != '0') {
          const porcentajeDescuento = parseFloat(this.dsc) / 100;
          this.montoDescuento = TotalSIG * porcentajeDescuento;
        }
        Swal.fire({
          text: "Registre un motivo",
          icon: "warning",
          confirmButtonColor: "#900052",
        });
        this.posMotivo = true;
      } else {
        this.dsc = '0';
        this.montoDescuento = '0';
        this.posDesc = false;
        this.posMotivo = false;
        this.motivo = "";
      }
    },
    mtdOutFocusMotivo() {
      if (this.motivo != "") {
        this.posMotivo = false;
      } else {
        Swal.fire({
          text: "Registre un motivo",
          icon: "warning",
          confirmButtonColor: "#900052",
        });
        return;
      }
    },


    validateNumberInput(event) {
      const regex = /^[0-9]*\.?[0-9]*$/;

      if (!regex.test(event.key)) {
        event.preventDefault();
      }
    },
    mtdGetDataPerAndData: function () {
      this.post({
        url: this.$store.getters.get__url + "/Jefatura/otrosPermisosJefa",
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.permissionValue = response.data[0].permission;

        })
        .catch((errors) => { });
    },

    AplicarDescuento1: function (index) {
      let item = null;
      item = this.carrito[index];
      if (item.tipodescuento === "monto" && item.descuento != '0') {
        let subtotalSING = item.subtotal / 1.18;
        subtotalSING = subtotalSING - parseFloat(item.descuento);
        item.montodescuento = parseFloat(item.descuento);
        item.subtotal = subtotalSING + (subtotalSING * 0.18);
      } else if (item.tipodescuento === "porcentaje" && item.descuento != '0') {
        let subtotalSING = item.subtotal / 1.18;
        const porcentajeDescuento = parseFloat(item.descuento) / 100;
        subtotalSING = subtotalSING - (subtotalSING * porcentajeDescuento);
        item.montodescuento = parseFloat(item.subtotal) * porcentajeDescuento;
        item.subtotal = subtotalSING + (subtotalSING * 0.18);
      } else if (item.descuento == '0') {
        item.montodescuento = '0';
        item.subtotal = item.quantity * parseFloat(item.price);
      }
      if (item.price < 0) {
        item.price = 0;
      }
      this.carrito[index] = item;
      this.actulizarDscGlobal();
    },
    actulizarDscGlobal: function () {
      if (parseFloat(this.dsc) > 0) {
        this.posDesc = false;
        let total = 0;
        this.carrito.forEach((element) => {
          total += element.subtotal;
        });
        let TotalSIG = total / 1.18;
        if (this.TypeDiscount == 'monto' && this.dsc != '0') {
          this.montoDescuento = parseFloat(this.dsc);
        }
        else if (this.TypeDiscount == 'porcentaje' && this.dsc != '0') {
          const porcentajeDescuento = parseFloat(this.dsc) / 100;
          this.montoDescuento = (TotalSIG * porcentajeDescuento);
        }
      } else {
        this.dsc = '0';
        this.montoDescuento = '0';
        this.posDesc = false;
        this.posMotivo = false;
        this.motivo = "";
      }
    }
  },
};
</script>

<style scoped>
  .btn-home {
    z-index: 1;
  }

  #div-venta-body {
    height: 450px;
    overflow: auto;
    box-sizing: border-box;
    padding: 1rem 1rem;
  }

  #div-venta-body::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  #div-venta-body::-webkit-scrollbar:vertical {
    width: 10px;
  }

  #div-venta-body::-webkit-scrollbar-button:increment,
  #div-venta-body::-webkit-scrollbar-button {
    display: none;
  }

  #div-venta-body::-webkit-scrollbar:horizontal {
    height: 10px;
  }

  #div-venta-body::-webkit-scrollbar-thumb {
    background-color: #797979;
    border-radius: 20px;
    border: 2px solid #f1f2f3;
  }

  #div-venta-body::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  #name-product {
    color: #8a8a97;
    font-size: 13px;
  }

  .list-doctor,
  .list-monto {
    padding-right: 25px;
  }

  .text-doctor {
    color: #900052;
    font-size: 13px;
    font-weight: bold;
  }

  .text-monto {
    color: #900052;
    font-size: 13px;
    font-weight: bold;
    margin-bottom: 0px;
  }

  .container-list {
    margin: 30px;
    width: 100%;
  }

  .box {
    margin-bottom: 10px;
    position: relative;
  }

  .box::before {
    content: "";
    position: absolute;
    width: 3px;
    height: 100%;
    background: #808080;
    left: -15px;
    top: 30px;
  }

  .box::after {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #900052;
    left: -23px;
    top: 10px;
  }

  .item-doctor,
  .item-monto {
    padding: 10px 10px;
    border: #900052 3px solid;
    border-top-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }

  .item-doctor:hover {
    cursor: pointer;
    background-color: #900052;
  }

  .item-doctor:hover p {
    color: #ffffff !important;
  }

  #item-product {
    align-items: center;
  }

  #item-product:hover {
    cursor: pointer;
    align-items: center;
    transform: scale(1.05);
  }

  .item-monto .input-group-text,
  .item-monto .form-control {
    background-color: #e6e6e6 !important;
    border: 0 !important;
    padding: 0px;
  }

  .item-monto .form-control button {
    background-color: #808080 !important;
    border: 0 !important;
  }

  .item-monto .form-control button:hover {
    background-color: #000000 !important;
    border: 0 !important;
  }

  .item-name {
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .item-name strong {
    padding: 0px 10px;
    width: 120px;
  }

  .circulo {
    vertical-align: middle;
    border-style: none;
  }

  @media (max-width: 800px) {
    #btn-open-caja {
      justify-content: center !important;
    }

    #btn-registro-venta {
      text-align: center;
    }

    #div-tabla-venta {
      overflow-x: auto;
    }
  }

  @media (min-width: 800px) {
    #btn-registro-venta {
      text-align: left;
    }
  }

  .text-custom-light-green {
    color: #60d004;
  }

</style>