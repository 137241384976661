<template>
    <div>
        <div class="container">
            <div class="row">
                <div class=" btn-home  col-md-12 mt-3 mb-4 d-flex justify-content-end">
                    <button type="button" class="btn btn-outline-main" @click="returnHome">
                        <i class="fas fa-home-lg-alt"></i> Inicio
                    </button>
                </div>
            </div>
            <div id="div-modal-cxc" class="d-flex justify-content-center">
                <div id="div-cxc" class="card w-100">
                    <div class="text-center card-header text-white h4 bg-main">
                        <strong>SERVICIO ESPECÍFICO</strong>
                    </div>
                    <div class="card-body w-100">
                        <div class="row">
                            <div class="col-md-12 py-2">
                                <div class="row mx-0">
                                    <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12 px-1 mb-2">
                                        <div class="input-group input-group">
                                            <span class="input-group-text" id="basic-addon1"
                                            >Categoría</span
                                            >
                                            <select
                                            class="form-control form-control-sm"
                                            v-model="filter.category"
                                            @change="mtdSelectCategory"
                                            >
                                            <option :value="null">Seleccione una categoria</option>
                                            <option 
                                                v-for="(item, index) in categories"
                                                :key="index"
                                                :value="item.id"
                                            >
                                                {{ item.name }}
                                            </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12 px-1 mb-2">
                                        <div class="input-group input-group">
                                            <span class="input-group-text" id="basic-addon1"
                                            >SubCategoría</span
                                            >
                                            <select
                                            class="form-control form-control-sm"
                                            v-model="filter.subcategory"
                                            @change="mtdSearchData()"
                                            >
                                            <option :value="null">Seleccione una subcategoria</option>
                                            <option
                                                v-for="(item, index) in subcategories"
                                                :key="index"
                                                :value="item.id"
                                            >
                                                {{ item.name }}
                                            </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="row d-flex mx-0">
                                    <div class="col-xl-8 col-lg-12 col-md-12 col-sm-12 col-xs-12 px-1">
                                        <div class="input-group input-group">
                                            <span class="input-group-text text-white bg-main"
                                            ><i class="fas fa-search"></i
                                            ></span>
                                            <input
                                            @keyup="calculateData(7)"
                                            v-model="search"
                                            type="text"
                                            class="form-control form-control"
                                            placeholder="Buscar"
                                            />
                                        </div>
                                    </div>
                                    <div class="col-xl-2 col-lg-6 col-md-12 col-sm-12 col-xs-12 btn-home px-1 py-xl-0 my-xl-0 my-2">
                                        <button
                                            type="button"
                                            class="btn btn-bm-noradius w-100"
                                            @click="mtdModalShow(0,null)"
                                        >
                                            <i class="fas fa-plus"></i> Nueva
                                        </button>
                                    </div>
                                    <div class="col-xl-2 col-lg-6 col-md-12 col-sm-12 col-xs-12 btn-home px-1 py-xl-0 my-xl-0 my-2">
                                        <button
                                            type="button"
                                            class="btn btn-bm-noradius w-100"
                                        >
                                            <i class="fas fa-upload"></i> Cargar
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 mt-2" id="content-timelinetable">
                                <table id="tb-cxc" class="table table-bordered border-main">
                                    <caption class="py-0 my-0"></caption>
                                    <thead class="bg-main">
                                        <tr class=" table-th text-white text-center">
                                            <th class="table-th-number">N°</th>
                                            <th>CATEGORIA</th>
                                            <th>SUBCATEGORIA</th>
                                            <th>SERV. ESPECIFICO</th>
                                            <th class="table-th-number">ESTADO</th>
                                            <th class="table-th-acciones">ACCIONES</th>
                                        </tr>
                                    </thead>
                                    <tbody class="border-main">
                                        <tr class="table-body text-center" v-if="cpData.length === 0">
                                            <td colspan="6" class="text-center">No hay registros</td>
                                        </tr>
                                        <tr class="table-body text-center" v-for="(item, index) in cpData" :key="index">
                                            <td>
                                                {{mtdNumber(index)}}
                                            </td>
                                            <td class="">
                                                {{item.categorie_name}}
                                            </td>
                                            <td class="">
                                                {{item.subcategorie_name }}
                                            </td>
                                            <td class="">
                                                {{item.name}}
                                            </td>
                                            <td>
                                                <div id="state_speciality" v-if="item.state == 1">
                                                    <div class="bg-success text-white">Activo</div>
                                                </div>
                                                <div id="state_speciality" v-else>
                                                    <div class="bg-secondary text-white">Inactivo</div>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="text-center d-flex justify-content-center">
                                                    <button
                                                        class="btn btn-sm a-success text-white" @click="mtdModalShow(1, item)">
                                                        <i class="fa fa-edit"></i>
                                                    </button>
                                                    <button 
                                                        class="btn btn-sm a-danger text-white" @click="Datadetail(item.id ,2)">
                                                        <i class="fas fa-trash-alt"></i>
                                                    </button>
                                                    </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mt-3 text-center">
                                <!-- paginado -->
                                <div v-if="cpData.length > 0" class="d-flex justify-content-center mb-2">
                                    <nav aria-label="Page navigation example">
                                        <ul class="pagination mb-1">
                                            <li class="page-item">
                                                <a class="page-link page" href="javascript:void(0)" @click="backPage">Anterior</a>
                                            </li>
                                            <li v-if="limitedStepPagination[0] !== 1" class="page-item">
                                                <a :class="selectPage === 1 ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(1)">1</a>
                                            </li>
                                            <li v-if="limitedStepPagination[0] > 2" class="page-item disabled">
                                                <span class="page-link">...</span>
                                            </li>
    
                                            <li v-for="(item, index) in limitedStepPagination" :key="index" class="page-item">
                                                <a :class="selectPage === item ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(item)">{{ item }}
                                                </a>
                                            </li>
                                            <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] <stepPagination.length - 1" class="page-item disabled">
                                                <span class="page-link">...</span>
                                            </li>
                                            <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] !== stepPagination.length" class="page-item">
                                                <a :class="selectPage === limitedStepPagination.length + 2 ? 'page-link active':'page-link'" href="javascript:void(0)" @click="selectedPage(stepPagination.length)">{{ stepPagination.length }}
                                                </a>
                                            </li>
                                            <li class="page-item">
                                                <a class="page-link page" href="javascript:void(0)" @click="nextPage">Siguiente</a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    
        </div>

        <cModalServicioEspVue :dataCategories="categories"  :dataSpecialtys="specialtys" :dataContracts="contracts" :dataSubcategories="subcategories"
        :num="modal.num" :payload="modal.payload" :title="modal.title" :boo_modal="modal.modal_form" 
        @closeModal="closeModal" @Datadetail="Datadetail" @mtdModalOpenCat="mtdModalOpenCat" @mtdModalOpenSub="mtdModalOpenSub"/>

        <cModalServicioEspCaVue :payload="modalCa.payload" :title="modalCa.title" :boo_modal="modalCa.modal_form" :num="modalCa.num" 
        @closeModalCa="closeModalCa"  @registerCa="registerCa"/>
        
        <cModalServicioEspSuVue :dataCategories="categories" :payload="modalSu.payload" :title="modalSu.title" :boo_modal="modalSu.modal_form"  :num="modalSu.num"
        @closeModalSu="closeModalSu" @registerSu="registerSu"/>
        <SweetAlert :swal="swal" ref="SweetAlert" />
    </div>
</template>

<script>
import {
    mapActions
} from "vuex";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import cModalServicioEspVue from '../Modals/cModalServicioEsp.vue'
import cModalServicioEspCaVue from '../Modals/cModalServicioCa.vue'
import cModalServicioEspSuVue from '../Modals/cModalServicioSu.vue'
export default {
    name: "c-mantenedores-pages-servicioEsp",
    components: {
        SweetAlert,
        cModalServicioEspVue,
        cModalServicioEspCaVue,
        cModalServicioEspSuVue
    },
    data() {
        return {
            swal: null,
            search:"",
            dataShow: [],
            all:[],
            pageExcel:1,
            categorie_control: "",
            subcategorie_control: "",
            especialidadesModalVisible: false,
            category: {
                id: "",
            },
            Subcategorie: {
                name: "",
                categorie_id: "",
            },
            estado: "activo",
            categories: [],
            specialtys: [],
            contracts:[],
            filter: {
                category: null,
                subcategory: null,
                specialty: null,
            },
            subcategories: [],
            dataSubsubcategory: [],
            dataSubcategory: [],
            estado: "activo",
            Subsubcategory: {
                name: "",
                Id: "",
                state: "",
                categorie_id: "",
                subcategorie_id: "",
                specialty_id: "",
                insurance_contrat_id:"",
                kairos:0,
                unidad: 0,
                particular: 0,
                eps: false,
                parti:false,
            },
            posEdit: null,
            message: false,
            file: "",
            fileLoaded: false,
            
            modal: {
                title: '',
                modal_form: false,
                num: null,
                payload: {},
            },
            modalCa: {
                title: '',
                modal_form: false,
                num: null,
                payload: {},
            },
            modalSu: {
                title: '',
                modal_form: false,
                num: null,
                payload: {},
            },
            //Pag
            page: 0,
            selectPage: 1,
            stepPagination: [],
            totalPagesToShow: 4,
        };
    },
    watch: {
        selectPage() {
            this.calculateData(7);
        },
    },
    computed: {
        classTh() {
            return 'align-middle border border-5 bg-main border-white';
        },
        showSubcategoryColumn() {
            return this.cpData.some(item => item.subcategorie_name !== null);
        },
        cpData() {
            return this.dataShow;
        },
        limitedStepPagination() {
            const totalPages = this.stepPagination.length;
            const currentPage = this.selectPage;

            let startPage = 1;
            let endPage = totalPages;
            if (totalPages > this.totalPagesToShow) {
                const halfPagesToShow = Math.floor(this.totalPagesToShow / 2);

                if (currentPage > halfPagesToShow) {
                    startPage = currentPage - halfPagesToShow;
                    endPage = currentPage + halfPagesToShow - 1;

                    if (endPage > totalPages) {
                        endPage = totalPages;
                        startPage = totalPages - this.totalPagesToShow + 1;
                    }
                } else {
                    endPage = this.totalPagesToShow;
                }
            }

            return Array.from({
                    length: endPage - startPage + 1,
                },
                (_, i) => startPage + i
            );
        },
        cpDisabled: function(){
            if(this.filter.category && this.filter.subcategory){
                return false;
            }
            return true
        },
    },
    created() {
        this.mtdGetData();
    },
    methods: {
        ...mapActions(["get", "post"]),

        //Traer Data
        mtdGetData: function () {
            this.get({
                url: this.$store.getters.get__url + "/SubsubCategorie",
                token: this.$store.getters.get__token,
            })
            .then((response) => {
                this.categories = response.data.categories.filter(category => category.name !== 'FARMACIA');
                this.specialtys = response.data.specialtys;
                this.contracts = response.data.contract;
                this.mtdSelectCategory();
            })
            .catch((errors) => {
                this.$refs.SweetAlert.showError(errors);
            });
        },

        mtdSearchData: function () {
            if (this.filter.subcategory != null) {
                this.post({
                    url: this.$store.getters.get__url + "/SubsubCategorie/filter",
                    token: this.$store.getters.get__token,
                    params: this.filter,
                })
                .then((response) => {
                    this.dataSubsubcategory = response.data;
                    this.calculateData(7);
                })
                .catch((errors) => {
                    this.$refs.SweetAlert.showError(errors);
                }); 
            } else {
                this.$refs.SweetAlert.showWarning("Debe seleccionar una Subcategoria");
            }
        },

        mtdSelectCategory: function () {
            this.categories.forEach((element, index) => {
                if (element.id == this.filter.category) {
                    this.categorie_control = element.name;
                    this.subcategories = element.subcategorie;
                }
            });
        },

        mtdSelectsubcategorie: function () {
            this.subcategories.forEach((element, index) => {
                if (element.id == this.Subsubcategory.subcategorie_id) {
                    this.subcategorie_control = element.name;
                    if (this.subcategorie_control == "GINECOLOGIA") {
                        this.Subsubcategory.obstetra = 1;
                    } else {
                        this.Subsubcategory.obstetra = 0;
                    }
                }
            });
        },

        mtdhonorarios: function () {
            this.Subsubcategory.honorarios_primer_ayudante = (
                this.Subsubcategory.honorarios_cirujano_principal * 0.25
            ).toFixed(2);
            this.Subsubcategory.honorarios_segundo_ayudante = (
                this.Subsubcategory.honorarios_cirujano_principal * 0.15
            ).toFixed(2);
            this.Subsubcategory.honorarios_tercer_ayudante = (
                this.Subsubcategory.honorarios_cirujano_principal * 0.15
            ).toFixed(2);
            this.Subsubcategory.sala_operacion = (
                this.Subsubcategory.honorarios_cirujano_principal * 0.3
            ).toFixed(2);

            (
                parseFloat(this.Subsubcategory.price_unit) +
                parseFloat(this.Subsubcategory.honorarios_cirujano_principal) +
                parseFloat(this.Subsubcategory.honorarios_primer_ayudante) +
                parseFloat(this.Subsubcategory.honorarios_segundo_ayudante) +
                parseFloat(this.Subsubcategory.honorarios_tercer_ayudante) +
                parseFloat(this.Subsubcategory.sala_operacion) +
                parseFloat(this.Subsubcategory.anestesiologo) +
                parseFloat(this.Subsubcategory.obstetra)
            ).toFixed(2);
        },

        mtdTotal: function () {
            (
                parseFloat(this.Subsubcategory.price_unit) +
                parseFloat(this.Subsubcategory.honorarios_cirujano_principal) +
                parseFloat(this.Subsubcategory.honorarios_primer_ayudante) +
                parseFloat(this.Subsubcategory.honorarios_segundo_ayudante) +
                parseFloat(this.Subsubcategory.honorarios_tercer_ayudante) +
                parseFloat(this.Subsubcategory.sala_operacion) +
                parseFloat(this.Subsubcategory.anestesiologo) +
                parseFloat(this.Subsubcategory.obstetra)
            ).toFixed(2);
        },

        calculateData(items) {
            if (!this.dataSubsubcategory || !this.dataSubsubcategory.length) {
                return (this.dataShow = []);
            }
            let filteredData = this.dataSubsubcategory;
            let indexInitial = this.selectPage;
            if (this.search.length >= 2 && this.search != "") {
                let query = this.search.toString().toUpperCase();
                filteredData = this.dataSubsubcategory.filter((element) => {
                    let name = element.name.toString().toUpperCase();
                    return name.includes(query);
                });
                indexInitial = 1;
            }

            let totalPages = Math.ceil(filteredData.length / items);
            this.stepPagination = Array.from({
                    length: totalPages,
                },
                (_, index) => index + 1
            );

            let startIndex = (indexInitial - 1) * items;
            let endIndex = startIndex + (items - 1);
            this.dataShow = filteredData.slice(startIndex, endIndex + 1);
            this.count = this.count + 1;
        },

        mtdModalShow: function (num, payload) {
            this.modal.title = (num == 0) ? 'Registrar Servicio Específico' : 'Editar Servicio Específico';
            this.modal.modal_form = true;
            this.modal.num = num;
            this.modal.payload = payload;
            //console.log(payload);
        },

        closeModal: function () {
            this.modal = {
                title: '',
                modal_form: false,
                num: null,
                payload: {},
            }
        },

        Datadetail: function (payload_detail, num_detail) {
            if (num_detail == 0) {
                payload_detail.action = 'c';
                this.addDatadetail(payload_detail, num_detail);
            } else if (num_detail == 1) {
                payload_detail.action = 'u';
                this.addDatadetail(payload_detail, num_detail);
            } else {
                let data = {
                    action : 'd',
                    id : payload_detail
                }

                this.$refs.SweetAlert.showConfirmSimple2("","¿Estás seguro?","warning","Eliminar") 
                .then((result) => {
                    if (result.value) {
                        this.addDatadetail(data, num_detail);
                    }
                });
            }
        },

        addDatadetail: function (payload_detail, num_detail) {
            this.post({
                    url: this.$store.getters.get__url + "/SubsubCategorie/crudSubsubCategorie",
                    token: this.$store.getters.get__token,
                    params: payload_detail,
                })
                .then(response => {
                    if (num_detail == 2) {
                        this.$refs.SweetAlert.showSuccess("El registro ha sido eliminado");
                        this.mtdSearchData();
                    } else {
                        this.$refs.SweetAlert.showSuccess(num_detail == 0 ? "Registro Exitoso" : "Modificación Exitosa");
                        this.filter.category = payload_detail.categorie_id;
                        this.filter.subcategory = payload_detail.subcategorie_id;
                        this.closeModal()
                        this.mtdSearchData();
                    }
                })
                .catch(error => {
                    this.$refs.SweetAlert.showError(error);
                })
        },


        //Agregar Categoria
        mtdModalOpenCat: function() {
            this.modalCa.title = 'Registrar Categoria';
            this.modalCa.modal_form = true;
            this.modalCa.num = 0;
        },

        closeModalCa: function () {
            this.modalCa = {
                title: '',
                modal_form: false,
                num: null,
            }
        },

        registerCa: function(payload_detail, num_detail) {
            payload_detail.action = 'c';
            this.addDataCa(payload_detail, num_detail);
        },

        addDataCa: function (payload_detail, num_detail) {
            this.post({
                    url: this.$store.getters.get__url + "/Category/crudCategories",
                    token: this.$store.getters.get__token,
                    params: payload_detail,
                })
            .then(response => {
                this.$refs.SweetAlert.showSuccess(num_detail == 0 ? "Registro Exitoso" : "Modificación Exitosa");
                this.closeModalCa()
                this.mtdGetData();
            })
            .catch(error => {
                this.$refs.SweetAlert.showError(error);
            })
        },

        //Agregar Sub Categoria
 
        mtdModalOpenSub: function() {
            this.modalSu.title = 'Registrar Subcategoria';
            this.modalSu.modal_form = true;
            this.modalSu.num = 0;
        },

        closeModalSu: function () {
            this.modalSu = {
                title: '',
                modal_form: false,
                num: null,
            }
        },

        registerSu: function(payload_detail, num_detail) {
            payload_detail.action = 'c';
            this.addDataSu(payload_detail, num_detail);
        },

        addDataSu: function (payload_detail, num_detail) {
            this.post({
                    url: this.$store.getters.get__url + "/SubCategory/crudSubCategory",
                    token: this.$store.getters.get__token,
                    params: payload_detail,
                })
            .then(response => {
                this.$refs.SweetAlert.showSuccess(num_detail == 0 ? "Registro Exitoso" : "Modificación Exitosa");
                this.closeModalSu()
                this.mtdGetData();
            })
            .catch(error => {
                this.$refs.SweetAlert.showError(error);
            })
        },


        //Excel
        getFile(e) {
            let file = e.target.files[0];
            if (!/\.(xlsx|xls|csv)$/i.test(file.name)) {
                this.$refs.SweetAlert.showInfo("Seleccione un archivo formato Excel");
                const input = this.$refs.fileInput;
                input.type = "text";
                input.type = "file";
                this.fileLoaded = false;
            } else {
                this.file = file;
                this.fileLoaded = true;
                const input = document.getElementById("file");
                readXlsFile(input.files[0]).then((rows) => {
                    this.all = rows;
                });
            }
        },

        mtdCommitReceipt:async function () {
            await this.post({
                url: this.$store.getters.get__url + "/Excel",
                token: this.$store.getters.get__token,
                params: {
                    data: this.all
                }
            })
            .then((response) => {
                if (response.data.state == 0) {
                    this.$refs.SweetAlert.showSuccess("Carga de datos exito");
                }
            })
            .catch((errors) => {
                this.$refs.SweetAlert.showError(errors);
            });
        },

        //Paginacion
        backPage() {
            this.selectPage = this.selectPage > 1 ? this.selectPage - 1 : 1;
        },
        nextPage() {
            this.selectPage =
                this.selectPage < this.stepPagination.length ?
                this.selectPage + 1 :
                this.stepPagination.length;
        },
        selectedPage(page) {
            this.selectPage = page;
        },
        mtdNumber: function(index){
            return (this.selectPage-1)*7 + (index + 1)
        },
        returnHome: function () {
            this.$emit("returnHome");
        },
    }
}
</script>
<style scoped>
.btn-disabled {
    background-color: #900052;
    color: #ffffff; 
    border-color: #900052;
}
</style>
    