<template>
    <div class="col-12">
        <div class="row d-flex">



            <div class="border border-1 p-3" v-if="typeof data === 'object' && !Array.isArray(data) && Object.keys(data).length > 0">
                <!-- TABLA MONITOREO -->
                <div class="row d-flex mt-2">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-2 table-responsive mb-2">
                        <table class="table table-bordered border-main mb-0 px-0 text-xss fw-bold">
                            <caption class="my-0 py-0"></caption>
                            <thead>
                                <tr class="text-white border-white text-center bg-main">
                                    <th rowspan="2" scope="col">FECHA</th>
                                    <th rowspan="2" scope="col">HORA</th>
                                    <th rowspan="2" scope="col">PA</th>
                                    <th rowspan="2" scope="col">PULSO X'</th>
                                    <th rowspan="2" scope="col">FR X'</th>
                                    <th rowspan="2" scope="col">TEMP</th>
                                    <th rowspan="2" scope="col">SO2</th>
                                    <th rowspan="2" scope="col">ENFERMERA</th>
                                </tr>
                            </thead>
                            <tbody>
                                <template v-for="(item1) in datasheets">
                                    <tr class="px-1 border-bottom border-main">
                                        <td class="text-center align-middle" :rowspan="item1.sheets.length">{{ item1.date }}</td>
                                        <td class="text-center" :class="item1.sheets[0].color">{{ item1.sheets[0].hour }}</td>
                                        <td class="text-center" :class="item1.sheets[0].color">{{ item1.sheets[0].pa }}</td>
                                        <td class="text-center" :class="item1.sheets[0].color">{{ item1.sheets[0].pulso }}</td>
                                        <td class="text-center" :class="item1.sheets[0].color">{{ item1.sheets[0].fr }}</td>
                                        <td class="text-center" :class="item1.sheets[0].color">{{ item1.sheets[0].temp }}</td>
                                        <td class="text-center" :class="item1.sheets[0].color">{{ item1.sheets[0].so2 }}</td>
                                        <td class="text-center align-middle">{{ item1.sheets[0].nurse_name }}</td>
                                    </tr>
                                    <template v-if="item1.sheets.length > 1">
                                        <tr class="px-1 border border-main align" v-for="(item2) in item1.sheets.slice(1)" :key="item2.id">
                                            <td class="text-center" :class="item2.color">{{ item2.hour }}</td>
                                            <td class="text-center" :class="item2.color">{{ item2.pa }}</td>
                                            <td class="text-center" :class="item2.color">{{ item2.pulso }}</td>
                                            <td class="text-center" :class="item2.color">{{ item2.fr }}</td>
                                            <td class="text-center" :class="item2.color">{{ item2.temp }}</td>
                                            <td class="text-center" :class="item2.color">{{ item2.so2 }}</td>
                                            <td class="text-center align-middle">{{ item2.nurse_name }}</td>
                                        </tr>
                                    </template>
                                </template>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class=" border border-main p-5" v-else>
                <div class=" text-center">
                    <h4>No hay Monitoreo</h4>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    mapActions
} from 'vuex';
export default {
    name: 'c-consultingshow',
    data() {
        return {
            datatime: [],
            datasheets: [],
            graphic_sheets: {
                date_name: 'GRÁFICA ACTUAL',
                id: null,
                medical_act_id: null,
                date: null,
                hour: null,
                day: 0,
                doctor_name: '',
                user_id: null,
                day_po: '',
                weight: '',
                deposition: '',
                urine: '',
                hydric_balance: '',
                values: [
                    { id: 1, label: 'PAS', value: '', value2: '', inputs: 2 },
                    { id: 2, label: 'PAD', value: '', inputs: 1 },
                    { id: 3, label: 'Pulso', value: '', inputs: 1 },
                    { id: 4, label: 'FR', value: '', inputs: 1 },
                    { id: 5, label: 'Temp', value: '', inputs: 1 },
                    { id: 6, label: 'S02', value: '', inputs: 1 },
                ],
                type: 1,
            },
            subpage: "buttons",
        }
    },
    props: {
        data: Object,
        typeService: String,
    },
    watch: {

    },
    computed: {

        
    },
    mounted() {
        if (typeof this.data === 'object' && !Array.isArray(this.data) && Object.keys(this.data).length > 0) {
            this.mtgetsheet();
        }
    },
    methods: {
        returnHome() {
            this.$emit('mtdChangePage', 'home');
        },
        mtgetsheet() {
            const dates = this.data.dates;
            const sheets = this.data.sheets;

            // Prepare the datatime array
            this.datatime = dates;

            // Prepare the datasheets array
            let fechas = dates.map(item => ({
                date: item,
                sheets: []
            }));

            sheets.forEach(horario => {
                let num = 0
                fechas.forEach(item => {
                    if (item.date === horario.datename) {
                        let parsedValues = JSON.parse(horario.values);
                        let detallesheet = {
                            id: horario.id,
                            hour: horario.hour,
                            pa: `${parsedValues[0].value}/${parsedValues[0].value2}`,
                            pulso: parsedValues[2].value,
                            fr: parsedValues[3].value,
                            temp: parsedValues[4].value,
                            so2: parsedValues[5].value,
                            color: '',
                            status: horario.status,
                            state: horario.state,
                            nurse_id: horario.nurse_id,
                            nurse_name: horario.nurse.name + ' ' + horario.nurse.last_name
                        };

                        if (horario.hour >= "07:31:00" && horario.hour < "19:30:00") {
                            detallesheet.color = 'text-primary'
                            item.sheets.push(detallesheet)
                        } else if (horario.hour >= "19:30:00" && horario.hour <= "23:59:00") {
                            detallesheet.color = 'text-danger'
                            item.sheets.push(detallesheet)
                        } else {
                            detallesheet.color = 'text-danger'
                            fechas[num - 1].sheets.push(detallesheet)
                        }
                    }
                    num++
                });
            });
            this.datasheets = []
                fechas.forEach(item2 => {
                    if (item2.sheets.length > 0) {
                        this.datasheets.push(item2)
                    }
                })
        }
    }

}
</script>
<style>
.table-responsive {
  overflow-x: auto;
}
.align-middle {
    vertical-align: middle; 
}
</style>
