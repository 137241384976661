<template>
<div>
    <div class="card-body" style="background-color: #e6e6e6">
        <!-- <div class="row">
                <div class="text-center h6 color-main">
                    <strong>{{ elementProcess.name }}</strong>
                </div>
            </div> -->
        <div class="row d-flex">
            <div class="w-100">
                <div class="row d-flex">
                    <!-- <div class="col-md-12">
                        <div class="form-check form-check-inline justify-content-end">
                            <input
                                v-model="elementProcess.reservadespues"
                                class="form-check-input"
                                type="checkbox"
                                id="checkboxreserva"
                                @click="reserva()"
                            />
                            <label class="form-check-label" for="checkboxFacturareserva">
                                Reservar Luego
                            </label>
                        </div>
                </div> -->
                    <div class="col-lg-6 col-md-12 mb-2">
                        <div class="d-flex justify-content-center">
                            <div class="card w-100">
                                <div align="center" class="card-header text-white h6 bg-main">
                                    <strong>DISPONIBILIDAD DE CAMA HOSPITALIZACIÓN</strong>
                                </div>
                                <div class="card-body w-100">
                                    <div class="row d-flex">
                                        <div class="col-md-12 text-center">
                                            <div class="d-flex justify-content-evenly">
                                                <ul class="nav nav-pills">
                                                    <li v-for="(item, index) in dataFloor" :key="index" class="nav-item me-1 mt-2" @click="mtdSelectGroup(item.id)">
                                                        <a :class="tab == item.id
                                      ? 'nav-link active'
                                      : 'nav-link'
                                    " aria-current="page" href="javascript:void(0)">{{ item.name }}</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row" v-if="tab != 0">
                                        <div class="col-12" id="scroll-div-beds">
                                            <div class="tab-pane" v-bind:id="tab + '-tab-pane'" role="tabpanel" v-bind:aria-labelledby="tab + '-tab'" tabindex="0">
                                                <div class="row d-flex justify-content-center text-center">
                                                    <!-- CAMAS -->
                                                    <div class="col-lg-6 col-md-6 col-sm-6 card bg-card-bed m-3 p-1" v-for="(itemg, index) in dataBedGroupsD" :key="index" :style="'max-height: ' +
                                180 * itemg.beds.length +
                                'px'
                                ">
                                                        <div v-for="(itemd, indexb) in itemg.beds" :key="indexb">
                                                            <div class="row d-flex g-0 align-items-center">
                                                                <div class="col-md-7">
                                                                    <img class="img-fluid-bed-sop rounded-start" src="../../../../assets/cama_sin_fondo.png" alt="" />
                                                                </div>
                                                                <div class="col-md-5">
                                                                    <div class="card-body">
                                                                        <div v-if="itemd.id == elementProcess.data.bed
                                          ">
                                                                            <div v-if="mostrar == true">
                                                                                <span id="" class="card-text circle p-2 text-white" v-if="itemd.id ==
                                              elementProcess.data.bed
                                              " style="background: orange">{{ itemd.name }}</span>
                                                                            </div>
                                                                            <div v-else>
                                                                                <span id="cama" class="card-text circle p-2 text-white" @click="
                                              mtdReserveBed(
                                                itemd.id,
                                                index,
                                                indexb
                                              )
                                              " v-if="itemd.status == 1" style="background: #39b54a">{{ itemd.name
  }}</span>
                                                                            </div>
                                                                        </div>
                                                                        <div v-else>
                                                                            <span id="cama" class="card-text circle p-2 text-white" @click="
                                            mtdReserveBed(
                                              itemd.id,
                                              index,
                                              indexb
                                            )
                                            " v-if="itemd.status == 1" style="background: #39b54a">{{ itemd.name
  }}</span>
                                                                            <span id="" class="card-text circle p-2 text-white" v-else-if="itemd.status == 2" style="background: #ff0000">{{ itemd.name
                                            }}</span>
                                                                            <span id="" class="card-text circle p-2 text-white" v-else-if="itemd.status == 3" style="background: #00b8ff">{{ itemd.name
                                            }}</span>
                                                                            <span id="" class="card-text circle p-2 text-white" v-else-if="itemd.status == 4" style="background: #fcee21">{{ itemd.name
                                            }}</span>
                                                                            <span id="" class="card-text circle p-2 text-white" v-else-if="itemd.status == 5" style="background: orange">{{ itemd.name
                                            }}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="d-flex justify-content-center">
                            <div class="card w-100">
                                <div align="center" class="card-header text-white h6 bg-main">
                                    <strong>DISPONIBILIDAD SOP</strong>
                                </div>
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-md-12 text-center">
                                            <div class="d-flex justify-content-evenly">
                                                <ul class="nav nav-pills">
                                                    <li v-for="(itemc, index) in rooms" :key="index" class="nav-item mt-2 me-1 h-100 text-xs" @click="mtdSelectConsultingRoom(itemc.id)">
                                                        <a :class="tab_c == itemc.id
                                      ? 'nav-link active'
                                      : 'nav-link'
                                    " aria-current="page" href="javascript:void(0)">{{ itemc.name }}</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>

                                        <div class="col-md-12">
                                            <div class="card w-100 mt-2 border-main" style="width: 20rem;">
                                                <div class="card-body p-0">
                                                    <div class="col-md-12 p-0 table-responsive" style="height: 19.5rem; background-color: #f1f2f3;">
                                                        <table class="table table-sm table-bordered p-0">
                                                            <thead>
                                                                <tr align="left" class="text-white border-white" style="
                                          font-size: 12px;
                                          background: #900052;
                                        ">
                                                                    <th scope="col">FECHA</th>
                                                                    <th scope="col" :colspan="cpColspan">
                                                                        HORARIOS
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr style="font-size: 12px" v-for="(item, index) in data" :key="index">
                                                                    <td class="py-2" style="font-weight: bold">
                                                                        <span class="rounded-pill p-1" style="background: #fff">{{ item.date }}</span>
                                                                    </td>
                                                                    <td class="py-2" v-for="(item2, index2) in item.hours" :key="index2">
                                                                        <span id="hora" class="rounded-pill py-1 px-2" style="background: #fff" v-if="item2.state == 0">{{ item2.name }}</span>
                                                                        <div v-if="elementProcess.data.hour ==
                                            item2.name &&
                                            elementProcess.data.date ==
                                            item.date &&
                                            tab_c == elementProcess.data.room
                                            ">
                                                                            <div v-if="mostrar == true">
                                                                                <span id="" class="rounded-pill py-1 px-2" style="
                                                  background: orange;
                                                  color: #fff;
                                                " v-if="elementProcess.data.hour ==
                                                  item2.name &&
                                                  elementProcess.data.date ==
                                                  item.date
                                                  ">{{ item2.name }}</span>
                                                                            </div>
                                                                            <div v-else>
                                                                                <span id="hora" class="rounded-pill py-1 px-2" @click="
                                                mtdReserveHour(
                                                  item.date,
                                                  item2.name,
                                                  index,
                                                  index2
                                                )
                                                " style="
                                                  background: #39b54a;
                                                  color: #fff;
                                                " v-if="item2.state == 1">{{ item2.name }}</span>
                                                                            </div>
                                                                        </div>
                                                                        <div v-else>
                                                                            <span id="hora" class="rounded-pill py-1 px-2" @click="
                                              mtdReserveHour(
                                                item.date,
                                                item2.name,
                                                index,
                                                index2
                                              )
                                              " style="
                                                background: #39b54a;
                                                color: #fff;
                                              " v-if="item2.state == 1">{{ item2.name }}</span>
                                                                            <span id="hora" class="rounded-pill py-1 px-2" style="
                                                background: #fcee21;
                                                color: #fff;
                                              " v-if="item2.state == 2">{{ item2.name }}</span>
                                                                            <span id="" class="rounded-pill py-1 px-2" style="
                                                background: #ff0000;
                                                color: #fff;
                                              " v-if="item2.state == 3">{{ item2.name }}</span>
                                                                            <span id="" class="rounded-pill py-1 px-2" style="
                                                background: orange;
                                                color: #fff;
                                              " v-if="item2.state == 4">{{ item2.name }}</span>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <br />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card-footer text-white" style="background: #900052; font-size: 20px">
        <div class="row d-flex">
            <div class="col-4" align="left">
                <span class="btn border border-white text-white" @click="mtdBehindStep"><strong>ATRAS</strong></span>
            </div>
            <div align="right" class="col-8">
                <span class="btn border border-white text-white" style="margin-left: 20px" @click="mtdNextStep"><strong>SIGUIENTE</strong></span>
            </div>
        </div>
    </div>
    <SweetAlert :swal="swal" ref="SweetAlert" />
</div>
</template>

<script>
import {
    mapActions
} from "vuex";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';

export default {
    name: "c-admision-cxc-sop",
    components: {
        SweetAlert,
    },
    props: {
        elementProcess: {},
    },
    data() {
        return {
            tab: 0,
            tab_c: 0,
            swal: null,
            rooms: [],
            data: [],
            dataFloor: [],
            dataBedGroupsD: [],
            verifSelectBed: false,
            oldPosSelectBed: [],
            oldPosSelectHour: [],
            mostrar: false,
            horaConfirmada: false,
            verifSelectHour: false
        };
    },
    computed: {
        cpColspan() {
            return this.data.length == 0 ? 10 : this.data[0].hours.length;
        }
    },
    mounted() {

        this.mtdGetData();
        this.mtdGetConsultingRoom();
    },
    methods: {
        ...mapActions(["get", "post"]),
        mtdGetData: function () {
            this.get({
                    url: this.$store.getters.get__url +
                        "/Venta/floor/" +
                        this.$store.getters.get__campus,
                    token: this.$store.getters.get__token,
                })
                .then((response) => {
                    this.dataFloor = response.data;
                    if (this.elementProcess.programado == true) {
                        this.mostrar = true;
                        this.mtdSelectGroup(this.elementProcess.data.floor);
                    } else {
                        this.mtdSelectGroup(this.dataFloor[0].id);
                    }
                })
                .catch((errors) => {});
        },
        mtdGetConsultingRoom: function () {
            this.get({
                    url: this.$store.getters.get__url +
                        "/Venta/consultingrooms/" +
                        this.$store.getters.get__campus,
                    token: this.$store.getters.get__token,
                })
                .then((response) => {
                    this.rooms = response.data;
                    if (this.elementProcess.programado == true) {
                        this.mostrar = true;
                        this.mtdSelectConsultingRoom(this.elementProcess.data.room);
                        this.horaConfirmada = true;
                    } else {
                        this.mtdSelectConsultingRoom(this.rooms[0].id);
                    }
                })
                .catch((errors) => {});
        },
        mtdSelectConsultingRoom: function (id) {
            this.post({
                    url: this.$store.getters.get__url + "/Venta/consultingrooms/filter",
                    token: this.$store.getters.get__token,
                    params: {
                        subsub: this.elementProcess.id,
                        room: id,
                    },
                })
                .then((response) => {
                    this.data = response.data;
                    this.tab_c = id;
                })
                .catch((errors) => {});
        },
        mtdSelectGroup: function (id) {
            this.post({
                    url: this.$store.getters.get__url + "/Venta/bedgroup/filter",
                    token: this.$store.getters.get__token,
                    params: {
                        floor_id: id,
                    },
                })
                .then((response) => {
                    this.dataBedGroupsD = response.data.bedgroups_details;
                    this.tab = id;
                })
                .catch((errors) => {});
        },
        mtdBehindGroup: function (data) {
            this.post({
                url: this.$store.getters.get__url + "/Venta/bedgroup/filter",
                token: this.$store.getters.get__token,
                params: {
                    floor_id: data[2],
                },
            }).then((response) => {
                let dataBedGroupsD;
                dataBedGroupsD = response.data.bedgroups_details;
                dataBedGroupsD[data[0]].beds[data[1]].status = 1;
            });
        },
        mtdReserveBed: function (bed, pos1, pos2) {
            if (this.dataBedGroupsD[pos1].beds[pos2].status == 1) {
                if (this.verifSelectBed == true) {
                    //verificar si es un grupo del mismo piso
                    if (this.oldPosSelectBed[2] != this.tab) {
                        this.mtdBehindGroup(this.oldPosSelectBed);
                    } else {
                        this.dataBedGroupsD[this.oldPosSelectBed[0]].beds[
                            this.oldPosSelectBed[1]
                        ].status = 1;
                    }
                }
                this.oldPosSelectBed = [pos1, pos2, this.tab];
                this.verifSelectBed = true;
                this.dataBedGroupsD[pos1].beds[pos2].status = 4;
            }
            if (this.verifSelectHour == true || this.horaConfirmada == true) {
                this.elementProcess.data.bed = bed;
                this.elementProcess.data.floor = this.tab;
            } else {
                this.elementProcess.data = {
                    bed: bed,
                    floor: this.tab,
                    room: this.tab_c,
                };
            }

            this.elementProcess.programado = true;
            this.mostrar = true;
            //console.log("cama");
            //console.log(this.elementProcess.data);
        },
        mtdReserveHour: function (fecha, hora, pos1, pos2) {
            /** consultar con bd si aun está libre */
            if (this.verifSelectHour == true) {
                this.data[this.oldPosSelectHour[0]].hours[
                    this.oldPosSelectHour[1]
                ].state = 1;
            }
            this.oldPosSelectHour = [pos1, pos2];
            this.verifSelectHour = true;
            this.data[pos1].hours[pos2].state = 4;

            // Asegurarte de que elementProcess.data sea un objeto, no un array
            if (typeof this.elementProcess.data !== 'object' || Array.isArray(this.elementProcess.data)) {
                this.elementProcess.data = {}; // Reiniciar a un objeto vacío si es un array
            }

            this.elementProcess.data.date = fecha;
            this.elementProcess.data.hour = hora;
            this.elementProcess.data.room = this.tab_c;
            this.elementProcess.programado = true;
            this.mostrar = true;

            //console.log("HORA");
            //console.log(this.elementProcess.data);
        },
        /* CAMBIAR DE COMPONENTES */
        mtdNextStep: function () {
            if (this.elementProcess.reservadespues === true) {
                this.$emit('mtdNextProcess', this.elementProcess);
            } else {
                if (
                    this.elementProcess.data.bed != null &&
                    this.elementProcess.data.bed != undefined
                ) {
                    if (
                        this.elementProcess.data.date != null &&
                        this.elementProcess.data.hour != null
                    ) {

                        this.tab = 0;
                        this.tab_c = 0;
                        this.rooms = [];
                        this.data = [];
                        this.dataFloor = [];
                        this.dataBedGroupsD = [];
                        this.verifSelectBed = false;
                        this.oldPosSelectBed = [];
                        this.oldPosSelectHour = [];
                        this.mostrar = false;
                        this.horaConfirmada = false;
                        this.verifSelectHour = false;
                        this.$emit("mtdNextProcess", this.elementProcess);
                        this.mtdGetData();
                        this.mtdGetConsultingRoom();
                    } else {
                        this.$refs.SweetAlert.showWarning("Seleccione Fecha y Hora");

                    }
                } else {
                    this.$refs.SweetAlert.showWarning("Seleccione cama");
                }
            }
        },
        mtdBehindStep: function () {
            //this.elementProcess.programado = false;
            this.$emit("mtdBehindProcess", this.elementProcess);
        },
    }

}
</script>
