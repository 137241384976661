<template>
<div>
    <div class="container mt-5">
        <div id="div-modal-venta" style="margin-top: -15px" class="d-flex justify-content-center">
            <div id="div-venta" class="card w-100">
                <div class="card-header text-white center bg-main" :class="elementProcess.name ? 'h6' : 'h5'">
                    <strong>AGREGAR SERVICIOS A LIQUIDACION {{ cpTitle }}</strong>
                </div>
                <!-- Contenido -->
                <div class="card-body" style="background-color: #e6e6e6" v-if="page == 'venta'  ||   page == 'pago'">
                    <div class="row d-flex">
                        <!-- BUSQUEDA DE PRODCUTO -->
                        <div class="col-md-6" id="div-venta-body">
                            <div class="col-md-12">
                                <div class="input-group input-group-sm mb-1">
                                    <span class="input-group-text" style="background: #900052; color: #ffffff"><i class="fas fa-search"></i></span>
                                    <input type="search" id="search_product" class="form-control" placeholder="Digitar Venta" aria-describedby="search_product" v-model="search_product" :disabled="page !== 'venta'" @keyup="mtd_filter_product" />
                                </div>
                            </div>

                            <div class="row py-1" id="list-product" v-if="page == 'venta'">
                                <div v-for="(item, index) in data_product_filter" :key="index" class="col-md-12 py-1 d-flex align-items-center" id="item-product" style="background-color: #e6e6e6; height: 80px">
                                    <div class="p-1 d-flex flex-column w-100 justify-content-center" style="background-color: #ffffff; height: 100%" @click="mtdSelectSubsubcatergorie(item)">
                                        <div class="align-items-center" id="name-product">
                                            <p class="d-flex align-items-center mb-0 m-1">
                                                <strong class="mb-0 bg-secondary text-white" style="text-align: CENTER; width: 12%; border-radius: 10px">
                                                    S/{{ item.precio_farmacia_particular }}
                                                </strong>
                                                <span style="width: 5%">&nbsp;</span>
                                                <!-- Espacio entre precio y nombre -->
                                                <strong class="mb-0 item-name custom-tooltip" data-toggle="tooltip" :data-placement="'top'" :title="item.name" style="width: 88%">
                                                    {{ item.name }}
                                                </strong>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- doctores -->
                        <div class="col-md-3 pt-3" v-if="this.doctors.length != 0">
                            <div class="col-md-12">
                                <div class="input-group input-group-sm mb-3">
                                    <span class="input-group-text rounded-pill w-100 bg-main text-white title-carrito">
                                        <strong>Médico</strong>
                                    </span>
                                    <strong>Seleccione un médico</strong>
                                </div>
                            </div>
                            <div class="col-md-12 list-doctor">
                                <div class="container-list">
                                    <div class="" v-if="doctors.length > 0">
                                        <div class="box item-doctor" v-for="(item, index) in doctors" :key="index" @click="mtdSelectDoctor(item)">
                                            <p class="text-doctor">
                                                DR(A).
                                                {{
                            item.doctor.last_name != null
                              ? item.doctor.last_name.toUpperCase()
                              : ""
                          }}
                                                {{ item.doctor.name.toUpperCase() }}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="" v-else>
                                        <!-- no hay data -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- carrito -->
                        <div class="col-md-3 pt-3" v-if="doctors.length == 0">
                            <div class="col-md-12">
                                <div class="input-group input-group-sm mb-3">
                                    <span class="input-group-text rounded-pill w-100" style="background: #900052; color: #ffffff; padding-left: 25px"><strong>SERVICIOS ESCOGIDOS</strong></span>
                                </div>
                            </div>
                            <div class="col-md-12 list-monto">
                                <div class="container-list">
                                    <div class="" v-if="carrito.length > 0">
                                        <div class="box item-monto" v-for="(item, index) in carrito" :key="index">
                                            <p class="text-monto">({{ item.quantity }}) {{ item.name }}</p>
                                            <div class="input-group" style="">
                                                <span class="input-group-text" style="color: #8a8a97">
                                                    <strong v-if="posEditPrice != index">S/ {{ parseFloat(item.price).toFixed(2) }} <br />S/
                                                        {{
                                parseFloat(item.price * item.quantity).toFixed(2)
                              }}</strong>
                                                    <input v-else type="text" style="background: white" class="form-control-sm" @keyup.enter="mtdOutFocusEditPrice" @blur="mtdOutFocusEditPrice" v-model="item.price" />
                                                </span>
                                                <div align="right" class="form-control" v-if="posEditPrice != index">
                                                    <button type="button" class="rounded-circle m-1" @click="mtdAddQuantity(index)">
                                                        <i class="fas fa-plus text-white"></i>
                                                    </button>
                                                    <button type="button" class="rounded-circle m-1" @click="mtdDelQuantity(index)">
                                                        <i class="fas fa-minus text-white"></i>
                                                    </button>
                                                    <button type="button" class="rounded-circle m-1" @click="mtdChangePrice(index)">
                                                        <i class="fas fa-triangle text-white"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- montos -->
                        <div class="col-md-3 pt-3">
                            <div class="col-md-12">
                                <div class="input-group input-group-sm mb-3">
                                    <span class="input-group-text rounded-pill w-100" style="background: #900052; color: #ffffff; padding-left: 25px"><strong>SALDOS</strong></span>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="list-group text-center">
                                    <a class="list-group-item list-group-item-action list-group-item-primary fw-bold">TOTAL GASTOS: S/ {{ cpTotalGastos }}</a>
                                    <a class="fw-bold list-group-item list-group-item-action list-group-item-warning">TOTAL PAGADO S/ {{ amount[1] }}</a>
                                    <a class="fw-bold list-group-item list-group-item-action list-group-item-dark">{{ cpEstado }}</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12"></div>
                    </div>
                </div>
                <!-- Seleccionar -->
                <ConsultorioVue v-if="page == 'consultas'" :elementProcess="elementProcess" @mtdNextProcess="mtdNextProcess" @mtdBehindProcess="mtdBehindProcess" />
                <CamasVue v-if="page == 'camas'" :elementProcess="elementProcess" @mtdNextProcess="mtdNextProcess" @mtdBehindProcess="mtdBehindProcess" />
                <SopVue v-if="page == 'sop'" :elementProcess="elementProcess" @mtdNextProcess="mtdNextProcess" @mtdBehindProcess="mtdBehindProcess" />
                <!-- FOOTER PARA VENTA -->
                <div class="card-footer text-white" style="background: #900052; font-size: 20px" v-if="page == 'venta' || page == 'pago' ">
                    <div class="row d-flex">
                        <div class="col-4" align="left">
                            <span class="btn border border-white text-white" @click="retroceder"><strong>ATRAS</strong></span>
                        </div>
                        <div align="right" class="col-8">
                            <strong> TOTAL SERVICIOS AGREGADOS: S/ {{ cpTotal }} </strong>
                            <span class="btn border border-white text-white" style="margin-left: 20px" v-if="page == 'venta'" @click="verifyZero"><strong>OK</strong></span>
                            <span class="btn border border-white text-white" style="margin-left: 20px" v-else @click="endSale"><strong>FINALIZAR</strong></span>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <br />
    </div>
    <SweetAlert :swal="swal" ref="SweetAlert" />
</div>
</template>

<script>
import {
    mapActions
} from "vuex";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import ConsultorioVue from "./ConsultorioCxc.vue";
import CamasVue from "./CamaCxc.vue";
import SopVue from "./SopCxc.vue";
export default {
    name: "c-cxc-carrito",
    components: {
        SweetAlert,
        ConsultorioVue,
        CamasVue,
        SopVue
    },
    props: {
        type_patient: "",
        amount: [],
        insurance: "",
        type: "",
        payment_eps: "",
    },
    data() {
        return {
            page: 'venta',
            dataSubSubCategories: [],
            data_product_filter: [],
            search_product: "",
            setTimeoutSearch: "",
            elementProcess: {},
            doctors: [],
            carrito: [],
            castCarrito: [],
            item_carrito: {},
            totalGasto: 0,
            posEditPrice: null,
            swal: null,
        };
    },
    computed: {
        cpTotal() {
            let total = 0;
            this.carrito.forEach((element) => {
                total += parseFloat(element.price) * parseFloat(element.quantity);
            });
            return parseFloat(total).toFixed(2);
        },
        cpTotalGastos() {
            let tot = parseFloat(this.amount[0]);
            this.carrito.forEach((element) => {
                tot += parseFloat(element.price) * parseFloat(element.quantity);
            });
            this.totalGasto = parseFloat(tot).toFixed(2);
            return parseFloat(tot).toFixed(2);
        },
        cpEstado() {
            let pen = parseFloat(this.totalGasto - this.amount[1]);
            let res =
                pen == 0 ?
                "PAGADO" :
                pen < 0 ?
                "A FAVOR DEL CLIENTE S/ " + parseFloat(pen * -1).toFixed(2) :
                "A FAVOR DE LA EMPRESA S/" + parseFloat(pen).toFixed(2);
            return res;
        },
        cpTitle() {
            let title = "";
            if (this.page !== 'pago') {
                title = this.elementProcess.name ? "- " + this.elementProcess.name : " "
            }
            return title;
        }
    },
    created() {
        // this.mtdGetDataSubSubCategories();
    },
    methods: {
        ...mapActions(["get", "post"]),
        mtdGetDataSubSubCategories: function () {
            this.get({
                    url: this.$store.getters.get__url +
                        "/cxc/" +
                        this.type +
                        "/" +
                        this.insurance +
                        "/subsubcategoriesCxc?search_product=" +
                        this.search_product,
                    token: this.$store.getters.get__token,
                })
                .then((response) => {
                    // this.carrito = [];
                    /*%*/
                    if (this.type == 'eps') {
                        response.data.forEach((element) => {
                            let tmpPrice = element.precio_farmacia_particular;
                            let newPrice = (tmpPrice * (100 - this.payment_eps)) / 100;
                            element.precio_farmacia_particular = parseFloat(newPrice).toFixed(2);
                        });
                    }
                    this.dataSubSubCategories = response.data;
                    this.data_product_filter = response.data;
                })
                .catch((errors) => {});
        },
        mtdSelectDoctor: function (item) {
            this.item_carrito.doctor.id = item.doctor.id;
            this.item_carrito.doctor.name =
                item.doctor.name + " " + item.doctor.last_name;
            this.doctors = [];
            //this.carrito.push(this.item_carrito), (this.item_carrito = []);
            this.carrito.push(this.item_carrito);
        },
        retroceder() {
            if (this.page == 'venta' || this.page == 'pago') {
                this.$emit("mtdBack");
            } else {
                this.elementProcess.programado = false;
                this.mtdBehindProcess();
            }
        },

        mtd_filter_product: function () {
            if (search_product.value.length >= 7 && search_product.value != "") {
                let q = search_product.value.toString().toUpperCase();
                this.mtdGetDataSubSubCategories(q);
                this.data_product_filter = [];
                let i = 0;
                this.dataSubSubCategories.forEach((element) => {
                    let name = element.name.toString().toUpperCase();
                    if (name.includes(q)) {
                        this.data_product_filter.push(element);
                    }
                });
            } else {
                this.data_product_filter = this.dataSubSubCategories;
            }
        },
        search() {
            clearTimeout(this.setTimeoutSearch);
            this.setTimeoutSearch = setTimeout(this.mtdGetDataSubSubCategories, 360);
        },
        /** carrito */
        truncateText(text, maxLength) {
            if (text.length > maxLength) {
                return text.substring(0, maxLength) + "...";
            }
            return text;
        },
        mtdSelectSubsubcatergorie: function (item) {

            let bo = false;
            if (this.carrito.length == 0) {
                this.item_carrito = {
                    id: item.id,
                    name: item.name,
                    price: item.precio_farmacia_particular,
                    quantity: 1,
                    subategorie: {
                        id: item.subCatId,
                        name: item.subCatName,
                    },
                    categorie: {
                        id: item.catId,
                        name: item.catName,
                        type: item.catType,
                    },
                    doctor: {
                        id: null,
                        name: null,
                    },
                };
                if (item.catId == 1 || item.catId == 8 || item.catId == 10) {
                    if (this.doctors.length == 0) {
                        this.doctors =
                            item.catId == 1 || item.catId == 8 || item.catId == 10 ?
                            item.doctors :
                            [];
                            if(item.doctors.length == 0){
                                this.$refs.SweetAlert.showWarning(
                            "Este servicio no tiene medicos asignados."
                        );
                            }
                        //  this.doctors = item.catId==1?item.doctors: [];
                        // this.carrito.push(this.item_carrito);
                    } else {
                        this.$refs.SweetAlert.showWarning(
                            "Este servicio no tiene medicos asignados."
                        );
                        this.doctors = [];
                    }
                } else {
                    this.doctors = [];
                    this.carrito.push(this.item_carrito);
                }
                bo = true;
            } else {
                this.carrito.forEach((element) => {
                    if (element.id == item.id) {
                        element.quantity = element.quantity + 1;
                        bo = true;
                    }
                });
            }
            if (!bo) {
                this.doctors =
                    item.catId == 1 || item.catId == 8 || item.catId == 10 ?
                    item.doctors :
                    [];
                this.item_carrito = {
                    id: item.id,
                    name: item.name,
                    price: item.precio_farmacia_particular,
                    quantity: 1,
                    subategorie: {
                        id: item.subCatId,
                        name: item.subCatName,
                    },
                    categorie: {
                        id: item.catId,
                        name: item.catName,
                        type: item.catType,
                    },
                };
                if (this.doctors.length == 0) {
                    this.carrito.push(this.item_carrito);
                }
                //this.carrito.push(this.item_carrito);
                bo = false;
            }
            this.search_product = "";
        },
        mtdAddQuantity: function (pos) {
            this.carrito[pos].quantity = this.carrito[pos].quantity + 1;
        },
        mtdDelQuantity: function (pos) {
            let cantidad = this.carrito[pos].quantity;
            if (cantidad > 1) {
                this.carrito[pos].quantity = this.carrito[pos].quantity - 1;
            } else {
                let temp = [];
                this.carrito.forEach((element, index) => {
                    if (index != pos) {
                        temp.push(element);
                    }
                });
                this.carrito = temp;
            }
        },
        /** programas citas o reservas */
        /* mtdNextStep: function (step) {
          if (this.carrito.length > 0) {
              this.page = 'consultas'
            //this.$emit("mtdSendCarrito", this.carrito);
            this.carrito = [];
          } else {
            this.$refs.SweetAlert.showWarning("Seleccione un Producto o Servicio");
          }
        }, mtd inicial */
        mtdNextStep: function (step) {
            if (this.carrito.length > 0) {
                if (step == 2) {
                    this.castCarrito = [];
                    this.carrito.forEach((element) => {
                        element.data = [];
                        //element.programado = false; // false cuando funcione reserva
                        element.actualPage = false;
                        if (element.categorie.id == 1) {
                            /** consultorio */
                            element.component = "consultas";
                        } else if (
                            element.categorie.id == 2 ||
                            element.categorie.id == 4 ||
                            element.categorie.id == 5 ||
                            element.categorie.id == 6 ||
                            element.categorie.id == 7 ||
                            element.categorie.id == 3
                        ) {
                            /** Hospitalización */
                            element.component = "camas";
                        } else if (
                            element.categorie.id == 8 ||
                            element.categorie.id == 10
                        ) {
                            /** sop */
                            element.component = "sop";
                        } else
                        /* if (element.categorie.id == 11) {
                                                   // laboratorio 
                                                   element.component = "laboratorio";
                                               } else if (element.categorie.id == 13) {
                                                   // imagenes
                                                   element.component = "imagenes";
                                               } else */
                        {
                            //this.$emit("mtdSendCarrito", this.carrito);
                            //this.carrito = [];
                            element.component = "pago";

                        }
                        this.castCarrito.push(element);
                    });
                    //poner  los de pago al ultimo
                    this.castCarrito.sort((a, b) => {
                        if (a.component === "pago" && b.component !== "pago") {
                            return 1;
                        } else if (a.component !== "pago" && b.component === "pago") {
                            return -1;
                        } else {
                            return 0;
                        }
                    });

                    this.mtdProcessCastCarrito();
                    // falta arreglar el orden de componente de carrito ejemplo: primer item == pago pasa directo son validar que los demas sean otros componentes
                }
            } else {
                this.$refs.SweetAlert.showWarning("Seleccione un Producto o Servicio");
            }
        },
        verifyZero: function () {
            const todosMayoresQueCero = this.carrito.every(
                (item) => parseFloat(item.price) > 0
            );

            const todosFarmacia = this.carrito.every(
                (item) => item.categorie.id > 10
            );
            if (todosMayoresQueCero) {
                if (todosFarmacia) {
                    this.mtdViewTotal();
                } else {
                    /* if (this.page === "pago") {
                        // Llama a la función específica para "pago"
                        this.mtdViewTotal();
                    } else {
                        // Llama a mtdNextStep para otras páginas
                        this.mtdNextStep(2);
                    } */
                    this.mtdNextStep(2);
                }

                return true;
            } else {
                this.$refs.SweetAlert.showWarning(
                    "Hay valores iguales o menores que 0.00"
                );
                return false;
            }
        },
        endSale: function () {
            this.mtdViewTotal();
        },
        mtdViewTotal: function () {
            if (this.carrito.length > 0) {
                this.$emit("mtdSendCarrito", this.carrito);
                this.carrito = [];
            } else {
                this.$refs.SweetAlert.showWarning(
                    "Seleccione un Producto o servicio"
                );
            }
        },
        mtdProcessCastCarrito: function () {
            let boo = false;
            for (let index = 0; index < this.castCarrito.length; index++) {
                const element = this.castCarrito[index];
                if (element.actualPage == true) {
                    boo = true;
                    element.actualPage = false;
                    if (index == this.castCarrito.length - 1) {
                        //ir a la página de pagos
                        this.page = "pago";
                        this.data_product_filter = [];
                    } else {
                        this.elementProcess = {};
                        this.elementProcess = this.castCarrito[index + 1];
                        this.castCarrito[index + 1].actualPage = true;
                        this.page = this.castCarrito[index + 1].component;
                        index = this.castCarrito.length;
                    }
                }
            }
            if (boo == false) {
                this.castCarrito[0].actualPage = true;
                this.elementProcess = {};
                this.elementProcess = this.castCarrito[0];
                // this.page = "pago";  sin sreserva
                this.page = this.castCarrito[0].component;
            }
        },
        mtdNextProcess: function (process) {
            this.page = "";
            for (let index = 0; index < this.castCarrito.length; index++) {
                let element = this.castCarrito[index];
                if (element.id == process.id) {
                    this.castCarrito[index] = process;
                    for (let i = 0; i < this.castCarrito.length; i++) {
                        if (
                            this.castCarrito[index].categorie.id ==
                            this.castCarrito[i].categorie.id
                        ) {
                            this.castCarrito[i].data = this.castCarrito[index].data;
                            this.castCarrito[i].reservadespues =
                                this.castCarrito[index].reservadespues;
                        }
                    }
                }
            }
            this.mtdProcessCastCarrito();
        },
        mtdBehindProcess: function () {
            let boo = false;
            for (let index = 0; index < this.castCarrito.length; index++) {
                const element = this.castCarrito[index];
                if (element.actualPage == true) {
                    if (index != 0) {
                        boo = true;
                        this.elementProcess = this.castCarrito[index - 1];
                        element.actualPage = false;
                        this.castCarrito[index - 1].actualPage = true;
                        this.page = this.castCarrito[index - 1].component;
                        break;
                    } else {
                        break;
                    }
                }
            }
            if (this.page == "pago") {
                let cat = this.castCarrito[this.castCarrito.length - 1].categorie.id;
                if (cat == 17 || cat == 12 || cat == 3 || cat == 14) {
                    this.page = "venta";
                    this.search_product = "";
                } else {
                    this.castCarrito[this.castCarrito.length - 1].actualPage = true;
                    this.elementProcess = this.castCarrito[this.castCarrito.length - 1];
                    this.page = this.castCarrito[this.castCarrito.length - 1].component;
                }
            } else if (boo == false) {
                //alert
                Swal.fire({
                    title: "¿Desea volver al menú?",
                    text: "¡Se perderá todo los datos seleccionados!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Si",
                    width: "400px",
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.page = "venta";
                        this.search_product = "";
                    }
                });
            }
        },

        /** temporal cambiar precio */
        mtdChangePrice: function (pos) {

            this.posEditPrice = pos;
        },
        mtdOutFocusEditPrice: function () {
            this.posEditPrice = null;
        },
    },
};
</script>

<style scoped>
.btn-home {
    z-index: 1;
}

/* Scrool de vista */
#div-venta-body {
    /* border: 1px solid #aaa; */
    height: 450px;
    /* background: #f1f2f3; */
    overflow: auto;
    box-sizing: border-box;
    padding: 1rem 1rem;
}

#div-venta-body::-webkit-scrollbar {
    -webkit-appearance: none;
}

#div-venta-body::-webkit-scrollbar:vertical {
    width: 10px;
}

#div-venta-body::-webkit-scrollbar-button:increment,
#div-venta-body::-webkit-scrollbar-button {
    display: none;
}

#div-venta-body::-webkit-scrollbar:horizontal {
    height: 10px;
}

#div-venta-body::-webkit-scrollbar-thumb {
    background-color: #797979;
    border-radius: 20px;
    border: 2px solid #f1f2f3;
}

#div-venta-body::-webkit-scrollbar-track {
    border-radius: 10px;
}

/*  */

#name-product {
    color: #8a8a97;
    font-size: 13px;
}

.list-doctor,
.list-monto {
    padding-right: 25px;
}

.text-doctor {
    color: #900052;
    font-size: 13px;
    font-weight: bold;
}

.text-monto {
    color: #900052;
    font-size: 13px;
    font-weight: bold;
    margin-bottom: 0px;
}

.container-list {
    margin: 30px;
    width: 100%;
}

.box {
    margin-bottom: 10px;
    position: relative;
}

.box::before {
    content: "";
    position: absolute;
    width: 3px;
    height: 100%;
    background: #808080;
    left: -15px;
    top: 30px;
}

.box::after {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #900052;
    left: -23px;
    top: 10px;
}

.item-doctor,
.item-monto {
    padding: 10px 10px;
    border: #900052 3px solid;
    border-top-left-radius: 20px;
    border-bottom-right-radius: 20px;
}

.item-doctor:hover {
    cursor: pointer;
    background-color: #900052;
}

.item-doctor:hover p {
    color: #ffffff !important;
}

#item-product {
    align-items: center;
}

#item-product:hover {
    cursor: pointer;
    align-items: center;
    transform: scale(1.05);
}

.item-monto .input-group-text,
.item-monto .form-control {
    background-color: #e6e6e6 !important;
    border: 0 !important;
    padding: 0px;
}

.item-monto .form-control button {
    background-color: #808080 !important;
    border: 0 !important;
}

.item-monto .form-control button:hover {
    background-color: #000000 !important;
    border: 0 !important;
}

.item-name {
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.item-name strong {
    padding: 0px 10px;
    width: 120px;
}

.circulo {
    vertical-align: middle;
    border-style: none;
}

@media (max-width: 800px) {
    #btn-open-caja {
        justify-content: center !important;
    }

    #btn-registro-venta {
        text-align: center;
    }

    #div-tabla-venta {
        overflow-x: auto;
    }
}

@media (min-width: 800px) {

    /* #div-modal-venta{
      margin-top: 10px;
    } */
    #btn-registro-venta {
        text-align: left;
    }
}
</style>
