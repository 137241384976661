<template>
    <CModal @mtdclosemodal="mtdclosemodal" :show="boo_modal" :close-on-backdrop="false" :centered="true" :title="title"
        :size="'lg'" :btn_cancel="true" :btn_ok="true" :booTitile="true">
        <div id="floorSelect">
            <ul>
                <li v-for="(item, index) in dataFloor" :key="index" class="d-flex flex-column">
                    <h2 class="h2_bed-name">{{ item.name }}</h2>
                    <div class="row mx-0 px-2 my-2">
                        <div class="row mx-0 px-0" v-if="filteredBeads(item.id).length > 0">
                            <div class="col-xl-3 mb-1 px-1" v-for="(itemBead, index) in filteredBeads(item.id)"
                                :key="index">
                                <div
                                    class="text-center text-white rounded rounded-2 text-xxs h-100 border border-main border-3 w-100">
                                    <button type="button" class="btn w-100 text-xs h-100"
                                        :class="{ 'bg-clinica': selectedBead == itemBead.id, 'bg-light': selectedBead != itemBead.id }"
                                        @click="selectBead(itemBead.id)" style="border-radius: 0;">
                                        {{ itemBead.name }}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="text-center fw-bold" v-else>
                            <span>No hay registros</span>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        <template #footer>
            <button @click="mtdclosemodal" class="btn btn-danger btn-sm">
                <i class="fa fa-times" />&nbsp;Cerrar
            </button>
            <button class="btn btn-success btn-sm" :disabled="dataBeads.length == 0" @click="mtdaction()">
                Guardar
            </button>
            &nbsp;
        </template>
        <SweetAlert :swal="swal" ref="SweetAlert" />
    </CModal>
</template>

<script>
import CModal from "@/components/shared/modals/CModal.vue";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import {
    mapActions
} from "vuex";

export default {
    name: "c-modal-beads",

    data() {
        return {
            swal: null,
            dataBeads: [],
            selectedBead: null,
            text: "",
            dataFloor: [],
        }
    },
    components: {
        CModal,
        SweetAlert
    },

    props: {
        title: String,
        boo_modal: Boolean,
        bead_id: [String, Number],
        floor_id: [String, Number],
        info_id: [String, Number],
        item: Object,
    },
    watch: {
        floor_id: {
            handler(newVal) {
                this.mtdGetData();
                if (this.floor_id == 1) {
                    this.mtdFreeCamas(this.floor_id);
                    this.text = "uci";
                } else if (this.floor_id == 2 || this.floor_id == 3) {
                    const floorIds = [2, 3];
                    this.mtdFreeCamas(floorIds);
                    this.text = "hospi";
                } else if (this.floor_id == 4) {
                    this.mtdFreeCamas(this.floor_id);
                    this.text = "emergency";
                }
            }
        }
    },
    methods: {
        ...mapActions(["get", "post"]),
        filteredBeads(floorId) {
            return this.dataBeads.filter(itemBead => itemBead.floor_id === floorId);
        },
        mtdclosemodal() {
            this.selectedBead = null;
            this.$emit('mtdclosemodalBed')
        },
        mtdGetData: function () {
            this.get({
                url: this.$store.getters.get__url + "/admision/floor/" + this.$store.getters.get__campus,
                token: this.$store.getters.get__token,
            })
                .then((response) => {
                    this.dataFloor = response.floors;
                })
                .catch((errors) => {
                    console.log(errors);
                });
        },
        mtdFreeCamas: function (floorIds) {
            const url = Array.isArray(floorIds)
                ? `${this.$store.getters.get__url}/admision/freebeds?floor_ids=${floorIds.join(',')}`
                : `${this.$store.getters.get__url}/admision/freebeds?floor_id=${floorIds}`;
            this.get({
                url: url,
                token: this.$store.getters.get__token,

            }).then((response) => {
                this.dataBeads = response.data;
            });

        },
        selectBead(beadId) {
            this.selectedBead = null;
            this.selectedBead = beadId; // Establece el ID de la cama seleccionada
        },
        mtdaction() {
            this.post({
                url: this.$store.getters.get__url + "/admision/changeBead",
                token: this.$store.getters.get__token,
                params: {
                    info_id: this.info_id,
                    selectedBead: this.selectedBead,
                    bead_past: this.bead_id,
                    floor_id: this.floor_id,
                    text: this.text,
                    item: this.item,
                }
            }).then((response) => {
                if (response.statusCode == 200) {
                    this.$refs.SweetAlert.showSuccess(response.message);
                    this.mtdclosemodal();
                } else {
                    this.$refs.SweetAlert.showSuccess(response.message);
                }
            });
        }
    },
}
</script>
<style scoped>
.bg-clinica {
    background-color: #900052 !important;
    color: white;
}

.bg-clinica:hover {
    color: white;
}

.bg-light {
    color: black !important;
}
.h2_bed-name{
    font-size: 20px;
}
</style>