<template>
<div>
    <div class="card-body" style="background-color: #e6e6e6">
        <!-- <div class="row">
                <div class="text-center h6 color-main">
                    <strong>{{ elementProcess.name }}</strong>
                </div>
            </div> -->
        <div class="row d-flex">
            <div class="w-100">
                <div class="row">
                    <div class="col-md-12 text-center">
                        <div class="d-flex justify-content-evenly">
                            <ul class="nav nav-pills">
                                <li v-for="(item, index) in dataFloor" :key="index" class="nav-item me-2 mt-2" @click="mtdSelectGroup(item.id)">
                                    <a :class="tab == item.id ? 'nav-link active' : 'nav-link'" aria-current="page" href="javascript:void(0)">{{ item.name }}</a>
                                </li>
                            </ul>
                            <!-- <ul class="nav nav-pills">
                    <li class="nav-item">
                      <a class="nav-link" aria-current="page"
                        href="javascript:void(0)">
                        <div class="form-check form-check-inline">
                        <input
                          v-model="elementProcess.reservadespues"
                          class="form-check-input"
                          type="checkbox"
                          id="checkboxreserva"
                          @click="reserva()"
                        />
                        <label class="form-check-label" for="checkboxFacturareserva">
                          Reservar Luego
                        </label>
                      </div>
                      </a>
                    </li>
                  </ul> -->
                        </div>
                    </div>
                </div>

                <div class="row" v-if="tab != 0">
                    <div class="tab-pane" v-bind:id="tab + '-tab-pane'" role="tabpanel" v-bind:aria-labelledby="tab + '-tab'" tabindex="0">
                        <div class="row m-2 p-4 d-flex justify-content-center">
                            <!-- CAMAS EN GRUPO -->
                            <div class="col-lg-4 col-md-6 col-sm-6 card bg-card-bed m-3 p-4" v-for="(itemg, index) in dataBedGroupsD" :key="index" :style="'max-height: ' +
                    180 * itemg.beds.length +
                    'px'
                    ">
                                <!-- <div v-for="(item, index) in dataBeds" :key="index"> -->
                                <div v-for="(itemd, indexb) in itemg.beds" :key="indexb">
                                    <div class="row d-flex g-0 align-items-center">
                                        <div class="col-md-7 col-sm-6">
                                            <img class="img-fluid-bed rounded-start" src="../../../../assets/cama_sin_fondo.png" alt="" />
                                        </div>
                                        <div class="col-md-5 col-sm-6">
                                            <div class="card-body p-1">
                                                <div v-if="itemd.id == elementProcess.data.bed">
                                                    <div v-if="mostrar == true">
                                                        <span class="card-text h6 circle text-white" v-if="itemd.id == elementProcess.data.bed" style="background: orange">{{ itemd.name }} </span>

                                                    </div>
                                                    <div v-else>
                                                        <span class="card-text h6 circle text-white" @click="mtdReserveHour(itemd.id, index, indexb)" v-if="itemd.status == 1" style="background: #39b54a">{{ itemd.name }}</span>
                                                    </div>
                                                </div>
                                                <div v-else>
                                                    <span class="card-text h6 circle text-white" @click="mtdReserveHour(itemd.id, index, indexb )" v-if="itemd.status == 1 && itemd.name != 'AUX'" style="background: #39b54a">{{ itemd.name }} </span>
                                                    <span class="card-text h6 circle text-white" @click="mtdReserveAuxiliar(itemd.id, index, indexb)" v-if="itemd.status == 1 && itemd.name == 'AUX'" style="background: #39b54a">{{ itemd.name }} </span>
                                                    <span class="card-text h6 circle text-white" v-else-if="itemd.status == 2" style="background: #ff0000">{{ itemd.name }}</span>
                                                    <span class="card-text h6 circle text-white" v-else-if="itemd.status == 3" style="background: #00b8ff">{{ itemd.name }}</span>
                                                    <span class="card-text h6 circle text-white" v-else-if="itemd.status == 4" style="background: #fcee21">{{ itemd.name }}</span>
                                                    <span class="card-text h6 circle text-white" v-else-if="itemd.status == 5" style="background: orange">{{ itemd.name }}</span>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- </div> -->
                            </div>

                            <!-- <div class="card m-3 p-4" style="border: 2px solid #900052; max-width: 400px;" v-for="(item, index) in dataBedSingle" :key="index">
                    <div class="row g-0 align-items-center">
                      <div class="col-md-7">
                        <img
                          class="img-fluid rounded-start"
                          src="../../../assets/cama_sin_fondo.png"
                          alt=""
                        />
                      </div>
                      <div class="col-md-5">
                        <div class="card-body">
                          <span class="card-text h4 circle p-3 text-white" v-if="item.status == 1" style="background: #39b54a">{{ item.name }}</span>
                          <span class="card-text h4 circle p-3 text-white" v-else-if="item.status == 2" style="background: #ff0000">{{ item.name }}</span>
                          <span class="card-text h4 circle p-3 text-white" v-else-if="item.status == 3" style="background: #fcee21">{{ item.name }}</span>
                        </div>
                      </div>
                    </div>
                  </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card-footer text-white" style="background: #900052; font-size: 20px">
        <div class="row d-flex">
            <div class="col-4" align="left">
                <span class="btn border border-white text-white" @click="mtdBehindStep"><strong>ATRAS</strong></span>
            </div>
            <div align="right" class="col-8">
                <span class="btn border border-white text-white" style="margin-left: 20px" @click="mtdNextStep"><strong>SIGUIENTE</strong></span>
            </div>
        </div>
    </div>
    <SweetAlert :swal="swal" ref="SweetAlert" />
</div>
</template>

<script>
import {
    mapActions
} from "vuex";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';

export default {
    name: "c-admision-cxc-consultorio",
    components: {
        SweetAlert,
    },
    props: {
        elementProcess: {},
    },
    data() {
        return {
            tab: 0,
            swal: "",
            dataFloor: [],
            dataBedGroupsD: [],
            // dataBedSingle: [],
            verifSelectBed: false,
            oldPosSelectBed: [],
            mostrar: false,
            cModalBed: {
                title: '',
                modal_form: false
            },
            bed: {
                campu_id: null,
                bed: null,
                pos1: null,
                pos2: null,
            },
            bedaux:null
        };
    },
    mounted() {
        this.mtdGetData();
    },
    methods: {
        ...mapActions(["get", "post"]),
        mtdGetData: function () {
            this.get({
                    url: this.$store.getters.get__url + "/Venta/floor/" + this.$store.getters.get__campus,
                    token: this.$store.getters.get__token,
                })
                .then((response) => {
                    this.dataFloor = response.data;
                    if (this.elementProcess.programado == true) {
                        this.mostrar = true;

                        let idFloor = this.elementProcess.data.floor;
                        this.mtdSelectGroup(this.elementProcess.data.floor);
                    } else {
                        this.mtdSelectGroup(this.dataFloor[0].id);
                    }
                })
                .catch((errors) => {});
        },
        mtdSelectGroup: function (id) {
            this.post({
                    url: this.$store.getters.get__url + "/Venta/bedgroup/filter",
                    token: this.$store.getters.get__token,
                    params: {
                        floor_id: id,
                    },
                })
                .then((response) => {
                    this.dataBedGroupsD = response.data.bedgroups_details;
                    this.tab = id;
                })
                .catch((errors) => {});
        },

        mtdBehindGroup: function (data) {
            this.post({
                    url: this.$store.getters.get__url + "/Venta/bedgroup/filter",
                    token: this.$store.getters.get__token,
                    params: {
                        floor_id: data[2],
                    },
                })
                .then((response) => {
                    let dataBedGroupsD;
                    dataBedGroupsD = response.data.bedgroups_details;
                    dataBedGroupsD[data[0]].beds[data[1]].status = 1;
                })
        },
        mtdReserveHour: function (bed, pos1, pos2) { //pos1->grupo , pos2->cama
            if (this.dataBedGroupsD[pos1].beds[pos2].status == 1) {
                if (this.verifSelectBed == true) {
                    //verificar si es un grupo del mismo piso
                    if (this.oldPosSelectBed[2] != this.tab) {
                        this.mtdBehindGroup(this.oldPosSelectBed);
                    } else {
                        this.dataBedGroupsD[this.oldPosSelectBed[0]].beds[this.oldPosSelectBed[1]].status = 1;
                    }
                }
                this.oldPosSelectBed = [pos1, pos2, this.tab];
                this.verifSelectBed = true;
                this.dataBedGroupsD[pos1].beds[pos2].status = 4;
            }
            this.elementProcess.data = {
                bed: bed,
                floor: this.tab,
                bedaux:this.bedaux
            };
            this.elementProcess.programado = true;
            this.mostrar = true;
        },
        mtdReserveAuxiliar: function (bed, pos1, pos2) {
            this.cModalBed = {
                title: 'Camas de hospitalizacion',
                modal_form: true
            }
            this.bed = {
                campu_id: this.$store.getters.get__campus,
                bed: bed,
                pos1: pos1,
                pos2: pos2
            }
        },
        mtdclosemodalBed: function () {
            this.cModalBed = {
                title: "",
                modal_form: false,
            }

        },
        addbedAux: function (bedaux, payload) {
          this.bedaux = bedaux
          this.mtdReserveHour(payload.bed, payload.pos1, payload.pos2)
          this.mtdclosemodalBed()
        },
        /* CAMBIAR DE COMPONENTES */
        mtdNextStep: function () {
            if (this.elementProcess.reservadespues === true) {
                this.$emit('mtdNextProcess', this.elementProcess);
            } else {
                if (
                    this.elementProcess.data.bed != null &&
                    this.elementProcess.data.bed != undefined
                ) {

                    this.$emit("mtdNextProcess", this.elementProcess);
                } else {
                    this.$refs.SweetAlert.showWarning("Seleccione cama");
                }
            }
        },
        mtdBehindStep: function (bed, pos1, pos2) {
            //this.elementProcess.programado = false;
            this.$emit("mtdBehindProcess", this.elementProcess);
        },
    }

}
</script>
