<template>
    <div>
        <div id="div-modal-cxc" class="d-flex justify-content-center">
            <div id="div-cxc" class="w-100">
                <div class="card-body w-100">
                    <div class="row">
                        <div class="col-md-12 py-2">
                            <div class="row d-flex mx-0 mb-1">
                                <div :class="cpData.length > 0 ? 'col-xl-8 col-lg-12 col-md-12 col-sm-12 col-xs-12' : 'col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12'">
                                    <div class="input-group input-group">
                                        <span class="input-group-text text-white bg-main">
                                            <i class="fas fa-search"></i>
                                        </span>
                                        <input
                                            v-model="filter.molecula"
                                            @keyup.enter="mtdGetData()"
                                            type="text"
                                            class="form-control form-control"
                                            placeholder="Nombre / molécula / comercial / marca"
                                            style="background-color: #d2edf2"
                                        />
                                    </div>
                                </div>
                                <div class="col-xl-4 col-lg-12 col-md-12 col-sm-12 text-center py-2" v-if="cpData.length > 0">
                                    <div class="d-flex justify-content-end">
                                        <nav aria-label="Page navigation example">
                                            <ul class="pagination mb-1">
                                                <li class="page-item">
                                                    <a class="page-link page" href="javascript:void(0)" @click="backPage">Anterior</a>
                                                </li>
                                                <li v-if="limitedStepPagination[0] !== 1" class="page-item">
                                                    <a :class="selectPage === 1 ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(1)">1</a>
                                                </li>
                                                <li v-if="limitedStepPagination[0] > 2" class="page-item disabled">
                                                    <span class="page-link">...</span>
                                                </li>
                                
                                                <li v-for="(item, index) in limitedStepPagination" :key="index" class="page-item">
                                                    <a :class="selectPage === item ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(item)">{{ item }}
                                                    </a>
                                                </li>
                                                <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] <stepPagination.length - 1" class="page-item disabled">
                                                    <span class="page-link">...</span>
                                                </li>
                                                <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] !== stepPagination.length" class="page-item">
                                                    <a :class="selectPage === limitedStepPagination.length + 2 ? 'page-link active':'page-link'" href="javascript:void(0)" @click="selectedPage(stepPagination.length)">{{ stepPagination.length }}
                                                    </a>
                                                </li>
                                                <li class="page-item">
                                                    <a class="page-link page" href="javascript:void(0)" @click="nextPage">Siguiente</a>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 mb-3" id="content-timelinetable" v-if="showTable">
                            <table id="tb-cxc" class="table table-bordered border-main">
                                <caption class="py-0 my-0"></caption>
                                <thead class="bg-main">
                                    <tr class=" table-th text-white text-center font-size">
                                        <th class="table-th-number">N°</th>
                                        <th>MARCA</th>
                                        <th>MOLECULA</th>
                                        <th>PRESENTACIÓN</th>
                                        <th>COMERCIAL</th>
                                        <th>DOSIS</th>
                                        <th>UBICACIÓN</th>
                                        <th class="table-th-acciones" style="white-space: nowrap;">S/ PRECIO PART.</th>
                                        <th class="table-th-acciones" style="white-space: nowrap;">S/ PRECIO KAIROS</th>
                                        <th class="table-th-number">ACCIÓN</th>
                                    </tr>
                                </thead>
                                <tbody class="border-main">
                                    <tr class="table-body text-center font-size" v-if="cpData.length === 0">
                                        <td colspan="9" class="text-center">No hay registros</td>
                                    </tr>
                                    <tr class="table-body text-center font-size" v-for="(item, index) in cpData" :key="index">
                                        <td>  {{mtdNumber(index) }}</td>
                                        <td class="">
                                            {{item.brand}}
                                        </td>
                                        <td class="">
                                            {{item.molecule}}
                                        </td>
                                        <td class="">
                                            {{item.presentation}}
                                        </td>
                                        <td class="">
                                            {{item.commercial}}
                                        </td>
                                        <td class="">
                                            {{item.dosis ?  item.dosis : '-'}}
                                        </td>
                                        <td class="">
                                            {{item.location ?  item.location : '-'}}
                                        </td>
                                        <td class="">
                                            S/ {{item.precio_farmacia_particular}}
                                        </td>
                                        <td class="">
                                            S/ {{item.precio_farmacia_kairos }}
                                        </td>
                                        <td>
                                            <div class="text-center d-flex justify-content-center">
                                                <button
                                                    class="btn btn-sm a-success text-white" @click="mtdModalShow(1, item)">
                                                    <i class="fa fa-edit"></i>
                                                </button>
                                                <button
                                                    class="btn btn-sm a-primary text-white"  @click="mtdModalAlmacen(item.id)">
                                                    <i class="fa fa-solid fa-box"></i>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <cModalMedicPharmacy :num="modal.num" :payload="modal.payload" :title="modal.title"
        :boo_modal="modal.modal_form" @closeModal="closeModal" @Datadetail="Datadetail" />
        <cModalMedicAlmaPharmacy :payload="modalAlma.payload" :title="modalAlma.title"
        :boo_modal="modalAlma.modal_form"  @closeModalMedicAlma="closeModalMedicAlma"/>
        <SweetAlert :swal="swal" ref="SweetAlert" />
    </div>
</template>

<script>
import { mapActions} from "vuex";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import cModalMedicPharmacy from './Modals/cModalMedicPharmacy.vue';
import cModalMedicAlmaPharmacy from './Modals/cModalMedicAlmaPharmacy.vue';
export default {
    name:"maintenance-pharmacy-medic",
    components:{
        SweetAlert,
        cModalMedicPharmacy,
        cModalMedicAlmaPharmacy
    },
    data() {
        return {
            swal: null,
            filter: {
                molecula: "",
            },
            search:"",
            dataShow: [],
            medicaments: [],
            modal: {
                title: '',
                modal_form: false,
                num: null,
                payload: {},
            },
            modalAlma: {
                title: '',
                modal_form: false,
                payload: {},
            },
            showTable: false,
            //Pag
            page: 0,
            selectPage: 1,
            stepPagination: [],
            totalPagesToShow: 4,
            action: "",
        };
    },
    created() {
    },
    watch: {
        selectPage() {
            this.calculateData(10);
        },
    },
    computed: {
        classTh() {
            return 'align-middle border border-5 bg-main border-white';
        },
        
        cpData() {
            return this.dataShow;
        },

        limitedStepPagination() {
            const totalPages = this.stepPagination.length;
            const currentPage = this.selectPage;

            let startPage = 1;
            let endPage = totalPages;
            if (totalPages > this.totalPagesToShow) {
                const halfPagesToShow = Math.floor(this.totalPagesToShow / 2);

                if (currentPage > halfPagesToShow) {
                    startPage = currentPage - halfPagesToShow;
                    endPage = currentPage + halfPagesToShow - 1;

                    if (endPage > totalPages) {
                        endPage = totalPages;
                        startPage = totalPages - this.totalPagesToShow + 1;
                    }
                } else {
                    endPage = this.totalPagesToShow;
                }
            }

            return Array.from({
                    length: endPage - startPage + 1,
                },
                (_, i) => startPage + i
            );
        },
    },
    methods: {
        ...mapActions(["get", "post"]),

        mtdGetData() {
            this.post({
                url: this.$store.getters.get__url + "/Pharmacy/maintenance/mtdGetMedicPharmacy",
                token: this.$store.getters.get__token,
                params: this.filter,
            })
            .then((response) => {
                if(response.data.length > 0){
                    this.medicaments = response.data;
                    this.showTable = true;
                    this.calculateData(10);
                }else{
                    this.showTable = false;
                    this.$refs.SweetAlert.showWarning("Sin Medicamentos");
                }
            })
            .catch((errors) => {
                this.$refs.SweetAlert.showError(errors);
            });
        },

        calculateData(items) {
            if (!this.medicaments || !this.medicaments.length) {
                return (this.dataShow = []);
            }
            let filteredData = this.medicaments;
            let indexInitial = this.selectPage;
            let totalPages = Math.ceil(filteredData.length / items);
            this.stepPagination = Array.from({
                    length: totalPages,
                },
                (_, index) => index + 1
            );

            let startIndex = (indexInitial - 1) * items;
            let endIndex = startIndex + (items - 1);
            this.dataShow = filteredData.slice(startIndex, endIndex + 1);
            this.count = this.count + 1;
        },

        //Modals
        mtdModalShow: function (num, payload) {
            this.modal.title = (num == 0) ? 'Registrar Precios' : 'Editar Precios';
            this.modal.num = num;
            this.modal.payload = payload;
            this.modal.modal_form = true;
        },

        closeModal: function () {
            this.modal = {
                title: '',
                modal_form: false,
                num: null,
                payload: {},
            }
        },

        Datadetail: function (payload_detail, num_detail) {
            if (num_detail == 0) {
                payload_detail.action = 'c';
                this.addDatadetail(payload_detail, num_detail);
            } else if (num_detail == 1) {
                payload_detail.action = 'u';
                this.addDatadetail(payload_detail, num_detail);
            } else {
                let data = {
                    action : 'd',
                    id : payload_detail
                }

                this.$refs.SweetAlert.showConfirmSimple2("","¿Estás seguro?","warning","Eliminar") 
                .then((result) => {
                    if (result.value) {
                        this.addDatadetail(data, num_detail);
                    }
                });
            }
        },

        addDatadetail: function (payload_detail, num_detail) {
            this.post({
                    url: this.$store.getters.get__url + "/Pharmacy/maintenance/crudMedicPharmacy",
                    token: this.$store.getters.get__token,
                    params: payload_detail,
                })
                .then(response => {
                    if (num_detail == 2) {
                        this.$refs.SweetAlert.showSuccess("El registro ha sido eliminado");
                        this.action = payload_detail.action;
                        this.mtdGetData();
                    } else {
                        this.$refs.SweetAlert.showSuccess(num_detail == 0 ? "Registro Exitoso" : "Modificación Exitosa");
                        this.closeModal()
                        this.mtdGetData();
                    }
                })
                .catch(error => {
                    this.$refs.SweetAlert.showError(error);
                })
        },

        //Modals Almacen
        mtdModalAlmacen: function (id) {
            this.get({
                url: this.$store.getters.get__url + "/Pharmacy/maintenance/mtdModalAlmacen/" + id,
                token: this.$store.getters.get__token,
            })
            .then((response) => {
                if(response.data.length == 0){
                    this.$refs.SweetAlert.showWarning("No esta presente en ningún Almacén");
                }else{
                    this.modalAlma.title = 'Almacenes';
                    this.modalAlma.payload = response.data;
                    this.modalAlma.modal_form = true;
                }
            })
            .catch((errors) => {
                this.$refs.SweetAlert.showError(errors);
            });
        },

        closeModalMedicAlma: function () {
            this.modalAlma = {
                title: '',
                modal_form: false,
                payload: {},
            }
        },

        //Paginacion
        backPage() {
            this.selectPage = this.selectPage > 1 ? this.selectPage - 1 : 1;
        },
        nextPage() {
            this.selectPage =
                this.selectPage < this.stepPagination.length ?
                this.selectPage + 1 :
                this.stepPagination.length;
        },
        selectedPage(page) {
            this.selectPage = page;
        },
        mtdNumber: function (index) {
            return index + 1 + (this.selectPage - 1) * 10;
        },
    },
};
</script>

<style lang="scss" scoped>
.font-size {
    font-size: 12.5px !important; 
}
</style>
    