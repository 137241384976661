<template>
    <CModal @mtdclosemodal="mtdclosemodal" :show="boo_modal" :close-on-backdrop="false" :centered="true" :title="title" :size="'lg'" :btn_cancel="true" :btn_ok="true" :booTitile="true">
        <div class="row mx-0  px-2">
            <div class="mb-3">
                <label for="exampleFormControlInput1" class="form-label">Fecha</label>
                <input 
                    type="date" 
                    class="form-control" 
                    id="exampleFormControlInput1" 
                    v-model="date"
                    :min="minDate" 
                    :max="maxDate" 
                    @change="mtdhour"
                >
            </div>
            <label class="mb-3" for="">Horarios Disponibles</label>
            <div class=" row mx-0" v-if="hour.length > 0">
                <div class="col-1 mb-1 px-1"  v-for="(hora, index) in hour" :key="index">
                    <button @click="mtdseletedHour(hora)" type="button" :class="hora.color" class="btn btn-sm  w-100">{{ hora.name }}</button>
                </div>
            </div>
            <div v-else class=" d-flex justify-content-center align-items-center">
                <div> No hay resultados</div>
            </div>
        </div>
        <template #footer>
            <button @click="mtdclosemodal"  class="btn btn-danger btn-sm">
                <i class="fa fa-times" />&nbsp;Cerrar
            </button>
            <button :disabled="validation" @click="mtdAdd" class="btn btn-success btn-sm" >
                Guardar
            </button>
            &nbsp;
        </template>
    </CModal>
</template>
    
    <script>
    import CModal from "@/components/shared/modals/CModal.vue";
    
    import {
        mapActions
    } from "vuex";
    
    export default {
        name: "c-asistencial-consulta-modals-cModaldestino",
        components: {
            CModal,
        },
        data() {
            return {
                  minDate: '',
                  maxDate: '',
                  date:'',
                  hour:[],
                  hourSelected : ''
            };
        },
        props: {
            title: String,
            boo_modal: Boolean,
            stateHour:Boolean
    
        },
        
        watch : {
            stateHour: {
                handler: function (newValue) 
                {
                    if (newValue) {
                        this.setDateLimits();
                        this.mtdhour();
                        
                    }
                },
                deep: true,
                immediate: true,
            },
        },
        computed : {
            validation:function (){
                return !this.hourSelected
            }
        },
        methods: {
            ...mapActions(["get", "post"]),
            setDateLimits() {
                const today = new Date();
                const todayStr = today.toISOString().split('T')[0]; 
                const maxDate = new Date();
                maxDate.setDate(today.getDate() + 7); 
                const maxDateStr = maxDate.toISOString().split('T')[0];
                this.date = todayStr;
                this.minDate = todayStr;
                this.maxDate = maxDateStr;
            },
            mtdclosemodal() {
                this.hour.forEach(item =>{
                    item.color = 'btn-success'
                })
                this.hourSelected = ''
                this.$emit('mtdclosemodalLectura')
            },
            mtdhour:function(){
                this.post({
                    url: this.$store.getters.get__url + "/admision/horarioLectura",
                    token: this.$store.getters.get__token,
                    params: {
                        date:this.date
                    }
                })
                .then((response) => {
                  this.hour = []
                  response.data.calendar.hours.forEach(element => {
                     if(element.state == 1){
                        element.color = 'btn-success'
                        this.hour.push(element)
                     }
                  });
                  console.log(this.hour)
                })
                .catch((errors) => {
                    console.log(errors);
                });
            },
            mtdseletedHour:function(payload){
                this.hour.forEach(item =>{
                    item.color = 'btn-success'
                })
                payload.color = 'btn-info'
                this.hourSelected = payload.name
            },
            mtdAdd:function(){
                this.$emit('mtdhorarioLectura',this.date,this.hourSelected)
                this.mtdclosemodal()
            }
            
        },
    };
    </script>