<template>
  <div>
    <div class="px-2" v-if="view == 'data'">
      <div class="btn-home col-md-12 mt-3 mb-4 d-flex justify-content-end">
        <button type="button" class="btn btn-outline-main" @click="returnHome">
          <i class="fas fa-home-lg-alt"></i> Inicio
        </button>
      </div>
      <div id="div-modal-cxc" class="d-flex justify-content-center">
        <div id="div-cxc" class="card w-100">
            <div class="d-flex px-4 responsive-search mt-3">
                <div
                    v-if="acto.patient.name"
                    class="input-group input-group mb-1 custom-margin"
                    >
                    <span class="input-group-text spam" id="basic-addon1">
                        <strong>PACIENTE</strong>
                    </span>
                    <input
                        class="form-control form-control-sm text-center"
                        style="background-color: #ffffff; font-size: 15px"
                        disabled
                        :value="acto.patient ? acto.patient.name : ''"
                    />
                </div>
                <div class="d-flex">
                    <div class="input-group-append" style="margin-left: 10px">
                        <button
                        @click="mtdOpenDispath()"
                        class="btn btn-primary rounded-circle"
                        type="button"
                        style="background-color: #900052; border: 2px solid #900052"
                        >
                        <i class="fas fa-solid fa-file-pdf"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="card-body w-100">
                <div class="row">
                    <div class="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="col-md-12" id="content-timelinetable">
                            <div class="card-header text-white text-center h5 bg-main">
                                <div class="row d-flex align-items-center text-center" style="height: 100%;">
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <strong>RECETA</strong>
                                    </div>
                                </div>
                            </div>
                            <div class="list-group mb-3 scroll-container">
                                <div
                                    class="list-group-item list-group-item-action flex-column align-items-start"
                                    v-if="recipe === null || (recipe && recipe.recipe_details && recipe.recipe_details.length === 0)"
                                    >
                                    <div class="d-flex w-100 justify-content-center">
                                        <p class="mb-1">Sin Recetas</p>
                                    </div>
                                </div>
                                <div v-for="(item, index) in recipe ? recipe.recipe_details : []"
                                :key="index"
                                href="#"
                                >
                                <a href="#" class="list-group-item list-group-item-action flex-column align-items-start table-container" aria-current="true">
                                    <div class="d-flex w-100 justify-content-between align-items-center">
                                        <div class="d-flex flex-grow-1 justify-content-start">
                                            <h5 class="mb-1 text-title me-2" :class="getTypeClass(item.type)">
                                                {{ getType(item.type) }}
                                            </h5>
                                            <h5 :class="getBadgeClass(item.state)" class="mb-1 text-title ">
                                                {{ getStatusText(item.state) }}
                                            </h5>
                                        </div>
                                        <div class="ml-auto">
                                            <i class="fas fa-eye icon-custom" title="Detalle de Despacho" @click="verDetalle(item.id)"></i>
                                            <i v-if="item.cart_details_not_dispatched.length > 0 && item.state !== '3'" class="fas fa-trash-alt icon-custom" title="Eliminar Marca" @click="deleteCar(item.id)"></i>
                                        </div>
                                    </div>
                                </a>

                                <a href="#" class="list-group-item list-group-item-action flex-column align-items-start table-container" aria-current="true" @click="mtdAddEdit(item)"
                                :class="{
                                    'recipe-item-orange': item.desp_state === 2,
                                    'recipe-item-green': item.desp_state === 3,
                                }">
                                    <!-- <div class="d-flex w-100 justify-content-end z">
                                        <div class="d-flex align-items-center">
                                        <span
                                            :class="getBadgeClass(item.state)"
                                            class="badge badge-pill spams"
                                            >{{ getStatusText(item.state) }}</span
                                        >
                                        </div>
                                    </div> -->
                                    <h5 class="mb-1" style="font-size: 9.7px">
                                        <strong>DOSIS - MOLECULA - PRESENTACIÓN:</strong>
                                        {{ item.dosis ? item.dosis : '' }}
                                        {{ item.dosis && item.molecule ? '-' : '' }}
                                        {{ item.molecule ? item.molecule.toUpperCase() : '' }}
                                        {{ (item.dosis || item.molecule) && item.presentation ? '-' : '' }}
                                        {{ item.presentation ? item.presentation.toUpperCase() : '' }}
                                    </h5>
                                    <h5 class="mb-1" style="font-size: 10px">
                                        <strong>MARCA:</strong>
                                        {{ item.brand_name ? item.brand_name.toUpperCase() : "-"}}
                                    </h5>
                                    <h5 class="mb-1" style="font-size: 10px">
                                        <strong>VIA:</strong>
                                        {{ item.via ? item.via.toUpperCase() : "-" }}
                                    </h5>
                                    <h5 class="mb-1" style="font-size: 10px">
                                        <strong v-if="item.frequency">FRECUENCIA: </strong>
                                        <span v-if="item.frequency">{{ item.frequency }}</span>
                                        
                                        <span v-if="item.frequency && item.duration">&nbsp;</span>
                    
                                        <strong v-if="item.duration">DURACIÓN: </strong>
                                        <span v-if="item.duration">{{ item.duration.toUpperCase() }}</span>
                                    </h5>
                                    <h5 class="mb-1" style="font-size: 10px">
                                        <strong>TOTAL:</strong>
                                            {{item.total}}
                                        <span>
                                            <span>&nbsp;&nbsp;</span>
                                            <strong>PENDIENTE:</strong>  {{
                                                    item.duration && item.frequency && item.desp_state == 1
                                                    ? item.total
                                                    : item.pending
                                                }}
                                        </span>
                                    </h5>
                                </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-8 col-lg-12 col-md-12 col-sm-12 col-xs-12 py-2" v-if="view2 == 'carrito'">
                            <cCarrito
                                ref="modalCarroComponent"
                                @mtdBack="mtdBackCarro"
                                :recipeId="carrito.recipeId"
                                :molecule="carrito.molecule"
                                :dosis="carrito.dosis"
                                :presentation="carrito.presentation"
                                :name="carrito.name"
                                :type="carrito.type"
                                :detailId="carrito.detailId"
                                :nameMedic="carrito.nameMedic"
                                :total="carrito.total"
                                :dataSubSubCategories="dataSubSubCategories"
                                :data_product_filter="dataSubSubCategories"
                                :dataLote="carrito.item"
                                :destino="destino"
                                :recipe="recipe"
                                :totalMedic="carrito.totalMedic"
                                @addCarrito="addCarrito"
                                @mtdMediDespachar="mtdMediDespachar"
                            />
                    </div>
                    <div class="col-xl-8 col-lg-12 col-md-12 col-sm-12 col-xs-12 py-2" v-if="view2 !== 'carrito'">
                        <div class="col-md-12" id="content-timelinetable" 
                        v-if="recipe === null || (recipe && recipe.recipe_details && recipe.recipe_details.length === 0)">
                        </div>
                        <div class="col-md-12" id="content-timelinetable" v-else>
                            <div class="list-group mb-3 scroll-container">
                                <div class="list-group-item list-group-item-action flex-column align-items-start">
                                    <div class="d-flex w-100 justify-content-center">
                                        <p class="mb-1">Seleccione una Receta</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
      </div>
    </div>
    <cModalDetalleVue
      :title="modalDeta.title"
      :boo_modal="modalDeta.boo_modal"
      :item="modalDeta.item"
      :producto="modalDeta.producto"
      @mtdCloseModalDeta="mtdCloseModalDeta"
    />
    <cModalMedicDespachadoVue
      :title="modalDispath.title"
      :boo_modal="modalDispath.boo_modal"
      :item="modalDispath.item"
      @mtdCloseModalDispath="mtdCloseModalDispath"
    />
    <SweetAlert :swal="swal" ref="SweetAlert" />
  </div>
</template>
<script>
import { mapActions } from "vuex";
import cCarrito from "@/components/Farmacia/Recipes/cCarroMarca.vue";
import cModalDetalleVue from "@/components/Farmacia/Modals/cModalDetalle.vue";
import cModalMedicDespachadoVue from "@/components/Farmacia/Modals/cModalMedicDespachado.vue";
import SweetAlert from "@/components/shared/SweetAlert/SweetAlert.vue";
export default {
  name: "c-Pharmacy-cReci",
  components: {
    SweetAlert,
    cCarrito,
    cModalDetalleVue,
    cModalMedicDespachadoVue,
  },
  props: {
    acto: [],
    recipe: [],
    destino: String,
  },
  data() {
    return {
      swal: null,
      selectedTask: null,
      view: "data",
      view2: "data",
      
      carrito: {
        saleId: null,
        recipeId: 0,
        molecule: "",
        dosis: "",
        presentation: "",
        name: "",
        type: "",
        detailId: 0,
        nameMedic: 0,
        total: 0,
        totalMedic: 0,
        item: [],
      },
      modalDeta: {
        title: "Detalles de Medicamento",
        item: [],
        boo_modal: false,
        pos: null,
        producto: "",
      },
      modalDispath: {
        title: "Detalles de Despacho",
        item: [],
        boo_modal: false,
        pos: null,
      },
      carroDispath: {
        item: [],
      },
      dispath: [],
      dataSubSubCategories: [],
    };
  },
  created() {},
  computed: {},
  methods: {
    ...mapActions(["get", "post"]),

    //metodo retornar a Home
    returnHome: function () {
      this.$emit("mtdBack");
    },

    mtdGetDispath: function () {
      this.post({
        url: this.$store.getters.get__url + "/Pharmacy/recipe/mtdGetDispath",
        token: this.$store.getters.get__token,
        params: {
          recipeId: this.recipe.id,
          patientId: this.recipe.patient_id,
        },
      })
        .then((response) => {
          this.dispath = response.data.data;
        })
        .catch((errors) => {
          this.$refs.SweetAlert.showError(errors);
        });
    },

    mtdAddService: function () {
      this.view = "carritoDesp";
    },

    verDetalle(id) {
      this.get({
        url: this.$store.getters.get__url + "/Pharmacy/recipe/recetasDetalle/" + id,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          if (response.data.length > 0) {
            this.modalDeta.item = response.data;
            this.modalDeta.boo_modal = true;
          } else {
            this.modalDeta.boo_modal = false;
            this.$refs.SweetAlert.showWarning("Sin Medicamentos Despachados");
          }
        })
        .catch((errors) => {
          this.$refs.SweetAlert.showError(errors);
        });
    },

    mtdCloseModalDeta: function () {
      this.modalDeta = {
        title: "Detalles de Medicamento",
        item: [],
        boo_modal: false,
        pos: null,
      };
    },

    mtdAddEditValidation: function(item) {
        if(item.desp_state == "3" || item.desp_state == 3){
            this.$refs.SweetAlert.showWarning("Receta ya Atendida");
            //this.view2 = "data";
        }else {
            this.mtdAddEdit(item);
        }
    },

    mtdAddEdit: function (item) {
    this.clearCarrito();
      this.carrito.saleId = this.recipe.sale_id;
      this.carrito.recipeId = this.recipe.id;
      if (item.type == 2) {
        this.carrito.name = 
            (item.molecule ? item.molecule.toUpperCase() : "") +
            " - " +
            (item.presentation ? item.presentation.toUpperCase() : "");
      } else {
        this.carrito.name =
          (item.dosis ? item.dosis.toUpperCase() : "") +
          " - " +
          (item.molecule ? item.molecule.toUpperCase() : "") +
          " - " +
          (item.presentation ? item.presentation.toUpperCase() : "");
      }

      this.carrito.molecule = item.molecule ? item.molecule.toUpperCase() : "";
      this.carrito.dosis = item.dosis ? item.dosis.toUpperCase() : "";
      this.carrito.presentation = item.presentation
        ? item.presentation.toUpperCase()
        : "";
      this.carrito.type = item.type;

      this.carrito.detailId = item.id;
      this.carrito.nameMedic = item.product_id;
      this.carrito.total =
        item.duration && item.frequency && item.desp_state == 1 ? item.total : item.pending;
        this.carrito.totalMedic =  item.total;
      this.carrito.item = item;

      this.post({
        url: this.$store.getters.get__url + "/Pharmacy/recipe/mtdAddPd",
        token: this.$store.getters.get__token,
        params: {
          molecule: this.carrito.molecule,
          dosis: this.carrito.dosis,
          presentation: this.carrito.presentation,
          //nameMedic: this.carrito.nameMedic,
          nameMedic: null,
          type: this.carrito.type,
        },
      })
        .then((response) => {
          if (response.data.data.length > 0) {
            this.dataSubSubCategories = response.data.data;
            this.view2 = "carrito";
            this.clearBrandCarritoComponent();
          } else {
            this.$refs.SweetAlert.showWarning("Sin Marcas");
            this.clearCarrito();
          }
        })
        .catch((errors) => {
          this.$refs.SweetAlert.showError(errors);
        });
    },

    clearBrandCarritoComponent: function () {
        this.$nextTick(() => {
            if (this.$refs.modalCarroComponent && this.$refs.modalCarroComponent.clearBrandCarrito) {
                this.$refs.modalCarroComponent.clearBrandCarrito();
            } else {
                console.error('clearBrandCarrito no está definido o modalCarroComponent no está montado');
            }
        });
    },

    clearCarrito: function() {
        this.carrito = {
            saleId: null,
            recipeId: 0,
            molecule: "",
            dosis: "",
            presentation: "",
            name: "",
            type: "",
            detailId: 0,
            nameMedic: 0,
            total: 0,
            item: [],
        }
    },

    addCarrito(datos, item) {
      //this.view2 = "data";
     if (datos) {
        this.$emit("update", this.recipe.id);
        this.mtdAddEdit(item);
      } 
    },

    mtdMediDespachar: function (id) {
        this.view2 = "data"
        this.$emit("updateRecipe", id);
         this.mtdGetDispath();
    },

    mtdBackCarro() {
      this.view = "data";
    },

    mtdDelete(detailId) {
      const indice = this.carroDispath.item.findIndex(
        (item) => item.detailId === detailId
      );
      if (indice !== -1) {
        this.carroDispath.item.splice(indice, 1);
      }
    },

    mtdCloseModalDispath: function () {
      this.modalDispath = {
        title: "Detalles de Despacho",
        item: [],
        boo_modal: false,
        pos: null,
      };
    },

    mtdOpenDispath() {
      this.post({
        url: this.$store.getters.get__url + "/Pharmacy/recipe/mtdGetDispath",
        token: this.$store.getters.get__token,
        params: {
          recipeId: this.recipe.id,
          patientId: this.recipe.patient_id,
        },
      })
        .then((response) => {
          if (response.data.data.length > 0) {
            this.modalDispath.boo_modal = true;
            this.modalDispath.item = response.data.data;
          } else {
            this.modalDispath.boo_modal = false;
            this.$refs.SweetAlert.showWarning("Sin Medicamentos Despachados");
          }
        })
        .catch((errors) => {
          this.$refs.SweetAlert.showError(errors);
        });
    },
    getType(type) {
        switch (type) {
        case 1:
            return 'MEDICAMENTO';
        case 2:
            return 'INSUMO';
        default:
            return 'TIPO DESCONOCIDO';
        }
    },
    getTypeClass(type) {
        switch (type) {
        case 1:
            return 'badge-type-medic';
        case 2:
            return 'badge-type-insumo';
        default:
            return 'badge-secondary';
        }
    },
    getStatusText(state) {
        switch (state) {
        case "1":
            return 'SIN ATENDER';
        case "2":
            return 'ATENDIDO PARCIALMENTE';
        case "3":
            return 'ATENDIDO';
        default:
            return 'ESTADO DESCONOCIDO';
        }
    },
    getBadgeClass(state) {
        switch (state) {
        case "1":
            return 'badge-danger';
        case "2":
            return 'badge-warning';
        case "3":
            return 'badge-success';
        default:
            return 'badge-secondary';
        }
    },
    deleteCar(id){
        this.$refs.SweetAlert.showConfirmSimple2("","¿Estás seguro de Eliminar las Marcas Asignadas?","warning","Confirmar") 
        .then((result) => {
            if (result.value) {
                this.deleteCarDe(id);
            }
        });
    },

    deleteCarDe(id){
        this.get({
                url: this.$store.getters.get__url + "/Pharmacy/recipe/mtdCarDelete/" + id,
                token: this.$store.getters.get__token,
            })
        .then((response) => {
            if(response.statusCode == 200) {
                this.$refs.SweetAlert.showSuccess("Carrito Limpiado");
                this.$emit("update", this.recipe.id);
                this.mtdAddEdit(response.data);
                this.view2 = "data";
            }
        })
        .catch((errors) => {
            this.$refs.SweetAlert.showError(errors);
        });
    }

  },
};
</script>
<style scoped>
.bootom {
  background-color: #900052 !important;
  border: 0.5px solid #900052 !important;
}
.spam {
  background: #900052;
  color: #ffffff;
  width: 90px;
  line-height: 20px;
  justify-content: center;
  border-radius: 1rem;
}
.badge-danger {
    background-color: #fa0821;
    color: #ffffff;
    font-size: 8px;
    border-radius: 0.50rem;
    padding: 0.2rem;
    font-weight: bold;
    align-items: center;
    display: inline-flex;
  }
  .badge-warning {
    background-color: #f7ba03;
    color: #000000;
    font-size: 8px;
    border-radius: 0.50rem;
    padding: 0.2rem;
    font-weight: bold;
    align-items: center;
    display: inline-flex;
  }
  .badge-success {
    background-color: #0bc837;
    color: #ffffff;
    font-size: 8px;
    border-radius: 0.50rem;
    padding: 0.2rem;
    font-weight: bold;
    align-items: center;
    display: inline-flex;
  }
  .badge-type-medic {
    background-color: #096df1;
    color: #ffffff;
    font-size: 7.5px;
    border-radius: 0.50rem;
    padding: 0.2rem;
    font-weight: bold;
    align-items: center;
    display: inline-flex;
  }
  .badge-type-insumo {
    background-color: #abcd06;
    color: #ffffff;
    font-size: 7.5px;
    border-radius: 0.50rem;
    padding: 0.2rem;
    font-weight: bold;
    align-items: center;
    display: inline-flex;
  }
  .badge-secondary {
    background-color: #838383;
    color: #ffffff;
    font-size: 8px;
    border-radius: 0.50rem;
    padding: 0.2rem;
    font-weight: bold;
    align-items: center;
    display: inline-flex;
  }

  .scroll-container {
    max-height: 410px;
    overflow-y: auto;
    overflow-x: hidden; 
    width: 100%; 
    box-sizing: border-box; 
}

.recipe-item-orange {
    background-color: #fff4de; 
}

.recipe-item-green {
    background-color: #ebfde5; 
}

.icon-custom{
    color: #900052; 
    margin-left: 0.3em;
}

</style>
