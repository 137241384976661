<template>
  <div class="col-md-12">
    <div class="row d-flex">
      <div class="panel-border my-1">
        <div class="row d-flex text-xs">
          <div class="col-md-12 bg-main text-white text-center py-2">
            <span class="fw-bold">BALANCE HIDRICO - {{ typeService }}</span>
          </div>
        </div>
      </div>
      <div class="border border-1 p-3" v-if="data.length > 0">
        <!-- PAG -->
        <div class="row d-flex justify-content-end mt-1">
          <div class="col-12 px-0">
            <nav
              aria-label="Page navigation example"
              class="pagination-container"
            >
              <ul class="pagination pagination-xs justify-content-end">
                <li class="page-item">
                  <a
                    style="cursor: pointer"
                    href="javascript:void(0)"
                    class="page-link"
                    @click="backPage"
                    >«</a
                  >
                </li>
                <template>
                  <li class="page-item">
                    <a class="page-link active" href="javascript:void(0)">{{cpDateName}}</a>
                  </li>
                </template>
                <li class="page-item">
                  <a style="cursor: pointer" class="page-link" @click="nextPage"
                    >»</a
                  >
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <!--FIN PAG-->

        <div class="row d-flex px-2">
            <div class="panel-border my-1">
                <div class="row d-flex text-xs">
                    <div class="col-md-4 bg-main text-white py-2">
                        <span class="fw-bold">ENFERMERA </span>
                    </div>
                    <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12 py-2">
                        <span class="fw-bold">{{ cpNurse }}</span>
                    </div>
                </div>
            </div>
        </div>

        <!-- Cabezera -->
        <div class="row d-flex px-2 mt-1">
            <div class="panel-border my-1">
                <div class="row px-3 d-sm-flex">
                    <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12 col-xs-12 my-1">
                        <div id="div-te" class="form-group row align-left">
                            <label for="te" class="col-sm-8 color-main fw-bold">FECHA DE INGRESO</label>
                            <div class="col-xl-4 col-sm-12">
                            <input 
                                type="text" 
                                placeholder="-" 
                                class="form-control form-control-input form-control-sm" 
                                id="te" 
                                :value="medical_evolutions.date_entry ?? '-'" 
                                disabled
                            >
                            </div>
                        </div>
                    </div>

                    <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12 col-xs-12 my-1 mb-2" v-if="isDatePast">
                        <div id="div-fi" class="form-group row align-left">
                            <label for="fi" class="col-sm-6 color-main fw-bold">HORA DE ING.</label>
                            <div class="col-xl-6 col-sm-12">
                            <input 
                                type="text" 
                                placeholder="-" 
                                class="form-control form-control-input form-control-sm" 
                                id="fi" 
                                :value="medical_evolutions.hourBalance ?? '-'" 
                                disabled
                            >
                            </div>
                        </div>
                    </div>

                    <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12 col-xs-12 my-1">
                        <div id="div-curso" class="form-group row align-left">
                            <label for="curso" class="col-sm-6 color-main fw-bold">PESO</label>
                            <div class="col-xl-6 col-sm-12">
                            <input 
                                type="text" 
                                placeholder="-" 
                                class="form-control form-control-input form-control-sm" 
                                id="curso" 
                                :value="medical_evolutions.weight ?? '-'" 
                                disabled
                            >
                            </div>
                        </div>
                    </div>

                    <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12 col-xs-12 my-1 mb-2">
                        <div id="div-curso" class="form-group row align-left">
                            <label for="curso" class="col-sm-6 color-main fw-bold">HORAS</label>
                            <div class="col-xl-6 col-sm-12">
                            <input 
                                type="text" 
                                placeholder="-" 
                                class="form-control form-control-input form-control-sm" 
                                id="curso" 
                                :value="medical_evolutions.hourNum ?? '-'" 
                                disabled
                            >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row d-flex">
            <!--INGRESOS-->
           <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-2 table-responsive">
            <label for="te" class="col-sm-8 color-main fw-bold px-0 ">INGRESOS</label>
             <table class="table table-bordered border-main mb-0 px-0 text-xs">
                 <caption class="my-0 py-0"></caption>
                 <thead class="txt-xs">
                     <tr class="text-white border-white text-center bg-main">
                         <th scope="col" class="ingreso-egreso" colspan="2">Nombre</th>
                         <th scope="col" class="ingreso-egreso">7:30 am. - 1:30 pm.</th>
                         <th scope="col" class="ingreso-egreso">1:30 pm. - 7:30 pm.</th>
                         <th scope="col" class="ingreso-egreso">Parcial</th>
                         <th scope="col" class="ingreso-egreso">7:30 pm. - 7:30 am.</th>
                         <th scope="col" class="ingreso-egreso">Total</th>
                     </tr>
                 </thead>
                 <tbody class="div-text">
                    <tr v-if="cpDataIngreso.length === 0">
                        <td colspan="7" class="text-center">Sin Ingresos</td>
                    </tr>
                    <tr v-for="(item) in cpDataIngreso" :key="item.id">
                        <td colspan="2">{{ nameTableIngreso(item.name, item.otherText) }}</td>
                        <td class="text-center">{{  item.mañana ? parseFloat(item.mañana).toFixed(2) : item.mañana }}</td>
                        <td class="text-center">{{  item.tarde ? parseFloat(item.tarde).toFixed(2) : item.tarde }}</td>
                        <td class="text-center">{{ getParcial(item) }}</td>
                        <td class="text-center">{{ item.noche ? parseFloat(item.noche).toFixed(2) : item.noche}}</td>
                        <td class="text-center">{{ getTotal(item) }}</td>
                    </tr>
                    <tr v-for="(item, index) in cpMetabolicWater" :key="index" class="px-1 border border-main align">
                        <td colspan="2"><strong>AGUA METABOLICA</strong></td>
                        <td class="text-center">{{ item.mañanaIn }}</td>
                        <td class="text-center">{{ item.tardeIn }}</td>
                        <td class="text-center">{{ item.parcialIn }}</td>
                        <td class="text-center">{{ item.nocheIn }}</td>
                        <td class="text-center">{{ item.totalIn }}</td>
                    </tr>
                    <tr class="px-1 border border-main align">
                        <td colspan="2"><strong>SUB-TOTAL</strong></td>
                        <td class="text-center">{{ getSubtotal('mañana', 1) }}</td>
                        <td class="text-center">{{ getSubtotal('tarde',1) }}</td>
                        <td class="text-center">{{ getSubtotal('parcial',1) }}</td>
                        <td class="text-center">{{ getSubtotal('noche',1) }}</td>
                        <td class="text-center">{{ getSubtotal('total',1) }}</td>
                    </tr>
                 </tbody>
             </table>
           </div>
    
            <!--EGRESOS-->
            <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-2 table-responsive">
                <label for="te" class="col-sm-8 color-main fw-bold px-0 ">EGRESOS</label>
                <table class="table table-bordered border-main mb-0 px-0 text-xs">
                    <caption class="my-0 py-0"></caption>
                    <thead class="txt-xs">
                        <tr class="text-white border-white text-center bg-main">
                            <th scope="col" class="ingreso-egreso" colspan="2">Nombre</th>
                            <th scope="col" class="ingreso-egreso">7:30 am. - 1:30 pm.</th>
                            <th scope="col" class="ingreso-egreso">1:30 pm. - 7:30 pm.</th>
                            <th scope="col" class="ingreso-egreso">Parcial</th>
                            <th scope="col" class="ingreso-egreso">7:30 pm. - 7:30 am.</th>
                            <th scope="col" class="ingreso-egreso">Total</th>

                        </tr>
                    </thead>
                    <tbody class="div-text">
                        <tr v-if="cpDataEgreso.length === 0">
                            <td colspan="7" class="text-center">Sin Egresos</td>
                        </tr>
                        <tr v-for="(item) in cpDataEgreso" :key="item.id">
                            <td colspan="2">{{ nameTableEgreso(item.name, item.otherText) }}</td>
                            <td class="text-center">{{  item.mañana ? parseFloat(item.mañana).toFixed(2) : item.mañana }}</td>
                            <td class="text-center">{{  item.tarde ? parseFloat(item.tarde).toFixed(2) : item.tarde }}</td>
                            <td class="text-center">{{ getParcial(item) }}</td>
                            <td class="text-center">{{ item.noche ? parseFloat(item.noche).toFixed(2) : item.noche}}</td>
                            <td class="text-center">{{ getTotal(item) }}</td>
                        </tr>
                        <tr v-for="(item, index2) in cpInsensibleLosses" :key="index2" class="px-1 border border-main align">
                            <td colspan="2"><strong>PERDIDAS INSENSIBLES</strong></td>
                            <td class="text-center">{{ item.mañanaEg }}</td>
                            <td class="text-center">{{ item.tardeEg }}</td>
                            <td class="text-center">{{ item.parcialEg }}</td>
                            <td class="text-center">{{ item.nocheEg }}</td>
                            <td class="text-center">{{ item.totalEg }}</td>
                        </tr>
                        <tr class="px-1 border border-main align">
                            <td colspan="2"><strong>SUB-TOTAL</strong></td>
                            <td class="text-center">{{ getSubtotal('mañana', 2) }}</td>
                            <td class="text-center">{{ getSubtotal('tarde',2) }}</td>
                            <td class="text-center">{{ getSubtotal('parcial',2) }}</td>
                            <td class="text-center">{{ getSubtotal('noche',2) }}</td>
                            <td class="text-center">{{ getSubtotal('total',2) }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <!-- BALANCE -->
        <div class="row d-flex mt-2">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-2 table-responsive">
                <label for="te" class="col-sm-8 color-main fw-bold ">BALANCE</label>
                <table class="table table-bordered border-main mb-0 text-xs">
                    <caption class="my-0 py-0"></caption>
                    <thead class="txt-xs">
                        <tr class="text-white border-white text-center bg-main">
                            <th scope="col" class="ingreso-egreso">7:30 am. - 1:30 pm.</th>
                            <th scope="col" class="ingreso-egreso">1:30 pm. - 7:30 pm.</th>
                            <th scope="col" class="ingreso-egreso">Parcial</th>
                            <th scope="col" class="ingreso-egreso">7:30 pm. - 7:30 am.</th>
                            <th scope="col" class="ingreso-egreso">Total</th>
                        </tr>
                    </thead>
                    <tbody class="div-text">
                        <tr class="px-1 border border-main align">
                            <td class="text-center">{{ calculateDifference('mañana') }}</td>  
                            <td class="text-center">{{ calculateDifference('tarde') }}</td>   
                            <td class="text-center">{{ calculateDifference('parcial') }}</td>  
                            <td class="text-center">{{ calculateDifference('noche') }}</td>  
                            <td class="text-center">{{ calculateDifference('total') }}</td>    
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

      </div>
      <div class=" border border-main p-5" v-else>
          <div class=" text-center">
          <h4>No hay Balance Hidrico Registrados</h4>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "c-medicalAct-reportRp",
  data() {
    return {
      subpage: "buttons",
      pos: 0,
      medical_evolutions: {},
      isDatePast: true,
    };
  },
  props: {
    data: Array,
    typeService: String,
  },
  watch: {
    typeService: {
      handler: function (newValue) {
        this.pos = this.data.length - 1;
        this.medical_evolutions = this.data[this.pos];
      },
      deep: true,
      immediate: true,
    },
    pos: function () {
        this.checkDateEntry(); 
    },
  },
  mounted() {
    if(this.data.length > 0){
        if (this.medical_evolutions.date_entry === moment().format('YYYY-MM-DD')) {
            this.isDatePast = true;  
        } else {
            this.isDatePast = false;  
        }
    }
  },
  computed: {
    cpNurse() {
      let name = "";
      let last_name = "";
      let nurse = this.medical_evolutions.nurse;
      if (nurse) {
        name = nurse.name ?? "";
        last_name = nurse.last_name ?? "";
      }
      return name + " " + last_name;
    },
    cpDataIngreso() {
        return this.medical_evolutions.dataIngreso
        ? JSON.parse(this.medical_evolutions.dataIngreso)
        : [];
    },

    cpDataEgreso() {
        return this.medical_evolutions.dataEgreso
        ? JSON.parse(this.medical_evolutions.dataEgreso)
        : [];
    },

    cpMetabolicWater() {
        if (this.medical_evolutions.metabolic_water) {
        return JSON.parse(this.medical_evolutions.metabolic_water);
        }
        return [];
    },
    
    cpInsensibleLosses(){
        if (this.medical_evolutions.insensible_losses) {
        return JSON.parse(this.medical_evolutions.insensible_losses);
        }
        return [];
    },


    cpDateName() {
      let date = this.$options.filters.formatDate(this.medical_evolutions.date);
      let hour = this.$options.filters.formatTime(this.medical_evolutions.hour);

      return date + " " + hour;
    },
    cpDataDay() {
      return [
        { key: "Dias hosp", value: this.medical_evolutions.day_hosp },
        { key: "Dias SOP", value: this.medical_evolutions.day_sop },
        { key: "Días UCI", value: this.medical_evolutions.day_uci },
      ];
    },

  },
  methods: {
    returnHome() {
      this.$emit("mtdChangePage", "home");
    },

    getParcial(item) {
        const mañana = parseFloat(item.mañana) || 0;
        const tarde = parseFloat(item.tarde) || 0;
        return (mañana + tarde).toFixed(2);
    },

    getTotal(item) {
        const parcial = parseFloat(this.getParcial(item)) || 0;     
        const noche = parseFloat(item.noche) || 0;
        return (parcial + noche).toFixed(2);
    },

    getSubtotal(period, type) {
        let total = 0;
        if(type == 1){
            const dataIngreso = JSON.parse(this.medical_evolutions.dataIngreso);
            const metabolicWater = JSON.parse(this.medical_evolutions.metabolic_water);

            dataIngreso.forEach(item => {
                if (period === 'parcial') {
                    total += parseFloat(this.getParcial(item)) || 0;
                } else if (period === 'total') {
                    total += parseFloat(this.getTotal(item)) || 0; 
                } else {
                    total += parseFloat(item[period]) || 0; 
                }
            });
            if (metabolicWater && metabolicWater[0]) {
                const meta = metabolicWater[0];
                
                if (period === 'mañana') {
                    total += parseFloat(meta.mañanaIn || 0);
                } else if (period === 'tarde') {
                    total += parseFloat(meta.tardeIn || 0);
                } else if (period === 'noche') {
                    total += parseFloat(meta.nocheIn || 0);
                } else if (period === 'parcial') {
                    total += parseFloat(meta.parcialIn || 0);
                } else if (period === 'total') {
                    total += parseFloat(meta.totalIn || 0);
                }
            }
            return total.toFixed(2); 
        } else if(type==2) {

            const dataEgreso = JSON.parse(this.medical_evolutions.dataEgreso);
            const insensibleLosses = JSON.parse(this.medical_evolutions.insensible_losses);

            dataEgreso.forEach(item => {
                if (period === 'parcial') {
                    total += parseFloat(this.getParcial(item)) || 0;
                } else if (period === 'total') {
                    total += parseFloat(this.getTotal(item)) || 0; 
                } else {
                    total += parseFloat(item[period]) || 0; 
                }
            });
            
            if (insensibleLosses && insensibleLosses[0]) {
                const insen = insensibleLosses[0];

                if (period === 'mañana') {
                    total += parseFloat(insen.mañanaEg || 0);
                } else if (period === 'tarde') {
                    total += parseFloat(insen.tardeEg || 0);
                } else if (period === 'noche') {
                    total += parseFloat(insen.nocheEg || 0);
                } else if (period === 'parcial') {
                    total += parseFloat(insen.parcialEg || 0);
                } else if (period === 'total') {
                    total += parseFloat(insen.totalEg || 0);
                }
            }
            return total.toFixed(2); 
        }
    },

    calculateDifference(period) {
        const subtotal1 = parseFloat(this.getSubtotal(period, 1)) || 0; 
        const subtotal2 = parseFloat(this.getSubtotal(period, 2)) || 0; 
        const difference = subtotal1 - subtotal2; 
        return difference.toFixed(2); 
    },
  
    nameTableIngreso(name, otherText) {
        switch (name) {
            case 'dieta':
                return "Dieta";
            case 'tratamiento':
                return "Tratamiento Endocrino";
            case 'via_oral':
                return "Via Oral";
            case 'sonda_nasogastrica':
                return "Por sonda Nasogástrica";
            case 'tratamiento_entera':
                return "Tratamiento para Enterar";
            case 'transfusiones':
                return "Transfusiones";
            case 'agua_metabolica':
                return "Agua Metabólica";
            case 'otros':
                return otherText;
            default:
                return "-";
        }
    },

    nameTableEgreso(name, otherText) {
        switch (name) {
            case 'diuresis':
                return "Diuresis";
            case 'deposiciones':
                return "Deposiciones";
            case 'vomitos':
                return "Vómitos";
            case 'drenes':
                return "Drenes";
            case 'perdidas':
                return "Pérdidas Insensibles";
            case 'otros':
                return otherText;
            default:
                return "-";
        }
    },

    //Pag
    mtdback(subpage) {
      this.$emit("mtdChangeSubPage", subpage);
    },
    nextPage: function () {
      this.pos = this.pos == this.data.length - 1 ? this.pos : this.pos + 1;
      this.medical_evolutions = this.data[this.pos];
    },
    backPage: function () {
      this.pos = this.pos == 0 ? this.pos : this.pos - 1;
      this.medical_evolutions = this.data[this.pos];
    },
    checkDateEntry: function () {
        if (this.medical_evolutions.date !== null) {
            if (moment(this.medical_evolutions.date_entry).isSame(this.medical_evolutions.date)) {
                this.isDatePast = true;
            } else {
                this.isDatePast = false; 
            }
        } else {
            this.isDatePast = false;
        }
    },

  },
  filters: {
    formatDate(date) {
      return date != null ? moment(date).format("DD/MM/YYYY") : "-";
    },
    formatTime(time) {
      let arTime = time.split(":");
      if (arTime[0] <= 12) {
        return time + " am";
      } else {
        return arTime[0] - 12 + ":" + arTime[1] + ":" + arTime[2] + " pm";
      }
    },
  },
};
</script>
<style>
.ingreso-egreso {
    white-space: nowrap !important;
    vertical-align: middle !important;
}

.table-responsive {
  overflow-x: auto;
}
</style>


