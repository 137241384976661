<template>
    <div class="container">
        <div class="row">
            <div class=" btn-home  col-md-12 mt-3 mb-4 d-flex justify-content-end">
                <button type="button" class="btn btn-outline-main" @click="changePage('home')">
                    <i class="fas fa-recycle"></i> Atras
                </button>
            </div>
        </div>
        <div id="div-modal-cxc" class="d-flex justify-content-center">
            <div id="div-cxc" class="card w-100">
                <div class="text-center card-header text-white h4 bg-main">
                    <strong>UNIDADES</strong>
                </div>
                <div class="card-body w-100">
                    <div class="row">
                        <div class="col-md-12 py-2">
                            <div class="row mx-0 d-flex">
                                <div class="col-xl-10 col-lg-10 col-md-9 col-sm-9 col-xs-12 px-1">
                                    <div class="input-group input-group">
                                        <span class="input-group-text text-white bg-main"
                                        ><i class="fas fa-search"></i
                                        ></span>
                                        <input
                                        @keyup="calculateData(10)"
                                        v-model="search"
                                        type="text"
                                        class="form-control form-control"
                                        placeholder="Buscar"
                                        />
                                    </div>
                                </div>
                                <div class="col-xl-2 col-lg-2 col-md-3 col-sm-3 col-xs-12 btn-home mt-2">
                                    <button
                                        type="button"
                                        class="btn btn-bm-noradius w-100"
                                        @click="mtdOpenModal(null, 0, 'store')"
                                    >
                                        <i class="fas fa-plus"></i> Nueva
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 mt-2" id="content-timelinetable">
                            <table id="tb-cxc" class="table table-bordered border-main">
                                <caption class="py-0 my-0"></caption>
                                <thead class="bg-main">
                                    <tr class=" table-th text-white text-center font-size">
                                        <th class="table-th-number">N°</th>
                                        <th>NOMBRE</th>
                                        <th>UNIDADES</th>
                                        <th class="table-th-number">ESTADO</th>
                                        <th class="table-th-acciones">ACCIONES</th>
                                    </tr>
                                </thead>
                                <tbody class="border-main">
                                    <tr class="table-body text-center font-size" v-if="cpData.length === 0">
                                        <td colspan="5" class="text-center">No hay registros</td>
                                    </tr>
                                    <tr class="table-body text-center font-size" v-for="(item, index) in cpData" :key="index">
                                        <td>
                                            {{mtdNumber(index) }}
                                        </td>
                                        <td class="">
                                            {{item.name}}
                                        </td>
                                        <td class="">
                                            {{ item.quantity }}
                                        </td>
                                        <td>
                                            <div id="state_speciality" v-if="item.state == 1">
                                                <div class="bg-success text-white">Activo</div>
                                            </div>
                                            <div id="state_speciality" v-else>
                                                <div class="bg-secondary text-white">Inactivo</div>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="text-center d-flex justify-content-center">
                                                <button
                                                    class="btn btn-sm a-success text-white" @click="mtdOpenModal(item, 1, 'edit')"  v-if="item.id !== 1">
                                                    <i class="fa fa-edit"></i>
                                                </button>
                                                <button 
                                                    class="btn btn-sm a-danger text-white" @click="mtdBeforeDelete(item.id)"  v-if="item.id !== 1">
                                                    <i class="fas fa-trash-alt"></i>
                                                </button>
                                                </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mt-3 text-center">
                            <!-- paginado -->
                            <div v-if="cpData.length > 0" class="d-flex justify-content-center mb-2">
                                <nav aria-label="Page navigation example">
                                    <ul class="pagination mb-1">
                                        <li class="page-item">
                                            <a class="page-link page" href="javascript:void(0)" @click="backPage">Anterior</a>
                                        </li>
                                        <li v-if="limitedStepPagination[0] !== 1" class="page-item">
                                            <a :class="selectPage === 1 ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(1)">1</a>
                                        </li>
                                        <li v-if="limitedStepPagination[0] > 2" class="page-item disabled">
                                            <span class="page-link">...</span>
                                        </li>

                                        <li v-for="(item, index) in limitedStepPagination" :key="index" class="page-item">
                                            <a :class="selectPage === item ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(item)">{{ item }}
                                            </a>
                                        </li>
                                        <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] <stepPagination.length - 1" class="page-item disabled">
                                            <span class="page-link">...</span>
                                        </li>
                                        <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] !== stepPagination.length" class="page-item">
                                            <a :class="selectPage === limitedStepPagination.length + 2 ? 'page-link active':'page-link'" href="javascript:void(0)" @click="selectedPage(stepPagination.length)">{{ stepPagination.length }}
                                            </a>
                                        </li>
                                        <li class="page-item">
                                            <a class="page-link page" href="javascript:void(0)" @click="nextPage">Siguiente</a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <cModalUnidadVue :units="unit" :title="modal.title" :boo_modal="modal.modal_form" :data="modal.data" :action="modal.action" @closeModal="closeModal" @mtdCommitUnit="mtdCommitUnit" />
        <SweetAlert :swal="swal" ref="SweetAlert" />
    </div>
</template>

<script>
import { mapActions} from "vuex";
import cModalUnidadVue from "./../../modals/Mantenimiento/cModalUnidad.vue";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';

export default {
    name: "logis-maintenance-units",
    components: {
        SweetAlert,
        cModalUnidadVue,
    },
    data() {
        return {
            swal: null,
            search: "",
            dataUnit: [],
            dataShow: [],
            modal: {
                title: "",
                action: "",
                modal_form: false,
                data: {},
            },
            unit: {
                id: "",
                name: "",
                quantity: "",
                state: "",
            },
            //Pag
            page: 0,
            selectPage: 1,
            stepPagination: [],
            totalPagesToShow: 4,
        };
    },
    watch: {
        selectPage() {
            this.calculateData(10);
        },
    },
    created() {
        this.mtdGetData();
    },
    computed: {
        cpData() {
            return this.dataShow;
        },
        limitedStepPagination() {
            const totalPages = this.stepPagination.length;
            const currentPage = this.selectPage;

            let startPage = 1;
            let endPage = totalPages;
            if (totalPages > this.totalPagesToShow) {
                const halfPagesToShow = Math.floor(this.totalPagesToShow / 2);

                if (currentPage > halfPagesToShow) {
                    startPage = currentPage - halfPagesToShow;
                    endPage = currentPage + halfPagesToShow - 1;

                    if (endPage > totalPages) {
                        endPage = totalPages;
                        startPage = totalPages - this.totalPagesToShow + 1;
                    }
                } else {
                    endPage = this.totalPagesToShow;
                }
            }

            return Array.from({
                    length: endPage - startPage + 1,
                },
                (_, i) => startPage + i
            );
        },
    },
    methods: {
        ...mapActions(["get", "post"]),
        mtdGetData: function () {
            this.get({
                url: this.$store.getters.get__url + "/logistics/maintenance/unit",
                token: this.$store.getters.get__token,
            }).then((response) => {
                this.dataUnit = response.data;
                this.calculateData(10);
            })
            .catch((errors) => {
                this.$refs.SweetAlert.showError(errors);
            });
        },

        calculateData(items) {
            if (!this.dataUnit || !this.dataUnit.length) {
                return (this.dataShow = []);
            }
            let filteredData = this.dataUnit;
            let indexInitial = this.selectPage;
            if (this.search.length >= 2 && this.search != "") {
                let query = this.search.toString().toUpperCase();
                filteredData = this.dataUnit.filter((element) => {
                    let name = element.name.toString().toUpperCase();
                    return name.includes(query);
                });
                indexInitial = 1;
            }

            let totalPages = Math.ceil(filteredData.length / items);
            this.stepPagination = Array.from({
                    length: totalPages,
                },
                (_, index) => index + 1
            );

            let startIndex = (indexInitial - 1) * items;
            let endIndex = startIndex + (items - 1);
            this.dataShow = filteredData.slice(startIndex, endIndex + 1);
            this.count = this.count + 1;
        },

        mtdOpenModal: function (item, pos, action) {
            this.modal.title = "UNIDADES";
            this.modal.modal_form = true;
            this.modal.action = action;
            if (pos == 1) {
                this.unit = {
                    id: item.id,
                    name: item.name,
                    quantity: item.quantity,
                    state: item.state,
                }
            }
        },

        closeModal: function () {
            this.modal = {
                title: "",
                modal_form: false,
                data: {},
            };
        },

        mtdCommitUnit: function (payload) {
            if (this.modal.action == "store") {
                this.post({
                    url: this.$store.getters.get__url + "/logistics/maintenance/unit/store",
                    token: this.$store.getters.get__token,
                    params: payload,
                })
                .then((response) => {
                    if (response.data.state == 0) {
                        this.$refs.SweetAlert.showSuccess("Registro Exitoso");
                        this.dataUnit.push(response.data.data);
                        this.mtdGetData();
                        this.closeModal();
                    } else {
                        this.$refs.SweetAlert.showError("Error");
                    }
                })
                .catch((errors) => {
                    this.$refs.SweetAlert.showError(errors);
                });
            } else {
                this.post({
                    url: this.$store.getters.get__url + "/logistics/maintenance/unit/update",
                    token: this.$store.getters.get__token,
                    params: payload,
                })
                .then((response) => {
                    if (response.data.state == 0) {
                        this.$refs.SweetAlert.showSuccess("Modificación Exitosa");
                        this.mtdGetData();
                        this.closeModal();
                    } else {
                        this.$refs.SweetAlert.showError("Error");
                    }
                })
                .catch((errors) => {
                    this.$refs.SweetAlert.showError(errors);
                });
            }
        },

        mtdBeforeDelete: function (id) {
            this.unit.id = id;
            this.$refs.SweetAlert.showConfirmSimple2("¿Estás seguro?","No podrás revertir esto","warning","Aceptar") 
            .then((result) => {
                if (result.value) {
                    this.mtdDeleteData();
                }
            });
        },

        mtdDeleteData: function () {
            this.post({
                url: this.$store.getters.get__url + "/logistics/maintenance/unit/destroy",
                token: this.$store.getters.get__token,
                params: this.unit,
            })
            .then((response) => {
                if (response.data.state == 0) {
                    this.$refs.SweetAlert.showSuccess("El registro ha sido eliminado");
                    this.mtdGetData();
                }
            })
            .catch((errors) => {
                this.$refs.SweetAlert.showError(errors);
            });
        },

        //Paginacion
        backPage() {
            this.selectPage = this.selectPage > 1 ? this.selectPage - 1 : 1;
        },
        nextPage() {
            this.selectPage =
            this.selectPage < this.stepPagination.length ?
            this.selectPage + 1 :
            this.stepPagination.length;
        },
        selectedPage(page) {
            this.selectPage = page;
        },
        mtdNumber: function(index){
            return (this.selectPage-1)*10 + (index + 1)
        },
        changePage(page) {
            this.$emit('changePage', page)
        },
    },
};
</script>

<style lang="scss" scoped>
.font-size {
    font-size: 13px !important; 
}
</style>
