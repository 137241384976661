<template>
    <div id="loading-modal" class="content-load-modal bg-black-trans font-family-lobster">
      <div class="load-modal">Escuchando ...</div>
      <div id="btn_home" class="c-app  align-items-center">
        <div class="d-grid gap-2 mx-auto text-center col-lg-3 col-md-3 col-sm-12 col-xs-12">
          <h2>CLÍNICA BM</h2>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "c-loading-modal"
  };
  </script>
  <style>
    .bg-black-trans {
      background: #00000082;
  }
  
  .content-load-modal {
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      position: fixed;
      z-index: 2000;
      color: white;
  }
  
  .load-modal {
      text-align: center;
      height: 60px;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      font-size: 22px;
  }
  </style>