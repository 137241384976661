<template>
<div>
    <div class="px-5 pb-5 pt-2">
        <div class="row">
            <div class=" btn-home  col-md-12 mt-3 mb-4 d-flex justify-content-end">
                <button type="button" class="btn btn-outline-main" @click="returnHome">
                    <i class="fas fa-home-lg-alt"></i> Inicio
                </button>
            </div>
        </div>
        <div class="d-flex justify-content-center">
            <div class="card w-100 mb-lg-5">
                <!-- <div class="text-center card-header text-white h4 bg-main">
                    <strong>TRAMAS</strong>
                </div> -->
                <div class="card-body w-100">
                    <div class="row d-flex">
                        <!-- Escoger mes -->
                        <div class="col-xl-2 col-lg-3 col-md-4 col-sm-12 col-xs-12">
                            <div class="input-group input-group-sm mt-2 px-3 w-100">
                                <span class="input-group-text col-md-4">Mes</span>
                                <select class="form-control form-control-input form-control-sm" id="finalizo_mes" v-model="filter.mounth" @change="mtdGetData">
                                    <option value="0" disabled>[Seleccione...]</option>
                                    <option v-for="(item, index) in months" :key="index" :value="index + 1">
                                        {{ item }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <!-- lista de tablas -->
                        <div class="col-md-8 col-sm-12 col-lg-9 col-xs-12 px-3" :class="mtdShow() ? 'col-xl-7' : 'col-xl-8'">
                            <ul class="nav pb-0 mt-2" id="myTab" role="tablist">
                                <li v-for="(item, index) in tables" :key="index" class="nav-item-floor" role="presentation">
                                    <div class="btn-group">
                                        <button @click="changeTable(item.id,index)" :class="{
                          'item-button-tramas': true,
                          'nav-link': true,
                          active: filter.table === item.id,
                        }" id="home-tab" type="button">
                                            {{ item.label }}
                                        </button>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="col-xl-1 col-lg-9 col-md-9 col-sm-6 col-xs-6" v-if="mtdShow()">
                            <div class="d-flex justify-content-end mt-2 px-3">
                                <button class="btn btn-sm btn-success text-white " @click="mtdExporTxt('save')">
                                    <i class="fas fa-save"></i>
                                </button>
                            </div>
                        </div>
                        <div :class="mtdShow() ? 'col-xl-2 col-lg-3 col-md-3 col-sm-6 col-xs-6' : 'col-xl-2 col-md-12 col-sm-12 col-xs-12'">
                            <div class="d-flex justify-content-end mt-2 px-3">
                                <button class="btn btn-sm btn-info text-white " @click="mtdExporTxt()">
                                    <i class="fas fa-file-alt"></i>&nbsp;Exportar txt
                                </button>
                            </div>
                        </div>
                        <!-- Mostrar la tabla -->
                        <div class="col-md-12 mt-2">
                            <div class="row m-2 p-2 d-flex justify-content-center">
                                <div class="col-md-12 px-0" id="content-timelinetable">
                                    <table id="dtb-cxc" class="table table-bordered border-main text-center align-middle">
                                        <caption class="py-0 my-0"></caption>
                                        <thead>
                                            <tr :class="cpClass" style="vertical-align: middle">
                                                <th scope="col">Período de Reporte</th>
                                                <th scope="col">Código de la IPRESS</th>
                                                <th scope="col">Código de la UGIPRESS</th>
                                                <template v-for="(itemth) in tables[pos].thead">
                                                    <th scope="col">{{itemth}}</th>
                                                </template>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(item, index) in tables[pos].tbody" :key="index" class="border border-main text-xs">
                                                <td>{{ period }} </td>
                                                <td>{{ ipress }}</td>
                                                <td>{{ ipress }}</td>

                                                <!-- Mapeo dinámico para las columnas de las 13 tablas -->
                                                <template v-if="pos !== 7 && pos !== 10">
                                                    <template v-for="(field, fieldIndex) in getColumnsByPos(pos, item)">
                                                        <template v-if="pos ==12"> 
                                                            <!-- TABLA J - LLENADO MANUAL -->
                                                            <td v-if="fieldIndex == 0 || fieldIndex == 1">
                                                                {{ field }}
                                                            </td>
                                                            <template v-else>
                                                                <editable-cell :value="field" :column="fieldIndex" :index="index" @update-value="updateContentTablaJ " />
                                                            </template>

                                                        </template>
                                                        <!-- LLENADO POR BD -->
                                                        <td v-else>{{ field }}</td>
                                                    </template>
                                                </template>
                                                <template v-else-if="pos == 7">
                                                    <!-- LLENADO MANUAL DE DATOS PARTOS -->
                                                    <editable-cell :type="'select'" :column="'type'" :value="item.type" :index="index" :options="typeOptions" @update-value="updateContentTablaE " />
                                                    <editable-cell :type="'select'" :column="'complication'" :value="item.complication" :index="index" :options="complicationOptions" @update-value="updateContentTablaE " />
                                                    <!-- item.type, item.complication, item.partos, item.total, item.nac_vivos, item.nac_muertos -->
                                                    <editable-cell :value="item.partos" :column="'partos'" :index="index" @update-value="updateContentTablaE " />
                                                    <editable-cell :value="item.total" :column="'total'" :index="index" @update-value="updateContentTablaE " />
                                                    <editable-cell :value="item.nac_vivos" :column="'nvivos'" :index="index" @update-value="updateContentTablaE " />
                                                    <td>{{ cpCalculationNacMuertos(index) }}</td>
                                                    <td class="align-middle  d-flex justify-content-center">
                                                        <button v-if="index == 0" class="btn btn-success btn-sm text-xxs" title="Agregar fila" @click="mtdAddItem" :disabled="tables[pos].tbody.length == 2">
                                                            <i class="fas fa-plus text-white"></i>
                                                        </button>
                                                        <button v-else class="btn btn-danger btn-sm text-xxs" @click="removeItem(index)">
                                                            <i class="fas fa-minus text-white"></i>
                                                        </button>
                                                    </td>
                                                </template>
                                                <template v-else>
                                                    <!-- LLENADO MANUAL DE DATOS DE CIRUGIAS -->
                                                    <editable-cell :column="'programing'" :value="item.programing" :index="index" @update-value="updateContentTablaH " />
                                                    <editable-cell :column="'major'" :value="item.major_surgeries" :index="index" @update-value="updateContentTablaH " />
                                                    <editable-cell :column="'minor'" :value="item.minor_surgeries" :index="index" @update-value="updateContentTablaH " />
                                                    <editable-cell :column="'hours_p'" :value="item.hours_p" :index="index" @update-value="updateContentTablaH " />
                                                    <editable-cell :column="'hours_e'" :value="item.hours_e" :index="index" @update-value="updateContentTablaH " />
                                                    <editable-cell :column="'hours_acto'" :value="item.hours_acto" :index="index" @update-value="updateContentTablaH " />
                                                    <editable-cell :column="'suspended'" :value="item.suspended_surgeries" :index="index" @update-value="updateContentTablaH " />
                                                </template>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <SweetAlert :swal="swal" ref="SweetAlert" />
</div>
</template>

<script>
import {
    mapActions
} from "vuex";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import EditableCell from '@/components/shared/column/EditableCell.vue';

export default {
    name: "c-jefatura-tramas",
    components: {
        SweetAlert,
        EditableCell
    },
    data() {
        return {
            filter: {
                begindate: null,
                enddate: null,
            },
            swal: null,
            search: "",
            pos: 0,
            months: [
                'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
                'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
            ],
            typeOptions: [{
                    value: '1',
                    label: '1 - Vaginal'
                },
                {
                    value: '2',
                    label: '2 - Cesaria'
                }
            ],
            complicationOptions: [{
                    value: '1',
                    label: '1 - sin complicacion'
                },
                {
                    value: '2',
                    label: '2 - con complicacion'
                }
            ],
            posEditQuantity: null,
            posEdit: null,
            tables: [{
                    id: 1,
                    label: 'A',
                    thead: ['Consultorios Fisicos', 'Consultorios Funcionales', 'Camas Hospitalarias', 'Total de Medicos', 'Medicos Serums', 'Medicos Residentes', 'Enfermeras', 'Odontologos', 'Psicologos', 'Nutricionistas', 'Tecnologos medicos', 'Obstetrices', 'Farmaceuticos', 'Auxiliares /Tecnicos', 'Otros profesionales', 'Ambulancias'],
                    tbody: [],
                },
                {
                    id: 2,
                    label: 'B1',
                    thead: ['Sexo del Paciente', 'Grupo de Edad en años', 'Atenciones Medicas', 'Atenciones No Medicas', 'Atendidos/ Mes'],
                    tbody: [],
                },
                {
                    id: 3,
                    label: 'B2',
                    thead: ['Sexo del Paciente', 'Grupo etario', 'Diagnosticos definitivos', 'Total de Atendidos'],
                    tbody: [],
                },
                {
                    id: 4,
                    label: 'C1',
                    thead: ['Sexo', 'Grupo etario', 'Numero Total de Atencion', 'Numero total de Atendidos'],
                    tbody: [],
                },
                {
                    id: 5,
                    label: 'C2',
                    thead: ['Sexo', 'Grupo etario', 'CIE 10', 'Total atendido'],
                    tbody: [],
                },
                {
                    id: 6,
                    label: 'D1',
                    thead: ['Codigo especialidad', 'Ingresos', 'Egresos', 'Estancias', 'Pacientes dias', 'N° de camas', 'Dias cama disponible', 'Fallecidos'],
                    tbody: [],
                },
                {
                    id: 7,
                    label: 'D2',
                    thead: ['Sexo', 'Grupo etario', 'CIE 10', 'Total atendido'],
                    tbody: [],
                },
                {
                    id: 8,
                    label: 'E0',
                    thead: ['Tipo de parto', 'Complicacion del parto', 'Partos', 'Total de nacimientos', 'Nacimientos vivos', 'Nac. muertos', 'Accion'],
                    tbody: [],
                },
                {
                    id: 9,
                    label: 'F0',
                    thead: ['Sexo', 'Grupo de edad', 'Eventos', 'Total'],
                    tbody: [],
                },
                {
                    id: 10,
                    label: 'G0',
                    thead: ['Codigo de procedimiento', 'Total procedimientos', 'Servicio/especialiadad'],
                    tbody: [],
                },
                {
                    id: 11,
                    label: 'H0',
                    thead: ['Programacion', 'Cirugias mayores', 'Cirugias menores', 'Horas programadas', 'Horas efectivas', 'Hora acto quirurgico', 'Cirugias suspendidas'],
                    tbody: [],
                },
                {
                    id: 12,
                    label: 'I0',
                    thead: ['Centro asistencial', 'Correlativos otorgado', 'Número de HC', 'Tipo de documento', 'Número de documento', 'Sexo del paciento', 'Edad del Paciente', 'Servicio asistencial Origen', 'Centro Asistencial destino', 'Servicio Asistencial Destino', 'Diagnostico Principal', 'Tipo de diagnostico', 'Diagnostico Secundaria', 'Tipo de diagnostico', 'Fecha de extension', 'Fecha de trámite o solicitud de referencia'],
                    tbody: [],
                },
                {
                    id: 13,
                    label: 'J0',
                    thead: ['Codigo de Colegio Profesional', 'Total de profesionales', 'Horas Mensuales Programadas en Atencion Ambulatoria', 'Horas programadas en Emergencia', 'H. Prog. en hospitalizacion', 'H.M. Administrativas Programadas', 'Horas M. Programadas en Capacitacion', 'Horas, M. programadas Centro Quirurgico', 'Horas Mensuales Programadas en Centro Obstetrico', 'Horas Mensuales Programadas para Procedimientos', 'Horas Mensuales Pr. Otras Act'],
                    tbody: [],
                },
            ],
            filter: {
                table: 1,
                mounth: "",

            },
            period: "",
            ipress: "",
            dataShow: [],
            text: [],
            isValidInput: [],
            //Pag
            page: 0,
            selectPage: 1,
            stepPagination: [],
            totalPagesToShow: 4,
        };
    },
    computed: {
        cpClass() {
            return 'text-white border-white text-center bg-main text-xs';
        }

    },
    created() {
        this.getMonth();

    },
    methods: {
        ...mapActions(["get", "post"]),
        //mapear las columnas:
        getColumnsByPos(pos, item) {
            const columnsByPos = {
                /* A */
                0: [item.room, item.room_func, item.beds_hosp, item.doctors, item.doctor_serum, item.doctor_residentes, item.nurses, item.dentist, item.psychologist, item.nutricionista, item.medical_tech, item.obstetrices, item.pharmacy, item.technical, item.other, item.ambulance],
                /* B1 */
                1: [item.sex_patient, item.group_age, item.total, 0, item.total],
                /* B2 */
                2: [item.sex_patient, item.group_age, item.code, item.total],
                /* C1 */
                3: [item.sex_patient, item.group_age, item.total, item.total],
                /* C2 */
                4: [item.sex_patient, item.group_age, item.code, item.total],
                /* D1 */
                5: [item.codigo, item.income, item.expense, item.estancias, item.patient_day, item.num_beds, item.days_beds, item.deaths],
                /* D2 */
                6: [item.sex_patient, item.group_age, item.code, item.total],
                /* E1 */
                7: [item.type, item.complication, item.partos, item.total, item.nac_vivos, item.nac_muertos],
                /* F1 */
                8: [item.sex_type, item.group_age, item.evento, item.total],
                /* G */
                9: [item.code, item.total, item.ups],
                /* H */
                10: [item.programing, item.major_surgeries, item.minor_surgeries, item.hours_p, item.hours_e, item.hours_acto, item.suspended_surgeries],
                /* I */
                11: [item.origen, item.correlativo, item.hc, item.type_document, item.document, item.sex, item.age, item.service_origin,item.destination,
                    item.service_destination,item.cie_first,item.type_cie_first,item.cie_second,item.type_cie_second ,item.date_emission,item.date_acepted
                    ],
                /* J */
                12: [item.code, item.total, item.col_f, item.col_g, item.col_h, item.col_i, item.col_j, item.col_k, item.col_l, item.col_m, item.col_n],
            };
            return columnsByPos[pos] || [];
        },
        /* DATOS PARA LLENAR LA TABLA */
        mtdGetData: function () {
            this.get({
                    url: this.$store.getters.get__url + "/control/tramas/" + this.filter.mounth,
                    token: this.$store.getters.get__token,
                })
                .then((response) => {
                    if (response.statusCode == 200) {

                        this.data = response.data;
                        this.period = this.data.period;
                        this.ipress = this.data.codeIpress;
                        //completar la tabla
                        this.tables[0].tbody = this.data.tablaA;
                        this.tables[1].tbody = this.data.tablaBuno;
                        this.tables[2].tbody = this.data.tablaBdos;
                        this.tables[3].tbody = this.data.tablaCuno;
                        this.tables[4].tbody = this.data.tablaCdos;
                        this.tables[5].tbody = this.data.tablaDuno;
                        this.tables[6].tbody = this.data.tablaDdos;
                        this.tables[7].tbody = this.data.tablaEuno;
                        this.tables[8].tbody = this.data.tablaF;
                        this.tables[9].tbody = this.data.tablaG;
                        this.tables[10].tbody = this.data.tablaH;
                        this.tables[11].tbody = this.data.tablaI;
                        this.tables[12].tbody = this.data.tablaJ;
                    }
                })
                .catch((errors) => {
                    this.$refs.SweetAlert.showError(errors);
                });
        },
        cpCalculationNacMuertos(index) {
            let table = this.tables[this.pos].tbody;
            let tot = table[index].total - table[index].nac_vivos;
            let resp = isNaN(tot) ? 'NE_0002' : tot;
            table[index].nac_muertos = resp;
            return resp;

        },
        getMonth: function () {
            const currentDate = new Date();
            const month = currentDate.getMonth() + 1;
            this.filter.mounth = month;
            this.mtdGetData();
        },
        mtdAddItem() {
            let table = this.tables[this.pos].tbody;
            table.push({
                'type': 1,
                'complication': 1,
                "partos": 0,
                "total": 0,
                "nac_vivos": 0,
                'nac_muertos': 0,
            });
        },
        updateContentTablaE(value, index, column) {
            let table = this.tables[this.pos].tbody;
            switch (column) {
                case 'partos':
                    table[index].partos = value;
                    break;
                case 'total':
                    table[index].total = value;
                    break;
                case 'nvivos':
                    table[index].nac_vivos = value;
                    break;
                case 'type':
                    table[index].type = value;
                    break;
                case 'complication':
                    table[index].complication = value;
                    break;
                default:
                    break;
            }
        },
        updateContentTablaH(value, index, column) {
            let table = this.tables[this.pos].tbody;
            switch (column) {
                case 'programing':
                    table[index].programing = value;
                    break;
                case 'major':
                    table[index].major_surgeries = value;
                    break;
                case 'minor':
                    table[index].minor_surgeries = value;
                    break;
                case 'hours_p':
                    table[index].hours_p = value;
                    break;
                case 'hours_e':
                    table[index].hours_e = value;
                    break;
                case 'hours_acto':
                    table[index].hours_acto = value;
                    break;
                case 'suspended':
                    table[index].suspended_surgeries = value;
                    break;
                default:
                    break;
            }

        },
        mtdShow: function () {
            return this.pos == 7 || this.pos == 10 || this.pos == 12;
        },
        
        changeTable(id, index) {
            this.filter.table = id;
            this.pos = index;
        },
        updateContentTablaJ(value,index, column) {
            let table = this.tables[this.pos].tbody;
            switch (column) {
                case 2:
                    table[index].col_f = value;
                    break;
                case 3:
                    table[index].col_g = value;
                    break;
                case 4:
                    table[index].col_h = value;
                    break;
                case 5:
                    table[index].col_i = value;
                    break;
                case 6:
                    table[index].col_j = value;
                    break;
                case 7:
                    table[index].col_k = value;
                    break;
                case 8:
                    table[index].col_l = value;
                    break;
                case 9:
                    table[index].col_m = value;
                    break;
                case 10:
                    table[index].col_n = value;
                    break;
                default:
                    break;
            }
        },
        
        mtdExporTxt: function (action) {
            let array = this.tables[this.pos].tbody;
            this.text = [];
            array.forEach(element => {
                let text = this.period + "|" + this.ipress + "|" + this.ipress + "|";
                let dataExport = this.getColumnsByPos(this.pos, element);
                // Añadir los valores al texto, separados por "|"
                text += dataExport.join("|");
                this.text.push(text);
            });
            this.downloadText(action);

        },

        downloadText: function (action) {
            //guardar en la bd los registros exportados
            const textData = this.text.join("\n");
            if (textData !== "") {
                this.post({
                        url: this.$store.getters.get__url +
                            "/control/tramas/store",
                        token: this.$store.getters.get__token,
                        params: {
                            ...this.tables[this.pos],
                            period: this.period,
                            text: textData,
                        },
                    })
                    .then((response) => {
                        if (action !== 'save') {
                            // Unir el array de strings con saltos de línea
                            const blob = new Blob([textData], {
                                type: "text/plain"
                            });
                            const link = document.createElement("a");
                            link.href = URL.createObjectURL(blob);
                            link.download = "tabla_" + this.tables[this.pos].label + ".txt";
                            link.click();
                            URL.revokeObjectURL(link.href);
                        } else {
                            this.$refs.SweetAlert.showSuccess(response.message);

                        }
                    })
                    .catch((errors) => {});
            } else {
                this.$refs.SweetAlert.showInfo('No hay datos para exportar');

            }

        },
        removeItem(index) {
            let array = this.tables[this.pos].tbody;
            array.splice(index, 1);

        },
        returnHome: function () {
            this.$emit("mtdChangePage", 'home');
        },

    },
}
</script>
