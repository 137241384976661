<template>

<label :for="'input2' + index" class="col-sm-5 col-xl-5 col-lg-12 color-main fw-bold" v-if="!isEditing" @dblclick="startEditing">{{ value }}</label>
<div v-else class="col-sm-5 col-xl-5 col-lg-12 px-2">

    <input  type="text" :name="value" class=" form-control form-control-sm" @keyup.enter="finishEditing" @blur="finishEditing" v-model="inputValue">
</div>

</template>

<script>
export default {
    props: ['value', 'index','array'],
    data() {
        return {
            isEditing: false,
            inputValue: this.value,
            isValid: true
        };
    },
    methods: {
        startEditing() {
            this.isEditing = true;
        },
        finishEditing() {
            this.$emit('update-label', this.inputValue, this.index,this.array);
            this.isEditing = false;
        },
    }
};
</script>
