<template>
<div>
    <div class="card-body" style="background-color: #e6e6e6">
        <!-- <div class="row">
                <div class="text-center h6 color-main">
                    <strong>{{ elementProcess.name }}</strong>
                </div>
            </div> -->
        <div class="row d-flex">
            <div class="w-100">
                <div class="row">
                    <div class="col-md-12 text-center">
                        <div class="d-flex justify-content-evenly">
                            <ul class="nav nav-pills">
                                <li v-for="(item, index) in rooms" :key="index" class="nav-item" @click="mtdSelectTab(item.id)">
                                    <a :class="tab == item.id ? 'nav-link active' : 'nav-link'" aria-current="page" href="javascript:void(0)">{{ item.name }}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="row d-flex" v-if="tab != 0">
                    <div v-for="(item, index) in doctors" :key="index" class="col-lg-4 col-md-6 mt-3" id="btn-medico">
                        <button type="button" class="btn w-100 rounded-pill bg-main text-white">
                            <strong>Dr(a) {{ item.nameDoctor }}</strong>
                        </button>
                        <br />
                        <div class="card w-100 mt-2 border-main" style="width: 18rem">
                            <div class="card-body card-cxc">
                                <div class="col-md-12 p-0" id="div-cola-cxc">
                                    <table id="tb-cola" class="table table-sm table-bordered p-0">
                                        <thead>
                                            <tr align="left" class="text-white border-white bg-main text-xs">
                                                <th scope="col">FECHA</th>
                                                <th scope="col" :colspan="item.calendar[0].hours.length">HORARIOS</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr class="text-xs" v-for="(date, ind) in item.calendar" :key="ind">
                                                <td class="py-2" width="20%" style="font-weight: bold">
                                                    <span class="rounded-pill p-1 border border-griss" style="background: #fff">{{ date.date }}</span>
                                                </td>
                                                <td class="py-2" v-for="(hora, index2) in date.hours" :key="index2">
                                                    <span class="rounded-pill p-1" style="background: #fff" v-if="hora.state == 0">{{ hora.name
                                      }}</span>
                                                    <div v-if="elementProcess.data.hour == hora.name && item.idDoctor== elementProcess.data.doctor &&
                                        elementProcess.data.date == date.date && tab==elementProcess.data.room  ">
                                                        <div v-if="mostrar == true">
                                                            <span class="rounded-pill p-1" style="background: orange; color: #fff" v-if="elementProcess.data.hour == hora.name &&
                                            elementProcess.data.date == date.date
                                            ">{{ hora.name }}</span>
                                                        </div>
                                                        <div v-else>
                                                            <span @click="mtdReserveHour(
                                        item.idDoctor,
                                        date.date,
                                        hora.name,
                                        index,
                                        ind,
                                        index2
                                      )
                                        " class="rounded-pill p-1" style="background: #39b54a; color: #fff" v-if="hora.state == 1">{{ hora.name }}</span>
                                                        </div>
                                                    </div>
                                                    <div v-else>
                                                        <span @click="mtdReserveHour(
                                        item.idDoctor,
                                        date.date,
                                        hora.name,
                                        index,
                                        ind,
                                        index2
                                      )
                                        " class="rounded-pill p-1" style="background: #39b54a; color: #fff" v-if="hora.state == 1">{{ hora.name }}</span>
                                                        <span class="rounded-pill p-1" style="background: #fcee21; color: #fff" v-if="hora.state == 2">{{ hora.name }}</span>
                                                        <span class="rounded-pill p-1" style="background: #ff0000; color: #fff" v-if="hora.state == 3">{{ hora.name }}</span>
                                                        <span class="rounded-pill p-1" style="background: orange; color: #fff" v-if="hora.state == 4">{{ hora.name }}</span>
                                                    </div>

                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card-footer text-white" style="background: #900052; font-size: 20px">
        <div class="row d-flex">
            <div class="col-4" align="left">
                <span class="btn border border-white text-white" @click="mtdBehindStep"><strong>ATRAS</strong></span>
            </div>
            <div align="right" class="col-8">
                <span class="btn border border-white text-white" style="margin-left: 20px" @click="mtdNextStep"><strong>SIGUIENTE</strong></span>
            </div>
        </div>
    </div>
    <SweetAlert :swal="swal" ref="SweetAlert" />
</div>
</template>

<script>
import {
    mapActions
} from "vuex";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';

export default {
    name: "c-admision-cxc-consultorio",
    components: {
        SweetAlert,
    },
    props: {
        elementProcess: {},
    },
    data() {
        return {
            tab: 0,
            rooms: [],
            swal: null,
            doctors: [],
            verifSelectHour: false,
            oldPosSelectHour: [],
            view: "table",
            mostrar: false,
        };
    },
    mounted() {
        this.mtdGetData();
    },
    methods: {
        ...mapActions(["get", "post"]),
        mtdGetData: function () {
            this.get({
                    url: this.$store.getters.get__url +
                        "/Venta/consultorio/" + this.$store.getters.get__campus,
                    token: this.$store.getters.get__token,
                })
                .then((response) => {
                    this.rooms = response.data;
                    if (this.elementProcess.programado == true) {
                        this.mostrar = true;
                        this.tab = this.rooms[0].id;
                        this.mtdSelectTab(this.elementProcess.data.room);

                    } else {
                        this.mtdSelectTab(this.rooms[0].id);
                    }

                })
                .catch((errors) => {});
        },
        mtdSelectTab: function (id) {
            this.post({
                    url: this.$store.getters.get__url + "/Venta/consultorio/filter",
                    token: this.$store.getters.get__token,
                    params: {
                        subsub: this.elementProcess.id,
                        room: id,
                    },
                })
                .then((response) => {

                    this.doctors = response.data;
                    this.tab = id;
                })
                .catch((errors) => {});
        },
        mtdReserveHour: function (dr, fecha, hora, pos1, pos2, pos3) {
            /** consultar con bd si aun está libre */
            if (this.verifSelectHour == true) {
                this.doctors[this.oldPosSelectHour[0]].calendar[
                    this.oldPosSelectHour[1]
                ].hours[this.oldPosSelectHour[2]].state = 1;
            }
            this.oldPosSelectHour = [pos1, pos2, pos3];
            this.verifSelectHour = true;
            this.doctors[pos1].calendar[pos2].hours[pos3].state = 4;
            this.elementProcess.data = {
                date: fecha,
                hour: hora,
                doctor: dr,
                room: this.tab
            }
            this.elementProcess.programado = true;
            this.mostrar = true;
        },
        /* CAMBIAR DE COMPONENTES */
        mtdBehindStep: function () {
            this.elementProcess.programado = false;
            this.$emit('mtdBehindProcess', this.elementProcess);
        },
        mtdNextStep: function () {
            if (this.elementProcess.reservadespues === true) {
                this.$emit('mtdNextProcess', this.elementProcess);
            } else {
                if (this.elementProcess.data.room != null && this.elementProcess.data.room != undefined) {
                    this.elementProcess.programado = true;
                    this.$emit('mtdNextProcess', this.elementProcess);
                    this.tab = 0;
                    this.rooms = [];
                    this.doctors = [];
                    this.verifSelectHour = false;
                    this.oldPosSelectHour = [];
                    this.view = "table";
                    this.mostrar = false;
                    this.mtdGetData();
                } else {
                    this.$refs.SweetAlert.showWarning("Seleccione Consultorio");
                }
            }
        },
    }

}
</script>
