<template>
<CModal @mtdclosemodal="mtd_close_view" :show.sync="boo_modal" :close-on-backdrop="false" :centered="true" :title="title" :size="'xl'" :btn_cancel="true" :btn_ok="false" :booTitile="true">
    <div class="row employee-profile p-2" style="justify-content: center !important;">
        <div class="row panel-border p-3 mt-1">
            <div class="col-lg-4 col-md-6 col-sm-12">
                <p><strong>Código:</strong> {{ employee.cod_uni_employee }}</p>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12">
                <p><strong>Tipo de Documento:</strong> {{ employee.document_type }}</p>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12">
                <p><strong>Número de Documento:</strong> {{ employee.num_document }}</p>
            </div>
            <div class="col-lg-8 col-md-6 col-sm-12">
                <p><strong>Dirección:</strong> {{ employee.address }}</p>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12">
                <p><strong>Fecha de Nacimiento:</strong> {{ employee.birthday | formatDate("%d/%m/%y") }}</p>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12">
                <p><strong>Teléfono:</strong> {{ employee.phone }}</p>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12">
                <p><strong>Otro Teléfono:</strong> {{ employee.other_phone }}</p>
            </div>
        </div>
        <div class="row panel-border p-3 mt-1">
            <div class="col-lg-4 col-md-6 col-sm-12">
                <p><strong>Dependencia:</strong> {{ employee.dependency }}</p>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12">
                <p><strong>Centro de Costo:</strong> {{ employee.cost_center }}</p>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12">
                <p><strong>Clasificación:</strong> {{ employee.classification }}</p>
            </div>
        </div>
        <div class="row panel-border p-3 mt-1">
            <div class="col-lg-4 col-md-6 col-sm-12">
                <p><strong>Posición:</strong> {{ employee.position }}</p>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12">
                <p><strong>Salario Base:</strong> S/ {{ employee.base_salary }}</p>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12">
                <p><strong>Horas Trabajadas:</strong> {{ employee.hours_worked }}</p>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12">
                <p><strong>Turno:</strong> {{ employee.turn }}</p>
            </div>
        </div>
        <div class="row panel-border p-3 mt-1">
            <div class="col-lg-4 col-md-6 col-sm-12">
                <p v-if="employee.family_allowance == 0"><strong>Asignación Familiar:</strong> NO</p>
                <p v-else><strong>Asignación Familiar:</strong> SI</p>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12">
                <p v-if="employee.family_allowance == 0"><strong>Fondo de Pensión:</strong> ONP</p>
                <p v-else><strong>Fondo de Pensión:</strong> AFP</p>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12">
                <p><strong>Banco:</strong> {{ employee.bank }}</p>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12">
                <p><strong>Número de Cuenta:</strong> {{ employee.account_number }}</p>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12">
                <p><strong>Tipo de Contrato:</strong> {{ employee.type_contract }} </p>
            </div>
        </div>
    </div>
    <template #footer>
        <button @click="mtd_close_view" color="danger" class="btn btn-danger">
            <i class="fa fa-times" />&nbsp;Cerrar
        </button>
    </template>
    <SweetAlert :swal="swal" ref="SweetAlert" />
</CModal>
</template>

<script>
import CModal from "../../shared/modals/CModal.vue";
import CButtonClose from "../../shared/modals/cButtonClose.vue";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import {
    mapActions
} from "vuex";
import moment from "moment";

export default {
    name: "c-maintenance-view-employee",
    components: {
        CModal,
        CButtonClose,
        SweetAlert
    },
    data() {
        return {
            swal: null,
            filter: {
                category: null,
                subcategory: null,
                subsubcategory: null,
            },
            search: "",
            cant: 0,
            data: [],
            dataShow: [],
            //paginacion
            page: 0,
            selectPage: 1,
            stepPagination: [],
            totalPagesToShow: 4,
            action: "",
        };
    },
    props: {
        title: String,
        boo_modal: Boolean,
        employee: [],
        size: String,
        view: String,
        iddoctor: Number
    },
    watch: {
        
    },
    computed: {
        //validar el filtrado de las subcategorias
        cpButtonForm: function () {
            if (
                this.filter.category != null &&
                this.filter.subcategory != null &&
                this.filter.subsubcategory != null
            )

                return false;
            return true;
        },
        btnClasses() {
            return [`btn btn-${this.color || "primary"}`];
        },
        cpData() {
            return this.dataShow;
        },
        limitedStepPagination() {
            const totalPages = this.stepPagination.length;
            const currentPage = this.selectPage;

            let startPage = 1;
            let endPage = totalPages;
            if (totalPages > this.totalPagesToShow) {
                const halfPagesToShow = Math.floor(this.totalPagesToShow / 2);

                if (currentPage > halfPagesToShow) {
                    startPage = currentPage - halfPagesToShow;
                    endPage = currentPage + halfPagesToShow - 1;

                    if (endPage > totalPages) {
                        endPage = totalPages;
                        startPage = totalPages - this.totalPagesToShow + 1;
                    }
                } else {
                    endPage = this.totalPagesToShow;
                }
            }

            return Array.from({
                    length: endPage - startPage + 1,
                },
                (_, i) => startPage + i
            );
        },
    },
    methods: {
        ...mapActions(["get", "post"]),
        
        mtd_close_view() {
            this.$emit("close_modal_view");
        },
    },
    filters: {
        // formato de fecha
        formatDate(date) {
            return date != null ? moment(date).format("DD/MM/YYYY") : "-";
        },
    }
};
</script>