<template>
  <div>
      <div class="px-5 mt-2" v-if="page !== 'devolucion'">      
          <div class="d-flex title-option">
              <div class="btn-home mt-2 ms-auto">
                  <button type="button" class="btn btn-outline-main" @click="returnHome()">
                      <i class="fas fa-home-lg-alt"></i> Inicio 
                  </button>
              </div>
          </div>

          <ul class="nav pb-0 " id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
                <button @click="changePage('hospi')" :class="{'item-button':true, 'nav-link': true, 'active': page === 'hospi' }" id="home-tab" type="button">
                    <strong>HOSPITALIZACION</strong>
                </button>
            </li>
            <li class="nav-item" role="presentation">
                <button @click="changePage('uci')" :class="{ 'item-button':true,'nav-link': true, 'active': page === 'uci' }" id="profile-tab" type="button">
                    <strong>UCI ADULTO</strong>
                </button>
            </li>
            <li class="nav-item" role="presentation">
                <button @click="changePage('emergencia')" :class="{ 'item-button':true,'nav-link': true, 'active': page === 'emergencia' }" id="profile-tab" type="button">
                    <strong>EMERGENCIA</strong>
                </button>
            </li>
        </ul>

          <div class="tab-content mb-3" id="myTabContent">
              <HospitalizacionVue v-if="page == 'hospi'" @openDev="openDev"/>
              <UciVue v-if="page == 'uci'" @openDev="openDev"/>
              <EmergenciaVue v-if="page == 'emergencia'" @openDev="openDev"/>
          </div>
      </div>
      <div  v-if="page == 'devolucion'">
            <cDevolucion
                :patient_id="patient_id"
                :id_medical="medical_act_id"
                 @mtdBack="mtdBack"
            />
     </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import HospitalizacionVue from "@/components/Farmacia/pages/Dev/Hospitalizacion.vue";
import UciVue from "@/components/Farmacia/pages/Dev/Uci.vue";
import EmergenciaVue from "@/components/Farmacia/pages/Dev/Emergencia.vue";
import cDevolucion from "@/components/Farmacia/pages/DevAsist.vue";
export default {

name:"devoluciones",
components: {
    HospitalizacionVue, 
    UciVue,
    EmergenciaVue,
    cDevolucion
},
data(){
  return {
      page: "hospi",
      type: null,
      patient_id: null,
      medical_act_id: null
  }
},
computed: {
    currentType() {
        switch (this.page) {
        case "hospi":
            return 3; 
        case "uci":
            return 4; 
        case "emergencia":
            return 7; 
        default:
            return null;
        }
    },
},
created() {
},
methods:{
  ...mapActions(["get", "post"]),
  changePage: function (page) {
    this.page = page;
  },
  returnHome: function () {
    this.$emit("returnHome");
  },

  openDev: function (patient_id, medical_act_id) {
    this.page = "devolucion";
    this.patient_id = patient_id;
    this.medical_act_id = medical_act_id;
  },

  mtdBack() {
        this.page = "hospi";
    },

}
}
</script>

<style scoped>
.h4-borde{
  border-radius: 50px;
}
#myTabContent{
  border:#900052 1.9px solid;
}

.item-button{
  background: #A9A9A9 !important;
  border-radius: 10px 10px 0px 0px;   
  border: none !important;
  color:white !important;
  width: 155px;   
}
.item-button1{
  background: #A9A9A9 !important;
  border-radius: 10px 10px 0px 0px;   
  border: none !important;
  color:white !important;
  width: 160px !important;    
}

.nav-link:hover{
  background:#900052 !important
}
.active {
background-color: #900052 !important ;

}
.nav-item{
  width: 159.39px;
}
</style>