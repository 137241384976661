<template>
<div>
    <div id="app" class="px-2 mt-3">
        <div class="row mb-1 mx-0" id="boton-arrow" v-if="stateHistoryShow === 0 || showHistoryComplet ==1">
            <div class=" d-flex justify-content-end px-0">
                <!-- <div class="btn-success text-start ">
                    <button type="button" class="btn text-white btn-sm text-start" @click="mtdBackPage">
                        <i class="fas fa-arrow-left"></i> Atr&aacute;s
                    </button>
                </div> -->
                <div v-if="(!origin  && !date_out) && typeDoctor == 1 && (rolname == 'ENFERMERA(O)' || rolname == 'DOCTOR(A)')">
                    <button @click="openDestino" type="button" class="btn text-white btn-sm bg-main text-xs px-5  ">
                        <i class="fas fa-search"></i> DESTINO
                    </button>
                </div>
            </div>

        </div>
        <div class="row" v-if="viewinputs!==0">
            <div class="col-lg-8 col-md-12 ">
                <form class="d-flex flex-row">
                    <div class="row mb-4">
                        <div id="nombre" class="input-group-sm col-md-4 d-inline-flex">
                            <input type="text" class="form-control form-control-sm" id="nombre" placeholder="Nombre y Apellido" autocomplete="off" v-model="filterHistory.name" :disabled="stateDisabled" @keyup.enter="mtdGetDataFilter" />
                        </div>
                        <div id="edad" class="input-group-sm col-md-1 d-inline-flex">
                            <input type="text" class="form-control form-control-sm" id="edad" placeholder="Edad" autocomplete="off" v-model="filterHistory.year" :disabled="stateDisabled" @keyup.enter="mtdGetDataFilter" />
                        </div>
                        <div id="fecha" class="input-group-sm col-md-2 d-inline-flex">
                            <input type="date" class="form-control form-control-sm" id="fecha_inicio" placeholder="Fecha de inicio" autocomplete="off" v-model="filterHistory.startDate" :disabled="stateDisabled" @keyup.enter="mtdGetDataFilter" />
                        </div>
                        <div id="fecha" class="input-group-sm col-md-2 d-inline-flex">
                            <input type="date" class="form-control form-control-sm" id="fecha_fin" placeholder="Fecha Fin" autocomplete="off" v-model="filterHistory.endDate" :disabled="stateDisabled" @keyup.enter="mtdGetDataFilter" />
                        </div>
                        <div id="dias" class="input-group-sm col-md-2 d-inline-flex">
                            <input type="text" class="form-control form-control-sm" id="dias_transcurridos" placeholder="Dias transcurridos" autocomplete="off" v-model="filterHistory.dayPassed" :disabled="stateDisabled" @keyup.enter="mtdGetDataFilter" />
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="row mx-0 text-white h-100  " v-if="viewinputs===0">
            <div class="mx-0 col-sm-12 col-md-12 col-xl-6 h-100 order-lg-1  rounded-3 px-0 bg-blue mb-2">
                <div class="row mx-0">
                    <div class="h-100 py-1 mx-0 col-sm-12 col-md-9 col-xl-9 px-0 text-x border rounded-start d-inline-flex">
                        <span class=" align-middle col-sm-4 h-100 px-2 text-start">Nombre y Apellido:</span>
                        <span class=" align-middle col-sm-8 h-100 px-0 text-start ">{{ datapatients.patient_name }}</span>
                    </div>
                    <div class="h-100 mx-0 text-x   px-0 py-1 col-sm-12 col-md-3 col-xl-3 border rounded-end d-inline-flex">
                        <span class=" align-middle col-sm-4 h-100 px-2 text-start">Edad:</span>
                        <span class=" align-middle col-sm-7 h-100 px-0 text-sm-start text-xl-center  ">{{datapatients.patient_year }}</span>
                    </div>
                </div>
            </div>
            <div class="col-xl-5 col-md-12 order-md-1 order-lg-2">
                <div class="row" v-if="viewMedico===0">
                    <!-- <div class="col-md-10 d-inline-flex w-100">
                        <div class="input-group input-group-sm w-100">
                            <span class="input-group-text text-white bg-main" id="inputGroup-sizing-sm">Médico</span>
                            <input type="text" class="form-control text-xs" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-sm" v-model="patient.doctor_name" disabled />
                        </div>
                    </div> -->
                </div>
            </div>
            <div class="col-xl-1 col-md-12 order-md-1 order-lg-2 py-2 py-xl-0 px-0">
                <div class="row px-0 mx-1" v-if="viewMedico===0">
                    <div v-if="origin" class="col-md-10 d-inline-flex w-100 px-0">
                        <button @click="mtdaddhistory" type="button" class="btn text-white btn-sm bg-main text-xs w-100">
                            Guardar Historial
                        </button>
                    </div>
                    <div v-if="!origin  && !date_out && !date_out_doctor && rolname == 'DOCTOR(A)' && typeDoctor == 1" class="col-md-10 d-inline-flex w-100 px-0">
                        <button @click="mtdaddall" type="button" class="btn text-white btn-sm bg-main text-xs w-100">
                            Guardar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="px-2 mt-2 " v-if="stateHistoryShow === 0 || showHistoryComplet ==1">

        <ul class="nav pb-0 mt-2 d-none d-lg-flex" id="myTabHijoHistorial" role="tablist">
            <li class="nav-itemUci" role="presentation">
                <button @click="changeSubPage('hcsop')" :class="{'item-button':true, 'nav-link': true, 'active': subpage === 'hcsop' }" id="profile-tab" type="button">
                    <strong class="text-xxs">HC QUIRURGICA</strong>
                </button>
            </li>
            <li class="nav-itemUci" role="presentation">
                <button @click="changeSubPage('emedica')" :class="{ 'item-button':true,'nav-link': true, 'active': subpage === 'emedica' }" id="profile-tab" type="button" :disabled=origin>
                    <strong class="text-xxs">EV. MEDICA SOP</strong>
                </button>
            </li>
            <li class="nav-itemUci" role="presentation">
                <button @click="changeSubPage('rp')" :class="{ 'item-button':true,'nav-link': true, 'active': subpage === 'rp' }" id="profile-tab" type="button" :disabled=origin>
                    <strong class="text-xxs">R.P DE SOP</strong>
                </button>
            </li>
            <li class="nav-itemUci" role="presentation">
                <button @click="changeSubPage('ordensop')" :class="{ 'item-button':true,'nav-link': true, 'active': subpage=='ordensop' }" id="profile-tab" type="button" :disabled=origin>
                    <strong class="text-xxs">ORDEN DE SOP</strong>
                </button>
            </li>
            <li class="nav-itemUci" role="presentation">
                <button @click="changeSubPage('preanestecia')" :class="{ 'item-button':true,'nav-link': true, 'active': subpage === 'preanestecia' }" id="profile-tab" type="button" :disabled=origin>
                    <strong class="text-xxs">PRE ANESTECA</strong>
                </button>
            </li>
            <li class="nav-itemUci" role="presentation">
                <button @click="changeSubPage('rxqx')" :class="{ 'item-button':true,'nav-link': true, 'active': subpage === 'rxqx' }" id="profile-tab" type="button" :disabled=origin>
                    <strong class="text-xxs">RX QX</strong>
                </button>
            </li>
            <li class="nav-itemUci" role="presentation">
                <button @click="changeSubPage('rxneumologico')" :class="{ 'item-button':true,'nav-link': true, 'active': subpage === 'rxneumologico' }" id="profile-tab" type="button" :disabled=origin>
                    <strong class="text-xxs">RX NEUMOLOGICO</strong>
                </button>
            </li>
            <li class="nav-itemUci" role="presentation">
                <button @click="changeSubPage('ci')" :class="{ 'item-button':true,'nav-link': true, 'active': subpage === 'ci' }" id="profile-tab" type="button" :disabled=origin>
                    <strong class="text-xxs">C.I.</strong>
                </button>
            </li>
            <li class="nav-itemUci" role="presentation">
                <button @click="changeSubPage('hojaanestecia')" :class="{ 'item-button':true,'nav-link': true, 'active': subpage === 'hojaanestecia' }" id="profile-tab" type="button" :disabled=origin>
                    <strong class="text-xxs">HOJA DE ANESTECIA</strong>
                </button>
            </li>
            <li class="nav-itemUci" role="presentation">
                <button @click="changeSubPage('postanestecia')" :class="{ 'item-button':true,'nav-link': true, 'active': subpage === 'postanestecia' }" id="profile-tab" type="button" :disabled=origin>
                    <strong class="text-xxs">POST ANESTECIA</strong>
                </button>
            </li>
            <li class="nav-itemUci" role="presentation">
                <button @click="changeSubPage('informeoperatorio')" :class="{ 'item-button':true,'nav-link': true, 'active': subpage === 'informeoperatorio' }" id="profile-tab" type="button" :disabled=origin>
                    <strong class="text-xxs">INFORME OPERATORIO</strong>
                </button>
            </li>
            <li class="nav-itemUci" role="presentation">
                <button @click="changeSubPage('listaverificacion')" :class="{ 'item-button':true,'nav-link': true, 'active': subpage === 'listaverificacion' }" id="profile-tab" type="button" :disabled=origin>
                    <strong class="text-xxs">LISTA DE VERIF.</strong>
                </button>
            </li>
            <li class="nav-itemUci" role="presentation">
                <button @click="changeSubPage('kardex')" :class="{ 'item-button':true,'nav-link': true, 'active': subpage === 'kardex' }" id="profile-tab" type="button" :disabled=origin>
                    <strong class="text-xxs">KARDEX DE SOP</strong>
                </button>
            </li>
            <li class="nav-itemUci" role="presentation">
                <button @click="changeSubPage('bhe')" :class="{ 'item-button':true,'nav-link': true, 'active': subpage === 'bhe' }" id="profile-tab" type="button" :disabled=origin>
                    <strong class="text-xxs">BHE</strong>
                </button>
            </li>
            <li class="nav-itemUci" role="presentation">
                <button @click="changeSubPage('mhemodinamico')" :class="{ 'item-button':true, 'nav-link': true, 'active': subpage === 'mhemodinamico' }" id="profile-tab" type="button" :disabled=origin>
                    <strong class="text-xxs">MONITOREO SOP</strong>
                </button>
            </li>
        </ul>

        <div class="tab-content mb-3 d-none d-lg-block" id="myTabContentHijo">
            <HcsopDesktop :typeDoctor="typeDoctor" :metodoadd="metodoadd" @mtdaddexit="mtdaddexit" @changePage="changePage" :viewMedico="viewMedico" :Clinicahistory="Clinicahistory" :datapatients="datapatients" :showHistory="showHistory" v-if="subpage == 'hcsop'" />
            <Hc :viewMedico="viewMedico" :Clinicahistory="Clinicahistory" :datapatients="datapatients" v-if="subpage=='hcsop1'" />
            <Emedica v-if="subpage=='emedica'" :medical_act_id="Clinicahistory.medical_act_id" :addEv="addEv" @mtdaddevexit="mtdaddevexit" :date_out="date_out" :rolname="rolname" :date_out_doctor="date_out_doctor" :typeDoctor="typeDoctor"/>
            <Rp v-if="subpage=='rp'" :medical_act_id="Clinicahistory.medical_act_id" :addRp="addRp" @mtdaddrpexit="mtdaddrpexit" :date_out="date_out" :rolname="rolname"  :date_out_doctor="date_out_doctor" />
            <Kardex v-if="subpage=='kardex'" />
            <Bhe v-if="subpage=='bhe'" />
            <Mhemodinamico v-if="subpage=='mhemodinamico'" />
            <Ordensop v-if="subpage=='ordensop'" />
            <Preanestecia v-if="subpage=='preanestecia'" />
            <Rxqx v-if="subpage=='rxqx'" />
            <Rxneumologico v-if="subpage=='rxneumologico'" />
            <Ci v-if="subpage=='ci'" />
            <Hojaanestecia v-if="subpage=='hojaanestecia'" />
            <Postanestecia v-if="subpage=='postanestecia'" />
            <Informeoperatorio v-if="subpage=='informeoperatorio'" />
            <Listaverificacion v-if="subpage=='listaverificacion'" />
        </div>

        <!-- Tablet -->
        <div id="app" class="root d-md-block d-lg-none">
            <div class="wrapper-list">
                <dropdown title="H. Clínica" component="HistoriaSop" :datapatients="datapatients" :viewMedico="viewMedico" :Clinicahistory="Clinicahistory"></dropdown>
                <dropdown title="Evolución Médica" component="Emedica"></dropdown>
                <dropdown title="Rp" component="Rp"></dropdown>
                <dropdown title="Orden de Sop" component="Ordensop"></dropdown>
                <dropdown title="Pre anestecia" component="preanestecia"></dropdown>
                <dropdown title="Rx qx" component="Rxqx"></dropdown>
                <dropdown title="Rx neumologico" component="Rxneumologico"></dropdown>
                <dropdown title="C.I." component="Ci"></dropdown>
                <dropdown title="Hoja de anestecia" component="Hojaanestecia"></dropdown>
                <dropdown title="Post anestecia" component="Postanestecia"></dropdown>
                <dropdown title="Informe operatorio" component="Informeoperatorio"></dropdown>
                <dropdown title="Lista de verificacion" component="Listaverificacion"></dropdown>
                <dropdown title="Kardex" component="Kardex"></dropdown>
                <dropdown title="Bhe" component="Bhe"></dropdown>
                <dropdown title="Monitoreo Hemodinamico" component="Mhemodinamico"></dropdown>
            </div>
        </div>
        <!--  -->
    </div>
    <div v-if="showHistoryComplet !=1">
        <Tablehistory :resultFilter="resultFilter" @historyPlanPatient="getHistoryPatient" />
    </div>
</div>
</template>

<script>
import {
    mapActions
} from 'vuex'
import Hc from '@/components/Asistencial/Sop/Historia/Hcsop.vue';
import HcsopDesktop from '@/components/Asistencial/Sop/Historia/HcsopDesktop.vue';
import Kardex from '@/components/Asistencial/Sop/Historia/Kardex.vue';
import Emedica from '@/components/Asistencial/Sop/Historia/Emedica.vue';
import Rp from '@/components/Asistencial/Sop/Historia/Rp.vue';
import Bhe from '@/components/Asistencial/Sop/Historia/Bhe.vue';
import Mhemodinamico from '@/components/Asistencial/Sop/Historia/Mhemodinamico.vue';
import Ordensop from '@/components/Asistencial/Sop/Historia/Ordendesop.vue';
import Preanestecia from '@/components/Asistencial/Sop/Historia/Preanestecia.vue';
import Rxqx from '@/components/Asistencial/Sop/Historia/Rxqx.vue';
import Rxneumologico from '@/components/Asistencial/Sop/Historia/Rxneumologico.vue';
import Ci from '@/components/Asistencial/Sop/Historia/Ci.vue';
import Hojaanestecia from '@/components/Asistencial/Sop/Historia/Hojaanestecia.vue';
import Postanestecia from '@/components/Asistencial/Sop/Historia/Postanestecia.vue';
import Informeoperatorio from '@/components/Asistencial/Sop/Historia/Informeoperatorio.vue';
import Listaverificacion from '@/components/Asistencial/Sop/Historia/Listaverificacion.vue';
import Dropdown from '@/components/Asistencial/Sop/Dropdown.vue';
import Tablehistory from "@/components/Asistencial/Sop/TableHistory.vue";
export default {
    name: 'c-asistencial-sop-historia',
    components: {
        Hc,
        HcsopDesktop,
        Kardex,
        Emedica,
        Rp,
        Bhe,
        Mhemodinamico,
        Ordensop,
        Preanestecia,
        Rxqx,
        Rxneumologico,
        Ci,
        Hojaanestecia,
        Postanestecia,
        Informeoperatorio,
        Listaverificacion,
        Dropdown,
        Tablehistory,
    },
    data() {
        return {
            subpage: 'hcsop',
            resultFilter: [],
            showHistoryComplet: 0,
            filterHistory: {
                dayPassed: null,
                startDate: null,
                endDate: null,
                year: null,
                name: null,
            },
            stateDisabled: false,
            patient: [],
            datapatients: {
                patient_id: '',
                model_id: '',
                patient_name: '',
                patient_year: ''
            },
            viewinputs: null,
            viewMedico: null,
            Clinicahistory: {},
            origin: true,
            showHistory: false,
            metodoadd: null,
            addEv: null,
            addRp: null,
            addSheet: null,
            modal: {
                title: "",
                modal_form: false,
            },
            cModalBed: {
                title: "",
                modal_form: false,
            },
            bed: {
                campu_id: null,
                type: null,
            },
            type_history: null,
            date_out: false,
            date_out_doctor:false,
            count:0
        };
    },
    props: {
        stateHistoryShow: {
            type: Number,
        },
        patients: {
            type: Object,
            default: () => ({})
        },
        viewInput: {
            type: Number,
        },
        rolname: String,
        typeDoctor: {
            type: Number,
            default: 1,
        }
    },
    created() {
        this.getFirstDayOfMonth();

    },
    methods: {
        ...mapActions(['get', 'post']),
        mtdGetStyle: function (option) {
            if (option == this.subpage) return 'background: #900052';
            return '';
        },
        mtdGetStyleSpan: function (option) {
            if (option == this.subpage) return 'color:white';
            return '';
        },
        changeSubPage: function (subpage) {
            this.subpage = subpage;
        },
        mtdaddhistory: function () {
            this.metodoadd = 1
        },
        getHistoryPatient: function (datos, origin) {
            this.date_out_doctor = datos.patient.status_medical == 1 ? true : false
            this.date_out = datos.patient.date_out ? true : false
            this.origin = (origin == 'cola');
            this.showHistory = (origin == 'table')
            const {
                patient
            } = datos;
            this.changeSubPage('Hchospitalizacion');
            this.showHistoryComplet = 1;
            this.patient = patient;
            this.mtdClear();

        },
        mtdGetDataFilter: function () {
            //Cargar data de acuerdo a lo buscado
            this.post({
                url: this.$store.getters.get__url + "/Sop/filter",
                token: this.$store.getters.get__token,
                params: this.filterHistory,
            }).then((response) => {
                if (response.statusCode === 200) {
                    this.resultFilter = response.data;
                }
            });
        },
        mtdaddexit: function (item, payload) {
            this.metodoadd = null
            if (item == 1) {
                this.origin = false
                this.Clinicahistory = payload
                this.showHistory = true
                this.$emit('validation', {
                    patient: payload
                })
            }
        },
        mtdaddrp: function () {
            /* this.post({
                    url: this.$store.getters.get__url + "/Uciadulto/storeRp",
                    token: this.$store.getters.get__token,
                    params: this.$miVariableGlobalRpUci,
                })
                .then((response) => { //   
                    this.addRp = 1
                    this.$refs.SweetAlert.showSuccess(response.message);
                })
                .catch((errors) => {
                    console.log(errors);
                }); */
        },
        mtdaddsheet: function () {
            /* this.post({
                    url: this.$store.getters.get__url + "/Uciadulto/storeSheet",
                    token: this.$store.getters.get__token,
                    params: this.$miVariableGlobalSheetUci,
                })
                .then((response) => {
                    this.addSheet = 1
                    this.$refs.SweetAlert.showSuccess(response.message);
                })
                .catch((errors) => {
                    console.log(errors);
                }); */
        },
        mtdaddrpexit: function () {
            this.addRp = null
        },
        mtdaddevexit: function () {
            this.addEv = null
        },
        mtdaddsheetexit: function () {
            this.addSheet = null
        },
        getFirstDayOfMonth: function () {
            const currentDate = new Date();
            const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
            this.filterHistory.startDate = firstDayOfMonth.toISOString().split('T')[0];
        },
        mtdClear: function () {
            this.datapatients = {
                patient_id: this.patient.patient_id,
                model_id: this.patient.model_id,
                patient_year: this.patient.patient_year,
                patient_name: this.patient.patient_name,
            }
            this.viewinputs = 0
            this.viewMedico = 0
            this.Clinicahistory = this.patient
        },
        mtdBackPage: function () {

            if (this.stateHistoryShow === 1) {
                this.getFirstDayOfMonth();
                this.showHistoryComplet = 0;
                this.stateDisabled = false;
                this.mtdGetDataFilter();
                this.viewinputs = 1;
            } else {
                this.$emit('changePage', 'cola');
            }

        },
        changePage: function () {
            this.$emit('changePage', 'cola')
        },
        mtdaddhistory: function () {
            this.metodoadd = 1
        },
        openDestino: function () {
            if (this.patient.status_medical == 1 && this.rolname == 'DOCTOR(A)') {
                this.$refs.SweetAlert.showSuccess('El Paciente ya a sido dado de alta');
                this.mtdclosemodal()
            } else { 
                this.modal = {
                    title: "DESTINO",
                    modal_form: true,
                }
                this.type_history = this.Clinicahistory.type
            }
            
        },
        mtdclosemodal: function () {
            this.modal = {
                title: "",
                modal_form: false,
            }
        },
        //registro de destino
        mtdstoredata: function (payload,destination) {
            let medical_id = this.Clinicahistory.medical_act_id
            this.count = 0
            if (payload == 0) {
                if (this.Clinicahistory.status_medical != 1 && this.rolname == 'ENFERMERA(O)') {
                    this.$refs.SweetAlert.showInfo('El doctor no ha dado de alta al paciente');
                    this.mtdclosemodal()
                } else if (this.Clinicahistory.status_medical == 1 && this.rolname == 'ENFERMERA(O)') {
                    this.post({
                            url: this.$store.getters.get__url + "/Emergency/countMedicine",
                            token: this.$store.getters.get__token,
                            params: {
                                id: medical_id
                            },
                        })
                        .then((response) => {
                            this.count = response.data
                            if (this.count == 0) {
                                this.post({
                                        url: this.$store.getters.get__url + "/admision/destinationNurse",
                                        token: this.$store.getters.get__token,
                                        params: {
                                            id: medical_id
                                        },
                                    })
                                    .then((response) => {
                                        this.mtdclosemodal()
                                        this.$refs.SweetAlert.showSuccess(response.message);
                                        this.Clinicahistory = {};
                                        this.$emit('changePage', 'cola')
                                        this.$emit('vaciarpatient')
                                    })
                                    .catch((errors) => {
                                        console.log(errors);
                                    });
                            } else {
                                this.$refs.SweetAlert.showInfo('Devolver Todos los Medicamentos');
                                this.mtdclosemodal()
                            }
                        })
                        .catch((errors) => {
                            console.log(errors);
                        });
                }else{
                    this.post({
                        url: this.$store.getters.get__url + "/admision/destination",
                        token: this.$store.getters.get__token,
                        params: {
                            id: medical_id,
                            destination: destination,
                        },
                    })
                    .then((response) => {
                        this.mtdclosemodal()
                        this.$refs.SweetAlert.showSuccess(response.message);
                        this.Clinicahistory = {};
                        this.$emit('changePage', 'cola')
                        this.$emit('vaciarpatient')
                    })
                    .catch((errors) => {
                        console.log(errors);
                    });
                }
              
            } else if (payload == 1) {
                this.cModalBed = {
                    title: "Lista de Camas / Servicios",
                    modal_form: true,
                }
                this.bed = {
                    campu_id: this.$store.getters.get__campus,
                    type: 3,
                }
                this.mtdclosemodal()
            } else if (payload == 2) {
                this.cModalBed = {
                    title: "Lista de Camas / Servicios",
                    modal_form: true,
                }
                this.bed = {
                    campu_id: this.$store.getters.get__campus,
                    type: 4,
                }
                this.mtdclosemodal()
            }else if (payload == 3) {
                this.cModalBed = {
                    title: "Lista de Camas / Servicios",
                    modal_form: true,
                }
                this.bed = {
                    campu_id: this.$store.getters.get__campus,
                    type: 7,
                }
                this.mtdclosemodal()
            }

        },
        mtdclosemodalBed: function () {
            this.cModalBed = {
                title: "",
                modal_form: false,
            }

        },
        mtdaddbed: function (payload, type, subsubcategories) {
            let data = {
                type: type,
                bed_id: payload.id,
                patient_id: this.Clinicahistory.patient_id,
                id: this.Clinicahistory.medical_act_id,
                campu_id : this.$store.getters.get__campus,
                subsubcategorie_id :subsubcategories.id
            }
            this.post({
                    url: this.$store.getters.get__url + "/admision/reservation",
                    token: this.$store.getters.get__token,
                    params: data
                })
                .then((response) => {
                    this.mtdclosemodalBed()
                    this.$refs.SweetAlert.showSuccess(response.message);
                    this.Clinicahistory = {};
                    this.$emit('changePage', 'cola')
                    this.$emit('vaciarpatient')
                })
                .catch((errors) => {
                    console.log(errors);
                });
        }
    },
}
</script>
