<template>
<CModal @mtdclosemodal="mtdclosemodal" :show="boo_modal" :close-on-backdrop="false" :centered="true" :title="title" :size="'xl'" :btn_cancel="true" :btn_ok="true" :booTitile="true">
    <div class="row d-flex mx-0 px-2">

        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
            <div class="input-group input-group-sm mb-3">
                <span class="input-group-text col-md-4">PACIENTE</span>
                <input type="text" aria-label="date" class="form-control form-control-sm" required disabled v-model="data_detail.patient" />
            </div>
        </div>
        <div class="col-lg-2 col-md-4 col-sm-12 col-xs-12">
            <div class="input-group input-group-sm mb-3">
                <span class="input-group-text col-md-4 col-sm-4">DNI</span>
                <input type="text" aria-label="date" class="form-control form-control-sm" required disabled v-model="data_detail.document" />
            </div>
        </div>
        <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12">
            <div class="input-group input-group-sm mb-3">
                <span class="input-group-text col-xl-4 col-lg-6 col-md-4">EDAD</span>
                <input type="text" aria-label="date" class="form-control form-control-sm" v-model="data_detail.yearOld" />
            </div>
        </div>
        <div class="col-lg-2 col-md-4 col-sm-6 col-xs-12">
            <div class="input-group input-group-sm mb-3">
                <span class="input-group-text col-lg-4" id="basic-addon1">Sexo</span>
                <select selected class="form-control form-control-sm pb-0 pt-0" v-model="data_detail.sex_type">
                    <option selected disabled :value=null>Seleccione..</option>
                    <option value="1">Femenino</option>
                    <option value="2">Masculino</option>
                </select>
            </div>
        </div>
    </div>
    <div class="row d-flex mx-0 px-2">
        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
            <div class="input-group input-group-sm mb-3">
                <span class="input-group-text col-md-4">ESPECIALIDAD</span>
                <input type="text" aria-label="date" class="form-control form-control-sm" required disabled v-model="data_detail.specialty" />
            </div>
        </div>
        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
            <div class="input-group input-group-sm mb-3">
                <span class="input-group-text col-md-4 col-lg-3">DOCTOR</span>
                <v-select class="flex-grow-1" :options="dataDoctors" v-model="data_detail.doctor_name" :reduce="(cie) => cie" label="name" placeholder="- Buscar Doctor -" required :clearable="false" @search="mtdgetDoctors" @input="mtdaddDoctor">
                    <template slot="no-options">
                        No hay doctor para la busqueda
                    </template>
                </v-select>
            </div>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="input-group input-group-sm mb-3">
                <span class="input-group-text">DIAGNOSTICO</span>
                <v-select class="form-control form-control-sm col-md-12" :options="dataCies" v-model="data_detail.cie" :reduce="(cie) => cie" label="name" placeholder="- Buscar Cie -" required :clearable="false" @search="mtdgetCies" @input="mtdaddDiagnosticoUnit">
                    <template slot="no-options">
                        No hay diagnosticos para la busqueda.
                    </template>
                </v-select>
                <input type="text" disabled aria-label="date" class="form-control form-control-sm" v-model="data_detail.cie_code" />
            </div>
        </div>

    </div>
    <div class="row mx-0 px-2">
        <label>Signos Vitales</label>
        <div class="col-md-12 mx-2">
            <div class="row d-flex px-3 mt-2 panel-border">
                <div class="col-lg-2 col-md-4 col-sm-6 col-xs-6 my-1 align-left" v-for="(item2, index) in data_detail.vital_signs" :key="index">
                    <div id="div-estado" class="form-group row">
                        <label :for="'input2' + index" class="col-sm-4 color-main fw-bold">{{ item2.label }}</label>
                        <div class="col-xl-7 col-sm-12">
                            <input type="text" v-model="item2.value" class="form-control form-control-input form-control-sm" :id="'input2' + index">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <template #footer>
        <button @click="mtdclosemodal" color="danger" class="btn btn-danger btn-sm">
            <i class="fa fa-times" />&nbsp;Cerrar
        </button>
        &nbsp;
        <button @click="mtdEditData" color="danger" class="btn btn-success btn-sm">
            <i class="fas fa-save" />&nbsp;Guardar
        </button>
        &nbsp;
    </template>
    <SweetAlert :swal="swal" ref="SweetAlert" />
</CModal>
</template>

<script>
import CModal from "@/components/shared/modals/CModal.vue";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import {
    mapActions
} from "vuex";

export default {
    name: "c-asistencial-hospitalizacion-modals-cModaldestino",
    components: {
        CModal,
        SweetAlert
    },
    data() {
        return {
            data_detail: {
                id: "",
                patient: "",
                yearOld: "",
                cie_id: "",
                cie_code: "",
                cie: "",
                specialty: "",
                destination: "",
                doctor_name: "",
                doctor_id: "",
                sex_type: "",
            },
            vital_signs: [{
                    id: 1,
                    label: 'PESO',
                    value: ''
                }, {
                    id: 2,
                    label: 'TALLA',
                    value: ''
                },
                {
                    id: 2,
                    label: 'PA',
                    value: ''
                },

                {
                    id: 4,
                    label: 'SAT O2',
                    value: ''
                },
                {
                    id: 5,
                    label: 'FC',
                    value: ''
                },
                {
                    id: 6,
                    label: 'T(°C)',
                    value: ''
                }
            ],
            swal: null,
            destino: ['UCI', 'Hospitalizacion', 'Fallecimiento', 'Alta'],
            pos: -1,
            dataCies: [],
            dataDoctors: [],

        };
    },
    props: {
        title: String,
        boo_modal: Boolean,
        payload: Object,
    },
    watch: {
        payload: {
            handler(newVal) {
                if (newVal) {
                    this.data_detail = {
                        ...newVal
                    };
                    if (!newVal.vital_signs) {
                        this.data_detail.vital_signs = this.vital_signs;
                    } else {
                        this.data_detail.vital_signs = JSON.parse(newVal.vital_signs);
                    }
                }
            },
            immediate: true,
        },
    },
    methods: {
        ...mapActions(["get", "post"]),
        mtdclosemodal(opt) {
            this.pos = -1;
            this.vital_signs = [{
                        id: 1,
                        label: 'PESO',
                        value: ''
                    }, {
                        id: 2,
                        label: 'TALLA',
                        value: ''
                    },
                    {
                        id: 2,
                        label: 'PA',
                        value: ''
                    },

                    {
                        id: 4,
                        label: 'SAT O2',
                        value: ''
                    },
                    {
                        id: 5,
                        label: 'FC',
                        value: ''
                    },
                    {
                        id: 6,
                        label: 'T(°C)',
                        value: ''
                    }
                ],
                this.dataCies = [];
            this.dataDoctors = [];
            this.$emit('mtdclosemodal', opt);
        },
        mtdgetCies: function (search, loading) {
            if (search.length > 4) {
                this.dataCies = [];
                loading(true);
                this.get({
                        url: this.$store.getters.get__url + "/Consultation/cies/" + search,
                        token: this.$store.getters.get__token,
                    })
                    .then((response) => {
                        if (response.statusCode === 200) {
                            this.dataCies = response.data;

                        }
                        loading(false);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        },
        mtdgetDoctors: function (search, loading) {
            if (search.length > 4) {
                this.dataDoctors = [];
                loading(true);
                this.get({
                        url: this.$store.getters.get__url + "/Doctor/search/" + search,
                        token: this.$store.getters.get__token,
                    })
                    .then((response) => {
                        if (response.statusCode === 200) {
                            this.dataDoctors = response.data;

                        }
                        loading(false);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        },
        mtdaddDiagnosticoUnit: function (diagnostico) {
            this.data_detail.name = diagnostico.name;
            this.data_detail.cie_id = diagnostico.id;
            this.data_detail.cie_code = diagnostico.code;
        },
        activarTipo(index, item) {
            this.pos = index;
            this.data_detail.destination = item;
        },
        mtdaddDoctor(doctor) {
            this.data_detail.doctor_id = doctor.id;
            alert(this.data_detail.doctor_id)
        },
        mtdEditData() {
            //console.log(this.data_detail);
            //guardar los nuevos registros 
            this.post({
                    url: this.$store.getters.get__url + "/control/consultingBook/update",
                    token: this.$store.getters.get__token,
                    params: this.data_detail,
                })
                .then((response) => {
                    if (response.statusCode === 200) {
                        this.$refs.SweetAlert.showSuccess(response.message);
                        this.mtdclosemodal(0);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    },
};
</script>
