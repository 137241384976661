<template>
  <CModal
    @mtdclosemodal="mtdClose"
    :show.sync="boo_modal"
    :close-on-backdrop="false"
    :centered="true"
    :title="title"
    :size="'lg'"
    :btn_cancel="true"
    :btn_ok="true"
    :booTitile="true"
    :scrollable="true"
  >
    <div class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div class="col-md-12 table-responsive" id="div-tabla-anular">
              <table id="tb-anular" class="table table-bordered">
                  <caption class="mx-0 px-0"></caption>
                  <thead class="bg-main" style="font-size: 13px">
                      <tr class="text-white text-center align-middle">
                        <th scope="col">ALMACÉN</th>
                        <th scope="col">LOTE</th>
                        <th scope="col">CANTIDAD</th>
                      </tr>
                  </thead>
                  <tbody class="border-main" style="font-size: 12px">
                    <template v-for="(items) in cpData">
                        <tr v-for="(item, index) in items" :key="item.id" class="text-center align-middle">
                            <td v-if="index === 0" :rowspan="items.length">{{ item.almacen_name }}</td>
                            <td>{{ item.lote }}</td>
                            <td>{{ item.new_quantity }}</td>
                        </tr>
                    </template>
                    </tbody>
              </table>
          </div>

      </div>
    </div>
  </CModal>
</template>

<script>
import CModal from "@/components/shared/modals/CModal.vue";
import { mapActions } from "vuex";
export default {
  name: "c-Pharmacy-cModalViewDetail",
  components: {
    CModal,
  },
  data() {
    return {
      tabla: [],
    };
  },
  created() {},
  computed: {
    cpData() {
      let show = [];
      Object.values(this.payload).forEach((element) => {
        show.push(element);
    });
      this.tabla = show;
      return show;
    },
  },
  props: {
    title: String,
    boo_modal: Boolean,
    payload: [],
  },
  methods: {
    ...mapActions(["get", "post"]),
    mtdClose() {
      this.$emit("closeModalMedicAlma");
    },
  },
};
</script>
<style scoped>
.align-middle{
  vertical-align: middle;
}
</style>
