<template>
<div>
    <div class="w-100 h-100">
        <Nav :rolname="rolname" />
    </div>
    <div class="content">
        <Home v-if="page=='home'" @mtdChangePage="mtdChangePage" @mtdBackCampus="mtdBackCampus" />
        <ControlDiarioView v-if="page=='control'" @mtdChangePage="mtdChangePage" />
        <TramasView v-if="page=='tramas'" @mtdChangePage="mtdChangePage" />
    </div>
    <div class="footer">
        <Footer @mtdChangePage="mtdChangePage" :pageView="page" />
    </div>
</div>
</template>

    
<script>
import Nav from '../../components/Asistencial/Nav.vue';
import ControlDiarioView from '@/components/Asistencial/Control.vue';
import TramasView from '../../components/JefaEnfermeria/Pages/Tramas.vue';
import Home from '../../components/JefaEnfermeria/Home.vue'
import Footer from '../../components/JefaEnfermeria/Footer.vue'

export default {
    name: "v-recursosHumanos",
    components: {
        Nav,
        Home,
        ControlDiarioView,
        Footer,
        TramasView
    },
    data() {
        return {
            page: "home",
            rol_name: null,
        };
    },
    props: {
        rolname: String,
        dataRoles: Array,
    },
    methods: {
        changePage: function (page) {
            this.page = page;
        },
        returnHome: function () {
            this.page = "home";
        },
        mtdBackCampus: function () {
            this.$emit("mtdBackCampus");
        },
        mtdChangePage: function (page) {
            this.page = page;
        },
    },
}
</script>
