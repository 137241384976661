<template>
    <CModal @mtdclosemodal="mtdClose" :show="boo_modal" :close-on-backdrop="false" :centered="false" :title="title" :size="'md'" :btn_cancel="true" :btn_ok="true" :booTitile="true">
        <div class="px-3">
            <div class="row mx-0 px-0">
                <div class="mb-2 col-xl-12 px-1">
                    <label for="dataUno" class="form-label">Almacén Superior</label>
                    <v-select
                        v-model="data_detail.almacen_id"
                        class="form-control"
                        :reduce="(name) => name.id"
                        :options="dataUno"
                        multiple
                        placeholder="Seleccione varias opciones..."
                        label="name"
                        :close-on-select="false"
                    />
                </div>
                <div class="mb-2 col-xl-12 px-1">
                    <label for="name" class="form-label">Nombre</label>
                    <input type="text" class="form-control form-control-sm" v-model="data_detail.name" placeholder=" Nombre">
                </div>
                <div class="mb-2 col-xl-12 px-1">
                    <label for="type" class="form-label">Tipo</label>
                    <div class="d-flex justify-content-center">
                        <div class="form-check me-3">
                            <input 
                                type="radio" 
                                id="almacen" 
                                value="1" 
                                v-model="data_detail.type" 
                                class="form-check-input" 
                                name="type"
                            />
                            <label for="almacen" class="form-check-label">Almacen</label>
                        </div>
                        <div class="form-check me-3">
                            <input 
                                type="radio" 
                                id="anexo" 
                                value="2" 
                                v-model="data_detail.type" 
                                class="form-check-input" 
                                name="type"
                            />
                            <label for="anexo" class="form-check-label">Anexo</label>
                        </div>
                        <div class="form-check">
                            <input 
                                type="radio" 
                                id="quitt" 
                                value="3" 
                                v-model="data_detail.type" 
                                class="form-check-input" 
                                name="type"
                            />
                            <label for="quitt" class="form-check-label">Kitt</label>
                        </div>
                    </div>
                </div>
                <div class="mb-2 col-xl-12 px-1" v-if="mod == 2">
                    <label for="area" class="form-label">Área</label>
                    <div class="form-check">
                        <input 
                            type="checkbox" 
                            id="farmacia" 
                            v-model="data_detail.area" 
                            class="form-check-input" 
                            aria-checked="false"
                        />
                        <label for="farmacia" class="form-check-label">Farmacia</label>
                    </div>
                </div>
<!--                 <div class="mb-2 col-xl-12 px-1" v-if="num === 1">
                    <label for="area" class="form-label">Contenido</label>
                    <div class="form-check form-switch">
                        <input
                            class="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckChecked"
                            v-model="data_detail.status"
                            :checked="data_detail.status == 1 ? true : false"
                            aria-checked="false"
                        />
                        <label class="form-check-label" for="flexSwitchCheckChecked">
                            {{ data_detail.status == 1 ? 'No LLeno' : 'Lleno' }}
                        </label>
                    </div>
                </div> -->
            </div>
            <div class="col-md-12 mt-3 pt-1" v-if="num === 1">
                <div class="form-check form-switch">
                    <input
                        class="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="flexSwitchCheckChecked"
                        v-model="data_detail.state"
                        :checked="data_detail.state == 1 ? true : false"
                        aria-checked="false"
                    />
                    <label class="form-check-label" for="flexSwitchCheckChecked">
                        {{ data_detail.state == 1 ? 'Activo' : 'Inactivo' }}
                    </label>
                </div>
            </div>
        </div>
        <template #footer>
            <button class="btn btn-success btn-sm" @click="DataDetail" :disabled="cpButton">
                {{ (num == 0 ) ? 'Registrar':'Editar' }}
            </button>
            &nbsp;
        </template>
    </CModal>
</template>

<script>
import CModal from "@/components/shared/modals/CModal.vue";
import {
    mapActions
} from "vuex";

export default {
    name: "c-maintenance-modal-documento",
    components: {
        CModal,
    },
    data() {
        return {
            data_detail: {
                name: '',
                type: '',
                campu_id: '',
                almacen_id: [],
                state: '',
                area:'',
                status: ''
            },
        };
    },
    watch: {
        payload: {
            handler(newVal) {
                if(this.num == 1){
                    if (newVal) {
                    this.data_detail = {
                        ...newVal,
                        almacen_id: newVal.almacen_id ? newVal.almacen_id.split(',').map(Number) : []
                    };
                }
                }
            },
            immediate: true,
        },
    },
    props: {
        title: String,
        boo_modal: Boolean,
        num: Number,
        mod: Number,
        payload: {
            type: Object,
            default: null
        },
        dataUno: Array,
    },
    computed: {
        cpButton() {
                return !(this.data_detail.name.trim() !== '' && this.data_detail.type !== '');
        }
    },
    methods: {
        ...mapActions(["get", "post"]),
        mtdClose() {
            this.data_detail = {
                name: '',
                type: '',
                campu_id: '',
                almacen_id: [],
                state: '',
                area:'',
                status: ''
            },
            this.$emit("closeModal");
        },
        DataDetail() {
            this.data_detail.campu_id =  this.$store.getters.get__campus;
            this.$emit('Datadetail', this.data_detail, this.num)
            this.mtdClose();
        },
    },
    

};
</script>