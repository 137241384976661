<template>
  <div>
    <div class="px-3 mt-1" v-if="view == 'carrito'">
      <div
        id="div-modal-venta"
        class="d-flex justify-content-center"
      >
        <div id="div-venta" class="card w-100">
          <div
            class="card-header text-white text-center h5 bg-main"
          >
            <div class="row d-flex align-items-center text-center">
                <div class="col-xl-10 col-lg-10 col-md-9 col-sm-9 col-xs-8">
                    <strong>AGREGAR MARCA</strong>
                </div>
                <div class="col-xl-2 col-lg-2 col-md-3 col-sm-3 col-xs-4">
                    <div class="d-flex justify-content-end align-items-center">
                        <button type="button" class="btn btn-light btn-sm" style="border-radius: 2px; font-size: 0.75rem; padding: 0.25rem 0.5rem; height: auto;">
                            <strong>TOTAL : &nbsp;<span class="ms-1">{{ total }}</span></strong>
                        </button>
                    </div>
                </div>

            </div>
          </div>
          <div class="card-body" style="background-color: #e6e6e6; height: 347px; ">
            <div class="row d-flex" v-if="dataLote.desp_state !== 3">
              <div class="col-md-6">
                <div class="col-md-12">
                  <div class="input-group input-group-sm mb-1">
                    <span
                      class="input-group-text"
                      style="background: #900052; color: #ffffff"
                      ><i class="fas fa-search"></i
                    ></span>
                    <input
                      type="search"
                      id="search_product"
                      class="form-control"
                      placeholder="Digitar Medicamento Similar"
                      aria-describedby="search_product"
                      v-model="name"
                      disabled
                    />
                  </div>
                </div>

                <div class="row py-1 scrollable-container-ca" id="list-product">
                  <div
                    v-for="(item, index) in data_product_filter"
                    :key="index"
                    class="col-md-12 py-1 d-flex align-items-center"
                    id="item-product"
                    style="background-color: #e6e6e6; height: 70px"
                  >
                    <div
                      class="p-1 d-flex flex-column w-100 justify-content-center"
                      style="background-color: #ffffff; height: 100%"
                      @click="mtdSelectSubsubcatergorie(item)"
                    >
                      <div class="align-items-center" id="name-product">
                        <p class="d-flex align-items-center mb-0 m-1">
                          <strong
                            class="mb-0 item-name custom-tooltip d-flex justify-content-between align-items-center text-center"
                            data-toggle="tooltip"
                            :data-placement="'top'"
                            :title="item.name"
                            :class="{
                              'text-success': item.total_quantity > 5,
                              'text-danger': item.total_quantity === 0,
                              'text-custom-light-green':
                                item.total_quantity <= 5 && item.total_quantity > 0,
                            }"
                            style="
                              width: 98%;
                              margin-left: 10px;
                              font-size: 11px;
                            "
                          >
                            {{ item.name }}&nbsp;
                            <template v-if="item.brand_name !== null">
                              &nbsp; ({{ item.brand_name }})
                            </template>
                            &nbsp;
                            <span style="font-weight: bold; font-size: 12.5px; text-align: right;">
                              <b>({{ item.total_quantity }})</b>
                            </span>
                          </strong>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- carrito -->
              <div class="col-md-6">
                <div class="col-md-12">
                  <div class="input-group input-group-sm mb-3">
                    <span
                      class="input-group-text rounded-pill w-100"
                      style="background: #900052; color: #ffffff; padding-left: 25px"
                      ><strong>MEDICAMENTOS ESCOGIDOS</strong></span
                    >
                  </div>
                </div>
                <div class="col-md-12 list-monto">
                  <div class="container-list">
                    <div class="" v-if="carrito.length > 0">
                      <div
                        class="box item-monto"
                        v-for="(item, index) in carrito"
                        :key="index"
                      >
                        <p class="text-monto">({{ item.quantity }}) {{ item.marca }}</p>
                        <div class="input-group" style="">
                          <div
                            align="right"
                            class="form-control"
                            v-if="posEditPrice != index"
                          >
                            <button
                              type="button"
                              class="rounded-circle m-1"
                              @click="mtdDelQuantity(index)"
                            >
                              <i class="fas fa-minus text-white"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card text-center" v-else>
                <div class="card-body">
                    <h6 class="card-title mb-0" style="color: #900052">Marcas Asignada y Atendida</h6>
                </div>
            </div>

          </div>
          <div
            class="card-footer text-white"
            style="background: #900052; font-size: 20px"
          >
            <div class="row">
                <div align="left" class="col">
                    <button class="btn btn-sm border border-white text-white" style="margin-left: 2px" @click="mtdNextStep">
                        <strong>GUARDAR MARCA</strong>
                    </button>
                    <button class="btn btn-sm border border-white text-white" style="margin-left: 10px" @click="verifyDispatch">
                        <strong>GUARDAR DESPACHO</strong>
                    </button>
                </div>
            </div>

          </div>
        </div>
      </div>
      <br />
    </div>
    <cModalLoteVue
      ref="modalLoteComponent"
      :title="modalLote.title"
      :boo_modal="modalLote.boo_modal"
      :item="modalLote.item"
      :total="modalLote.total"
      :producto="modalLote.producto"
      :marca="modalLote.marca"
      :idProducto="modalLote.idProducto"
      :idRecipeDetail="modalLote.idRecipeDetail"
      :location="modalLote.location"
      @mtdCloseModalLote="mtdCloseModalLote"
      @datos-agregados="handleDatosAgregados"
    />
    <SweetAlert :swal="swal" ref="SweetAlert" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import cModalLoteVue from "../Modals/cModalLotePd.vue";

export default {
  name: "c-Pharmacy-cCarroAddPd",
  components: {
    SweetAlert,
    cModalLoteVue,
  },
  props: {
    recipeId: Number,
    molecule: String,
    dosis: String,
    presentation: String,
    name: String,
    type: Number,
    detailId: Number,
    nameMedic: Number,
    total: Number,
    totalMedic: Number,
    dataSubSubCategories: {
        type: Array,
        default: null
    },
    data_product_filter: {
        type: Array,
        default: null
    },
    dataLote: [],
    recipe: [],
    destino: String,
  },
  data() {
    return {
        swal: null,
      view: "carrito",
      search_product: "",
      carrito: [],
      item_carrito: {},
      posEditPrice: null,
      stock: [],
      modalLote: {
        title: "Seleccionar Lote",
        item: [],
        boo_modal: false,
        pos: null,
        total: null,
        producto: "",
        marca: "",
        idProducto: null,
        idRecipeDetail: null,
        price: null,
        id: null,
        location: null,
      },
      contadorTotal: null
    };
  },
  computed: {},
  methods: {
    ...mapActions(["get", "post"]),
   
    retroceder() {
      this.$emit("mtdBack");
    },

    mtdSelectSubsubcatergorie: function (item) {
      if (item.total_quantity === 0) {
        this.$refs.SweetAlert.showWarning("No puede agregar al carrito Medicamentos sin Stock");
        return;
      }
      const itemExists = this.carrito.some(cartItem => cartItem.idProducto === item.id);
  
        if (itemExists) {
            this.$refs.SweetAlert.showWarning("Ya está agregado esta Marca en el Carritoo");
            return; 
        }

        const totalQuantityInCarrito = this.carrito.reduce((sum, cartItem) => sum + cartItem.quantity, 0);
        this.contadorTotal = this.dataLote.duration && this.dataLote.frequency && this.dataLote.desp_state == 1
                ? this.dataLote.total
                : this.dataLote.pending;
        this.contadorTotal = this.contadorTotal - totalQuantityInCarrito;
        if (this.contadorTotal <= 0) {
            this.$refs.SweetAlert.showWarning("No puede agregar más. Se ha alcanzado el límite de: " + this.total);
            return;
        } 

      this.item_carrito = {
        id: item.id,
        name: item.name,
        price: item.precio_farmacia_particular,
        quantity: 1,
        subategorie: {
          id: item.subCatId,
          name: item.subCatName,
        },
        categorie: {
          id: item.catId,
          name: item.catName,
          type: item.catType,
        },
      };
      this.search_product = "";
      this.traerLote(this.dataLote, item);
    },

    //Traer Lote
    traerLote(item, i) {
      this.post({
        url: this.$store.getters.get__url + "/Pharmacy/recipe/stockProductId",
        params: { productdetail: i.id },
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          if (response.data.state == 0 && response.data.data.length > 0) {
            this.modalLote.item = response.data.data;
            this.modalLote.total = this.contadorTotal;
            this.modalLote.producto = i.name;
            this.modalLote.marca = i.brand_name;
            this.modalLote.idProducto = i.id;
            this.modalLote.idRecipeDetail = item.id;
            this.modalLote.title =  "Seleccionar Lote";
            this.modalLote.boo_modal = true;
            this.$nextTick(() => {
              this.$refs.modalLoteComponent.validarAutomaticamente();
              });
          } else {
            this.modalLote.boo_modal = false;
            this.$refs.SweetAlert.showWarning("Sin Stocks");
          }
        })
        .catch((errors) => {
          this.$refs.SweetAlert.showError(errors);
        });
    },

    handleDatosAgregados(datos) {
        this.carrito.push(datos);
        this.mtdCloseModalLote();
    },

    mtdDelQuantity: function (pos) {
      let cantidad = this.carrito[pos].quantity;
      let temp = [];
      this.carrito.forEach((element, index) => {
        if (index != pos) {
          temp.push(element);
        }
      });
      this.carrito = temp;
    },

    calculateTotalQuantityDispatch(carrito) {
        let total = 0;
        carrito.forEach(item => {
            if (item.tabla && Array.isArray(item.tabla)) {
                item.tabla.forEach(tablaObj => {
                    if (tablaObj.dates && Array.isArray(tablaObj.dates)) {
                        tablaObj.dates.forEach(dateObj => {
                            total += parseFloat(dateObj.quantity_dispath) || 0;
                        });
                    }
                });
            }
        });
        return total;
    },
    
    mtdNextStep: function () {
    if (this.carrito.length > 0) {
        const totalQuantityDispatch = this.calculateTotalQuantityDispatch(this.carrito);
        const data = {
            carrito: this.carrito,
            total: this.totalMedic,
            detailId: this.detailId,
            totalDespachado: totalQuantityDispatch,
        };
        if (this.total == totalQuantityDispatch){
            this.mtdSendCarrito(data, this.carrito, this.detailId);
            this.carrito = [];
        } else {
            this.$refs.SweetAlert.showConfirmSimple2(
                `La Cant. Despachada (${totalQuantityDispatch}) no coincide con la Cant. Total (${this.total})`, 
                "¿Desea continuar?",
                "warning",
                "Confirmar"
            )
                .then((result) => {
                    if (result.value) {
                        this.mtdSendCarrito(data, this.carrito, this.detailId);
                        this.carrito = [];
                    }
                });
        }

      } else {
        this.$refs.SweetAlert.showWarning("Seleccione una Marca");
      } 
    },

    mtdSendCarrito: function (data, carrito, detailId) {
      this.post({
        url: this.$store.getters.get__url + "/Pharmacy/recipe/carroAddBrand",
        token: this.$store.getters.get__token,
        params: {
            data: data,
            carrito: carrito,
            detailId: detailId,
            idRecipe: this.recipeId
        }
      })
      .then((response) => {
            if(response.statusCode == 200){
                this.$emit("addCarrito", data, response.data);
                Swal.fire({
                    text: "Marcas Asignadas",
                    icon: "success",
                    confirmButtonColor: "#900052",
                });
            }
        })
        .catch((errors) => {
            Swal.fire({
                text: errors,
                icon: "error",
                confirmButtonColor: "#900052",
            });
        });
    },

    verifyDispatch() {
        this.get({
                url: this.$store.getters.get__url + "/Pharmacy/recipe/verifyDispatch/" + this.recipeId,
                token: this.$store.getters.get__token,
            })
        .then((response) => {
            if(response.statusCode == 200) {
               if(response.data.length == 0){
                this.$refs.SweetAlert.showWarning("Sin Recetas Agregadas al Carrito. Seleccione una Marca");
               }else{
                    this.addmedicament(this.recipeId);
               }
            }
        })
        .catch((errors) => {
            this.$refs.SweetAlert.showError(errors);
        });
    },


    addmedicament(id) {
        Swal.fire({
          icon: "warning",
          title: "¿Está seguro de despachar el medicamento?",
          input: "textarea",
          inputAttributes: {
            autocapitalize: "off",
            placeholder: "NOTA",
          },
          width: "400px",
          confirmButtonText: "Permitir",
          confirmButtonColor: "#3085d6",
          showCancelButton: true,
          cancelButtonColor: "#d33",
          cancelButtonText: "Cerrar",
        }).then((result) => {
          if (result.isConfirmed) {
            const note = result.value;
            this.post({
              url: this.$store.getters.get__url + "/Pharmacy/recipe/storeRecipedetail",
              token: this.$store.getters.get__token,
              params: {
                campu_id: this.$store.getters.get__campus,
                recipe_id: id,
                patient_id: this.recipe.patient_id,
                sale_id: this.recipe.sale_id,
                note: note,
                destino: this.destino,
              },
            })
              .then((response) => {
                if (response.statusCode == 200) {
                    Swal.fire({
                        text: "Recetas Despachadas",
                        icon: "success",
                        confirmButtonColor: "#900052",
                    });
                    window.open(
                        this.$store.getters.get__url + "/viewpdfDocInterno/" + response.data.idDis,
                        "_blank"
                    );
                    this.$emit("mtdMediDespachar", response.data.idRecipe);
                }

              })
              .catch((errors) => {
                    Swal.fire({
                        text: errors,
                        icon: "error",
                        confirmButtonColor: "#900052",
                    });
              });
          }
        });
    },

    mtdCloseModalLote: function (item) {
      this.modalLote = {
        title: "Seleccionar Lote",
        item: [],
        boo_modal: false,
        pos: null,
        total: null,
        producto: "",
        marca: "",
        idProducto: null,
        idRecipeDetail: null,
        price: null,
        id: null,
        location: null,
      };
    },

    clearBrandCarrito: function(){
        this.carrito = [];
    }
  },
};
</script>

<style scoped>
.btn-home {
  z-index: 1;
}

.text-custom-light-green {
  color: #60d004;
}

.list-doctor,
.list-monto {
  padding-right: 25px;
}

.text-monto {
  color: #900052;
  font-size: 13px;
  font-weight: bold;
  margin-bottom: 0px;
}

.container-list {
  margin: 30px;
  width: 100%;
}

.box {
  margin-bottom: 10px;
  position: relative;
}

.box::before {
  content: "";
  position: absolute;
  width: 3px;
  height: 100%;
  background: #808080;
  left: -15px;
  top: 30px;
}

.box::after {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #900052;
  left: -23px;
  top: 10px;
}

.item-doctor,
.item-monto {
  padding: 10px 10px;
  border: #900052 3px solid;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.item-doctor:hover {
  cursor: pointer;
  background-color: #900052;
}

.item-doctor:hover p {
  color: #ffffff !important;
}

#item-product {
  align-items: center;
}

#item-product:hover {
  cursor: pointer;
  align-items: center;
  transform: scale(1.05);
}

.item-monto .input-group-text,
.item-monto .form-control {
  background-color: #e6e6e6 !important;
  border: 0 !important;
  padding: 0px;
}

.item-monto .form-control button {
  background-color: #808080 !important;
  border: 0 !important;
}

.item-monto .form-control button:hover {
  background-color: #000000 !important;
  border: 0 !important;
}
.item-name {
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.item-name strong {
  padding: 0px 4px;
  width: 120px;
}

@media (max-width: 800px) {
  #btn-open-caja {
    justify-content: center !important;
  }

  #btn-registro-venta {
    text-align: center;
  }

  #div-tabla-venta {
    overflow-x: auto;
  }
}

@media (min-width: 800px) {
  #btn-registro-venta {
    text-align: left;
  }
}
#table-stock tbody tr:hover {
  background-color: #f3e2ec;
}
.scrollable-container-ca {
    height: 300px;
    overflow-y: auto;
    overflow-x: hidden; 
  }
</style>
