<template>
    <div>
        <div class="container">
            <div class="row">
                <div class=" btn-home  col-md-12 mt-3 mb-4 d-flex justify-content-end">
                    <button type="button" class="btn btn-outline-main" @click="returnHome">
                        <i class="fas fa-home-lg-alt"></i> Inicio
                    </button>
                </div>
            </div>
            <div id="div-modal-cxc" class="d-flex justify-content-center">
                <div id="div-cxc" class="card w-100">
                    <div class="text-center card-header text-white h4 bg-main">
                        <strong>PERMISO DE ORDEN DE SERVICIO</strong>
                    </div>
                    <div class="card-body w-100">
                        <div class="row">
                            <div class="col-md-12 py-2">
                                <div class="row mx-0">

                                </div>
                            </div>
                            <div class="col-md-12" id="content-timelinetable">
                                <table id="tb-cxc" class="table table-bordered border-main">
                                    <caption class="py-0 my-0"></caption>
                                    <thead>
                                        <tr class=" text-center text-white border-white bg-main">
                                            <th scope="col">N°</th>
                                            <th scope="col">USUARIO SOLICITANTE</th>
                                            <th scope="col">PROVEEDOR</th>
                                            <th scope="col">TOTAL</th>
                                            <th scope="col" class="table-th-acciones">ESTADO</th>
                                            <th scope="col" class="table-th-acciones">ACCION</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-if="cpData.length === 0">
                                            <td class="text-center align-middle" colspan="7">Sin Permisos de orden de
                                                servicios</td>
                                        </tr>
                                        <tr v-for="(item, index) in cpData" :key="index" class="font-size">
                                            <td class="text-center align-middle">
                                                {{ index + 1 }}
                                            </td>
                                            <td class="text-center align-middle">{{ item.user.name }}</td>
                                            <td class="text-center align-middle">{{ item.provider.name }}</td>
                                            <td class="text-center align-middle"> {{ item.total }}</td>
                                            <td class="text-center align-middle">
                                                <div id="state_speciality" v-if="item.permission == 1">
                                                    <div class="bg-secondary text-white">Por confirmar</div>
                                                </div>
                                                <div id="state_speciality" v-if="item.permission == 2">
                                                    <div class="bg-success text-white">Aceptado</div>
                                                </div>
                                                <div id="state_speciality" v-if="item.permission == 3">
                                                    <div class="bg-warning text-white">Rechazado</div>
                                                </div>
                                            </td>
                                            <td class="text-center align-middle">
                                                <div class="text-center d-flex justify-content-center">
                                                    <button v-if="item.permission == 1"
                                                        @click="mtdPermission(item.id, item.permission)"
                                                        class="btn btn-sm btn-success text-white mx-1">
                                                        <i class="fa fa-edit"></i>
                                                    </button>
                                                    <button @click="mtdViewPdf(item)"
                                                        class="btn btn-sm btn-primary text-white mx-1">
                                                        <i class="fa fa-file-pdf"></i>
                                                    </button>
                                                    <button class="btn btn-sm btn-info text-white mx-1"
                                                        @click="viewservice(item)" style="margin-left: 2px">
                                                        <i class="fas fa-eye text-white"></i>
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div v-if="cpData.length > 0" class="d-flex justify-content-center px-3 mt-3">
                                <nav aria-label="Page navigation example">
                                    <ul class="pagination mb-1">
                                        <li class="page-item">
                                            <a class="page-link page" href="javascript:void(0)"
                                                @click="backPage">Anterior</a>
                                        </li>
                                        <li v-if="limitedStepPagination[0] !== 1" class="page-item">
                                            <a :class="selectPage === 1 ? 'page-link active' : 'page-link'"
                                                href="javascript:void(0)" @click="selectedPage(1)">1</a>
                                        </li>
                                        <li v-if="limitedStepPagination[0] > 2" class="page-item disabled">
                                            <span class="page-link">...</span>
                                        </li>

                                        <li v-for="(item, index) in limitedStepPagination" :key="index"
                                            class="page-item">
                                            <a :class="selectPage === item ? 'page-link active' : 'page-link'"
                                                href="javascript:void(0)" @click="selectedPage(item)">{{ item }}
                                            </a>
                                        </li>
                                        <li v-if="limitedStepPagination[limitedStepPagination.length - 1] < stepPagination.length - 1"
                                            class="page-item disabled">
                                            <span class="page-link">...</span>
                                        </li>
                                        <li v-if="limitedStepPagination[limitedStepPagination.length - 1] !== stepPagination.length"
                                            class="page-item">
                                            <a :class="selectPage === limitedStepPagination.length + 2 ? 'page-link active' : 'page-link'"
                                                href="javascript:void(0)"
                                                @click="selectedPage(stepPagination.length)">{{ stepPagination.length }}
                                            </a>
                                        </li>
                                        <li class="page-item">
                                            <a class="page-link page" href="javascript:void(0)"
                                                @click="nextPage">Siguiente</a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <SweetAlert :swal="swal" ref="SweetAlert" @mtdPermissionRollback="mtdPermissionRollback"
            @mtdPermissionCommit="mtdPermissionCommit" /> -->
        <SweetAlert :swal="swal" ref="SweetAlert" @mtdPermissionCommit="mtdPermissionCommit" />
        <cModalViewServicesVue :dataservicedetails="dataservicedetails" :title="modalService.title"
            :boo_modal="modalService.modal_form" @closeModalService="closeModalService" />

    </div>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
// import cModalViewServicesVue from '../../modals/Os/cModalViewServices.vue';
import cModalViewServicesVue from "@/components/Logistica/modals/Os/cModalViewServices.vue";

export default {
    name: "c-gerencia-permisoLogistica",
    components: {
        SweetAlert,
        cModalViewServicesVue,
    },
    data() {
        return {
            search: "",
            dataservicedetails: [],
            dataShow: [],
            data: [],
            filter: {
                init: null,
                end: null,
            },
            modal: {
                title: '',
                modal_form: false,
                item: [],
                idNote: null,
                state: null
            },
            modalService: {
                title: "",
                modal_form: false,
            },
            //Pag
            totalPagesToShow: 10,
            page: 0,
            selectPage: 1,
            stepPagination: [],
            swal: null,
        };
    },
    watch: {
        selectPage() {
            this.calculateData(10);
        },

    },
    computed: {
        cpData() {
            return this.dataShow;
        },
        limitedStepPagination() {
            const totalPages = this.stepPagination.length;
            const currentPage = this.selectPage;

            let startPage = 1;
            let endPage = totalPages;
            if (totalPages > this.totalPagesToShow) {
                const halfPagesToShow = Math.floor(this.totalPagesToShow / 2);

                if (currentPage > halfPagesToShow) {
                    startPage = currentPage - halfPagesToShow;
                    endPage = currentPage + halfPagesToShow - 1;

                    if (endPage > totalPages) {
                        endPage = totalPages;
                        startPage = totalPages - this.totalPagesToShow + 1;
                    }
                } else {
                    endPage = this.totalPagesToShow;
                }
            }

            return Array.from({
                length: endPage - startPage + 1,
            },
                (_, i) => startPage + i
            );
        },

    },
    created() {
        moment.locale("es");
        this.filter.end =
            moment().format("L").substr(6, 4) +
            "-" +
            moment().format("L").substr(3, 2) +
            "-" +
            moment().format("L").substr(0, 2);
        this.filter.init = this.filter.end;
        this.mtdGetData();
    },
    methods: {
        ...mapActions(["get", "post"]),

        mtdGetData: function () {
            this.get({
                url: this.$store.getters.get__url + "/Gerencia/byPermisoOrdenServicio",
                token: this.$store.getters.get__token,
            })
                .then((response) => {
                    console.log(response);

                    this.data = response.data;
                    this.calculateData(10);
                })
                .catch((errors) => {
                    this.$refs.SweetAlert.showError(errors);
                });
        },
        viewservice: function (item) {
            this.get({
                url: this.$store.getters.get__url + "/logistics/os/indexservicedetails/" + item.id,
                token: this.$store.getters.get__token,
            })
                .then((response) => {
                    this.dataservicedetails = response.data;
                    let code = this.mtdGenerateCode(item.id);
                    this.modalService = {
                        title: "Detalle del orden del servicio" + code,
                        modal_form: true,
                    }
                })
                .catch((errors) => { });

        },
        closeModalService: function () {
            this.modalService = {
                title: '',
                modal_form: false,
            }
        },

        calculateData(items) {
            let filteredData = this.data;
            let totalPages = Math.ceil(filteredData.length / items);
            this.stepPagination = Array.from({
                length: totalPages,
            },
                (_, index) => index + 1
            );
            let startIndex = (this.selectPage - 1) * items;
            let endIndex = startIndex + (items - 1);
            this.dataShow = filteredData.slice(startIndex, endIndex + 1);
        },

        openModal(id, detail, state) {
            this.modal.title = 'Detalle de Nota de Ingreso';
            this.modal.idNote = id;
            this.modal.item = detail;
            this.modal.state = state;
            this.modal.modal_form = true;
        },

        closeModal() {
            this.modal = {
                title: '',
                modal_form: false,
                idNote: null,
                item: [],
                state: null
            }
        },

        //Paginado
        backPage() {
            this.selectPage = this.selectPage > 1 ? this.selectPage - 1 : 1;
        },
        nextPage() {
            this.selectPage =
                this.selectPage < this.stepPagination.length ?
                    this.selectPage + 1 :
                    this.stepPagination.length;
        },
        selectedPage(page) {
            this.selectPage = page;
        },
        returnHome: function () {
            this.$emit("returnHome");
        },
        mtdPermission: function (id, type) {
            if (type == 1) {
                this.$refs.SweetAlert.showPermisions(id, type, false, true);
            }
        },
        mtdPermissionCommit: function (id, obs, type, state) {
            console.log('id', id, obs, 'type:', type, 'state:', state);

            this.post({
                url: this.$store.getters.get__url + "/Gerencia/commitpermision/" + id,
                token: this.$store.getters.get__token,
                params: {
                    obs: obs,
                    permission: state,
                    type: type,
                    user: this.$store.getters.get__user.id,
                },
            })
                .then((response) => {
                    if (response.statusCode == 200) {
                        if (state == 2) {
                            window.open(
                                this.$store.getters.get__url +
                                "/logistics/os/viewpdf/" +
                                response.data.id,
                                "_blank"
                            );
                        }
                        this.$refs.SweetAlert.showSuccess(state == 2 ? "Permiso otorgado" : "Permiso denegado");

                        this.mtdGetData();
                    } else {
                        this.$refs.SweetAlert.showWarning("No se puedo registrar.");
                    }
                })
                .catch((errors) => {
                    console.log(errors);
                });
        },
        mtdViewPdf: function (expense) {
            if (expense) {
                window.open(
                    this.$store.getters.get__url +
                    "/logistics/os/viewpdf/" +
                    expense.id,
                    "_blank"
                );
            } else {
                this.$refs.SweetAlert.showWarning("No tiene documento adjunto.");
            }
        },
        mtdGenerateCode(item) {
            if (0 < item && item < 10) {
                return " OS-000" + item;
            }

            if (9 < item && item < 100) {
                return " OS-00" + item;
            }
            if (99 < item && item < 1000) {
                return " OS-0" + item;
            }
            if (999 < item && item < 10000) {
                return " OS-" + item;
            }
        },
    },

    filters: {
        formatDate(date) {
            return date != null ? moment(date).format("DD/MM/YYYY") : "-";
        },
        formatDateTime(dateTime) {
            return dateTime != null ? moment(dateTime).format("DD/MM/YYYY HH:mm") : "-";
        },
    },
}
</script>
<style>
.font-size {
    font-size: 13px !important;
}

.align-middle {
    vertical-align: middle;
}
</style>