<template>
    <div>
        <div class="px-5 pb-5 pt-2">
            <div class="row">
                <div class=" btn-home  col-md-12 mt-3 mb-4 d-flex justify-content-end">
                    <button type="button" class="btn btn-outline-main" @click="returnHome">
                        <i class="fas fa-home-lg-alt"></i> Inicio
                    </button>
                </div>
            </div>
            <div class="d-flex justify-content-center">
                <div class="card w-100 mb-lg-5">
                    <div class="text-center card-header text-white h4 bg-main">
                        <strong>DESCUENTOS</strong>
                    </div>
                    <div class="card-body w-100">
                        <div class="row">
                            <div class="col-md-12 py-2">
                                <div class="row d-flex mx-0">
                                    <div class="col-xl-2 col-lg-2 col-md-6 col-sm-6 mt-2">
                                        <div class="input-group input-group-sm">
                                            <span class="input-group-text col-md-4">Desde</span>
                                            <input type="date" aria-label="shift_date" class="form-control form-control-sm" id="begindate" v-model="filter.begindate" @change="mtdGetData" />
                                        </div>
                                    </div>
                                    <div class="col-xl-2 col-lg-2 col-md-6 col-sm-6 mt-2">
                                        <div class="input-group input-group-sm">
                                            <span class="input-group-text col-md-5">Hasta</span>
                                            <input type="date" aria-label="shift_date" class="form-control form-control-sm" id="end" v-model="filter.enddate" @change="mtdGetData" />
                                        </div>
                                    </div>
                                    <div class="col-xl-6 col-lg-6 col-md-10 col-sm-10 col-xs-12 mt-2">
                                        <div class="input-group input-group">
                                            <span class="input-group-text text-white bg-main"><i class="fas fa-search"></i></span>
                                            <input @keyup="calculateData(10)" v-model="search" type="text" class="form-control form-control-sm" placeholder="Buscar DNI / Nombre y Apellido" />
                                        </div>
                                    </div>
                                    <div class="col-xl-2 col-md-2 col-sm-3 mb-3 mt-2">
                                        <button type="button" name="accion" class="btn btn-sm mb-3 w-100 bg-main text-white" @click="exportExcel">
                                            <i class="fas fa-file-excel"></i> Exportar
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 text-center px-4">
                                <!-- paginado -->
                                <div v-if="cpData.length > 0" class="d-flex justify-content-end">
                                    <nav aria-label="Page navigation example">
                                        <ul class="pagination mb-1">
                                            <li class="page-item">
                                                <a class="page-link page" href="javascript:void(0)" @click="backPage">Anterior</a>
                                            </li>
                                            <li v-if="limitedStepPagination[0] !== 1" class="page-item">
                                                <a :class="selectPage === 1 ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(1)">1</a>
                                            </li>
                                            <li v-if="limitedStepPagination[0] > 2" class="page-item disabled">
                                                <span class="page-link">...</span>
                                            </li>

                                            <li v-for="(item, index) in limitedStepPagination" :key="index" class="page-item">
                                                <a :class="selectPage === item ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(item)">{{ item }}
                                                </a>
                                            </li>
                                            <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] <stepPagination.length - 1" class="page-item disabled">
                                                <span class="page-link">...</span>
                                            </li>
                                            <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] !== stepPagination.length" class="page-item">
                                                <a :class="selectPage === limitedStepPagination.length + 2 ? 'page-link active':'page-link'" href="javascript:void(0)" @click="selectedPage(stepPagination.length)">{{ stepPagination.length }}
                                                </a>
                                            </li>
                                            <li class="page-item">
                                                <a class="page-link page" href="javascript:void(0)" @click="nextPage">Siguiente</a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                            <div class="col-md-12" id="content-timelinetable">
                                <table class="table table-bordered border-main">
                                    <caption class="py-0 my-0"></caption>
                                    <thead class="bg-main">
                                        <tr class=" table-th text-white text-center">
                                            <th class="table-th-number">N°</th>
                                            <th>TIPO</th>
                                            <th>APELLIDOS Y NOMBRE</th>
                                            <th>LIQUIDACION DEL SERVICIO</th>
                                            <th>FECHA DE LA VENTA</th>
                                            <th>CUOTAS</th>
                                            <th>SERIE - NUMERO</th>
                                            <th>MES</th>
                                            <th>ESTADO DEL DOCUMENTO</th>
                                            <th>VOUCHER</th>
                                        </tr>
                                    </thead>
                                    <tbody class="border-main">
                                        <tr class="table-body text-center" v-if="cpData.length === 0">
                                            <td colspan="10" class="text-center">No se encontraron resultados</td>
                                        </tr>
                                        <tr class="table-body text-center" v-for="(item, index) in cpData" :key="index">
                                            <td>
                                                {{mtdNumber(index)}}
                                            </td>
                                            <td>
                                                {{ item.type.toUpperCase() }}
                                            </td>
                                            <td>
                                                {{ item.patient.document }}-{{ item.patient.name }}
                                            </td>
                                            <td>
                                                S/{{ item.total }}
                                            </td>
                                            <td>
                                                {{ item.date | formatDate }}
                                            </td>
                                            <td>
                                                {{ item.discounts[0].quotes }}
                                            </td>
                                            <td>
                                                {{ item.voucher[0].serie }}-{{ item.voucher[0].number }}
                                            </td>
                                            <td class="text-danger">
                                                {{ mes(item.discounts[0].month) }}
                                            </td>
                                            <td>
                                                <div>
                                                    <span class="badge text-bg-success" v-if="item.voucher[0].state == 1">Activo</span>
                                                    <span class="badge text-bg-danger" v-if="item.voucher[0].state == 2">Anulado</span>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="text-center">
                                                    <button @click="mtdViewPdf(item)" class="btn btn-sm btn-primary text-white">
                                                        <i class="fa fa-file-pdf"></i>
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </div>
        <SweetAlert :swal="swal" ref="SweetAlert" />
    </div>
</template>

    
<script>
import {
    mapActions
} from "vuex";
import moment from "moment";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import exportFromJson from "export-from-json";

export default {
    name: "c-admision-pages-anular",
    components: {
        SweetAlert,
    },
    data() {
        return {
            filter: {
                begindate: null,
                enddate: null,
            },
            swal: null,
            search: "",
            dataShow: [],
            excel: [],
            //Pag
            page: 0,
            selectPage: 1,
            stepPagination: [],
            totalPagesToShow: 4,
        };
    },
    watch: {
        selectPage() {
            this.calculateData(10);
        },
    },
    computed: {
        cpData() {
            return this.dataShow;
        },
        limitedStepPagination() {
            const totalPages = this.stepPagination.length;
            const currentPage = this.selectPage;

            let startPage = 1;
            let endPage = totalPages;
            if (totalPages > this.totalPagesToShow) {
                const halfPagesToShow = Math.floor(this.totalPagesToShow / 2);

                if (currentPage > halfPagesToShow) {
                    startPage = currentPage - halfPagesToShow;
                    endPage = currentPage + halfPagesToShow - 1;

                    if (endPage > totalPages) {
                        endPage = totalPages;
                        startPage = totalPages - this.totalPagesToShow + 1;
                    }
                } else {
                    endPage = this.totalPagesToShow;
                }
            }

            return Array.from({
                    length: endPage - startPage + 1,
                },
                (_, i) => startPage + i
            );
        },

    },
    created() {
        this.getFirstDayOfMonth();

    },
    methods: {
        ...mapActions(["get", "post"]),
        getFirstDayOfMonth: function () {
            const currentDate = new Date();
            const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
            const today = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
            this.filter.begindate = firstDayOfMonth.toISOString().split('T')[0];
            this.filter.enddate = today.toISOString().split('T')[0];
            this.mtdGetData();
        },
        mtdViewPdf: function (item) {
            let ticket = JSON.parse(item.voucher[0].sunat);
            let enlace = ticket.json.enlace_del_pdf_ticket;
            window.open(
                enlace,
                "_blank"
            );

        },
        //Traer Data
        mtdGetData: function () {
            this.post({
                    url: this.$store.getters.get__url + "/Admision/planilla",
                    token: this.$store.getters.get__token,
                    params: {
                        Campus: this.$store.getters.get__campus,
                        init: this.filter.begindate,
                        end: this.filter.enddate,
                    },
                })
                .then((response) => {
                    this.dataShow = [];
                    this.selectPage = 1;
                    this.data = response.data;
                    this.exportExcelReport();
                    this.calculateData(10);
                })
                .catch((errors) => {
                    this.$refs.SweetAlert.showError(errors);
                });
        },
        convertirFecha(formatoYMD) {
            const [year, month, day] = formatoYMD.split("-");
            return `${day}/${month}/${year}`;
        },
        exportExcelReport: function () {
            if (this.data.length > 0) {
                this.data.forEach(element => {
                    let mes = this.mes(element.discounts[0].month);
                    let fecha = this.convertirFecha(element.date);
                    let serie_number = element.voucher[0].serie + "-" + element.voucher[0].number;
                    let state = element.voucher[0].state == 1 ? 'Aceptado' : 'Anulado';
                    this.excel.push({
                        "DNI": element.patient.document,
                        "APELLIDOS Y NOMBRE": element.patient.name,
                        "FECHA": fecha,
                        "LIQUIDACION": element.total,
                        "CUOTAS": element.discounts[0].quotes,
                        "SERIE-NUMERO": serie_number,
                        "MESES": mes,
                        "ESTADO": state,
                    });
                });
            } else {
                this.excel = [];
            }
        },
        exportExcel: function () {
            const datos = this.excel;
            const fileName = 'PLANILLAS';
            const typeExport = exportFromJson.types.xls;
            exportFromJson({
                data: datos,
                fileName: fileName,
                exportType: typeExport,
            });
        },
        calculateData(items) {
            if (!this.data || !this.data.length) {
                return (this.dataShow = []);
            }
            let filteredData = this.data;
            let indexInitial = this.selectPage;
            if (this.search.length >= 2 && this.search != "") {
                let query = this.search.toString().toUpperCase();
                filteredData = this.data.filter((element) => {
                    let name = element.patient.name.toString().toUpperCase();
                    let dni = element.patient.document.toString().toUpperCase();
                    return name.includes(query) || dni.includes(query);
                });
                indexInitial = 1;
            }
            let totalPages = Math.ceil(filteredData.length / items);
            this.stepPagination = Array.from({
                    length: totalPages,
                },
                (_, index) => index + 1
            );

            let startIndex = (indexInitial - 1) * items;
            let endIndex = startIndex + (items - 1);
            this.dataShow = filteredData.slice(startIndex, endIndex + 1);
        },

        //Paginacion
        backPage() {
            this.selectPage = this.selectPage > 1 ? this.selectPage - 1 : 1;
        },
        nextPage() {
            this.selectPage =
                this.selectPage < this.stepPagination.length ?
                this.selectPage + 1 :
                this.stepPagination.length;
        },
        selectedPage(page) {
            this.selectPage = page;
        },
        mtdNumber: function (index) {
            return (this.selectPage - 1) * 10 + (index + 1)
        },
        returnHome: function () {
            this.$emit("mtdChangePage", 'home');
        },
        getMonthName: function (monthString) {
            const months = [
                'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
                'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
            ];
            const monthNumber = parseInt(monthString, 10);
            if (!isNaN(monthNumber) && monthNumber >= 1 && monthNumber <= 12) {
                return months[monthNumber - 1];
            } else {
                return 'Mes no válido';
            }
        },
        mes: function (mes) {
            let monthName = this.getMonthName(mes);
            return monthName;
        }
    },
    filters: {
        formatDate(date) {
            return date != null ? moment(date).format("DD/MM/YYYY") : "-";
        },
        formatTime(time) {
            let arTime = time.split(":");
            if (arTime[0] <= 12) {
                return time + " am";
            } else {
                return arTime[0] - 12 + ":" + arTime[1] + ":" + arTime[2] + " pm";
            }
        },
    },
}
</script>
