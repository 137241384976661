<template>
   <div>
    <div class="w-100 h-100">
      <Nav />
    </div>
    <div class="content h-100" style="background: white">
       <Home/>
    </div>

   </div> 
</template>
<script>
import Nav from '../../components/Asistencial/Nav.vue'
import Home from  '../../components/profile/Home.vue'
export default{
    name : 'profile',
    components : {Nav,Home}
}


</script>