<template>
    <td v-if="type !== 'select'">
        <span v-if="!isEditing" @dblclick="startEditing">{{ value }}</span>
        <input v-else type="text" :name="value" class="form-control form-control-input text-center" @input="validateInput" @keyup.enter="finishEditing" @blur="finishEditing" @keydown="filterKey" v-model="inputValue">
        <div v-if="!isValid" class="error-message-tramas">
            Cantidad no permitida
        </div>
    </td>
    <td v-else>
        <span v-if="!isEditing" @dblclick="startEditing">{{ displayValue }}</span>
        <select v-else class="form-control form-control-sm" v-model="inputValue" @change="finishEditingSelect" @blur="finishEditingSelect">
            <option selected disabled value="NE_0002">Seleccione ...</option>
            <option v-for="option in options" :key="option.value" :value="option.value">{{ option.label }}</option>
        </select>
    </td>
</template>

<script>
export default {
    props: ['value', 'index', 'column', 'type','options'],
    data() {
        return {
            isEditing: false,
            inputValue: this.value,
            isValid: true
        };
    },
    computed: {
        displayValue() {
            const selectedOption = this.options.find(option => option.value === this.value);
            return selectedOption ? selectedOption.value : 'NE_0002';
        }
    },
    methods: {
        startEditing() {
            this.isEditing = true;
        },
        finishEditing() {
            if (this.isValid) {
                this.$emit('update-value', this.inputValue, this.index, this.column);
                this.isEditing = false;

            } else {
                this.isEditing = true;
            }
        },
        finishEditingSelect() {
            this.isEditing = false;
            this.$emit('update-value', this.inputValue, this.index,this.column);
        },
        validateInput(event) {
            let sanitizedValue = event.target.value.replace(/[^0-9.]/g, ''); // Elimina cualquier carácter no numérico o punto
            let max = this.column == 'suspended' ? 0 : 1;
            if (isNaN(sanitizedValue) || sanitizedValue === '' || sanitizedValue < max) {
                this.isValid = false;
                //this.inputValue = this.value; // Restablece el valor original si es menor a 0 o no válido
            } else {
                this.isValid = true;
            }
        },
        filterKey(event) {
            if (!/[0-9]/.test(event.key) && event.key !== 'Backspace') {
                event.preventDefault();
            }
        }
    }
};
</script>
