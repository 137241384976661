<template>
<div class="col-12">
    <div class="row d-flex">
        <div class="panel-border my-1">
            <div class="row d-flex text-xs">
                <div class="col-md-12 bg-main text-white text-center py-2">
                    <span class="fw-bold">MONITOREO - {{ typeService }}</span>
                </div>
                <!-- <div class="col-xl-9 col-lg-9 col-md-9 col-sm-12 col-xs-12 py-2">
                    <span class="fw-bold">DOCTOR: </span>
                    DOCTOR
                </div> -->
            </div>
        </div>
        <div class="border border-1 p-3" v-if="data.sheets.length > 0">
            <!-- paginado -->
            <div class="row">
                <!-- PAGINADO -->
                <div class="row d-flex justify-content-end">
                    <div class="col-12 px-2">
                        <nav aria-label="Page navigation example" class="pagination-container">
                            <ul class="pagination pagination-xs justify-content-end">
                                <li class="page-item">
                                    <a style="cursor: pointer" href="javascript:void(0)" class="page-link" @click="backPage">«</a>
                                </li>
                                <template>
                                    <li class="page-item">
                                        <a class="page-link active" href="javascript:void(0)">PAG. {{ pos + 1  }} - {{ cpDateName }}</a>
                                    </li>
                                </template>
                                <li class="page-item">
                                    <a style="cursor: pointer" class="page-link" @click="nextPage">»</a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 mt-3">
                    <!-- TABLA -->
                    <cTableSheet :sendSheet="sendSheet" :pos="pos" :pageOrigin="'report'"></cTableSheet>
                </div>
            </div>
        </div>

        <div class=" border border-main p-5" v-else>
            <div class=" text-center">
                <h4>No hay Monitoreo</h4>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import moment from "moment";
import cTableSheet from '@/components/Asistencial/UciAdult/modals/cTableSheet.vue';

export default {
    name: 'c-admision-medicalAct-ReportMonitoreoUCI',
    data() {
        return {
            pos: 0,
            sendSheet: [],
            dataSheets: [],

        }
    },
    components: {
        cTableSheet,
    },
    props: {
        data: Object,
        typeService: String,
    },
    watch: {
        data: {
            handler: function (newValue) {
                this.dataSheets = newValue.sheets; //todos lo graficos agrupado por dia
                if (this.dataSheets.length > 0) {
                    this.pos = this.dataSheets.length - 1;
                    this.sendSheet = this.dataSheets[this.pos];

                }
            },
            deep: true,
            immediate: true,
        }
    },
    computed: {
        cpDateName() {
            //this.datatime = ;
            if (this.data.dates.length > 0) {
                let date = this.data.dates[this.pos].day_page;
                return this.$options.filters.formatDate(date);
            }
        },
    },
    mounted() {

    },
    methods: {
        nextPage: function () {
            this.pos = (this.pos == this.dataSheets.length - 1) ? this.pos : this.pos + 1;
            this.sendSheet = this.dataSheets[this.pos];
        },

        backPage: function () {
            this.pos = (this.pos == 0) ? this.pos : this.pos - 1;
            this.sendSheet = this.dataSheets[this.pos];

        },
        returnHome() {
            this.$emit('mtdChangePage', 'home');
        },
    },
    filters: {
        formatDate(date) {
            return date != null ? moment(date).format("DD/MM/YYYY") : "-";
        },
        formatTime(time) {
            let arTime = time.split(":");
            if (arTime[0] <= 12) {
                return time + " am";
            } else {
                return arTime[0] - 12 + ":" + arTime[1] + " pm";
            }
        },
    },

}
</script>
