<template>
  <div>
    <div class="w-100 h-100">
      <Nav @rolname="rolname" />
    </div>
    <div class="c-app h-100">
      <div class="container">
        <div class="row" id="div_conte2">
          <div id="div_conte" class="col-md-8 mx-auto">
            <div class="bg-secondary text-white bg-opacity-10">
              <div class="text-center py-4 px-5" id="scroll">
                <div class="row">
                    <h4 class="text-dark fw-bold">SELECCIONA UN ROL</h4>
                </div>
                <div id="contenedorPadre" class="row d-flex">
                  <div v-for="(item, index) in cpShowRoles" :key="index" class="col-md-4">
                    <div class="divInterior">
                      <button class="div-rol col m-2 p-2 text-white fw-bold"
                        @click="mtdSelectRol(item.role.id, item.role.name)"
                      >
                        {{ item.role.name }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Nav from "../../components/Asistencial/Nav.vue";
export default {
  name: "v-rol",
  components: {
    Nav,
  },
  data () {
    return {
      rolname: '',
    }
  },
  props: {
    dataRoles: [],
  },
  computed:{
    cpShowRoles(){
      //v-if="item.role.id == 1 || item.role.id == 5 || item.role.id == 6 || item.role.id == 7 || item.role.id == 9 || item.role.id == 10"
      const dataRoles = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19];
      return this.dataRoles.filter(rol => dataRoles.includes(rol.role.id));
    }
  },
  methods: {
    mtdSelectRol: function (id, name_rol) {
      this.$emit("mtdSelectRol", id, name_rol);
      this.rolname = name_rol;
    },
    
  },
};
</script>

