<template>
<CModal class="bg-white p-0" @mtdclosemodal="mtdclosemodal" :show="boo_modal" :close-on-backdrop="false" :centered="true" :title="title" :size="'sm'" :btn_cancel="true" :btn_ok="true" :booTitile="true" :full-screen="true">
    <div class="px-2">
        <div class="row d-flex">
            <div class="col-md-12 col-lg-12 col-xs-12 mb-3">
                <div class="row mt-2 d-flex" v-if="type == 'INGRESO' || type == 'EGRESO'">
                    <div class="col-md-3 col-sm-12 col-lg-3 mb-2">
                        <div class="input-group input-group-sm">
                            <span class="input-group-text">Sede</span>
                            <select id="campu_id" class="form-control form-control" v-model="filterModal.campu_id" @change="mtdSelectCampusToBox">
                                <option selected value="0" disabled>Seleccione ...</option>
                                <option v-for="(item, index) in data.dataCampus" :key="index" :value="item.id">
                                    {{ item.name }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-3 col-sm-12 col-lg-3 mb-3">
                        <div class="input-group input-group-sm">
                            <span class="input-group-text">Caja</span>
                            <select id="bead_id" class="form-control form-control" v-model="filterModal.bead_id" @change="mtdDataReport">
                                <option selected value="0" disabled>Seleccione ...</option>
                                <option v-for="(item, index) in dataBeadsList" :key="index" :value="item.id">
                                    {{ item.name }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-3 col-sm-6 col-lg-2 mb-2">
                        <div class="input-group input-group-sm">
                            <span class="input-group-text">Desde</span>
                            <input type="date" aria-label="shift_date" class="form-control form-control-sm" id="begindate" v-model="filterModal.begindate" @change="mtdDataReport" />
                        </div>
                    </div>

                    <div class="col-md-3 col-sm-6 col-lg-2 mb-2">
                        <div class="input-group input-group-sm">
                            <span class="input-group-text">Hasta</span>
                            <input type="date" aria-label="shift_date" class="form-control form-control-sm" id="enddate" v-model="filterModal.enddate" @change="mtdDataReport" />
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-2 col-sm-12" v-if="type.length > 0">
                        <button type="button" name="accion" class="btn btn-sm mb-3 w-100 bg-main text-white" @click="exportExcel">
                            <i class="fas fa-file-excel"></i> Exportar
                        </button>
                    </div>
                </div>
                <div class="row mt-2 d-flex">
                    <div class="col-md-6 col-sm-12" v-if="type == 'INGRESO'">
                        <div class="mb-3">
                            <label class="form-label">Categoría</label>
                            <select class="form-control form-control-sm" v-model="filterModal.categorie">
                                <option value="0" selected>[Todas las categorias..]</option>
                                <option v-for="(item, index) in categories" :key="index" :value="item.id">
                                    {{ item.label }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12" v-if="type == 'INGRESO'">
                        <label>Tipo de paciente</label>
                        <br />
                        <div class="form-check form-check-inline mt-2">
                            <input class="form-check-input" type="checkbox" v-model="filterModal.eps" />
                            <label class="form-check-label">EPS</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="checkbox" v-model="filterModal.particular" />
                            <label class="form-check-label">PARTICULAR</label>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
        <div class="card-body w-100">
            <div class="row d-flex">
                <div v-if="type == 'INGRESO'" class="col-md-12" id="content-timelinetable" style="height: 500px;
            overflow-y: auto;">
                    <table class="table table-bordered border-main">
                        <thead>
                            <tr class="text-white border-white text-center bg-main text-xxs">
                                <th style="vertical-align: middle" scope="col">N°</th>
                                <th style="vertical-align: middle" scope="col">ESTADO</th>
                                <th style="vertical-align: middle" scope="col">ADM</th>
                                <th style="vertical-align: middle" scope="col">SEDE</th>
                                <th style="vertical-align: middle" scope="col">FECHA</th>
                                <th style="vertical-align: middle" scope="col">COMPROBANTE</th>
                                <th style="vertical-align: middle" scope="col">A NOMBRE DE</th>
                                <th style="vertical-align: middle" scope="col">SERIE</th>
                                <th style="vertical-align: middle" scope="col">NÚMERO</th>
                                <th style="vertical-align: middle" scope="col">PACIENTE TIPO</th>
                                <th style="vertical-align: middle" scope="col">CONVENIO</th>
                                <th style="vertical-align: middle" scope="col">%</th>
                                <th style="vertical-align: middle" scope="col">
                                    INFORMACIÓN DEL PACIENTE
                                </th>
                                <th style="vertical-align: middle" scope="col">CATEGORÍA</th>
                                <th style="vertical-align: middle" scope="col">
                                    SERVICIO ESPECÍFICO
                                </th>
                                <th style="vertical-align: middle" scope="col">CANTIDAD</th>
                                <th style="vertical-align: middle" scope="col">PRECIO UNI</th>
                                <th style="vertical-align: middle" scope="col">IGV</th>
                                <th style="vertical-align: middle" scope="col">SUB-TOTAL SIN IGV</th>
                                <th style="vertical-align: middle" scope="col">SUB TOTAL</th>
                                <th style="vertical-align: middle" scope="col">MEDIO DE PAGO</th>
                                <th style="vertical-align: middle" scope="col">DOCUMENTO</th>
                            </tr>
                        </thead>
                        <tbody class="text-xs" v-html="cpTableBodyModal"></tbody>
                    </table>
                </div>
                <div v-if="this.type == 'EGRESO'" class="col-md-12" id="content-timelinetable" style="height: 500px;
            overflow-y: auto;">
                    <table class="table table-responsive table-bordered border-main" aria-describedby="tb-egreso">
                        <thead>
                            <tr class="text-white border-white text-center bg-main text-xxs">
                                <th scope="col">N°</th>
                                <th scope="col">ESTADO</th>
                                <th scope="col">ADM</th>
                                <th scope="col">SEDE</th>
                                <th scope="col">FECHA EGRESO</th>
                                <th scope="col">FECHA DE COMPROBANTE</th>
                                <th scope="col">TIPO COMPROBANTE</th>
                                <th scope="col">SERIE</th>
                                <th scope="col">NÚMERO</th>
                                <th scope="col">RAZÓN SOCIAL</th>
                                <th scope="col">RUC / DNI</th>
                                <th scope="col">TOTAL</th>
                                <th scope="col">MONTO PAGADO</th>
                                <th scope="col">PENDIENTE</th>
                                <th scope="col">MEDIO DE PAGO</th>
                                <th scope="col">MOTIVO, DESCRIPCIÓN O DETALLE DE COMPRA</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="text-xxs" v-for="(item2, index) in dataReport" :key="index">
                                <th scope="row">{{ index + 1 }}</th>
                                <td>
                                    {{ item2.state == 1 ? 'PENDIENTE DE APROBACIÓN' : (item2.state == 2 || item2.state == 4 ? 'APROBADO' : 'RECHAZADO') }}
                                </td>
                                <td>
                                    {{ item2.bead ? item2.bead.user.last_name : '' }}
                                    {{ item2.bead ? item2.bead.user.name : '' }}
                                </td>
                                <td>{{ item2.campu.name }}</td>
                                <td>{{ item2.date | formatDate }}</td>
                                <td>
                                    {{
                              item2.date_doc == null
                              ? item2.date
                              : item2.date_doc | formatDate
                            }}
                                </td>
                                <td>
                                    {{
                              item2.document_type_id != null
                              ? item2.document_type.name
                                        : item2.modulo == 'receipt'?"RECIBO POR HONORARIOS":"DOC. INTERNO",
                            }}
                                </td>
                                <td>{{ item2.serie == null ? "-" : item2.serie }}</td>
                                <td>
                                    {{ item2.number == null ? "-" : item2.number }}
                                </td>
                                <td>{{ item2.modulo == 'passage' ? item2.passage.name : (item2.modulo == 'retiro' ? item2.bead.user.last_name + 
                          " "+item2.bead.user.name:(item2.modulo =='receipt'? item2.receipt.name:item2.company )) }}</td>
                                <td>{{ item2.modulo == 'passage' ? item2.passage.document : (item2.modulo == 'retiro' ? item2.bead.user.dni : (
                            item2.modulo == 'receipt' ? item2.receipt.document : item2.document)) }}</td>
                                <td>{{ item2.total }}</td>
                                <td>{{ item2.amount }}</td>
                                <td>{{ item2.pending }}</td>
                                <td>{{ item2.method }}</td>
                                <td>{{ item2.description }}</td>
                            </tr>
                            <tr v-if="dataReport.length == 0">
                                <td colspan="18" class="text-center text-xs">NO HAY REGISTROS PARA MOSTRAR</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <SweetAlert :swal="swal" ref="SweetAlert" />
</CModal>
</template>

<script>
import CModal from "@/components/shared/modals/CModal.vue";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import moment from 'moment';
import exportFromJson from "export-from-json";
import {
    mapActions
} from "vuex";

export default {
    name: "c-admision-cerra-modal-egresoIngreso",
    components: {
        CModal,
        SweetAlert
    },
    data() {
        return {
            swal: null,
            filterModal: {
                campu_id: 0,
                bead_id: 0,
                begindate: null,
                enddate: null,
                eps: true,
                particular: true,
                categorie: 0,
                subcategorie: 0,
            },
            categories: [],
            dataReport: [],
            excelIncome: [],
            excelExpense: [],
            dataBeadsList: [],
        };
    },
    props: {
        title: String,
        boo_modal: Boolean,
        item: {},
        bead: {},
        type: String,
        data: Object,
        close: Number
    },
    computed: {
        cpSubcategoriesModal() {
            let view = [];
            this.subcategoriesModal.forEach((element) => {
                if (this.filterModal.categorie == element.categorie) {
                    view.push(element);
                }
            });
            this.filterModal.subcategorie = 0;
            return view;
        },
        cpTableBodyModall() {
            let html = "";
            let boo = true; // true => si hay filtro
            if (this.filterModal.categorie == 0 && this.filterModal.subcategorie == 0) {
                boo = false;
            }
            let temp = [];
            if (this.filterModal.eps == true && this.filterModal.particular == true) {
                temp = this.dataReport;
            } else if (this.filterModal.eps == true && this.filterModal.particular == false) {
                temp = this.dataReport.filter(function (el) {
                    return el.voucher.sale.type_patient != "particular";
                });
            } else if (this.filterModal.eps == false && this.filterModal.particular == true) {
                temp = this.dataReport.filter(function (el) {
                    return el.voucher.sale.type_patient == "particular";
                });
            } else {
                temp = [];
            }
            if (temp.length == 0) {
                html =
                    "<tr><td colspan='18' align='center'>NO HAY REGISTROS PARA MOSTRAR</td><tr>";
            } else {
                temp.forEach((element, index) => {
                    let model = element.voucher.sale.detail ?
                        element.voucher.sale.detail[0].subsub_categorie.subategorie.categorie.name :
                        "-";
                    /*
                    let ssEspecifico = element.voucher.sale.detail
                      ? element.voucher.sale.detail[0].subsub_categorie.name
                      : "-";
                    */
                    let tdContent = [];
                    for (let i = 0; i < element.voucher.sale.detail.length; i++) {
                        let ssEspecifico = element.voucher.sale.detail[i] ?
                            element.voucher.sale.detail[i].subsub_categorie.name :
                            "-";
                        tdContent.push(ssEspecifico);
                    };
                    let insurance =
                        element.voucher.sale.insurance != null ?
                        element.voucher.sale.insurance.name :
                        "-";
                    let itemHtml =
                        "<tr>" +
                        "<td>" +
                        (index + 1) +
                        "</td>" +
                        "<td>" +
                        (element.voucher.state == 1 ? "ACTIVO" : "ANULADO") +
                        "</td>" +
                        "<td>" +
                        element.voucher.bead.user.last_name +
                        " " +
                        element.voucher.bead.user.name +
                        "</td>" +
                        "<td>" +
                        element.voucher.sale.campu.name +
                        "</td>" +
                        "<td>" +
                        element.voucher.date +
                        "</td>" +
                        "<td>" +
                        element.voucher.document +
                        "</td>" +
                        "<td>" +
                        element.voucher.vaucher_document +
                        "-" +
                        element.voucher.vaucher_fullname +
                        "</td>" +
                        "<td align='center'>" +
                        element.voucher.serie +
                        "</td>" +
                        "<td align='center'>" +
                        element.voucher.number +
                        "</td>" +
                        "<td>" +
                        element.voucher.sale.type_patient +
                        "</td>" +
                        "<td>" +
                        insurance +
                        "</td>" +
                        "<td>" +
                        element.voucher.sale.payment_patient +
                        "</td>" +
                        "<td>" +
                        element.voucher.sale.patient.name +
                        "</td>" +
                        "<td>" +
                        model +
                        "</td>" +
                        "<td>" +
                        tdContent.join(",") +
                        "</td>" +
                        "<td>" +
                        (element.voucher.state === 2 ? "0.00" : element.amount) +
                        "</td>" +
                        "<td>" +
                        element.method +
                        "</td>" +
                        // +"<td>"+element.voucher.sale.billing_period+"</td>"
                        "</tr>";

                    if (!boo) {
                        html += itemHtml;
                    } else {
                        // if (this.filterModal.subcategorie != 0) {
                        //   if(element.voucher.sale.detail[0].subsub_categorie.id != 0 &&  element.voucher.sale.detail[0].subsub_categorie.id == this.filterModal.subcategorie){
                        //     html += itemHtml;
                        //   }
                        // }else{
                        if (
                            element.voucher.sale.detail[0].subsub_categorie.subategorie.categorie.id !=
                            0 &&
                            element.voucher.sale.detail[0].subsub_categorie.subategorie.categorie.id ==
                            this.filterModal.categorie
                        ) {
                            html += itemHtml;
                        }
                        // }
                    }
                });
            }
            return html;
        },
        cpTableBodyModal() {
            let html = "";
            let boo = true; // true => si hay filtro
            if (
                this.filterModal.categorie == 0 &&
                this.filterModal.subcategorie == 0
            ) {
                boo = false;
            }
            let temp = [];
            if (this.filterModal.eps == true && this.filterModal.particular == true) {
                temp = this.dataReport;
            } else if (
                this.filterModal.eps == true &&
                this.filterModal.particular == false
            ) {
                temp = this.dataReport.filter(function (el) {
                    return el.type_patient != "particular";
                });
            } else if (
                this.filterModal.eps == false &&
                this.filterModal.particular == true
            ) {
                temp = this.dataReport.filter(function (el) {
                    return el.type_patient == "particular";
                });
            } else {
                temp = [];
            }
            if (temp.length == 0) {
                html =
                    "<tr><td colspan='22' align='center'>NO HAY REGISTROS PARA MOSTRAR</td><tr>";
            } else {
                temp.forEach((element, index) => {
                    let model = element.category_name;
                    let price = element.state === 2 ? 0.00 : element.validation == 0 ? element.price : (element.price - parseFloat(element.monto_desc/element.quantity)) ;
                    let sub_total = (element.quantity * price).toFixed(2);
                    let igv = (sub_total - (sub_total/ 1.18)).toFixed(2);
                    let sin_igv = (sub_total/1.18).toFixed(2);
                    let insurance =
                        element.insurance_name != null ?
                        element.insurance_name :
                        "Particular";
                    let ticket = JSON.parse(element.sunat);
                    let enlace = ticket.json.enlace_del_pdf_ticket;
                    let dateFormat = element.date != null ? moment(element.date).format("DD/MM/YYYY") : "";
                    let itemHtml =
                        "<tr class='tbody-table'>" +
                        "<td>" +
                        (index + 1) +
                        "</td>" +
                        "<td>" +
                        (element.state == 1 ? "ACTIVO" : "ANULADO") +
                        "</td>" +
                        "<td>" +
                        element.last_name +
                        " " +
                        element.user_name +
                        "</td>" +
                        "<td>" +
                        element.campus_name +
                        "</td>" +
                        "<td>" +
                        dateFormat +
                        "</td>" +
                        "<td>" +
                        element.document +
                        "</td>" +
                        "<td>" +
                        element.vaucher_document +
                        "-" +
                        element.vaucher_fullname +
                        "</td>" +
                        "<td align='center'>" +
                        element.serie +
                        "</td>" +
                        "<td align='center'>" +
                        element.number +
                        "</td>" +
                        "<td>" +
                        element.type_patient +
                        "</td>" +
                        "<td>" +
                        insurance +
                        "</td>" +
                        "<td>" +
                        element.payment_patient +
                        "</td>" +
                        "<td>" +
                        element.patient_name +
                        "</td>" +
                        "<td>" +
                        model +
                        "</td>" +
                        "<td>" +
                        element.subsubcategory_name +
                        "</td>" +
                        "<td>" +
                        element.quantity +
                        "</td>" +
                        "<td>" +
                        parseFloat(price).toFixed(2) +
                        "</td>" +
                        "<td>" +
                        igv +
                        "</td>" +
                        "<td>" +
                        sin_igv +
                        "</td>" +
                        "<td>" +
                        sub_total +
                        "</td>" +
                        "<td>" +
                        (element.method ? element.method : '-') +
                        "</td>" +
                        "<td>" +
                        "<a id='btn-check'  style='margin-left: 5px; font-weight: bold; text-decoration: none;' href='" +
                        enlace +
                        "' target='_blank'>" +
                        "<i class='fas fa-file-pdf'></i><span  style='font-size: 13px;'> VER DOC.</span>" +
                        "</a>" +
                        "</td>" +
                        "</tr>";

                    if (!boo) {
                        html += itemHtml;
                    } else {
                        if (
                            element.cat_id != 0 &&
                            element.cat_id == this.filterModal.categorie
                        ) {
                            html += itemHtml;
                        }
                        // }
                    }
                });
            }
            return html;
        },
    },
    watch: {
        title() {
            this.mtdDate();
        },
    },
    methods: {
        ...mapActions(["get", "post"]),
        mtdDate: function () {
            const currentDate = new Date();
            const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
            const today = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
            this.filterModal.begindate = firstDayOfMonth.toISOString().split('T')[0];
            this.filterModal.enddate = today.toISOString().split('T')[0];
        },
        mtdSelectCampusToBox: function () {
            this.dataBeadsList = [];
            this.data.dataCampus.forEach((element) => {
                if (element.id == this.filterModal.campu_id) {
                    this.dataBeadsList = element.boxes;
                }
            });
        },

        /*
        mtdDataReport: function () {
            if (this.filterModal.campu_id == 0) {
                Swal.fire({
                    text: "Escoja una sede!!",
                    icon: "warning",
                    confirmButtonColor: "#900052",
                });
                return;
            }
            if (this.filterModal.bead_id == 0) {
                Swal.fire({
                    text: "Escoja una caja!!",
                    icon: "warning",
                    confirmButtonColor: "#900052",
                });
                return;
            }
            this.get({
                    url: this.$store.getters.get__url +
                        "/ReportesCajas/reportecajaRe?type=" +
                        this.type +
                        "&campu_id=" +
                        this.filterModal.campu_id +
                        "&bead_id=" +
                        this.filterModal.bead_id +
                        "&begindate=" +
                        this.filterModal.begindate +
                        "&enddate=" +
                        this.filterModal.enddate,
                    token: this.$store.getters.get__token,
                    //params: this.bead,
                })
                .then((response) => {
                    this.dataReport = response.data;
                    this.filterModal.categorie = 0;
                    this.filterModal.subategorie = 0;
                    this.excelExpense = [];
                    this.excelIncome = [];
                    this.dataReport.forEach((element, index) => {
                        if (this.type != "EGRESO") {
                            let model = element.voucher.sale.detail ?
                                element.voucher.sale.detail[0].subsub_categorie.subategorie.categorie
                                .name :
                                "-";
                            let tdContent = [];
                            for (let i = 0; i < element.voucher.sale.detail.length; i++) {
                                let ssEspecifico = element.voucher.sale.detail[i] ?
                                    element.voucher.sale.detail[i].subsub_categorie.name :
                                    "-";
                                tdContent.push(ssEspecifico);
                            };
                            let insurance =
                                element.voucher.sale.insurance != null ?
                                element.voucher.sale.insurance.name :
                                "-";
                            this.excelIncome.push({
                                "N°": index + 1,
                                ESTADO: element.voucher.state == 1 ? "ACTIVO" : "ANULADO",
                                ADM: element.voucher.bead.user.last_name +
                                    " " +
                                    element.voucher.bead.user.name,
                                SEDE: element.voucher.sale.campu.name,
                                FECHA: element.voucher.date,
                                COMPROBANTE: element.voucher.document,
                                "A NOMBRE DE": element.voucher.vaucher_document +
                                    " " +
                                    element.voucher.vaucher_fullname,
                                SERIE: element.voucher.serie,
                                NÚMERO: element.voucher.number,
                                "PACIENTE TIPO": element.voucher.sale.type_patient,
                                CONVENIO: insurance,
                                "%": element.voucher.sale.payment_patient,
                                "INFORMACIÓN DEL PACIENTE": element.voucher.sale.patient.name,
                                CATEGORÍA: model,
                                "SERVICIO ESPECÍFICO": tdContent.join(","),
                                MONTO: (element.voucher.state === 2 ? "0.00" : element.amount),
                                "MEDIO DE PAGO": element.method,
                                // 'PER. FACTURACIÓN':element.voucher.sale.billing_period,
                            });
                        } else {
                            this.excelExpense.push({
                                "N°": index + 1,
                                ESTADO: element.state == 1 ?
                                    "PENDIENTE DE APROBACIÓN" : element.state == 2 ?
                                    "APROBADO" : "RECHAZADO",
                                ADMISIONISTA: element.bead.user.last_name + " " + element.bead.user.name,
                                SEDE: element.campu.name,
                                "FECHA DE EGRESO": element.date,
                                "FECHA DE COMPROBANTE": element.date_doc == null ? element.date : element.date_doc,
                                "TIPO DE COMPROBANTE": element.document_type_id != null ?
                                    element.document_type.name : element.modulo == "receipt" ?
                                    "RECIBO POR HONORARIOS" : "DOC. INTERNO",
                                "NÚMERO DE COMPROBANTE": element.modulo == "passage" ?
                                    "PLANILLA-PASAJES" : element.number ?
                                    element.serie + "-" + element.number : element.serie ?
                                    element.serie : "-",
                                "RAZON SOCIAL/NOMBRE": element.modulo == "passage" ?
                                    element.passage.name : element.modulo == "retiro" ?
                                    element.bead.user.last_name + " " + element.bead.user.name : element.modulo == "receipt" ?
                                    element.receipt.name : element.company,
                                "RUC/DNI": element.modulo == "passage" ?
                                    element.passage.document : element.modulo == "retiro" ?
                                    element.bead.user.dni : element.modulo == "receipt" ?
                                    element.receipt.document : element.document,
                                TOTAL: element.total,
                                "MONTO PAGADO": element.amount,
                                PENDIENTE: element.pending,
                                "MEDIO DE PAGO": element.method,
                                "MOTIVO, DESCRIPCION O DETALLE DE COMPRA": element.description,
                            });
                        }
                    });

                    this.mtdSetCategoriesModal();
                    this.mtdSetSubcategoriesModal();
                })
                .catch((errors) => {});
        },
        mtdSetCategoriesModal: function () {
            let str = "";
            this.dataReport.forEach((element) => {
                element.voucher.sale.detail.forEach((ele) => {
                    if (!str.includes("," + ele.subsub_categorie.subategorie.categorie.id + ",")) {
                        this.categoriesModal.push({
                            label: ele.subsub_categorie.subategorie.categorie.name,
                            id: ele.subsub_categorie.subategorie.categorie.id,
                        });
                        str += "," + ele.subsub_categorie.subategorie.categorie.id + ",";
                    }
                });
            });
        },
        mtdSetSubcategoriesModal: function () {
            let str = "";
            this.dataReport.forEach((element) => {
                element.voucher.sale.detail.forEach((ele) => {
                    if (!str.includes("," + ele.subsub_categorie.subategorie.id + ",")) {
                        this.subcategoriesModal.push({
                            label: ele.subsub_categorie.subategorie.name,
                            id: ele.subsub_categorie.subategorie.id,
                            categorie: ele.subsub_categorie.subategorie.categorie.id,
                        });
                        str += "," + ele.subsub_categorie.subategorie.id + ",";
                    }
                });
            });
        },
        exportExcel: function () {
            let nameCategoria = ''
            let dataExport = []
            if (this.filterModal.categorie != 0 && this.type == "INGRESO") {
                this.categories.forEach((item) => {
                    if (item.id == this.filterModal.categorie) {
                        nameCategoria = item.label;
                    }
                });
                this.excelIncome.forEach((categoria) => {
                    if (categoria.CATEGORÍA == nameCategoria) {
                        dataExport.push(categoria)
                    }

                })
                this.excelIncome = dataExport
            }
            const datos = this.type == "INGRESO" ? this.excelIncome : this.excelExpense;
            const fileName = this.type;
            const typeExport = exportFromJson.types.xls;
            exportFromJson({
                data: datos,
                fileName: fileName,
                exportType: typeExport,
            });
            this.dataReport = []
        },
        */
        mtdDataReport: function () {
            if (this.filterModal.campu_id == 0) {
                Swal.fire({
                    text: "Escoja una sede!!",
                    icon: "warning",
                    confirmButtonColor: "#900052",
                });
                return;
            }
            if (this.filterModal.bead_id == 0) {
                Swal.fire({
                    text: "Escoja una caja!!",
                    icon: "warning",
                    confirmButtonColor: "#900052",
                });
                return;
            }
            this.get({
                    url: this.$store.getters.get__url +
                        "/ReportesCajas/reportecajaRe?type=" +
                        this.type +
                        "&campu_id=" +
                        this.filterModal.campu_id +
                        "&bead_id=" +
                        this.filterModal.bead_id +
                        "&begindate=" +
                        this.filterModal.begindate +
                        "&enddate=" +
                        this.filterModal.enddate +
                        "&close=" + this.close,
                    token: this.$store.getters.get__token,
                })
                .then((response) => {
                    this.dataReport = response.data;
                    this.filterModal.categorie = 0;
                    this.filterModal.subategorie = 0;
                    this.excelIncome = [];
                    this.excelExpense = [];
                    /* EXPORTAR A EXCEL*/
                    this.dataReport.forEach((element, index) => {
                        if (this.type != "EGRESO") {
                            let model = element.category_name !=
                                null ? element.category_name :
                                "-";
                            let insurance = element.insurance_name != null ?
                                element.insurance_name : "Particular";
                            let price = element.state === 2 ? 0.00 : element.validation == 0 ? element.price : (element.price - parseFloat(element.monto_desc/element.quantity)) ;
                            let sub_total = (element.quantity * price).toFixed(2);
                            let igv = (sub_total - (sub_total/ 1.18)).toFixed(2);
                            let sin_igv = (sub_total/1.18).toFixed(2);
                            let dateFormat = element.date != null ? moment(element.date).format("DD/MM/YYYY") : "";
                            this.excelIncome.push({
                                "N°": index + 1,
                                "ESTADO": element.state == 1 ? "ACTIVO" : "ANULADO",
                                "ADMISIONISTA": element.last_name +
                                    " " +
                                    element.user_name,
                                "SEDE": element.campus_name,
                                "FECHA": dateFormat,
                                "COMPROBANTE": element.document,
                                "A NOMBRE DE": element.vaucher_document +
                                    "-" +
                                    element.vaucher_fullname,
                                "SERIE": element.serie,
                                "NÚMERO": element.number,
                                "PACIENTE TIPO": element.type_patient,
                                "CONVENIO": insurance,
                                "%": element.payment_patient,
                                "INFORMACIÓN DEL PACIENTE": element.patient_name,
                                "CATEGORÍA": model,
                                "SERVICIO ESPECÍFICO": element.subsubcategory_name,
                                "CANTIDAD": element.quantity,
                                "PRECIO": price,
                                "IGV": igv,
                                "SUBTOTAL SIN IGV": sin_igv,
                                "SUBTOTAL": sub_total,
                                "MEDIO DE PAGO": element.method ? element.method : '-',
                            });
                        } else {
                            this.excelExpense.push({
                                "N°": index + 1,
                                ESTADO: element.state == 1 ?
                                    "PENDIENTE DE APROBACIÓN" : element.state == 2 ?
                                    "APROBADO" : "RECHAZADO",
                                ADMISIONISTA: element.bead.user.last_name + " " + element.bead.user.name,
                                SEDE: element.campu.name,
                                "FECHA DE EGRESO": element.date,
                                "FECHA DE COMPROBANTE": element.date_doc == null ? element.date : element.date_doc,
                                "TIPO DE COMPROBANTE": element.document_type_id != null ?
                                    element.document_type.name : element.modulo == "receipt" ?
                                    "RECIBO POR HONORARIOS" : "DOC. INTERNO",
                                "NÚMERO DE COMPROBANTE": element.modulo == "passage" ?
                                    "PLANILLA-PASAJES" : element.number ?
                                    element.serie + "-" + element.number : element.serie ?
                                    element.serie : "-",
                                "RAZON SOCIAL/NOMBRE": element.modulo == "passage" ?
                                    element.passage.name : element.modulo == "retiro" ?
                                    element.bead.user.last_name + " " + element.bead.user.name : element.modulo == "receipt" ?
                                    element.receipt.name : element.company,
                                "RUC/DNI": element.modulo == "passage" ?
                                    element.passage.document : element.modulo == "retiro" ?
                                    element.bead.user.dni : element.modulo == "receipt" ?
                                    element.receipt.document : element.document,
                                TOTAL: element.total,
                                "MONTO PAGADO": element.amount,
                                PENDIENTE: element.pending,
                                "MEDIO DE PAGO": element.method,
                                "MOTIVO, DESCRIPCION O DETALLE DE COMPRA": element.description,
                            });
                        }
                    });
                    this.mtdSetCategoriesModal();
                    this.mtdSetSubcategoriesModal();
                })
                .catch((errors) => {});
        },
        mtdSetCategoriesModal: function () {
        this.categoriesModal = [];
            let str = "";
            if (this.type == 'EGRESO') {
                this.dataReport.forEach((element) => {
                    element.voucher.sale.detail.forEach((ele) => {
                        if (
                            !str.includes(
                                "," + ele.subsub_categorie.subategorie.categorie.id + ","
                            )
                        ) {
                            this.categoriesModal.push({
                                label: ele.subsub_categorie.subategorie.categorie.name,
                                id: ele.subsub_categorie.subategorie.categorie.id,
                            });
                            str += "," + ele.subsub_categorie.subategorie.categorie.id + ",";
                        }
                    });
                });
            } else {
                const uniqueCategoriesSet = new Set();
                this.dataReport.forEach(element => {                 ;
                    const uniqueKey = `${element.category_name}-${element.cat_id}`;
                    if (!uniqueCategoriesSet.has(uniqueKey)) {
                        uniqueCategoriesSet.add(uniqueKey);
                        this.categoriesModal.push({
                            label: element.category_name,
                            id: element.cat_id
                        });
                    }
                });
            }
        },
        mtdSetSubcategoriesModal: function () {
            let str = "";
                this.dataReport.forEach((element) => {
                    element.voucher.sale.detail.forEach((ele) => {
                        if (!str.includes("," + ele.subsub_categorie.subategorie.id + ",")) {
                            this.subcategoriesModal.push({
                                label: ele.subsub_categorie.subategorie.name,
                                id: ele.subsub_categorie.subategorie.id,
                                categorie: ele.subsub_categorie.subategorie.categorie.id,
                            });
                            str += "," + ele.subsub_categorie.subategorie.id + ",";
                        }
                    });
                });
            
        },
        exportExcel: function () {
            const datos =
                this.type == "INGRESO" ? this.excelIncome : this.excelExpense;
            const fileName = this.type;
            const typeExport = exportFromJson.types.xls;
            exportFromJson({
                data: datos,
                fileName: fileName,
                exportType: typeExport,
            });
        },
        
        FormatJson(FilterData, JsonData) {
            return JsonData.map((v) =>
                FilterData.map((j) => {
                    return v[j];
                })
            );
        },
        mtdclosemodal() {
            this.filterModal = {
                campu_id: 0,
                bead_id: 0,
                begindate: null,
                enddate: null,
                eps: true,
                particular: true,
                categorie: 0,
                subcategorie: 0,
            };
            this.dataReport = [];
            this.dataBeadsList = [];
            this.excelExpense = [];
            this.excelIncome = [];
            this.$emit('mtdclosemodal')
        }
    },
    filters: {
        formatDate(date) {
            return date != null ? moment(date).format("DD/MM/YYYY") : "-";
        },
        formatTime(time) {
            let arTime = time.split(":");
            if (arTime[0] <= 12) {
                return time + " am";
            } else {
                return arTime[0] - 12 + ":" + arTime[1] + ":" + arTime[2] + " pm";
            }
        },
    },
};
</script>
