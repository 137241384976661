<template>
<CModal @mtdclosemodal="mtdClose" :show.sync="boo_modal" :close-on-backdrop="false" :centered="true" :title="title" :size="'xl'" :btn_cancel="true" :btn_ok="true" :booTitile="true" :scrollable="true">
    <div class="row px-3">
        <div class="col-md-12 col-lg-12 col-xs-12 text-center">
            <table class="table table-bordered table-striped">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Cantidad</th>
                        <th>Categoria</th>
                        <th>Servicio</th>
                        <th>Precio</th>
                        <th>Subtotal</th>
                        <th>Estado</th>
                        <th v-if="permissionEli == 1">Eliminar</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(det, index) in cpData" :key="index">
                        <template v-if="det.subsub_categorie_id != 13061">
                            <td>{{ index + 1 }}</td>
                            <td>
                                {{ det.quantity }}
                            </td>
                            <td>{{ det.subsub_categorie.subategorie.categorie.name }}</td>
                            <td>{{ det.subsub_categorie.name }}</td>
                            <td v-if="posEditPrice !== index" @dblclick="mtdEditPrecies(index)">
                                {{ det.price }}
                            </td>
                            <td v-else>
                                <template>
                                    <input :disabled="det.state === 1" type="text" class="form-control form-control-sm text-center" v-model="newPrice" @keyup.enter="mtdOutFocusEditPrices" @blur="mtdOutFocusEditPrices" @keypress="validateNumberInput" />
                                </template>
                                <!-- <template v-else>
                    <span @click="showNoPermissionAlert">{{ det.price }}</span>
                  </template> -->
                            </td>
                            <td>{{ parseFloat(det.quantity * det.price).toFixed(2) }}</td>
                            <td>
                                <div v-if="det.state == 2 || det.state == 3">
                                    <span class="badge rounded-pill text-bg-danger">Por Pagar</span>
                                </div>
                                <div v-else>
                                    <span class="badge rounded-pill text-bg-success">Pagado</span>
                                </div>
                            </td>
                            <td v-if="permissionEli == 1">
                                <div v-if="det.state == 2 || det.state == 3">
                                    <button @click="mtdDelCarrito(det)" class="btn btn-sm btn-danger">
                                        <i class="fas fa-trash"></i>
                                    </button>
                                </div>
                            </td>
                        </template>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <template #footer>
        <button @click="mtdClose" color="danger" class="btn btn-danger btn-sm">
            <i class="fa fa-times" />&nbsp;Cerrar
        </button>
        <!-- &nbsp;
      <button class="btn btn-success btn-sm" @click="mtdEditValor">
        <i class="fas fa-save" />&nbsp; Editar
      </button> -->
        &nbsp;
    </template>
    <SweetAlert :swal="swal" ref="SweetAlert" />
</CModal>
</template>

<script>
import CModal from "../../shared/modals/CModal.vue";
import CButtonClose from "../../shared/modals/cButtonClose.vue";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import {
    mapActions
} from 'vuex';

export default {
    name: "c-modalEditCarrito",
    components: {
        CModal,
        CButtonClose,
        SweetAlert,
    },
    data() {
        return {
            ind: null,
            end: null,
            swal: null,
            total: 0,
            posEditQuantity: null,
            newQuantity: null,
            oldQuantity: null,
            tabla: [],
            posEditPrice: null,
            newPrice: null,
            oldPrice: null,
            //Quitar Permiso
            permissionValue: 0,
        };
    },
    computed: {
        cpData() {
            let show = [];
            this.item.forEach((element) => {
                // if (element.subsub_categorie_id != 13061) {
                show.push(element);
                // }
            });
            this.tabla = show;
            return show;
        },
    },
    props: {
        title: String,
        boo_modal: Boolean,
        item: [],
        //Al quitar Permiso comento Props
        //permissionValue: Number,
        permissionEli: Number,
        type_patient: String
    },
    methods: {
      ...mapActions(["get", "post"]),

        validateNumberInput(event) {
            const regex = /^[0-9]*\.?[0-9]*$/;
            if (!regex.test(event.key)) {
                event.preventDefault();
            }
        },
        mtdClose() {
            this.posEditPrice = null;
            this.posEditQuantity = null;
            this.$emit("mtdCloseModalEdiatCarrito");
        },
        mtdDelCarrito: function (item) {
            if (this.permissionEli == 1) {
                let ArrayCategory = [1, 3, 2, 4, 5, 6, 7, 8, 10,11,13];
                let categorie = item.subsub_categorie.subategorie.categorie_id;
                if (ArrayCategory.includes(categorie)) {
                    //verificar en el back si aun no se da checkin
                  this.mtdVerifyBeforeDelete(item);
                } else {
                    this.$emit("mtdCommitDelCarrito", item.id);
                }
            } else {
                this.$refs.SweetAlert.showWarning("No tienes permiso para eliminar este ítem");
            }
        },
        mtdVerifyBeforeDelete(item) {
          let categorie = item.subsub_categorie.subategorie.categorie_id;
          let type = "";
            if (categorie == 1) {
                /** consultorio */
               type = 'ConsultingRoomBooking';
            } else if (
                categorie == 4 ||
                categorie == 5 ||
                categorie == 6 ||
                categorie == 7 
            ) {
                /** Uci */
               type = 'UciAdult';
            } else if (
                categorie == 8 ||
                categorie == 10
            ) {
                /** sop */
               type = 'OperationsRoomsBooking';
            } else if (
                categorie == 3
            ) {
                /** emergencia */
               type = "Emergency";
            } else if (
                categorie == 2
            ) {
                /** hospi */
               type = "Hospitalization";
            } else if (
                categorie == 13
            ) {
                /** imagenes */
               type = "Imagenes";
            } else if (
                categorie == 11
            ) {
                /** imagenes */
               type = "Laboratorio";
            }

            //enviar al back
            this.post({
                    url: this.$store.getters.get__url + "/cxc/verifydeletecarrito",
                    token: this.$store.getters.get__token,
                    params: {
                        model: type,
                        sale_id: item.sale_id,
                        subsubcategory_id: item.subsub_categorie_id,
                        id: item.id,
                    },
                })
                .then((response) => {
                  if(response.data == 1){
                    this.$refs.SweetAlert.showWarning('El paciente ya ingresó al Servicio');
                  }else{
                    //No tiene checkin y se procede a Eliminar
                    this.$emit("mtdCommitDelCarrito", item.id);
                  }

                })
                .catch((errors) => {});
        },
        mtdEditPrecies: function (pos) {
           /*  this.posEditPrice = pos;
            this.oldPrice = this.tabla[pos].price;
            this.newPrice = this.tabla[pos].price; */
            if (this.type_patient === "particular") {
                this.posEditPrice = pos;
                this.oldPrice = this.cpData[pos].price;
                this.newPrice = this.cpData[pos].price;
            } else {
                this.$refs.SweetAlert.showWarning("No tiene permiso para editar el precio");
            }
        },
        
        mtdOutFocusEditPrice: function () {
            if (this.oldQuantity != this.newQuantity && this.newQuantity > 0) {
                Swal.fire({
                    icon: "warning",
                    title: "¿Desea cambiar esta cantidad?",
                    width: "400px",
                    /** buttons */
                    showDenyButton: true,
                    denyButtonText: `Rechazar`,
                    denyButtonColor: "#bb8a17",
                    confirmButtonText: "Permitir",
                    confirmButtonColor: "#3085d6",
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.$emit(
                            "mtdCommitEditQuantity",
                            this.posEditQuantity,
                            this.newQuantity
                        );
                        this.posEditQuantity = null;
                        this.oldQuantity = null;
                        this.newQuantity = null;
                    } else if (result.isDenied) {
                        this.posEditQuantity = null;
                        this.oldQuantity = null;
                        this.newQuantity = null;
                    }
                });
            } else {
                this.posEditQuantity = null;
                this.oldQuantity = null;
                this.newQuantity = null;
            }
        },
        mtdOutFocusEditPrices: function () {
            // if (
            //   this.oldPrice !== this.newPrice &&
            //   this.newPrice > 0 &&
            //   this.permissionValue === 1
            // ) {
            Swal.fire({
                icon: "warning",
                title: "¿Desea cambiar esta Precio?",
                width: "400px",
                /** buttons */
                showDenyButton: true,
                denyButtonText: `Rechazar`,
                denyButtonColor: "#bb8a17",
                confirmButtonText: "Permitir",
                confirmButtonColor: "#3085d6",
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$emit("mtdCommitEditPrice", this.posEditPrice, this.newPrice);
                    this.posEditPrice = null;
                    this.oldPrice = null;
                    this.newPrice = null;
                } else if (result.isDenied) {
                    this.posEditPrice = null;
                    this.oldPrice = null;
                    this.newPrice = null;
                }
            });
            // } else {
            //   this.posEditPrice = null;
            //   this.oldPrice = null;
            //   this.newPrice = null;
            // }
        },
        showNoPermissionAlert() {
            Swal.fire({
                icon: "warning",
                text: "No tiene permiso para editar el precio",
                confirmButtonText: "OK",
                confirmButtonColor: "#900052",
            });
        },
    },
};
</script>
