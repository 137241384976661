<template>
<div class="col-md-12">
    <div class="row d-flex">
        <div class="panel-border my-1">
            <div class="row d-flex text-xs">
                <div class="col-md-12 bg-main text-white text-center py-2">
                    <span class="fw-bold">EVOLUCION MÉDICA  - {{ typeService }}</span>
                </div>
                <!-- <div class="col-xl-9 col-lg-9 col-md-9 col-sm-12 col-xs-12 py-2">
                    <span class="fw-bold">DOCTOR: </span>
                    DOCTOR
                </div> -->
            </div>
        </div>
        <div class="border border-1 p-3" v-if="data.length > 0">
            <!-- PAGINADO -->
            <div class="row d-flex justify-content-end mt-1">
                <div class="col-12 px-0">
                    <nav aria-label="Page navigation example" class="pagination-container">
                        <ul class="pagination pagination-xs justify-content-end">
                            <li class="page-item">
                                <a style="cursor: pointer" href="javascript:void(0)" class="page-link" @click="backPage">«</a>
                            </li>
                            <template>
                                <li class="page-item">
                                    <a class="page-link active" href="javascript:void(0)">PAG. {{ pos + 1  }}  - {{ cpDateName }}</a>
                                </li>
                            </template>
                            <li class="page-item">
                                <a style="cursor: pointer" class="page-link" @click="nextPage">»</a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
            <div class="row d-flex mb-1">
                <div class="col-xl-3 col-sm-12  my-1 text-xs">
                    <div id="div-fi" class="form-group row d-flex align-left">
                        <div>
                            <span class="color-main fw-bold">DIAS HOSPITALIZADOS: </span>
                            <span>{{ medical_evolutions.day }}</span>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-sm-12  my-1 text-xs">
                    <div id="div-medico" class="form-group row d-flex align-left">
                        <div>
                            <span class="color-main fw-bold">MEDICO: </span>
                            <span>{{ cpDoctor }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <!-- SOA -->
            <div class="row d-flex">
                <div class="panel-border my-1">
                    <div class="row px-3 d-sm-flex ">
                        <div class="col-xs-12 col-xl-2 col-lg-6 col-md-12 mb-2">
                            <div class="row d-flex">
                                <label class="col-sm-12 color-main fw-bold">SIGNOS VITALES</label>
                                <div class="col-md-4 col-xl-12 col-lg-4 col-xs-6 mt-1 align-left" v-for="(item2, index) in signosVitales" :key="index">
                                    <div class="form-group row d-flex text-xs">
                                        <label :for="'inputsig' + index" class="col-sm-5 col-xl-5 color-main">{{ item2.label }}</label>
                                        <div class="col-xl-7 col-lg-7 col-xs-6">
                                            <input type="text" v-model="item2.value" disabled class="form-control form-control-input form-control-sm" :id="'inputsig' + index">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-lg-6 col-xl-3 my-1">
                            <div id="div-subjective" class="form-group row align-left">
                                <label for="story" class="col-sm-12 col-md-12 color-main fw-bold">(S) SUBJETIVO</label>
                                <div class="col-xl-12 col-sm-12 col-md-12">
                                    <textarea rows="5" v-model="medical_evolutions.subjective" class="form-control text-xs" aria-label="With textarea" id="story" disabled></textarea>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-xl-4 col-lg-6 my-1">
                            <div id="div-physical" class="form-group row d-flex align-left">
                                <label class="col-sm-12 color-main fw-bold ">(O) OBJETIVO</label>
                                <div class="col-xl-12 col-lg-6 col-md-6 col-xs-6 mt-1 align-left" v-for="(item2, index) in cpPhysicalExam" :key="index">
                                    <div class="form-group row text-xs">
                                        <label :for="'input2' + index" class="col-xl-4 col-sm-12 color-main">{{ item2.label }}</label>
                                        <div class="col-xl-8 col-sm-12">
                                            <input type="text" v-model="item2.value" disabled class="form-control form-control-input form-control-sm" :id="'input2' + index">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-xl-3 col-lg-6 my-1">
                            <div id="div-appreciation" class="form-group row align-left">
                                <label for="appreciation" class="col-sm-12 col-md-12 color-main fw-bold">(A) APRECIACION</label>
                                <div class="col-xl-12 col-sm-12 col-md-12">
                                    <textarea rows="5" v-model="medical_evolutions.appreciation" class="form-control text-xs" aria-label="With textarea" id="story" disabled></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- PLAN Y GRAFICO (PENDIENTE) -->
            <div class="row d-flex">
                <div class="panel-border my-1">
                    <div class="row px-3 d-sm-flex ">
                        <div class="col-sm-12 col-xl-6 my-1">
                            <div id="div-plan" class="form-group row d-flex align-left">
                                <label class="col-sm-12 color-main fw-bold ">PLAN DE TRABAJO</label>
                                <div class="col-xl-12 col-sm-12">
                                    <ul class="list-group text-xs">
                                        <li class="list-group-item" v-for="(item, index) in cpPlan" :key="index">
                                            <span>{{ item.name }}</span>
                                        </li>
                                        <li class="list-group-item mb-2" v-if="cpPlan.length == 0">
                                            No se han registrado Planes
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-xl-6 my-1">
                            <div id="div-cie" class="form-group row d-flex align-left">
                                <label class="col-sm-12 color-main fw-bold ">DIAGNÓSTICOS</label>
                                <div class="col-xl-12 col-sm-12">
                                    <ul class="list-group text-xs">
                                        <li class="list-group-item" v-for="(item, index) in cpCies" :key="index">
                                            <span><b>{{ index + 1 }}</b>. {{ item.name }} - {{ item.codigo }}</span>
                                        </li>
                                        <li class="list-group-item mb-2" v-if="cpCies.length == 0">
                                            No se han registrado diagnosticos
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class=" border border-main p-5" v-else>
            <div class=" text-center">
            <h4>No hay evoluciones médicas registradas</h4>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import moment from "moment";
export default {
    name: 'c-medicalAct-reportEvMedical',
    data() {
        return {
            subpage: "buttons",
            pos: 0,
            medical_evolutions: {},

        }
    },
    props: {
        data: Array,
        doctor: Object,
        typeService: String,
    },
    watch: {
        typeService: {
            handler: function (newValue) {
                this.pos = this.data.length - 1;
                this.medical_evolutions = this.data[this.pos];
            },
            deep: true,
            immediate: true,
        }
    },
    computed: {
        cpDoctor() {
            let name = "";
            let last_name = "";
            let doctor = this.medical_evolutions.doctor
            if (doctor) {
                name = doctor.name ?? "";
                last_name = doctor.last_name ?? "";
            }
            return name + " " + last_name;
        },
        cpDateName() {
            let date = this.$options.filters.formatDate(this.medical_evolutions.date);
            let hour = this.$options.filters.formatTime(this.medical_evolutions.hour);

            return date + " " + hour;
        },
        signosVitales() {
            return JSON.parse(this.medical_evolutions.vital_sings);
        },
        cpPhysicalExam() {
            let exam = JSON.parse(this.medical_evolutions.physical_exam);
            return exam;
        },
        cpPlan() {
            return JSON.parse(this.medical_evolutions.plan);
        },
        cpCies() {
            return JSON.parse(this.medical_evolutions.diagnostics);
        },

    },
    methods: {
        returnHome() {
            //window.location.reload();
            this.$emit('mtdChangePage', 'home');
        },
        mtdback(subpage) {
            this.$emit('mtdChangeSubPage', subpage);

        },
        nextPage: function () {
            this.pos = (this.pos == this.data.length - 1) ? this.pos : this.pos + 1
            this.medical_evolutions = this.data[this.pos];
        },

        backPage: function () {
            this.pos = (this.pos == 0) ? this.pos : this.pos - 1
            this.medical_evolutions = this.data[this.pos];

        },
    },
    filters: {
        formatDate(date) {
            return date != null ? moment(date).format("DD/MM/YYYY") : "-";
        },
        formatTime(time) {
            let arTime = time.split(":");
            if (arTime[0] <= 12) {
                return time + " am";
            } else {
                return arTime[0] - 12 + ":" + arTime[1]  + " pm";
            }
        },
    },

}
</script>
