<template>
<CModal @mtdclosemodal="mtdclosemodal" :show="boo_modal" :close-on-backdrop="false" :centered="true" :title="title" :size="'lg'" :btn_cancel="true" :btn_ok="true" :booTitile="true">
    <div class="row mx-0 px-2 ">
        <div class="mb-3 text-start">
            <label for="exampleFormControlInput1" class="form-label ">Ingrese Comentario:</label>
            <textarea class="form-control form-control-sm" v-model="comment" id="exampleFormControlTextarea1" rows="5"></textarea>
        </div>
        <div class="row mx-0 px-2 mb-2">
            <table class="table table-bordered border-main mb-0 px-0 text-xs">
                <caption class=" my-0 py-0"></caption>
                <thead class="">
                    <tr class="text-white border-white text-center bg-main">
                        <th scope="col">SELECCIONE</th>
                        <th scope="col">PLANES EKG</th>
                        <th scope="col">FECHA DE SOLICITUD</th>
                        <th scope="col">FECHA DE RESULTADO</th>
                        <th scope="col">ORIGEN</th>
                        <th scope="col">ACCIONES</th>
                    </tr>
                </thead>
                <tbody v-if="this.ekg.length > 0">
                    <tr v-for="(item, index) in this.ekg" :key="index" class="px-1 border-bottom">
                        <td class="text-center"><span> <input class="form-check-input" @change="mtdIds()" type="checkbox" v-model="item.value_state" id="flexCheckDefault"></span></td>
                        <td class="text-center"><span>{{ item.subsubcategorie.name}} </span></td>
                        <td class="text-center"><span>{{ item.datenewaplicacion }} </span></td>
                        <td class="text-center"><span>{{ item.datenewresultado }}</span></td>
                        <td class="text-center">
                            <span>
                                {{ 
                                    item.type_service == 1 ? 'CONSULTA' : 
                                    item.type_service == 3 ? 'HOSPITALIZACIÓN':
                                    item.type_service == 4 ? 'UCI ADULTO': 'EMERGENCIA' 
                                 }}
                            </span>
                        </td>
                        <td class="text-center">
                            <span><button class="btn btn-sm btn-danger text-white" @click="openPdf(item.file)">
                                    <i class="fa fa-file-pdf"></i>
                                </button>
                            </span>
                        </td>
                    </tr>
                </tbody>
                <tbody class=" text-center" v-else>
                    <tr>
                        <td colspan="19" class=" text-center">
                            <strong> No hay resultados</strong>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

    </div>
    <template #footer>
        <button @click="mtdclosemodal" class="btn btn-danger btn-sm">
            <i class="fa fa-times" />&nbsp;Cerrar
        </button>
        <button class="btn btn-success btn-sm" @click="mtdaddcomment" :disabled="mtddisable">
            Guardar
        </button>
        &nbsp;
    </template>
    <SweetAlert :swal="swal" ref="SweetAlert" />
</CModal>
</template>

<script>
import CModal from "@/components/shared/modals/CModal.vue";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import {
    mapActions
} from "vuex";

export default {
    name: "c-medico-modal-diagnostico",

    data() {
        return {
            plan_detail: {
                name: '',
                subsubcategory_id: null,
                category_id: null,
                comment: '',
                ekg_id: []
            },
            swal: null,
            comment: '',
            ekg: []
        }
    },
    components: {
        CModal,
        SweetAlert
    },

    watch: {
        plans: {
            handler(newVal) {
                if (Object.keys(newVal).length > 0) {
                    this.plan_detail = newVal
                    this.plan_detail.ekg_id=[]
                    this.mtdekg(this.plan_detail)
                }

            },
            immediate: true
        }
    },
    computed: {
        mtddisable: function () {
            return !this.comment
        }
    },
    props: {
        title: String,
        boo_modal: Boolean,
        plans: {
            type: Object,
            default: () => ({})
        }
    },
    methods: {
        ...mapActions(["get", "post"]),
        mtdclosemodal() {
            this.$emit('mtdclosemodalcomment')
        },
        mtdaddcomment: function () {
            this.plan_detail.comment = this.comment
            this.$emit('mtdaddComment', this.plan_detail);
            this.comment = ''
            this.ekg = []
        },
        mtdekg: function (payload) {
            this.get({
                    url: this.$store.getters.get__url + "/Emergency/indexEkg/" + this.plan_detail.medical_act_id,
                    token: this.$store.getters.get__token,

                })
                .then((response) => {
                    this.ekg = response.data
                })
                .catch((errors) => {
                    console.log(errors);
                });
        },
        mtdIds: function () {
            let ekg_data = this.ekg
            this.plan_detail.ekg_id = []
            ekg_data.forEach(item => {
                if (item.value_state) {
                    this.plan_detail.ekg_id.push(item.id)
                }
            })
        },
        openPdf: function (url) {
            let nuevaUrl = "/assets/" + url;
            window.open(nuevaUrl, '_blank');
        },
    },
}
</script>
