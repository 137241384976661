<template>
<div class="dropdown mb-2" :class="[dropdown && 'activeItem']">
    <div class="dropdown-header" @click="toggleDropdown()">
        {{ title }}
        <img src="@/assets/helpers/circle-chevron-down-solid.svg" alt="" class="dropdown-icon" :class="[dropdown && 'active']">
    </div>
    <transition @before-enter="beforeEnter" @enter="enter" @before-leave="beforeLeave" @leave="leave">
        <div class="dropdown-wrapper" v-if="dropdown">
            <div id="myTabContentHijo" v-if="component=='Hchospitalizacion'">
                <Hchospitalizacion :responsive="true" :typeDoctor="typeDoctor" :metodoadd="metodoadd" @mtdaddexit="mtdaddexit"  :viewMedico="viewMedico" :Clinicahistory="Clinicahistory" :datapatients="datapatients" :showHistory="showHistory"></Hchospitalizacion>
            </div>
            <div id="myTabContentHijo" v-if="component=='Evolucionmedica'">
                <Emedica :typeDoctor="typeDoctor" :medical_act_id="medical_act_id" :addEv="addEv" @mtdaddevexit="mtdaddevexit" :dete_out="dete_out" :rolname="rolname" :dete_out_doctor="dete_out_doctor"></Emedica>
            </div>
            <div id="myTabContentHijo" v-if="component=='Rp'">
                <Rp :medical_act_id="medical_act_id" :addRp="addRp" @mtdaddrpexit="mtdaddrpexit" :dete_out="dete_out" :rolname="rolname"  :dete_out_doctor="dete_out_doctor"></Rp>
            </div>
            <div id="myTabContentHijo" v-if="component=='Hojagrafica'">
                <Hojagrafica :medical_act_id="medical_act_id"  :dete_out="dete_out" :rolname="rolname" :dete_out_doctor="dete_out_doctor"></Hojagrafica>
            </div>
            <div id="myTabContentHijo" v-if="component=='Notadeenfermeria'">
                <Notaenfermeria :medical_act_id="medical_act_id" :dete_out="dete_out" :rolname="rolname" :dete_out_doctor="dete_out_doctor"></Notaenfermeria>
            </div>
            <div id="myTabContentHijo" v-if="component=='Kardex'">
                <Kardex :medical_act_id="medical_act_id" :datapatients="datapatients" :dete_out="dete_out" :rolname="rolname" :dete_out_doctor="dete_out_doctor"></Kardex>
            </div>
            <div id="myTabContentHijo" v-if="component=='Mhemodinamico'">
                <Mhemodinamico :medical_act_id="medical_act_id" :dete_out="dete_out" :dete_out_doctor="dete_out_doctor" :rolname="rolname"></Mhemodinamico>
            </div>
            <div id="myTabContentHijo" v-if="component=='balanceHidrico'">
                <BalanceHidri :medical_act_id="medical_act_id" :datapatients="datapatients" :dete_out="dete_out" :rolname="rolname" :dete_out_doctor="dete_out_doctor"></BalanceHidri>
            </div>
            <div id="myTabContentHijo" v-if="component=='Oxigeno'">
                <Oxigeno></Oxigeno>
            </div>
           
            <div id="myTabContentHijo" v-if="component=='Bme'">
                <Bme></Bme>
            </div>
        </div>
    </transition>

</div>
</template>

<script>
import Bme from '@/components/Asistencial/Hospitalizacion/Historia/Bme.vue';
import Emedica from '@/components/Asistencial/Hospitalizacion/Historia/Emedica.vue';
import Hchospitalizacion from '@/components/Asistencial/Hospitalizacion/Historia/HchospitalizacionDesktop.vue';
import Hojagrafica from '@/components/Asistencial/Hospitalizacion/Historia/Hojagrafica.vue';
import Notaenfermeria from '@/components/Asistencial/Hospitalizacion/Historia/Notaenfermeria.vue'
import Kardex from '@/components/Asistencial/Hospitalizacion/Historia/Kardex.vue'
import Oxigeno from '@/components/Asistencial/Hospitalizacion/Historia/Oxigeno.vue'
import Mhemodinamico from '@/components/Asistencial/Hospitalizacion/Historia/Mhemodinamico.vue'
import Rp from '@/components/Asistencial/Hospitalizacion/Historia/Rp.vue'
import BalanceHidri from '@/components/Asistencial/Hospitalizacion/Historia/Balance.vue'
export default {
    name: 'dropdown-component-hospitalizacion',
    props: {
        title: String,
        component: String,
        datapatients: {
            type: Object,
            default: () => ({})
        },
        Clinicahistory: {
            type: Object,
            default: () => ({})
        },
        viewMedico: Number,
        metodoadd: {
            type: Number,
            default: 0
        },
        showHistory: {
            type: Boolean,
            default: false
        },
        typeDoctor: {
            type: Number,
            default: 1,
        },
        medical_act_id: {
            type: Number,
            default: 0
        },
        addEv: {
            type: Number,
            default: 0
        },
        addRp: {
            type: Number,
            default: 0
        },
        dete_out: {
            type: Boolean
        },
        dete_out_doctor: {
            type: Boolean
        },
        rolname: String,
    },
    components: {
        Hchospitalizacion,
        Emedica,
        Rp,
        Hojagrafica,
        Notaenfermeria,
        Kardex,
        Oxigeno,
        Bme,
        Mhemodinamico,
        BalanceHidri
    },
    data: () => ({
        dropdown: false
    }),
    methods: {
        toggleDropdown() {
            this.dropdown = !this.dropdown;
        },
        // beforeEnter(el) {
        //     el.style.height = 0
        // },
        // enter(el) {
        //     el.style.height = el.scrollHeight + 'px'
        // },
        // beforeLeave(el) {
        //     el.style.height = el.scrollHeight + 'px'
        // },
        // leave(el) {
        //     el.style.height = 0
        // },
        beforeEnter(el) {
            el.style.transformOrigin = 'top'; 
            el.style.transform = 'scaleY(0)';
            el.style.opacity = 0;
        },
        enter(el) {
            el.style.transition = 'transform 0.1s ease, opacity 0.1s ease';
            el.style.transform = 'scaleY(1)';
            el.style.opacity = 1;
        },
        beforeLeave(el) {
            el.style.transformOrigin = 'top'; 
            el.style.transform = 'scaleY(1)';
            el.style.opacity = 1;
        },
        leave(el) {
            el.style.transition = 'transform 0.1s ease, opacity 0.1s ease'; 
            el.style.transform = 'scaleY(0)';
            el.style.opacity = 0;
        },
        mtdaddexit:function(ind,Historia){
            this.$emit("mtdaddexit", ind, Historia);
        },
        mtdaddevexit:function(){
            this.$emit('mtdaddevexit')
        },
        mtdaddrpexit:function(){
            this.$emit('mtdaddrpexit')
        }
    }
}
</script>
