<template>
<div class="pt-2 pb-3">
    <div class="container-line">
        <div class="row" v-if="rolname !== 'DOCTOR(A)'">
            <div class="col-md-12">
                <div class="row d-flex">
                    <!-- <div class="col-md-2 d-flex justify-content-start align-bottom" v-if="action == 'edit'">
                        <button type="button" class="btn btn-success btn-sm text-white" @click="saveBalance" :disabled = "dete_out || rolname !== 'ENFERMERA(O)' || dete_out_doctor">
                        <i class="fas fa-save"></i> Guardar Balance</button>
                    </div> -->
                    <div class="d-flex justify-content-end" :class="action == 'edit'?'col-md-12':'col-md-12'" style="align-items: flex-start">
                        <div class="form-check form-switch">
                            <input class="form-check-input" type="checkbox" id="flexSwitchCheckChecked" :checked="action !== 'edit'" @change="mtdChangeState" />
                            <label class="form-check-label" for="flexSwitchCheckChecked">
                                <strong>{{ action == 'edit' ? "AGREGAR" : "VER" }}</strong>
                            </label>
                        </div>
                    </div>
                    <div class="col-lg-3 col-xl-5" v-if="action !== 'edit' && data.length > 0">
                        <!-- PAGINADO -->
                        <div class="row d-flex justify-content-end">
                            <div class="col-12 px-2">
                                <nav aria-label="Page navigation example" class="pagination-container">
                                    <ul class="pagination pagination-xs justify-content-end">
                                        <li class="page-item">
                                            <a style="cursor: pointer" href="javascript:void(0)" class="page-link" @click="backPage">«</a>
                                        </li>
                                        <template>
                                            <li class="page-item">
                                                <a class="page-link active" href="javascript:void(0)">PAG. {{ pos + 1  }} - {{ cpName }}</a>
                                            </li>
                                        </template>
                                        <li class="page-item">
                                            <a style="cursor: pointer" class="page-link" @click="nextPage">»</a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row d-flex">
            <div id="edit" class="col-md-12 my-2" v-if="action == 'edit'">
                <!-- PANEL PARA AGREGAR DATOS -->
                <span class="background">
                    <section id="myTabContentHijoHC" class="col-md-12 gallery mb-2">

                        <!-- Fecha -->
                        <article class="card-date col-xl-2 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="container px-2 text-start">
                                <div class="col-auto px-1 mt-3">
                                    <label for="fechaIngreso" class="fw-bold font-size">FECHA DE INGRESO:</label>
                                    <input 
                                        type="date" 
                                        class="form-control form-control-sm mt-2" 
                                        style="width: 100%;" 
                                        :disabled="balance.length > 1 || dete_out || rolname !== 'ENFERMERA(O)' || dete_out_doctor"
                                        v-model="water_balance.date_entry" 
                                        :max="currentDate" 
                                    />
                                </div>
                                <div class="col-auto px-1 mt-2" v-if="isDatePast">
                                    <label for="horaIngreso" class="fw-bold font-size">HORA DE INGRESO:</label>
                                    <input 
                                        type="time" 
                                        class="form-control form-control-sm mt-2" 
                                        style="width: 100%;" 
                                        v-model="water_balance.hourBalance" 
                                        :disabled="validation || balance.length > 1" 
                                    />
                                </div>
                                <div class="col-auto px-1 mt-2">
                                    <label for="peso" class="fw-bold font-size">PESO:</label>
                                    <input 
                                        type="text" 
                                        class="form-control form-control-sm mt-2" 
                                        style="width: 100%;" 
                                        placeholder="Peso Kg."
                                        v-model="water_balance.weight" 
                                        v-on:keypress="isNumber($event)"
                                        :disabled="validation" 
                                    />
                                </div>
                                <div class="d-flex justify-content-end" v-if="!validation">
                                    <div class="col-auto px-1 mt-2">
                                        <button 
                                            class="btn btn-sm text-white bg-main mt-3" 
                                            @click="mtdadd"
                                            :disabled="isGenerateButtonDisabled"
                                        >
                                            <i class="fas fa-save"></i> Guardar Balance
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </article>
                        <!-- Fin Fecha -->

                        <!-- INGRESOS -->
                        <article id="div1" ref="div1" class="card active-section" @click="toggleClass(1, $event)">
                            <div id="div1-content" ref="div1_content" class="row p-2">
                                <div class="px-4 py-4 row d-flex" style="overflow-y: auto; font-size: 12px;">
                                    <h6 style="font-size: 14px"><strong>INGRESOS</strong></h6>
                                    <div class="col-md-6 col-lg-4">
                                        <!-- parenteral -->
                                        <div class="panel-border mt-1 mb-0 px-3 pt-1 d-inline-block" @click="toggleDropdown(0)">
                                            <h6 class="fw-bold color-main d-inline-block">{{titleDropdown[0].title}}</h6>

                                            <img src="@/assets/helpers/circle-chevron-down-solid.svg" alt="" class="dropdown-icon-content d-inline-block float-end" :class="[titleDropdown[0].state && 'active']">
                                        </div>
                                        <div class="panel-border mb-1 pb-2" v-if="titleDropdown[0].state">
                                            <div class="row px-3">
                                                <div class="col-md-12 col-lg-6 mt-1 align-left" v-for="(item2, index) in dataIngreso.valuesParen" :key="index">
                                                    <div id="div-estado" class="form-group row">
                                                        <label :for="'input0' + index" class="col-sm-5 color-main fw-bold">{{ item2.label }}</label>
                                                        <div class="col-xl-7 col-sm-12">
                                                            <input type="text" v-model="item2.value" v-on:keypress="validateDecimal($event)" class="form-control form-control-input form-control-sm" :id="'input0' + index">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- sedonalgesia -->
                                        <div class="panel-border mt-1 mb-0 px-3 pt-1 d-inline-block" @click="toggleDropdown(1)">
                                            <h6 class="fw-bold color-main d-inline-block">{{titleDropdown[1].title}}</h6>
                                            <img src="@/assets/helpers/circle-chevron-down-solid.svg" alt="" class="dropdown-icon-content d-inline-block float-end" :class="[titleDropdown[1].state && 'active']">
                                        </div>
                                        <div class="panel-border mb-1 pb-2" v-if="titleDropdown[1].state">
                                            <div class="row px-3 pt-1">
                                                <div class="col-md-12 mt-1 align-left" v-for="(itemv, indexv) in dataIngreso.valuesSedo" :key="indexv">
                                                    <div id="div-estado1" class="form-group row">
                                                        <label :for="'inputv' + indexv" class="col-sm-5 color-main fw-bold">{{ itemv.label }}</label>
                                                        <div class="col-xl-7 col-sm-12">
                                                            <input type="text" v-model="itemv.value" v-on:keypress="validateDecimal($event)" class="form-control form-control-input form-control-sm" :id="'inputv' + indexv">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- inotropicos -->
                                        <div class="panel-border mt-1 mb-0 px-3 pt-1 d-inline-block" @click="toggleDropdown(2)">
                                            <h6 class="fw-bold color-main d-inline-block">{{titleDropdown[2].title}}</h6>
                                            <img src="@/assets/helpers/circle-chevron-down-solid.svg" alt="" class="dropdown-icon-content d-inline-block float-end" :class="[titleDropdown[2].state && 'active']">
                                        </div>
                                        <div class="panel-border mb-1 pb-2" v-if="titleDropdown[2].state">
                                            <div class="row px-3 pt-1">
                                                <div class="col-md-12 mt-1 align-left" v-for="(item2, index) in dataIngreso.valuesIno" :key="index">
                                                    <div id="div-vaso" class="form-group row">
                                                        <template>
                                                            <label v-if="item2.new == false && index < 1" :for="'inputIno' + index" class="col-sm-5 col-xl-5 col-lg-12 color-main fw-bold">{{ item2.label }}</label>
                                                            <editable-cell v-else :value="item2.label" :index="index" :array="'dataIngreso.valuesIno'" @update-label="updateLabel" />

                                                        </template>
                                                        <div :class="mtdClassButton(index,dataIngreso.valuesIno,'ino')">
                                                            <input type="text" v-model="item2.value" v-on:keypress="validateDecimal($event)" class="form-control form-control-input form-control-sm" :id="'inputIno' + index">
                                                        </div>
                                                        <div class="col-auto">
                                                            <button v-if="index == (dataIngreso.valuesIno.length-1)" class="btn btn-success btn-sm text-xxs me-1" title="Agregar fila" @click="mtdAddItem(item2.id,'dataIngreso.valuesIno')">
                                                                <i class="fas fa-plus text-white"></i>
                                                            </button>
                                                            <button v-if="mtdShowButton(dataIngreso.valuesIno,index,'ino')" class="btn btn-danger btn-sm text-xxs" @click="removeItem(index,'dataIngreso.valuesIno')">
                                                                <i class="fas fa-minus text-white"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-lg-4">
                                        <!-- Vasoactivos -->
                                        <div class="panel-border mt-1 mb-0 px-3 pt-1 d-inline-block" @click="toggleDropdown(3)">
                                            <h6 class="fw-bold color-main d-inline-block">{{titleDropdown[3].title}}</h6>
                                            <img src="@/assets/helpers/circle-chevron-down-solid.svg" alt="" class="dropdown-icon-content d-inline-block float-end" :class="[titleDropdown[3].state && 'active']">
                                        </div>
                                        <div class="panel-border mb-1 pb-2" v-if="titleDropdown[3].state">
                                            <div class="row px-3 pt-1">
                                                <div class="col-md-12 mt-1 align-left" v-for="(itemv, indexv) in dataIngreso.valuesVasoActivo" :key="indexv">
                                                    <div id="div-vaso" class="form-group row">
                                                        <template>
                                                            <label v-if="itemv.new == false && indexv < 3" :for="'input2' + indexv" class="col-sm-5 col-xl-5 col-lg-12 color-main fw-bold">{{ itemv.label }}</label>
                                                            <editable-cell v-else :value="itemv.label" :index="indexv" :array="'dataIngreso.valuesVasoActivo'" @update-label="updateLabel" />

                                                        </template>
                                                        <div :class="mtdClassButton(indexv,dataIngreso.valuesVasoActivo,'vaso')">
                                                            <input type="text" v-model="itemv.value" v-on:keypress="validateDecimal($event)" class="form-control form-control-input form-control-sm" :id="'input2' + indexv">
                                                        </div>
                                                        <div class="col-auto">
                                                            <button v-if="indexv == (dataIngreso.valuesVasoActivo.length-1)" class="btn btn-success btn-sm text-xxs me-1" title="Agregar fila" @click="mtdAddItem(itemv.id,'dataIngreso.valuesVasoActivo')">
                                                                <i class="fas fa-plus text-white"></i>
                                                            </button>
                                                            <button v-if="mtdShowButton(dataIngreso.valuesVasoActivo,indexv,'vaso')" class="btn btn-danger btn-sm text-xxs" @click="removeItem(indexv,'dataIngreso.valuesVasoActivo')">
                                                                <i class="fas fa-minus text-white"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- retos -->
                                        <div class="panel-border mt-1 mb-0 px-3 pt-1 d-inline-block" @click="toggleDropdown(4)">
                                            <h6 class="fw-bold color-main d-inline-block">{{titleDropdown[4].title}}</h6>
                                            <img src="@/assets/helpers/circle-chevron-down-solid.svg" alt="" class="dropdown-icon-content d-inline-block float-end" :class="[titleDropdown[4].state && 'active']">
                                        </div>
                                        <div class="panel-border mb-1 pb-2" v-if="titleDropdown[4].state">
                                            <div class="row px-3 pt-1">
                                                <div class="col-md-12 mt-1 align-left" v-for="(itemRe, indexRe) in dataIngreso.valuesReto" :key="indexRe">
                                                    <div id="div-estado" class="form-group row">
                                                        <label :for="'inputRe' + indexRe" class="col-sm-5 color-main fw-bold">{{ itemRe.label }}</label>
                                                        <div class="col-xl-7 col-sm-12">
                                                            <input type="text" v-model="itemRe.value" v-on:keypress="validateDecimal($event)" class="form-control form-control-input form-control-sm" :id="'inputRe' + indexRe">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- manitol -->
                                        <div class="panel-border mt-1 mb-0 px-3 pt-1 d-inline-block" @click="toggleDropdown(5)">
                                            <h6 class="fw-bold color-main d-inline-block">{{titleDropdown[5].title}}</h6>
                                            <img src="@/assets/helpers/circle-chevron-down-solid.svg" alt="" class="dropdown-icon-content d-inline-block float-end" :class="[titleDropdown[3].state && 'active']">
                                        </div>
                                        <div class="panel-border mb-1 pb-2" v-if="titleDropdown[5].state">
                                            <div class="row px-3 pt-1">
                                                <div class="col-md-12 mt-1 align-left" v-for="(itemMa, indexMa) in dataIngreso.valuesMani" :key="indexMa">
                                                    <div id="div-vaso" class="form-group row">
                                                        <template>
                                                            <label v-if="itemMa.new == false && indexMa < 1" :for="'input2' + indexMa" class="col-sm-5 col-xl-5 col-lg-12 color-main fw-bold">{{ itemMa.label }}</label>
                                                            <editable-cell v-else :value="itemMa.label" :index="indexMa" :array="'dataIngreso.valuesMani'" @update-label="updateLabel" />

                                                        </template>
                                                        <div :class="mtdClassButton(indexMa,dataIngreso.valuesMani,'vaso')">
                                                            <input type="text" v-model="itemMa.value" v-on:keypress="validateDecimal($event)" class="form-control form-control-input form-control-sm" :id="'input2' + indexMa">
                                                        </div>
                                                        <div class="col-auto">
                                                            <button v-if="indexMa == (dataIngreso.valuesMani.length-1)" class="btn btn-success btn-sm text-xxs me-1" title="Agregar fila" @click="mtdAddItem(itemMa.id,'dataIngreso.valuesMani')">
                                                                <i class="fas fa-plus text-white"></i>
                                                            </button>
                                                            <button v-if="mtdShowButton(dataIngreso.valuesMani,indexMa,'vaso')" class="btn btn-danger btn-sm text-xxs" @click="removeItem(indexMa,'dataIngreso.valuesMani')">
                                                                <i class="fas fa-minus text-white"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-lg-4">
                                        <!-- hemoderivado -->
                                        <div class="panel-border mt-1 mb-0 px-3 pt-1 d-inline-block" @click="toggleDropdown(6)">
                                            <h6 class="fw-bold color-main d-inline-block">{{titleDropdown[6].title}}</h6>
                                            <img src="@/assets/helpers/circle-chevron-down-solid.svg" alt="" class="dropdown-icon-content d-inline-block float-end" :class="[titleDropdown[6].state && 'active']">
                                        </div>
                                        <div class="panel-border mb-1 pb-2" v-if="titleDropdown[6].state">
                                            <div class="row px-3 pt-1">
                                                <div class="col-md-12 mt-1 align-left" v-for="(itemHe, indexHe) in dataIngreso.valuesHemo" :key="indexHe">
                                                    <div id="div-vaso" class="form-group row">
                                                        <template>
                                                            <label v-if="itemHe.new == false && indexHe < 1" :for="'input2' + indexHe" class="col-sm-5 col-xl-5 col-lg-12 color-main fw-bold">{{ itemHe.label }}</label>
                                                            <editable-cell v-else :value="itemHe.label" :index="indexHe" :array="'dataIngreso.valuesHemo'" @update-label="updateLabel" />

                                                        </template>
                                                        <div :class="mtdClassButton(indexHe,dataIngreso.valuesHemo,'vaso')">
                                                            <input type="text" v-model="itemHe.value" v-on:keypress="validateDecimal($event)" class="form-control form-control-input form-control-sm" :id="'input2' + indexHe">
                                                        </div>
                                                        <div class="col-auto">
                                                            <button v-if="indexHe == (dataIngreso.valuesHemo.length-1)" class="btn btn-success btn-sm text-xxs me-1" title="Agregar fila" @click="mtdAddItem(itemHe.id,'dataIngreso.valuesHemo')">
                                                                <i class="fas fa-plus text-white"></i>
                                                            </button>
                                                            <button v-if="mtdShowButton(dataIngreso.valuesHemo,indexHe,'vaso')" class="btn btn-danger btn-sm text-xxs" @click="removeItem(indexHe,'dataIngreso.valuesHemo')">
                                                                <i class="fas fa-minus text-white"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- tratamiento ev -->
                                        <div class="panel-border mt-1 mb-0 px-3 pt-1 d-inline-block" @click="toggleDropdown(7)">
                                            <h6 class="fw-bold color-main d-inline-block">{{titleDropdown[7].title}}</h6>
                                            <img src="@/assets/helpers/circle-chevron-down-solid.svg" alt="" class="dropdown-icon-content d-inline-block float-end" :class="[titleDropdown[7].state && 'active']">
                                        </div>
                                        <div class="panel-border mb-1 pb-2" v-if="titleDropdown[7].state">
                                            <div class="row px-3 pt-1">
                                                <div class="col-md-12 mt-1 align-left" v-for="(itemTra, indexTra) in dataIngreso.valuesTrata" :key="indexTra">
                                                    <div id="div-vaso" class="form-group row">
                                                        <template>
                                                            <label v-if="itemTra.new == false && indexTra < 1" :for="'input2' + indexTra" class="col-sm-5 col-xl-5 col-lg-12 color-main fw-bold">{{ itemTra.label }}</label>
                                                            <editable-cell v-else :value="itemTra.label" :index="indexTra" :array="'dataIngreso.valuesTrata'" @update-label="updateLabel" />

                                                        </template>
                                                        <div :class="mtdClassButton(indexTra,dataIngreso.valuesTrata,'vaso')">
                                                            <input type="text" v-model="itemTra.value" v-on:keypress="validateDecimal($event)" class="form-control form-control-input form-control-sm" :id="'input2' + indexTra">
                                                        </div>
                                                        <div class="col-auto">
                                                            <button v-if="indexTra == (dataIngreso.valuesTrata.length-1)" class="btn btn-success btn-sm text-xxs me-1" title="Agregar fila" @click="mtdAddItem(itemTra.id,'dataIngreso.valuesTrata')">
                                                                <i class="fas fa-plus text-white"></i>
                                                            </button>
                                                            <button v-if="mtdShowButton(dataIngreso.valuesTrata,indexTra,'vaso')" class="btn btn-danger btn-sm text-xxs" @click="removeItem(indexTra,'dataIngreso.valuesTrata')">
                                                                <i class="fas fa-minus text-white"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- enteral -->
                                        <div class="panel-border mt-1 mb-0 px-3 pt-1 d-inline-block" @click="toggleDropdown(8)">
                                            <h6 class="fw-bold color-main d-inline-block">{{titleDropdown[8].title}}</h6>
                                            <img src="@/assets/helpers/circle-chevron-down-solid.svg" alt="" class="dropdown-icon-content d-inline-block float-end" :class="[titleDropdown[8].state && 'active']">
                                        </div>
                                        <div class="panel-border mb-1 pb-2" v-if="titleDropdown[8].state">
                                            <div class="row px-3 pt-1">
                                                <div class="col-md-12 mt-1 align-left" v-for="(itemEn, indexEn) in dataIngreso.valuesEn" :key="indexEn">
                                                    <div id="div-vaso" class="form-group row">
                                                        <template>
                                                            <label v-if="itemEn.new == false && indexEn < 1" :for="'input2' + indexEn" class="col-sm-5 col-xl-5 col-lg-12 color-main fw-bold">{{ itemEn.label }}</label>
                                                            <editable-cell v-else :value="itemEn.label" :index="indexEn" :array="'dataIngreso.valuesEn'" @update-label="updateLabel" />

                                                        </template>
                                                        <div :class="mtdClassButton(indexEn,dataIngreso.valuesEn,'vaso')">
                                                            <input type="text" v-model="itemEn.value" v-on:keypress="validateDecimal($event)" class="form-control form-control-input form-control-sm" :id="'input2' + indexEn">
                                                        </div>
                                                        <div class="col-auto">
                                                            <button v-if="indexEn == (dataIngreso.valuesEn.length-1)" class="btn btn-success btn-sm text-xxs me-1" title="Agregar fila" @click="mtdAddItem(itemEn.id,'dataIngreso.valuesEn')">
                                                                <i class="fas fa-plus text-white"></i>
                                                            </button>
                                                            <button v-if="mtdShowButton(dataIngreso.valuesEn,indexEn,'vaso')" class="btn btn-danger btn-sm text-xxs" @click="removeItem(indexEn,'dataIngreso.valuesEn')">
                                                                <i class="fas fa-minus text-white"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </article>
                        <!-- EGRESOS -->
                        <article id="div2" ref="div2" class="card" @click="toggleClass(2, $event)">
                            <div id="div2-content" ref="div2_content" class="row p-2">
                                <div class="px-4 py-4 row d-flex" style="overflow-y: auto; font-size: 12px">
                                    <h6 style="font-size: 14px"><strong>EGRESOS</strong></h6>
                                    <div class="col-md-6 col-lg-4">
                                        <!-- diuresis -->
                                        <div class="panel-border mt-1 mb-0 px-3 pt-1 d-inline-block" @click="toggleDropdownEg(0)">
                                            <h6 class="fw-bold color-main d-inline-block">{{titleDropdownEg[0].title}}</h6>
                                            <img src="@/assets/helpers/circle-chevron-down-solid.svg" alt="" class="dropdown-icon-content d-inline-block float-end" :class="[titleDropdownEg[0].state && 'active']">
                                        </div>
                                        <div class="panel-border mb-1 pb-2" v-if="titleDropdownEg[0].state">
                                            <div class="row px-3 pt-1">
                                                <div class="col-md-12 mt-1 align-left" v-for="(itemDiu, indexDiu) in dataEgreso.valuesDiu" :key="indexDiu">
                                                    <div id="div-vaso" class="form-group row">
                                                        <template>
                                                            <label v-if="itemDiu.new == false && indexDiu < 1" :for="'inputDiu' + indexDiu" class="col-sm-5 col-xl-5 col-lg-12 color-main fw-bold">{{ itemDiu.label }}</label>
                                                            <editable-cell v-else :value="itemDiu.label" :index="indexDiu" :array="'dataEgreso.valuesDiu'" @update-label="updateLabel" />

                                                        </template>
                                                        <div :class="mtdClassButton(indexDiu,dataEgreso.valuesDiu,'diu')">
                                                            <input type="text" v-model="itemDiu.value" v-on:keypress="validateDecimal($event)" class="form-control form-control-input form-control-sm" :id="'inputDiu' + indexDiu">
                                                        </div>
                                                        <div class="col-auto">
                                                            <button v-if="indexDiu == (dataEgreso.valuesDiu.length-1)" class="btn btn-success btn-sm text-xxs me-1" title="Agregar fila" @click="mtdAddItem(itemDiu.id,'dataEgreso.valuesDiu')">
                                                                <i class="fas fa-plus text-white"></i>
                                                            </button>
                                                            <button v-if="mtdShowButton(dataEgreso.valuesDiu,indexDiu,'diu')" class="btn btn-danger btn-sm text-xxs" @click="removeItem(indexDiu,'dataEgreso.valuesDiu')">
                                                                <i class="fas fa-minus text-white"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- deposicion -->
                                        <div class="panel-border mt-1 mb-0 px-3 pt-1 d-inline-block" @click="toggleDropdownEg(1)">
                                            <h6 class="fw-bold color-main d-inline-block">{{titleDropdownEg[1].title}}</h6>
                                            <img src="@/assets/helpers/circle-chevron-down-solid.svg" alt="" class="dropdown-icon-content d-inline-block float-end" :class="[titleDropdownEg[1].state && 'active']">
                                        </div>
                                        <div class="panel-border mb-1 pb-2" v-if="titleDropdownEg[1].state">
                                            <div class="row px-3 pt-1">
                                                <div class="col-md-12 mt-1 align-left" v-for="(itemDep, indexDep) in dataEgreso.valuesDep" :key="indexDep">
                                                    <div id="div-vaso" class="form-group row">
                                                        <template>
                                                            <label v-if="itemDep.new == false && indexDep < 1" :for="'inputDep' + indexDep" class="col-sm-5 col-xl-5 col-lg-12 color-main fw-bold">{{ itemDep.label }}</label>
                                                            <editable-cell v-else :value="itemDep.label" :index="indexDep" :array="'dataEgreso.valuesDep'" @update-label="updateLabel" />

                                                        </template>
                                                        <div :class="mtdClassButton(indexDep,dataEgreso.valuesDep,'dep')">
                                                            <input type="text" v-model="itemDep.value" v-on:keypress="validateDecimal($event)" class="form-control form-control-input form-control-sm" :id="'inputDep' + indexDep">
                                                        </div>
                                                        <div class="col-auto">
                                                            <button v-if="indexDep == (dataEgreso.valuesDep.length-1)" class="btn btn-success btn-sm text-xxs me-1" title="Agregar fila" @click="mtdAddItem(itemDep.id,'dataEgreso.valuesDep')">
                                                                <i class="fas fa-plus text-white"></i>
                                                            </button>
                                                            <button v-if="mtdShowButton(dataEgreso.valuesDep,indexDep,'dep')" class="btn btn-danger btn-sm text-xxs" @click="removeItem(indexDep,'dataEgreso.valuesDep')">
                                                                <i class="fas fa-minus text-white"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                         <!-- vom -->
                                         <div class="panel-border mt-1 mb-0 px-3 pt-1 d-inline-block" @click="toggleDropdownEg(2)">
                                            <h6 class="fw-bold color-main d-inline-block">{{titleDropdownEg[2].title}}</h6>
                                            <img src="@/assets/helpers/circle-chevron-down-solid.svg" alt="" class="dropdown-icon-content d-inline-block float-end" :class="[titleDropdownEg[2].state && 'active']">
                                        </div>
                                        <div class="panel-border mb-1 pb-2" v-if="titleDropdownEg[2].state">
                                            <div class="row px-3 pt-1">
                                                <div class="col-md-12 mt-1 align-left" v-for="(itemVom, indexVom) in dataEgreso.valuesVom" :key="indexVom">
                                                    <div id="div-vaso" class="form-group row">
                                                        <template>
                                                            <label v-if="itemVom.new == false && indexVom < 1" :for="'inputVom' + indexVom" class="col-sm-5 col-xl-5 col-lg-12 color-main fw-bold">{{ itemVom.label }}</label>
                                                            <editable-cell v-else :value="itemVom.label" :index="indexVom" :array="'dataEgreso.valuesVom'" @update-label="updateLabel" />

                                                        </template>
                                                        <div :class="mtdClassButton(indexVom,dataEgreso.valuesVom,'vom')">
                                                            <input type="text" v-model="itemVom.value" v-on:keypress="validateDecimal($event)" class="form-control form-control-input form-control-sm" :id="'inputVom' + indexVom">
                                                        </div>
                                                        <div class="col-auto">
                                                            <button v-if="indexVom == (dataEgreso.valuesVom.length-1)" class="btn btn-success btn-sm text-xxs me-1" title="Agregar fila" @click="mtdAddItem(itemVom.id,'dataEgreso.valuesVom')">
                                                                <i class="fas fa-plus text-white"></i>
                                                            </button>
                                                            <button v-if="mtdShowButton(dataEgreso.valuesVom,indexVom,'vom')" class="btn btn-danger btn-sm text-xxs" @click="removeItem(indexVom,'dataEgreso.valuesVom')">
                                                                <i class="fas fa-minus text-white"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-lg-4">
                                        <!-- dre -->
                                        <div class="panel-border mt-1 mb-0 px-3 pt-1 d-inline-block" @click="toggleDropdownEg(3)">
                                            <h6 class="fw-bold color-main d-inline-block">{{titleDropdownEg[3].title}}</h6>
                                            <img src="@/assets/helpers/circle-chevron-down-solid.svg" alt="" class="dropdown-icon-content d-inline-block float-end" :class="[titleDropdownEg[3].state && 'active']">
                                        </div>
                                        <div class="panel-border mb-1 pb-2" v-if="titleDropdownEg[3].state">
                                            <div class="row px-3 pt-1">
                                                <div class="col-md-12 mt-1 align-left" v-for="(itemDre, indexDre) in dataEgreso.valuesDre" :key="indexDre">
                                                    <div id="div-vaso" class="form-group row">
                                                        <template>
                                                            <label v-if="itemDre.new == false && indexDre < 1" :for="'inputDre' + indexDre" class="col-sm-5 col-xl-5 col-lg-12 color-main fw-bold">{{ itemDre.label }}</label>
                                                            <editable-cell v-else :value="itemDre.label" :index="indexDre" :array="'dataEgreso.valuesDre'" @update-label="updateLabel" />

                                                        </template>
                                                        <div :class="mtdClassButton(indexDre,dataEgreso.valuesDre,'dre')">
                                                            <input type="text" v-model="itemDre.value" v-on:keypress="validateDecimal($event)" class="form-control form-control-input form-control-sm" :id="'inputDre' + indexDre">
                                                        </div>
                                                        <div class="col-auto">
                                                            <button v-if="indexDre == (dataEgreso.valuesDre.length-1)" class="btn btn-success btn-sm text-xxs me-1" title="Agregar fila" @click="mtdAddItem(itemDre.id,'dataEgreso.valuesDre')">
                                                                <i class="fas fa-plus text-white"></i>
                                                            </button>
                                                            <button v-if="mtdShowButton(dataEgreso.valuesDre,indexDre,'dre')" class="btn btn-danger btn-sm text-xxs" @click="removeItem(indexDre,'dataEgreso.valuesDre')">
                                                                <i class="fas fa-minus text-white"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                         <!-- tem -->
                                         <div class="panel-border mt-1 mb-0 px-3 pt-1 d-inline-block" @click="toggleDropdownEg(4)">
                                            <h6 class="fw-bold color-main d-inline-block">{{titleDropdownEg[4].title}}</h6>
                                            <img src="@/assets/helpers/circle-chevron-down-solid.svg" alt="" class="dropdown-icon-content d-inline-block float-end" :class="[titleDropdownEg[4].state && 'active']">
                                        </div>
                                        <div class="panel-border mb-1 pb-2" v-if="titleDropdownEg[4].state">
                                            <div class="row px-3 pt-1">
                                                <div class="col-md-12 mt-1 align-left" v-for="(itemTem, indexTem) in dataEgreso.valuesTem" :key="indexTem">
                                                    <div id="div-tem" class="form-group row">
                                                        <template>
                                                            <label v-if="itemTem.new == false && indexTem < 1" :for="'inputTem' + indexTem" class="col-sm-5 col-xl-5 col-lg-12 color-main fw-bold">{{ itemTem.label }}</label>
                                                            <editable-cell v-else :value="itemTem.label" :index="indexTem" :array="'dataEgreso.valuesTem'" @update-label="updateLabel" />

                                                        </template>
                                                        <div :class="mtdClassButton(indexTem,dataEgreso.valuesTem,'tem')">
                                                            <input type="text" v-model="itemTem.value" v-on:keypress="validateDecimal($event)" class="form-control form-control-input form-control-sm" :id="'inputTem' + indexTem">
                                                        </div>
                                                        <div class="col-auto">
                                                            <button v-if="indexTem == (dataEgreso.valuesTem.length-1)" class="btn btn-success btn-sm text-xxs me-1" title="Agregar fila" @click="mtdAddItem(itemTem.id,'dataEgreso.valuesTem')">
                                                                <i class="fas fa-plus text-white"></i>
                                                            </button>
                                                            <button v-if="mtdShowButton(dataEgreso.valuesTem,indexTem,'tem')" class="btn btn-danger btn-sm text-xxs" @click="removeItem(indexTem,'dataEgreso.valuesTem')">
                                                                <i class="fas fa-minus text-white"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                         <!-- sebo -->
                                         <div class="panel-border mt-1 mb-0 px-3 pt-1 d-inline-block" @click="toggleDropdownEg(5)">
                                            <h6 class="fw-bold color-main d-inline-block">{{titleDropdownEg[5].title}}</h6>
                                            <img src="@/assets/helpers/circle-chevron-down-solid.svg" alt="" class="dropdown-icon-content d-inline-block float-end" :class="[titleDropdownEg[5].state && 'active']">
                                        </div>
                                        <div class="panel-border mb-1 pb-2" v-if="titleDropdownEg[5].state">
                                            <div class="row px-3 pt-1">
                                                <div class="col-md-12 mt-1 align-left" v-for="(itemSeBo, indexSeBo) in dataEgreso.valuesSeBo" :key="indexSeBo">
                                                    <div id="div-tem" class="form-group row">
                                                        <template>
                                                            <label v-if="itemSeBo.new == false && indexSeBo < 1" :for="'inputSeBo' + indexSeBo" class="col-sm-5 col-xl-5 col-lg-12 color-main fw-bold">{{ itemSeBo.label }}</label>
                                                            <editable-cell v-else :value="itemSeBo.label" :index="indexSeBo" :array="'dataEgreso.valuesSeBo'" @update-label="updateLabel" />

                                                        </template>
                                                        <div :class="mtdClassButton(indexSeBo,dataEgreso.valuesSeBo,'sebo')">
                                                            <input type="text" v-model="itemSeBo.value" v-on:keypress="validateDecimal($event)" class="form-control form-control-input form-control-sm" :id="'inputSeBo' + indexSeBo">
                                                        </div>
                                                        <div class="col-auto">
                                                            <button v-if="indexSeBo == (dataEgreso.valuesSeBo.length-1)" class="btn btn-success btn-sm text-xxs me-1" title="Agregar fila" @click="mtdAddItem(itemSeBo.id,'dataEgreso.valuesSeBo')">
                                                                <i class="fas fa-plus text-white"></i>
                                                            </button>
                                                            <button v-if="mtdShowButton(dataEgreso.valuesSeBo,indexSeBo,'sebo')" class="btn btn-danger btn-sm text-xxs" @click="removeItem(indexSeBo,'dataEgreso.valuesSeBo')">
                                                                <i class="fas fa-minus text-white"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-lg-4">
                                         <!-- setra -->
                                         <div class="panel-border mt-1 mb-0 px-3 pt-1 d-inline-block" @click="toggleDropdownEg(6)">
                                            <h6 class="fw-bold color-main d-inline-block">{{titleDropdownEg[6].title}}</h6>
                                            <img src="@/assets/helpers/circle-chevron-down-solid.svg" alt="" class="dropdown-icon-content d-inline-block float-end" :class="[titleDropdownEg[6].state && 'active']">
                                        </div>
                                        <div class="panel-border mb-1 pb-2" v-if="titleDropdownEg[6].state">
                                            <div class="row px-3 pt-1">
                                                <div class="col-md-12 mt-1 align-left" v-for="(itemSeTra, indexSeTra) in dataEgreso.valuesSeTra" :key="indexSeTra">
                                                    <div id="div-tem" class="form-group row">
                                                        <template>
                                                            <label v-if="itemSeTra.new == false && indexSeTra < 1" :for="'inputSeTra' + indexSeTra" class="col-sm-5 col-xl-5 col-lg-12 color-main fw-bold">{{itemSeTra.label }}</label>
                                                            <editable-cell v-else :value="itemSeTra.label" :index="indexSeTra" :array="'dataEgreso.valuesSeTra'" @update-label="updateLabel" />

                                                        </template>
                                                        <div :class="mtdClassButton(indexSeTra,dataEgreso.valuesSeTra,'setra')">
                                                            <input type="text" v-model="itemSeTra.value" v-on:keypress="validateDecimal($event)" class="form-control form-control-input form-control-sm" :id="'inputSeTra' + indexSeTra">
                                                        </div>
                                                        <div class="col-auto">
                                                            <button v-if="indexSeTra == (dataEgreso.valuesSeTra.length-1)" class="btn btn-success btn-sm text-xxs me-1" title="Agregar fila" @click="mtdAddItem(itemSeTra.id,'dataEgreso.valuesSeTra')">
                                                                <i class="fas fa-plus text-white"></i>
                                                            </button>
                                                            <button v-if="mtdShowButton(dataEgreso.valuesSeTra,indexSeTra,'setra')" class="btn btn-danger btn-sm text-xxs" @click="removeItem(indexSeTra,'dataEgreso.valuesSeTra')">
                                                                <i class="fas fa-minus text-white"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                         <!-- pe -->
                                         <div class="panel-border mt-1 mb-0 px-3 pt-1 d-inline-block" @click="toggleDropdownEg(7)">
                                            <h6 class="fw-bold color-main d-inline-block">{{titleDropdownEg[7].title}}</h6>
                                            <img src="@/assets/helpers/circle-chevron-down-solid.svg" alt="" class="dropdown-icon-content d-inline-block float-end" :class="[titleDropdownEg[7].state && 'active']">
                                        </div>
                                        <div class="panel-border mb-1 pb-2" v-if="titleDropdownEg[7].state">
                                            <div class="row px-3 pt-1">
                                                <div class="col-md-12 mt-1 align-left" v-for="(itemPe, indexPe) in dataEgreso.valuesPe" :key="indexPe">
                                                    <div id="div-tem" class="form-group row">
                                                        <template>
                                                            <label v-if="itemPe.new == false && indexPe < 1" :for="'inputPe' + indexPe" class="col-sm-5 col-xl-5 col-lg-12 color-main fw-bold">{{itemPe.label }}</label>
                                                            <editable-cell v-else :value="itemPe.label" :index="indexPe" :array="'dataEgreso.valuesPe'" @update-label="updateLabel" />

                                                        </template>
                                                        <div :class="mtdClassButton(indexPe,dataEgreso.valuesPe,'pe')">
                                                            <input type="text" v-model="itemPe.value" v-on:keypress="validateDecimal($event)" class="form-control form-control-input form-control-sm" :id="'inputPe' + indexPe">
                                                        </div>
                                                        <div class="col-auto">
                                                            <button v-if="indexPe == (dataEgreso.valuesPe.length-1)" class="btn btn-success btn-sm text-xxs me-1" title="Agregar fila" @click="mtdAddItem(itemPe.id,'dataEgreso.valuesPe')">
                                                                <i class="fas fa-plus text-white"></i>
                                                            </button>
                                                            <button v-if="mtdShowButton(dataEgreso.valuesPe,indexPe,'pe')" class="btn btn-danger btn-sm text-xxs" @click="removeItem(indexPe,'dataEgreso.valuesPe')">
                                                                <i class="fas fa-minus text-white"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </article>
                    </section>
                </span>
            </div>
            <div class="col-md-12"  v-if="action !== 'edit'">
                <cTableSheet :sendSheet="sendSheet" :pos="pos" :medical_act_id="medical_act_id"></cTableSheet>
            </div>
        </div>
    </div>
    <SweetAlert :swal="swal" ref="SweetAlert" @mtdhorario="mtdhorario"/>

</div>
</template>

<script>
import {
    mapActions
} from 'vuex';
import moment from "moment";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import EditableCell from '@/components/shared/column/EditableLabel.vue';
import cTableSheet from '../modals/cTableBalance.vue';


export default {
    name: 'c-asistencial-uciadulto-historia-monitoreohemodinamico',
    props: {
        medical_act_id: {
            type: Number,
            default: 0
        },
        dete_out: {
            type: Boolean
        },
        dete_out_doctor: {
            type: Boolean
        },
        rolname: String
    },
    components: {
        SweetAlert,
        EditableCell,
        cTableSheet,
    },
    watch: {
        medical_act_id: {
            handler: function (newValue) {
                if (newValue != 0) {
                    this.mtdtypepatient();
                }
            },
            deep: true,
            immediate: true,
        },
        'water_balance.date_entry'(newDate) {
            this.showTable = false;
            if (newDate === moment().format('YYYY-MM-DD')) {
                if(this.balance.length <= 1){
                    this.water_balance.hourBalance = null;
                }
                this.isDatePast = true;  
            } else {
                this.water_balance.hourBalance = '24'; 
                this.isDatePast = false;  
            }
        },
        'water_balance.weight'(newDate) {
            this.showTable = false;
        },
        'water_balance.hourBalance'(newDate) {
            if (!this.validation) {
                this.water_balance.hourNum = '';
            }
        },
        pos: function () {
            this.checkDateEntry(); 
        },
    },
    data() {
        return {
            swal: null,
            action: 'show',
            data: [],
            pos: 0,
            dropdown: false,
            validate: false,
            sendSheet: [],
            titleDropdown: [{
                    title: 'PARENTERAL',
                    state: true,
                },
                {
                    title: 'SEDOANALGESIA',
                    state: true,
                },
                {
                    title: 'INOTROPICOS',
                    state: true,
                },
                {
                    title: 'VASOACTIVOS',
                    state: true,
                },
                {
                    title: 'RETOS',
                    state: true,
                },
                {
                    title: 'MANITOL/SOL. HIPERTONICA',
                    state: true,
                },
                {
                    title: 'HEMODERIVADOS',
                    state: true,
                },
                {
                    title: 'TRATAMIENTO EV',
                    state: true,
                },
                {
                    title: 'ENTERAL',
                    state: true,
                },
            ],
            titleDropdownEg: [{
                    title: 'DIURESIS',
                    state: true,
                },
                {
                    title: 'DEPOSICIÓN',
                    state: true,
                },
                {
                    title: 'VÓMITO',
                    state: true,
                },
                {
                    title: 'DRENAJE',
                    state: true,
                },
                {
                    title: 'TEMPERATURA',
                    state: true,
                },
                {
                    title: 'SECRECIÓN POR BOCA',
                    state: true,
                },
                {
                    title: 'SECRECIÓN TRAQUEAL',
                    state: true,
                },
                {
                    title: 'PERIODO MENSTRUAL',
                    state: true,
                },
            ],
            //Ingresos
            dataIngreso: {
                valuesParen: [{
                        id: 1,
                        label: 'CL Na',
                        value: '',
                        value2: '',
                    },
                    {
                        id: 2,
                        label: 'Dext',
                        value: '',
                        value2: '',
                    },
                ],
                valuesSedo: [{
                        id: 1,
                        label: 'MIDAZOLAM',
                        value: '',
                        value2: '',
                    },
                    {
                        id: 2,
                        label: 'FENTANILO',
                        value: '',
                        value2: '',
                    },
                    {
                        id: 3,
                        label: 'VECURONIO',
                        value: '',
                        value2: '',
                    },
                ],
                valuesIno: [
                    {
                        id: 1,
                        label: 'Item 1',
                        value: '',
                        value2: '',
                    },
                ],
                valuesVasoActivo: [{
                        id: 1,
                        label: 'Item 1',
                        value: '',
                        value2: '',
                        new: true,
                    }
                ],
                valuesReto: [{
                        id: 1,
                        label: 'POLIGELINA',
                        value: '',
                        value2: '',
                    },
                    {
                        id: 2,
                        label: 'POTASIO',
                        value: '',
                        value2: '',
                    },
                ],
                valuesMani: [{
                        id: 1,
                        label: 'Item 1',
                        value: '',
                        value2: '',
                        new: true,
                    },
                ],
                valuesHemo: [{
                        id: 1,
                        label: 'Item 1',
                        value: '',
                        value2: '',
                        new: true,
                    },
                ],
                valuesTrata: [{
                        id: 1,
                        label: 'Item 1',
                        value: '',
                        value2: '',
                        new: true,
                    }
                ],
                valuesEn: [{
                        id: 1,
                        label: 'Item 1',
                        value: '',
                        value2: '',
                        new: true,
                    }
                ],
            },
            //Egresos
            dataEgreso: {
                valuesDiu: [
                    {
                        id: 1,
                        label: 'Item 1',
                        value: '',
                        value2: '',
                    },
                ],
                valuesDep: [
                    {
                        id: 1,
                        label: 'Item 1',
                        value: '',
                        value2: '',
                    },
                ],
                valuesVom: [
                    {
                        id: 1,
                        label: 'Item 1',
                        value: '',
                        value2: '',
                    },
                ],
                valuesDre: [
                    {
                        id: 1,
                        label: 'Item 1',
                        value: '',
                        value2: '',
                    },
                ],
                valuesTem: [
                    {
                        id: 1,
                        label: 'Item 1',
                        value: '',
                        value2: '',
                    },
                ],
                valuesSeBo: [
                    {
                        id: 1,
                        label: 'Item 1',
                        value: '',
                        value2: '',
                    },
                ],
                valuesSeTra: [
                    {
                        id: 1,
                        label: 'Item 1',
                        value: '',
                        value2: '',
                    },
                ],
                valuesPe: [
                    {
                        id: 1,
                        label: 'Item 1',
                        value: '',
                        value2: '',
                    },
                ],
            },
            totalVaso: 1,
            totalSedacion: 1,
            //mtdtypepatient
            patient_name: '',
            patient_year: '',
            insurance_id: '',
            insurance_name: '',
            type_patient: null,
            peso: '',
            datatime: [],
            modal: {
                title: '',
                modal_form: false,
                payload: {},
                dataId: []
            },
            //Balance
            validation: false,
            isDatePast: true,
            metabolic_water: {
                mañanaIn: '',
                tardeIn: '',
                parcialIn: '',
                nocheIn: '',
                totalIn: '',
            },
            insensible_losses: {
                mañanaEg: '',
                tardeEg: '',
                parcialEg: '',
                nocheEg: '',
                totalEg: '',
            },
            balance: [],
            water_balance: {
                date_name: 'BH ACTUAL',
                id: null,
                medical_act_id: null,
                date: null,
                hour: null,
                hourUci: null,
                dataIngreso: [
                ],
                dataEgreso: [
                ],
                metabolic_water: [
                ],
                insensible_losses: [
                ],
                date_entry: null,
                weight: null,
                hourBalance: null,
                hourNum: null,
                shift: null
            },
            currentDate: moment().format('YYYY-MM-DD'),
        }
    },
    created() {

    },
    mounted() {

    },
    computed: {
        cpName() {
            if (this.datatime.length > 0) {

                let date = this.datatime[this.pos].day_page;
                return this.$options.filters.formatDate(date);
            }
        },
        isGenerateButtonDisabled() {
            const isInvalidTime = this.water_balance.hourBalance <= 0 || 
                this.water_balance.hourBalance > 24 || 
                this.water_balance.hourBalance === '' || 
                this.water_balance.hourBalance === null || 
                this.water_balance.weight === "" || 
                this.water_balance.weight === null;
            return isInvalidTime ;
        }
    },
    methods: {
        ...mapActions(['get', 'post']),

        mtdClassButtonn(index, array,title) {
            let numMax = title == 'sedacion' ? this.totalSedacion : this.totalVaso;
            if (array.length === 1 && index === 0) {
                return 'col-xl-5 col-sm-12';
            }

            if (array.length > 1) {
                if (index < numMax - 1) {
                    return 'col-xl-7 col-sm-12';
                } else if (index < array.length - 1) {
                    return 'col-xl-5 col-sm-10';
                } else {
                    return 'col-xl-4 col-sm-12';
                }
            }

            return '';
        },

        mtdClassButton(index, array,title) {
            if (array.length === 1 && index === 0) {
                return 'col-xl-4 col-sm-12';
            }

            if (array.length > 1) {
               if (index < array.length - 1) {
                    return 'col-xl-4 col-sm-10';
                } else {
                    return 'col-xl-3 col-sm-12';
                }
            }

            return '';
        },

        mtdShowButton: function (array, index, title) {
            let numMax = title == 'sedacion' ? this.totalSedacion : this.totalVaso;
            if (array.length === 1 && index === 0) {
                return false;
            }

            if (array.length > 1) {
                return index >= numMax && index < array.length;
            }

        },

        mtdChangeState: function () {
            this.action = this.action == 'edit' ? 'show' : 'edit';
            if (this.action === 'edit') {
                this.$nextTick(() => {
                    this.mtdToggleClassInit();
                });
            }
        },
        mtdAddItem(id, array) {
            let num = id ;
            let newItem = {
                id: id + 1,
                label: 'Nuevo ' + num,
                value: '',
                value2: '',
                new: true
            };
            switch (array) {
                case 'dataIngreso.valuesIno':
                    this.dataIngreso.valuesIno.push(newItem);
                    break;
                case 'dataIngreso.valuesVasoActivo':
                    this.dataIngreso.valuesVasoActivo.push(newItem);
                    break;
                case 'dataIngreso.valuesMani':
                    this.dataIngreso.valuesMani.push(newItem);
                    break;
                case 'dataIngreso.valuesHemo':
                    this.dataIngreso.valuesHemo.push(newItem);
                    break;
                case 'dataIngreso.valuesTrata':
                    this.dataIngreso.valuesTrata.push(newItem);
                    break;
                case 'dataIngreso.valuesEn':
                    this.dataIngreso.valuesEn.push(newItem);
                    break;
                //Egresos
                case 'dataEgreso.valuesDiu':
                    this.dataEgreso.valuesDiu.push(newItem);
                    break;
                case 'dataEgreso.valuesDep':
                    this.dataEgreso.valuesDep.push(newItem);
                    break;
                case 'dataEgreso.valuesVom':
                    this.dataEgreso.valuesVom.push(newItem);
                    break;
                case 'dataEgreso.valuesDre':
                    this.dataEgreso.valuesDre.push(newItem);
                    break;
                case 'dataEgreso.valuesTem':
                    this.dataEgreso.valuesTem.push(newItem);
                    break;
                case 'dataEgreso.valuesSeBo':
                    this.dataEgreso.valuesSeBo.push(newItem);
                    break;
                case 'dataEgreso.valuesSeTra':
                    this.dataEgreso.valuesSeTra.push(newItem);
                    break;
                case 'dataEgreso.valuesPe':
                    this.dataEgreso.valuesPe.push(newItem);
                    break;
                default:
                    break;
            }
        },
        removeItem(index, array) {
            switch (array) {
                case 'dataIngreso.valuesIno':
                    this.dataIngreso.valuesIno.splice(index, 1);
                    break;
                case 'dataIngreso.valuesVasoActivo':
                    this.dataIngreso.valuesVasoActivo.splice(index, 1);
                    break;
                case 'dataIngreso.valuesMani':
                    this.dataIngreso.valuesMani.splice(index, 1);
                    break;
                case 'dataIngreso.valuesHemo':
                    this.dataIngreso.valuesHemo.splice(index, 1);
                    break;
                case 'dataIngreso.valuesTrata':
                    this.dataIngreso.valuesTrata.splice(index, 1);
                    break;
                case 'dataIngreso.valuesEn':
                    this.dataIngreso.valuesEn.splice(index, 1);
                    break;
                //Egresos
                case 'dataEgreso.valuesDiu':
                    this.dataEgreso.valuesDiu.splice(index, 1);
                    break;
                case 'dataEgreso.valuesDep':
                    this.dataEgreso.valuesDep.splice(index, 1);
                    break;
                case 'dataEgreso.valuesVom':
                    this.dataEgreso.valuesVom.splice(index, 1);
                    break;
                case 'dataEgreso.valuesDre':
                    this.dataEgreso.valuesDre.splice(index, 1);
                    break;
                case 'dataEgreso.valuesTem':
                    this.dataEgreso.valuesTem.splice(index, 1);
                    break;
                case 'dataEgreso.valuesSeBo':
                    this.dataEgreso.valuesSeBo.splice(index, 1);
                    break;
                case 'dataEgreso.valuesSeTra':
                    this.dataEgreso.valuesSeTra.splice(index, 1);
                    break;
                case 'dataEgreso.valuesPe':
                    this.dataEgreso.valuesPe.splice(index, 1);
                    break;
                default:
                    break;
            }

        },
        updateLabel(label, index, array) {
            switch (array) {
                case 'dataIngreso.valuesIno':
                    this.dataIngreso.valuesIno[index].label = label;
                    break;
                case 'dataIngreso.valuesVasoActivo':
                    this.dataIngreso.valuesVasoActivo[index].label = label;
                    break;
                case 'dataIngreso.valuesMani':
                    this.dataIngreso.valuesMani[index].label = label;
                    break;
                case 'dataIngreso.valuesHemo':
                    this.dataIngreso.valuesHemo[index].label = label;
                    break;
                case 'dataIngreso.valuesTrata':
                    this.dataIngreso.valuesTrata[index].label = label;
                    break;
                case 'dataIngreso.valuesEn':
                    this.dataIngreso.valuesEn[index].label = label;
                    break;
                //Egresos
                case 'dataEgreso.valuesDiu':
                    this.dataEgreso.valuesDiu[index].label = label;
                    break;
                case 'dataEgreso.valuesDep':
                    this.dataEgreso.valuesDep[index].label = label;
                    break;
                case 'dataEgreso.valuesVom':
                    this.dataEgreso.valuesVom[index].label = label;
                    break;
                case 'dataEgreso.valuesDre':
                    this.dataEgreso.valuesDre[index].label = label;
                        break;
                case 'dataEgreso.valuesTem':
                    this.dataEgreso.valuesTem[index].label = label;
                    break;
                case 'dataEgreso.valuesSeBo':
                    this.dataEgreso.valuesSeBo[index].label = label;
                    break;
                case 'dataEgreso.valuesSeTra':
                    this.dataEgreso.valuesSeTra[index].label = label;
                    break;
                case 'dataEgreso.valuesPe':
                    this.dataEgreso.valuesPe[index].label = label;
                    break;
                default:
                    break;
            }
        },
        isNumber: function (evt) {
            evt = evt ? evt : window.event;
            var charCode = evt.which ? evt.which : evt.keyCode;
            if (
                charCode > 31 &&
                (charCode < 48 || charCode > 57) &&
                charCode !== 46
            ) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        validateDecimal: function (evt) {
            evt = evt ? evt : window.event;
            var charCode = evt.which ? evt.which : evt.keyCode;
            if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },

        checkDateEntry: function () {
            if (this.water_balance.date !== null) {
                if (moment(this.water_balance.date_entry).isSame(this.water_balance.date)) {
                    this.isDatePast = true; 
                } else {
                    this.isDatePast = false; 
                }
            } else {
                if (this.water_balance.date_entry === moment().format('YYYY-MM-DD')) {
                    this.isDatePast = true;  
                } else {
                    this.isDatePast = false;  
                }
            }
        },

        //Registrar Balance
        mtdadd: function () {
            this.$refs.SweetAlert.horario();
        },

        mtdhorario: function (hour, payload) {
            this.water_balance.hourUci = hour
            this.generateBalance();
        },

        generateBalance() {
            if (this.water_balance.date_entry === this.currentDate) {
                this.showTable = true;
                this.mtdGetAgua();
            } else  {
                this.water_balance.hourBalance = 24;
                this.mtdGetAgua();
                this.showTable = true;
            }
        },

        mtdGetAgua: function () {
            const fecha = this.water_balance.date_entry;
            const hora = this.water_balance.hourBalance;
            const peso = this.water_balance.weight;
            const year = this.patient_year;
            this.post({
                url: this.$store.getters.get__url + "/Uciadulto/mtdGetAgua",
                token: this.$store.getters.get__token,
                params: {
                    fecha: fecha,
                    hora: hora,
                    peso: peso,
                    year: year
                },
            })
            .then((response) => {
                this.water_balance.metabolic_water = [];
                this.water_balance.insensible_losses = [];
                if (response.statusCode === 200) {
                    this.metabolic_water = {
                        mañanaIn: response.data.mañanaIn,
                        tardeIn: response.data.tardeIn,
                        parcialIn: response.data.parcialIn,
                        nocheIn: response.data.nocheIn,
                        totalIn: response.data.totalIn,
                    }
                    this.insensible_losses = {
                        mañanaEg: response.data.mañanaEg,
                        tardeEg: response.data.tardeEg,
                        parcialEg: response.data.parcialEg,
                        nocheEg: response.data.nocheEg,
                        totalEg: response.data.totalEg,
                    }
                    this.water_balance.metabolic_water.push(this.metabolic_water);
                    this.water_balance.insensible_losses.push(this.insensible_losses);
                    this.water_balance.hourNum = response.data.horas;
                    this.saveBalance();
                    this.metabolic_water = {
                        mañanaIn: '',
                        tardeIn: '',
                        parcialIn: '',
                        nocheIn: '',
                        totalIn: '',
                    },
                    this.insensible_losses = {
                        mañanaEg: '',
                        tardeEg: '',
                        parcialEg: '',
                        nocheEg: '',
                        totalEg: '',
                    }
                }else{
                    this.water_balance.metabolic_water = [];
                    this.water_balance.insensible_losses = [];
                }
            })
            .catch((errors) => {
                this.$refs.SweetAlert.showError(errors);
            });
        },

        saveBalance: function(){
            this.syncDataIngreso();
            this.syncDataEgreso();
            this.water_balance.medical_act_id = this.medical_act_id;
            this.post({
                url: this.$store.getters.get__url + "/Uciadulto/balance",
                token: this.$store.getters.get__token,
                params: this.water_balance,
            })
            .then((response) => {
                if(response.data.state == 0){
                    this.$refs.SweetAlert.showSuccess(response.message);
                    this.water_balance = {
                        date_name: 'BH ACTUAL',
                        id: null,
                        medical_act_id: null,
                        date: null,
                        hour: null,
                        dataIngreso: [
                        ],
                        dataEgreso: [
                        ],
                        metabolic_water: [
                        ],
                        insensible_losses: [
                        ],
                        date_entry: null,
                        weight: null,
                        hourBalance: null,
                        hourNum: null,
                        shift: null
                    }
                    //this.mtdGetBalance();
                }else if(response.data.state == 1){
                    this.$refs.SweetAlert.showWarning(response.message);
                    this.water_balance = {
                        date_name: 'BH ACTUAL',
                        id: null,
                        medical_act_id: null,
                        date: null,
                        hour: null,
                        dataIngreso: [
                        ],
                        dataEgreso: [
                        ],
                        metabolic_water: [
                        ],
                        insensible_losses: [
                        ],
                        date_entry: null,
                        weight: null,
                        hourBalance: null,
                        hourNum: null,
                        shift: null
                    }
                }
                this.validation = false,
                this.showTable = false,
                this.posEditField = null, 
                this.editField = ''
            })
            .catch((errors) => {
                this.$refs.SweetAlert.showError(errors);
            });
        },

        syncDataIngreso() {
            //this.water_balance.dataIngreso.push(this.dataIngreso);
            this.water_balance.dataIngreso = this.dataIngreso;
            this.dataIngreso = {
                valuesParen: [{
                        id: 1,
                        label: 'CL Na',
                        value: '',
                        value2: '',
                    },
                    {
                        id: 2,
                        label: 'Dext',
                        value: '',
                        value2: '',
                    },
                ],
                valuesSedo: [{
                        id: 1,
                        label: 'MIDAZOLAM',
                        value: '',
                        value2: '',
                    },
                    {
                        id: 2,
                        label: 'FENTANILO',
                        value: '',
                        value2: '',
                    },
                    {
                        id: 3,
                        label: 'VECURONIO',
                        value: '',
                        value2: '',
                    },
                ],
                valuesIno: [
                    {
                        id: 1,
                        label: 'Item 1',
                        value: '',
                        value2: '',
                    },
                ],
                valuesVasoActivo: [{
                        id: 1,
                        label: 'Item 1',
                        value: '',
                        value2: '',
                        new: true,
                    }
                ],
                valuesReto: [{
                        id: 1,
                        label: 'POLIGELINA',
                        value: '',
                        value2: '',
                    },
                    {
                        id: 2,
                        label: 'POTASIO',
                        value: '',
                        value2: '',
                    },
                ],
                valuesMani: [{
                        id: 1,
                        label: 'Item 1',
                        value: '',
                        value2: '',
                        new: true,
                    },
                ],
                valuesHemo: [{
                        id: 1,
                        label: 'Item 1',
                        value: '',
                        value2: '',
                        new: true,
                    },
                ],
                valuesTrata: [{
                        id: 1,
                        label: 'Item 1',
                        value: '',
                        value2: '',
                        new: true,
                    }
                ],
                valuesEn: [{
                        id: 1,
                        label: 'Item 1',
                        value: '',
                        value2: '',
                        new: true,
                    }
                ],
            }
        },

        syncDataEgreso() {
            //this.water_balance.dataEgreso.push(this.dataEgreso);
            this.water_balance.dataEgreso = this.dataEgreso;
            this.dataEgreso = {
                valuesDiu: [
                    {
                        id: 1,
                        label: 'Item 1',
                        value: '',
                        value2: '',
                    },
                ],
                valuesDep: [
                    {
                        id: 1,
                        label: 'Item 1',
                        value: '',
                        value2: '',
                    },
                ],
                valuesVom: [
                    {
                        id: 1,
                        label: 'Item 1',
                        value: '',
                        value2: '',
                    },
                ],
                valuesDre: [
                    {
                        id: 1,
                        label: 'Item 1',
                        value: '',
                        value2: '',
                    },
                ],
                valuesTem: [
                    {
                        id: 1,
                        label: 'Item 1',
                        value: '',
                        value2: '',
                    },
                ],
                valuesSeBo: [
                    {
                        id: 1,
                        label: 'Item 1',
                        value: '',
                        value2: '',
                    },
                ],
                valuesSeTra: [
                    {
                        id: 1,
                        label: 'Item 1',
                        value: '',
                        value2: '',
                    },
                ],
                valuesPe: [
                    {
                        id: 1,
                        label: 'Item 1',
                        value: '',
                        value2: '',
                    },
                ],
            }
        },

        //Pag
        nextPage: function () {
            this.pos = (this.pos == this.data.length - 1) ? this.pos : this.pos + 1
            this.sendSheet = this.data[this.pos];
        },

        backPage: function () {
            this.pos = (this.pos == 0) ? this.pos : this.pos - 1
            this.sendSheet = this.data[this.pos];

        },
        //Dropdown
        toggleDropdown(index) {
            this.titleDropdown[index].state = !this.titleDropdown[index].state;
        },
        toggleDropdownEg(index) {
            this.titleDropdownEg[index].state = !this.titleDropdownEg[index].state;
        },
        beforeEnter(el) {
            el.style.height = 0
        },
        enter(el) {
            el.style.height = el.scrollHeight + 'px'
        },
        beforeLeave(el) {
            el.style.height = el.scrollHeight + 'px'
        },
        leave(el) {
            el.style.height = 0
        },
        mtdToggleClassInit: function () {
            if (this.action === 'edit') {
                this.tab = 1;
                const div1 = this.$refs.div1;
                const div2 = this.$refs.div2;
                const div1_content = this.$refs.div1_content;
                const div2_content = this.$refs.div2_content;

                if (div1 && div2 && div1_content && div2_content) {
                    div1.classList.remove('orden1');
                    div1.classList.add('active-section');
                    div1_content.classList.remove('visually-hidden');

                    div2.classList.add('orden2');
                    div2.classList.remove('active-section');
                    div2_content.classList.add('visually-hidden');
                } else {
                    console.warn("Uno o más elementos no están disponibles en el DOM.");
                }
            }
            this.clearDropdown();
        },

        toggleClass: function (id, event) {
            if (event.target !== event.currentTarget) {
                return;
            }
            this.tab = id;
            let div1 = document.getElementById('div1');
            let div2 = document.getElementById('div2');
            let div1_content = document.getElementById('div1-content');
            let div2_content = document.getElementById('div2-content');

            if (id == 1) {
                div1.classList.remove('orden1');
                div1.classList.add('active-section');
                div1_content.classList.remove('visually-hidden');
                div2.classList.add('orden2');
                div2.classList.remove('active-section');
                div2_content.classList.add('visually-hidden');
            } else if (id == 2) {
                div1.classList.add('orden1');
                div1.classList.remove('active-section');
                div1_content.classList.add('visually-hidden');
                div2.classList.remove('orden2');
                div2.classList.add('active-section');
                div2_content.classList.remove('visually-hidden');
            } else if (id == 3) {
                div1.classList.add('orden1');
                div1.classList.remove('active-section');
                div1_content.classList.add('visually-hidden');
                div2.classList.add('orden2');
                div2.classList.remove('active-section');
                div2_content.classList.add('visually-hidden');
            }
            this.clearDropdown();
        },
        mtdtypepatient: function () {
            this.post({
                    url: this.$store.getters.get__url + "/Uciadulto/salePatient",
                    token: this.$store.getters.get__token,
                    params: {
                        medical_act_id: this.medical_act_id
                    },
                })
                .then((response) => {
                    this.type_patient = response.data.type_patient;
                    this.date_in = response.data.date_ind;
                    this.date_out = response.data.date_out;
                    this.insurance_id = response.data.insurance ? response.data.insurance.id : null;
                    this.insurance_name = response.data.insurance ? response.data.insurance.name : '';
                    this.patient_name = response.data.patient.name;
                    this.patient_year = response.data.patient.year;
                })
                .catch((errors) => {
                    this.$refs.SweetAlert.showError(errors);
                });
        },
        clearDropdown(){
            this.titleDropdown = [{
                    title: 'PARENTERAL',
                    state: true,
                },
                {
                    title: 'SEDOANALGESIA',
                    state: true,
                },
                {
                    title: 'INOTROPICOS',
                    state: true,
                },
                {
                    title: 'VASOACTIVOS',
                    state: true,
                },
                {
                    title: 'RETOS',
                    state: true,
                },
                {
                    title: 'MANITOL/SOL. HIPERTONICA',
                    state: true,
                },
                {
                    title: 'HEMODERIVADOS',
                    state: true,
                },
                {
                    title: 'TRATAMIENTO EV',
                    state: true,
                },
                {
                    title: 'ENTERAL',
                    state: true,
                },
            ],
            this.titleDropdownEg = [{
                    title: 'DIURESIS',
                    state: true,
                },
                {
                    title: 'DEPOSICIÓN',
                    state: true,
                },
                {
                    title: 'VÓMITO',
                    state: true,
                },
                {
                    title: 'DRENAJE',
                    state: true,
                },
                {
                    title: 'TEMPERATURA',
                    state: true,
                },
                {
                    title: 'SECRECIÓN POR BOCA',
                    state: true,
                },
                {
                    title: 'SECRECIÓN TRAQUEAL',
                    state: true,
                },
                {
                    title: 'PERIODO MENSTRUAL',
                    state: true,
                },
            ]
        }
    },
    filters: {
        formatDate(date) {
            return date != null ? moment(date).format("DD/MM/YYYY") : "-";
        },
        formatTime(time) {
            let arTime = time.split(":");
            if (arTime[0] <= 12) {
                return time + " am";
            } else {
                return arTime[0] - 12 + ":" + arTime[1] + " pm";
            }
        },
    },
}
</script>
<style scoped>
 .background {
    background-color: #ffffff;
    display: grid;
    place-items: center;
    min-height: 260px;
  }

  .gallery {
    display: flex;
    flex-wrap: wrap;
    gap: 13px;
    width: 100%;
  }

  .card {
    position: relative;
    left: 0px;
    /*width: 50px;*/
    border-radius: 16px;
    /*height: 560px;*/
    overflow: hidden;
    transition: 0.4s ease-in-out;
    color: #ffffff;
    box-shadow: 0 5px 12px rgba(0, 0, 0, 0.5);
    /*flex: 0.50;*/
    flex: 0.2 1 auto;
  }

  .card:hover {
    font-weight: bold;
    cursor: pointer;
    border-radius: 8px;
  }

  .card-date {
    position: relative;
    left: 0px;
    border-radius: 16px;
    overflow: hidden;
    transition: 0.4s ease-in-out;
    box-shadow: 0 5px 12px rgba(0, 0, 0, 0.5);
    flex: 0.2 1 auto;
    padding: 16px;
  }

  .card-date:hover {
    font-weight: bold;
    border-radius: 8px;
  }


  .active-section {
    flex: 4;
    background-color: #ffffff !important;
    cursor: auto !important;
    border-radius: 16px !important;
    color: #000000;
  }

  .orden1 {
    background-image: url('../../../../assets/orden_01.jpg');
    background-position: center 10%;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .orden2 {
    background-image: url('../../../../assets/orden_02.jpg');
    background-position: center 10%;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .orden3 {
    background-image: url('../../../../assets/orden_03.jpg');
    background-position: center 10%;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .btn-outline-main {
    --bs-btn-color: #900052;
    --bs-btn-border-color: #900052;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #900052;
    --bs-btn-hover-border-color: #900052;
    --bs-btn-focus-shadow-rgb: 13, 110, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #900052;
    --bs-btn-active-border-color: #900052;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #900052;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #900052;
    --bs-gradient: none;
  }

  #floating-button {
    position: fixed;
    bottom: 60px;
    right: 20px;
    z-index: 1000;
  }

  .button_comando {
    background-color: #900052;
    color: white;
    border: none;
    border-radius: 50%;
    width: 55px;
    height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: background-color 0.3s;
  }

  .button_comando:hover {
    background-color: #50002d;
    color: #fff;
  }

  .i_comando {
    font-size: 20px;
  }
</style>