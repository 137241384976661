<template>
<div>
    <div class="py-2 px-3">
        <div id="div-modal-cxc" class="d-flex justify-content-center">
            <div id="div-cxc" class="w-100">   
                <div class="card-body w-100">
                    <div class="row">
                        <div class="col-md-12 py-2">
                            <div class="row d-flex mx-0">
                                <div class="col-xl-10 col-lg-10 col-md-10 col-sm-12 col-xs-12 px-1 mb-1">
                                    <div class="input-group input-group">
                                        <span class="input-group-text text-white bg-main"
                                        ><i class="fas fa-search"></i
                                        ></span>
                                        <input
                                        @keyup="calculateData(7)"
                                        v-model="search"
                                        type="text"
                                        class="form-control form-control"
                                        placeholder="Buscar"
                                        />
                                    </div>
                                </div>
                                <div class="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-xs-12 btn-home">
                                    <button
                                        type="button"
                                        class="btn btn-bm-noradius w-100"
                                        @click="mtdModalShow(0,null)"
                                    >
                                        <i class="fas fa-plus"></i> Nueva
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 mt-2" id="content-timelinetable">
                            <table id="tb-cxc" class="table table-bordered border-main">
                                <caption class="py-0 my-0"></caption>
                                <thead class="bg-main">
                                    <tr class=" table-th text-white text-center">
                                        <th class="table-th-number">N°</th>
                                        <th>ESPECIALIDAD</th>
                                        <th>TIPO</th>
                                        <th class="table-th-number">ESTADO</th>
                                        <th class="table-th-acciones">ACCIONES</th>
                                    </tr>
                                </thead>
                                <tbody class="border-main">
                                    <tr class="table-body text-center" v-if="cpData.length === 0">
                                        <td colspan="4" class="text-center">No hay registros</td>
                                    </tr>
                                    <tr class="table-body text-center" v-for="(item, index) in cpData" :key="index">
                                        <td>
                                            {{mtdNumber(index)}}
                                        </td>
                                        <td class="">
                                            {{item.name}}
                                        </td>
                                        <td>
                                            {{item.type == 1 ? "DOCTOR" : "ENFERMERA"}}
                                        </td>
                                        <td>
                                            <div id="state_speciality" v-if="item.state == 1">
                                                <div class="bg-success text-white">Activo</div>
                                            </div>
                                            <div id="state_speciality" v-else>
                                                <div class="bg-secondary text-white">Inactivo</div>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="text-center d-flex justify-content-center">
                                                <button
                                                    class="btn btn-sm a-success text-white" @click="mtdModalShow(1, item)">
                                                    <i class="fa fa-edit"></i>
                                                </button>
                                                <button 
                                                    class="btn btn-sm a-danger text-white" @click="Datadetail(item.id ,2)">
                                                    <i class="fas fa-trash-alt"></i>
                                                </button>
                                                </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mt-3 text-center">
                            <!-- paginado -->
                            <div v-if="cpData.length > 0" class="d-flex justify-content-center mb-2">
                                <nav aria-label="Page navigation example">
                                    <ul class="pagination mb-1">
                                        <li class="page-item">
                                            <a class="page-link page" href="javascript:void(0)" @click="backPage">Anterior</a>
                                        </li>
                                        <li v-if="limitedStepPagination[0] !== 1" class="page-item">
                                            <a :class="selectPage === 1 ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(1)">1</a>
                                        </li>
                                        <li v-if="limitedStepPagination[0] > 2" class="page-item disabled">
                                            <span class="page-link">...</span>
                                        </li>

                                        <li v-for="(item, index) in limitedStepPagination" :key="index" class="page-item">
                                            <a :class="selectPage === item ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(item)">{{ item }}
                                            </a>
                                        </li>
                                        <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] <stepPagination.length - 1" class="page-item disabled">
                                            <span class="page-link">...</span>
                                        </li>
                                        <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] !== stepPagination.length" class="page-item">
                                            <a :class="selectPage === limitedStepPagination.length + 2 ? 'page-link active':'page-link'" href="javascript:void(0)" @click="selectedPage(stepPagination.length)">{{ stepPagination.length }}
                                            </a>
                                        </li>
                                        <li class="page-item">
                                            <a class="page-link page" href="javascript:void(0)" @click="nextPage">Siguiente</a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <cModalEspecialidadVue :input="modal.input" :num="modal.num" :payload="modal.payload" :title="modal.title" 
    :boo_modal="modal.modal_form" @closeModal="closeModal" @Datadetail="Datadetail" />
    <SweetAlert :swal="swal" ref="SweetAlert" />
</div>
</template>

<script>
import {
    mapActions
} from "vuex";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import cModalEspecialidadVue from '../../Modals/cModalEspecialidad.vue';
export default {
    name: "c-mantenedores-pages-especialidad",
    components: {
        SweetAlert,
        cModalEspecialidadVue
    },
    data() {
        return {
            swal: null,
            search:"",
            dataShow: [],
            dataSubSpecialties: [],
            dataSpecialtys: [],
            estado: "activo",
            specialty: {
                name: "",
                id: "",
                state: "",
            },
            filter:{
                specialty_id_filtro: ""
            },
            posEdit:null,

            modal: {
                title: '',
                modal_form: false,
                num: null,
                payload: {},
            },
            action:"",
            //Pag
            page: 0,
            selectPage: 1,
            stepPagination: [],
            totalPagesToShow: 3,
        };
    },
    watch: {
        selectPage() {
            this.calculateData(7);
        },
    },
    computed: {
        classTh() {
            return 'align-middle border border-5 bg-main border-white';
        },
        cpData() {
            return this.dataShow;
        },
        limitedStepPagination() {
            const totalPages = this.stepPagination.length;
            const currentPage = this.selectPage;

            let startPage = 1;
            let endPage = totalPages;
            if (totalPages > this.totalPagesToShow) {
                const halfPagesToShow = Math.floor(this.totalPagesToShow / 2);

                if (currentPage > halfPagesToShow) {
                    startPage = currentPage - halfPagesToShow;
                    endPage = currentPage + halfPagesToShow - 1;

                    if (endPage > totalPages) {
                        endPage = totalPages;
                        startPage = totalPages - this.totalPagesToShow + 1;
                    }
                } else {
                    endPage = this.totalPagesToShow;
                }
            }

            return Array.from({
                    length: endPage - startPage + 1,
                },
                (_, i) => startPage + i
            );
        },

    },
    created() {
        this.mtdGetData();
    },
    methods: {
        ...mapActions(["get", "post"]),

        //Traer Data
        mtdGetData: function () {
            this.get({
                url: this.$store.getters.get__url + "/Specialty",
                token: this.$store.getters.get__token,
            })
            .then((response) => {
                this.dataSpecialtys = response.data;
                this.calculateData(7);
            })
            .catch((errors) => {
                this.$refs.SweetAlert.showError(errors);
            });
        },

        calculateData(items) {
            if (!this.dataSpecialtys || !this.dataSpecialtys.length) {
                return (this.dataShow = []);
            }
            let filteredData = this.dataSpecialtys;
            let indexInitial = this.selectPage;
            if (this.search.length >= 2 && this.search != "") {
                let query = this.search.toString().toUpperCase();
                filteredData = this.dataSpecialtys.filter((element) => {
                    let name = element.name.toString().toUpperCase();
                    return name.includes(query);
                });
                indexInitial = 1;
            }

            let totalPages = Math.ceil(filteredData.length / items);
            this.stepPagination = Array.from({
                    length: totalPages,
                },
                (_, index) => index + 1
            );
            //verificar si no hay data vacia cuando se borra
            if(this.action == 'd'){
                if(this.dataShow.length == 1){
                    if(indexInitial > 1 ){
                        indexInitial-=1;
                    }else{
                        indexInitial =1;
                    }
                }
            }
            let startIndex = (indexInitial - 1) * items;
            let endIndex = startIndex + (items - 1);
            this.dataShow = filteredData.slice(startIndex, endIndex + 1);
            //this.count = this.count + 1;
        },

        mtdModalShow: function (num, payload) {
            this.modal.title = (num == 0) ? 'Registrar Especialidad' : 'Editar Especialidad';
            this.modal.modal_form = true;
            this.modal.num = num;
            this.modal.payload = payload;
            //console.log(payload);
        },

        closeModal: function () {
            this.modal = {
                title: '',
                modal_form: false,
                num: null,
                payload: {},
            }
        },

        Datadetail: function (payload_detail, num_detail) {
            if (num_detail == 0) {
                payload_detail.action = 'c';
                this.addDatadetail(payload_detail, num_detail);
            } else if (num_detail == 1) {
                payload_detail.action = 'u';
                this.addDatadetail(payload_detail, num_detail);
            } else {
                let data = {
                    action : 'd',
                    id : payload_detail
                }
                this.filter.specialty_id_filtro=payload_detail;
                this.mtdGetDataSubspecialty();
                this.$refs.SweetAlert.showConfirmSimple2("","¿Estás seguro?","warning","Eliminar") 
                .then((result) => {
                    if (result.value) {
                        if(this.dataSubSpecialties.length==0){
                            this.addDatadetail(data, num_detail);
                        } else {
                            let mensaje="";
                            this.dataSubSpecialties.forEach(element => {
                                mensaje+=element.Name +"/ ";
                            });
                            mensaje = mensaje.slice(0, -2);
                            
                            this.$refs.SweetAlert.showConfirmSimple2("Se eliminaran las Subespecialidades:",mensaje,"warning","Continuar") 
                            .then((result) => {
                                if (result.value) {
                                    this.dataSubSpecialties.forEach(element=>{
                                        this.mtdDeleteDataSubspecialty(element);
                                    });
                                    this.addDatadetail(data, num_detail);
                                }
                            });
                        }
                    }
                });
            }
        },

        addDatadetail: function (payload_detail, num_detail) {
            this.post({
                    url: this.$store.getters.get__url + "/RecursosHumanos/crudSpecialty",
                    token: this.$store.getters.get__token,
                    params: payload_detail,
                })
                .then(response => {
                    if (num_detail == 2) {
                        this.$refs.SweetAlert.showSuccess("El registro ha sido eliminado");
                        this.action = payload_detail.action;
                        this.mtdGetData();
                    } else {
                        this.$refs.SweetAlert.showSuccess(num_detail == 0 ? "Registro Exitoso" : "Modificación Exitosa");
                        this.closeModal()
                        this.mtdGetData();
                    }
                })
                .catch(error => {
                    this.$refs.SweetAlert.showError(error);
                })
        },

        checkEmptyPageAndUpdate() {
            this.mtdGetData();
            if (this.dataShow.length === 0 && this.selectPage > 1) {
                this.backPage();
                this.mtdGetData();
            }
        },

         //metodo eliminar subespecialidades
        mtdDeleteDataSubspecialty: function (objeto) {
            let data = {
                action : 'd',
                id : objeto
            }
            this.post({
                url: this.$store.getters.get__url + "/SubSpecialty/crudSubSpecialty",
                token: this.$store.getters.get__token,
                params: data,
            })
            .catch((errors) => {
                this.$refs.SweetAlert.showError(errors);
            });
        },

        //metodo obtener data de subespecialidades
        mtdGetDataSubspecialty: function () {
            this.post({
                url: this.$store.getters.get__url + "/SubSpecialty/filter",
                token: this.$store.getters.get__token,
                params: this.filter
            })
            .then((response) => {
                this.dataSubSpecialties = response.data;
            })
            .catch((errors) => {
                this.$refs.SweetAlert.showError(errors);
            });
        },

        //Paginacion
        backPage() {
            this.selectPage = this.selectPage > 1 ? this.selectPage - 1 : 1;
        },
        nextPage() {
            this.selectPage =
                this.selectPage < this.stepPagination.length ?
                this.selectPage + 1 :
                this.stepPagination.length;
        },
        selectedPage(page) {
            this.selectPage = page;
        },
        mtdNumber: function(index){
            return (this.selectPage-1)*7 + (index + 1)
        }
    }
}
</script>
