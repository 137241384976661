<template>
  <div class="col-md-12">
    <div class="row d-flex">
      <div class="panel-border my-1">
        <div class="row d-flex text-xs">
          <div class="col-md-12 bg-main text-white text-center py-2">
            <span class="fw-bold">RP - {{ typeService }}</span>
          </div>
        </div>
      </div>
      <div class="border border-1 p-3" v-if="data.length > 0">
        <!-- PAG -->
        <div class="row d-flex justify-content-end mt-1">
          <div class="col-12 px-0">
            <nav
              aria-label="Page navigation example"
              class="pagination-container"
            >
              <ul class="pagination pagination-xs justify-content-end">
                <li class="page-item">
                  <a
                    style="cursor: pointer"
                    href="javascript:void(0)"
                    class="page-link"
                    @click="backPage"
                    >«</a
                  >
                </li>
                <template>
                  <li class="page-item">
                    <a class="page-link active" href="javascript:void(0)">PAG {{pos + 1 }} - {{cpDateName}}</a>
                  </li>
                </template>
                <li class="page-item">
                  <a style="cursor: pointer" class="page-link" @click="nextPage"
                    >»</a
                  >
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <!--FIN PAG-->

        <div class="row d-flex">
            <div class="panel-border my-1">
                <div class="row d-flex text-xs">
                    <div class="col-md-4 bg-main text-white py-2">
                        <span class="fw-bold">DOCTOR </span>
                    </div>
                    <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12 py-2">
                        <span class="fw-bold">{{ cpDoctor }}</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="row d-flex">
            <div class="panel-border my-1">
                <div class="row px-3 d-sm-flex">
                    <div class="col-sm-4 my-1">
                        <div id="div-te" class="form-group row align-left">
                            <label for="te" class="col-sm-8 color-main fw-bold">DÍAS HOSP.</label>
                            <div class="col-xl-4 col-sm-12">
                            <input 
                                type="text" 
                                placeholder="-" 
                                class="form-control form-control-input form-control-sm" 
                                id="te" 
                                :value="medical_evolutions.day_hosp ?? '-'" 
                                disabled
                            >
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-4 my-1">
                        <div id="div-fi" class="form-group row align-left">
                            <label for="fi" class="col-sm-6 color-main fw-bold">DÍAS SOP</label>
                            <div class="col-xl-6 col-sm-12">
                            <input 
                                type="text" 
                                placeholder="-" 
                                class="form-control form-control-input form-control-sm" 
                                id="fi" 
                                :value="medical_evolutions.day_sop ?? '-'" 
                                disabled
                            >
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-4 my-1">
                        <div id="div-curso" class="form-group row align-left">
                            <label for="curso" class="col-sm-6 color-main fw-bold">DÍAS UCI</label>
                            <div class="col-xl-6 col-sm-12">
                            <input 
                                type="text" 
                                placeholder="-" 
                                class="form-control form-control-input form-control-sm" 
                                id="curso" 
                                :value="medical_evolutions.day_uci ?? '-'" 
                                disabled
                            >
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-12 my-1">
                        <div id="div-story" class="form-group row align-left mb-1">
                            <label for="story" class="col-sm-12 col-md-3 col-xl-3 color-main fw-bold">OBSERVACIÓN</label>
                            <div class="col-xl-9 col-sm-12 col-md-12">
                            <textarea 
                                rows="5" 
                                placeholder="-" 
                                class="form-control text-xs" 
                                aria-label="With textarea" 
                                id="story" 
                                :value="medical_evolutions.observation ?? '-'" 
                                disabled
                            ></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row d-flex">
            <div class="panel-border mb-1">
                <div class="row px-3">
                    <div class="col-sm-12 col-xl-12 my-1">
                        <div id="div-recipe" class="form-group row d-flex align-left mb-1">
                            <label class="col-sm-12 color-main fw-bold ">RECETAS</label>
                            <div class="col-xl-12 col-sm-12">
                                <ul class="list-group text-xs">
                                    <li class="list-group-item" v-for="(item, index) in cpRecipe" :key="index">
                                        <span>{{ index + 1 }}. {{ item.molecule }} - {{ item.dosis }} - {{ item.presentation }} - {{ mtdIndicacion(item) }}</span>
                                    </li>
                                    <li class="list-group-item mb-2" v-if="cpRecipe.length == 0">
                                        No se han registrado Recetas
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row d-flex">
            <div class="panel-border mb-1">
                <div class="row px-3  mb-1">
                    <div class="col-sm-12 col-xl-6 my-1">
                        <div id="div-other" class="form-group row align-left">
                            <label for="other" class="col-sm-12 color-main fw-bold">RECOMENDACIONES</label>
                            <div class="col-xl-12 col-sm-12">
                                <textarea rows="4"  :value="medical_evolutions.recomendation.trim() || '-'"  class="form-control text-xs" aria-label="With textarea" id="other" disabled></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-xl-6 my-1">
                        <div id="div-other" class="form-group row align-left">
                            <label for="other" class="col-sm-12 color-main fw-bold">DIETA</label>
                            <div class="col-xl-12 col-sm-12">
                                <textarea rows="4"  :value="medical_evolutions.dieta.trim() || '-'"  class="form-control text-xs" aria-label="With textarea" id="other" disabled></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

      </div>
      <div class=" border border-main p-5" v-else>
          <div class=" text-center">
          <h4>No hay Rps registradas</h4>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "c-medicalAct-reportRp",
  data() {
    return {
      subpage: "buttons",
      pos: 0,
      medical_evolutions: {},
    };
  },
  props: {
    data: Array,
    typeService: String,
  },
  watch: {
    typeService: {
      handler: function (newValue) {
        this.pos = this.data.length - 1;
        this.medical_evolutions = this.data[this.pos];
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    cpDoctor() {
      let name = "";
      let last_name = "";
      let doctor = this.medical_evolutions.doctor;
      if (doctor) {
        name = doctor.name ?? "";
        last_name = doctor.last_name ?? "";
      }
      return name + " " + last_name;
    },
    cpDateName() {
      let date = this.$options.filters.formatDate(this.medical_evolutions.date);
      let hour = this.$options.filters.formatTime(this.medical_evolutions.hour);

      return date + " " + hour;
    },
    cpDataDay() {
      return [
        { key: "Dias hosp", value: this.medical_evolutions.day_hosp },
        { key: "Dias SOP", value: this.medical_evolutions.day_sop },
        { key: "Días UCI", value: this.medical_evolutions.day_uci },
      ];
    },
    cpRecipe() {
        return JSON.parse(this.medical_evolutions.recipe);
    },
  },
  methods: {
    returnHome() {
      this.$emit("mtdChangePage", "home");
    },

    mtdIndicacion(item) {
      if(item.type !== 2){
        let frequency = item.frequency;
        let duration = item.duration;

        let msg = frequency == 1 ? ' hora  x' : ' horas x';
        let msgduration = duration == 1 ? ' dia' : ' días';
        let msgfinal = "( cada " +  frequency  + msg  + " "+ duration + " " + msgduration + " )";
        return msgfinal.toUpperCase();
      }else{
        return "";
      }
    },

    //Pag
    mtdback(subpage) {
      this.$emit("mtdChangeSubPage", subpage);
    },
    nextPage: function () {
      this.pos = this.pos == this.data.length - 1 ? this.pos : this.pos + 1;
      this.medical_evolutions = this.data[this.pos];
    },
    backPage: function () {
      this.pos = this.pos == 0 ? this.pos : this.pos - 1;
      this.medical_evolutions = this.data[this.pos];
    },
  },
  filters: {
    formatDate(date) {
      return date != null ? moment(date).format("DD/MM/YYYY") : "-";
    },
    formatTime(time) {
      let arTime = time.split(":");
      if (arTime[0] <= 12) {
        return time + " am";
      } else {
        return arTime[0] - 12 + ":" + arTime[1] + ":" + arTime[2] + " pm";
      }
    },
  },
};
</script>
<style>
  .custom-border {
    border: 2px solid #2f395c;
  }
</style>
