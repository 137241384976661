<template>
    <div>
        <div class="px-5 pb-5 pt-2">
            <div class="row">
                <div class="btn-home col-md-12 mt-3 mb-4 d-flex justify-content-end">
                    <button type="button" class="btn" @click="returnHome">
                        <i class="fas fa-home-lg-alt"></i> Inicio
                    </button>
                </div>
            </div>
            <div class="d-flex justify-content-center">
                <div class="card w-100 mb-lg-5">
                    <div class="text-center card-header text-white h4 bg-main">
                        <strong>PERSONAL</strong>
                    </div>
                    <div class="card-body w-100">
                        <div class="row">
                            <div class="col-md-12 py-2">
                                <div class="row d-flex mx-0">
                                    <div class="col-xl-10 col-lg-10 col-md-9 col-sm-12 col-12 px-1 mb-2">
                                        <div class="input-group">
                                            <span class="input-group-text text-white bg-main"><i
                                                    class="fas fa-search"></i></span>
                                            <input @keyup="calculateData(7)" v-model="search" type="text"
                                                class="form-control" placeholder="Buscar" />
                                        </div>
                                    </div>
                                    <div class="col-xl-2 col-lg-2 col-md-3 col-sm-12 col-12 btn-home">
                                        <button type="button" class="btn btn-bm-noradius w-100"
                                            @click="mtdModalShow(0, null)">
                                            <i class="fas fa-plus"></i> Nueva
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 mt-2" id="content-timelinetable">
                                <div class="table-responsive">
                                    <table id="tb-cxc" class="table table-bordered border-main">
                                        <caption class="py-0 my-0"></caption>
                                        <thead class="bg-main">
                                            <tr class=" table-th text-white text-center">
                                                <th class="table-th-number">N°</th>
                                                <!-- <th>CUP</th>
                                                <th>TIPO DE DOC.</th> -->
                                                <th>NUM. DOC.</th>
                                                <th class="table-th-name-personal">COLABORADOR</th>
                                                <!-- <th class="table-th-name-personal">APELLIDOS</th>
                                                <th class="table-th-name-personal">DOMICILIO</th>
                                                <th>FEC. NACIMIENTO</th> -->
                                                <th>TELÉFONO</th>
                                                <!-- <th>TELÉFONO 2</th> -->
                                                <th>CARGO</th>
                                                <!-- <th>DEPENDENCIA</th>
                                                <th>CENTRO DE COSTO</th>
                                                <th>CLASIFICACIÓN</th>
                                                <th>SUELDO BASE</th>
                                                <th>HORAS TRABAJADAS</th>
                                                <th>TURNO</th>
                                                <th>ASIG. FAMILIAR</th>
                                                <th>FONDO DE PENSIONES</th>
                                                <th>BANCO</th>
                                                <th>NRO. DE CUENTA</th> -->
                                                <th class="table-th-name-personal">TIPO DE CONTRATO</th>
                                                <th class="table-th-acciones">CONDICIÓN</th>
                                                <th class="table-th-acciones">ACCIONES</th>
                                            </tr>
                                        </thead>
                                        <tbody class="border-main">
                                            <tr class="table-body text-center" v-if="cpData.length === 0">
                                                <td colspan="25" class="text-center">No se encontraron resultados</td>
                                            </tr>
                                            <tr class="table-body text-center" v-for="(item, index) in cpData" :key="index">
                                                <td>
                                                    {{ mtdNumber(index) }}
                                                </td>
                                                <!-- <td class="">
                                                    {{ item.cod_uni_employee }}
                                                </td>
                                                <td class="">
                                                    {{ item.document_type }}
                                                </td> -->
                                                <td class="">
                                                    {{ item.num_document }}
                                                </td>
                                                <td class="align-left">
                                                    {{ item.last_name }} {{ item.name }}
                                                </td>
                                                <!-- <td class="">
                                                    {{ item.last_name }}
                                                </td>
                                                <td class="">
                                                    {{ item.address }}
                                                </td>
                                                <td class="">
                                                    {{ item.birthday | formatDate("%d/%m/%y") }}
                                                </td> -->
                                                <td class="">
                                                    {{ item.phone }}
                                                </td>
                                                <!-- <td class="">
                                                    {{ item.other_phone }}
                                                </td> -->
                                                <td class="">
                                                    {{ item.position_name }}
                                                </td>
                                                <!-- <td class="">
                                                    {{ item.dependency }}
                                                </td>
                                                <td class="">
                                                    {{ item.cost_center }}
                                                </td>
                                                <td class="">
                                                    {{ item.classification }}
                                                </td>
                                                <td>
                                                    {{ item.base_salary }}
                                                </td>
                                                <td>
                                                    {{ item.hours_worked }}
                                                </td>
                                                <td>
                                                    {{ item.turn_name }}
                                                </td>
                                                <td>
                                                    <span v-if="item.family_allowance == 1">SI</span>
                                                    <span v-else>NO</span>
                                                </td>
                                                <td>
                                                    <span v-if="item.pension_fund == 1">ONP</span>
                                                    <span v-else>AFP</span>
                                                </td>
                                                <td>
                                                    {{ item.bank_name }}
                                                </td>
                                                <td>
                                                    {{ item.account_number }}
                                                </td> -->
                                                <td>
                                                    {{ item.type_contract_name }}
                                                </td>
                                                <td>
                                                    <div id="state_speciality" v-if="item.condition == 1">
                                                        <div class="bg-success text-white">Activo</div>
                                                    </div>
                                                    <div id="state_speciality" v-else>
                                                        <div class="bg-secondary text-white">Inactivo</div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="text-center d-flex justify-content-center">
                                                        <button class="btn btn-sm a-warning text-white" title="Ficha Empleado"
                                                            @click="mtdModalShowEmployee(item,'view')">
                                                            <i class="fa fa-id-card"></i>
                                                        </button>
                                                        <div v-if="item.family_allowance == 1">
                                                            <button class="btn btn-sm a-primary text-white" title="Agregar Familia"
                                                                @click="mtdAddFamily(item,'add')">
                                                                <i class="fas fa-users"></i>
                                                            </button>
                                                        </div>
                                                        <button class="btn btn-sm a-success text-white" title="Editar"
                                                            @click="mtdModalShow(1, item)">
                                                            <i class="fa fa-edit"></i>
                                                        </button>
                                                        <button class="btn btn-sm a-danger text-white"
                                                            @click="Datadetail(item.id, 2)">
                                                            <i class="fas fa-trash-alt"></i>
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mt-3 text-center">
                                <div v-if="cpData.length > 0" class="d-flex justify-content-center mb-2">
                                    <nav aria-label="Page navigation example">
                                        <ul class="pagination mb-1">
                                            <li class="page-item">
                                                <a class="page-link page" href="javascript:void(0)"
                                                    @click="backPage">Anterior</a>
                                            </li>
                                            <template v-if="!isMobile">
                                                <li v-if="limitedStepPagination[0] !== 1" class="page-item">
                                                    <a :class="selectPage === 1 ? 'page-link active' : 'page-link'"
                                                        href="javascript:void(0)" @click="selectedPage(1)">1</a>
                                                </li>
                                                <li v-if="limitedStepPagination[0] > 2" class="page-item disabled">
                                                    <span class="page-link">...</span>
                                                </li>
                                                <li v-for="(item, index) in limitedStepPagination" :key="index"
                                                    class="page-item">
                                                    <a :class="selectPage === item ? 'page-link active' : 'page-link'"
                                                        href="javascript:void(0)" @click="selectedPage(item)">{{ item }}</a>
                                                </li>
                                                <li v-if="limitedStepPagination[limitedStepPagination.length - 1] < stepPagination.length - 1"
                                                    class="page-item disabled">
                                                    <span class="page-link">...</span>
                                                </li>
                                                <li v-if="limitedStepPagination[limitedStepPagination.length - 1] !== stepPagination.length"
                                                    class="page-item">
                                                    <a :class="selectPage === stepPagination.length ? 'page-link active' : 'page-link'"
                                                        href="javascript:void(0)"
                                                        @click="selectedPage(stepPagination.length)">{{
                                                            stepPagination.length }}</a>
                                                </li>
                                            </template>
                                            <li class="page-item">
                                                <a class="page-link page" href="javascript:void(0)"
                                                    @click="nextPage">Siguiente</a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <crudEmployee :boo_modal="modal.modal_form" :payload="modal.payload" :title="modal.title" :num="modal.num"
            @closeModal="closeModal" @Datadetail="Datadetail" :positionList="positionList" :bankList="bankList"
            :TurnsList="TurnsList" :contractTypes="contractTypes" />
        <cModalViewEmployee :title="modal_view.title" :boo_modal="modal_view.modal_form" :employee="modal_view.data" :view="modal_view.view" :idemployee="modal_view.idemployee" @close_modal_view="close_modal_view" />
        <SweetAlert :swal="swal" ref="SweetAlert" />
    </div>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import crudEmployee from '@/components/RecursosHumanos/Modals/cModalAddEmployee.vue';
import cModalViewEmployee from '@/components/RecursosHumanos/Modals/cModalViewEmployee.vue';

export default {
    name: "c-mantenedores-pages-employee",
    components: {
        SweetAlert,
        crudEmployee,
        cModalViewEmployee
    },
    data() {
        return {
            swal: null,
            search: "",
            dataShow: [],
            data: [],
            positionList: [],
            bankList: [],
            contractTypes: [],
            TurnsList: [],
            modal: {
                title: '',
                modal_form: false,
                num: null,
                payload: {},
            },
            modal_view: {
                title: '',
                modal_form: false,
                num: null,
                payload: {},
            },
            page: 0,
            selectPage: 1,
            stepPagination: [],
            totalPagesToShow: 4,
            action: "",
            isMobile: false,
        };
    },
    watch: {
        selectPage() {
            this.calculateData(7);
        },
    },
    computed: {
        classTh() {
            return 'align-middle border border-5 bg-main border-white';
        },
        cpData() {
            return this.dataShow;
        },
        limitedStepPagination() {
            const totalPages = this.stepPagination.length;
            const currentPage = this.selectPage;

            let startPage = 1;
            let endPage = totalPages;
            if (totalPages > this.totalPagesToShow) {
                const halfPagesToShow = Math.floor(this.totalPagesToShow / 2);

                if (currentPage > halfPagesToShow) {
                    startPage = currentPage - halfPagesToShow;
                    endPage = currentPage + halfPagesToShow - 1;

                    if (endPage > totalPages) {
                        endPage = totalPages;
                        startPage = totalPages - this.totalPagesToShow + 1;
                    }
                } else {
                    endPage = this.totalPagesToShow;
                }
            }

            return Array.from({
                length: endPage - startPage + 1,
            },
                (_, i) => startPage + i
            );
        },

    },
    created() {
        this.mtdGetData();
        this.mtdGetPosition();
        this.mtdGetTurn();
        this.mtdGetBanks();
        this.mtdGetContracts();
        this.checkIfMobile();
        window.addEventListener('resize', this.checkIfMobile);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.checkIfMobile);
    },
    methods: {
        ...mapActions(["get", "post"]),
        mtdGetData: function () {
            this.get({
                url: this.$store.getters.get__url + "/RecursosHumanos/employees",
                token: this.$store.getters.get__token,
            })
                .then((response) => {
                    this.data = response.data;
                    for (let i = 0; i < this.data.length; i++) {
                        this.data[i].turn_name = response.data[i].turn.turn;
                        this.data[i].position_name = response.data[i].position.name;
                        this.data[i].bank_name = response.data[i].bank.abbreviation;
                        this.data[i].type_contract_name = response.data[i].type_contract.name;
                    }
                    this.calculateData(7);
                })
                .catch((errors) => {
                    this.$refs.SweetAlert.showError(errors);
                });
        },
        mtdGetPosition: function () {
            this.get({
                url: this.$store.getters.get__url + "/RecursosHumanos/position",
                token: this.$store.getters.get__token,
            })
                .then((response) => {
                    this.positionList = response.data;
                })
                .catch((errors) => {
                    this.$refs.SweetAlert.showError(errors);
                });
        },
        mtdGetTurn: function () {
            this.get({
                url: this.$store.getters.get__url + "/RecursosHumanos/turn",
                token: this.$store.getters.get__token,
            })
                .then((response) => {
                    this.TurnsList = response.data;
                })
                .catch((errors) => {
                    this.$refs.SweetAlert.showError(errors);
                });
        },
        mtdGetBanks: function () {
            this.get({
                url: this.$store.getters.get__url + "/RecursosHumanos/employeeBank",
                token: this.$store.getters.get__token,
            })
                .then((response) => {
                    this.bankList = response.data;
                })
                .catch((errors) => {
                    this.$refs.SweetAlert.showError(errors);
                });
        },
        mtdGetContracts: function () {
            this.get({
                url: this.$store.getters.get__url + "/RecursosHumanos/typeOfContract",
                token: this.$store.getters.get__token,
            })
                .then((response) => {
                    this.contractTypes = response.data;
                })
                .catch((errors) => {
                    this.$refs.SweetAlert.showError(errors);
                });
        },
        calculateData(items) {
            if (!this.data || !this.data.length) {
                return (this.dataShow = []);
            }
            let filteredData = this.data;
            let indexInitial = this.selectPage;
            if (this.search.length >= 2 && this.search != "") {
                let query = this.search.toString().toUpperCase();
                filteredData = this.data.filter((element) => {
                    let name = element.name.toString().toUpperCase();
                    return name.includes(query);
                });
                indexInitial = 1;
            }

            let totalPages = Math.ceil(filteredData.length / items);
            this.stepPagination = Array.from({
                length: totalPages,
            },
                (_, index) => index + 1
            );
            if (this.action == 'd') {
                if (this.dataShow.length == 1) {
                    if (indexInitial > 1) {
                        indexInitial -= 1;
                    } else {
                        indexInitial = 1;
                    }
                }
            }
            let startIndex = (indexInitial - 1) * items;
            let endIndex = startIndex + (items - 1);
            this.dataShow = filteredData.slice(startIndex, endIndex + 1);
        },
        mtdModalShow: function (num, payload) {
            this.modal.title = (num == 0) ? 'Registrar Empleado' : 'Editar Empleado';
            this.modal.modal_form = true;
            this.modal.num = num;
            this.modal.payload = payload;
        },
        closeModal: function () {
            this.modal = {
                title: '',
                modal_form: false,
                num: null,
                payload: {},
            }
        },
        Datadetail: function (payload_detail, num_detail) {
            if (num_detail == 0) {
                payload_detail.action = 'c';
                this.addDatadetail(payload_detail, num_detail);
            } else if (num_detail == 1) {
                payload_detail.action = 'u';
                this.addDatadetail(payload_detail, num_detail);
            } else {
                let data = {
                    action: 'd',
                    id: payload_detail
                }

                this.$refs.SweetAlert.showConfirmSimple2("", "¿Estás seguro?", "warning", "Eliminar")
                    .then((result) => {
                        if (result.value) {
                            this.addDatadetail(data, num_detail);
                        }
                    });
            }
        },
        addDatadetail: function (payload_detail, num_detail) {
            this.post({
                url: this.$store.getters.get__url + "/RecursosHumanos/crudEmployees",
                token: this.$store.getters.get__token,
                params: payload_detail,
            })
                .then(response => {
                    if (num_detail == 2) {
                        this.$refs.SweetAlert.showSuccess("El registro ha sido eliminado");
                        this.action = payload_detail.action;
                        this.mtdGetData();
                    } else {
                        this.$refs.SweetAlert.showSuccess(num_detail == 0 ? "Registro Exitoso" : "Modificación Exitosa");
                        this.closeModal()
                        this.mtdGetData();
                    }
                })
                .catch(error => {
                    this.$refs.SweetAlert.showError(error);
                })
        },
        /* mtdAddFamily: function (item, view) {
            this.get({
                url: this.$store.getters.get__url + "/Doctor/getServicio/" + item.id,
                token: this.$store.getters.get__token,
            })
            .then((response) => {
                this.modal_add.modal_form = true;
                this.modal_add.view = view;
                this.modal_add.data = response.data;
                this.modal_add.idempleado = item.id;
                this.modal_add.title = "ASIGNACIÓN FAMILIAR DE: " + (item.last_name ? item.last_name : "") + " " + (item.name ? item.name : "");
            })
            .catch((errors) => {});
        }, */
        mtdModalShowEmployee: function (item, view) {
            this.get({
                url: this.$store.getters.get__url + "/RecursosHumanos/getEmployee/" + item.id,
                token: this.$store.getters.get__token,
            })
            .then((response) => {
                this.modal_view.modal_form = true;
                this.modal_view.view = view;
                this.modal_view.data = response.data;
                this.modal_view.idemployee = item.id;
                this.modal_view.title = "FICHA DE COLABORADOR(A): " + (item.name ? item.name : "") + " " + (item.last_name ? item.last_name : "");
            })
            .catch((errors) => {});
        },
        mtdModalViewShow: function (num, payload) {
            this.modal_view.title = (num == 0) ? 'Registrar Empleado' : 'Editar Empleado';
            this.modal_view.modal_form = true;
            this.modal_view.num = num;
            this.modal_view.payload = payload;
        },
        close_modal_view() {
            this.modal_view = {
                action: "",
                title: "",
                view: "",
                modal_form: false,
                data: [],
            };
        },
        //Paginacion
        backPage() {
            this.selectPage = this.selectPage > 1 ? this.selectPage - 1 : 1;
        },
        nextPage() {
            this.selectPage =
                this.selectPage < this.stepPagination.length ?
                    this.selectPage + 1 :
                    this.stepPagination.length;
        },
        selectedPage(page) {
            this.selectPage = page;
        },
        mtdNumber: function (index) {
            return (this.selectPage - 1) * 7 + (index + 1)
        },
        returnHome: function () {
            this.$emit("mtdChangePage", 'home');
        },
        checkIfMobile() {
            this.isMobile = window.innerWidth <= 700;
        },
    },
    filters: {
        // formato de fecha
        formatDate(date) {
            return date != null ? moment(date).format("DD/MM/YYYY") : "-";
        },
    }
}
</script>
