<template>
    <div>
      <div class="px-4 py-3 text-center mb-3">
        <div class=" btn-home  col-md-12 mt-3 mb-4 d-flex justify-content-end">
            <button type="button" class="btn btn-outline-main" @click="returnHome">
                <i class="fas fa-home-lg-alt"></i> Inicio
            </button>
        </div>
        <div id="div-modal-cxc" class="container d-flex justify-content-center">
            <div id="div-cxc" class="card w-100">
                <div class="text-center card-header text-white h4 bg-main">
                    <strong>RECETAS</strong>
                </div>
                <div class="card-body w-100">
                    <div class="row">
                        <div class="row d-flex mb-2">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-sm-12">
                                <div class="input-group">
                                    <span class="input-group-text text-white bg-main"><i class="fas fa-search"></i></span>
                                    <v-select
                                        class="text-dark form-control"
                                        label="name"
                                        :options="servicesPa"
                                        placeholder="-- Buscar Paciente --"
                                        @search="searchPa"
                                        v-model="selectedPa"
                                        :reduce="(name) => name.id"
                                        required
                                        :clearable="false"
                                        @input="mtdGetData"
                                    >
                                        <template slot="no-options">
                                        No hay Pacientes para la busqueda.
                                        </template>
                                    </v-select>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 mt-2" id="content-timelinetable" v-if="showTable && cpData.length !== 0">
                            <table id="tb-cxc" class="table table-bordered border-main">
                                <caption class="py-0 my-0"></caption>
                                <thead>
                                    <tr class="text-center text-white border-white bg-main align-middle">
                                        <th scope="col" class="align-middle">N°</th>
                                        <th scope="col" class="align-middle">FECHA</th>
                                        <th scope="col" class="align-middle">DOCTOR</th>
                                        <th scope="col" class="align-middle">ORIGEN</th>
                                        <th scope="col" class="align-middle">ESTADO</th>
                                        <th scope="col" class="align-middle">ACCIÓN</th>
                                    </tr>
                                </thead>
                                <tbody style="font-size: 12px;">
                                  <template>
                                    <tr v-if="cpData.length === 0">
                                        <td class="text-center align-middle" colspan="6">Sin Recetas</td>
                                    </tr>
                                    <tr  v-for="(item, index) in cpData" :key="index" class="text-center align-middle">
                                        <td>{{mtdNumber(index)}}</td>
                                        <td>{{ item.created_at | formatDateTime }}</td>
                                        <td>
                                            <template v-if="item.user">
                                                {{ item.user.name }} {{ item.user.last_name }}
                                            </template>
                                            <template v-else>
                                                -
                                            </template>
                                        </td>
                                        <td class="text-nowrap" v-if="item.origin">
                                            {{ item.origin }} - {{ item.type | formatType }}
                                        </td>
                                        <td class="text-nowrap" v-else>
                                            {{ item.type | formatType }}
                                        </td>
                                        <td class="text-center">
                                            <div class="badge text-bg-danger" v-if="item.state == 1">
                                                <div>
                                                    <strong>SIN ATENDER</strong>
                                                </div>
                                            </div>
                                            <div class="badge text-bg-warning" v-else-if="item.state == 2">
                                                <div>
                                                    <strong>EN PROCESO</strong>
                                                </div>
                                            </div>
                                            <div class="badge text-bg-success" v-else-if="item.state == 3">
                                                <div>
                                                    <strong>COMPLETADO</strong>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="d-flex d-md-block">
                                                <button
                                                    @click="openRecipe(item.id, item.created_at)" class="btn btn-primary btn-sm rounded-circle bootom me-1">
                                                    <i class="fas fa-solid fa-warehouse"></i>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                  </template>
                                </tbody>
                            </table>
                        </div>
                        <!--Paginado-->
                        <div v-if="showTable && cpData.length > 0" class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mt-3 text-center">
                            <div v-if="cpData.length > 0" class="d-flex justify-content-center mb-2">
                                <nav aria-label="Page navigation example">
                                    <ul class="pagination mb-1">
                                        <li class="page-item">
                                            <a class="page-link page" href="javascript:void(0)" @click="backPage">Anterior</a>
                                        </li>
                                        <li v-if="limitedStepPagination[0] !== 1" class="page-item">
                                            <a :class="selectPage === 1 ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(1)">1</a>
                                        </li>
                                        <li v-if="limitedStepPagination[0] > 2" class="page-item disabled">
                                            <span class="page-link">...</span>
                                        </li>

                                        <li v-for="(item, index) in limitedStepPagination" :key="index" class="page-item">
                                            <a :class="selectPage === item ? 'page-link active' : 'page-link'" href="javascript:void(0)" @click="selectedPage(item)">{{ item }}
                                            </a>
                                        </li>
                                        <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] <stepPagination.length - 1" class="page-item disabled">
                                            <span class="page-link">...</span>
                                        </li>
                                        <li v-if=" limitedStepPagination[limitedStepPagination.length - 1] !== stepPagination.length" class="page-item">
                                            <a :class="selectPage === limitedStepPagination.length + 2 ? 'page-link active':'page-link'" href="javascript:void(0)" @click="selectedPage(stepPagination.length)">{{ stepPagination.length }}
                                            </a>
                                        </li>
                                        <li class="page-item">
                                            <a class="page-link page" href="javascript:void(0)" @click="nextPage">Siguiente</a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
      <cModalMediStockVue :payload="modal.payload" :title="modal.title" :boo_modal="modal.modal_form" 
      :idReci="modal.idReci" @closeModal="closeModal"/>
      <SweetAlert :swal="swal" ref="SweetAlert" />
    </div>
</template>
<script>
import { mapActions } from "vuex";
import moment from "moment";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import cModalMediStockVue from '../Modals/cModalMediStock.vue'

export default {
    name: "c-pages-PharmacyMedic",
    components:{
        SweetAlert,
        cModalMediStockVue
    },
    data() {
    return {
        swal: null,
        search: "",
        data:[],
        dataShow: [],
        servicesPa: [],
        selectedPa: "",
        showTable: false,
        modal: {
            title: '',
            modal_form: false,
            payload: [],
            idReci: null
        },
        //Pag
        page: 0,
        selectPage: 1,
        stepPagination: [],
        totalPagesToShow: 4,
    }
    },
    created() {
    },
    watch: {
    selectPage() {
        this.calculateData(10);
    },
    },
    computed: {
    cpData() {
        return this.dataShow;
    },

    limitedStepPagination() {
        const totalPages = this.stepPagination.length;
        const currentPage = this.selectPage;

        let startPage = 1;
        let endPage = totalPages;
        if (totalPages > this.totalPagesToShow) {
            const halfPagesToShow = Math.floor(this.totalPagesToShow / 2);

            if (currentPage > halfPagesToShow) {
                startPage = currentPage - halfPagesToShow;
                endPage = currentPage + halfPagesToShow - 1;

                if (endPage > totalPages) {
                    endPage = totalPages;
                    startPage = totalPages - this.totalPagesToShow + 1;
                }
            } else {
                endPage = this.totalPagesToShow;
            }
        }

        return Array.from({
                length: endPage - startPage + 1,
            },
            (_, i) => startPage + i
        );
    },
    },
    methods: {
    ...mapActions(["get", "post"]),
    returnHome: function () {
        this.$emit("returnHome");
    },

    searchPa: function (search, loading) {
        if (search.length > 3) {
            this.showTable = false;
            this.servicesPa = [];
            this.selectedPa =  "",
            loading(true);

            this.get({
                url: this.$store.getters.get__url + "/Pharmacy/medic/patient/" + search,
                token: this.$store.getters.get__token,
            })
            .then((response) => {
                this.servicesPa = response.data;
                loading(false);
            })
            .catch((errors) => {
                this.$refs.SweetAlert.showError(errors);
            });
        }
    },

    mtdGetData: function() {
        this.selectedProd = "";
        this.post({
            url: this.$store.getters.get__url + "/Pharmacy/medic/mtdGetRecipe",
            token: this.$store.getters.get__token,
            params: {
                selectedPa: this.selectedPa,
            },
        })
        .then((response) => {
            if(response.data.length > 0){
                this.data = response.data;
                this.showTable = true;
                this.calculateData(10);
            }else{
                this.showTable = false;
                this.$refs.SweetAlert.showWarning("No hay Datos");
            }
        })
        .catch((errors) => {
            this.$refs.SweetAlert.showError(errors);
        });
    },

    calculateData(items) {
        if (!this.data || !this.data.length) {
            return (this.dataShow = []);
        }
        let filteredData = this.data;
        let indexInitial = this.selectPage;

        if (this.selectedProd && this.selectedProd !== 'TODOS') {
            filteredData = filteredData.filter((element) => {
                return element.name === this.selectedProd;
            })
            indexInitial = 1;
        }

        let totalPages = Math.ceil(filteredData.length / items);
        this.stepPagination = Array.from({
                length: totalPages,
            },
            (_, index) => index + 1
        );
        let startIndex = (indexInitial - 1) * items;
        let endIndex = startIndex + (items - 1);
        this.dataShow = filteredData.slice(startIndex, endIndex + 1);
        this.count = this.count + 1;
    },

    openRecipe(id, date){
        this.get({
            url: this.$store.getters.get__url + "/Pharmacy/medic/mtdGetRecipeDetail/" + id,
            token: this.$store.getters.get__token,
        })
        .then((response) => {
            if(response.data.data.length > 0){
                let dateFormat = moment(date).format("DD/MM/YYYY HH:mm");
                this.modal.idReci = id;
                this.modal.payload = response.data.data;
                this.modal.title = "Detalle de Recetas: " + dateFormat;
                this.modal.modal_form = true;
            }else{
                this.$refs.SweetAlert.showWarning("No hay Recetas Sin Stock");
            }
        })
        .catch((errors) => {
            this.$refs.SweetAlert.showError(errors);
        });
    },

    closeModal: function () {
        this.modal = {
            title: '',
            modal_form: false,
            idReci: null,
            payload: [],
        }
    },

    /** paginación */
    backPage() {
            this.selectPage = this.selectPage > 1 ? this.selectPage - 1 : 1;
        },
    nextPage() {
        this.selectPage =
            this.selectPage < this.stepPagination.length ?
            this.selectPage + 1 :
            this.stepPagination.length;
    },
    selectedPage(page) {
        this.selectPage = page;
    },
    mtdNumber: function(index){
        return (this.selectPage-1)*10 + (index + 1)
    },
    },
    filters: {
        formatDate(date) {
            return date != null ? moment(date).format("DD/MM/YYYY") : "-";
        },
        formatDateTime(dateTime) {
            return dateTime != null ? moment(dateTime).format("DD/MM/YYYY HH:mm") : "-";
        },
        formatType(type) {
            const types = {
                1: 'CONSULTAS',
                2: 'INTERCONSULTA',
                3: 'HOSPITALIZACIÓN',
                4: 'UCI ADULT.',
                5: 'UCINEO',
                6: 'SOP Y URPA',
                7: 'EMERGENCIA'
            };
            return types[type] || '-';
        }
    },
};
</script>

<style scoped>
.table-container {
    overflow-x: auto;
    white-space: nowrap;
    scrollbar-width: thin;
    scrollbar-color: darkgray lightgray;
}
.bootom {
    background-color: #900052 !important;
    border: 0.5px solid #900052 !important;
}
</style>
