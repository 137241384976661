<template>
<div class="container">
    <div class="row">
        <div class="btn-home-perfil col-md-12 mt-3 mb-4 d-flex justify-content-end">
            <router-link to="/home" type="button" class="btn">
                <i class="fas fa-home-lg-alt"></i> Inicio
            </router-link>
        </div>
    </div>

    <div class="mb-1">
        <div class="border-bottom border-2 border-main text-main ">
            <h3> <i class="fas fa-user"></i> Perfil del Usuario</h3>
        </div>
    </div>
    <div class="row d-flex my-2 px-4 mx-1 rounded-2" style="background-color: #f2f4f4">
        <div class="col-md-3 my-2">
            <div class="border border-main border-3 rounded-2 py-4 text-center">
                <div>
                    <img src="../../assets/bm-perfil.png" alt="" width="50%">
                </div>
                <div class="">
                    <div class="color-main mt-2"> {{ user }} </div>
                    <div class="color-main"> {{ usuario.email}}</div>
                </div>
            </div>
        </div>
        <div class="col-md-9 mt-2 rounded-2">
            <!-- OPCIONES -->
            <div class="btn-group w-100 px-1" role="group" aria-label="Basic radio toggle button group">
                <input type="radio" class="btn-check" name="btnradio" id="btnradio1" autocomplete="off" @click="changeView(1)" checked>
                <label class="btn btn-outline-bm align-middle" for="btnradio1">Datos Personales</label>

                <input type="radio" class="btn-check" name="btnradio" id="btnradio2" autocomplete="off" @click="changeView(2)">
                <label class="btn btn-outline-bm align-middle" for="btnradio2">Cambiar Contraseña</label>

                <input type="radio" class="btn-check" name="btnradio" id="btnradio3" autocomplete="off" @click="changeView(3)">
                <label class="btn btn-outline-bm align-middle" for="btnradio3">Roles</label>
            </div>
            <!-- Contenido -->
            <div class="p-3">
                <div class="row d-flex border border-main rounded-3">
                    <!-- Actualizar datos -->
                    <div class="col-12 p-4" v-if="page == 1">
                        <div class="row">
                            <div class="mb-3 col-sm-12  col-md-6">
                                <label for="nameUser" class="form-label">Nombres:</label>
                                <input type="text" id="nameUser" class="form-control" placeholder="Nombres" v-model="usuario.name">
                            </div>
                            <div class="mb-3 col-sm-12  col-md-6">
                                <label for="lastName" class="form-label">Apellidos:</label>
                                <input type="text" id="lastName" class="form-control" placeholder="Apellidos" v-model="usuario.last_name">
                            </div>
                        </div>
                        <div class="row">
                            <div class="mb-3  col-sm-12  col-md-6">
                                <label for="dni" class="form-label">DNI:</label>
                                <input type="text" id="dni" class="form-control" placeholder="DNI" v-model="usuario.dni" @keypress="validateNumberInput">
                            </div>
                            <div class="mb-3 col-sm-12  col-md-6">
                                <label for="phone" class="form-label">Telefono:</label>
                                <input type="text" id="phone" class="form-control" placeholder="Telefono" v-model="usuario.phone" @keypress="validateNumberInput">
                            </div>
                        </div>
                        <div class="row">
                            <div class="mb-3 col-sm-12  col-md-6">
                                <label for="email" class="form-label">Correo Electronico:</label>
                                <input type="text" id="email" class="form-control" placeholder="Correo Electronico" v-model="usuario.email">
                            </div>
                        </div>

                        <div class="d-flex justify-content-end mt-2">
                            <button type="button" @click="mtdModifyData" class="btn btn-save bg-main text-white px-4"><i class="fas fa-save"></i> ACTUALIZAR</button>
                        </div>
                    </div>
                    <!-- Cambiar contraseña -->
                    <div class="col-12 p-4" v-if="page == 2">
                        <div class="row">
                            <div class="mb-3 col-md-4 col-sm-12">
                                <label for="actual" class="form-label">Contraseña Actual:</label>
                                <input type="password" id="actual" v-model="requestData.currentPassword" class="form-control" placeholder="Contraseña Actual">
                            </div>
                            <div class="mb-3 col-md-4 col-sm-12">
                                <label for="new" class="form-label">Contraseña Nueva :</label>
                                <input type="password" id="new" v-model="requestData.password" class="form-control" placeholder="Contraseña Nueva">
                            </div>
                            <div class="mb-3 col-md-4 col-sm-12">
                                <label for="repitNew" class="form-label">Repita Contraseña Nueva:</label>
                                <input type="password" id="repitNew" v-model="requestData.confirmPassword" class="form-control" placeholder="Repita Contraseña Nueva">
                                
                                <div class="col-md-12 mt-1" v-if="requestData.confirmPassword !== ''">
                                  <!-- Ícono condicional -->
                                  <span v-if="passwordsMatch" class="d-flex justify-content-end">
                                        <i class="fas fa-check-circle text-success"></i>
                                    </span>
                                    <span v-else class="d-flex justify-content-end" >
                                        <i class="fas fa-times-circle text-danger"></i>
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div class="d-flex justify-content-end mt-3">
                            <button type="button" @click="mtdModifyPassword" :disabled="cpbtnModify" class="btn btn-save  bg-main text-white px-4"><i class="fas fa-save"></i> CAMBIAR</button>
                        </div>
                    </div>
                    <!-- Roles -->
                    <div class="col-12" v-if="page == 3">
                        <div class="py-4 px-4" id="roles" role="tabpanel">
                            <div class="row px-2 ">
                                <div class="col-md-12 col-lg-4 color-main py-3" v-for="(role, index) in roles" :key="index">
                                    <i class="fas fa-circle fa-xs" style="font-size: 9px;"></i> {{ role.role.name }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <SweetAlert :swal="swal" ref="SweetAlert" />

</div>
</template>

<script>
import {
    mapActions
} from 'vuex';
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';

export default {
    data() {
        return {
            user: null,
            swal: null,
            page: 1,
            usuario: {
                name: "",
                email: "",
                last_name: "",
                dni: "",
                phone: "",
                state: "",

            },
            requestData: {
                id: "",
                currentPassword: "",
                password: "",
                confirmPassword: "",
            },

            roles: []
        }

    },
    components: {
        SweetAlert
    },
    computed: {
      cpbtnModify(){
        
         return this.requestData.password == '' || this.requestData.password == '' || !this.passwordsMatch;
      },
        passwordsMatch() {
            return  this.requestData.password === this.requestData.confirmPassword;
        }
    },

    created() {
        this.user = this.$store.getters.get__user.name + ' ' + this.$store.getters.get__user.last_name;
        this.usuario = this.$store.getters.get__user;
        this.roles = this.$store.getters.get__user.roles;
    },

    methods: {
        ...mapActions(["get", "post"]),
        changeView: function (id) {
            this.page = id;
        },
        mtdHideModal: function () {

            this.requestData = {
                id: "",
                currentPassword: "",
                password: "",
                confirmPassword: "",
            }

        },

        mtdModifyData: function () {
            this.usuario.state = 1;
            this.usuario.action = 'u';
            this.post({
                    url: this.$store.getters.get__url + "/Users/crudUsers",
                    token: this.$store.getters.get__token,
                    params: this.usuario,
                })
                .then((response) => {
                    if (response.statusCode == 200) {
                        // todo correcto
                        this.$refs.SweetAlert.showSuccess("Registro Exitoso");
                    }
                })
                .catch((errors) => {});
        },

        mtdModifyPassword: function () {

            this.requestData.id = this.$store.getters.get__user.id
            if (this.requestData.password !== this.requestData.confirmPassword ||
                this.requestData.password === '' ||
                this.requestData.confirmPassword === '') {
                this.$refs.SweetAlert.showError("Las contraseñas no coinciden o los campos estan vacios");
                this.mtdHideModal();

            } else {

                this.post({
                        url: this.$store.getters.get__url + "/Users/new_password",
                        token: this.$store.getters.get__token,
                        params: this.requestData,
                    })
                    .then((response) => {
                        if (response.statusCode == 200) {
                            // todo correcto
                            this.$refs.SweetAlert.showSuccess(response.message);
                            this.mtdHideModal();
                        }

                    })
                    .catch((errors) => {
                        this.$refs.SweetAlert.showError("La contaseña actual no es valida");
                        this.mtdHideModal();

                    });

            }

        },

        validateNumberInput(event) {

            const regex = /^[0-9]*\.?[0-9]*$/;

            if (!regex.test(event.key)) {
                event.preventDefault();
            }
        },

    }

};
</script>
