<template>
  <div>
    <div class="py-2 px-3">
      <div class="card-body w-100">
        <div class="row">
          <div class="col-md-12 py-2">
            <div class="row d-flex mx-0">
              <div class="col-1 px-1 mb-2">
                <button type="button" class="btn btn-info" @click="changePage('home')">
                  <i class="fas fa-arrow-left"></i>
                </button>
              </div>
              <div class="col-xl-9 col-lg-9 col-md-8 col-sm-8 col-xs-11 px-1 mb-2">
                <div class="input-group input-group">
                  <span class="input-group-text text-white bg-main"><i class="fas fa-search"></i></span>
                  <input @keyup="calculateData(7)" v-model="search" type="text" class="form-control form-control"
                    placeholder="Buscar" />
                </div>
              </div>
              <div class="col-xl-2 col-lg-2 col-md-3 col-sm-3 col-xs-12 btn-home">
                <button type="button" class="btn btn-bm-noradius w-100" @click="mtdModalShow(0, null)">
                  <i class="fas fa-plus"></i> Nueva
                </button>
              </div>
            </div>
          </div>
          <div class="col-md-12 mt-2" id="content-timelinetable">
            <table id="tb-cxc" class="table table-bordered border-main">
              <caption class="py-0 my-0"></caption>
              <thead class="bg-main">
                <tr class=" table-th text-white text-center">
                  <th class="">N°</th>
                  <th class="table-th-number">DNI</th>
                  <th class="table-th-name">NOMBRE</th>
                  <th>FIRMA</th>
                  <!-- <th>ESPECIALIDAD</th> -->
                  <th class="table-th-acciones">ESTADO</th>
                  <th class="table-th-acciones">ACCIONES</th>
                </tr>
              </thead>
              <tbody class="border-main">
                <tr class="table-body text-center" v-if="cpData.length === 0">
                  <td colspan="9" class="text-center">No se encontraron resultados</td>
                </tr>
                <tr class="table-body text-center" v-for="(item, index) in cpData" :key="index">
                  <td>
                    {{ mtdNumber(index) }}
                  </td>
                  <td>
                    {{ item.dni }}
                  </td>
                  <td class="">
                    {{ item.name }} {{ item.last_name }}
                  </td>
                  <td class="">
                    <div>
                      <div v-if="item.img_signature">
                        <!-- <img src="@/assets/user/firma/img/528b1359-329b-4c6a-8a2c-59c1299da941.png" :alt="'firma de'+item.name" style="width: 50px; height: auto;"> -->
                        <img :src="'https://clinicabm.v2.howertpi.com/assets/' + item.img_signature"
                          :alt="'firma ' + item.name" style="width: 50px; height: auto;">
                      </div>
                      <div v-else>-</div>
                    </div>
                  </td>
                  <td>
                    <div id="state_speciality" v-if="item.state == 1">
                      <div class="bg-success text-white">Activo</div>
                    </div>
                    <div id="state_speciality" v-else>
                      <div class="bg-secondary text-white">Inactivo</div>
                    </div>
                  </td>
                  <td>
                    <div class="text-center d-flex justify-content-center">
                      <button class="btn btn-sm a-success text-white" @click="mtdModalShow(1, item)">
                        <i class="fa fa-edit"></i>
                      </button>
                      <button class="btn btn-sm a-danger text-white" @click="Datadetail(item, 2)">
                        <i class="fas fa-trash-alt"></i>
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mt-3 text-center">
            <!-- paginado -->
            <div v-if="cpData.length > 0" class="d-flex justify-content-center mb-2">
              <nav aria-label="Page navigation example">
                <ul class="pagination mb-1">
                  <li class="page-item">
                    <a class="page-link page" href="javascript:void(0)" @click="backPage">Anterior</a>
                  </li>
                  <li v-if="limitedStepPagination[0] !== 1" class="page-item">
                    <a :class="selectPage === 1 ? 'page-link active' : 'page-link'" href="javascript:void(0)"
                      @click="selectedPage(1)">1</a>
                  </li>
                  <li v-if="limitedStepPagination[0] > 2" class="page-item disabled">
                    <span class="page-link">...</span>
                  </li>

                  <li v-for="(item, index) in limitedStepPagination" :key="index" class="page-item">
                    <a :class="selectPage === item ? 'page-link active' : 'page-link'" href="javascript:void(0)"
                      @click="selectedPage(item)">{{ item }}
                    </a>
                  </li>
                  <li v-if="limitedStepPagination[limitedStepPagination.length - 1] < stepPagination.length - 1"
                    class="page-item disabled">
                    <span class="page-link">...</span>
                  </li>
                  <li v-if="limitedStepPagination[limitedStepPagination.length - 1] !== stepPagination.length"
                    class="page-item">
                    <a :class="selectPage === limitedStepPagination.length + 2 ? 'page-link active' : 'page-link'"
                      href="javascript:void(0)" @click="selectedPage(stepPagination.length)">{{
                        stepPagination.length }}
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link page" href="javascript:void(0)" @click="nextPage">Siguiente</a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
    <crudQuimico :boo_modal="modal.modal_form" :payload="modal.payload" :title="modal.title" :num="modal.num"
      @closeModal="closeModal" @Datadetail="Datadetail" :specialtys="specialtys" />
    <!-- modal add servicio -->
    <SweetAlert :swal="swal" ref="SweetAlert" />
  </div>
</template>

<script>
import {
  mapActions
} from "vuex";
import SweetAlert from '@/components/shared/SweetAlert/SweetAlert.vue';
import crudQuimico from '../../../Modals/cModalBiologo.vue';
export default {
  name: "c-rrhh-mantenedores-usuarios-quimico",
  components: {
    SweetAlert,
    crudQuimico,
  },
  data() {
    return {
      swal: null,
      search: "",
      dataShow: [],
      data: [],
      filter: {
        specialty: null
      },
      specialtys: [],
      posEdit: null,
      message: false,
      modal: {
        title: '',
        modal_form: false,
        num: null,
        payload: {},
      },
      //Pag
      page: 0,
      selectPage: 1,
      stepPagination: [],
      totalPagesToShow: 4,
      action: "",
    };
  },
  watch: {
    selectPage() {
      this.calculateData(7);
    },
  },
  computed: {
    classTh() {
      return 'align-middle border border-5 bg-main border-white';
    },
    cpData() {
      return this.dataShow;
    },
    limitedStepPagination() {
      const totalPages = this.stepPagination.length;
      const currentPage = this.selectPage;

      let startPage = 1;
      let endPage = totalPages;
      if (totalPages > this.totalPagesToShow) {
        const halfPagesToShow = Math.floor(this.totalPagesToShow / 2);

        if (currentPage > halfPagesToShow) {
          startPage = currentPage - halfPagesToShow;
          endPage = currentPage + halfPagesToShow - 1;

          if (endPage > totalPages) {
            endPage = totalPages;
            startPage = totalPages - this.totalPagesToShow + 1;
          }
        } else {
          endPage = this.totalPagesToShow;
        }
      }

      return Array.from({
        length: endPage - startPage + 1,
      },
        (_, i) => startPage + i
      );
    },

  },
  created() {
    this.mtdAllData();
  },
  methods: {
    ...mapActions(["get", "post"]),
    /* mtdSearchData: function () {
        this.get({
                url: this.$store.getters.get__url + "/technicalNurse",
                token: this.$store.getters.get__token,
            })
            .then((response) => {
                this.data = response.data;
                this.calculateData(7);
            })
            .catch((errors) => {});
    }, */
    mtdAllData: function () {
      this.get({
        url: this.$store.getters.get__url + "/RecursosHumanos/index/Quimico",
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          if (!response.exception) {
            this.data = response.data;
            this.calculateData(7);
          } else {
            this.$refs.SweetAlert.showError("Error");
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    calculateData(items) {
      if (!this.data || !this.data.length) {
        return (this.dataShow = []);
      }
      let filteredData = this.data;
      let indexInitial = this.selectPage;
      if (this.search.length >= 2 && this.search != "") {
        let query = this.search.toString().toUpperCase();
        filteredData = this.data.filter((element) => {
          let name = element.name.toString().toUpperCase();
          return name.includes(query);
        });
        indexInitial = 1;
      }

      let totalPages = Math.ceil(filteredData.length / items);
      this.stepPagination = Array.from({
        length: totalPages,
      },
        (_, index) => index + 1
      );
      if (this.action == 'd') {
        if (this.dataShow.length == 1) {
          if (indexInitial > 1) {
            indexInitial -= 1;
          } else {
            indexInitial = 1;
          }
        }
      }
      let startIndex = (indexInitial - 1) * items;
      let endIndex = startIndex + (items - 1);
      this.dataShow = filteredData.slice(startIndex, endIndex + 1);
    },

    mtdModalShow: function (num, payload) {
      this.modal.title = (num == 0) ? 'Registrar Quimico' : 'Editar Quimico';
      this.modal.modal_form = true;
      this.modal.num = num;
      this.modal.payload = payload;
      //console.log(payload);
    },

    closeModal: function () {
      this.modal = {
        title: '',
        modal_form: false,
        num: null,
        payload: {},
      }
    },

    Datadetail: function (payload_detail, num_detail) {
      if (num_detail == 0) {
        payload_detail.action = 'c';
        this.addDatadetail(payload_detail);
      } else if (num_detail == 1) {
        payload_detail.action = 'u';
        this.addDatadetail(payload_detail);
      } else {
        this.$refs.SweetAlert.showConfirmSimple2("", "¿Estás seguro?", "warning", "Eliminar")
          .then((result) => {
            if (result.value) {
              this.addDatadetail(payload_detail);
            }
          });
      }
    },

    addDatadetail: function (payload_detail) {
      let formData = new FormData();
      formData.append("id", payload_detail.id);
      formData.append("name", payload_detail.name);
      formData.append("last_name", payload_detail.last_name);
      formData.append("documento", payload_detail.documento);
      formData.append("email", payload_detail.email);
      formData.append("dni", payload_detail.dni);
      formData.append("state", payload_detail.state);
      formData.append("file", payload_detail.file);
      formData.append("action", payload_detail.action);
      this.post({
        url: this.$store.getters.get__url + "/RecursosHumanos/crudQuimico",
        token: this.$store.getters.get__token,
        params: formData,
      })
        .then((response) => {
          if (response.statusCode == 200) {
            // todo correcto
            let message = payload_detail.action == 'd' ? 'Eliminado correctamente' : response.message;
            this.$refs.SweetAlert.showSuccess(message);
            this.mtdAllData();
            this.closeModal();
          } else { }
        })
        .catch((errors) => { });

    },
    //Paginacion
    backPage() {
      this.selectPage = this.selectPage > 1 ? this.selectPage - 1 : 1;
    },
    nextPage() {
      this.selectPage =
        this.selectPage < this.stepPagination.length ?
          this.selectPage + 1 :
          this.stepPagination.length;
    },
    selectedPage(page) {
      this.selectPage = page;
    },
    mtdNumber: function (index) {
      return (this.selectPage - 1) * 7 + (index + 1)
    },
    returnHome: function () {
      this.$emit("returnHome");
    },
    changePage(page) {
      this.$emit('changePage', page)
    },
  }
}
</script>